import { observable, action } from 'mobx';
import SystemLanguageService from 'shared/services/SystemLanguageService';
import AppHelper from 'shared/utils/AppHelper';
import { RootStore } from './RootStore';

export class SystemLanguageStore {
  @observable
  systemLanguageItemsCode: any[];

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  fetchLanguage = async () => {
    // should not call API when user not logged
    if (!this.rootStore.authStore.isLogged) {
      return;
    }

    const res: any = await SystemLanguageService.getSystemLanguage();
    if (res.data.data) {
      this.setSystemLanguageItemsCode(res.data.data.code);
    }
  };

  @action
  setSystemLanguageItemsCode(items: any[]) {
    this.systemLanguageItemsCode = items;
  }

  /**
   * Get common code name
   *
   */
  getCommonCodeName = (representCode: string, commonCode: string) => {
    const represent = this.systemLanguageItemsCode.find(x => x.representCode === representCode);

    if (represent) {
      const getLanguage = (languageList: any[], currLang: string): any => {
        const language = languageList.find(lang => lang.language === currLang.toUpperCase()) || {};
        if (language) return language.commonCodeName;
        return '';
      };
      const getOptionText = (option: any) => {
        let text = ' ';
        if (option && option.languageList) text = getLanguage(option.languageList, this.rootStore.uiStore!.currLanguage.locale);
        return text;
      };

      const { commonCodeList } = represent;
      if (commonCodeList) {
        const commonCodeItem = commonCodeList.find((x: any) => AppHelper.isEqualsIgnoreCase(x.commonCode, commonCode));
        if (commonCodeItem) return getOptionText(commonCodeItem);
      }
    }
    return ' ';
  };
}
