import { Select } from 'antd';
import _ from 'lodash';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import AppConst from 'shared/utils/AppConst';
import LanguageHelper from 'shared/utils/LanguageHelper';
import ISelectCommonCodeProps from './ISelectCommonCodeProps';

const { Option } = Select;
interface ISelectCommonCodeState {
  commonCodeList: Array<any>;
  currLanguage: string;
}

@inject('systemLanguageStore')
@inject('uiStore')
@observer
class SelectCommonCode extends React.Component<ISelectCommonCodeProps, ISelectCommonCodeState> {
  state = {
    commonCodeList: [],
    currLanguage: this.props.uiStore!.currLanguage.locale
  };

  static getDerivedStateFromProps(nextProps: any, currentState: any) {
    if (nextProps.uiStore!.currLanguage !== currentState.currLanguage) {
      return { currLanguage: nextProps.uiStore!.currLanguage.locale };
    }
    return null;
  }

  getLanguage = (languageList: any[], currLang: string): any => {
    const language = languageList.find(lang => lang.language === currLang) || {};
    if (language) return language.commonCodeName;
    return '';
  };

  getOptionText = (commonCode: any) => {
    let text = ' ';
    if (commonCode.languageList) text = this.getLanguage(commonCode.languageList, this.state.currLanguage.toUpperCase());
    return text;
  };

  getCommonList = (systemLanguageItemsCode: any) => {
    const { representCode } = this.props;
    const repCode = _.find(systemLanguageItemsCode, x => x.representCode === representCode);
    return repCode && repCode.commonCodeList ? toJS(repCode.commonCodeList) : [];
  };

  handleChange = (value: any) => {
    const { onChangeCommonCode } = this.props;
    onChangeCommonCode && onChangeCommonCode(value);
  };

  render() {
    const commonCodeListAsis = this.getCommonList(this.props.systemLanguageStore!.systemLanguageItemsCode);
    const { labelInValue, representCode, defaultValue, emptyOptionMessageKey, ...other} = this.props;
    const commonCodeList = commonCodeListAsis.sort((a: any, b: any) => a.displayOrder - b.displayOrder);

    if (commonCodeList.length && emptyOptionMessageKey) {
      commonCodeList.unshift({
        commonCode: '',
        languageList: [
          { language: AppConst.LANG_EN.toUpperCase(), commonCodeName: LanguageHelper.getMessageWithLanguage(emptyOptionMessageKey, AppConst.LANG_EN) },
          { language: AppConst.LANG_KO.toUpperCase(), commonCodeName: LanguageHelper.getMessageWithLanguage(emptyOptionMessageKey, AppConst.LANG_KO) }
        ]
      });
    }
    return (
      <Select getPopupContainer={trigger => trigger.parentNode as HTMLElement} {...other} onChange={this.handleChange} defaultValue={defaultValue} labelInValue={labelInValue}>
        {commonCodeList.length &&
          commonCodeList.map((x: any, i: number) => {
              const key = representCode + i;
              if(x.useYn === "Y"){
                return (
                  <Option key={key} value={x.commonCode}>
                    {this.getOptionText(x)}
                  </Option>
                );
              // eslint-disable-next-line no-else-return
              }else if(x.useYn === "N"){
                return (
                  <Option key={key} value={x.commonCode}>
                    {this.getOptionText(x)}(사용종료)
                  </Option>
                );
              } else {
                return (
                  <Option key={key} value={x.commonCode}>
                    {this.getOptionText(x)}
                  </Option>
                );
              }
              
          })}
      </Select>
    );
  }
}

export default SelectCommonCode;
