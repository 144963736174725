import uuid from 'uuid';

class RoleModel {
  id: string; // ID of FE side
  roleId: string; // ID from API
  status: string | null;
  name: string;
  remark: string;
  deptCode?: string | null;
  deptName?: string | null;
  roleMenuList?: any;
  useYn?: any;
  regEmpNo: string;
  regEmpName: string;
  regDate: number;
  updatedEmpNo: string;
  updatedEmpName: string;
  updatedDate: number;
  changeStatus: string;

  constructor(obj?: any, id?: string) {
    this.id = id || uuid.v4();
    if (!obj) return;
    this.roleId = obj.roleId;
    this.name = obj.name;
    this.remark = obj.remark;
    this.deptCode = obj.deptCode;
    this.deptName = obj.deptName;
    this.status = obj.status;
    this.useYn = obj.useYn;
    this.regEmpNo = obj.regEmpNo;
    this.regEmpName = obj.regEmpName;
    this.regDate = obj.regDate;
    this.updatedEmpNo = obj.updatedEmpNo;
    this.updatedDate = obj.updatedDate;
    this.updatedEmpName = obj.updatedEmpName;
    this.changeStatus = obj.changeStatus;
  }
}

export default RoleModel;
