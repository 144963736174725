import uuid from 'uuid';
import IBaseModel from 'shared/base/models/IBaseModel';
import AppHelper from 'shared/utils/AppHelper';
import AppConst from 'shared/utils/AppConst';
import { DepartmentType } from 'pages/department-management/static/constant';

export class DepartmentModel implements IBaseModel {
  id: string;
  corpCode?: string;
  deptCode: string;
  deptName: string;
  deptNameEn: string;
  deptType: DepartmentType;
  deptHeadEmpNo?: string;
  divCode?: string;
  divName?: string;
  endDate?: number; // TODO: check later. Should we use number type for unixtimestamp format?
  latestDeptYn?: boolean;
  priority?: number;
  sectCode?: string;
  sectName?: string;
  startDate?: number;
  teamYn?: boolean;
  upperDeptCode: string;
  upperDeptName: string;
  useYn?: string;
  virtualDeptYn?: string;
  upperDepartment?: any;
  // isParent?: boolean;
  deptHeadEmpName?: string;
  nextLevelHeadEmpName?: string;
  nextLevelHeadEmpNo?: string;
  sectHeadEmpNo?: string;
  sectHeadEmpName?: string;
  regEmpNo?: string;
  regEmpName?: string;
  updatedEmpNo?: string;
  updatedEmpName?: string;
  regDate?: number;
  updatedDate?: number;
  changeStatus: string;

  [key: string]: any;
  constructor(obj: DepartmentModel | any) {
    const {
      corpCode,
      deptCode,
      deptType,
      deptHeadEmpNo,
      deptName,
      deptNameEn,
      divCode,
      divName,
      endDate,
      latestDeptYn,
      priority,
      sectCode,
      sectName,
      startDate,
      teamYn,
      upperDepartment,
      upperDeptCode,
      upperDeptName,
      useYn,
      virtualDeptYn,
      deptHeadEmpName,
      nextLevelHeadEmpName,
      nextLevelHeadEmpNo,
      sectHeadEmpNo,
      sectHeadEmpName,
      regEmpNo,
      regEmpName,
      updatedEmpNo,
      updatedEmpName,
      regDate,
      updatedDate,
      changeStatus
    } = obj;
    this.id = uuid.v4();
    this.corpCode = corpCode;
    this.deptCode = deptCode;
    this.deptType = deptType;
    this.deptHeadEmpNo = deptHeadEmpNo;
    this.deptName = deptName;
    this.deptNameEn = deptNameEn;
    this.divCode = divCode;
    this.divName = divName;
    this.endDate = endDate;
    this.latestDeptYn = latestDeptYn;
    this.priority = priority;
    this.sectCode = sectCode;
    this.sectName = sectName;
    this.startDate = startDate || AppHelper.getStartDateByTodayInKoreanTimestamp();
    this.teamYn = teamYn;
    this.upperDepartment = upperDepartment;
    this.upperDeptCode = upperDeptCode;
    this.upperDeptName = upperDeptName;
    this.useYn = useYn;
    this.virtualDeptYn = virtualDeptYn || AppConst.YN_YES;
    this.deptHeadEmpName = deptHeadEmpName;
    this.nextLevelHeadEmpName = nextLevelHeadEmpName;
    this.nextLevelHeadEmpNo = nextLevelHeadEmpNo;
    this.sectHeadEmpNo = sectHeadEmpNo;
    this.sectHeadEmpName = sectHeadEmpName;
    this.regEmpNo = regEmpNo;
    this.regEmpName = regEmpName;
    this.updatedEmpNo = updatedEmpNo;
    this.updatedEmpName = updatedEmpName;
    this.regDate = regDate;
    this.updatedDate = updatedDate;
    this.changeStatus = changeStatus;
  }
}
