import { observable, action } from 'mobx';
import { RootStore } from 'shared/stores/RootStore';
import AppHelper from 'shared/utils/AppHelper';
import LanguageHelper from 'shared/utils/LanguageHelper';

import RawDataDownService from '../services/RawDataDownService';

export default class RawDataDownStore {
  @observable
  inProgress: boolean;

  @observable
  workHourVisible: boolean;

  @observable
  utilizationPlanVisible: boolean;

  @observable
  utilizationResultVisible: boolean;

  @observable
  rawDataDeptCode: string | undefined;

  @observable
  rawDataDeptName: string | undefined;

  @observable
  rawDataPjCode: string | undefined;

  @observable
  rawDataPjName: string | undefined;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.workHourVisible = false;
    this.utilizationPlanVisible = false;
    this.utilizationResultVisible = false;
    this.rawDataDeptCode = '';
    this.rawDataDeptName = '';
    this.rawDataPjCode = '';
    this.rawDataPjName = '';
    this.rootStore = rootStore;
  }

  @action
  setWorkHourVisible(workHourVisible: boolean): void {
    this.workHourVisible = workHourVisible;
  }

  @action
  setUtilizationPlanVisibleVisible(utilizationPlanVisible: boolean): void {
    this.utilizationPlanVisible = utilizationPlanVisible;
  }

  @action
  setUtilizationResultVisibleVisible(utilizationResultVisible: boolean): void {
    this.utilizationResultVisible = utilizationResultVisible;
  }

  @action
  async workHourImpot(): Promise<void> {
    this.inProgress = true;

    await RawDataDownService.workHourImpot()
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  // eslint-disable-next-line class-methods-use-this
  exportWorkHourExcelFile(searchValue: any): any {
    let deptCode = '';
    if (searchValue.deptCode.toString() !== undefined) {
      deptCode = searchValue.deptCode.toString();
    }
    let pjCode = '';
    if (searchValue.pjCode.toString() !== undefined) {
      pjCode = searchValue.pjCode.toString();
    }
    let empName = '';
    if (searchValue.empName.toString() !== undefined) {
      empName = searchValue.empName.toString();
    }
    let from = '';
    let to = '';
    if (searchValue.fromTo !== undefined) {
      from = searchValue.fromTo[0].format('YYYYMMDD');
      to = searchValue.fromTo[1].format('YYYYMMDD');
    }

    return RawDataDownService.exportWorkHourExcelFile(deptCode, from, to, empName)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const url = response.data.data.url || '';
        if (url && url !== '') {
          AppHelper.downloadExcel(url);
        }
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  }

  @action
  // eslint-disable-next-line class-methods-use-this
  exportUtilPlanExcelFile(searchValue: any): any {
    let deptCode = '';
    if (searchValue.deptCode.toString() !== undefined) {
      deptCode = searchValue.deptCode.toString();
    }
    let pjCode = '';
    if (searchValue.pjCode.toString() !== undefined) {
      pjCode = searchValue.pjCode.toString();
    }
    let empName = '';
    if (searchValue.empName.toString() !== undefined) {
      empName = searchValue.empName.toString();
    }
    let from = '';
    let to = '';
    if (searchValue.fromTo !== undefined) {
      from = searchValue.fromTo[0].format('YYYYMMDD');
      to = searchValue.fromTo[1].format('YYYYMMDD');
    }

    return RawDataDownService.exportUtilPlanExcelFile(deptCode, from, to, empName)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const url = response.data.data.url || '';
        if (url && url !== '') {
          AppHelper.downloadExcel(url);
        }
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  }

  @action
  // eslint-disable-next-line class-methods-use-this
  exportUtilResultExcelFile(searchValue: any): any {
    let deptCode = '';
    if (searchValue.deptCode.toString() !== undefined) {
      deptCode = searchValue.deptCode.toString();
    }
    let pjCode = '';
    if (searchValue.pjCode.toString() !== undefined) {
      pjCode = searchValue.pjCode.toString();
    }
    let empName = '';
    if (searchValue.empName.toString() !== undefined) {
      empName = searchValue.empName.toString();
    }
    let from = '';
    let to = '';
    if (searchValue.fromTo !== undefined) {
      from = searchValue.fromTo[0].format('YYYYMMDD');
      to = searchValue.fromTo[1].format('YYYYMMDD');
    }

    return RawDataDownService.exportUtilResultExcelFile(deptCode, from, to, empName)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const url = response.data.data.url || '';
        if (url && url !== '') {
          AppHelper.downloadExcel(url);
        }
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  }
}
