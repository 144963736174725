import { action, observable, computed, toJS } from 'mobx';
import CommonCodeModel from 'shared/models/code/CommonCodeModel';
import RepresentativeCodeModel from 'shared/models/code/RepresentativeCodeModel';
import { RootStore } from 'shared/stores/RootStore';
import AppConst from 'shared/utils/AppConst';
import AppHelper from 'shared/utils/AppHelper';
import LanguageHelper from 'shared/utils/LanguageHelper';
import CommonCodeManagementService from '../services/CommonCodeManagementService';
import getStatus from '../utils/getStatus';

export class CommonCodeStore {
  @observable
  commonCodes: CommonCodeModel[];

  @observable
  inProgress: boolean;

  rootStore: RootStore;

  private _originalCommonCodes: CommonCodeModel[] = []; // original items fetched from API

  constructor(rootStore: RootStore) {
    this.commonCodes = [];
    this.rootStore = rootStore;
  }

  @action
  getAllCommonCodes(representCode: string) {
    if (!representCode) return;

    const commonCodes: CommonCodeModel[] = [];
    this._originalCommonCodes = [];

    this.inProgress = true;

    // eslint-disable-next-line consistent-return
    return CommonCodeManagementService.getAllCommonCodes(representCode)
      .then(response => {
        const data = response.data.data || [];
        data.map((item: any) => {
          const getLanguage = (languageList: any[], language: any) => {
            const lang = languageList.find(value => value.language === language) || {};
            const { commonCodeName, abbreviationName } = lang;
            return {
              commonCodeName,
              abbreviationName
            };
          };
          const role = new CommonCodeModel({
            id: item.id,
            representCode: item.representCode,
            representCodeName: item.representCodeName,
            commonCode: item.commonCode,
            krCommonCodeName: getLanguage(item.languageList, AppConst.LANG_KO.toUpperCase()).commonCodeName,
            krAbbreviationName: getLanguage(item.languageList, AppConst.LANG_KO.toUpperCase()).abbreviationName,
            enCommonCodeName: getLanguage(item.languageList, AppConst.LANG_EN.toUpperCase()).commonCodeName,
            enAbbreviationName: getLanguage(item.languageList, AppConst.LANG_EN.toUpperCase()).abbreviationName,
            displayOrder: item.displayOrder,
            useYn: item.useYn,
            remark: item.remark,
            oldCode: item.oldCode,
            regEmpNo: item.regEmpNo,
            regEmpName: item.regEmpName,
            regDate: item.regDate,
            updatedEmpNo: item.updatedEmpNo,
            updatedEmpName: item.updatedEmpName,
            updatedDate: item.updatedDate
          });
          commonCodes.push(role);
          return commonCodes;
        });
        this.commonCodes = commonCodes;
        this._originalCommonCodes = commonCodes;

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.inProgress = false;
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  }

  @action
  summaryParamsUpsert = (arrayToUpsert: any, selectedRepresentativeCode: RepresentativeCodeModel) => {
    const newArrayToUpsert: any[] = [];
    const representCode = selectedRepresentativeCode.representCode ? selectedRepresentativeCode.representCode.trim() : '';
    const representCodeName = selectedRepresentativeCode.representCodeName ? selectedRepresentativeCode.representCodeName.trim() : '';

    // eslint-disable-next-line no-unused-expressions
    arrayToUpsert.length > 0 &&
      arrayToUpsert.map((value: any) => {
        newArrayToUpsert.push({
          representCode,
          representCodeName,
          commonCode: value.commonCode ? value.commonCode.trim() : '',
          languageList: [
            {
              language: AppConst.LANG_KO.toUpperCase(),
              commonCodeName: value.krCommonCodeName ? value.krCommonCodeName.trim() : '',
              abbreviationName: value.krAbbreviationName ? value.krAbbreviationName.trim() : ''
            },
            {
              language: AppConst.LANG_EN.toUpperCase(),
              commonCodeName: value.enCommonCodeName ? value.enCommonCodeName.trim() : '',
              abbreviationName: value.enAbbreviationName ? value.enAbbreviationName.trim() : ''
            }
          ],
          displayOrder: Number(value.displayOrder),
          useYn: value.useYn,
          remark: value.remark,
          oldCode: value.oldCode ? value.oldCode.trim() : '',
          changeStatus: value.changeStatus
        });
        return arrayToUpsert;
      });
    return newArrayToUpsert;
  };

  @action
  summaryParamsDelete = (arrayToDelete: any, selectedRepresentativeCode: RepresentativeCodeModel) => {
    const newArrayToUpsert: any[] = [];
    const representCode = selectedRepresentativeCode.representCode ? selectedRepresentativeCode.representCode.trim() : '';

    // eslint-disable-next-line no-unused-expressions
    arrayToDelete.length > 0 &&
      arrayToDelete.map((value: any) => {
        newArrayToUpsert.push({
          representCode,
          commonCode: value.commonCode ? value.commonCode.trim() : ''
        });
        return arrayToDelete;
      });
    return newArrayToUpsert;
  };

  @action
  submitCommonCodeData() {
    const { selectedRepresentativeCode } = this.rootStore.representativeCodeStore;
    if (!selectedRepresentativeCode) {
      return;
    }
    this.inProgress = true;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const representCode = selectedRepresentativeCode.representCode ? selectedRepresentativeCode.representCode.trim() : '';

    const arrayToUpdate = this.summaryParamsUpsert(
      this.commonCodes.filter(function filterItemToUpdate(item) {
        return item.changeStatus === AppConst.ActionType.UPDATE;
      }),
      selectedRepresentativeCode
    );

    const arrayToInsert = this.summaryParamsUpsert(
      this.commonCodes.filter(function filterItemToUpdate(item) {
        return item.changeStatus === AppConst.ActionType.CREATE;
      }),
      selectedRepresentativeCode
    );

    const arrayToDelete: CommonCodeModel[] = [];

    // use original data to push to API if needed (in case we need delete with whole obj instead of id string)
    toJS(this.commonCodes).map(obj => {
      if (obj.changeStatus === 'DELETE') {
        const objFromOriginal = new CommonCodeModel({ ...this._originalCommonCodes.find(orginObj => orginObj.id === obj.id) });
        if (objFromOriginal) arrayToDelete.push(objFromOriginal);
      }
      return true;
    });

    const promises: any[] = [];
    if (arrayToInsert.length) {
      promises.push(CommonCodeManagementService.insertUserCommon(arrayToInsert));
    }
    if (arrayToUpdate.length) {
      promises.push(CommonCodeManagementService.updateUserCommon(arrayToUpdate));
    }
    if (arrayToDelete.length > 0) {
      promises.push(CommonCodeManagementService.deleteCommonCodeItems(arrayToDelete));
    }

    if (arrayToInsert.length > 0 || arrayToUpdate.length > 0 || arrayToDelete.length > 0) {
      Promise.all(promises)
        .then(function submitData(responses) {
          self.rootStore.apiHandleStore.handleAllApiResult(responses);
          // eslint-disable-next-line no-undef
          self.getAllCommonCodes(representCode);
          self.inProgress = false;
        })
        .catch((error: any) => {
          this.rootStore.apiHandleStore.handleApiError(error);
        })
        .finally(() => {
          this.inProgress = false;
        });
    } else {
      this.inProgress = false;
      AppHelper.showMessage('warning', LanguageHelper.getMessage('message.nothing.submit'));
    }
  }

  @action
  exportExcelFile() {
    const { selectedRepresentativeCode } = this.rootStore.representativeCodeStore;
    const representCode = selectedRepresentativeCode ? selectedRepresentativeCode.representCode : null;
    if (!representCode) return true;
    return CommonCodeManagementService.exportExcelFile(representCode)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const url = response.data.data.url || '';
        if (url && url !== '') {
          AppHelper.downloadExcel(url);
        }
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  }

  @action
  updateCommonCodesToUpsert(newValues: any) {
    const idxToChange = this.commonCodes.findIndex(obj => obj.id === newValues.id);
    const objOriginal = this._originalCommonCodes.find(obj => obj.id === newValues.id);
    const objNew = this.commonCodes[idxToChange];

    let beforeUpsertIsDelete;
    if (objNew && objNew.changeStatus === 'DELETE') {
      beforeUpsertIsDelete = true;
    }

    // setup
    const newObj = new CommonCodeModel({
      ...objOriginal,
      ...newValues
    });
    newObj.id = newValues.id;

    if (beforeUpsertIsDelete) {
      newObj.changeStatus = 'DELETE';
      // A/C from KR: Delete status always has more priority. If we wanna upsert => undelete it first
    } else {
      newObj.changeStatus = getStatus(objOriginal, newObj, 'changeStatus');
    }

    // change
    this.commonCodes[idxToChange] = newObj;
  }

  @computed
  get dataSource(): any[] {
    const dataSource: any[] = [];

    this.commonCodes.map((item: CommonCodeModel, i: any) => {
      dataSource.push({ key: i + 1, ...item });
      return dataSource;
    });

    // reduce No. column + data type
    dataSource.map((v, i) => {
      v.no = i + 1;
      if (v.displayOrder !== null) {
        v.displayOrder = Number(v.displayOrder);
      }
      return dataSource;
    });

    return Object.assign([], dataSource);
  }

  @action
  removeCommonCodes(checkedRow: string[]) {
    this.commonCodes.map((obj: CommonCodeModel, i: number) => {
      if (checkedRow.includes(obj.id)) {
        const isNewRole = !this._originalCommonCodes.find(role => role.id === obj.id);
        if (isNewRole) {
          this.commonCodes.splice(i);
        } else {
          this.commonCodes[i] = new CommonCodeModel(
            {
              ...toJS(this.commonCodes[i]),
              changeStatus: 'DELETE'
            },
            obj.id
          );
        }
      }
      return true;
    });
  }

  @action
  unDeleteItems(itemIds: string[]) {
    // case when user uncheck a confirmed delete item
    // => should revert to previous state (READ/UPDATE...)
    itemIds.map(id => {
      const idxItemToUndelete = this.commonCodes.findIndex(obj => obj.id === id);
      if (idxItemToUndelete > -1) {
        const changeStatus = getStatus(toJS(this._originalCommonCodes.find(obj => obj.id === id)), toJS(this.commonCodes[idxItemToUndelete]), 'changeStatus');
        this.commonCodes[idxItemToUndelete] = new CommonCodeModel(
          {
            ...toJS(this.commonCodes[idxItemToUndelete]),
            changeStatus
          },
          id
        );
      }
      return this.commonCodes[idxItemToUndelete];
    });
  }

  @computed
  get originalCommonCodes() {
    return this._originalCommonCodes;
  }
}
