import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import { Button } from 'antd-mobile';
import UtilizationResultAppMobileStore from '../stores/UtilizationResultAppMobileStore';
import moment from 'moment';
import MobileLanguageHelper from 'shared/utils/MobileLanguageHelper';

interface Props {
  utilizationResultAppMobileStore?: UtilizationResultAppMobileStore;
  onClickGoToList: () => void;
}

const HeaderRow = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
`;

const HeaderColLabel = styled.div`
  padding: 5px;
  width: 100px;
  font-weight: 700;
`;

const PjNameEllipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 1.4em;
  height: 1.5em;
`;

const HeaderCol = styled.div`
  padding: 5px 0;
`;

@inject('utilizationResultAppMobileStore')
@observer
class AppDetailMobileHeaderContainer extends Component<Props> {
  renderDate = (): JSX.Element => {
    let date = '0';
    if (this.props.utilizationResultAppMobileStore!.selectedCellData) {
      date = moment(this.props.utilizationResultAppMobileStore!.selectedCellData.calendar.yyyymmdd, 'YYYYMMDD')
        .format('YYYY-MM-DD')
        .toString();
    }

    return <span>{date}</span>;
  };

  rendarPjName = (): JSX.Element => {
    let pjName = '0';
    if (this.props.utilizationResultAppMobileStore!.seletedProject) {
      pjName = `(${this.props.utilizationResultAppMobileStore!.seletedProject.pjCode})${this.props.utilizationResultAppMobileStore!.seletedProject.pjName}`;
    }
    return <span>{pjName}</span>;
  };

  render() {
    return (
      <>
        <HeaderRow>
          <Button type="ghost" size="small" style={{ marginTop: '10px', marginRight: '10px' }} onClick={() => this.props.onClickGoToList()}>
            {MobileLanguageHelper.getMessage('overtime.button.title.tolist')}
          </Button>
        </HeaderRow>
        <HeaderRow>
          <HeaderColLabel>프로젝트</HeaderColLabel>
          <HeaderCol>
            <PjNameEllipsis>{this.rendarPjName()}</PjNameEllipsis>
          </HeaderCol>
        </HeaderRow>
        <HeaderRow>
          <HeaderColLabel>일자</HeaderColLabel>
          <HeaderCol>{this.renderDate()}</HeaderCol>
        </HeaderRow>
      </>
    );
  }
}

export default AppDetailMobileHeaderContainer;
