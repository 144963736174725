import { observable, action, computed, ObservableSet } from 'mobx';
import { message } from 'antd';
import moment from 'moment';
import CalendarModel from '../static/CalendarModel';
import UtilizationPlanAndResultModel from '../static/UtilizationPlanAndResultModel';
import ProjectModel from 'shared/models/project/ProjectModel';
import UtilizationResultAppService from '../services/UtilizationResultAppService';
import { RootStore } from 'shared/stores/RootStore';

interface GridDataModel {
  injctYm: string;
  planMm: string;
  resultMm: string;
  gap: any | null;
  pjCode: string;
}

interface MonthDrawerDataModel {
  yyyymmdd: string;
  empNo: string;
  empName: string | null;
  deptName: string | null;
  planSum: string;
  resultSum: string;
  gap: string;
}

interface CloseStsModel {
  corpCode: string | null;
  yearMonth: string | null;
  closeSts: string | null;
}

export default class UtilizationResultAppStore {
  @observable
  inProgress: boolean;

  @observable
  drawerVisible = false;

  @observable
  seletedPjCode: string;

  @observable
  selectedPjModel: ProjectModel | null = null;

  @observable
  gridData: GridDataModel[];

  @observable
  preYearMonth = '0';

  @observable
  nowYearMonth = '0';

  @observable
  nextYearMonth = '0';

  @observable
  monthCalendarList: CalendarModel[];

  @observable
  monthDataList: UtilizationPlanAndResultModel[];

  @observable
  selectedCellDataList: UtilizationPlanAndResultModel[];

  @observable
  monthDrawerDataList: MonthDrawerDataModel[];

  @observable
  closingWeekly = 'N';

  @observable
  closingSts: CloseStsModel;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.gridData = [];
    this.monthCalendarList = [];
    this.monthDataList = [];
    this.selectedCellDataList = [];
    this.monthDrawerDataList = [];
    this.rootStore = rootStore;
  }

  @action
  getGridData(fromMonth: string, toMonth: string): any {
    this.inProgress = true;

    const fromDate = moment(fromMonth, 'YYYYMM').format('YYYYMMDD');
    const toDate = moment(toMonth, 'YYYYMM')
      .endOf('month')
      .format('YYYYMMDD');

    return UtilizationResultAppService.getUtilizationPlanAndResultSumList(this.seletedPjCode, fromDate, toDate)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.gridData = data;

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  setGridData(): any {
    this.gridData = [];
  }

  @action
  async getMonthData(selectedMonth: string): Promise<any> {
    this.inProgress = true;

    this.nowYearMonth = selectedMonth;
    this.preYearMonth = moment(selectedMonth, 'YYYYMM')
      .add(-1, 'months')
      .format('YYYYMM')
      .toString();
    this.nextYearMonth = moment(selectedMonth, 'YYYYMM')
      .add(1, 'months')
      .format('YYYYMM')
      .toString();

    await UtilizationResultAppService.getMonthCalendarList(selectedMonth)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.monthCalendarList = data;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });

    const fromDate = moment(selectedMonth, 'YYYYMM').format('YYYYMMDD');
    const toDate = moment(selectedMonth, 'YYYYMM')
      .endOf('month')
      .format('YYYYMMDD');

    await UtilizationResultAppService.getUtilizationPlanAndResultList(this.seletedPjCode, fromDate, toDate)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];

        this.monthDataList = data;
        if (this.selectedCellDataList.length > 0) this.getMonthDrawerData(); // 20200123 문제발생에 따른 주석 - monthly calendar에서 목록으로 갔다가 다시 row 클릭 시

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  rejectUtilizationResult(empNo: string, yyyymmdd: string): any {
    this.inProgress = true;

    return UtilizationResultAppService.rejectUtilizationResult(this.seletedPjCode, empNo, yyyymmdd)
      .then(response => {
        // const data = response.data.data || [];
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        message.success('반려되었습니다.');

        this.getMonthData(this.nowYearMonth);

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        message.error('저장 중 문제가 발생하였습니다.');
        this.inProgress = false;
      });
  }

  @action
  changeDrawerVisible(visible: boolean): any {
    this.drawerVisible = visible;
  }

  @computed
  get gridPlanSum(): any {
    let planSum = 0;
    this.gridData.forEach(item => {
      planSum += Number(item.planMm);
    });
    return planSum.toFixed(2);
  }

  @computed
  get gridResultSum(): any {
    let resultSum = 0;
    this.gridData.forEach(item => {
      resultSum += Number(item.resultMm);
    });
    return resultSum.toFixed(2);
  }

  @computed
  get monthPlanSum(): any {
    let planSum = 0;
    const workingDays = this.monthCalendarList.filter(item => item.holidayYn === 'N');

    if (this.monthDataList.length > 0) {
      this.monthDataList.forEach(monthDataItem => {
        monthDataItem.planList.forEach(planItem => {
          planSum += planItem.injctRate;
        });
      });

      // for (const monthDataItem of this.monthDataList) {
      //   for (const planItem of monthDataItem.planList) {
      //     planSum += planItem.injctRate;
      //   }
      // }
    }
    return (planSum / workingDays.length).toFixed(2);
  }

  @computed
  get monthResultSum(): any {
    let resultSum = 0;
    const workingDays = this.monthCalendarList.filter(item => item.holidayYn === 'N');

    if (this.monthDataList.length > 0) {
      this.monthDataList.forEach(monthDataItem => {
        monthDataItem.resultList.forEach(resultItem => {
          resultSum += resultItem.injctRate;
        });
      });

      // for (const monthDataItem of this.monthDataList) {
      //   for (const resultItem of monthDataItem.resultList) {
      //     resultSum += resultItem.injctRate;
      //   }
      // }
    }

    return (resultSum / workingDays.length).toFixed(2);
  }

  @action
  getMonthDrawerData(selectedCellDataList: UtilizationPlanAndResultModel[] = this.selectedCellDataList): any {
    this.selectedCellDataList = selectedCellDataList;
    this.inProgress = true;
    this.monthDrawerDataList = [];
    const cellList = this.monthDataList.filter(item => item.calendar.yyyymmdd === selectedCellDataList[0].calendar.yyyymmdd)[0];

    // const cellList = this.selectedCellDataList[0];

    let empNoList: string[] = [];
    const monthDrawerDataList: MonthDrawerDataModel[] = [];
    let empName: string | null = '';
    let deptName: string | null = '';

    cellList.planList.forEach(item => empNoList.push(item.empNo));
    cellList.resultList.forEach(item => empNoList.push(item.empNo));
    empNoList = Array.from(new Set(empNoList));

    // for (let item of empNoList) {
    empNoList.forEach(item => {
      let planSum = 0;
      let resultSum = 0;

      cellList.planList.forEach(planItem => {
        if (planItem.empNo === item) {
          planSum += planItem.injctRate;
          empName = planItem.empName;
          deptName = planItem.deptName;
        }
      });
      cellList.resultList.forEach(resultItem => {
        if (resultItem.empNo === item) {
          resultSum += resultItem.injctRate;
          empName = resultItem.empName;
          deptName = resultItem.deptName;
        }
      });

      const resultData = {
        yyyymmdd: cellList.calendar.yyyymmdd,
        empNo: item,
        empName,
        deptName,
        planSum: String(planSum * 100),
        resultSum: String(resultSum * 100),
        gap: String(resultSum * 100 - planSum * 100)
      };

      monthDrawerDataList.push(resultData);
    });

    this.monthDrawerDataList = monthDrawerDataList;
    this.inProgress = false;
  }

  @action
  setSelectedPjCode(projectModel: ProjectModel): any {
    this.seletedPjCode = projectModel.pjCode;
    this.selectedPjModel = projectModel;
  }

  @action
  getCorpConf(): any {
    // 법인 별 설정정보 가져오기 - 주마감 check
    this.inProgress = true;
    return UtilizationResultAppService.getCorpConf()
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];

        this.closingWeekly = data.closingWeekly;

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  getClosingSts(injctYmd: string): any {
    return UtilizationResultAppService.getCloseSts(
      moment(injctYmd, 'YYYYMMDD')
        .format('YYYYMM')
        .toString()
    )
      .then(response => {
        // this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.closingSts = data;
        return data;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  }

  // getMonthList(fromMonth: string, toMonth: string) {
  //   let monthListResult: { yearMonth: string; workingDay: number }[] = [];
  //   // this.searchMonthList = [];
  //   let monthList: any[] = [];
  //   monthList = this.getMonthListBetweenMonths(fromMonth, toMonth);
  //   monthList.forEach(item => {
  //     UtilizationResultAppService.getMonthCalendarList(item).then(response => {
  //       const data: CalendarModel[] = response.data.data || [];
  //       const workingDays = data.filter(dataItem => dataItem.holidayYn == 'N');

  //       // this.searchMonthList.push({ yearMonth: item, workingDay: workingDays.length });
  //       monthListResult.push({ yearMonth: item, workingDay: workingDays.length });
  //     });
  //   });
  //   return monthListResult;
  // }

  // getDayListBetweenDates = (startDate: string, endDate: string) => {
  //   let now = moment(startDate, 'YYYYMMDD');
  //   let dates = [];

  //   while (now.isSameOrBefore(moment(endDate, 'YYYYMMDD'))) {
  //     dates.push(now.format('YYYYMMDD'));
  //     now.add(1, 'days');
  //   }
  //   return dates;
  // };

  // getMonthListBetweenMonths = (startMonth: string, endMonth: string) => {
  //   let now = moment(startMonth, 'YYYYMM');
  //   let months = [];

  //   while (now.isSameOrBefore(moment(endMonth, 'YYYYMM'))) {
  //     months.push(now.format('YYYYMM'));
  //     now.add(1, 'months');
  //   }
  //   return months;
  // };

  // async getMonthListPromise(fromMonth: string, toMonth: string) {
  //   return new Promise<{ yearMonth: string; workingDay: number }[]>(async (resolve, reject) => {
  //     // this.searchMonthList = [];
  //     let monthList: any[] = [];
  //     // monthList = this.getMonthListBetweenMonthsPromise(fromMonth, toMonth);
  //     await this.getMonthListBetweenMonthsPromise(fromMonth, toMonth).then(response => {

  //       let monthListResult: { yearMonth: string; workingDay: number }[] = [];

  //       this.getMonthListResultPromise(response).then(result => {
  //         resolve(result);
  //       });

  //     });
  //   });
  // }

  // async getMonthListResultPromise(response: string[]) {
  //   return new Promise<{ yearMonth: string; workingDay: number }[]>(async (resolve, reject) => {
  //     let monthListResult: { yearMonth: string; workingDay: number }[] = [];

  //     for (const item of response) {

  //       await this.getMonthCalendarListPromise(item).then(responseDays => {
  //         monthListResult.push({ yearMonth: item, workingDay: responseDays.length });
  //       });
  //     }

  //     resolve(monthListResult);
  //   });
  // }

  // async getMonthCalendarListPromise(item: string) {
  //   return new Promise<CalendarModel[]>(async (resolve, reject) => {
  //     await UtilizationResultAppService.getMonthCalendarList(item).then(response => {
  //       const data: CalendarModel[] = response.data.data || [];
  //       const workingDays = data.filter(dataItem => dataItem.holidayYn == 'N');
  //       // this.searchMonthList.push({ yearMonth: item, workingDay: workingDays.length });
  //       resolve(workingDays);
  //     });
  //   });
  // }

  // getDayListBetweenDatesPromise = (startDate: string, endDate: string) => {
  //   return new Promise<string[]>((resolve, reject) => {
  //     let now = moment(startDate, 'YYYYMMDD');
  //     let dates = [];

  //     while (now.isSameOrBefore(moment(endDate, 'YYYYMMDD'))) {
  //       dates.push(now.format('YYYYMMDD'));
  //       now.add(1, 'days');
  //     }
  //     resolve(dates);
  //   });
  // };

  // async getMonthListBetweenMonthsPromise(startMonth: string, endMonth: string) {
  //   return new Promise<string[]>((resolve, reject) => {
  //     let now = moment(startMonth, 'YYYYMM');
  //     let months = [];

  //     while (now.isSameOrBefore(moment(endMonth, 'YYYYMM'))) {
  //       months.push(now.format('YYYYMM'));
  //       now.add(1, 'months');
  //     }
  //     resolve(months);
  //   });
  // }
}
