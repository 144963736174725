import ApiHelper from 'shared/utils/ApiHelper';
import AppConst from 'shared/utils/AppConst';

class MenuAssignedService {
  getListAssignedMenu = () => {
    return ApiHelper.get(AppConst.API.SYS_MENU_ASSIGNED);
  };
}

export default new MenuAssignedService();
