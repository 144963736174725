import ApiHelper from 'shared/utils/ApiHelper';
import AppConst from 'shared/utils/AppConst';

class SystemLanguageService {
  getSystemLanguage = () => {
    return ApiHelper.get(AppConst.API.SYS_LANGUAGE);
  };
}

export default new SystemLanguageService();
