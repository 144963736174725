import BaseRepository from 'shared/base/repositories/BaseRepository';
import CorpModel from 'shared/models/corp/CorpModel';
import ApiHelper from 'shared/utils/ApiHelper';
import AppConst from 'shared/utils/AppConst';
import axios, { AxiosResponse } from 'axios';

const API_BASE = AppConst.API.SYS_CORP_SEARCH;
const API_CRUD_CORP = AppConst.API.SYS_CORP_MANAGEMENT;
class CorpService extends BaseRepository<CorpModel> {
  constructor() {
    super(AppConst.API.SYS_CORP_SEARCH);
  }

  find = (params: any) => {
    return params && Object.keys(params).length > 0 ? ApiHelper.get(API_BASE, params) : ApiHelper.get(API_BASE);
  };

  createCorp(array: CorpModel[]): Promise<AxiosResponse<CorpModel>> {
    return ApiHelper.post(API_CRUD_CORP, array);
  }

  updateCorp(array: CorpModel[]): Promise<AxiosResponse<CorpModel>> {
    return ApiHelper.put(API_CRUD_CORP, array);
  }

  deleteCorp(array: any[]): Promise<AxiosResponse<CorpModel>> {
    return ApiHelper.delete(API_CRUD_CORP, array);
  }
}

export default new CorpService();
