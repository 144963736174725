import _ from 'lodash';
import { action, observable } from 'mobx';
import { computedFn } from 'mobx-utils';
import TreeNode from 'shared/components/TreeList/TreeNode';
import MenuModel from 'shared/models/menu/MenuModel';
import { RootStore } from 'shared/stores/RootStore';
import AppConst from 'shared/utils/AppConst';
import StorageHelper from 'shared/utils/StorageHelper';
import uuid from 'uuid';
import RoleService from '../services/RoleService';

export class MenuAssignmentStore {
  @observable
  selectedMenu: MenuModel;

  @observable
  leftMenuList: MenuModel[] = [];

  @observable
  rightMenuList: MenuModel[] = [];

  @observable
  expandedKeys: string[];

  @observable
  inProgress: boolean;

  @observable
  rootStore: RootStore;

  private _commonCodeList: Array<any> = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  // 3 function below use to get common code to bind to menu have been assigned
  getCommonList = () => {
    const { systemLanguageStore } = this.rootStore;
    const repCode = _.find(systemLanguageStore!.systemLanguageItemsCode, x => x.representCode === '0027');

    if (repCode && repCode.commonCodeList) {
      this._commonCodeList = repCode.commonCodeList;
    }
  };

  getLanguage = (languageList: any[], currLang: string): any => {
    const language = languageList.find(lang => lang.language === currLang) || {};
    if (language) return language.commonCodeName;
    return '';
  };

  getOptionText = (commonCode: any) => {
    let text = ' ';
    if (commonCode && commonCode.languageList) text = this.getLanguage(commonCode.languageList, this.rootStore.uiStore!.currLanguage.locale.toUpperCase());
    return text;
  };

  @action
  async getRoleMenu(roleCode: string) {
    this.inProgress = true;
    const getLanguage = (languageList: any[], currLang: string): any => {
      return languageList.find(value => value.language === currLang) || {};
    };

    this.getCommonList();

    try {
      const apiResult: any = await RoleService.getRoleMenu(roleCode);
      this.rootStore.apiHandleStore.handleApiFail(apiResult.data.header);
      const currLang = StorageHelper.getItem(StorageHelper.KEY_CURR_LANGUAGE) === AppConst.LANG_ENUS ? AppConst.LANG_EN.toUpperCase() : AppConst.LANG_KO.toUpperCase();
      let menuList = [...apiResult.data.data.assignedMenu, ...apiResult.data.data.unAssignedMenu];
      menuList = menuList.map((x: MenuModel) => {
        x.id = uuid.v4();
        x.menuName = getLanguage(x.languages, currLang).menuName;
        x.menuNameEnglish = getLanguage(x.languages, 'en').menuName;
        if (!x.authType) {
          x.authType = '';
        }
        x.disabled = false;
        return x;
      });
      this.leftMenuList = [...menuList];
      this.rightMenuList = menuList.filter((item: any) => item.authType);
    } catch (error) {
      this.rootStore.apiHandleStore.handleApiError(error);
    }
    this.inProgress = false;
  }

  @action
  updateRoleMenu = async (model: any) => {
    this.inProgress = true;
    await RoleService.updateRoles([model!], true);
    this.inProgress = false;
  };

  @action
  updatePermission(listIds: string[], permissionName: string) {
    this.rightMenuList.map(item => {
      if (listIds.includes(item.id)) {
        item.authType = permissionName;
      }
      return 0;
    });
  }

  getTreeData = computedFn((isLeftTree: boolean) => {
    const getLanguage = (languageList: any[], currLang: string): any => {
      return languageList.find(value => value.language === currLang) || {};
    };
    const currLang = StorageHelper.getItem(StorageHelper.KEY_CURR_LANGUAGE) === AppConst.LANG_ENUS ? AppConst.LANG_EN.toUpperCase() : AppConst.LANG_KO.toUpperCase();
    const menuList = isLeftTree ? this.leftMenuList : this.rightMenuList;
    return menuList.map(
      x =>
        new TreeNode({
          id: x.id,
          parentCode: x.upperMenuCode ? x.corpCode + x.upperMenuCode : '',
          valueCode: x.corpCode + x.menuCode,
          nodeText: isLeftTree
            ? `${getLanguage(x.languages, currLang).menuName}-${x.menuCode}`
            : `${getLanguage(x.languages, currLang).menuName}-${x.menuCode}${
                x.authType ? `-${this.getOptionText(this._commonCodeList.find(c => c.commonCode === x.authType))}` : ''
              }`,
          disabled: Boolean(isLeftTree && x.authType) || x.disabled
        })
    );
  });

  getTreeMenu = computedFn((isLeftTree: boolean) => {
    /**
     * Ref: http://jsfiddle.net/tx3uwhke/
     * @param data
     * @param parent
     */
    function buildTree(data: any, parent?: any): any[] {
      const result: any[] = [];
      const DEPTCODE_ROOT = '';
      parent = typeof parent !== 'undefined' ? parent : { valueCode: DEPTCODE_ROOT };
      const children = _.filter(data, (value: MenuModel) => {
        return value.parentCode === parent.valueCode;
      });
      if (!_.isEmpty(children)) {
        _.each(children, child => {
          if (child != null) {
            result.push(child);
            const ownChildren = buildTree(data, child);
            if (!_.isEmpty(ownChildren)) {
              child.isParent = true;
              child.children = ownChildren;
            } else {
              child.isParent = false;
            }
          }
        });
      }

      return result;
    }

    const tree = buildTree(this.getTreeData(isLeftTree));

    return tree;
  });
}
