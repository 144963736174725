import React, { Component } from 'react';
import { Drawer, Button, Form, DatePicker, Input, Select, InputNumber, Row, Col, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { observer, inject } from 'mobx-react';
import SelectCommonCode from 'shared/containers/SelectCommonCode';
// TODO: Not found
// import WorkhourTimeManagementStore from '../stores/WorkhourTimeManagementStore';
// import WorkTimeModel from '../static/WorkTimeModel';
import CommonSelectEmployeeDrawer from 'pages/common/CommonSelectEmployeeDrawer';
import ReducWorkManagementStore from '../stores/ReducWorkManagementStore';
import ReducWorkModel from '../static/ReducWorkModel';
import { formEmployee } from '../static/formFields';
import { EmployeeStore } from '../../employee-management/stores/EmployeeStore';
import EmployeeModel from 'shared/models/employee/EmployeeModel';
import AppConst from 'shared/utils/AppConst';

const { Option } = Select;
// const formItemLayout = {
//   labelCol: {
//     xs: { span: 24 },
//     sm: { span: 10 }
//   },
//   wrapperCol: {
//     xs: { span: 24 },
//     sm: { span: 14 }
//   }
// };

interface Props {
  reducWorkManagementStore?: ReducWorkManagementStore;
  employeeStore?: EmployeeStore;
}

@inject('employeeStore')
@inject('reducWorkManagementStore')
@observer
class DrawerReducWorkManagementFormContainer extends React.Component<Props & FormComponentProps, any> {
  constructor(props: any) {
    super(props);
  }

  state = {
    updateCfmVisible: false,
    removeCfmVisible: false,
    createCfmVisible: false,
    openEmpDrawer: false
  };

  onCloseDrawer = (): void => {
    this.props.form.resetFields();
    this.props.reducWorkManagementStore!.setVisibleDrawer(false);
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    this.props.form.validateFields((err: any, fieldsValue: any) => {
      if (!err) {
        if (this.props.reducWorkManagementStore!.visibleType === 'create') {
          this.showCreateConfirmModal();
        } else if (this.props.reducWorkManagementStore!.visibleType === 'update') {
          this.showUpdateConfirmModal();
        }
      }
    });
  };

  // 등록
  showCreateConfirmModal = () => {
    this.setState({
      createCfmVisible: true
    });
  };

  handleCreateOk = (e: any) => {
    e.preventDefault();

    this.props.form.validateFields((err: any, fieldsValue: any) => {
      const values = new ReducWorkModel({
        ...fieldsValue,
        parentReducWorkBirthday: fieldsValue.parentReducWorkBirthday.format('YYYYMMDD'),
        reducWorkTermFrom: fieldsValue.reducWorkTermFrom.format('YYYYMMDD'),
        reducWorkTermTo: fieldsValue.reducWorkTermTo.format('YYYYMMDD')
      });

      this.props.reducWorkManagementStore!.createWorkTimeData(values);
    });
    this.setState({
      createCfmVisible: false
    });

    this.props.reducWorkManagementStore!.setVisibleDrawer(false);
    this.props.form.resetFields();
    this.reSearchList();
  };

  // 수정
  showUpdateConfirmModal = () => {
    this.setState({
      updateCfmVisible: true
    });
  };

  handleUpdateOk = (e: any) => {
    e.preventDefault();

    this.props.form.validateFields((err: any, fieldsValue: any) => {
      const values = new ReducWorkModel({
        ...fieldsValue,
        parentReducWorkBirthday: fieldsValue.parentReducWorkBirthday.format('YYYYMMDD'),
        reducWorkTermFrom: fieldsValue.reducWorkTermFrom.format('YYYYMMDD'),
        reducWorkTermTo: fieldsValue.reducWorkTermTo.format('YYYYMMDD'),
        seq: this.props.reducWorkManagementStore!.selectedRecord.seq
      });
      this.props.reducWorkManagementStore!.updateWorkTimeData(values);
    });

    this.setState({
      updateCfmVisible: false
    });

    this.props.reducWorkManagementStore!.setVisibleDrawer(false);
    this.props.form.resetFields();
    this.reSearchList();
  };

  // 삭제
  showDeleteConfirmModal = () => {
    this.setState({
      removeCfmVisible: true
    });
  };

  handleDeleteOk = () => {
    this.props.reducWorkManagementStore!.deleteWorkTimeData(this.props.reducWorkManagementStore!.selectedRecord.empNo, this.props.reducWorkManagementStore!.selectedRecord.seq);

    this.setState({
      removeCfmVisible: false
    });

    this.props.reducWorkManagementStore!.setVisibleDrawer(false);
    this.props.form.resetFields();

    this.reSearchList();
  };

  // Modal All Hide
  hideModal = () => {
    this.setState({
      updateCfmVisible: false,
      removeCfmVisible: false,
      createCfmVisible: false
    });
  };

  // List 재조회
  reSearchList = () => {
    setTimeout(() => {
      this.props.reducWorkManagementStore!.getWorkTimeListData(
        '',
        '',
        '',
        moment().format('YYYYMMDD'),
        moment()
          .add(7, 'day')
          .format('YYYYMMDD')
      );
    }, 1000);
  };

  handleChangeSelectCommon = (values: any, key: string) => {
    this.props.form.setFieldsValue({
      [key]: values
    });
  };

  // Employee Drawer 영역
  handleShowEmpDrawer = () => {
    this.setState({
      openEmpDrawer: true
    });
  };

  handleCloseEmpDrawer = () => {
    this.setState({
      openEmpDrawer: false
    });
  };

  handleSearchConcurrentEmployee = (values: any) => {
    this.props.employeeStore!.findConcurrentEmployeeByConditions(values, AppConst.API.SYS_AUTHORITY_EMP);
  };

  handleCancelConcurrentEmployee = () => {
    this.handleCloseEmpDrawer();
  };

  handleSubmitConcurrentEmployee = (values: any) => {
    this.props.employeeStore!.setSelectedConcurrentEmployee(values as EmployeeModel);
    this.handleCloseEmpDrawer();
    this.handleSetFieldValue();
  };

  handleSetFieldValue = () => {
    const { selectedConcurrentEmployee } = this.props.employeeStore!;

    this.handleChangeSelectCommon(selectedConcurrentEmployee.empNo, 'empNo');
    this.handleChangeSelectCommon(selectedConcurrentEmployee.empName, 'empNm');
  };

  getInitialValue = (item: any) => {
    const { selectedRecord } = this.props.reducWorkManagementStore!;
    const isSelectedItem = !!selectedRecord;

    switch (item) {
      case 'empNm':
        return isSelectedItem ? selectedRecord.empNm : '';
      case 'empNo':
        return isSelectedItem ? selectedRecord.empNo : '';
      case 'reducWorkType':
        return isSelectedItem ? selectedRecord.reducWorkType : '';
      case 'reducWorkHours':
        return isSelectedItem ? Number(selectedRecord.reducWorkHours) : 1;
      case 'reducWorkTermFrom':
        return isSelectedItem ? moment(selectedRecord.reducWorkTermFrom, 'YYYY/MM/DD') : '';
      case 'reducWorkTermTo':
        return isSelectedItem ? moment(selectedRecord.reducWorkTermTo, 'YYYY/MM/DD') : '';
      case 'parentReducWorkName':
        return isSelectedItem ? selectedRecord.parentReducWorkName : '';
      case 'parentReducWorkBirthday':
        return isSelectedItem ? moment(selectedRecord.parentReducWorkBirthday, 'YYYY/MM/DD') : '';
      default:
        return <Input />;
    }
  };

  render() {
    const buttonStyle = {
      marginBottom: 14,
      marginRight: 4
    };

    const { getFieldDecorator } = this.props.form;
    const { visibleType } = this.props.reducWorkManagementStore!;

    const ShowButton = () => {
      if (visibleType === 'update') {
        return (
          <>
            <Button icon="save" type="primary" style={buttonStyle} htmlType="submit">
              <span>
                <FormattedMessage id="workhour.time.management.drawer.modal.title.update" />
              </span>
            </Button>
            <Modal
              title={<FormattedMessage id="workhour.time.management.drawer.modal.title.update" />}
              visible={this.state.updateCfmVisible}
              onOk={this.handleUpdateOk}
              onCancel={this.hideModal}
              okText={<FormattedMessage id="button.drawer.confirm" />}
              cancelText={<FormattedMessage id="button.drawer.cancel" />}
            >
              <p>
                <FormattedMessage id="workhour.time.management.message.confirm.update" />
              </p>
            </Modal>
            <Button icon="delete" type="dashed" style={buttonStyle} onClick={this.showDeleteConfirmModal}>
              <span>
                <FormattedMessage id="label.drawer.delete" />
              </span>
            </Button>
            <Modal
              title={<FormattedMessage id="label.modal.title.schedule.remove" />}
              visible={this.state.removeCfmVisible}
              onOk={this.handleDeleteOk}
              onCancel={this.hideModal}
              okText={<FormattedMessage id="button.drawer.confirm" />}
              cancelText={<FormattedMessage id="button.drawer.cancel" />}
            >
              <p>
                <FormattedMessage id="message.confirm.remove.schedule" />
              </p>
            </Modal>
          </>
        );
      }
      if (visibleType === 'create') {
        return (
          <>
            <Button icon="save" type="primary" style={buttonStyle} htmlType="submit">
              <span>
                <FormattedMessage id="workhour.time.management.drawer.modal.title.create" />
              </span>
            </Button>
            <Modal
              title={<FormattedMessage id="workhour.time.management.drawer.modal.title.create" />}
              visible={this.state.createCfmVisible}
              onOk={this.handleCreateOk}
              onCancel={this.hideModal}
              okText={<FormattedMessage id="button.drawer.confirm" />}
              cancelText={<FormattedMessage id="button.drawer.cancel" />}
            >
              <p>
                <FormattedMessage id="workhour.time.management.message.confirm.create" />
              </p>
            </Modal>
          </>
        );
      }
      return <></>;
    };

    return (
      <div>
        <Drawer
          title={<FormattedMessage id="workhour.time.management.drawer.title.reduce.work.hour.management" />}
          placement="right"
          closable={true}
          onClose={this.onCloseDrawer}
          visible={this.props.reducWorkManagementStore!.visibleDrawer}
          maskClosable={false}
          width={550}
        >
          <CommonSelectEmployeeDrawer
            formFields={formEmployee}
            visibleCondition={this.state.openEmpDrawer}
            onSubmit={this.handleSubmitConcurrentEmployee}
            onSearch={this.handleSearchConcurrentEmployee}
            onCancel={this.handleCancelConcurrentEmployee}
          />
          <Form
            // {...formItemLayout}
            onSubmit={this.handleSubmit}
          >
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Form.Item label={<FormattedMessage id="workhour.time.management.label.emp.name" />}>
                  {getFieldDecorator('empNm', {
                    validateFirst: true,
                    initialValue: this.getInitialValue('empNm'),
                    rules: [{ required: true, message: <FormattedMessage id="workhour.time.management.message.emp.nm.isrequired" /> }]
                  })(<Input onClick={this.handleShowEmpDrawer} />)}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item label={<FormattedMessage id="workhour.time.management.label.emp.no" />}>
                  {getFieldDecorator('empNo', {
                    validateFirst: true,
                    initialValue: this.getInitialValue('empNo'),
                    rules: [{ required: true, message: <FormattedMessage id="workhour.time.management.message.emp.no.isrequired" /> }]
                  })(<Input onClick={this.handleShowEmpDrawer} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Form.Item label={<FormattedMessage id="workhour.time.management.label.type" />}>
                  {getFieldDecorator('reducWorkType', {
                    initialValue: this.getInitialValue('reducWorkType'),
                    rules: [
                      {
                        required: true,
                        message: <FormattedMessage id="workhour.time.management.message.isrequired" />
                      }
                    ]
                  })(
                    <SelectCommonCode
                      representCode={AppConst.RCode.REDUC_WORK_TYPE}
                      defaultValue={this.getInitialValue('reducWorkType')}
                      emptyOptionMessageKey="workhour.time.management.select.empty.select"
                      onChangeCommonCode={(values: any) => {
                        this.handleChangeSelectCommon(values, 'reducWorkType');
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item label={<FormattedMessage id="workhour.time.management.label.time" />}>
                  {getFieldDecorator('reducWorkHours', {
                    validateFirst: true,
                    initialValue: this.getInitialValue('reducWorkHours') ? this.getInitialValue('reducWorkHours') : 1,
                    rules: [{ required: false, type: 'number' }]
                  })(<InputNumber min={1} max={10} style={{ width: '30%' }} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Form.Item label={<FormattedMessage id="workhour.time.management.label.start.date" />}>
                  {getFieldDecorator('reducWorkTermFrom', {
                    initialValue: visibleType === 'update' ? this.getInitialValue('reducWorkTermFrom') : '',
                    rules: [{ required: true, message: <FormattedMessage id="workhour.time.management.message.isrequired" /> }]
                  })(<DatePicker format="YYYY/MM/DD" />)}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item label={<FormattedMessage id="workhour.time.management.label.end.date" />}>
                  {getFieldDecorator('reducWorkTermTo', {
                    initialValue: visibleType === 'update' ? this.getInitialValue('reducWorkTermTo') : '',
                    rules: [{ required: true, message: <FormattedMessage id="workhour.time.management.message.isrequired" /> }]
                  })(<DatePicker format="YYYY/MM/DD" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Form.Item label={<FormattedMessage id="workhour.time.management.label.target.name" />}>
                  {getFieldDecorator('parentReducWorkName', {
                    validateFirst: true,
                    initialValue: this.getInitialValue('parentReducWorkName'),
                    rules: [{ required: false }]
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item label={<FormattedMessage id="workhour.time.management.label.children.date.of.birth" />}>
                  {getFieldDecorator('parentReducWorkBirthday', {
                    initialValue: visibleType === 'update' ? this.getInitialValue('parentReducWorkBirthday') : '',
                    rules: [{ type: 'object', required: false }]
                  })(<DatePicker format="YYYY/MM/DD" />)}
                </Form.Item>
              </Col>
            </Row>
            <div
              style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right'
              }}
            >
              <ShowButton />
              <Button icon="close" onClick={this.onCloseDrawer} style={{ marginRight: 8 }}>
                <span>
                  <FormattedMessage id="utilizationresult.button.title.close" />
                </span>
              </Button>
            </div>
          </Form>
        </Drawer>
      </div>
    );
  }
}
const WrappedFormDrawer = Form.create<Props & FormComponentProps>()(DrawerReducWorkManagementFormContainer);
export default WrappedFormDrawer;
