/* eslint-disable class-methods-use-this */
import ApiHelper from 'shared/utils/ApiHelper';
import { AxiosResponse } from 'axios';

const API_BASE = '/timesheet';

class MasterImportService {
  unitPriceImpot(): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/unit-price-grade-import`;
    return ApiHelper.get(api);
  }

  empImpot(): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/emp-import`;
    return ApiHelper.get(api);
  }

  projectImpot(): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/project-import`;
    return ApiHelper.get(api);
  }

  deptImpot(): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/dept-import`;
    return ApiHelper.get(api);
  }

  interfaceImpot(jobId: any): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/legacy-if/${jobId}`;
    return ApiHelper.get(api);
  }
}

export default new MasterImportService();
