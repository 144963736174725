import { observable, action, computed, toJS } from 'mobx';
import AppHelper from 'shared/utils/AppHelper';
import ITableStore from 'shared/containers/MobXEditTable/ITableStore';
import DepartmentHistoryModel from 'shared/models/department/DepartmentHistoryModel';
import DepartmentHistorySearchModel from 'shared/models/department/DepartmentHistorySearchModel';
import _ from 'lodash';
import AppConst from 'shared/utils/AppConst';
import { RootStore } from 'shared/stores/RootStore';
import TreeNode from 'shared/components/TreeList/TreeNode';
import uuid from 'uuid';
import DepartmentService from '../services/DepartmentService';

export default class DepartmentHistoryStore implements ITableStore<DepartmentHistoryModel> {
  @observable
  dataSource: DepartmentHistoryModel[];

  @observable
  selectedItem?: DepartmentHistoryModel | undefined;

  @observable
  selectedRowKeys: string[];

  @observable
  inProgress: boolean;

  rootStore: RootStore;
  @observable
  searchModel: DepartmentHistorySearchModel;

  @observable
  lastSearch: any;

  /** Current selected row key */
  @observable
  selectedRowKey: string;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.resetStore();    
  }

  submit = () => {};

  @action
  setDataSource = (dataSource: DepartmentHistoryModel[]) => {
    this.dataSource = dataSource;
  };

  @action
  setSelectedItem = (selectedItem: any) => {
    this.selectedItem = selectedItem;
  };

  @action
  setSelectedRowKeys(selectedRowKeys: string[]) {
    this.selectedRowKeys = selectedRowKeys;
  }

  find = () => {
    if (!this.searchModel.deptCode) {
      this.dataSource = [];
    } else {
      const handleTrimValue = (obj: any) => {
        Object.keys(obj).forEach((key: any) => {
          if (!obj[key]) {
            delete obj[key];
          } else {
            obj[key] = obj[key].trim();
          }
        });
        return obj;
      };

      this.inProgress = true;
      const newParamsSearch = handleTrimValue(toJS(this.searchModel));
      this.lastSearch = handleTrimValue(toJS(this.searchModel));
      this.dataSource = Array<DepartmentHistoryModel>();
      let myApi = Array<DepartmentHistoryModel>();

      return DepartmentService.searchDepartmentHistories(newParamsSearch)
        .then((response: any) => {
          this.rootStore.apiHandleStore.handleApiFail(response.data.header);
          const data = response.data.data || [];
          myApi = data.map((element: any) => {
            element.id = uuid.v4();
            element.changeStatus = AppConst.ActionType.READ;
            return element;
          });

          const myApiSort = myApi.sort((a, b) => {
            return AppHelper.getStringSortResult(a.deptCode!, b.deptCode!, true);
          });
          myApiSort.map((v, i) => {
            v.no = i + 1;
            return v;
          });
          this.dataSource = myApiSort;
          this.inProgress = false;
        })
        .catch(error => {
          this.inProgress = false;
          this.rootStore.apiHandleStore.handleApiError(error);
        });
    }
  };

  @action
  setCurrentSelectedRow(selectedRowKey: string) {
    this.selectedRowKey = selectedRowKey;
  }

  @action
  resetStore = () => {
    this.dataSource = [];
    this.searchModel = {
      deptCode: ''
    };
    this.lastSearch = null;
  };
}
