import { message } from 'antd';
import _ from 'lodash';
import { action, computed, observable, toJS } from 'mobx';
import RepresentativeCodeModel from 'shared/models/code/RepresentativeCodeModel';
import { RootStore } from 'shared/stores/RootStore';
import AppHelper from 'shared/utils/AppHelper';
import LanguageHelper from 'shared/utils/LanguageHelper';
import CodeManagementService from '../services/CodeManagementService';
import getStatus from '../utils/getStatus';

interface ISearchParameter {
  representCode?: string;
  representCodeName?: string;
  commonCode?: string;
  isUseValue?: string;
}

export class RepresentativeCodeStore {
  private _originalRepresentativeCode: RepresentativeCodeModel[] = []; // original items fetched from API

  @observable
  selectedRepresentativeCode?: RepresentativeCodeModel;

  @observable
  representativeCodes: RepresentativeCodeModel[] = [];

  // MobX prefer using ID more than pass a whole object
  @observable
  inProgress: boolean;

  @observable
  searchParams?: ISearchParameter;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action
  cleanParamsBeforeCallApi = (arr: any) => {
    arr.map((item: any) => {
      delete item.changeStatus;
      delete item.id;
      delete item.key;
      delete item.no;
      return arr;
    });
    return arr;
  };

  @action
  async submitRepresentativeCodeData() {
    if (toJS(this.representativeCodes.length) > 0) {
      // setup arrays
      const arrayToCreate: RepresentativeCodeModel[] = Array.from(this.representativeCodes).filter(obj => obj.changeStatus === 'CREATE');
      const arrayToUpdate: RepresentativeCodeModel[] = Array.from(this.representativeCodes).filter(obj => obj.changeStatus === 'UPDATE');
      const arrayToDelete: RepresentativeCodeModel[] = [];

      // use original data to push to API if needed (in case we need delete with whole obj instead of id string)
      toJS(this.representativeCodes).map(obj => {
        if (obj.changeStatus === 'DELETE') {
          const objFromOriginal = new RepresentativeCodeModel({ ...this._originalRepresentativeCode.find(orginObj => orginObj.id === obj.id) });
          if (objFromOriginal) arrayToDelete.push(objFromOriginal);
        }
        return true;
      });

      // ! FINISHED setup

      if (arrayToCreate.length + arrayToUpdate.length + arrayToDelete.length === 0) {
        AppHelper.showMessage('warning', LanguageHelper.getMessage('message.nothing.submit'));
      } else {
        this.inProgress = true;

        const callService = (arr: any[], caller: Function) =>
          caller(this.cleanParamsBeforeCallApi(arr))
            .then((response: any) => {
              this.rootStore.apiHandleStore.handleApiFail(response.data.header);
            })
            .catch((error: any) => {
              this.rootStore.apiHandleStore.handleApiError(error);
            });

        if (arrayToCreate.length > 0) await callService(arrayToCreate, CodeManagementService.insertRepresent);
        if (arrayToUpdate.length > 0) await callService(arrayToUpdate, CodeManagementService.updateRepresent);
        if (arrayToDelete.length > 0) await callService(arrayToDelete, CodeManagementService.deleteRepresentItems);

        // TODO:
        // Need to dissucss this topic: How we handle the message "Data save successfully?"
        // in the case  ONE OF actions is fail

        this.findRepresentativeCodeByConditions();
        this.inProgress = false;
        this.selectedRepresentativeCode = undefined;
        this.rootStore.commonCodeStore!.commonCodes = [];
        message.info(LanguageHelper.getMessage('message.submit.done'));
      }
    }
  }

  @action
  findRepresentativeCodeByConditions() {
    // clear old states
    this.selectedRepresentativeCode = undefined;
    this.rootStore.commonCodeStore.commonCodes = [];

    // no need to do anything more
    if (!this.searchParams) return true;

    const { representCode, representCodeName, commonCode, isUseValue } = this.searchParams;
    const trimRepresentCode = representCode ? representCode.trim() : '';
    const trimRepresentCodeName = representCodeName ? representCodeName.trim() : '';
    const trimCommonCode = commonCode ? commonCode.trim() : '';
    this.inProgress = true;

    return CodeManagementService.findAllRepresentCode(trimRepresentCode, trimRepresentCodeName, trimCommonCode, isUseValue || '')
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        const arr: RepresentativeCodeModel[] = [];

        data.map((item: any) => {
          const model = new RepresentativeCodeModel({
            id: item.id,
            representCode: item.representCode,
            representCodeName: item.representCodeName,
            codeLength: item.codeLength,
            useYn: item.useYn,
            remark: item.remark,
            oldCode: item.oldCode,
            regEmpNo: item.regEmpNo,
            regEmpName: item.regEmpName,
            regDate: item.regDate,
            updatedEmpNo: item.updatedEmpNo,
            updatedEmpName: item.updatedEmpName,
            updatedDate: item.updatedDate
          });

          arr.push(model);
          return arr;
        });
        this._originalRepresentativeCode = arr;
        this.representativeCodes = arr;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  @action
  exportExcelFile() {
    if (!this.searchParams) return true;
    const { representCode, representCodeName, commonCode, isUseValue } = this.searchParams;
    const trimRepresentCode = representCode ? representCode.trim() : '';
    const trimRepresentCodeName = representCodeName ? representCodeName.trim() : '';
    const trimCommonCode = commonCode ? commonCode.trim() : '';

    return CodeManagementService.exportExcelFile(trimRepresentCode, trimRepresentCodeName, trimCommonCode, isUseValue || '')
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const url = response.data.data.url || '';
        if (url && url !== '') {
          AppHelper.downloadExcel(url);
        }
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  }

  @action
  updateRepresentativeCodesToUpsert(objChanged: any) {
    const idxToChange = this.representativeCodes.findIndex(obj => obj.id === objChanged.id);
    const objOriginal = this._originalRepresentativeCode.find(obj => obj.id === objChanged.id);
    const objNew = this.representativeCodes[idxToChange];
    let beforeUpsertIsDelete;
    if (objNew && objNew.changeStatus === 'DELETE') {
      beforeUpsertIsDelete = true;
    }

    // setup
    const newObj = new RepresentativeCodeModel({
      ...objOriginal,
      ...objChanged
    });
    newObj.id = objChanged.id;

    if (beforeUpsertIsDelete) {
      newObj.changeStatus = 'DELETE';
      // A/C from KR: Delete status always has more priority. If we wanna upsert => undelete it first
    } else {
      newObj.changeStatus = getStatus(objOriginal, newObj, 'changeStatus');
    }

    // change
    this.representativeCodes[idxToChange] = newObj;
  }

  @action
  setSelectedRepresentativeCode(representCode: RepresentativeCodeModel) {
    this.selectedRepresentativeCode = representCode;
  }

  @computed
  get dataSource(): any[] {
    const dataSource: any[] = [];

    this.representativeCodes.map((representativeCode: RepresentativeCodeModel, i: any) => {
      dataSource.push({ key: i + 1, ...representativeCode });
      return dataSource;
    });

    // reduce No. column + data type
    dataSource.map((v, i) => {
      v.no = i + 1;
      if (v.codeLength !== null) {
        v.codeLength = Number(v.codeLength);
      }
      return dataSource;
    });

    return Object.assign([], dataSource);
  }

  @action
  removeRepresentativeCode(checkedRow: string[]) {
    this.representativeCodes.map((obj: RepresentativeCodeModel, i: number) => {
      if (checkedRow.includes(obj.id)) {
        const isNewRole = !this._originalRepresentativeCode.find(role => role.id === obj.id);
        if (isNewRole) {
          this.representativeCodes.splice(i);
        } else {
          this.representativeCodes[i] = new RepresentativeCodeModel(
            {
              ...toJS(this.representativeCodes[i]),
              changeStatus: 'DELETE'
            },
            obj.id
          );
        }
      }
      return true;
    });
  }

  @action
  unDeleteItems(itemIds: string[]) {
    // case when user uncheck a confirmed delete item
    // => should revert to previous state (READ/UPDATE...)
    itemIds.map(id => {
      const idxItemToUndelete = this.representativeCodes.findIndex(obj => obj.id === id);
      if (idxItemToUndelete > -1) {
        const changeStatus = getStatus(toJS(this._originalRepresentativeCode.find(obj => obj.id === id)), toJS(this.representativeCodes[idxItemToUndelete]), 'changeStatus');
        this.representativeCodes[idxItemToUndelete] = new RepresentativeCodeModel(
          {
            ...toJS(this.representativeCodes[idxItemToUndelete]),
            changeStatus
          },
          id
        );
      }
      return this.representativeCodes[idxItemToUndelete];
    });
  }
}
