/* eslint-disable class-methods-use-this */
import ApiHelper from 'shared/utils/ApiHelper';

import { AxiosResponse } from 'axios';
import OverTimeSumModel from '../static/OverTimeSumModel';
import OverTimeModel from '../static/OverTimeModel';

const API_BASE = '/timesheet';

class OvertimeAppMobileService {
  getWeekNumber(date: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/calendars/${date}`;
    return ApiHelper.get(api);
  }

  getMonthOverTimeList(pjCode: string, workSysYearMonth: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/project/${pjCode}/calendar/${workSysYearMonth}/overtimes/monthly-sum`;
    return ApiHelper.get(api);
  }

  allApprOverTime(overtimeSum: OverTimeSumModel): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/overtimes/monthly-all-appr`;
    return ApiHelper.put(api, overtimeSum);
  }

  getMonthOverTimeAppr(empNo: string, workSysYearMonth: string, pjCode: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/pjCode/${pjCode}/emps/${empNo}/calendar/${workSysYearMonth}/overtimes/monthly-list`;
    return ApiHelper.get(api);
  }

  getWorkType(empNo: string, date: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/emps/${empNo}/work-type-changes/${date}`;
    return ApiHelper.get(api);
  }

  updateOverTime(overtime: OverTimeModel): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/overtimes`;
    return ApiHelper.put(api, overtime);
  }
}

export default new OvertimeAppMobileService();
