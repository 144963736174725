import React, { Component } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import { SystemLanguageStore } from 'shared/mobile-stores/SystemLanguageStore';
import { UIStore } from 'shared/mobile-stores/UIStore';
import UtilizationResultCalendarMobileStore from '../stores/UtilizationResultCalendarMobileStore';
import CommonProjectMobileStore from '../../common/stores/CommonProjectMobileStore';
import CommonProjectMobileComponent from '../../common/containers/CommonProjectMobileComponent';
import { Icon } from 'antd';
import { Button, Flex, DatePicker, List, InputItem, Picker, WhiteSpace, Toast } from 'antd-mobile';
import { Link, withRouter } from 'react-router-dom';
import enUs from 'antd-mobile/lib/date-picker/locale/en_US';
import moment from 'moment';
import { toJS } from 'mobx';
import AppConst from 'shared/utils/AppConst';
import LanguageHelper from 'shared/utils/LanguageHelper';

const rcForm = require('rc-form');

const now = new Date();

// const actvTypeSample = [{ value: 'P2', label: '용역프로젝트수행' }, { value: 'S2', label: '영업프로젝트지원' }, { value: 'E4', label: '자기학습활동' }];

const isNum = (num: any): boolean => /^\d+$/.test(num) || /^\d+\.\d+$/.test(num);

interface Props {
  systemLanguageStore?: SystemLanguageStore;
  uiStore?: UIStore;
  utilizationResultCalendarMobileStore?: UtilizationResultCalendarMobileStore;
  commonProjectMobileStore?: CommonProjectMobileStore;
  form: any;
  history: any;
  emptyOptionMessageKey?: string;
}

const InputForm = styled.div`
  /* padding-top: 50px; */
  margin: 10px;
`;

const FooterButton = styled.div`
  padding: 10px;
  width: 100%;
  position: fixed;
  bottom: 0px;
`;

const ValidatorArea = styled.div`
  padding: 10px;
  color: #ff0000;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
`;

@inject('uiStore')
@inject('systemLanguageStore')
@inject('commonProjectMobileStore')
@inject('utilizationResultCalendarMobileStore')
@observer
class RegMobileWrapperContainer extends Component<Props> {
  state = {
    sValue: ['M3'],
    dpValue: now,
    validationMessage: [],
    commonCodeList: [{ value: '선택', label: '선택' }]
  };

  getLanguage = (languageList: any[], currLang: string): any => {
    const language = languageList.find(lang => lang.language === currLang) || {};
    if (language) return language.commonCodeName;
    return '';
  };

  getOptionText = (commonCode: any) => {
    let text = ' ';
    if (commonCode.languageList) text = this.getLanguage(commonCode.languageList, this.props.uiStore!.currLanguage.locale.toUpperCase());
    return text;
  };

  rederActvType = () => {
    // const commonCodeList = this.props.systemLanguageStore!.systemLanguageItemsCode.find(item => item.representCode === '0016');
    const commonCodeList = _.find(this.props.systemLanguageStore!.systemLanguageItemsCode, item => item.representCode === '0016');
    if (commonCodeList && commonCodeList.commonCodeList) {
      let actvTypeCodeList = toJS(commonCodeList.commonCodeList);

      if (actvTypeCodeList.length && this.props.emptyOptionMessageKey) {
        actvTypeCodeList.unshift({
          commonCode: '',
          languageList: [
            { language: AppConst.LANG_EN.toUpperCase(), commonCodeName: LanguageHelper.getMessageWithLanguage(this.props.emptyOptionMessageKey, AppConst.LANG_EN) },
            { language: AppConst.LANG_KO.toUpperCase(), commonCodeName: LanguageHelper.getMessageWithLanguage(this.props.emptyOptionMessageKey, AppConst.LANG_KO) }
          ]
        });
      }

      return actvTypeCodeList;
    } else {
      this.props.history.push('/m/timesheet/utilresult/calendar');
      return;
    }
  };

  componentDidMount() {
    // const commonCodeList = this.props.systemLanguageStore!.systemLanguageItemsCode.find(item => item.representCode === '0016');
    // console.log(commonCodeList);
    let commonCodeTmp = { value: '', label: '' };
    let commonCodeListTmp = new Array();
    if (this.state.commonCodeList.length < 2) {
      const actvTypeList = this.rederActvType();
      if (actvTypeList) {
        actvTypeList.forEach((item: any) => {
          commonCodeTmp = { value: '', label: '' };
          commonCodeTmp.value = item.commonCode;
          commonCodeTmp.label = this.getOptionText(item);
          console.log(commonCodeTmp);
          commonCodeListTmp.push(commonCodeTmp);
          console.log(commonCodeListTmp);
        });
        console.log(commonCodeListTmp);
        this.setState({
          commonCodeList: commonCodeListTmp
        });
      }
    }
  }

  openProjectModal = () => {
    this.props.commonProjectMobileStore!.setVisibleModal(true);
  };

  onSubmit = () => {
    // 마감여부 체크
    if (this.props.utilizationResultCalendarMobileStore!.closingWeekly === 'Y') {
      if (moment(this.props.form.getFieldsValue().injctYmd).isBefore(moment(), 'week')) {
        Toast.fail('마감되었습니다.', 2); // 주마감
        return;
      }
    }
    this.props.utilizationResultCalendarMobileStore!.getClosingSts(moment(this.props.form.getFieldsValue().injctYmd).format('YYYYMMDD')).then((response: any) => {
      if (response.closeSts === 'C' || response.closeSts === 'T') {
        Toast.fail('마감되었습니다.', 2); // 월마감
        return;
      }
    });

    this.setState({ validationMessage: [] });
    this.props.form.validateFields({ force: true }, (error: any) => {
      if (!error) {
        const values = {
          ...this.props.form.getFieldsValue(),
          actvType: this.props.form.getFieldsValue().actvType[0],
          pjCode: this.props.commonProjectMobileStore!.returnPjCode,
          pjName: this.props.commonProjectMobileStore!.returnPjName,
          injctYmd: moment(this.props.form.getFieldsValue().injctYmd).format('YYYYMMDD')
        };
        this.props
          .utilizationResultCalendarMobileStore!.regUtilizationResultInjctYmdValidation(values)
          .then((response: any) => {
            this.setState({ validationMessage: response });
            Toast.fail(response, 2);
            if (response.length < 1) {
              Toast.success('저장되었습니다.', 2);
              this.props.history.goBack();
            }
          })
          .catch((error: any) => {});
      } else {
        let message = '';
        for (let key in error) {
          error[key].errors.map((item: any, index: any) => {
            message = message + item.message;
          });
        }
        Toast.fail(message, 2);
      }
    });
  };

  validateInjctRate = (rule: any, value: any, callback: any) => {
    if (isNum(this.props.form.getFieldsValue().injctRate) && this.props.form.getFieldsValue().injctRate > 0 && this.props.form.getFieldsValue().injctRate <= 100) {
      callback();
    } else {
      callback(new Error('0부터 100까지의 숫자를 입력해주세요.'));
    }
  };

  validatePjCode = (rule: any, value: any, callback: any) => {
    if (this.props.commonProjectMobileStore!.returnPjCode) {
      callback();
    } else {
      callback(new Error('프로젝트는 필수입력 값입니다.'));
    }
  };

  validateInjctYmd = (rule: any, value: any, callback: any) => {
    const injctYmd = moment(value).format('YYYYMMDD');
    if (moment(injctYmd, 'YYYYMMDD').isBefore(moment(), 'week')) {
      callback(new Error('마감되었습니다.'));
    } else {
      callback();
    }
  };

  renderValidationMessage = (messages: string[]) => {
    let messagesElements: JSX.Element[];
    messagesElements = messages.map(item => {
      return (
        <div>
          <Icon type="warning" /> {item}
        </div>
      );
    });
    return messagesElements;
  };

  render() {
    const { returnPjName } = this.props.commonProjectMobileStore!;
    const { getFieldProps, getFieldError } = this.props.form;

    return (
      <>
        <InputForm>
          <WhiteSpace size="lg" />
          {/* <List renderHeader={() => '투입일자'}> */}
          <List>
            <DatePicker
              mode="date"
              locale={enUs}
              title="투입일자 선택"
              extra="Optional"
              value={now}
              onChange={date => this.setState({ date })}
              {...getFieldProps('injctYmd', {
                initialValue: this.state.dpValue,
                rules: [{ required: true, message: '투입일자는 필수입력 값입니다.' }, { validator: this.validateInjctYmd }]
              })}
            >
              <List.Item>투입일자</List.Item>
            </DatePicker>
          </List>
          <WhiteSpace size="lg" />
          {/* <List renderHeader={() => '활동구분'}> */}
          <List>
            <Picker
              // data={actvTypeSample}
              data={this.state.commonCodeList}
              cols={1}
              value={this.state.sValue}
              okText="OK"
              dismissText="Cancel"
              onChange={v => this.setState({ sValue: v })}
              onOk={v => this.setState({ sValue: v })}
              {...getFieldProps('actvType', {
                initialValue: this.state.sValue,
                rules: [{ required: true, message: '활동구분은 필수입력 값입니다.' }]
              })}
            >
              <List.Item>활동구분</List.Item>
            </Picker>
          </List>
          <WhiteSpace size="lg" />
          {/* <List renderHeader={() => '투입프로젝트'}> */}
          <List>
            <InputItem
              {...getFieldProps('pjCode', {
                initialValue: returnPjName,
                rules: [{ validator: this.validatePjCode }]
              })}
              onClick={this.openProjectModal}
              value={returnPjName}
            >
              프로젝트
            </InputItem>
          </List>
          <WhiteSpace size="lg" />
          {/* <List renderHeader={() => '투입율'}> */}
          <List>
            <InputItem
              {...getFieldProps('injctRate', {
                initialValue: 100,
                rules: [{ required: true, message: '투입율은 필수입력 값입니다. ' }, { validator: this.validateInjctRate }]
              })}
              type="digit"
            >
              투입율
            </InputItem>
          </List>
        </InputForm>
        <ValidatorArea>
          <div>
            {getFieldError('injctYmd') ? <Icon type="warning" /> : null} {(getFieldError('injctYmd') || []).join('\r\n')}
          </div>
          <div>
            {getFieldError('actvType') ? <Icon type="warning" /> : null} {(getFieldError('actvType') || []).join('\r\n')}
          </div>
          <div>
            {getFieldError('pjCode') ? <Icon type="warning" /> : null} {(getFieldError('pjCode') || []).join('\r\n')}
          </div>
          <div>
            {getFieldError('injctRate') ? <Icon type="warning" /> : null} {(getFieldError('injctRate') || []).join('\r\n')}
          </div>
          <div>{this.renderValidationMessage(this.state.validationMessage)}</div>
        </ValidatorArea>
        <FooterButton>
          <Flex>
            <Flex.Item>
              <Link to="/m/timesheet/utilresult/calendar">
                <Button>취소</Button>
              </Link>
            </Flex.Item>
            <Flex.Item>
              <Button type="primary" onClick={this.onSubmit}>
                저장
              </Button>
            </Flex.Item>
          </Flex>
        </FooterButton>
        <CommonProjectMobileComponent actvType={this.state.sValue[0]} />
      </>
    );
  }
}

const WrappedRegMobileWrapperContainerForm = rcForm.createForm()(RegMobileWrapperContainer);
export default withRouter(WrappedRegMobileWrapperContainerForm);
