/* eslint-disable class-methods-use-this */
import ApiHelper from 'shared/utils/ApiHelper';

import { AxiosResponse } from 'axios';
import CalendarListModel from '../static/CalendarListModel';

const API_BASE = '/timesheet';

class CalendarListService {
  getYyyyList() {
    const api = `${API_BASE}/calendars/yyyy`;
    return ApiHelper.get(api);
  }

  getCalenderCreate(): any {
    const api = `${API_BASE}/calendars/create`;
    return ApiHelper.get(api);
  }

  getWorkTypeList(yyyy: string) {
    const api = `${API_BASE}/calendars/param?yyyy=${yyyy}`;
    return ApiHelper.get(api);
  }

  updateWorkTypeChange(CalendarList: CalendarListModel): any {
    const api = `${API_BASE}/work-type-changes`;
    return ApiHelper.put(api, CalendarList);
  }
}

export default new CalendarListService();
