import UserApiModel from './UserApiModel';

/**
 * User model for User Detail View
 */
class UserDetailModel {
  identityType: string;
  userType: string;
  affiliationId: string;
  isForeigner: boolean;
  isIncumbency: boolean;
  email: string;
  isFirstLogin: boolean;
  passwordChangeDate: string;
  userId: string;
  userName: string;
  timezone: string;
  timezoneOffset: number;
  mobilePhoneNo: string;
  classification: string;
  statusType: string;
  foreignerYn: boolean;
  officeYn: boolean;
  firstLoginYn: boolean;

  constructor(user: UserApiModel | object) {}
}
export default UserDetailModel;
