import { observable, action } from 'mobx';

import { message } from 'antd';
import CorporationConfigService from '../services/CorporationConfigService';
import CorporationConfigModel from '../static/CorporationConfigModel';
import { RootStore } from 'shared/stores/RootStore';

export default class CorporationConfigStore {
  @observable
  inProgress: boolean;

  @observable
  CorporationConfig: CorporationConfigModel;

  @observable
  dailyCheck: boolean;

  @observable
  weeklyCheck: boolean;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.CorporationConfig = {
      rowStatus: '',
      corpCode: '',
      closingDaily: '',
      closingWeekly: '',
      worktypeRegularPerform: '',
      worktypeRegularSales: '',
      worktypeRegularManagement: '',
      worktypeExecutive: '',
      worktypePjcontract: '',
      workCloseTime: '00:00:00'
    };

    this.dailyCheck = false;
    this.weeklyCheck = false;
    this.rootStore = rootStore;
  }

  // @action
  // setVisible(visible: boolean): void{
  //   this.visible = visible;
  // }

  @action
  async getCorpConf(): Promise<void> {
    this.inProgress = true;

    await CorporationConfigService.getCorpConf()
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.CorporationConfig = data;

        if (this.CorporationConfig.closingDaily === 'Y') {
          this.dailyCheck = true;
        }

        if (this.CorporationConfig.closingWeekly === 'Y') {
          this.weeklyCheck = true;
        }

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  updateCorpConf(): any {
    // 연장근로 등록
    this.inProgress = true;

    const CorporationConfigSave = this.CorporationConfig;

    // const CorporationConfigSave: CorporationConfigModel = {
    //   rowStatus : '',
    //   corpCode : CorporationConfig.corpCode,
    //   closingDaily : CorporationConfig.closingDaily,
    //   closingWeekly : CorporationConfig.closingWeekly,
    //   worktypeRegularPerform : CorporationConfig.worktypeRegularPerform,
    //   worktypeRegularSales : CorporationConfig.worktypeRegularSales,
    //   worktypeRegularManagement : CorporationConfig.worktypeRegularManagement,
    //   worktypeExecutive : CorporationConfig.worktypeExecutive,
    //   worktypePjcontract : CorporationConfig.worktypePjcontract,
    // };

    return CorporationConfigService.updateCorpConf(CorporationConfigSave)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        // const data = response.data.data || [];
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
        message.error('저장 중 문제가 발생하였습니다.');
      });
  }
}
