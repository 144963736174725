import { observable, action } from 'mobx';
import { RootStore } from 'shared/stores/RootStore';
import AppHelper from 'shared/utils/AppHelper';
import AppConst from 'shared/utils/AppConst';
import MailSendLogService from '../services/MailSendLogService';
import MailSendLogModel from '../static/MailSendLogModel';

export default class MailSendLogStore {
  @observable
  inProgress: boolean;

  @observable
  MailSendLogs: MailSendLogModel[];

  @observable
  MailSendLog: MailSendLogModel;

  @observable
  gridData: [];

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.MailSendLogs = [];
    this.gridData = [];
    this.rootStore = rootStore;
  }

  @action
  async getMailSendLog(empNo: string, empNm: string, mailSendYn: string, fromDt: string, toDt: string): Promise<void> {
    this.inProgress = true;
    await MailSendLogService.getMailSendLog(empNo, empNm, mailSendYn, fromDt, toDt)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        const formatDate = AppConst.DEFAULT_DATE_TIME_FORMAT;

        // reduce No. column + data type
        data.map((v: any, i: any) => {
          v.no = i + 1;
          v.regDate = AppHelper.parseTimestampToDate(v.regDate, formatDate);
          return null;
        });

        this.gridData = data;
        console.log(this.gridData);

        this.MailSendLogs = data;
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  async getMailReSend(seq: number): Promise<void> {
    this.inProgress = true;
    await MailSendLogService.getMailReSend(seq)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }
}
