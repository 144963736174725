class UseYnConfig {
  static ALL = 'ALL';
  static Y = 'Y';
  static N = 'N';
  static mappingApi(value: any) {
    switch (value) {
      case UseYnConfig.ALL:
        return null;
      case UseYnConfig.Y:
        return 'Y';
      case UseYnConfig.N:
        return 'N';
      default:
        return null;
    }
  }
}
export { UseYnConfig };
