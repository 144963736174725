import React from 'react';
import moment from 'moment';
import { Provider } from 'mobx-react';
import './App.less';
import Routes from 'Routes';
import { RootStore } from 'shared/stores/RootStore';

moment.locale('en');

export const rootStore = new RootStore();

const HRMApp = () => {

  // const {baseURI} = document;
  // console.log("referrer url : " + baseURI);
  // console.log("referrer url : " + document);
  // if(baseURI === 'http://test.metanet.co.kr:3000/'){
  //   window.location.replace('https://fa-eozb-saasfaprod1.fa.ocs.oraclecloud.com');
  // }else{
  //   return (
  //     <Provider {...rootStore}>
  //       <Routes />
  //     </Provider>
  //   );
  // }
  // return(<></>);
  
  return (
    <Provider {...rootStore}>
      <Routes />
    </Provider>
  );
  
};

export default HRMApp;
