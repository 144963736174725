const getParentKey = (deptCode: any, tree: any): any => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item: any) => item.valueCode === deptCode)) {
        parentKey = node.valueCode;
      } else if (getParentKey(deptCode, node.children)) {
        parentKey = getParentKey(deptCode, node.children);
      }
    }
  }
  return parentKey;
};
export default getParentKey;
