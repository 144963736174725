import ApiHelper from 'shared/utils/ApiHelper';
import PmGroupModel from 'shared/models/project/PmGroupModel';

const API_BASE = '/timesheet';

class PmGroupService {
  // Select All PM Groups
  getPmGroups() {
    const api = API_BASE + '/pmgroups';
    return ApiHelper.get(api);
  }

  // PM Group 에 속해 있지 않은 직원정보 조회
  getEmpList(pjCode: string, empName: string, deptCode: string) {
    const api = API_BASE + '/projects/' + pjCode + '/pmgroups/emps?empName=' + empName + '&deptCode=' + deptCode;
    return ApiHelper.get(api);
  }

  // Select PM Group By Project Code
  getPmGroupByPjCode(pjCode: String) {
    const api = API_BASE + '/projects/' + pjCode + '/pmgroups';
    return ApiHelper.get(api);
  }

  // Create PM Group
  createPmGroup(arr: PmGroupModel) {
    const api = API_BASE + '/pmgroups';
    return ApiHelper.post(api, arr);
  }

  // Update PM Group
  updatePmGroup(arr: PmGroupModel[]): any {
    const api = API_BASE + '/pmgroups';
    return ApiHelper.put(api, arr);
  }

  // Delete PM Group
  deletePmGroup(pjCode: String, empNo: String): any {
    const api = API_BASE + '/projects/' + pjCode + '/pmgroups/' + empNo;
    return ApiHelper.delete(api, []);
  }
}
export default new PmGroupService();
