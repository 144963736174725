import ApiHelper from 'shared/utils/ApiHelper';
import RepresentativeCodeModel from 'shared/models/code/RepresentativeCodeModel';
import AppConst from 'shared/utils/AppConst';

const API_BASE_REPRESENTCODE = AppConst.API.SYS_REPRESENTCODE;

class CodeManagementService {
  // eslint-disable-next-line class-methods-use-this
  getAllRepresentCodes() {
    return ApiHelper.get(AppConst.API.SYS_REPRESENTCODE);
  }

  insertRepresent = (arr: RepresentativeCodeModel[]) => {
    const api = API_BASE_REPRESENTCODE;
    arr.map((item: any) => {
      delete item.changeStatus;
      delete item.id;
    });
    return ApiHelper.post(api, arr);
  };

  updateRepresent = (arr: RepresentativeCodeModel[]) => {
    const api = API_BASE_REPRESENTCODE;
    arr.map((item: any) => {
      delete item.changeStatus;
      delete item.id;
    });
    return ApiHelper.put(api, arr);
  };

  // eslint-disable-next-line class-methods-use-this
  deleteRepresentItems(arr: any[]) {
    const api = API_BASE_REPRESENTCODE;
    return ApiHelper.delete(api, arr);
  }

  // eslint-disable-next-line class-methods-use-this
  exportExcelFile(representCode: string, representCodeName: string, commonCode: string, useYnText: string) {
    const trimedRepresentCode = representCode ? representCode.trim() : '';
    const trimedRepresentCodeName = representCodeName ? representCodeName.trim() : '';
    const trimedCommonCode = commonCode ? commonCode.trim() : '';
    const params = {
      representCode: trimedRepresentCode,
      representCodeName: trimedRepresentCodeName,
      commonCode: trimedCommonCode,
      useYn: useYnText === 'All' ? '' : useYnText
    };
    return ApiHelper.get(`${AppConst.API.SYS_REPRESENTCODE}/export`, params);
  }


  // eslint-disable-next-line class-methods-use-this
  findAllRepresentCode(representCode: string, representCodeName: string, commonCode: string, isUseValue: string) {
    const trimedRepresentCode = representCode ? representCode.trim() : '';
    const trimedRepresentCodeName = representCodeName ? representCodeName.trim() : '';
    const trimedCommonCode = commonCode ? commonCode.trim() : '';
    const params = {
      representCode: trimedRepresentCode,
      representCodeName: trimedRepresentCodeName,
      commonCode: trimedCommonCode,
      useYn: isUseValue !== AppConst.SELECT_ALL ? isUseValue : ''
    };
    return ApiHelper.get(AppConst.API.SYS_REPRESENTCODE, params);
  }
}

export default new CodeManagementService();
