import { AuthStore } from 'pages-mobile/login/stores/AuthStore';
import { ApiHandleStore } from './ApiHandleStore';
import { UIStore } from './UIStore';
import { CorporationStore } from 'pages-mobile/corporations/stores/CorporationStore';
import { UserInfoStore } from 'pages-mobile/user-info/stores/UserInfoStore';
import { MobileHeaderSidebarStore } from 'pages-mobile/header-sidebar/stores/MobileHeaderSidebarStore';
import { SystemLanguageStore } from './SystemLanguageStore';
import { HistoryMenuAccessStore } from './HistoryMenuAccessStore';

import UtilizationResultCalendarMobileStore from 'pages-mobile/utilization-result/stores/UtilizationResultCalendarMobileStore';
import UtilizationResultAppMobileStore from 'pages-mobile/utilization-result/stores/UtilizationResultAppMobileStore';
import CommonProjectMobileStore from 'pages-mobile/common/stores/CommonProjectMobileStore';
import OvertimeCalendarMobileStore from 'pages-mobile/overtime/stores/OvertimeCalendarMobileStore';
import OvertimeAppMobileStore from 'pages-mobile/overtime/stores/OvertimeAppMobileStore';

export class MobileRootStore {
  authStore: AuthStore;
  apiHandleStore: ApiHandleStore;
  uiStore: UIStore;
  corporationStore: CorporationStore;
  userInfoStore: UserInfoStore;
  mobileHeaderSidebarStore: MobileHeaderSidebarStore;
  systemLanguageStore: SystemLanguageStore;
  historyMenuAccessStore: HistoryMenuAccessStore;

  utilizationResultCalendarMobileStore: UtilizationResultCalendarMobileStore;
  utilizationResultAppMobileStore: UtilizationResultAppMobileStore;
  commonProjectMobileStore: CommonProjectMobileStore;
  overtimeCalendarMobileStore: OvertimeCalendarMobileStore;
  overtimeAppMobileStore: OvertimeAppMobileStore;

  constructor() {
    this.authStore = new AuthStore(this);
    this.apiHandleStore = new ApiHandleStore(this);
    this.uiStore = new UIStore(this);
    this.corporationStore = new CorporationStore(this);
    this.userInfoStore = new UserInfoStore(this);
    this.mobileHeaderSidebarStore = new MobileHeaderSidebarStore(this);
    this.systemLanguageStore = new SystemLanguageStore(this);
    this.historyMenuAccessStore = new HistoryMenuAccessStore(this);

    this.utilizationResultCalendarMobileStore = new UtilizationResultCalendarMobileStore(this);
    this.utilizationResultAppMobileStore = new UtilizationResultAppMobileStore(this);
    this.commonProjectMobileStore = new CommonProjectMobileStore(this);
    this.overtimeCalendarMobileStore = new OvertimeCalendarMobileStore(this);
    this.overtimeAppMobileStore = new OvertimeAppMobileStore(this);
  }
}
