import uuid from 'uuid';

class RepresentativeCodeModel {
  // ID of object in store, not ID of record from API.
  id: string;
  representCode: string;
  representCodeName: string;
  codeLength: number;
  useYn: string;
  remark: string;
  oldCode: string;
  regEmpNo: string;
  regEmpName: string;
  regDate: number;
  updatedEmpNo: string;
  updatedEmpName: string;
  updatedDate: number;
  changeStatus: string;

  constructor(obj: any, id?: string) {
    this.id = uuid.v4();
    this.representCode = obj.representCode;
    this.representCodeName = obj.representCodeName;
    this.codeLength = obj.codeLength;
    this.useYn = obj.useYn;
    this.remark = obj.remark;
    this.oldCode = obj.oldCode;
    this.regEmpNo = obj.regEmpNo;
    this.regEmpName = obj.regEmpName;
    this.regDate = obj.regDate;
    this.updatedEmpNo = obj.updatedEmpNo;
    this.updatedEmpName = obj.updatedEmpName;
    this.changeStatus = obj.changeStatus;
    this.updatedDate = obj.updatedDate;
    if (id) {
      // update case
      this.id = id;
    }
  }
}

export default RepresentativeCodeModel;
