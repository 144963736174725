import _ from 'lodash';
import AppConst from 'shared/utils/AppConst';

const getStatus = (objOriginal: any, newObj: any, changeStatusFieldName: string): string => {
  // changeStatusFieldName: some model use changeStatus, some model use status => need a string
  const objOrginalShallow = { ...objOriginal };
  objOrginalShallow[changeStatusFieldName] = undefined;
  const objNewShallow = { ...newObj };
  objNewShallow[changeStatusFieldName] = undefined;
  if (!objOriginal) {
    return AppConst.ActionType.CREATE;
  }
  if (_.isEqual(objOrginalShallow, objNewShallow)) {
    return AppConst.ActionType.READ;
  }
  return AppConst.ActionType.UPDATE;
};

export default getStatus;
