import ApiHelper from 'shared/utils/ApiHelper';

import { AxiosResponse } from 'axios';
import OverTimeModel from '../static/OverTimeModel';

const API_BASE = '/timesheet';

class OvertimeCalendarMobileService {
  getWeekNumber(date: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/calendars/${date}`;
    return ApiHelper.get(api);
  }

  getWeekCalendarList(weekNum: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/calendars/param?nowWorkSysYearMonth=${weekNum}`;
    return ApiHelper.get(api);
  }

  getWorkType(empNo: string, date: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/emps/${empNo}/work-type-changes/${date}`;
    return ApiHelper.get(api);
  }

  getMonthOverTime(workSysYearMonth: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/emps/me/calendar/${workSysYearMonth}/overtimes/monthly-list`;
    return ApiHelper.get(api);
  }

  createOverTime(overtime: OverTimeModel): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/overtimes`;
    return ApiHelper.post(api, overtime);
  }

  updateOverTime(overtime: OverTimeModel): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/overtimes`;
    return ApiHelper.put(api, overtime);
  }

  deleteOverTime(empNo: string, overTimeYmd: string, overTimeType: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/emps/${empNo}/overtimes/${overTimeYmd}/${overTimeType}`;
    return ApiHelper.delete(api, []);
  }

  getRecentlyProjectInfo() {
    const api = `${API_BASE}/utilization-leave-and-overtime-and-plan-and-results/project`;
    return ApiHelper.get(api);
  }
}

export default new OvertimeCalendarMobileService();
