import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import UtilizationResultAppMobileStore from '../stores/UtilizationResultAppMobileStore';
import { Checkbox, Flex, Modal, Button, Toast } from 'antd-mobile';
import moment from 'moment';

const { alert, prompt } = Modal;

const AgreeItem = Checkbox.AgreeItem;

interface MonthDrawerDataModel {
  yyyymmdd: string;
  empNo: string;
  empName: string | null;
  deptName: string | null;
  planSum: string;
  resultSum: string;
  gap: string;
}

interface Props {
  utilizationResultAppMobileStore?: UtilizationResultAppMobileStore;
}

const CheckboxWrapper = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
`;

const CardWrapper = styled.div`
  background-color: #ffffff;
  padding: 10px;
  margin-bottom: 10px;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 14px;
`;

const CardBody = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 14px;
  margin-top: 10px;
`;

const RenderPlanSum = styled.span`
  font-size: 20px;
  font-weight: 700;
  margin-left: 10px;
`;

const RenderResultSum = styled.span<{ gap?: string | null }>`
  color: ${(props: any): string => colorChooser(props.gap)};
  font-size: 20px;
  font-weight: 700;
  margin-left: 10px;
`;

const colorChooser = (gap: any): string => {
  if (Number(gap) > 0) return '#0000DB';
  if (Number(gap) < 0) return '#DB0000';
  return '#000000';
};

const ButtonWrapper = styled.div`
  /* margin-top: 10px; */
  display: flex;
  justify-content: flex-end;
`;

@inject('utilizationResultAppMobileStore')
@observer
class AppDetailMobileBodyContainer extends Component<Props> {
  state = { checked: false, tableDataList: [] };

  onChangeCheckBox = (e: any): void => {
    this.setState({ checked: e.target.checked });
    // let dataList: MonthDrawerDataModel[] = [];
    // if (e.target.checked) {
    //   dataList = this.props.utilizationResultAppMobileStore!.monthDrawerDataList.filter(item => item.planSum !== item.resultSum);
    // } else {
    //   dataList = this.props.utilizationResultAppMobileStore!.monthDrawerDataList;
    // }
    // this.setState({
    //   tableDataList: dataList
    // });
  };

  handleReject = (data: MonthDrawerDataModel, monthDrawerDataList: MonthDrawerDataModel[]): void => {
    // 당월이 마감상태라면 return 하는 로직 추가해야 함
    // if (this.props.utilizationResultAppMobileStore!.closingWeekly === 'Y') {
    //   if (moment(data.yyyymmdd, 'YYYYMMDD').isBefore(moment(), 'week')) {
    //     Toast.fail('마감되었습니다.', 2); // 주마감
    //     return;
    //   }
    // }
    this.props.utilizationResultAppMobileStore!.getClosingSts(data.yyyymmdd).then((response: any) => {
      if (response.closeSts === 'C' || response.closeSts === 'T') {
        Toast.fail('마감되었습니다.', 2); // 월마감
        return;
      }
    });

    this.props.utilizationResultAppMobileStore!.rejectUtilizationResult(data).then(() => {
      Toast.success('반려되었습니다.', 2);
      // this.setState({
      //   tableDataList: monthDrawerDataList // TODO 새로고침을 해야한다
      // });
    });
  };

  rendeRejectButton = (data: MonthDrawerDataModel, monthDrawerDataList: MonthDrawerDataModel[]): JSX.Element | null => {
    const closeSts = this.props.utilizationResultAppMobileStore!.closingSts.closeSts;
    if (closeSts !== 'C' && closeSts !== 'T' && Number(data.gap) > 0) {
      return (
        <Button
          type="primary"
          inline
          size="small"
          onClick={() => alert('가동실적 반려', '반려하시겠습니까?', [{ text: '취소' }, { text: '반려', onPress: () => this.handleReject(data, monthDrawerDataList) }])}
        >
          반려
        </Button>
      );
    }
    return null;
  };

  renderContents = (monthDrawerDataList: MonthDrawerDataModel[]): JSX.Element[] => {
    let elementList: JSX.Element[] = [];
    let dataList: MonthDrawerDataModel[] = [];
    // dataList = this.state.checked ? this.state.tableDataList : monthDrawerDataList;

    if (this.state.checked) {
      dataList = monthDrawerDataList.filter(item => item.planSum !== item.resultSum);
    } else {
      dataList = monthDrawerDataList;
    }

    dataList.forEach(item => {
      elementList.push(
        <CardWrapper>
          <CardHeader>
            <div>{item.deptName}</div>
            <div>{item.empNo}</div>
            <div>{item.empName}</div>
          </CardHeader>
          <CardBody>
            <div>
              계획
              <RenderPlanSum>{Number(item.planSum).toFixed()}</RenderPlanSum>
            </div>
            <div>
              실적
              <RenderResultSum gap={item.gap}>{Number(item.resultSum).toFixed()}</RenderResultSum>
            </div>
            <div>
              <ButtonWrapper>{this.rendeRejectButton(item, monthDrawerDataList)}</ButtonWrapper>
            </div>
          </CardBody>
        </CardWrapper>
      );
    });

    return elementList;
  };

  render() {
    const { monthDrawerDataList } = this.props.utilizationResultAppMobileStore!;

    return (
      <>
        <CheckboxWrapper>
          <Flex>
            <Flex.Item>
              <AgreeItem data-seed="logId" onChange={this.onChangeCheckBox}>
                변경내역만 보기
              </AgreeItem>
            </Flex.Item>
          </Flex>
        </CheckboxWrapper>
        <div>{this.renderContents(monthDrawerDataList)}</div>
      </>
    );
  }
}

export default AppDetailMobileBodyContainer;
