import ApiHelper from 'shared/utils/ApiHelper';
import AppConst from 'shared/utils/AppConst';
import DepartmentHistorySearchModel from 'shared/models/department/DepartmentHistorySearchModel';
import { ISearchParameter } from '../stores/DepartmentStore';
import { UseYnConfig } from '../config/configUseYn';
import { DepartmentModel } from 'shared/models/department/DepartmentModel';
import BaseRepository from 'shared/base/repositories/BaseRepository';

const API_DEPARTMENT = AppConst.API.SYS_DEPT;

class DepartmentService extends BaseRepository<DepartmentModel> {
  constructor() {
    super(API_DEPARTMENT);
  }

  searchDepartmentHistories = ({ deptCode }: DepartmentHistorySearchModel) => {
    const api = `${AppConst.API.SYS_DEPT_HISTORY}`;
    const params = {
      deptCode
    };
    return ApiHelper.get(api, params);
  };

  searchUpperDepartments = (params: any) => {
    const api = `${AppConst.API.SYS_DEPT_ROOT}`;
    return ApiHelper.get(api, params);
  };

  searchDepartments = ({ departmentName, departmentCode, useYn }: ISearchParameter) => {
    const api = `${API_DEPARTMENT}`;

    const trimDepartmentName = departmentName ? departmentName.trim() : '';
    const trimDepartmentCode = departmentCode ? departmentCode.trim() : '';

    const params = {
      ...(departmentName && {
        deptName: trimDepartmentName
      }),
      ...(departmentCode && {
        deptCode: trimDepartmentCode
      }),
      useYn: UseYnConfig.mappingApi(useYn)
    };

    return ApiHelper.get(api, params);
  };

  exportExcelFile = (params: ISearchParameter) => {
    // TODO: update with real API
    const api = AppConst.API.SYS_DEPT_EXPORT;
    return ApiHelper.get(api, {
      deptCode: params.departmentCode,
      deptName: params.departmentName,
      useYn: UseYnConfig.mappingApi(params.useYn)
    });
  };
}

export default new DepartmentService();
