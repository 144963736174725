import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Row, Col, Button, Input, Divider, Select } from 'antd';
import AppConst from 'shared/utils/AppConst';
import useStores from 'shared/hooks/useStores';
import EmployeeModel from 'shared/models/employee/EmployeeModel';
import ProjectModel from 'shared/models/project/ProjectModel';
import CommonProjectSearchComponent from 'pages/common/containers/CommonProjectSearchComponent';
import CommonSelectEmployeeDrawer from 'pages/common/CommonSelectEmployeeDrawer';
import { formEmployee } from 'pages/project-resource/static/formFields';

const ReducWorkManagementSearchContainer = observer(
  (): JSX.Element => {
    // state hooks
    const [empNo, setEmpNo] = useState<string>('');
    const [planYn, setPlanYn] = useState<string>('');
    const [resultYn, setResultYn] = useState<string>('');
    const [openEmpDrawer, setOpenEmpDrawer] = useState<boolean>(false);
    const [selectedEmpNo, setSelectedEmpNo] = useState<string>('');
    const [selectedEmpNm, setSelectedEmpNm] = useState<string>('');

    // mobx inject hook
    const { projectResourceStore, commonStore, employeeStore } = useStores();

    // 다국어 hook
    const { formatMessage } = useIntl();

    const onSearch = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
      e.preventDefault();
      projectResourceStore.getProjectResources(commonStore.returnPjCode, empNo, planYn, resultYn);
    };

    const onClearInput = (): void => {
      setEmpNo('');
      setSelectedEmpNo('');
      setSelectedEmpNm('');
    };

    // Employee Drawer 영역
    const handleShowEmpDrawer = () => {
      setOpenEmpDrawer(true);
    };

    const handleCloseEmpDrawer = () => {
      setOpenEmpDrawer(false);
    };

    const handleSearchConcurrentEmployee = (values: any) => {
      employeeStore.findConcurrentEmployeeByConditions(values, AppConst.API.SYS_AUTHORITY_EMP);
    };

    const handleCancelConcurrentEmployee = () => {
      handleCloseEmpDrawer();
    };

    const handleSetFieldValue = () => {
      const { selectedConcurrentEmployee } = employeeStore!;

      setEmpNo(selectedConcurrentEmployee.empNo);
      setSelectedEmpNo(selectedConcurrentEmployee.empNo);
      setSelectedEmpNm(selectedConcurrentEmployee.empName);
    };

    const handleSubmitConcurrentEmployee = (values: any) => {
      employeeStore!.setSelectedConcurrentEmployee(values as EmployeeModel);
      handleCloseEmpDrawer();
      handleSetFieldValue();
    };

    const handleChangePlanYn = (value: string) => {
      setPlanYn(value);
    };

    const handleChangeResultYn = (value: string) => {
      setResultYn(value);
    };

    const setInitProjectInfo = () => {
      const obj = new ProjectModel({
        pjCode: '',
        pjName: ''
      });
      commonStore.setReturnPjValue(obj);
    };

    useEffect(() => {
      setInitProjectInfo();
    });

    return (
      <>
        <CommonSelectEmployeeDrawer
          formFields={formEmployee}
          visibleCondition={openEmpDrawer}
          onSubmit={handleSubmitConcurrentEmployee}
          onSearch={handleSearchConcurrentEmployee}
          onCancel={handleCancelConcurrentEmployee}
        />
        <Form>
          <Row gutter={24} type="flex" align="middle" justify="start">
            <Col>
              <Form.Item label={formatMessage({ id: 'workhour.time.management.label.emp.no' })} style={{ marginBottom: 0 }} />
            </Col>
            <Col>
              <Input allowClear style={{ width: '100%' }} placeholder="Employee Name" value={selectedEmpNo} onChange={onClearInput} onClick={handleShowEmpDrawer} />
            </Col>
            <Col>
              <Form.Item label={formatMessage({ id: 'workhour.time.management.label.emp.name' })} style={{ marginBottom: 0 }} />
            </Col>
            <Col>
              <Input allowClear style={{ width: '100%' }} placeholder="Employee No" value={selectedEmpNm} onChange={onClearInput} onClick={handleShowEmpDrawer} />
            </Col>
            <Col>
              <CommonProjectSearchComponent labelYn nameYn searchYn />
            </Col>
          </Row>

          <Row gutter={24} type="flex" align="middle" justify="start">
            <Col>
              <Form.Item label={formatMessage({ id: 'project.resource.table.column.plan.yn' })} style={{ marginBottom: 0 }} />
            </Col>
            <Col>
              <Select defaultValue="" style={{ width: 120 }} onChange={handleChangePlanYn}>
                <Select.Option value="">
                  <FormattedMessage id="project.resource.label.all" />
                </Select.Option>
                <Select.Option value="Y">Y</Select.Option>
                <Select.Option value="N">N</Select.Option>
              </Select>
            </Col>
            <Col>
              <Form.Item label={formatMessage({ id: 'project.resource.table.column.result.yn' })} style={{ marginBottom: 0 }} />
            </Col>
            <Col>
              <Select defaultValue="" style={{ width: 120 }} onChange={handleChangeResultYn}>
                <Select.Option value="">
                  <FormattedMessage id="project.resource.label.all" />
                </Select.Option>
                <Select.Option value="Y">Y</Select.Option>
                <Select.Option value="N">N</Select.Option>
              </Select>
            </Col>

            <Col style={{ marginLeft: 'auto' }}>
              <Button icon="search" type="primary" onClick={onSearch}>
                <span>
                  <FormattedMessage id="button.title.search" />
                </span>
              </Button>
            </Col>
          </Row>
          <Divider />
        </Form>
      </>
    );
  }
);

export default ReducWorkManagementSearchContainer;
