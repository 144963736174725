import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import HRMBreadcrumb from 'components/HRMBreadcrumb';
import ReducWorkManagementSearchContainer from 'pages/reducwork/containers/ReducWorkManagementSearchContainer';
import ReducWorkManagementTableContainer from 'pages/reducwork/containers/ReducWorkManagementTableContainer';

const ReducWorkManagementView = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <>
      <HRMBreadcrumb urlPath={match.path} />
      <ReducWorkManagementSearchContainer />
      <ReducWorkManagementTableContainer />
    </>
  );
};

export default ReducWorkManagementView;
