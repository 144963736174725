import { FormattedMessage } from 'react-intl';
import React from 'react';

const columnsSelectedEmployee = [
  {
    title: <FormattedMessage id="employees.label.stt" />,
    dataIndex: 'no',
    key: 'no',
    width: '3%'
  },
  {
    title: <FormattedMessage id="employees.label.no" />,
    dataIndex: 'empNo',
    key: 'empNo',
    width: '7%'
  },
  {
    title: <FormattedMessage id="employees.label.name" />,
    dataIndex: 'empName',
    key: 'empName',
    width: '15%'
  },
  {
    title: <FormattedMessage id="employees.label.position.name" />,
    dataIndex: 'positName',
    key: 'positName',
    width: '15%'
  },
  {
    title: <FormattedMessage id="employees.label.department.name" />,
    dataIndex: 'deptName',
    key: 'deptName',
    width: '15%'
  }
];

export default columnsSelectedEmployee;
