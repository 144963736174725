import { action, observable } from 'mobx';
import UsersServices from 'pages/user-management/services/UserService';
import EmployeeModel from 'shared/models/employee/EmployeeModel';
import EmployeeCountModel from 'shared/models/user/EmployeeCountModel';
import AppHelper from 'shared/utils/AppHelper';
import LanguageHelper from 'shared/utils/LanguageHelper';
import { RootStore } from 'shared/stores/RootStore';
import StorageHelper from 'shared/utils/StorageHelper';
import AuthenticationService from 'pages/login/services/AuthenticationService';

export class UserInfoStore {
  rootStore: RootStore;

  @observable
  userInfoModel?: EmployeeCountModel;

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  @action
  findUserInfo() {
    // use value in Store later. Should not use directly from local storage
    const userId = StorageHelper.getItem(StorageHelper.KEY_USER_ID);

    if (!userId) {
      return true;
    }

    this.userInfoModel = new EmployeeCountModel({
      userName: '',
      userId: '',
      empList: Array<EmployeeModel>()
    });
    return UsersServices.findEmployees(userId)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const { data } = response.data;
        if(data) {
          const { userName } = data;
          const empList = data.empList || [];
          const validEmpList = empList.filter((empData: EmployeeModel) => {
            return empData.officeCode !== '2'; // For resigned Employees
          });

          this.userInfoModel = new EmployeeCountModel({
            userName: userName || '',
            userId: userId || '',
            empList: validEmpList
          });
        }
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  }

  @action
  changePassword = (userId: string, oldPassword: string, newPassword: string) => {
    return AuthenticationService.changePassword(userId, oldPassword, newPassword)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const { isSuccessful } = response.data.header;
        if (isSuccessful) {
          AppHelper.showMessage('success', LanguageHelper.getMessage('userinfo.message.change.password.done'));
          this.rootStore.authStore!.moveToLoginPage();
        }
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  };
}
