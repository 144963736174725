import _ from 'lodash';
import { action, observable, toJS } from 'mobx';
import ITableStore from 'shared/containers/MobXEditTable/ITableStore';
import EmployeeModel from 'shared/models/employee/EmployeeModel';
import EmployeeCountModel from 'shared/models/user/EmployeeCountModel';
import UserDetailModel from 'shared/models/user/UserDetailModel';
import UserModel from 'shared/models/user/UserModel';
import UserSearchModel from 'shared/models/user/UserSearchModel';
import { RootStore } from 'shared/stores/RootStore';
import AppConst from 'shared/utils/AppConst';
import AppHelper from 'shared/utils/AppHelper';
import LanguageHelper from 'shared/utils/LanguageHelper';
import uuid from 'uuid';
import UsersServices from '../services/UserService';

export class UserStore implements ITableStore<UserModel> {
  @observable
  user: UserDetailModel;

  @observable
  isDisplayLoading: boolean;

  rootStore: RootStore;
  @observable
  searchModel: UserSearchModel;

  @observable
  dataSource: UserModel[];

  @observable
  selectedItem: UserModel;

  @observable
  employeeCountModel: EmployeeCountModel;

  @observable
  employeeCountModelOfLoggedInUser: EmployeeCountModel;

  @observable
  lastSearch: any;

  @observable
  employeeDetail: EmployeeModel;

  selectedRowKeys: string[]; // For Password Reset action only

  /** Current selected row key */
  @observable
  selectedRowKey: string;

  constructor(rootStore: any) {
    this.rootStore = rootStore;
    this.searchModel = {
      userName: '',
      userId: '',
      sortCondition: ''
    };
    this.dataSource = [];
    this.employeeCountModel = new EmployeeCountModel({
      userName: '',
      userId: '',
      empList: Array<EmployeeModel>()
    });
    this.employeeCountModelOfLoggedInUser = new EmployeeCountModel({
      userName: '',
      userId: '',
      empList: Array<EmployeeModel>()
    });
    this.selectedRowKeys = [];
    this.lastSearch = null;
  }

  @action
  findByUsername(userName: string) {
    return UsersServices.fetchUserDetail(userName)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const { data } = response.data;
        this.user = data;
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  }

  @action
  exportExcelFile(userName: string, userId: string, userClassification: string, sortCondition: string) {
    return UsersServices.export(this.lastSearch)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const url = response.data.data.url || '';
        if (url && url !== '') {
          AppHelper.downloadExcel(url);
        }
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  }

  @action
  submit() {
    this.isDisplayLoading = true;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const _insert = this.dataSource.filter(x => x.changeStatus === AppConst.ActionType.CREATE);
    const _update = this.dataSource.filter(x => x.changeStatus === AppConst.ActionType.UPDATE);
    const _delete = this.dataSource.filter(x => x.changeStatus === AppConst.ActionType.DELETE);

    if (_insert.length === 0 && _update.length === 0 && _delete.length === 0) {
      AppHelper.showMessage('warning', LanguageHelper.getMessage('message.nothing.submit'));
      this.isDisplayLoading = false;
      return;
    }

    const _deleteIds = _delete.map(x => {
      return x.userId;
    });

    const promises: any[] = [];
    if (_insert.length > 0) {
      promises.push(UsersServices.createArray(_insert));
    }
    if (_update.length > 0) {
      promises.push(UsersServices.updateArray(_update));
    }
    if (_delete.length > 0) {
      promises.push(UsersServices.deleteArray(_deleteIds));
    }

    Promise.all(promises)
      .then(function submitData(responses: any[]) {
        self.rootStore.apiHandleStore.handleAllApiResult(responses);
        self.find();
      })
      .catch((error: any) => {
        console.error(error);
        this.isDisplayLoading = false;
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.isDisplayLoading = false;
      });
  }

  @action
  find() {
    this.isDisplayLoading = true;
    const { userName, userId, sortCondition } = this.searchModel;

    const myUser = Array<UserModel>();
    this.dataSource = Array<UserModel>();
    this.user = new UserDetailModel({});
    this.isDisplayLoading = true;
    const updatedUserName = userName ? userName.trim() : '';
    const updatedUserId = userId ? userId.trim() : '';
    const updatedSortCondition = sortCondition ? sortCondition.trim() : '';

    this.lastSearch = this.handleTrimValue(toJS(this.searchModel));

    this.setSelectedRowKeys([]);
    return UsersServices.findUsersByConditions(updatedUserName, updatedUserId, updatedSortCondition)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        const length = data ? data.length : 0;
        for (let index = 0; index < length; index++) {
          const element = data[index];
          element.id = uuid.v4();
          element.changeStatus = AppConst.ActionType.READ;
          myUser.push(element);
        }

        const myUserSort = myUser.sort(function(a, b) {
          return AppHelper.getStringSortResult(a.userId, b.userId, true);
        });
        myUserSort.map((v, i) => {
          v.no = i + 1;
        });
        this.dataSource = myUserSort;

        this.isDisplayLoading = false;
      })
      .catch(error => {
        this.isDisplayLoading = false;
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  }

  @action
  setSelectedItem(selectedItem: any) {
    this.selectedItem = selectedItem;
  }

  @action
  setDataSource(dataSource: UserModel[]) {
    this.dataSource = dataSource;
  }

  getDataSource() {
    return this.dataSource;
  }

  @action
  findEmployeeCount(userId: string) {
    this.employeeCountModel = new EmployeeCountModel({
      userName: '',
      userId: '',
      empList: Array<EmployeeModel>()
    });
    return UsersServices.findEmployees(userId)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const { data } = response.data;
        this.employeeCountModel =
          data ||
          new EmployeeCountModel({
            userName: '',
            userId: '',
            empList: Array<EmployeeModel>()
          });
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  }

  @action
  setSelectedRowKeys(selectedRowKeys: string[]) {
    this.selectedRowKeys = selectedRowKeys;
  }

  getSelectedRowKeys() {
    return this.selectedRowKeys;
  }

  @action
  resetPassword() {
    const selectedRowKeys = this.getSelectedRowKeys() || [];
    const arr: any[] = [];
    selectedRowKeys.forEach((id: string) => {
      const userRow = _.find(this.dataSource, { id });
      if (userRow) {
        arr.push({
          userName: userRow.userName,
          email: userRow.email
        });
      }
    });

    this.isDisplayLoading = true;
    return UsersServices.resetPassword(arr)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        this.isDisplayLoading = false;
        const { isSuccessful } = response.data.header;
        if (isSuccessful) {
          AppHelper.showMessage('success', LanguageHelper.getMessage('users.message.user.password.reset.done'));
        }
        this.find();
      })
      .catch((error: any) => {
        this.isDisplayLoading = false;
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  }

  handleTrimValue = (obj: any) => {
    Object.keys(obj).forEach((key: any) => {
      if (!obj[key]) {
        delete obj[key];
      } else {
        obj[key] = obj[key].trim();
      }
    });
    return obj;
  };

  @action
  removeBlankRow() {
    const currDataSource = this.dataSource.filter((item: any) => item.regDate > 0 || !_.isUndefined(item.userName));
    this.dataSource = currDataSource;
  }

  @action
  setCurrentSelectedRow(selectedRowKey: string) {
    this.selectedRowKey = selectedRowKey;
    const { dataSource } = this;
    dataSource.map((itemOrigin: any) => {
      if (itemOrigin.id === this.selectedRowKey) {
        itemOrigin.changeStatus = itemOrigin.changeStatus === 'DELETE' ? 'READ' : itemOrigin.changeStatus;
      }
    });
  }

  @action
  getEmployeeDetail = (employeeNo: string, corpCode: string) => {
    this.isDisplayLoading = true;
    return UsersServices.getEmployeeDetail(employeeNo, corpCode)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        this.isDisplayLoading = false;
        const { isSuccessful } = response.data.header;
        if (isSuccessful) {
          const { data } = response.data;
          this.employeeDetail = data || null;
        }
      })
      .catch((error: any) => {
        this.isDisplayLoading = false;
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  };
}
