import { action, computed, observable, toJS } from 'mobx';
import RoleModel from 'shared/models/role/RoleModel';
import { RootStore } from 'shared/stores/RootStore';
import AppHelper from 'shared/utils/AppHelper';
import { message } from 'antd';
import LanguageHelper from 'shared/utils/LanguageHelper';
import { isEqual } from 'lodash';
import AppConst from 'shared/utils/AppConst';
import RoleService from '../services/RoleService';

interface ISearchParameter {
  roleName?: string;
  deptCode?: string;
  useYn?: string;
}

export class RoleStore {
  private _originalRoles: RoleModel[] = []; // original items fetched from API
  rootStore: RootStore;

  @observable
  roles: RoleModel[] = [];

  @observable
  inProgress: boolean;

  // store uuid (FE side) of a role which prepare to be assigned a Department:
  @observable
  private _mapperDepartmentToRole?: string;

  // store uuid (FE side) of a role which prepare to be assigned a Menu:
  @observable
  mapperMenuToRole?: string;

  @observable
  searchParams: ISearchParameter;

  @observable
  lastSearch: any;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.lastSearch = null;
  }

  get originalRoles(): RoleModel[] {
    return this._originalRoles;
  }

  get mapperDepartmentToRole() {
    return this._mapperDepartmentToRole;
  }

  set mapperDepartmentToRole(recordUUID: any) {
    // recordUUID: uuid of role record
    if (!recordUUID) {
      this._mapperDepartmentToRole = undefined;
      return;
    }

    this.fetchDepartmentToAssign(recordUUID);
  }

  async fetchDepartmentToAssign(recordUUID: string) {
    // To fetch success department before show the Drawer
    // Condition to show drawer: this._mapperDepartmentToRole = recordUUID;
    const shouldFetchNew = !this.rootStore.departmentStore.departmentList.length && !!recordUUID;
    if (shouldFetchNew) {
      await this.rootStore.departmentStore.searchDepartments({});
    }

    this._mapperDepartmentToRole = recordUUID;
  }

  async fetchDepartmentForSearchPanel() {
    if (toJS(this.rootStore.departmentStore.departmentList.length) === 0) {
      await this.rootStore.departmentStore.searchDepartments({});
    }
  }

  @action
  getAllRoles() {
    const arrayRoles: RoleModel[] = [];
    this._originalRoles = [];
    this.inProgress = true;
    return RoleService.getAllRoles()
      .then(response => {
        const data = response.data.data || [];

        data.map((item: any) => {
          const role = new RoleModel();
          role.roleId = item.roleCode;
          role.name = item.roleName;
          role.status = item.rowStatus;
          role.deptCode = item.deptCode;
          role.deptName = item.deptName;
          role.remark = item.remark;
          role.useYn = item.useYn;
          role.regEmpNo = item.regEmpNo;
          role.regEmpName = item.regEmpName;
          role.regDate = item.regDate;
          role.updatedEmpNo = item.updatedEmpNo;
          role.updatedEmpName = item.updatedEmpName;
          role.updatedDate = item.updatedDate;
          arrayRoles.push(role);
          return true;
        });
        arrayRoles.sort((a: RoleModel, b: any) => {
          const aRoleIdTrim = Number(a.id.replace('R', ''));
          const bRoleIdTrim = Number(b.id.replace('R', ''));
          return bRoleIdTrim - aRoleIdTrim;
        });
        this.roles = arrayRoles;
        this._originalRoles = arrayRoles;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  @action
  async submitRolesData() {
    if (toJS(this.roles.length) > 0) {
      this.inProgress = true;
      const arrayToCreate: RoleModel[] = Array.from(this.roles).filter(obj => obj.status === 'CREATE');
      const arrayToUpdate: RoleModel[] = Array.from(this.roles).filter(obj => obj.status === 'UPDATE');
      const arrayToDelete: RoleModel[] = [];

      // use original data to push to API
      toJS(this.roles).map(obj => {
        if (obj.status === 'DELETE') {
          arrayToDelete.push(new RoleModel({ ...this._originalRoles.find(orginObj => orginObj.id === obj.id) }));
        }
        return true;
      });

      const callService = (arr: any[], caller: Function) =>
        caller(arr)
          .then((response: any) => {
            this.rootStore.apiHandleStore.handleApiFail(response.data.header);
          })
          .catch((error: any) => {
            this.rootStore.apiHandleStore.handleApiError(error);
          });

      if (arrayToCreate.length > 0) await callService(arrayToCreate, RoleService.createRoles);
      if (arrayToUpdate.length > 0) await callService(arrayToUpdate, RoleService.updateRoles);
      if (arrayToDelete.length > 0) await callService(arrayToDelete, RoleService.deleteRoles);
      this.getAllRoles();
      this.inProgress = false;
      message.info(LanguageHelper.getMessage('message.submit.done'));
    }
  }

  @action
  exportExcelFile() {
    // const updatedRoleName = this.searchParams.roleName ? this.searchParams.roleName.trim() : '';
    return RoleService.exportExcelFile(this.lastSearch)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const url = response.data.data.url || '';
        if (url && url !== '') {
          AppHelper.downloadExcel(url);
        }
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  }

  @action
  findRolesByConditions(searchParams: ISearchParameter) {
    const arrayRoles = Array<RoleModel>();
    const updatedRoleName = searchParams.roleName ? searchParams.roleName.trim() : '';
    this.lastSearch = searchParams;
    this.inProgress = true;
    return RoleService.getRoles(updatedRoleName, searchParams.deptCode!, searchParams.useYn!)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];

        data.map((item: any) => {
          const role = new RoleModel({
            roleId: item.roleCode,
            name: item.roleName,
            status: item.rowStatus,
            deptCode: item.deptCode,
            deptName: item.deptName,
            remark: item.remark,
            useYn: item.useYn,
            regEmpNo: item.regEmpNo,
            regEmpName: item.regEmpName,
            regDate: item.regDate,
            updatedEmpNo: item.updatedEmpNo,
            updatedEmpName: item.updatedEmpName,
            updatedDate: item.updatedDate,
            changeStatus: AppConst.ActionType.READ
          });
          arrayRoles.push(role);
          return true;
        });
        this.roles = arrayRoles;
        this._originalRoles = arrayRoles;
        this.searchParams = searchParams;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  @computed
  get dataSource(): any[] {
    const dataSource: any[] = [];

    this.roles.map(obj => {
      dataSource.push({ ...obj });
      return null;
    });

    dataSource
      .filter(obj => obj.changeStatus !== AppConst.ActionType.CREATE)
      .sort(function(a, b) {
        return AppHelper.getStringSortResult(a.roleId, b.roleId, true);
      });
    dataSource.map((v, i) => {
      v.no = i + 1;
      return true;
    });

    return Object.assign([], dataSource);
  }

  @action
  reset() {
    this.roles = [];
    this._originalRoles = [];
    this.rootStore.departmentStore.departmentList = [];
  }

  @action
  clearSelectedDepartment(id: string) {
    const oriRole = this._originalRoles.find(obj => obj.id === id);
    const idxRole = this.roles.findIndex(obj => obj.id === id);
    if (oriRole && idxRole > -1) {
      const newObj = new RoleModel({
        ...this.roles[idxRole]
      });
      newObj.id = this.roles[idxRole].id;
      newObj.deptCode = null;
      newObj.deptName = null;
      newObj.status = null;

      if (!isEqual(oriRole, newObj)) {
        // if edit others fields
        newObj.status = 'UPDATE';
      }

      // change
      this.roles[idxRole] = newObj;
    }
  }

  @action
  updateDepartmentOfSelectedRole(deptUUID?: string) {
    const objDepartment = this.rootStore.departmentStore.departmentList.find(obj => obj.id === deptUUID);
    const idxRole = this.roles.findIndex(obj => obj.id === this._mapperDepartmentToRole);
    if (!(deptUUID && objDepartment && idxRole >= 0)) return;

    // setup
    const newObj = new RoleModel({
      ...this.roles[idxRole]
    });
    newObj.id = this.roles[idxRole].id;
    newObj.deptCode = objDepartment.deptCode;
    newObj.deptName = objDepartment.deptName;

    const isUpdateCase = !!this._originalRoles.find(obj => obj.id === this._mapperDepartmentToRole);
    newObj.status = isUpdateCase ? 'UPDATE' : 'CREATE';

    // change
    this.roles[idxRole] = newObj;
  }

  @action
  removeRoles(deleteIds: string[]) {
    this.roles.map((obj: RoleModel, i: number) => {
      if (deleteIds.includes(obj.id)) {
        const isNewRole = !this._originalRoles.find(role => role.id === obj.id);

        if (isNewRole) {
          this.roles.splice(i);
        } else {
          this.roles[i] = new RoleModel(
            {
              ...toJS(this.roles[i]),
              status: 'DELETE'
            },
            obj.id
          );
        }
      }
      return true;
    });
  }

  @computed
  get selectedDepartment() {
    return this.rootStore.departmentStore.selectedDept;
  }
}
