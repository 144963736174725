/**
 * PM GROUP  model for View
 */
class PmGroupModel {
  no: number;
  corpCode: string;
  deptCode: string;
  deptName: string
  empNo: string;
  empName: string;
  positName: string;
  pjCode: string;
  role: string;
  regEmpNo: string;
  regDate: string;
  updatedEmpNo: string;
  updatedDate: string;

  constructor(obj: any) {
    this.no = obj.no;
    this.corpCode = obj.corpCode;
    this.deptCode = obj.deptCode;
    this.deptName = obj.deptName;
    this.empNo = obj.empNo;
    this.empName = obj.empName;
    this.positName = obj.positName;
    this.pjCode = obj.pjCode;
    this.role = obj.role;
    this.regEmpNo = obj.regEmpNo;
    this.regDate = obj.regDate;
    this.updatedEmpNo = obj.updatedEmpNo;
    this.updatedDate = obj.mainSalesDept;
  }
}
export default PmGroupModel;
