import ApiHelper from 'shared/utils/ApiHelper';
import AppConst from 'shared/utils/AppConst';
import BaseRepository from 'shared/base/repositories/BaseRepository';
import UserModel from 'shared/models/user/UserModel';

const API_BASE = AppConst.API.SYS_USER;
class UserService extends BaseRepository<UserModel> {
  constructor() {
    super(AppConst.API.SYS_USER);
  }

  fetchUserDetail(userName: string) {
    const api = `${API_BASE}/${userName}`;
    return ApiHelper.get(api);
  }

  findUsersByConditions(userName: string, userId: string, sortCondition: string) {
    const params = {
      userId,
      userName,
      sortBy: sortCondition
    };
    return ApiHelper.get(AppConst.API.SYS_USER, params);
  }

  findEmployees(userId: string) {
    const params = {
      userId
    };
    return ApiHelper.get(`${AppConst.API.SYS_USER}/empList`, params);
  }
}

export default new UserService();
