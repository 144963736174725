import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-scroll';
import moment from 'moment';
import OverTimeMonthModel from '../static/OverTimeMonthModel';

interface Props {
  overTimeMonthList: OverTimeMonthModel[];
  waypointDate: any;
  horizontalHandleClick?: (a: any) => void;
}

const WrapperHCalendar = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const DaySpan = styled.span<{ weekend?: any; waypointDate?: any }>`
  padding: 10px;
  margin: 5px;
  /* display: inline; */
  font-weight: 600;
  color: ${props => (props.waypointDate ? '#ffffff' : props.weekend === 'Y' ? '#e50505' : '#000000')};
  background-color: ${props => (props.waypointDate ? '#00adf5' : '')};
  width: 45px;
  height: 40px;
  border-radius: 20px;
`;

@inject('utilizationResultCalendarMobileStore')
@observer
export default class CalendarMobileHorizontalComponent extends Component<Props> {
  renderDate = (overTimeMonthList: OverTimeMonthModel[], waypointDate: any, horizontalHandleClick: (a: any) => void) => {
    const dayComponent = overTimeMonthList.map((item: any) => {
      return (
        <Link
          activeClass="active"
          className={moment(item.yyyymmdd, 'YYYYMMDD').format('MMDD')}
          // to={moment(item.yyyymmdd, 'YYYYMMDD').format('MMDD')}
          to={`v${moment(item.yyyymmdd, 'YYYYMMDD').format('MMDD')}`}
          spy={true}
          smooth={true}
          duration={500}
          key={'h' + item.yyyymmdd}
        >
          <DaySpan
            weekend={item.holidayYn}
            onClick={() => horizontalHandleClick!(moment(item.yyyymmdd, 'YYYYMMDD').format('MMDD'))}
            waypointDate={waypointDate === moment(item.yyyymmdd, 'YYYYMMDD').format('MMDD')}
            id={'h' + moment(item.yyyymmdd, 'YYYYMMDD').format('MMDD')}
            key={'h' + item.yyyymmdd}
          >
            {moment(item.yyyymmdd, 'YYYYMMDD').format('DD')}
          </DaySpan>
        </Link>
      );
    });
    return dayComponent;
  };

  render() {
    const { overTimeMonthList, waypointDate, horizontalHandleClick } = this.props;
    return (
      <>
        <WrapperHCalendar>{this.renderDate(overTimeMonthList, waypointDate, horizontalHandleClick!)}</WrapperHCalendar>
      </>
    );
  }
}
