import React, { Component } from 'react';
import { Button, List, Picker, Toast } from 'antd-mobile';
import enUS from 'antd/es/locale/en_US';
import koKR from 'antd/es/locale/ko_KR';
import EmployeeCountModel from 'shared/models/user/EmployeeCountModel';
import StorageHelper from 'shared/utils/StorageHelper';
import EmployeeModel from 'shared/models/employee/EmployeeModel';
import { inject, observer } from 'mobx-react';
import { UIStore } from 'shared/mobile-stores/UIStore';
import AppConst from 'shared/utils/AppConst';
import MobileLanguageHelper from 'shared/utils/MobileLanguageHelper';
import { FormattedMessage } from 'react-intl';
import { AuthStore } from '../../login/stores/AuthStore';
import { MobileHeaderSidebarStore } from '../../header-sidebar/stores/MobileHeaderSidebarStore';

interface IProps {
  userData: EmployeeCountModel;
  authStore?: AuthStore;
  uiStore?: UIStore;
  history: any;
  mobileHeaderSidebarStore?: MobileHeaderSidebarStore;
}

@inject('authStore', 'uiStore', 'mobileHeaderSidebarStore')
@observer
class UserInfo extends Component<IProps, any> {
  logout = () => {
    this.props.authStore!.logout();
  };

  onChangeCorporation = (value: any) => {
    const currCorpCode = StorageHelper.getItem(StorageHelper.KEY_CURR_CORP_CODE);
    if (value[0] === currCorpCode) {
      return;
    }
    this.props.authStore!.setSelectedCorp(value[0] || '', true).then(() => {
      if (StorageHelper.getItem(StorageHelper.KEY_CURR_CORP_CODE)) {
        Toast.success(MobileLanguageHelper.getMessage('userinfo.message.change.corporation.done'), 1);
        this.props.history.push('/m/dashboard');
      }
    });
  };

  onChangeLanguage = (value: any) => {
    this.props.uiStore!.setCurrentLanguage(value[0] === AppConst.LANG_EN ? enUS : koKR);
  };

  render() {
    StorageHelper.setItem(StorageHelper.KEY_CURR_LANGUAGE, this.props.uiStore!.currLanguage.locale === AppConst.LANG_EN ? AppConst.LANG_ENUS : AppConst.LANG_KOKR);
    const { userData } = this.props;
    const currCorpCode = StorageHelper.getItem(StorageHelper.KEY_CURR_CORP_CODE);
    const empInfo = userData.empList.find((x: any) => x.corpCode === currCorpCode) || new EmployeeModel({});
    const corpData = userData.empList.length ? userData.empList.map((x: any) => ({ value: x.corpCode, label: x.corpName })) : [];
    return (
      <>
        <div className="user-avatar">
          <img width="100%" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSa-3VfK6P9EvxVcVJ1u_Mc2ajx3eQ3mcEpPFJ7j-vNy8D6yEsz&s" alt="avatar" />
        </div>
        <h2>
          {/* {userData.userId} {userData.userName} */}
          {userData.userName} {empInfo.empName}
        </h2>
        <p style={{ textAlign: 'center' }}>{empInfo.deptName}</p>
        <p style={{ textAlign: 'center' }}>{empInfo.corpName}</p>
        <div className={`picker corporation ${corpData.length === 1 ? 'disabled' : ''}`}>
          <p>{MobileLanguageHelper.getMessage('userinfo.change.corporation')}</p>
          <Picker
            data={corpData}
            cols={1}
            value={[empInfo.corpCode]}
            onOk={this.onChangeCorporation}
            disabled={corpData.length === 1}
            okText={<FormattedMessage id="button.title.ok" />}
            dismissText={<FormattedMessage id="button.title.cancel" />}
          >
            <List.Item arrow="empty" />
          </Picker>
        </div>
        <div className="picker language">
          <p>{MobileLanguageHelper.getMessage('userinfo.language')}</p>
          <Picker
            data={[{ label: '한국어', value: 'ko' }, { label: 'English', value: 'en' }]}
            onOk={this.onChangeLanguage}
            cols={1}
            value={[this.props.uiStore!.currLanguage.locale]}
            okText={<FormattedMessage id="button.title.ok" />}
            dismissText={<FormattedMessage id="button.title.cancel" />}
          >
            <List.Item arrow="empty" />
          </Picker>
        </div>
        <Button type="primary" inline className="btn-logout" onClick={this.logout}>
          {MobileLanguageHelper.getMessage('common.text.logout')}
        </Button>
      </>
    );
  }
}

export default UserInfo;
