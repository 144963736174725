import _ from 'lodash';
import { action, observable, toJS } from 'mobx';
import ProgramService from 'pages/program-management/services/ProgramService';
import ITableStore from 'shared/containers/MobXEditTable/ITableStore';
import APIAssignmentModal from 'shared/models/program/APIAssignmentModal';
import ProgramModel from 'shared/models/program/ProgramModel';
import ProgramSearchModel from 'shared/models/program/ProgramSearchModel';
import { RootStore } from 'shared/stores/RootStore';
import AppConst from 'shared/utils/AppConst';
import AppHelper from 'shared/utils/AppHelper';
import LanguageHelper from 'shared/utils/LanguageHelper';
import uuid from 'uuid';
import MenuAssignedService from 'shared/services/MenuAssignedService';

export class ProgramStore implements ITableStore<ProgramModel> {
  @observable
  dataSource: ProgramModel[];

  rootStore: RootStore;
  @observable
  searchModel: ProgramSearchModel;

  @observable
  inProgress: boolean;

  @observable
  selectedItem: ProgramModel;

  @observable
  assignedApi: APIAssignmentModal[];

  @observable
  unAssignedApi: APIAssignmentModal[];

  @observable
  listApi: APIAssignmentModal[];

  @observable
  lastSearch: any;

  selectedRowKeys: string[];

  @observable
  selectedRowKey: string;

  constructor(rootStore: any) {
    this.rootStore = rootStore;
    this.searchModel = {
      progCode: '',
      progName: '',
      progFileName: '',
      mediumClass: AppConst.SELECT_ALL,
      largeClass: AppConst.SELECT_ALL
    };
    this.dataSource = [];
    this.assignedApi = [];
    this.unAssignedApi = [];
    this.listApi = [];
    this.lastSearch = null;
  }

  @action
  exportExcelFile() {
    return ProgramService.export(this.lastSearch)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const url = response.data.data.url || '';
        if (url && url !== '') {
          AppHelper.downloadExcel(url);
        }
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  }

  @action
  submit() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this.inProgress = true;
    const insertItems = this.dataSource.filter(x => x.changeStatus === AppConst.ActionType.CREATE);
    const updateItems = this.dataSource.filter(x => x.changeStatus === AppConst.ActionType.UPDATE);
    const deleteItems = this.dataSource.filter(x => x.changeStatus === AppConst.ActionType.DELETE);

    if (insertItems.length === 0 && updateItems.length === 0 && deleteItems.length === 0) {
      AppHelper.showMessage('warning', LanguageHelper.getMessage('message.nothing.submit'));
      this.inProgress = false;
      return;
    }

    const promises: any[] = [];
    if (insertItems.length > 0) {
      promises.push(ProgramService.createArray(insertItems));
    }
    if (updateItems.length > 0) {
      promises.push(ProgramService.updateArray(updateItems));
    }
    if (deleteItems.length > 0) {
      const deleteIds = deleteItems.map(x => {
        return x.progCode;
      });
      promises.push(ProgramService.deleteArray(deleteIds));
    }

    Promise.all(promises)
      .then((responses: any[]) => {
        self.rootStore.apiHandleStore.handleAllApiResult(responses);
        // NO NEED .find() again because we will refresh whole page later
      })
      .catch((error: any) => {
        this.inProgress = false;
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
        this.getLatestDataAfterChangeProgram();
      });
  }

  @action
  getLatestDataAfterChangeProgram = async () => {
    const rs = await MenuAssignedService.getListAssignedMenu();
    const { isSuccessful } = rs.data.header;
    const currentMenuCode = this.rootStore.menuAssignImplementStore.selectedProgramViewMenu!.menuCode;

    if (!isSuccessful) {
      this.rootStore.apiHandleStore.handleApiFail(rs.data.header);
      return;
    }
    const data = rs.data.data || [];
    const objCurrentMenu = data.find((obj: any) => obj.menuCode === currentMenuCode);
    if (objCurrentMenu) {
      this.rootStore.routeStore.hardRefreshToPath(`/${objCurrentMenu.progPath}`);
    }
  };

  @action
  find() {
    this.inProgress = true;
    const { progCode, progName, progFileName, mediumClass, largeClass } = this.searchModel;

    const myPrograms = Array<ProgramModel>();
    this.dataSource = Array<ProgramModel>();

    this.inProgress = true;
    const actualMediumClassValue = mediumClass !== AppConst.SELECT_ALL ? mediumClass : '';
    this.lastSearch = this.searchModel;

    return ProgramService.findByConditions(largeClass, actualMediumClassValue, _.trim(progCode), _.trim(progName), _.trim(progFileName))
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        const length = data ? data.length : 0;

        for (let index = 0; index < length; index += 1) {
          const element = data[index];
          element.id = uuid.v4();
          element.changeStatus = AppConst.ActionType.READ;
          myPrograms.push(element);
        }
        const myProgramsSort = myPrograms.sort(function(a, b) {
          return AppHelper.getStringSortResult(a.progCode, b.progCode, true);
        });
        myProgramsSort.map((v, i) => {
          v.no = i + 1;
        });
        this.dataSource = myProgramsSort;
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.inProgress = false;
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  }

  @action
  apllyResponseApiAssignment = (data: any, chosen: boolean) => {
    const arrayApi: APIAssignmentModal[] = [];

    data.map((item: any) => {
      const api = new APIAssignmentModal();
      api.apiAuthCode = item.apiAuthCode;
      api.apiName = item.apiName;
      api.apiPath = item.apiPath;
      api.chosen = chosen;
      arrayApi.push(api);
      return arrayApi;
    });
    return arrayApi;
  };

  @action
  getApiAssignment(progCode: string) {
    this.inProgress = true;
    this.assignedApi = Array<APIAssignmentModal>();
    this.unAssignedApi = Array<APIAssignmentModal>();
    return ProgramService.getApiAssignment(progCode)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const arrayAssignedApi = this.apllyResponseApiAssignment(response.data.data.assignedApi || [], true);
        const arrayUnAssignedApi = this.apllyResponseApiAssignment(response.data.data.unAssignedApi || [], false);
        this.assignedApi = arrayAssignedApi;
        this.unAssignedApi = arrayUnAssignedApi;
        this.listApi = this.assignedApi.concat(this.unAssignedApi);
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.inProgress = false;
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  }

  @action
  updateAssignedApiAprogram = (listApi: APIAssignmentModal[]) => {
    const updateAssignedApi: APIAssignmentModal[] = [];
    const updateUnAssignedApi: APIAssignmentModal[] = [];
    listApi.map((api: APIAssignmentModal) => {
      if (api.chosen) {
        updateAssignedApi.push(api);
      } else {
        updateUnAssignedApi.push(api);
      }
      return listApi;
    });
    this.assignedApi = updateAssignedApi;
    this.unAssignedApi = updateUnAssignedApi;
  };

  @action
  setSelectedItem(selectedItem: any) {
    this.selectedItem = selectedItem;
  }

  @action
  setDataSource(dataSource: ProgramModel[]) {
    this.dataSource = dataSource;
  }

  getDataSource() {
    return this.dataSource;
  }

  @action
  setSelectedRowKeys(selectedRowKeys: string[]) {
    this.selectedRowKeys = selectedRowKeys;
  }

  @action
  removeBlankRow() {
    const currDataSource = this.dataSource.filter((program: any) => !_.isUndefined(program.progCode) || !_.isUndefined(program.progName));
    this.dataSource = currDataSource;
  }

  @action
  setCurrentSelectedRow(selectedRowKey: string) {
    this.selectedRowKey = selectedRowKey;
    const { dataSource } = this;
    dataSource.map((itemOrigin: any) => {
      if (itemOrigin.id === this.selectedRowKey) {
        itemOrigin.changeStatus = itemOrigin.changeStatus === 'DELETE' ? 'READ' : itemOrigin.changeStatus;
      }
    });
  }
}
