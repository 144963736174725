import IBaseModel from 'shared/base/models/IBaseModel';
import AppConst from 'shared/utils/AppConst';
import AppHelper from 'shared/utils/AppHelper';

class MenuModel implements IBaseModel {
  id: string;
  changeStatus: string;
  corpCode?: string;
  displayOrder: number;
  endDate: number;
  languages: any;
  level: number;
  menuCode: string;
  menuName: string;
  menuNameEnglish: string;
  menuPath: string;
  menuType: string;
  persInfoUseYn: boolean;
  progCode: string;
  progFileName: string;
  progName: string;
  progPath: string;
  rowStatus: string;
  startDate: number;
  upperMenuName: string;
  upperMenuCode: string;
  useYn: string;
  mobileYn: string;
  userData01: string;
  userData02: string;
  userData03: string;
  authType?: string;
  disabled?: boolean;

  [key: string]: any;

  constructor(obj: any | MenuModel) {
    this.id = obj.id;
    this.corpCode = obj.corpCode;
    this.displayOrder = obj.displayOrder;
    this.endDate = obj.endDate || AppConst.DEFAULT_UNIX_END_DATE;
    this.languages = obj.languages;
    this.level = obj.level;
    this.menuCode = obj.menuCode;
    this.menuName = obj.menuName;
    this.menuNameEnglish = obj.menuNameEnglish;
    this.menuPath = obj.menuPath;
    this.menuType = obj.menuType;
    this.persInfoUseYn = obj.persInfoUseYn;
    this.progCode = obj.progCode;
    this.progName = obj.progName;
    this.progFileName = obj.progFileName;
    this.progPath = obj.progPath;
    this.rowStatus = obj.rowStatus;
    this.startDate = obj.startDate || AppHelper.getTodayInTimestampFormat();
    this.upperMenuCode = obj.upperMenuCode;
    this.upperMenuName = obj.upperMenuName;
    this.useYn = obj.useYn;
    this.mobileYn = obj.mobileYn;
    this.userData01 = obj.userData01;
    this.userData02 = obj.userData02;
    this.userData03 = obj.userData03;
    this.changeStatus = obj.changeStatus;
  }
}
export default MenuModel;
