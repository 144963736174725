import LoadingFullScreen from 'components/LoadingFullScreen';
import { createBrowserHistory } from 'history';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import MultiLanguageProvider from 'MultiLanguageProvider';
import CorporationsView from 'pages/corporations/views/CorporationsView';
import DashboardView from 'pages/dashboard/index';
import NotFoundPage from 'pages/errors/NotFoundPage';
import LoginView from 'pages/login/views/LoginView';
import LoginSsoView from 'pages/login-sso/views/LoginSsoView';
import PasswordFinderView from 'pages/password-finder/views/PasswordFinderView';
import PasswordResetView from 'pages/password-reset/views/PasswordResetView';
import PasswordChangeView from 'pages/password-change/views/PasswordChangeView';
import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import LayoutGenerator from 'shared/layouts/LayoutGenerator';
import { AuthStore } from 'shared/stores/AuthenticationStore';
import { MenuAssignImplementStore } from 'shared/stores/MenuAssignImplementStore';
import { RouteStore } from 'shared/stores/RouteStore';
import OvertimeMonthApprDetailView from 'pages/overtime/views/OvertimeMonthApprDetailView';
import OvertimeMonthStateDetailView from 'pages/overtime/views/OvertimeMonthStateDetailView';
import ReducWorkManagementView from 'pages/reducwork/views/ReducWorkManagementView';
import { bucketRegisteredComponentWithPath } from './RoutesAuthorization';
import ProjectResourceView from 'pages/project-resource/views/ProjectResourceView';

interface IProps {
  menuAssignImplementStore?: MenuAssignImplementStore;
  authStore?: AuthStore;
  routeStore?: RouteStore;
}
export const history = createBrowserHistory();
@inject('authStore', 'routeStore', 'menuAssignImplementStore')
@observer
class MTNRouter extends React.Component<IProps> {
  componentDidMount() {
    this.props.routeStore!.setFirstAccessLocationPathname(history.location.pathname);
    this.props.routeStore!.storeHistory = history;

    this.props.authStore!.fetchDataWhenUserNeedWhileUsing();
  }

  render() {
    const { withSidebar, withNoSidebar, blankLayout } = LayoutGenerator;
    const listRouteFromService = toJS(this.props.menuAssignImplementStore!.menuAssignedList);
    const isAuthorizationStoreProgressing = this.props.menuAssignImplementStore!.inProgress;

    // logged mean user already finished logged in progress including select coporattion
    const isUserLogged = !!this.props.authStore!.isLogged;

    return (
      <Router history={history}>
        <Switch>
          {/* TODO: should handle logic login-> dashboard in RouteStore */}
          <Route exact path="/" component={!isUserLogged ? blankLayout(LoginView) : withNoSidebar(DashboardView)} />
          <Route exact path="/loginSso" component={!isUserLogged ? blankLayout(LoginSsoView) : withNoSidebar(DashboardView)} />
          <Route exact path="/passwordfinder" component={PasswordFinderView} />
          <Route exact path="/reset/password" component={PasswordResetView} />
          <Route exact path="/corporations" component={CorporationsView} />
          <Route exact path="/dashboard" component={!isUserLogged ? blankLayout(LoginView) : withNoSidebar(DashboardView)} />
          <Route exact path="/change/password" component={PasswordChangeView} />

          <Route path="/timesheet/overtime/overtime/overtimeappdetail" render={withSidebar(OvertimeMonthApprDetailView)} />
          <Route path="/timesheet/overtime/overtime/overtimestsdetail" render={withSidebar(OvertimeMonthStateDetailView)} />
          <Route path="/timesheet/configmngt/corpconfig/reducworkmngt" render={withSidebar(ReducWorkManagementView)} />
          <Route path="/timesheet/project/project/projectresource" render={withSidebar(ProjectResourceView)}/>

          {listRouteFromService.map(v => {
            const { progFileName: pathFromService, progPath: pathRoute, progUseYn } = v;
            const Component = bucketRegisteredComponentWithPath.get(pathFromService);
            if (Component && progUseYn === 'Y') {
              return <Route exact path={pathRoute} render={withSidebar(Component)} key={Math.random()} />;
            }
            const NoFoundComponent = () => {
              return null;
            };
            return <Route exact path={pathRoute} render={withSidebar(NoFoundComponent)} key={Math.random()} />;
          })}

          {/* when user not logged in yet but access other page => redirect to Login page */}
          {!isUserLogged && <Redirect to="/" />}

          {!isAuthorizationStoreProgressing && <Route render={blankLayout(NotFoundPage)} />}
        </Switch>
        <MultiLanguageProvider>
          <LoadingFullScreen shouldShow={isAuthorizationStoreProgressing} />
        </MultiLanguageProvider>
      </Router>
    );
  }
}
export default MTNRouter;
