import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import background from 'images/metanet-background.jpg';

import WrappedLoginFormContainer from 'pages/login/containers/LoginFormContainer';

const LoginPage = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
`;

const WrapLoginForm = styled.div`
  width: 350px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
class LoginView extends React.PureComponent {
  render() {
    const backgroundStyle: any = {
      background: '50% 50% / cover no-repeat',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundImage: `url(${background})`
    };
    return (
      <LoginPage>
        <b style={{ ...backgroundStyle }} />
        <WrapLoginForm>
          <WrappedLoginFormContainer {...this.props} />
        </WrapLoginForm>
      </LoginPage>
    );
  }
}

export default LoginView;
