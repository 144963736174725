import ApiHelper from 'shared/utils/ApiHelper';
import { AxiosResponse } from 'axios';

import CloseStsModel from '../static/CloseStsModel';
import UtilizationResultModel from '../static/UtilizationResultModel';

const API_BASE = '/timesheet';

class UtilizationResultClosingService {
  getClosingSts(year: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/closests?year=${year}`;
    return ApiHelper.get(api);
  }

  createClosingSts(closeSts: CloseStsModel): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/closests`;
    return ApiHelper.post(api, closeSts);
  }

  updateCloseSts(closeSts: CloseStsModel): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/closests`;
    return ApiHelper.put(api, closeSts);
  }

  getClosePjList(yearMonth: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/monthly-closings/close-projects?yearMonth=${yearMonth}`;
    return ApiHelper.get(api);
  }

  removeClosePjList(yearMonth: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/monthly-closings/close-projects?yearMonth=${yearMonth}`;
    return ApiHelper.delete(api, null);
  }

  getEmpPriceList(yearMonth: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/monthly-closings/emp-prices?yearMonth=${yearMonth}`;
    return ApiHelper.get(api);
  }

  getRetiredEmpList(yearMonth: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/monthly-closings/rstn-emps?yearMonth=${yearMonth}`;
    return ApiHelper.get(api);
  }

  removeRetiredEmpList(yearMonth: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/monthly-closings/rstn-emps?yearMonth=${yearMonth}`;
    return ApiHelper.delete(api, null);
  }

  getOverMdList(yearMonth: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/monthly-closings/over-utilization-results?yearMonth=${yearMonth}`;
    return ApiHelper.get(api);
  }

  getOverMdDrawerDataList(empNo: string, injctYmd: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/emps/${empNo}/utilization-results/${injctYmd}?seqNo=0`;
    return ApiHelper.get(api);
  }

  removeOverMd(dataList: UtilizationResultModel[]): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/utilization-results`;
    return ApiHelper.delete(api, dataList);
  }

  createAggregateData(yearMonth: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/monthly-closings/aggregate?yearMonth=${yearMonth}`;
    return ApiHelper.post(api, null);
  }

  getAggregateData(yearMonth: string): Promise<AxiosResponse<any>> {
    // const api = `${API_BASE}/monthly-closings/${yearMonth}`;
    const api = `${API_BASE}/monthly-closings/aggregate?yearMonth=${yearMonth}`;
    return ApiHelper.get(api);
  }

  removeAggregateData(yearMonth: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/monthly-closings/${yearMonth}`;
    return ApiHelper.delete(api, null);
  }
}

export default new UtilizationResultClosingService();
