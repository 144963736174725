import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { AuthStore } from 'shared/stores/AuthenticationStore';

interface IProps {
  authStore?: AuthStore;
}

@inject('authStore')
@observer
class FeedbackHandler extends Component<IProps> {
  render() {
    return null;
  }
}

export default FeedbackHandler;
