import { Button, Modal } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import 'highlight.js/styles/github.css';
import { inject, observer } from 'mobx-react';
import { MenuHelpStore } from 'pages/menu-help/stores/MenuHelpStore';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MenuAssignImplementStore } from 'shared/stores/MenuAssignImplementStore';
import AppConst from 'shared/utils/AppConst';
import StorageHelper from 'shared/utils/StorageHelper';
import 'tui-editor/dist/tui-editor-contents.css';
import Viewer from 'tui-editor/dist/tui-editor-Viewer-all';
import _ from 'lodash';

interface HelpProps extends ButtonProps {
  title: string;
  menuAssignImplementStore?: MenuAssignImplementStore;
  menuHelpStore?: MenuHelpStore;
}

interface HelpState {
  visible: boolean;
}

@inject('menuAssignImplementStore', 'menuHelpStore')
@observer
class HelpButton extends React.Component<HelpProps, HelpState> {
  state = {
    visible: false
  };

  componentDidMount() {
    this.getContent();
  }

  visibleModal = (isVisible: boolean) => {
    this.setState({
      visible: isVisible
    });
  };

  handleOk = () => {
    this.setState({
      visible: false
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false
    });
  };

  getContent = () => {
    const { selectedProgramViewMenu } = this.props.menuAssignImplementStore!;
    const currentMenuCode = selectedProgramViewMenu ? selectedProgramViewMenu.menuCode : '';
    this.props.menuHelpStore!.getContentByMenuCode(currentMenuCode);
  };

  updateContent = () => {
    const hiddenContent = document.querySelector('#hiddenContent');
    const content = (hiddenContent && hiddenContent.innerHTML) || '';

    return { __html: content };
  };

  parseContent = (menuHelpSelected: any) => {
    if (menuHelpSelected) {
      const language = StorageHelper.getItem(StorageHelper.KEY_CURR_LANGUAGE) === AppConst.LANG_ENUS ? AppConst.LANG_EN.toUpperCase() : AppConst.LANG_KO.toUpperCase();
      const objContent = menuHelpSelected!.content.find((x: any) => x.language === language);
      if (objContent) {
        return objContent.contentText;
      }
    }
    return '';
  };

  render() {
    const { title, loading } = this.props;

    const el = document.querySelector('#hiddenContent');
    const content = this.parseContent(this.props.menuHelpStore!.menuHelpSelected);
    if (el) {
      const instance = new Viewer({
        el,
        initialValue: content,
        exts: ['table']
      });
    }

    const hiddenButton = _.isEmpty(content);

    return (
      <>
        <div id="hiddenContent" style={{ display:'none' }} />
        <style>
          {`
            .ant-modal-title {font-weight: bold}
          `}
        </style>
        <Modal
          width="70%"
          visible={this.state.visible}
          maskClosable={false}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          title={title}
          footer={[
            <Button key="submit" type="primary" loading={loading} onClick={this.handleOk}>
              <FormattedMessage id="button.title.close" />
            </Button>
          ]}
        >
          <div id="helpContent" dangerouslySetInnerHTML={this.updateContent()} style={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'auto' }} />
        </Modal>
        <Button hidden={hiddenButton} shape="circle" size="small" icon="question" onClick={() => this.visibleModal(true)} />
      </>
    );
  }
}

export default HelpButton;
