import React from 'react';
import { Col, Row, Form, Icon, message } from 'antd';
import styled from 'styled-components';
import background from 'images/metanet-background.jpg';
import IconMetanet from 'components/IconMetanet';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import { Redirect } from 'react-router';
import { AuthStore } from 'shared/stores/AuthenticationStore';
import { CorporationStore } from '../store/CorporationStore';
import CorporationCard from '../containers/CorporationCard';

const CorporationsPage = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const WrapCorporationsForm = styled.div`
  width: 70%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

interface IProps {
  corporationStore?: CorporationStore;
  authStore?: AuthStore;
}
@inject('corporationStore')
@inject('authStore')
@observer
class CorporationsView extends React.Component<IProps> {
  render() {
    const backgroundStyle: any = {
      background: '50% 50% / cover no-repeat',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundImage: `url(${background})`
    };

    const corpList = toJS(this.props.corporationStore!.corpList) || [];
    if (!corpList.length) {
      return <b>&nbsp;</b>;
    }

    const corpCards = corpList.map((corpItem: any, index: number) => (
      <Col span={8} key={corpItem.corpCode}>
        <CorporationCard corpName={corpItem.corpName} noWaitApproval={corpItem.totalApprCount || 0} corpCode={corpItem.corpCode} />
      </Col> 
    ));

    return (
      <CorporationsPage>
        <b style={{ ...backgroundStyle }} />
        <WrapCorporationsForm>
          <div>
            <h1 style={{ color: '#FFF', textAlign: 'center', fontSize: 40, marginBottom: 12 }}>
              <Icon type="clock-circle" style={{ fontSize: 50 }} />
              <span style={{ paddingLeft: 30 }}>TimeSheet</span>
            </h1>
            <Form style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '2em', marginBottom: 44 }}>
              <h1 style={{ textAlign: 'center', color: '#fff' }}>법인을 선택해주세요</h1>
              <Row gutter={16} style={{overflow: 'auto', maxHeight: 350}}>{corpCards}</Row>
              <div style={{ color: '#FFF', textAlign: 'center', fontSize: 20, padding: '1em 0 0', opacity: 0.3 }}>
                <IconMetanet style={{ width: 24, height: 24 }} />
                <b>Metanet</b>
              </div>
            </Form>
          </div>
        </WrapCorporationsForm>
      </CorporationsPage>
    );
  }
}

export default CorporationsView;
