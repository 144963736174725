import { Button, Layout, Menu, Popover } from 'antd';
import enUS from 'antd/es/locale/en_US';
import koKR from 'antd/es/locale/ko_KR';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import UserInfoPopup from 'pages/user-information/containers/UserInfoPopup';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { AuthStore } from 'shared/stores/AuthenticationStore';
import { IMenuHeader, MenuAssignImplementStore, MENU_CODE_MAPPING, TMenuAssign } from 'shared/stores/MenuAssignImplementStore';
import { RouteStore } from 'shared/stores/RouteStore';
import { UIStore } from 'shared/stores/UIStore';
import AppConst from 'shared/utils/AppConst';
import StorageHelper from 'shared/utils/StorageHelper';
import styled from 'styled-components';
import './Header.less';
import logoImage from './metanet-logo-transparent.png';

moment.locale('en');
const { Header } = Layout;
const { SubMenu } = Menu;

const SiteLogo = styled.div`
  float: left;
  width: 140px;
`;

const SiteLogoImage = styled.img`
  vertical-align: middle;
  height: 30px;
  padding-right: 10px;
  position: relative;
  top: -6px;
`;

const KEY_MENU_LOGOUT = 'key_logout';
const KEY_MENU_LANG_EN = 'key_lang_english';
const KEY_MENU_LANG_KR = 'key_lang_korean';

interface IProps {
  uiStore?: UIStore;
  authStore?: AuthStore;
  menuAssignImplementStore?: MenuAssignImplementStore;
  routeStore?: RouteStore;
}

@inject('authStore')
@inject('uiStore')
@inject('menuAssignImplementStore')
@inject('routeStore')
@observer
class MTNHeader extends React.Component<IProps> {
  onSelectMenu(menuId: any): void {
    if (KEY_MENU_LOGOUT === menuId) {
      this.props.authStore!.logout();
    } else if (KEY_MENU_LANG_EN === menuId) {
      moment.locale('en');
      this.props.uiStore!.setCurrentLanguage(enUS);
    } else if (KEY_MENU_LANG_KR === menuId) {
      moment.locale('ko_kr');
      this.props.uiStore!.setCurrentLanguage(koKR);
    }
  }

  handleClickMenuToLoadSidebar = (clickedMenu: IMenuHeader) => {
    const selectedMenuShouldBe = this.props.menuAssignImplementStore!.menuAssignedList.find(obj => obj.progPath === clickedMenu.pathToRedirectTo);
    if (selectedMenuShouldBe) this.props.menuAssignImplementStore!.selectedProgramViewMenu = selectedMenuShouldBe;
    this.props.routeStore!.storeHistory.push(clickedMenu.pathToRedirectTo);
  };

  renderMenu = (parentMenu?: TMenuAssign | IMenuHeader) => {
    // ex: menuCode for System or menu Code of TimeSheet
    if (!parentMenu || !parentMenu.menuCode) return null;

    const sortParentMenu = toJS(this.props.menuAssignImplementStore!.getListMenuHeader(parentMenu)).sort((a: IMenuHeader, b: IMenuHeader) => {
      const { displayOrder: aId } = a;
      const { displayOrder: bId } = b;

      if (bId && aId) {
        return Number(aId - bId);
      }
      if (bId === null) {
        // Fix for Firefox
        return 1;
      }
      return -1;
    });

    return (
      <SubMenu key={parentMenu.menuCode} title={<span>{this.props.menuAssignImplementStore!.getMenuNameByLanguage(parentMenu)}</span>}>
        {sortParentMenu.map(v => {
          return (
            <Menu.Item key={v.menuCode} style={{ whiteSpace: 'normal' }}>
              <div
                role="presentation"
                onClick={() => {
                  this.handleClickMenuToLoadSidebar(v);
                }}
              >
                <span className="submenu-title-wrapper">{this.props.menuAssignImplementStore!.getMenuNameByLanguage(v)}</span>
              </div>
            </Menu.Item>
          );
        })}
      </SubMenu>
    );
  };

  render() {
    const loggedUsername = StorageHelper.getItem(StorageHelper.KEY_USER_NAME) || '';

    const userInfoPopupContent = <UserInfoPopup userName={loggedUsername} />;
    const languagePopoverContent = (
      <div style={{ width: 68 }}>
        <Button type="link" onClick={() => this.props.uiStore!.setCurrentLanguage(koKR)}>
          한국어
        </Button>
        <Button type="link" onClick={() => this.props.uiStore!.setCurrentLanguage(enUS)}>
          English
        </Button>
      </div>
    );

    const timeSheetMenu = this.props.menuAssignImplementStore!.menuAssignedList.find(obj => obj.menuCode === MENU_CODE_MAPPING.TIMESHEET);
    const systemMenu = this.props.menuAssignImplementStore!.menuAssignedList.find(obj => obj.menuCode === MENU_CODE_MAPPING.SYSTEM);

    return (
      <Header className="header">
        <SiteLogo className="logo">
          <SiteLogoImage src={logoImage} alt="Metanet" />
        </SiteLogo>
        <Menu theme="dark" mode="horizontal" style={{ lineHeight: '64px', width: '70%', float: 'left' }} onClick={({ key }) => this.onSelectMenu(key)}>
          <Menu.Item key={AppConst.HEADER_MENU_DASHBOARD}>
            <Link to="/dashboard">
              <span className="submenu-title-wrapper">
                {/* <FormattedMessage id="header.menu.dashboard" /> */}
                Notice
              </span>
            </Link>
          </Menu.Item>
          {this.renderMenu(timeSheetMenu)}
          {this.renderMenu(systemMenu)}
        </Menu>
        <div style={{ float: 'right', height: 64 }}>
          <Popover placement="bottomRight" title="" content={languagePopoverContent} trigger="hover">
            <Button shape="circle" icon="global" style={{ marginRight: 6 }} />
          </Popover>
          <Popover style={{ left: 1430 }} placement="bottomRight" title="" content={userInfoPopupContent} trigger="click">
            <Button shape="circle" icon="user" />
          </Popover>
        </div>
      </Header>
    );
  }
}
export default MTNHeader;
