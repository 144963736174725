import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import * as autoBind from 'auto-bind';
import AppConst from 'shared/utils/AppConst';
import HRMBreadcrumb from 'components/HRMBreadcrumb';
import OvertimeMonthApprListStore from '../stores/OvertimeMonthApprListStore';
import OverTimeMonthWrapperApprDetailContainer from '../containers/OverTimeMonthWrapperApprDetailContainer';

interface Props {
  overtimeMonthApprListStore?: OvertimeMonthApprListStore;
  match?: any;
  history?: any;
}

@inject('overtimeMonthApprListStore')
@observer
export default class OvertimeMonthApprDetailView extends Component<Props> {
  representCode: string;
  representCodeName: string;
  commonCode: string;
  useYnText: string;

  constructor(props: any) {
    super(props);
    autoBind.react(this);

    this.representCode = '';
    this.representCodeName = '';
    this.commonCode = '';
    this.useYnText = AppConst.SELECT_ALL;
  }

  renderView = (): JSX.Element => {
    return <OverTimeMonthWrapperApprDetailContainer {...this.props} overtimeMonthApprListStore={this.props.overtimeMonthApprListStore} />;
  };

  render(): JSX.Element {
    const { inProgress } = this.props.overtimeMonthApprListStore!;
    const urlPath = this.props.match.path;

    return (
      <>
        <HRMBreadcrumb urlPath={urlPath} />
        {this.renderView()}
      </>
    );
  }
}
