import { observable, action, autorun, runInAction, computed, toJS } from 'mobx';
import _ from 'lodash';
import AppConst from 'shared/utils/AppConst';
import { RootStore } from 'shared/stores/RootStore';
import MenuModel from 'shared/models/menu/MenuModel';
import MenuService from 'pages/menu-management/services/MenuService';
import StorageHelper from 'shared/utils/StorageHelper';
import TreeNode from 'shared/components/TreeList/TreeNode';
import uuid from 'uuid';

export class MenuModalStore {
  @observable
  selectedMenu: MenuModel;

  @observable
  menuList: MenuModel[] = [];

  @observable
  inProgress = false;

  @observable
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action
  async find() {
    this.inProgress = true;
    const getLanguage = (languageList: any[], currLang: string): any => {
      return languageList.find(value => value.language === currLang) || {};
    };

    try {
      const apiResult: any = await MenuService.getFolder(this.rootStore.menuStore.selectedMenu!.id);
      this.rootStore.apiHandleStore.handleApiFail(apiResult.data.header);
      this.menuList = toJS(
        apiResult.data.data.map((x: MenuModel) => {
          x.id = uuid.v4();
          x.menuName = getLanguage(x.languages, AppConst.LANG_KO.toUpperCase()).menuName;
          x.menuNameEnglish = getLanguage(x.languages, AppConst.LANG_EN.toUpperCase()).menuName;
          x.authType = '';
          return x;
        })
      );
    } catch (error) {
      this.rootStore.apiHandleStore.handleApiError(error);
    }
    this.inProgress = false;
  }

  @action
  setSelectedMenu(menuId: string | number) {
    this.selectedMenu = this.menuList.find(x => x.id === menuId)!;
  }

  @computed
  get treeData(): TreeNode[] {
    const getLanguage = (languageList: any[], currLang: string): any => {
      return languageList.find(value => value.language === currLang) || {};
    };
    const currLang = StorageHelper.getItem(StorageHelper.KEY_CURR_LANGUAGE) === AppConst.LANG_ENUS ? AppConst.LANG_EN.toUpperCase() : AppConst.LANG_KO.toUpperCase();
    return this.menuList.map(
      x =>
        new TreeNode({
          id: x.id,
          parentCode: x.upperMenuCode ? x.corpCode + x.upperMenuCode : '',
          valueCode: x.corpCode + x.menuCode,
          nodeText: x.menuCode ? `${getLanguage(x.languages, currLang).menuName}-${x.menuCode}` : `${getLanguage(x.languages, currLang).menuName}`,
          changeStatus: x.changeStatus
        })
    );
  }

  @computed
  get treeMenu(): any[] {
    /**
     * Ref: http://jsfiddle.net/tx3uwhke/
     * @param data
     * @param parent
     */
    function buildTree(data: any, parent?: any): any[] {
      const result: any[] = [];
      const DEPTCODE_ROOT = '';
      parent = typeof parent !== 'undefined' ? parent : { valueCode: DEPTCODE_ROOT };
      const children = _.filter(data, function(value: MenuModel) {
        return value.parentCode === parent.valueCode;
      });
      if (!_.isEmpty(children)) {
        _.each(children, function(child) {
          if (child != null) {
            result.push(child);
            const ownChildren = buildTree(data, child);
            if (!_.isEmpty(ownChildren)) {
              child.isParent = true;
              child.children = ownChildren;
            } else {
              child.isParent = false;
            }
          }
        });
      }

      return result;
    }

    const tree = buildTree(this.treeData);
    return tree;
  }
}
