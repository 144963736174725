import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import OvertimeMonthApprListStore from '../stores/OvertimeMonthApprListStore';
import { FormattedMessage } from 'react-intl';

interface Props {
  overtimeMonthApprListStore: OvertimeMonthApprListStore;
  history?: any;
}

const RenderDate = styled.div`
  height: 50px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  padding: 5px;
  background-color: #ffffff;
  margin-bottom: 20px;
`;

const RenderButton = styled.div`
  text-align: right;
  position: relative;
  right: 0px;
  top: -64px;
`;

@observer
export default class OverTimeContentsHeaderMonthApprDetailContainer extends Component<Props> {
  state = {
    yyyy: this.props.overtimeMonthApprListStore.detailYyyy,
    mm: this.props.overtimeMonthApprListStore.detailMm
  };

  handleAllAppr = (): void => {
    this.props.overtimeMonthApprListStore.allApprMonthOverTime(this.props.overtimeMonthApprListStore.detailOverTimeSum);
    this.props.overtimeMonthApprListStore.detailOverTimeSum.allApprYn = 'N';
  };

  onClickGoToList = () => {
    this.props.overtimeMonthApprListStore.getGridData(
      this.props.overtimeMonthApprListStore.seletedPjCode,
      this.props.overtimeMonthApprListStore.selectYyyymm,
      this.props.overtimeMonthApprListStore.selectEmpNm
    );
    this.props.history.push('/timesheet/overtime/overtime/overtimeapp');
  };

  render(): JSX.Element {
    return (
      <>
        <RenderDate>
          {/* <span onClick={(): any => this.openCalendar()}>{this.state.yyyy}-{this.state.mm}</span> */}
          <span>
            {this.state.yyyy}-{this.state.mm}
          </span>
        </RenderDate>
        <RenderButton>
          <Button icon="unordered-list" size="large" onClick={this.onClickGoToList}>
            <FormattedMessage id="overtime.table.column.label.list" />
          </Button>
          &nbsp;&nbsp;
          {this.props.overtimeMonthApprListStore.detailOverTimeSum.allApprYn === 'Y' ? (
            <>
              <Button size="large" type="primary" onClick={(): any => this.handleAllAppr()}>
                {this.props.overtimeMonthApprListStore!.allApprTitle}
              </Button>
            </>
          ) : (
            ''
          )}
        </RenderButton>
      </>
    );
  }
}
