/* eslint-disable class-methods-use-this */
import koKR from 'antd/es/locale/ko_KR';
import _ from 'lodash';
import { action, observable } from 'mobx';
import AuthAPIModel from 'shared/models/auth/AuthAPIModel';
import { RootStore } from 'shared/stores/RootStore';
import AppConst from 'shared/utils/AppConst';
import AppHelper from 'shared/utils/AppHelper';
import LanguageHelper from 'shared/utils/LanguageHelper';
import StorageHelper from 'shared/utils/StorageHelper';
import EmployeeModel from 'shared/models/employee/EmployeeModel';
import AuthenticationService from '../../pages/login/services/AuthenticationService';

export class AuthStore {
  @observable
  authModel: AuthAPIModel;

  @observable
  isPasswordResetEmailSent: boolean;

  @observable
  isPasswordResetDone: boolean;

  rootStore: RootStore;

  @observable
  inProgress = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action
  async loginSso(userName: string, password: string) {
    this.inProgress = true;
    //userName = '0990184';
    //password = 'meta!123';
    try {
      // const response = await AuthenticationService.login(userName.trim(), password.trim());
      const response = await AuthenticationService.sso(userName.trim(), password.trim());
      this.rootStore.apiHandleStore.handleApiFail(response.data.header);
      
      const { data } = response.data;
      if (data) {
        this.rootStore.corporationStore.corpList = data.corpList;
        const { loginWithDefaultPassword, userId, accessToken, corpList } = data;

        this.authModel = new AuthAPIModel({
          userId,
          userName,
          accessToken,
          corpList,
          loginWithDefaultPassword
        });

        // still need to store this one to call select corp API with token
        StorageHelper.setItem(StorageHelper.KEY_CURR_LANGUAGE, AppConst.LANG_KOKR);
        AuthenticationService.storeToken(data.userId, data.userName, data.accessToken);

        if (loginWithDefaultPassword) {
          // This is first time to login then move to Change Password page to change default password
          this.rootStore.routeStore.storeHistory.push('/change/password');
        } else if (!(data.corpList.length > 1)) {
          // check if it's NOT concurent user => set token
          StorageHelper.setItem(StorageHelper.KEY_CURR_CORP_CODE, data.corpList[0].corpCode);

          this.runWhenLoginSuccess();
        } else {
          // if user is concurent user
          this.rootStore.routeStore.storeHistory.push('/corporations');
        }
      }
    } catch (error) {
      this.authModel = new AuthAPIModel();
      AppHelper.notify('error', LanguageHelper.getMessage('login.message.login.fail'), error.message);
    } finally {
      this.inProgress = false;
    }
  }


  @action
  logout() {
    return AuthenticationService.logout()
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        this.rootStore.uiStore.setCurrentLanguage(koKR);
        StorageHelper.resetAll();
        this.authModel = new AuthAPIModel();
        //
        this.rootStore.routeStore.hardRefreshToPath('/');
      })
      .catch((e: any) => {
        this.rootStore.apiHandleStore.handleApiError(e);
      });
  }

  @action
  async login(userName: string, password: string) {
    this.inProgress = true;
    try {
      const response = await AuthenticationService.login(userName.trim(), password.trim());
      this.rootStore.apiHandleStore.handleApiFail(response.data.header);

      const { data } = response.data;
      if (data) {
        this.rootStore.corporationStore.corpList = data.corpList;
        const { loginWithDefaultPassword, userId, accessToken, corpList } = data;

        this.authModel = new AuthAPIModel({
          userId,
          userName,
          accessToken,
          corpList,
          loginWithDefaultPassword
        });

        // still need to store this one to call select corp API with token
        StorageHelper.setItem(StorageHelper.KEY_CURR_LANGUAGE, AppConst.LANG_KOKR);
        AuthenticationService.storeToken(data.userId, data.userName, data.accessToken);

        if (loginWithDefaultPassword) {
          // This is first time to login then move to Change Password page to change default password
          this.rootStore.routeStore.storeHistory.push('/change/password');
        } else if (!(data.corpList.length > 1)) {
          // check if it's NOT concurent user => set token
          StorageHelper.setItem(StorageHelper.KEY_CURR_CORP_CODE, data.corpList[0].corpCode);

          this.runWhenLoginSuccess();
        } else {
          // if user is concurent user
          this.rootStore.routeStore.storeHistory.push('/corporations');
        }
      }
    } catch (error) {
      this.authModel = new AuthAPIModel();
      AppHelper.notify('error', LanguageHelper.getMessage('login.message.login.fail'), error.message);
    } finally {
      this.inProgress = false;
    }
  }

  @action
  moveToLoginPage() {
    this.rootStore.uiStore.setCurrentLanguage(koKR);
    StorageHelper.resetAll();
    this.authModel = new AuthAPIModel();
    this.rootStore.routeStore.hardRefreshToPath('/');
  }

  get isLogged(): boolean {
    const hasStorageCorp = !!StorageHelper.getItem(StorageHelper.KEY_CURR_CORP_CODE);
    const hasToken = !!StorageHelper.getItem(StorageHelper.KEY_ACCESS_TOKEN);
    return hasStorageCorp && hasToken;
  }

  static selectedCorporation(): any {
    return StorageHelper.getItem(StorageHelper.KEY_CURR_CORP_CODE);
  }

  // eslint-disable-next-line class-methods-use-this
  get selectedCorporation(): any {
    return StorageHelper.getItem(StorageHelper.KEY_CURR_CORP_CODE);
  }

  getUserDepartmentDataBySelectedCorp(): EmployeeModel | undefined {
    const corpCodeSelected = AuthStore.selectedCorporation();
    const empListData: EmployeeModel[] = this.rootStore.userInfoStore.userInfoModel!.empList;
    const result: EmployeeModel | undefined = empListData.find((v: EmployeeModel) => v.corpCode === corpCodeSelected);
    return result;
  }

  @action
  setSelectedCorp(corpCode: string, isSwitchCorp?: boolean) {
    // isSwitchCorp: switch corp after loggedin
    return AuthenticationService.fetchAccessTokenBySelectedCorp(corpCode, isSwitchCorp)
      .then((response: any) => {
        const { isSuccessful } = response.data.header;
        if (!isSuccessful) {
          this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        } else {
          StorageHelper.setItem(StorageHelper.KEY_CURR_CORP_CODE, corpCode);
          const jwtToken = response.data.data.accessToken;
          AuthenticationService.storeSecondToken(jwtToken);

          this.runWhenLoginSuccess();
        }
      })
      .catch((e: any) => {
        console.error(`Second Token fetching fail: ${JSON.stringify(e)}`);
      });
  }

  @action
  findPassword = (userName: string, email: string) => {
    this.isPasswordResetEmailSent = false;
    this.inProgress = true;
    return AuthenticationService.findPassword(_.trim(userName), _.trim(email))
      .then((response: any) => {
        const { isSuccessful } = response.data.header;
        if (!isSuccessful) {
          this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        } else {
          this.isPasswordResetEmailSent = true;
        }
        this.inProgress = false;
      })
      .catch((e: any) => {
        AppHelper.notify('error', 'Password Finder fail', e.message);
        this.isPasswordResetEmailSent = false;
      })
      .finally(() => {
        this.inProgress = false;
      });
  };

  @action
  resetPassword = (userName: string, token: string, newPassword: string) => {
    this.isPasswordResetDone = false;
    this.inProgress = true;
    return AuthenticationService.resetPassword(_.trim(userName), token, newPassword)
      .then((response: any) => {
        const { isSuccessful } = response.data.header;
        if (!isSuccessful) {
          this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        } else {
          AppHelper.showMessage('success', '비밀번호 재설정이 완료되었습니다.');
          this.isPasswordResetDone = true;
        }
        this.inProgress = false;
      })
      .catch((e: any) => {
        AppHelper.notify('error', 'Password Reset fail', e.message);
        this.isPasswordResetDone = false;
      })
      .finally(() => {
        this.inProgress = false;
      });
  };

  private runWhenLoginSuccess = () => {
    // LOGIN SUCCESS MEANS ALREADY FINISH WHOLE PROCESS INCLUDING SET CURRENT CORP
    this.fetchDataWhenUserNeedWhileUsing();
    this.rootStore.routeStore.storeHistory.push('/dashboard');
  };

  fetchDataWhenUserNeedWhileUsing = () => {
    this.rootStore.menuAssignImplementStore!.getMenuAssigned();
    this.rootStore.systemLanguageStore.fetchLanguage();
    this.rootStore.userInfoStore.findUserInfo();
  };

  @action
  changePassword = (userId: string, oldPassword: string, newPassword: string) => {
    return AuthenticationService.changePassword(userId, oldPassword, newPassword)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const { isSuccessful } = response.data.header;
        if (isSuccessful) {
          AppHelper.showMessage('success', LanguageHelper.getMessage('userinfo.message.change.password.done'));
          this.rootStore.authStore!.moveToLoginPage();
        }
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  };
}
