import React from 'react';
import { observer } from 'mobx-react';
import { useIntl , FormattedMessage } from 'react-intl';
import { Table } from 'antd';
import AppConst from 'shared/utils/AppConst';
import useStores from 'shared/hooks/useStores';
import 'pages/project-resource/static/styles.css';

const COL_WIDTH_MIN = '5%';
const COL_WIDTH_MEDIUM = '10%';
const COL_WIDTH_MAX = '50';

const ProjectResourceTableContainer = observer(()=>{ 
  
    // mobx inject hook
    const { projectResourceStore } = useStores();
    // 다국어 hook
    const { formatMessage } = useIntl();

    const myDataSource = projectResourceStore.projectResourceModel;
    const isPagingAllowed = !!(myDataSource && myDataSource.length > AppConst.TABLE_PAGE_SIZE);
    const paginationConfig = isPagingAllowed ? { pageSize: AppConst.TABLE_PAGE_SIZE, showSizeChanger: true, showQuickJumper: true } : false;
    const noRow =  (myDataSource ? myDataSource.length : 0 );
    
    return (
      <>        
        <Table 
          dataSource={myDataSource} 
          pagination={paginationConfig} 
          size="middle" 
          rowKey="no" 
          footer={() => <FormattedMessage id="text.total" values={{ noRow }} />}          
          >
          <Table.Column className="tableCellAlignCenter" key="no" dataIndex="no" title="No." width={COL_WIDTH_MIN} />
          <Table.Column className="tableCellAlignCenter" key="pjCode" dataIndex="pjCode"  width={COL_WIDTH_MEDIUM} title={ formatMessage({id :'project.resource.table.column.pj.code'})} />
          <Table.Column className="tableCellAlignCenter" key="pjName" dataIndex="pjName" width={COL_WIDTH_MAX} title={formatMessage({id :'project.resource.table.column.pj.name'})} />
          <Table.Column className="tableCellAlignCenter" key="empNo" dataIndex="empNo" title={formatMessage({id :'project.resource.table.column.emp.no'})} />
          <Table.Column className="tableCellAlignCenter" key="empNm" dataIndex="empNm" title={formatMessage({id :'project.resource.table.column.emp.nm'})} />
          <Table.Column className="tableCellAlignCenter" key="planYn" dataIndex="planYn" width={COL_WIDTH_MIN} title={formatMessage({id :'project.resource.table.column.plan.yn'})} />
          <Table.Column className="tableCellAlignCenter" key="resultYn" dataIndex="resultYn" width={COL_WIDTH_MIN} title={formatMessage({id :'project.resource.table.column.result.yn'})} />
          <Table.Column className="tableCellAlignCenter" key="regEmpNo" dataIndex="regEmpName" title={formatMessage({id :'project.resource.table.column.reg.emp.no'})} />
          <Table.Column className="tableCellAlignCenter" key="regDate" dataIndex="regDate" title={formatMessage({id :'project.resource.table.column.reg.date'})} />
          <Table.Column className="tableCellAlignCenter" key="updatedEmpNo" dataIndex="updatedEmpName" title={formatMessage({id :'project.resource.table.column.updated.emp.no'})} />
          <Table.Column className="tableCellAlignCenter" key="updatedDate" dataIndex="updatedDate" title={formatMessage({id :'project.resource.table.column.updated.date'})} />
          
        </Table>        
      </>
    );
  }
);
export default ProjectResourceTableContainer;
