import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import AppDetailMobileWrapperContainer from '../containers/AppDetailMobileWrapperContainer';

@inject('authStore')
@observer
export default class OvertimeAppDetailMobileView extends Component<any, any> {
  render() {
    return <AppDetailMobileWrapperContainer {...this.props} />;
  }
}
