import { Avatar, Button, Col, Form, Icon, Input, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import IconMetanet from 'components/IconMetanet';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AuthStore } from 'shared/stores/AuthenticationStore';
import { RouteStore } from 'shared/stores/RouteStore';
import AppHelper from 'shared/utils/AppHelper';
import LanguageHelper from 'shared/utils/LanguageHelper';
import StorageHelper from 'shared/utils/StorageHelper';
import ValidatorHelper from 'shared/utils/ValidatorHelper';
import '../static/style.less';
import { UserInfoStore } from '../store/UserInfoStore';

const { Option } = Select;

interface IProps {
  userName: string;
  authStore?: AuthStore;
  userInfoStore?: UserInfoStore;
  routeStore?: RouteStore;
  form?: any;
}

const KEY_NEW_PASSWORD = 'newPassword';
const KEY_CONFIRMED_PASSWORD = 'confirmedPassword';

@inject('authStore', 'userInfoStore', 'routeStore')
@observer
class UserInfoPopup extends React.Component<IProps, any> {
  state = {
    confirmDirty: false,
    isUserInfoForm: true
  };

  isRedirectToDashboard: boolean;

  constructor(props: any) {
    super(props);

    this.props.userInfoStore!.findUserInfo();
    this.isRedirectToDashboard = false;
  }

  doLogout = () => {
    this.props.authStore!.logout();
  };

  openChangePasswordForm = () => {
    this.setState({
      isUserInfoForm: false
    });
  };

  doChangePassword = (e: any) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err: any, values: any) => {
      if (!err) {
        const userId = StorageHelper.getItem(StorageHelper.KEY_USER_ID) || '';
        const { currPassword, newPassword } = values;
        this.props.userInfoStore!.changePassword(userId, currPassword, newPassword);
      }
    });
  };

  doCancelChangePassword = () => {
    this.setState({
      isUserInfoForm: true
    });
  };

  onChangeCorp = (value: any) => {
    this.props.authStore!.setSelectedCorp(value || '', true).then(() => {
      if (StorageHelper.getItem(StorageHelper.KEY_CURR_CORP_CODE)) {
        AppHelper.showMessage('success', LanguageHelper.getMessage('userinfo.message.change.corporation.done'));
        this.props.routeStore!.hardRefreshToPath('/dashboard');
      }
    });
  };

  getSelectComponent = () => {
    const { userInfoModel } = this.props.userInfoStore!;
    const options: any[] = [];
    if (userInfoModel) {
      userInfoModel.empList.map((empItem: any, index: number) => {
        const key = index + 1;
        options.push(
          <Option value={empItem.corpCode} key={key}>
            {empItem.corpName}
          </Option>
        );
        return true;
      });
    }
    const currCorpCode = StorageHelper.getItem(StorageHelper.KEY_CURR_CORP_CODE) || '';
    const isDisableSelect = !(options.length > 1);

    return (
      <>
        <Form layout="vertical">
          <Form.Item label={<FormattedMessage id="userinfo.label.accessed.corp" />} style={{ marginLeft: 6, marginTop: 45 }}>
            <Select
              getPopupContainer={trigger => trigger.parentNode as HTMLElement}
              defaultValue={currCorpCode}
              className="user-info-select-corp"
              disabled={isDisableSelect}
              onChange={this.onChangeCorp}
            >
              {options}
            </Select>
          </Form.Item>
        </Form>
      </>
    );
  };

  getUserInfoForm = () => {
    const { userInfoModel } = this.props.userInfoStore!;
    const userId = StorageHelper.getItem(StorageHelper.KEY_USER_ID);
    const currCorpCode = StorageHelper.getItem(StorageHelper.KEY_CURR_CORP_CODE);
    const currCorp = userInfoModel
      ? _.find(userInfoModel.empList, function(empItem) {
          return empItem.corpCode === currCorpCode;
        })
      : null;
    const currCorpName = !_.isNull(currCorp) && !_.isUndefined(currCorp) ? currCorp.corpName : '';
    const currDeptName = !_.isNull(currCorp) && !_.isUndefined(currCorp) ? currCorp.deptName : '';
    const currEmpName = !_.isNull(currCorp) && !_.isUndefined(currCorp) ? currCorp.empName : '';
    const pStyle = { marginBottom: 0, color: 'black', height: 24 };

    return (
      <>
        <div>
          <Row>
            <Col span={6}>
              <Avatar
                shape="square"
                size={64}
                style={{ backgroundColor: '#87d068', marginTop: 10, marginLeft: 10, width: 82, height: 90 }}
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSa-3VfK6P9EvxVcVJ1u_Mc2ajx3eQ3mcEpPFJ7j-vNy8D6yEsz&s"
              />
            </Col>
            <Col span={18}>
              {/* <p style={pStyle}>{userId}</p> */}
              <p style={pStyle}>{this.props.userName}</p>
              <p style={pStyle}>{currEmpName}</p>
              <p style={pStyle}>{currDeptName}</p>
              <p style={pStyle}>{currCorpName}</p>
            </Col>
          </Row>
        </div>
        <div>{this.getSelectComponent()}</div>
        <div className="userinfo-form-buttons-layout">
          <Button style={{ marginRight: 8, backgroundColor: '#95a5a6', borderColor: '#95a5a6' }} type="primary" onClick={this.openChangePasswordForm}>
            <FormattedMessage id="userinfo.button.label.change.password" />
          </Button>
          <Button type="danger" onClick={this.doLogout}>
            <FormattedMessage id="userinfo.button.label.logout" />
          </Button>
        </div>
      </>
    );
  };

  getPasswordChangeForm = () => {
    const { getFieldDecorator } = this.props.form;
    const passwordPlaceHolder = LanguageHelper.getMessage('text.password.placeholder');
    const currPasswordPlaceHolder = LanguageHelper.getMessage('userinfo.label.password.current');

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    };
    return (
      <div style={{ marginLeft: 14 }}>
        <Form layout="vertical" {...formItemLayout} onSubmit={this.doChangePassword}>
          <h3 style={{ fontWeight: 'bold', paddingBottom: 8 }}>
            <IconMetanet /> <FormattedMessage id="userinfo.title.change.password" />
          </h3>
          <Form.Item label={<FormattedMessage id="userinfo.label.password.current" />}>
            {getFieldDecorator('currPassword', {
              initialValue: '',
              rules: [
                {
                  whitespace: true,
                  required: true,
                  message: <FormattedMessage id="userinfo.messgae.input.curr.password.request" />
                }
              ]
            })(<Input type="password" allowClear prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={currPasswordPlaceHolder} />)}
          </Form.Item>
          <Form.Item label={<FormattedMessage id="userinfo.label.password.new" />} hasFeedback>
            {getFieldDecorator(KEY_NEW_PASSWORD, {
              initialValue: '',
              rules: [
                {
                  validator: this.validateNewPassword,
                  whitespace: true,
                  required: true,
                  message: <FormattedMessage id="message.invalid.password.format" />
                }
              ]
            })(<Input type="password" allowClear prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={passwordPlaceHolder} />)}
          </Form.Item>
          <Form.Item label={<FormattedMessage id="userinfo.label.password.new.confirm" />} hasFeedback>
            {getFieldDecorator(KEY_CONFIRMED_PASSWORD, {
              initialValue: '',
              rules: [
                {
                  validator: this.compareToNewPassword,
                  whitespace: true,
                  required: true,
                  message: <FormattedMessage id="userinfo.message.invalid.confirmed.password.format" />
                }
              ]
            })(
              <Input
                type="password"
                allowClear
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder={passwordPlaceHolder}
                onBlur={this.handleConfirmBlur}
              />
            )}
          </Form.Item>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button style={{ marginRight: 8 }} onClick={this.doCancelChangePassword}>
              <FormattedMessage id="button.title.back" />
            </Button>
            <Button type="primary" htmlType="submit">
              <FormattedMessage id="button.title.save" />
            </Button>
          </div>
        </Form>
      </div>
    );
  };

  /**
   * Validate New Password and validate to Confirmed Password
   */
  validateNewPassword = (rule: any, value: any, callback: any) => {
    const { form } = this.props;
    const isValidPassword = ValidatorHelper.isValidPassword(value);
    if (value && this.state.confirmDirty) {
      form.validateFields([KEY_CONFIRMED_PASSWORD], { force: true });
    }
    if (!isValidPassword) return false;
    callback();
  };

  compareToNewPassword = (rule: any, value: any, callback: any) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue(KEY_NEW_PASSWORD)) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  handleConfirmBlur = (e: any) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  render() {
    const { isUserInfoForm } = this.state;
    return <div className="user-info-form-layout">{isUserInfoForm ? this.getUserInfoForm() : this.getPasswordChangeForm()}</div>;
  }
}

const WrappedUserInfoPopupForm = Form.create<IProps & FormComponentProps>()(UserInfoPopup);
export default WrappedUserInfoPopupForm;
