import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import OvertimeMonthStateStore from '../stores/OvertimeMonthStateStore';
import { FormattedMessage } from 'react-intl';

interface Props {
  overtimeMonthStateStore?: OvertimeMonthStateStore;
  history?: any;
}

const RenderDate = styled.div`
  height: 50px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  padding: 5px;
  background-color: #ffffff;
  margin-bottom: 20px;
`;

const RenderButton = styled.div`
  text-align: right;
  position: relative;
  right: 0px;
  top: -64px;
`;

@inject('overtimeMonthStateStore')
@observer
export default class OverTimeContentsHeaderMonthStateDetailContainer extends Component<Props> {
  state = {
    yyyy: this.props.overtimeMonthStateStore!.detailYyyy,
    mm: this.props.overtimeMonthStateStore!.detailMm
  };

  onClickGoToList = () => {
    this.props.overtimeMonthStateStore!.getGridData(
      this.props.overtimeMonthStateStore!.seletedPjCode,
      this.props.overtimeMonthStateStore!.selectYyyymm,
      this.props.overtimeMonthStateStore!.selectEmpNm
    );
    this.props.history.push('/timesheet/overtime/overtime/overtimests');
  };

  render(): JSX.Element {
    return (
      <>
        <RenderDate>
          {/* <span onClick={(): any => this.openCalendar()}>{this.state.yyyy}-{this.state.mm}</span> */}
          <span>
            {this.state.yyyy}-{this.state.mm}
          </span>
        </RenderDate>
        <RenderButton>
          <Button icon="unordered-list" size="large" onClick={this.onClickGoToList}>
            <FormattedMessage id="overtime.table.column.label.list" />
          </Button>
        </RenderButton>
      </>
    );
  }
}
