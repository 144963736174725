class APIAssignmentModal {
  apiAuthCode: string;
  apiName: string;
  apiPath: string;
  corpCode?: string;
  corpName?: string;
  regDate?: number;
  regEmpNo?: string;
  updatedDate?: number;
  updatedEmpNo?: string;
  usePattern?: string;
  useYn?: string;
  chosen?: boolean;

  constructor(obj?: any) {
    if (!obj) return;
    this.apiAuthCode = obj.apiAuthCode;
    this.apiName = obj.apiName;
    this.apiPath = obj.apiPath;
    this.corpCode = obj.corpCode;
    this.corpName = obj.corpName;
    this.regDate = obj.regDate;
    this.regEmpNo = obj.regEmpNo;
    this.updatedDate = obj.updatedDate;
    this.updatedEmpNo = obj.updatedEmpNo;
    this.usePattern = obj.usePattern;
    this.useYn = obj.useYn;
    this.chosen = obj.chosen;
  }
}
export default APIAssignmentModal;
