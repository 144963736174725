import { Flex } from 'antd-mobile';
import React from 'react';
import '../user-info.less';
import { inject, observer } from 'mobx-react';
import StorageHelper from 'shared/utils/StorageHelper';
import UserInfo from '../containers/UserInfo';

@inject('userInfoStore')
@observer
class UserInfoMobile extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    const userId = StorageHelper.getItem(StorageHelper.KEY_USER_ID) || '';
    if ((!this.props.userInfoStore!.userInfoModel.userId && userId) || (userId && this.props.userInfoStore!.userInfoModel.userId !== userId)) {
      this.props.userInfoStore!.findUserInfo(userId);
    }
  }

  render() {
    return (
      <Flex className="user-info" justify="start" direction="column" style={{ height: '100%', backgroundColor: '#fff' }}>
        {this.props.userInfoStore!.userInfoModel.userId ? <UserInfo userData={this.props.userInfoStore!.userInfoModel} history={this.props.history} /> : null}
      </Flex>
    );
  }
}

export default UserInfoMobile;
