import ApiHelper from 'shared/utils/ApiHelper';
import HrAssignManageModel from '../static/HrAssignManageModel';

const API_BASE = '/timesheet';

class HrAssignReqService {
  // 개인별 프로젝트별 상세 내역 조회 (TimeLine)
  getUtilPlanMemberSchedule(pjCode: String, deptCode: String, year: string) {
    const api = `${API_BASE}/depts/${deptCode}/utilization-plans/member/${year}?pjCode=${pjCode}`;
    return ApiHelper.get(api);
  }

  // 개인별 프로젝트별 상세 내역 조회
  getUtilPlanDetailPersonalSchedule(empNo: String, pjCode: String, fromDate: String, toDate: String) {
    const api = `${API_BASE}/emps/${empNo}/projects/${pjCode}/utilization-plans?from=${fromDate}&to=${toDate}`;
    return ApiHelper.get(api);
  }

  // 상태 변경 (승인/반려)
  updateApproveStauts(model: HrAssignManageModel) {
    const api = `${API_BASE}/projects/${model.pjCode}/utilization-plans/`;
    return ApiHelper.put(api, model);
  }

  // 개인별 프로젝트별 상세 내역 조회 (Grid)
  getUtilPlanMemberGrid(pjCode: String, deptCode: String, year: string) {
    const api = `${API_BASE}/utilization-plans/member?year=${year}&deptCode=${deptCode}&pjCode=${pjCode}`;
    return ApiHelper.get(api);
  }
}
export default new HrAssignReqService();
