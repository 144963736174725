import { action } from 'mobx';
import AppHelper from '../utils/AppHelper';
import LanguageHelper from '../utils/LanguageHelper';
import { RootStore } from './RootStore';

export class ApiHandleStore {
  rootStore: RootStore;

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  @action
  handleApiFail(responseHeader: any) {
    if (responseHeader && !responseHeader.isSuccessful) {
      const httpCode = Number(responseHeader.messageInfo.code.split('.')[2]);
      const content = responseHeader.messageInfo.contents;
      const { title } = responseHeader.messageInfo;
      if (httpCode === 401 || httpCode === 409) {
        AppHelper.notify('error', title, content, () => this.rootStore.authStore.moveToLoginPage());
      } else if (httpCode === 403) {
        AppHelper.notify('error', title, content);
      } else {
        AppHelper.notify('info', title, content);
      }
    }
  }

  @action
  handleAllApiResult(responses: any[]) {
    responses.forEach(response => {
      this.handleApiFail(response.data.header);
    });
    let isAllApiSuccessful = true;
    for (let index = 0; index < responses.length; index++) {
      const { isSuccessful } = responses[index].data.header;
      if (!isSuccessful) {
        isAllApiSuccessful = false;
        break;
      }
    }

    if (isAllApiSuccessful) {
      AppHelper.showMessage('success', LanguageHelper.getMessage('message.submit.done'));
    }
  }

  @action
  // eslint-disable-next-line class-methods-use-this
  handleApiError(error: any) {
    console.error('handleApiError', error);
    const msg = error.message;
    const displayTitle = LanguageHelper.getMessage('modal.error.general.title');
    let displayMsg = LanguageHelper.getMessage('modal.error.general.content');

    if (msg === 'Network Error') {
      displayMsg = LanguageHelper.getMessage('message.network.error');
      AppHelper.notify('error', displayTitle, displayMsg);
    }
    // TODO: Still need confirm from PO for general message content later
    AppHelper.notify('error', displayTitle, displayMsg);
  }

  /**
   * Handle response of Exporting API
   */
  @action
  handleApiExport = (response: any) => {
    const { isSuccessful } = response.data.header;
    if (!isSuccessful) {
      this.handleApiFail(response.data.header);
      return;
    }
    const url = response.data.data.url || '';
    if (url && url !== '') {
      AppHelper.downloadExcel(url);
    }
  };
}
