import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import ProjectModel from 'shared/models/project/ProjectModel';
import { Icon } from 'antd';
import { Button, Flex, DatePicker, List, InputItem, WhiteSpace, Toast, SegmentedControl, Modal } from 'antd-mobile';
import { Link, withRouter } from 'react-router-dom';
import OvertimeCalendarMobileStore from '../stores/OvertimeCalendarMobileStore';
import CommonProjectMobileStore from '../../common/stores/CommonProjectMobileStore';
import CommonProjectMobileComponent from '../../common/containers/CommonProjectMobileComponent';
import enUs from 'antd-mobile/lib/date-picker/locale/en_US';
import MobileLanguageHelper from 'shared/utils/MobileLanguageHelper';

const rcForm = require('rc-form');

const now = new Date();

// const isNum = (num: any): boolean => /^\d+$/.test(num) || /^\d+\.\d+$/.test(num);
const isNum = (num: any): boolean => /^\d+$/.test(num);

const isTextInput = (node: any) => {
  return ['INPUT', 'TEXTAREA'].indexOf(node.nodeName) !== -1;
};

interface Props {
  overtimeCalendarMobileStore?: OvertimeCalendarMobileStore;
  commonProjectMobileStore?: CommonProjectMobileStore;
  form: any;
  history: any;
}

const InputForm = styled.div`
  /* padding-top: 50px; */
  margin: 10px;
`;

const FooterButton = styled.div`
  padding: 10px;
  width: 100%;
  position: fixed;
  bottom: 0px;
`;

const ValidatorArea = styled.div`
  padding: 10px;
  color: #ff0000;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
`;

@inject('commonProjectMobileStore')
@inject('overtimeCalendarMobileStore')
@observer
class RegMobileWrapperContainer extends Component<Props> {
  state = {
    validationMessage: [],
    status: 'create',
    modal: false,
    modalContents: '',
    overTimeType:
      this.props.overtimeCalendarMobileStore!.selectedOvertimeItem !== undefined
        ? this.props.overtimeCalendarMobileStore!.selectedOvertimeItem.overTimeType !== null
          ? this.props.overtimeCalendarMobileStore!.selectedOvertimeItem.overTimeType
          : 'OT'
        : 'OT'
  };

  onModalOpen = (response: any) => {
    this.setState({
      modal: true,
      modalContents: response
    });
  };

  onModalClose = () => {
    this.setState({
      modal: false
    });
  };

  componentDidMount() {
    const { selectedOvertimeItem } = this.props.overtimeCalendarMobileStore!;

    if (selectedOvertimeItem === undefined) {
      this.props.history.push('/m/timesheet/overtime/overtimecalendar');
      return;
    }

    // null이 아니면 update
    if (selectedOvertimeItem.apprSts === null) {
      this.setState({
        status: 'create'
      });
    } else {
      this.setState({
        status: 'update'
      });
    }

    const projectCode = new ProjectModel({
      no: 0,
      corpCode: '',
      pjCode: '',
      mainSalesDept: '',
      mainPerformDept: '',
      pjName: '',
      pmEmpNo: '',
      pmEmpNm: '',
      pjStartDate: '',
      pjEndDate: '',
      pjStatus: '',
      pjType: '',
      rowStatus: ''
    });

    if (selectedOvertimeItem.pjCode != null) {
      projectCode.pjCode = selectedOvertimeItem.pjCode;
      projectCode.pjName = selectedOvertimeItem.pjName;
      this.props.commonProjectMobileStore!.setReturnPjValue(projectCode);
    } else {
      projectCode.pjCode = '';
      projectCode.pjName = '';
      this.setInitProjectInfo();
    }
  }

  setInitProjectInfo = () => {
    this.props.overtimeCalendarMobileStore!.getRecentlyProjectInfo().then((response: any) => {
      const obj = new ProjectModel({
        pjCode: response.pjCode,
        pjName: response.pjName
      });

      this.props.commonProjectMobileStore!.setReturnPjValue(obj);
    });
  };

  openProjectModal = () => {
    this.props.commonProjectMobileStore!.setVisibleModal(true);
  };

  activeElementBlur = () => {
    if (isTextInput(document.activeElement) && document.activeElement !== null) {
      (document.activeElement as HTMLElement).blur();
    }
  };

  onCancel = () => {
    this.activeElementBlur();
    setTimeout(() => {
      this.props.history.goBack();
    }, 200);
  };

  onSubmitAppr = () => {
    // 승인요청
    this.activeElementBlur();
    this.setState({ validationMessage: [] });

    this.props.form.validateFields({ force: true }, (error: any) => {
      if (!error) {
        const values = {
          ...this.props.form.getFieldsValue(),
          corpCode: this.props.overtimeCalendarMobileStore!.selectedOvertimeItem.corpCode,
          empNo: this.props.overtimeCalendarMobileStore!.selectedOvertimeItem.empNo,
          overTimeType: this.state.overTimeType,
          pjCode: this.props.commonProjectMobileStore!.returnPjCode,
          pjName: this.props.commonProjectMobileStore!.returnPjName,
          overTimeYmd: moment(this.props.form.getFieldsValue().overTimeYmd).format('YYYYMMDD'),
          rowStatus: this.state.status,
          apprSts: '1'
        };

        // if (this.state.status === 'update') {
        // this.props
        //   .overtimeCalendarMobileStore!.updateOverTime(values)
        //   .then(() => {
        this.props
          .overtimeCalendarMobileStore!.apprOvertimeValidation(values)
          .then((response: any) => {
            this.setState({ validationMessage: response });
            // Toast.fail(response, 2);

            if (response.length < 1) {
              Toast.success(MobileLanguageHelper.getMessage('overtime.toast.message.approvalreq'), 2);
              setTimeout(() => {
                this.props.history.goBack();
              }, 200);
            } else {
              this.onModalOpen(response);
            }
          })
          .catch((error: any) => {
            console.error(`Errors: ${error}`);
          });
        // })
        // .catch((error: any) => {
        //   console.error(`Errors: ${error}`);
        // });
        // } else {
        // this.props
        //   .overtimeCalendarMobileStore!.createOverTime(values)
        //   .then(() => {
        // this.props
        //   .overtimeCalendarMobileStore!.apprOvertimeValidation(values)
        //   .then((response: any) => {
        //     this.setState({ validationMessage: response });
        // Toast.fail(response, 2);

        //   if (response.length < 1) {
        //     Toast.success(MobileLanguageHelper.getMessage('overtime.toast.message.approvalreq'), 2);
        //     setTimeout(() => {
        //       this.props.history.goBack();
        //     }, 200);
        //   }
        // })
        // .catch((error: any) => {
        //   console.error(`Errors: ${error}`);
        // });
        // })
        // .catch((error: any) => {});
        // }
      } else {
        let message = '';
        for (let key in error) {
          error[key].errors.map((item: any, index: any) => {
            message = message + item.message;
          });
        }

        Toast.fail(message, 2);
      }
    });
  };

  onSubmitSave = () => {
    // 저장
    this.activeElementBlur();
    this.setState({ validationMessage: [] });

    this.props.form.validateFields({ force: true }, (error: any) => {
      if (!error) {
        const values = {
          ...this.props.form.getFieldsValue(),
          corpCode: this.props.overtimeCalendarMobileStore!.selectedOvertimeItem.corpCode,
          empNo: this.props.overtimeCalendarMobileStore!.selectedOvertimeItem.empNo,
          overTimeType: this.state.overTimeType,
          pjCode: this.props.commonProjectMobileStore!.returnPjCode,
          pjName: this.props.commonProjectMobileStore!.returnPjName,
          overTimeYmd: moment(this.props.form.getFieldsValue().overTimeYmd).format('YYYYMMDD'),
          rowStatus: this.state.status,
          apprSts: '1'
        };

        if (this.state.status === 'update') {
          this.props
            .overtimeCalendarMobileStore!.updateOverTime(values)
            .then((response: any) => {
              Toast.success(MobileLanguageHelper.getMessage('overtime.toast.message.save'), 2);
              setTimeout(() => {
                this.props.history.goBack();
              }, 200);
            })
            .catch((error: any) => {
              console.error(`Errors: ${error}`);
            });
        } else {
          this.props
            .overtimeCalendarMobileStore!.createOverTime(values)
            .then((response: any) => {
              Toast.success(MobileLanguageHelper.getMessage('overtime.toast.message.save'), 2);
              setTimeout(() => {
                this.props.history.goBack();
              }, 200);
            })
            .catch((error: any) => {});
        }
      } else {
        let message = '';
        for (let key in error) {
          error[key].errors.map((item: any, index: any) => {
            message = message + item.message;
          });
        }
        Toast.fail(message, 2);
      }
    });
  };

  validateOvertimeWorkHours = (rule: any, value: any, callback: any) => {
    const { selectedOvertimeItem } = this.props.overtimeCalendarMobileStore!;
    let timeMax = 0;

    if (this.props.overtimeCalendarMobileStore!.workType === 'A') {
      if (this.state.overTimeType === 'OT') {
        if (selectedOvertimeItem.holidayYn === 'Y') {
          timeMax = 21;
        } else {
          timeMax = 13;
        }
      } else if (this.state.overTimeType === 'AB') {
        timeMax = 8;
      }
    } else if (this.props.overtimeCalendarMobileStore!.workType === 'B') {
      if (this.state.overTimeType === 'OT') {
        if (selectedOvertimeItem.holidayYn === 'Y') {
          timeMax = 12;
        } else {
          timeMax = 4;
        }
      } else if (this.state.overTimeType === 'AB') {
        timeMax = 8;
      }
    }
    if (!isNum(this.props.form.getFieldsValue().overtimeWorkHours)) {
      callback(new Error(MobileLanguageHelper.getMessage('overtime.validation.message.time2')));
    } else if (this.props.form.getFieldsValue().overtimeWorkHours < 1) {
      callback(new Error(MobileLanguageHelper.getMessage('overtime.validation.message.time1')));
    } else if (this.props.form.getFieldsValue().overtimeWorkHours > timeMax) {
      callback(new Error(`${timeMax} ${MobileLanguageHelper.getMessage('overtime.validation.message.time3')}`));
    } else {
      callback();
    }
  };

  validatePjCode = (rule: any, value: any, callback: any) => {
    if (this.props.commonProjectMobileStore!.returnPjCode) {
      callback();
    } else {
      callback(new Error(MobileLanguageHelper.getMessage('overtime.validation.message.pjcode')));
    }
  };

  renderValidationMessage = (messages: string[]) => {
    let messagesElements: JSX.Element[];
    messagesElements = messages.map(item => {
      return (
        <div>
          <Icon type="warning" /> {item}
        </div>
      );
    });
    return messagesElements;
  };

  onOverTimeTypeValueChange = (value: any) => {
    if (value === MobileLanguageHelper.getMessage('overtime.button.title.overtime')) {
      this.setState({
        overTimeType: 'OT'
      });
    } else if (value === MobileLanguageHelper.getMessage('overtime.button.title.alterbreak')) {
      this.setState({
        overTimeType: 'AB'
      });
    }
  };

  selectIndex = (): number => {
    let index = 0;
    if (this.props.overtimeCalendarMobileStore!.selectedOvertimeItem !== undefined) {
      if (this.props.overtimeCalendarMobileStore!.selectedOvertimeItem.overTimeType === 'OT') index = 0;
      else if (this.props.overtimeCalendarMobileStore!.selectedOvertimeItem.overTimeType === 'AB') index = 1;
      else if (this.state.overTimeType === 'OT') index = 0;
      else if (this.state.overTimeType === 'AB') index = 1;
    } else if (this.state.overTimeType === 'OT') index = 0;
    else if (this.state.overTimeType === 'AB') index = 1;

    return index;
  };

  getValue = () => {
    const { selectedOvertimeItem } = this.props.overtimeCalendarMobileStore!;
    if (selectedOvertimeItem !== undefined) {
      if (selectedOvertimeItem.holidayYn === 'Y') {
        return [MobileLanguageHelper.getMessage('overtime.button.title.overtime')];
      } else {
        return [MobileLanguageHelper.getMessage('overtime.button.title.overtime'), MobileLanguageHelper.getMessage('overtime.button.title.alterbreak')];
      }
    }
  };

  render() {
    const { returnPjName } = this.props.commonProjectMobileStore!;
    const { selectedOvertimeItem } = this.props.overtimeCalendarMobileStore!;
    const { getFieldProps, getFieldError } = this.props.form;

    return (
      <>
        <InputForm>
          <WhiteSpace size="lg" />
          {/* <SegmentedControl values={['연장근로', '대체휴식']} selectedIndex={this.selectIndex()} onValueChange={this.onOverTimeTypeValueChange} /> */}
          <SegmentedControl values={this.getValue()} selectedIndex={this.selectIndex()} onValueChange={this.onOverTimeTypeValueChange} />
          <WhiteSpace size="lg" />
          {/* <List renderHeader={() => '투입일자'}> */}
          <List>
            <DatePicker
              mode="date"
              locale={enUs}
              title="일자 선택"
              extra="Optional"
              disabled={true}
              onChange={date => this.setState({ date })}
              {...getFieldProps('overTimeYmd', {
                initialValue: selectedOvertimeItem !== undefined ? new Date(moment(selectedOvertimeItem.overTimeYmd, 'YYYYMMDD').format('YYYY-MM-DD')) : now,
                rules: [{ required: true, message: MobileLanguageHelper.getMessage('overtime.validation.message.date') }]
              })}
            >
              <List.Item>{MobileLanguageHelper.getMessage('overtime.input.title.date')}</List.Item>
            </DatePicker>
          </List>
          <WhiteSpace size="lg" />
          <List>
            <InputItem
              {...getFieldProps('pjCode', {
                initialValue: returnPjName,
                rules: [{ validator: this.validatePjCode }]
              })}
              onClick={this.openProjectModal}
              value={returnPjName}
            >
              {MobileLanguageHelper.getMessage('overtime.input.title.pjdept')}
            </InputItem>
          </List>
          <WhiteSpace size="lg" />
          <List>
            <InputItem
              {...getFieldProps('overtimeWorkHours', {
                initialValue: selectedOvertimeItem !== undefined ? (selectedOvertimeItem.overtimeWorkHours !== null ? selectedOvertimeItem.overtimeWorkHours : 0) : 0,
                rules: [{ required: true, message: MobileLanguageHelper.getMessage('overtime.validation.message.time') }, { validator: this.validateOvertimeWorkHours }]
              })}
              type="digit"
              error={!!getFieldError('overtimeWorkHours')}
            >
              {MobileLanguageHelper.getMessage('overtime.input.title.time')}
            </InputItem>
          </List>
        </InputForm>
        <ValidatorArea>
          <div>
            {getFieldError('pjCode') ? <Icon type="warning" /> : null} {(getFieldError('pjCode') || []).join('\r\n')}
          </div>
          <div>
            {getFieldError('overtimeWorkHours') ? <Icon type="warning" /> : null} {(getFieldError('overtimeWorkHours') || []).join('\r\n')}
          </div>
          <div>{this.renderValidationMessage(this.state.validationMessage)}</div>
        </ValidatorArea>
        <FooterButton>
          <Flex>
            <Flex.Item>
              {/* <Link to="/m/timesheet/overtime/overtimecalendar"> */}
              <Button onClick={this.onCancel}>{MobileLanguageHelper.getMessage('overtime.button.title.cancel')}</Button>
              {/* </Link> */}
            </Flex.Item>
            <Flex.Item>
              <Button type="primary" onClick={this.onSubmitSave}>
                {MobileLanguageHelper.getMessage('overtime.button.title.save')}
              </Button>
            </Flex.Item>
          </Flex>
          <WhiteSpace size="lg" />
          <Flex>
            <Flex.Item>
              <Button type="primary" onClick={this.onSubmitAppr}>
                {MobileLanguageHelper.getMessage('overtime.button.title.approvalreqlong')}
              </Button>
            </Flex.Item>
          </Flex>
        </FooterButton>
        <CommonProjectMobileComponent />
        <Modal
          visible={this.state.modal}
          transparent
          maskClosable={false}
          onClose={() => this.onModalClose()}
          // title="Title"
          footer={[{ text: '확인', onPress: () => this.onModalClose() }]}
          // wrapProps={{ onTouchStart: this.onWrapTouchStart }}
        >
          <div>{this.state.modalContents}</div>
        </Modal>
      </>
    );
  }
}

const WrappedRegMobileWrapperContainerForm = rcForm.createForm()(RegMobileWrapperContainer);
export default withRouter(WrappedRegMobileWrapperContainerForm);
