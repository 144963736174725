import * as React from 'react';
import { observable, action, computed, toJS } from 'mobx';
import HrAssignReqModel from '../static/HrAssignReqModel';
import GroupModel from '../static/HrAssignReqTimeLineGroupModel';
import ItemModel from '../static/HrAssignReqTimeLineItemModel';
import HrAssignReqService from '../services/HrAssignReqService';
import { RootStore } from 'shared/stores/RootStore';
import moment from 'moment';
import StorageHelper from 'shared/utils/StorageHelper';
import { Badge } from 'antd-mobile';
export class HrAssignReqStore {
  @observable
  utilPlans: HrAssignReqModel[];

  @observable
  utilPlanProjectList: HrAssignReqModel[];

  @observable
  selectedUtilPlan: HrAssignReqModel;

  @observable
  inProgress: boolean;

  @observable
  selectedPjCode: string;

  @observable
  selectedPjName: string;

  @observable
  utilPlanProjects: HrAssignReqModel[];

  @observable
  utilPlanMemberGroup: HrAssignReqModel[];

  @observable
  personalSchedules: HrAssignReqModel[]; // 개인별 프로젝트 리스트

  @observable
  personalScheduleDtl: HrAssignReqModel[]; // 개인 가동율 상세

  @observable
  possibleInjctRates: HrAssignReqModel[]; // 기간내 투입 가능 일자 비율

  @observable
  memDetailSchedule: HrAssignReqModel[]; // 기간내 프로젝트별, 개인별, 기간별 상세 조회

  @observable
  selectedEmpList: HrAssignReqModel[]; // 선택된 인력추가 List

  @observable
  selectedRemoveEmpList: HrAssignReqModel[]; // 선택된 인력제외 List

  @observable
  groupModel: GroupModel[]; // Schedule Group

  @observable
  itemModel: ItemModel[]; // Schedule Item

  @observable
  memDtlDrawerVisible: boolean; // 개인별 가동율 상세 Drawer

  @observable
  modifyDrawerVisible: boolean; // 스캐쥴 수정 Drawer

  @observable
  selectedMemDtlRecord: HrAssignReqModel;

  @observable
  selectedScheduleId: number;

  @observable
  gridData: [];

  @observable
  modalResultGridData: [];

  @observable
  calendarData: [];

  @observable
  calendarModalVisible: boolean;

  @observable
  resultModalVisible: boolean;

  @observable
  utiliFullIncludeYn: boolean;

  @observable
  searchDeptCode: string | undefined;

  @observable
  searchDeptName: string | undefined;

  rootStore: RootStore;

  corpCode: string;

  constructor(rootStore: RootStore) {
    this.utilPlans = [];
    this.rootStore = rootStore;
    this.memDtlDrawerVisible = false;
    this.modifyDrawerVisible = false;
    this.calendarModalVisible = false;
    this.resultModalVisible = false;
    this.corpCode = String(StorageHelper.getItem(StorageHelper.KEY_CURR_CORP_CODE));
    this.gridData = [];
    this.modalResultGridData = [];
    this.calendarData = [];
    this.selectedEmpList = [];
    this.selectedRemoveEmpList = [];
    this.utiliFullIncludeYn = false;
  }

  @action
  getUtilPlans(pjSearch: String, pjType: String) {
    const arrayRoles: HrAssignReqModel[] = [];
    const { getCommonCodeName } = this.rootStore.systemLanguageStore;
    this.inProgress = true;
    return HrAssignReqService.getUtilPlans(pjSearch, pjType)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);

        const data = response.data.data || [];
        data.map((item: any, i: number) => {
          const role = new HrAssignReqModel({
            no: i + 1,
            corpCode: item.corpCode,
            pjCode: item.pjCode,
            pjName: item.pjName,
            pmEmpNo: item.pmEmpNo,
            pmEmpNm: item.pmEmpNm,
            pjStartDate: item.pjStartDate,
            pjEndDate: item.pjEndDate,
            pjStatus: item.pjStatus,
            pjStatusNm: getCommonCodeName('0012', item.pjStatus),
            pjType: item.pjType,
            pjTypeNm: getCommonCodeName('0013', item.pjType),
            injctMonth: item.injctMonth.toFixed(0)
          });

          arrayRoles.push(role);
        });

        this.utilPlanProjectList = arrayRoles;
        this.inProgress = false;
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  // 기간 내 투입 가능 비율 조회
  @action
  getUtilPlanPossibleInjctRateByPeriod(empNo: string, empNm: string, fromDt: string, toDt: string, deptCode: string) {
    const arrayRoles: HrAssignReqModel[] = [];
    this.inProgress = true;
    return HrAssignReqService.getUtilPlanPossibleInjctRateByPeriod(empNo, empNm, fromDt, toDt, deptCode)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);

        const data = response.data.data || [];
        let cnt = 1;
        data.map((item: any) => {
          // 가동율 100% 포함 여부 에 따른 분기
          if (this.utiliFullIncludeYn) {
            const role = new HrAssignReqModel({
              no: cnt,
              deptCode: item.deptCode,
              deptName: item.deptName,
              empNo: item.empNo,
              empName: item.empName,
              totInjctRate: item.totInjctRate.toFixed(0),
              positName: item.positName,
              jobName: item.jobName,
              pjStartDate: fromDt,
              pjEndDate: toDt
            });
            cnt += 1;
            arrayRoles.push(role);
          } else {
            if (Number(item.totInjctRate.toFixed(0) < 100)) {
              const role = new HrAssignReqModel({
                no: cnt,
                deptCode: item.deptCode,
                deptName: item.deptName,
                empNo: item.empNo,
                empName: item.empName,
                totInjctRate: item.totInjctRate.toFixed(0),
                positName: item.positName,
                jobName: item.jobName,
                pjStartDate: fromDt,
                pjEndDate: toDt
              });
              cnt += 1;
              arrayRoles.push(role);
            }
          }
        });

        this.possibleInjctRates = arrayRoles;
        this.inProgress = false;
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  initPossibleInjctRatesModel(): void {
    this.possibleInjctRates = [];
  }

  //  개인별, 기간별 스케쥴 조회
  @action
  getUtilPlanPersonalSchedule(empNo: String, fromDate: String, toDate: String) {
    const arrayRoles: HrAssignReqModel[] = [];
    this.inProgress = true;
    return HrAssignReqService.getUtilPlanPersonalSchedule(empNo, fromDate, toDate)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);

        const data = response.data.data || [];

        let cnt = 1;
        data.map((item: any) => {
          const role = new HrAssignReqModel({
            no: cnt,
            corpCode: item.corpCode,
            pjCode: item.pjCode,
            pjName: item.pjName,
            pmEmpNo: item.pmEmpNo,
            pmEmpNm: item.pmEmpNm,
            pjStartDate: item.injctStartDay,
            pjEndDate: item.injctEndDay,
            pjStatus: item.pjStatus,
            pjType: item.pjType,
            injctMonth: item.totInjctDay.toFixed(1),
            deptCode: item.deptCode,
            deptName: item.deptName,
            empName: item.empName,
            empNo: item.empNo,
            jobName: item.JobName,
            positName: item.positName
          });
          cnt++;
          arrayRoles.push(role);
        });
        // this.memDetailSchedule  = [];
        this.personalScheduleDtl = [];
        this.personalSchedules = arrayRoles;
        this.inProgress = false;
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  // 프로젝트별, 개인별, 개간별 스케쥴 조회
  @action
  getUtilPlanDetailPersonalSchedule(empNo: String, pjCode: String, fromDate: String, toDate: String) {
    const arrayRoles: HrAssignReqModel[] = [];
    this.inProgress = true;
    return HrAssignReqService.getUtilPlanDetailPersonalSchedule(empNo, pjCode, fromDate, toDate)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);

        const data = response.data.data || [];

        data.map((item: any, i: number) => {
          const role = new HrAssignReqModel({
            no: i + 1,
            corpCode: item.corpCode,
            pjCode: item.pjCode,
            pjName: item.pjName,
            injctStartDay: item.injctStartDay,
            injctEndDay: item.injctEndDay,
            actvType: item.actvType,
            injctRate: item.injctRate,
            deptCode: item.deptCode,
            deptName: item.deptName,
            empName: item.empName,
            empNo: item.empNo,
            jobName: item.JobName,
            positName: item.positName,
            apprSts: item.apprSts
          });
          arrayRoles.push(role);
        });
        this.memDetailSchedule = arrayRoles;
        this.inProgress = false;
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  // 프로젝트 개인별 프로젝트 상세 조회
  @action
  getUtilPlanProjectScheduleByEmpNo(empNo: String, from: String, to: String, pjCode: String) {
    const arrayRoles: HrAssignReqModel[] = [];
    this.inProgress = true;
    return HrAssignReqService.getUtilPlanProjectScheduleByEmpNo(empNo, from, to, pjCode)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);

        const data = response.data.data || [];

        let cnt = 1;
        data.map((item: any) => {
          const role = new HrAssignReqModel({
            no: cnt,
            corpCode: item.corpCode,
            pjCode: item.pjCode,
            pjName: item.pjName,
            injctYmd: item.injctYmd,
            injctDay: item.injctDay,
            injctRate: item.injctRate.toFixed(1)
          });
          cnt++;
          arrayRoles.push(role);
        });
        this.personalScheduleDtl = arrayRoles;
        this.inProgress = false;
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  // 프로젝트 소속 인원 조회
  @action
  getUtilPlanMemeberList() {
    const arrayRoles: HrAssignReqModel[] = [];
    this.possibleInjctRates = [];
    this.utilPlanMemberGroup = [];
    this.inProgress = true;
    return HrAssignReqService.getUtilPlanMemeberList(this.selectedPjCode, this.corpCode) // ProjectCode, CorpCode
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);

        const data = response.data.data || [];

        data.map((item: any, i: number) => {
          const role = new HrAssignReqModel({
            no: i + 1,
            pjCode: item.pjCode,
            empNo: item.empNo,
            empName: item.empName,
            deptCode: item.deptCode,
            deptName: item.deptName
          });
          arrayRoles.push(role);
        });
        this.utilPlanMemberGroup = arrayRoles;
        this.inProgress = false;
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  // 프로젝트 소속 인원 별 스케쥴 조회
  @action
  getUtilPlanMemberSchedule() {
    const arrayRoles: HrAssignReqModel[] = [];
    this.inProgress = true;

    return HrAssignReqService.getUtilPlanMemberSchedule(this.selectedPjCode, String(StorageHelper.getItem(StorageHelper.KEY_CURR_CORP_CODE)))
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);

        const data = response.data.data || [];

        data.map((item: any, i: number) => {
          const role = new HrAssignReqModel({
            no: i + 1,
            corpCode: item.corpCode,
            pjCode: item.pjCode,
            pmEmpNo: item.pmEmpNo,
            pjStartDate: item.pjStartDate,
            pjEndDate: item.pjEndDate,
            empName: item.empName,
            empNo: item.empNo
          });

          arrayRoles.push(role);
        });

        this.utilPlans = arrayRoles;

        this.groupModel = [];
        this.itemModel = [];

        this.groupModel = this.createTimeLineGroupModel(data);
        this.itemModel = this.createTimeLineItemModel(data);

        this.inProgress = false;
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  createTimeLineGroupModel(data: any) {
    const arrayRoles: GroupModel[] = [];
    this.inProgress = true;

    let cnt = 1;
    let bfEmpNo = '';
    let bfCnt = 1;

    data.map((item: any) => {
      if (cnt === 1) {
        const role = new GroupModel({
          id: item.empNo,
          title: item.empName + '(' + item.positName + ') '
        });

        cnt++;
        bfEmpNo = item.empNo;
        bfCnt++;
        arrayRoles.push(role);
      } else {
        if (bfEmpNo != item.empNo) {
          const role = new GroupModel({
            id: item.empNo,
            title: item.empName + '(' + item.positName + ') '
          });

          bfEmpNo = item.empNo;
          bfCnt++;
          arrayRoles.push(role);
        }
      }
    });
    this.inProgress = false;
    return arrayRoles;
  }

  createTimeLineItemModel(data: any) {
    const arrayRoles: ItemModel[] = [];
    this.inProgress = true;

    let cnt = 1;
    data.map((item: any) => {
      let bgColor = '#78d6c5'; // 100&

      // 승인 완료
      if (item.apprSts === 'S') {
        // if (item.injctRate < 100) {
        //   bgColor = '#006699';
        // } else {
        //   bgColor = '#9e9e9e';
        // }
        bgColor = '#9e9e9e';
      }

      // 작성중
      if (item.apprSts === '1') {
        // if (item.injctRate < 100) {
        //   bgColor = '#ccffcc';
        // } else {
        //   bgColor = '#78d6c5';
        // }
        bgColor = '#78d6c5';
      }

      // 배정요청
      if (item.apprSts === 'A') {
        // if (item.injctRate < 100) {
        //   bgColor = '#ccffff';
        // } else {
        //   bgColor = '#99ffff';
        // }
        bgColor = '#99ffff';
      }

      // 반려
      if (item.apprSts === 'R') {
        // if (item.injctRate < 100) {
        //   bgColor = '#ff6600';
        // } else {
        //   bgColor = 'f39c11';
        // }
        bgColor = 'f39c11';
      }

      const role = new ItemModel({
        id: cnt,
        group: item.empNo,
        title: `(${item.injctRate}%)`,
        start_time: moment(item.injctStartDay, 'YYYYMMDD').toDate(),
        end_time: moment(item.injctEndDay, 'YYYYMMDD')
          .add(1, 'day')
          .toDate(),
        data: item.injctRate,
        bgColor: bgColor,
        apprSts: item.apprSts
      });
      cnt++;
      arrayRoles.push(role);
    });

    this.inProgress = false;
    return arrayRoles;
  }

  // 프로젝트 스케쥴 조회
  // @action
  // getUtilPlanProjectSchedule(pjCode: String, corpCode: String) {
  //   const arrayRoles: HrAssignReqModel[] = [];
  //   this.inProgress = true;
  //   return HrAssignReqService.getUtilPlanMemberSchedule(pjCode, 'A001')
  //     .then(response => {
  //       this.rootStore.apiHandleStore.handleApiFail(response.data.header);

  //       const data = response.data.data || [];

  //       data.map((item: any, i: number) => {
  //         const role = new HrAssignReqModel({
  //           no: i + 1,
  //           corpCode: item.corpCode,
  //           pjCode: item.pjCode,
  //           pmEmpNo: item.pmEmpNo,
  //           pjStartDate: item.pjStartDate,
  //           pjEndDate: item.pjEndDate
  //         });
  //         arrayRoles.push(role);
  //       });
  //       this.utilPlans = arrayRoles;
  //       this.inProgress = false;
  //     })
  //     .catch(error => {
  //       this.rootStore.apiHandleStore.handleApiError(error);
  //       this.inProgress = false;
  //     });
  // }

  // 프로젝트 선택
  @action
  setSelectedUtilPlan(utilItem: HrAssignReqModel) {
    this.selectedUtilPlan = utilItem;
    this.selectedPjCode = utilItem.pjCode;
    this.selectedPjName = utilItem.pjName;
  }

  // 가동계획 Drawer
  @action
  setMemDtlVisibleDrawer(visible: boolean) {
    this.memDtlDrawerVisible = visible;
  }

  // 가동계획 조회용 Selected Data
  @action
  setMemDtlSelectedData(record: any) {
    this.selectedMemDtlRecord = record;
  }

  // 스케쥴 수정 Drawer
  @action
  setModifyDrawerVisible(visible: boolean) {
    this.modifyDrawerVisible = visible;
  }

  // MH 등록
  @action
  createMemberUtilPlan(model: HrAssignReqModel) {
    this.inProgress = true;
    return HrAssignReqService.createMemberUtilPlan(model)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  // MH 등록 (List)
  @action
  createMembersUtilPlans(pjCode: string, records: HrAssignReqModel[]) {
    const arrayRoles: HrAssignReqModel[] = [];

    records.map((record: any) => {
      const submitModel = new HrAssignReqModel({
        empNo: record.empNo,
        empName: record.empName,
        deptCode: record.deptCode,
        deptName: record.deptName,
        positName: record.positName,
        pjCode: pjCode,
        injctStartDay: record.pjStartDate,
        injctEndDay: record.pjEndDate,
        injctRate: record.insertRate ? record.insertRate / 100 : 1,
        injctTime: 8 * record.insertRate
      });
      arrayRoles.push(submitModel);
    });

    this.inProgress = true;
    return HrAssignReqService.createMembersUtilPlans(pjCode, arrayRoles)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);

        const data = response.data.data || [];
        data.map((v: any, i: any) => {
          v.no = i + 1;
        });

        this.modalResultGridData = data;
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  @action
  removeUtilPlanMemeber(empNo: String, pjCode: String) {
    this.inProgress = true;
    return HrAssignReqService.removeUtilPlanMember(empNo, pjCode)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  @action
  removeMembersUtilPlans(pjCode: string, record: HrAssignReqModel[]) {
    this.inProgress = true;
    return HrAssignReqService.removeMembersUtilPlans(pjCode, record)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  @action
  filterPossibleInjctRates(key: string) {
    this.possibleInjctRates = this.possibleInjctRates.filter(item => item.empNo !== key);
  }

  @action
  filterUtilPlanMemberGroup(key: string) {
    this.utilPlanMemberGroup = this.utilPlanMemberGroup.filter(item => item.empNo !== key);
  }

  @action
  addUtilPlanMemberGroup(item: HrAssignReqModel) {
    let size = this.utilPlanMemberGroup.length;

    this.filterUtilPlanMemberGroup(item.empNo);

    const obj = new HrAssignReqModel({
      no: size + 1,
      deptCode: item.deptCode,
      deptName: item.deptName,
      empNo: item.empNo,
      empName: item.empName,
      pjStartDate: item.pjStartDate,
      pjEndDate: item.pjEndDate
    });

    this.utilPlanMemberGroup.push(obj);
  }

  // 작성/수정 Data Load
  @action
  getMemberScheduleDtl(item: ItemModel) {
    this.selectedScheduleId = item.id;

    let fromDt = moment(item.start_time).format('YYYYMMDD');
    let toDt = moment(item.end_time).format('YYYYMMDD');
    let empNo = String(item.group);
    let pjCode = this.selectedPjCode;

    this.getUtilPlanDetailPersonalSchedule(empNo, pjCode, fromDt, toDt);
  }

  // 개인 승인요청
  @action
  memberAproveRequest() {
    const model = new HrAssignReqModel({
      empNo: this.memDetailSchedule[0].empNo,
      pjCode: this.memDetailSchedule[0].pjCode,
      injctStartDay: this.memDetailSchedule[0].injctStartDay,
      injctEndDay: this.memDetailSchedule[0].injctEndDay,
      apprSts: 'A'
    });

    this.inProgress = true;
    return HrAssignReqService.updateApproveStauts(model)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  // 전체 승인요청
  @action
  allMemberAproveRequest() {
    const model = new HrAssignReqModel({
      pjCode: this.selectedPjCode,
      apprSts: 'A'
    });

    this.inProgress = true;
    return HrAssignReqService.updateApproveStauts(model)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  // 전체 반려 삭제
  @action
  allRejectListRemove() {
    const model = new HrAssignReqModel({
      pjCode: this.selectedPjCode,
      apprSts: 'R'
    });

    this.inProgress = true;

    return HrAssignReqService.removeUtilPlanListByCriteria(model)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  // 개인, 프로젝트, 일정 별 내용 삭제
  @action
  removeUtilPlanMemberScheduleByPeriod() {
    let empNo = this.memDetailSchedule[0].empNo;
    let pjCode = this.memDetailSchedule[0].pjCode;
    let injctStartDay = this.memDetailSchedule[0].injctStartDay;
    let injctEndDay = this.memDetailSchedule[0].injctEndDay;

    this.inProgress = true;

    return HrAssignReqService.removeUtilPlanMemberScheduleByPeriod(empNo, pjCode, injctStartDay, injctEndDay)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  // 개인, 프로젝트, 일정 별 내용 변경
  @action
  updateUtilPlanContentByEmpNoAndPeriod(injctRate: number, actvType: string) {
    const model = new HrAssignReqModel({
      empNo: this.memDetailSchedule[0].empNo,
      pjCode: this.memDetailSchedule[0].pjCode,
      injctStartDay: this.memDetailSchedule[0].injctStartDay,
      injctEndDay: this.memDetailSchedule[0].injctEndDay,
      injctRate: injctRate / 100,
      actvType: actvType
    });

    this.inProgress = true;

    return HrAssignReqService.updateUtilPlanContentByEmpNoAndPeriod(model)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  @action
  modfiyItemModelRate(rate: number) {
    let key = this.selectedScheduleId;
    if (this.itemModel) {
      this.itemModel.map(item => {
        if (item.id === key) {
          item.title = '(' + String(rate) + '%)';
        }
      });
    }
  }

  @action
  createUpdateUtilPlansPeriod(item: HrAssignReqModel) {
    this.inProgress = true;

    return HrAssignReqService.createUpdateUtilPlansPeriod(item.empNo, item.pjCode, item)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  /////////////////////////////////////////////////////////////////////////
  /////////////////////// Grid Area Start /////////////////////////////////
  /////////////////////////////////////////////////////////////////////////

  // 프로젝트 소속 인원 별 스케쥴 조회
  @action
  getUtilPlanMemberGrid(pjCode: string): any {
    this.inProgress = true;
    const { getCommonCodeName } = this.rootStore.systemLanguageStore;
    return HrAssignReqService.getUtilPlanMemberGrid(pjCode, '', '')
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);

        this.gridData = [];

        const data = response.data.data || [];

        // reduce No. column + data type
        data.map((v: any, i: any) => {
          v.no = i + 1;
          v.formatInjctStartDay = moment(v.injctStartDay).format('YYYY-MM-DD');
          v.formatInjctEndDay = moment(v.injctEndDay).format('YYYY-MM-DD');
          v.injctRate = '-'; // 가동율
          v.type = 'parents';

          let apprCnt = 0;
          let wrtCnt = 0;
          let endCnt = 0;
          let rjctCnt = 0;

          v.children.map((s: any, i: any) => {
            let typeNm = getCommonCodeName('0002', s.apprSts);
            let fontColorNm = 'gray';

            s.formatInjctStartDay = moment(s.injctStartDay).format('YYYY-MM-DD');
            s.formatInjctEndDay = moment(s.injctEndDay).format('YYYY-MM-DD');
            s.rowType = 'child';
            s.injctRate = `${s.injctRate.toFixed(0)}%`;

            if (s.apprSts === 'S') {
              //
              fontColorNm = 'green';
              endCnt += 1;
            } else if (s.apprSts === 'A') {
              fontColorNm = '#AC1F8D';
              apprCnt += 1;
            } else if (s.apprSts === '1') {
              fontColorNm = 'gray';
              wrtCnt += 1;
            } else if (s.apprSts === 'R') {
              fontColorNm = 'red';
              rjctCnt += 1;
            }

            s.status = React.createElement('div', { style: { color: fontColorNm, fontWeight: 'bold' } }, typeNm);
          });
          v.wrtCnt = wrtCnt;
          v.apprCnt = apprCnt;
          v.endCnt = endCnt;
          v.rjctCnt = rjctCnt;
          // v.status = `작성(${wrtCnt}) / 요청(${apprCnt}) / 완료(${endCnt}) / 반려(${rjctCnt})`;
        });

        this.gridData = data;
        this.inProgress = false;
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  // Get Calendar Data
  @action
  getUtilPlanCalendarData(empNo: string, pjCode: string) {
    this.inProgress = true;
    return HrAssignReqService.getUtilPlanCalendarData(empNo, pjCode, moment().format('YYYY'))
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);

        const data = response.data.data || [];

        // reduce No. column + data type
        // data.map((v: any, i: any) => {
        //   return null;
        //   console.log(v.injctYm);
        //   if (Number(v.injctYm.substring(4, 6)) === 1) {
        //   }
        // });

        // this.gridData = data;
        this.calendarData = data;
        this.inProgress = false;
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  // Calendar Modal
  @action
  setCalendarModalVisible(visible: boolean) {
    this.calendarModalVisible = visible;
  }

  // Check Include Utlization 100%
  @action
  setUtiliFullIncludeYn(flag: boolean) {
    this.utiliFullIncludeYn = flag;
  }

  // Common Department 영역
  //Drawer 영역
  @computed
  get selectedDepartment() {
    this.searchDeptCode = this.rootStore.departmentStore.selectedDept.deptCode;
    this.searchDeptName = this.rootStore.departmentStore.selectedDept.deptName;
    return this.rootStore.departmentStore.selectedDept;
  }

  async fetchDepartmentForSearchPanel() {
    if (toJS(this.rootStore.departmentStore.departmentList.length) === 0) {
      await this.rootStore.departmentStore.searchDepartments({});
    }
  }

  @action
  reset() {
    this.rootStore.departmentStore.departmentList = [];
  }

  // 인력추가시 선택 된 Row
  @action
  setSelectedEmpList(selectedRows: HrAssignReqModel[]) {
    this.selectedEmpList = selectedRows;
  }
  // 인력추가된 Row 초기화
  @action
  resetSelectedEmpList() {
    this.selectedEmpList = [];
  }

  // Show/Hide Result Modal
  @action
  setResultModalVisible(visible: boolean) {
    this.resultModalVisible = visible;
  }

  // Reset Result data
  @action
  resetResultGridData() {
    this.modalResultGridData = [];
  }

  // 인력제외시 선택 된 Row
  @action
  setSelectedRemoveEmpList(selectedRows: HrAssignReqModel[]) {
    this.selectedRemoveEmpList = selectedRows;
  }

  // 인력추가된 Row 초기화
  @action
  resetSelectedRemoveEmpList() {
    this.selectedRemoveEmpList = [];
  }
}

export default HrAssignReqStore;
