import EmployeeModel from 'shared/models/employee/EmployeeModel';

class EmployeeCountModel {
  userName: string;
  userId: string;
  empList: EmployeeModel[];

  constructor(obj: any | EmployeeCountModel) {
    this.userName = obj.userName;
    this.userId = obj.userId;
    this.empList = obj.empList;
  }
}

export default EmployeeCountModel;
