import ApiHelper from 'shared/utils/ApiHelper';
import ProjectModel from 'shared/models/project/ProjectModel';

const API_BASE = '/timesheet';

class ProjectService {
  getProjects(reqObj: any) {
    const api = `${API_BASE}/projects`;
    return ApiHelper.get(api, reqObj);
  }

  // Create Project
  createProject(arr: ProjectModel[]) {
    const api = `${API_BASE}/projects`;
    return ApiHelper.post(api, arr);
  }

  // Update Project
  updateProject(arr: ProjectModel[]) {
    const api = API_BASE + '/projects';
    const returnVal = ApiHelper.put(api, arr);

    return returnVal;
  }

  // Create Project Code
  createProjectCode(pjCreateType: string, pjType: string) {
    const api = `${API_BASE}/projects/pjCode/${pjCreateType}/${pjType}`;
    return ApiHelper.get(api);
  }
}
export default new ProjectService();
