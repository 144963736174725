import axios, { AxiosResponse } from 'axios';
import IBaseRepository from './IBaseRepository';
import IBaseModel from '../models/IBaseModel';
import AppConst from '../../../shared/utils/AppConst';
import ApiHelper from '../../../shared/utils/ApiHelper';

export default abstract class BaseRepository<T extends IBaseModel> implements IBaseRepository<T> {
  ApiPath: string;
  constructor(ApiPath: string) {
    this.ApiPath = ApiPath;
  }

  find(params: any): Promise<AxiosResponse<T[]>> {
    return ApiHelper.get(this.ApiPath, params);
  }

  export(params: any): Promise<AxiosResponse<T[]>> {
    return ApiHelper.get(`${this.ApiPath}/export`, params);
  }

  get(id: string | number): Promise<AxiosResponse<T>> {
    return ApiHelper.get(this.ApiPath + id);
  }

  create(t: T): Promise<AxiosResponse<T>> {
    return ApiHelper.post(this.ApiPath, t);
  }

  createArray(array: T[]): Promise<AxiosResponse<T>> {
    return ApiHelper.post(this.ApiPath, array);
  }

  update(id: string | number, t: T): Promise<AxiosResponse<T>> {
    return ApiHelper.put(this.ApiPath + id, t);
  }

  updateArray(array: T[]): Promise<AxiosResponse<T>> {
    return ApiHelper.put(this.ApiPath, array);
  }

  delete(id: string | number): Promise<AxiosResponse<T>> {
    return ApiHelper.delete(this.ApiPath, id);
  }

  deleteArray(array: any[]): Promise<AxiosResponse<T>> {
    return ApiHelper.delete(this.ApiPath, array);
  }
}
