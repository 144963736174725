import React from 'react';
import Loadable from 'react-loadable';
// import registeredPathComponents from './registeredPathComponents.json';
import registeredPathComponentsTimesheet from './registeredPathComponentsTimesheet.json';

// TODO: Fix codespliting later. Cause by imported json from LanguageHelper
const getComponent = (path: string) =>
  Loadable({
    loader: () => import(/* webpackChunkName: "program_view" */ `pages-mobile/${path}`),
    loading: () => <div>로딩중...</div>
  });

const registeredPaths = registeredPathComponentsTimesheet;
const bucketRegisteredComponentWithPath = registeredPaths.reduce((map, key) => {
  map.set(key, getComponent(key));
  return map;
}, new Map());

export { bucketRegisteredComponentWithPath };
