import _ from 'lodash';
import { action, computed, observable } from 'mobx';
import TreeNode from 'shared/components/TreeList/TreeNode';
import { DepartmentModel } from 'shared/models/department/DepartmentModel';
import { RootStore } from 'shared/stores/RootStore';
import AppConst from 'shared/utils/AppConst';
import AppHelper from 'shared/utils/AppHelper';
import LanguageHelper from 'shared/utils/LanguageHelper';
import uuid from 'uuid';
import DepartmentService from '../services/DepartmentService';
import { DepartmentType } from '../static/constant';

export interface ISearchParameter {
  departmentCode?: string;
  departmentName?: string;
  useYn?: string;
}

const ROOT_NODE_DEPTCODE = '/'; // avoid duplicate with deptCode='' will recursive
export class DepartmentStore {
  @observable
  departmentList: DepartmentModel[] = [];

  @observable
  selectedDept: DepartmentModel;

  @observable
  lastSearch: any;

  @observable
  expandedKeys: string[];

  @observable
  expandedDeptCode: string[];

  @observable
  inProgress = false;

  // to log  search parameter => can re-use latter
  @observable
  searchParameter: ISearchParameter = {};

  rootStore: RootStore;
  // departmentHistoryStore: DepartmentHistoryStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action
  save() {
    const self = this;
    this.inProgress = true;

    const _insert = this.departmentList
      .filter(x => x.changeStatus === 'CREATE')
      .map(y => {
        y.deptCode = AppHelper.removeSpacesOfString(y.deptCode);
        y.sectCode = AppHelper.removeSpacesOfString(y.sectCode || '');
        y.divCode = AppHelper.removeSpacesOfString(y.divCode || '');
        return y;
      });
    const _update = this.departmentList.filter(x => x.changeStatus === 'UPDATE');
    const _delete = this.departmentList.filter(x => x.changeStatus === 'DELETE');
    const _deleteIds = _delete
      .filter(x => x.deptCode !== undefined)
      .map(y => {
        return {
          deptCode: y.deptCode,
          endDate: y.endDate
        };
      });

    if (_insert.length === 0 && _update.length === 0 && _deleteIds.length === 0) {
      this.inProgress = false;
      AppHelper.showMessage('warning', LanguageHelper.getMessage('message.nothing.submit'));
      return;
    }

    const insertPromise = DepartmentService.createArray(_insert);
    const updatePromise = DepartmentService.updateArray(_update);
    const deletePromise = DepartmentService.deleteArray(_deleteIds);

    const promises: Array<Promise<any>> = [];
    if (_insert.length > 0) promises.push(insertPromise);
    if (_update.length > 0) promises.push(updatePromise);
    if (_deleteIds.length > 0) promises.push(deletePromise);

    Promise.all(promises)
      .then(function(responses: any) {
        self.rootStore.apiHandleStore.handleAllApiResult(responses);
        const { searchParameter } = self;
        self.searchDepartments(searchParameter);
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  getDeptType = (dept: DepartmentModel) => {
    if (dept.upperDeptCode === ROOT_NODE_DEPTCODE) return DepartmentType.Root;
    if (dept.deptCode === dept.sectCode) return DepartmentType.Sector;
    if (dept.deptCode === dept.divCode) return DepartmentType.Division;
    if (dept.deptCode !== dept.sectCode && dept.deptCode !== dept.divCode) return DepartmentType.Department;
  };

  getTeamYnByParentType = (parentType: string) => {
    switch (parentType) {
      case DepartmentType.Root:
        return AppConst.YN_NO;
      case DepartmentType.Sector:
        return AppConst.YN_NO;
      case DepartmentType.Division:
        return AppConst.YN_YES;
      default:
        return AppConst.YN_YES;
    }
  };

  getDeptTypeByParentType = (parentType: string) => {
    switch (parentType) {
      case DepartmentType.Root:
        return DepartmentType.Sector;
      case DepartmentType.Sector:
        return DepartmentType.Division;
      case DepartmentType.Division:
        return DepartmentType.Department;
    }
  };

  getUpperDept = (data: any, upperDeptCode: string) => {
    const dept = data.find((x: any) => x.deptCode === upperDeptCode);
    if (dept) {
      const _deptType = this.getDeptType(dept);
      const { deptCode, deptName } = dept;

      return {
        deptCode,
        deptName,
        deptType: _deptType
      };
    }
  };

  @action
  async searchDepartments({ departmentCode, departmentName, useYn }: ISearchParameter) {
    this.inProgress = true;
    this.rootStore.departmentHistoryStore.setDataSource([]);

    try {
      const apiResult: any = await DepartmentService.searchDepartments({ departmentName, departmentCode, useYn });
      this.rootStore.apiHandleStore.handleApiFail(apiResult.data.header);

      const arrDepartment = apiResult.data.data.map((value: any) => {
        const upperDepartment = this.getUpperDept(apiResult.data.data, value.upperDeptCode);
        value.upperDeptCode = value.upperDeptCode || ROOT_NODE_DEPTCODE;
        value.upperDeptName = upperDepartment ? upperDepartment.deptName : undefined;
        value.deptType = this.getDeptType(value);
        value.upperDepartment = upperDepartment;
        value.changeStatus = AppConst.ActionType.READ;
        return new DepartmentModel(value);
      });

      this.departmentList = arrDepartment;
      this.searchParameter = { departmentCode, departmentName, useYn };
      this.setSelectedDepartment('');
      // Expand Previous keys
      this.expandedKeys = this.getPreviousKey();
    } catch (error) {
      this.rootStore.apiHandleStore.handleApiError(error);
    }
    this.inProgress = false;
  }

  @action
  createDept(): DepartmentModel | undefined {
    const selectedDept = this.selectedDept!;
    if (selectedDept) {
      const deptId = uuid.v4();
      const deptModel = new DepartmentModel({
        id: deptId,
        deptName: 'New Department',
        corpCode: selectedDept.corpCode,
        upperDeptCode: selectedDept.deptCode,
        upperDeptName: selectedDept.deptName,
        useYn: 'Y',
        virtualDeptYn: 'Y',
        teamYn: this.getTeamYnByParentType(selectedDept.deptType),
        deptType: this.getDeptTypeByParentType(selectedDept.deptType),
        startDate: AppHelper.getStartDateByTodayInKoreanTimestamp(),
        endDate: AppConst.DEFAULT_UNIX_END_DATE,
        updatedDate: null,
        priority: 1,
        changeStatus: 'CREATE'
      });
      if (selectedDept.deptType === DepartmentType.Root) {
        deptModel.divCode = '';
        deptModel.divName = '';
        deptModel.sectCode = '';
        deptModel.sectName = 'New Department';
      } else if (selectedDept.deptType === DepartmentType.Sector) {
        deptModel.divCode = '';
        deptModel.divName = 'New Department';
        deptModel.sectCode = selectedDept.deptCode;
        deptModel.sectName = selectedDept.deptName;
      } else if (selectedDept.deptType === DepartmentType.Division) {
        deptModel.divCode = selectedDept.deptCode;
        deptModel.divName = selectedDept.deptName;
        deptModel.sectCode = selectedDept.upperDepartment.deptCode;
        deptModel.sectName = selectedDept.upperDepartment.deptName;
      }

      this.departmentList.push(deptModel);
      this.setSelectedDepartment(deptId);
      return deptModel;
    }
  }

  @computed
  get treeData(): TreeNode[] {
    const getNodeText = (deptName: string, deptCode: string) => {
      if (!deptCode) return deptName;
      return deptName ? `${deptName}-${deptCode}` : deptCode;
    };

    return this.departmentList.map(
      x =>
        new TreeNode({
          id: x.id,
          parentCode: x.upperDeptCode,
          valueCode: x.deptCode,
          nodeText: getNodeText(x.deptName, x.deptCode),
          changeStatus: x.changeStatus
        })
    );
  }

  isDuplicateDeptCode(deptCode: string): boolean {
    const codes = this.departmentList.find(x => x.deptCode === deptCode);
    return codes != null;
  }

  @computed
  get treeDepartment(): any[] {
    /**
     * Ref: http://jsfiddle.net/tx3uwhke/
     * @param data
     * @param parent
     */

    function buildTree(data: any, parent?: any): any[] {
      const result: any[] = [];
      parent = typeof parent !== 'undefined' ? parent : { valueCode: ROOT_NODE_DEPTCODE };
      const children = _.filter(data, function(child: TreeNode) {
        return child.parentCode === parent.valueCode && child.id !== parent.id;
      });

      if (!_.isEmpty(children)) {
        _.each(children, function(child) {
          if (child != null) {
            result.push(child);

            if (child.changeStatus !== AppConst.ActionType.CREATE) {
              const ownChildren = buildTree(data, child);
              if (!_.isEmpty(ownChildren)) {
                child.isParent = true;
                child.children = ownChildren;
              } else {
                child.isParent = false;
              }
            }
          }
        });
      }

      return result;
    }

    const tree = buildTree(this.treeData);

    return tree;
  }

  @action
  setSelectedDepartment(deptId: string | number) {
    this.selectedDept = this.departmentList.find(x => x.id === deptId)!;
    if (this.selectedDept) {
      this.rootStore.departmentHistoryStore.searchModel = {
        deptCode: this.selectedDept.deptCode
      };
      this.rootStore.departmentHistoryStore.find();
    }
  }

  @action
  setExpandedKeys(expandedKeys: string[]) {
    this.expandedKeys = expandedKeys;
    this.expandedDeptCode = this.departmentList.filter(x => expandedKeys.includes(x.id)).map(y => y.deptCode);
  }

  @action
  getPreviousKey() {
    if (this.expandedDeptCode) {
      const expandedKeys = this.departmentList.filter(x => this.expandedDeptCode.includes(x.deptCode)).map(y => y.id);
      return expandedKeys;
    }
    return [];
  }

  @action
  updateDept(deptId: string, partial: object) {
    const selectedDeptIndex = this.departmentList.findIndex(x => x.id === deptId)!;
    if (selectedDeptIndex !== -1) {
      const selectedDept = this.departmentList[selectedDeptIndex];
      const changedDept = { ...selectedDept, ...partial };
      this.selectedDept = changedDept;
      this.departmentList[selectedDeptIndex] = changedDept;
    }
  }

  @action
  exportExcel({ departmentCode, departmentName, useYn }: ISearchParameter) {
    return DepartmentService.exportExcelFile({ departmentCode, departmentName, useYn })
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const url = response.data.data.url || '';
        if (url && url !== '') {
          AppHelper.downloadExcel(url);
        }
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  }

  @action
  onDelete = () => {
    if (this.selectedDept!) {
      this.updateDept(this.selectedDept!.id, {
        changeStatus: 'DELETE'
      });
    }
  };

  @action
  resetStore = () => {
    this.departmentList = [];
    this.setSelectedDepartment!('');
    this.setExpandedKeys!([]);
    this.lastSearch = null;
  };
}
