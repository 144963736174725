import { Button, Col, Form, Icon, Input, Result, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { AuthStore } from '../../../shared/stores/AuthenticationStore';
import '../static/style.less';

export interface ILoginProps extends FormComponentProps {
  showModal: boolean;
  loginError: boolean;
  handleLogin: Function;
  handleClose: Function;
}

export interface IProps extends FormComponentProps, RouteComponentProps<any> {
  authStore?: AuthStore;
}

@inject('authStore')
@observer
class PasswordFinderFormContainer extends React.Component<IProps, any> {
  state = {
    isMoveBackToLogin: false,
    isPasswordResetEmailSent: false
  };

  /**
   * Handle Login action
   */
  handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { userName, email } = values;
        this.props.authStore!.findPassword(userName, email).then(() => {
          if (this.props.authStore!.isPasswordResetEmailSent) {
            this.setState({
              isMoveBackToLogin: false,
              isPasswordResetEmailSent: true
            });
          }
        });
      }
    });
  };

  getPasswordFinderForm = () => {
    const {
      form: { getFieldDecorator }
    } = this.props;
    return (
      <div>
        <h1 style={{ color: '#fff', textAlign: 'center', fontSize: 40, paddingBottom: 20 }}>
          <Icon type="clock-circle" style={{ fontSize: 50 }} />
          <span style={{ paddingLeft: 30 }}>TimeSheet</span>
        </h1>

        <Form style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '2em' }} onSubmit={this.handleSubmit}>
          <h1 style={{ color: '#fff', textAlign: 'center', fontSize: 20 }}>비밀번호 찾기</h1>
          <Form.Item label="아이디" className="pwd-finder-form-label">
            {getFieldDecorator('userName', {
              rules: [
                {
                  required: true,
                  message: 'Username 은(는) 필수 항목입니다'
                }
              ]
            })(<Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="내용을 입력해주세요" allowClear />)}
          </Form.Item>
          <Form.Item label="이메일 주소" className="pwd-finder-form-label">
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  required: true,
                  message: '형식에 맞는 이메일 주소를 입력해주세요'
                }
              ]
            })(<Input prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="내용을 입력해주세요" allowClear />)}
          </Form.Item>

          <div>
            <Row gutter={20}>
              <Col span={12}>
                <Button
                  block
                  type="default"
                  className="pwd-finder-back-button"
                  onClick={() => {
                    return this.setState({ isMoveBackToLogin: true });
                  }}
                >
                  뒤로 가기
                </Button>
              </Col>
              <Col span={12}>
                <Button block type="primary" htmlType="submit">
                  인증번호 발송
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    );
  };

  getPasswordResetEmailSentForm = () => {
    return (
      <div className="password-reset-email-sent-form">
        <Result
          status="success"
          title="비밀번호 재설정 이메일 전송 완료"
          subTitle="입력하신 주소로 이메일이 전송되었습니다. 이메일에 포함된 지침에 따라 비밀번호를 재설정하십시오."
        />
      </div>
    );
  };

  render() {
    const { isMoveBackToLogin, isPasswordResetEmailSent } = this.state;

    if (isMoveBackToLogin) {
      return <Redirect to="/" />;
    }

    return <div>{isPasswordResetEmailSent ? this.getPasswordResetEmailSentForm() : this.getPasswordFinderForm()}</div>;
  }
}

const WrappedPasswordFinderFormContainer = Form.create({ name: 'normal_login' })(PasswordFinderFormContainer);
export default WrappedPasswordFinderFormContainer;
