import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import { Icon } from 'antd';
import { DatePicker, List, InputItem, Toast } from 'antd-mobile';
import moment from 'moment';
import OvertimeAppMobileStore from '../stores/OvertimeAppMobileStore';
import CommonProjectMobileStore from '../../common/stores/CommonProjectMobileStore';
import CommonProjectMobileComponent from '../../common/containers/CommonProjectMobileComponent';
import enUs from 'antd-mobile/lib/date-picker/locale/en_US';
import MobileLanguageHelper from 'shared/utils/MobileLanguageHelper';

const rcForm = require('rc-form');

interface Props {
  overtimeAppMobileStore?: OvertimeAppMobileStore;
  commonProjectMobileStore?: CommonProjectMobileStore;
  form?: any;
}

const SerchBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SearchButton = styled.div`
  width: 55px;
  line-height: 84px;
  text-align: center;
  font-size: 24px;
  font-weight: 800;
  margin-left: 5px;
  background-color: #e0e0e0;
`;

@inject('commonProjectMobileStore')
@inject('overtimeAppMobileStore')
@observer
class AppMobileSearchContainer extends Component<Props> {
  openProjectModal = () => {
    this.props.commonProjectMobileStore!.setVisibleModal(true);
  };

  onSubmit = () => {
    this.props.form.validateFields({ force: true }, (error: any) => {
      if (!error) {
        this.props.overtimeAppMobileStore!.setSeletedPjCode(this.props.commonProjectMobileStore!.returnPjCode);
        this.props.overtimeAppMobileStore!.setSelectYyyymm(moment(this.props.form.getFieldsValue().yearMonth).format('YYYYMM'));

        this.props.overtimeAppMobileStore!.getGridData(this.props.commonProjectMobileStore!.returnPjCode, moment(this.props.form.getFieldsValue().yearMonth).format('YYYYMM'));
      } else {
        let message = '';
        for (let key in error) {
          error[key].errors.map((item: any, index: any) => {
            message = message + item.message;
          });
        }
        Toast.fail(message, 2);
      }
    });
  };

  render() {
    const { getFieldProps, getFieldError } = this.props.form;
    const { returnPjName } = this.props.commonProjectMobileStore!;

    return (
      <>
        <SerchBoxWrapper>
          <div>
            <List>
              <DatePicker
                mode="month"
                locale={enUs}
                title="조회년월 선택"
                extra="Optional"
                // format="YYYY-MM"
                format={val => moment(val).format('YYYY-MM')}
                // onChange={date => this.setState({ date })}
                {...getFieldProps('yearMonth', {
                  initialValue: new Date(moment(this.props.overtimeAppMobileStore!.nowWorkSysYearMonth, 'YYYYMM').format('YYYY/MM/DD')),
                  rules: [{ required: true, message: '조회년월은 필수입력 값입니다.' }]
                })}
              >
                <List.Item>{MobileLanguageHelper.getMessage('overtime.input.title.yyyymm')}</List.Item>
              </DatePicker>
              <InputItem
                {...getFieldProps('pjCode', {
                  initialValue: returnPjName
                })}
                onClick={this.openProjectModal}
                value={returnPjName}
              >
                {MobileLanguageHelper.getMessage('overtime.input.title.pjdept')}
              </InputItem>
            </List>
            <CommonProjectMobileComponent searchPattern="pmg" />
          </div>
          <SearchButton onClick={this.onSubmit}>
            <Icon type="search" />
          </SearchButton>
        </SerchBoxWrapper>
      </>
    );
  }
}

const WrappedAppMobileHeaderContainerForm = rcForm.createForm()(AppMobileSearchContainer);
export default WrappedAppMobileHeaderContainerForm;
