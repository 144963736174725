import React from 'react';
import { Spin, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';

const LoadingFullScreen = ({ shouldShow, message }: any) => {
  const antIcon = (
    <div style={{ maxHeight: 'none', width: '150px', marginLeft: -70 }}>
      <Icon type="loading" style={{ fontSize: 35 }} spin />
      <div style={{ maxHeight: '50px', paddingTop: 20 }}>{message || <FormattedMessage id="text.loading" />}</div>
    </div>
  );
  return (
    <div>
      {shouldShow && (
        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, textAlign: 'center', zIndex: 9999, background: 'rgba(0,0,0,0.2)' }}>
          <Spin style={{ marginTop: '50vh ' }} spinning={shouldShow} indicator={antIcon} />
        </div>
      )}
    </div>
  );
};

export default LoadingFullScreen;
