import ApiHelper from 'shared/utils/ApiHelper';
import AppConst from 'shared/utils/AppConst';
import MenuHelpModel, { TMenuHelpContent, ELangKey } from 'shared/models/menuHelp/MenuHelpModel';

const API_SYS_HELP = AppConst.API.SYS_HELP;

class MenuHelpService {
  submitHelpContent = async (selectedMenuHelp: MenuHelpModel, lang: ELangKey, markdown: string) => {
    // content means image + text
    const params = {
      menuCode: selectedMenuHelp.menuCode,
      content: {}
    };

    if (selectedMenuHelp.content) {
      selectedMenuHelp.content.map((obj: TMenuHelpContent) => {
        params.content = {
          ...params.content,
          ...{
            [`${obj.language}`]: {
              text: obj.contentText,
              imageList: []
            }
          }
        };
        return params.content;
      });
    }

    params.content = {
      ...{
        KO: {
          text: '',
          imageList: []
        },
        EN: {
          text: '',
          imageList: []
        }
      },
      ...params.content,
      [`${lang}`]: {
        text: markdown,
        imageList: []
      }
    };

    const exist = await this.checkHelpMenuItemExist(selectedMenuHelp.menuCode);
    if (exist === 'EXIST') {
      return ApiHelper.put(API_SYS_HELP, params);
    }
    return ApiHelper.post(API_SYS_HELP, params);
  };

  getMenuHelpByMenuCode = (menuCode: string) => {
    return ApiHelper.get(`${API_SYS_HELP}/content/${menuCode}`);
  };

  uploadImages = (menuCode: string, arr: TMenuHelpContent[]) => {
    const params = {
      menuCode,
      content: {}
    };

    arr.map(obj => {
      params.content = {
        ...params.content,
        [`${obj.language}`]: {
          text: obj.contentText,
          imageList: obj.contentImages
        }
      };
    });
    console.log(params);
    // ApiHelper.post(`${API_SYS_HELP}/images`, params);
  };

  checkHelpMenuItemExist = async (menuCode: string) => {
    let result = '';
    const rs = await ApiHelper.get(`${API_SYS_HELP}/exist/${menuCode}`);
    if (rs.data && rs.data.data) {
      result = 'EXIST';
    } else if (rs.data && !rs.data.data) {
      result = 'NONEXIST';
    } else {
      result = 'UNKNOWN';
    }
    return result;
  };
}

export default new MenuHelpService();
