import ApiHelper from 'shared/utils/ApiHelper';
import AppConst from 'shared/utils/AppConst';
import { ISearchParameter } from '../stores/AuthorityStore';

class RoleService {
  getAuthorityByCondition = (params: any) => {
    if (params && Object.keys(params).length) {
      return ApiHelper.get(AppConst.API.SYS_AUTHORITY_ROLE, params);
    }
    return ApiHelper.get(AppConst.API.SYS_AUTHORITY_ROLE);
  };

  getAssignmentTaskBycondition = (roleCode: string, deptName: string, empName: string) => {
    const params = {
      roleCode,
      deptName,
      empName
    };
    return ApiHelper.get(AppConst.API.SYS_AUTHORITY, params);
  };

  deleteAssignmentTask = (arr: any[]) => {
    return ApiHelper.delete(AppConst.API.SYS_AUTHORITY, arr);
  };

  insertAssignmentTask = (arr: any[]) => {
    return ApiHelper.post(AppConst.API.SYS_AUTHORITY, arr);
  };

  updateAssignmentTask = (arr: any[]) => {
    return ApiHelper.put(AppConst.API.SYS_AUTHORITY, arr);
  };

  exportExcelFile = (params: ISearchParameter) => {
    const api = AppConst.API.SYS_AUTHORITY_EXPORT;
    return ApiHelper.get(api, params);
  };
}

export default new RoleService();
