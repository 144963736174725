import React from 'react';
import moment from 'moment';
import { Provider } from 'mobx-react';
import './MobileApp.less';
import Routes from 'mobile-routes';
import { MobileRootStore } from 'shared/mobile-stores/RootStore';
import MobileLoading from './MobileLoading';

moment.locale('en');

export const rootStore = new MobileRootStore();

class HRM_MApp extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.setupForMobile();
  }
  /**
   * this code from ant mobile
   * https://mobile.ant.design/docs/react/introduce#3.-Usage
   */
  setupForMobile() {
    if ('addEventListener' in document) {
      document.addEventListener('DOMContentLoaded', function() {
        // @ts-ignore
        FastClick.attach(document.body);
      }, false);
    }
    // @ts-ignore
    if(!window.Promise) {
      document.writeln('<script src="https://as.alipayobjects.com/g/component/es6-promise/3.2.2/es6-promise.min.js"'+'>'+'<'+'/'+'script>');
    }
  }

  render() {
    return (
      <Provider {...rootStore}>
        <MobileLoading />
        <Routes />
      </Provider>
    );
  }
}

export default HRM_MApp;