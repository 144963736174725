import { MobileRootStore } from 'shared/mobile-stores/RootStore';
import { action, computed, observable, toJS } from 'mobx';
import { Toast } from 'antd-mobile';
import ApiHelper from 'shared/utils/ApiHelper';
import AppConst from 'shared/utils/AppConst';
import MenuModel from 'shared/mobile-models/sidebar/SidebarModel';
import uuid from 'uuid';
import InitMenuData from './InitMenuData';

export class MobileHeaderSidebarStore {
  // store to manage authorization features
  rootStore: MobileRootStore;

  @observable
  inProgress: boolean;

  @observable
  menuAssignedList: any[] = [];

  @observable
  openedMenu: string[] = [];

  @observable
  activeMenu = '';

  private _menuToHide = ['user_info', 'overtimereg_detail', 'overtimeapp_detail', 'utilResultreg_detail', 'utilResultapp_detail'];

  constructor(rootStore: MobileRootStore) {
    this.rootStore = rootStore;
  }

  @action
  getMenuAssigned() {
    // should not call API when user not logged
    if (!this.rootStore.authStore.isLogged) {
      return true;
    }

    this.inProgress = true;
    return ApiHelper.get(AppConst.API.SYS_MENU_ASSIGNED_MOBILE)
      .then((rs: any) => {
        const { isSuccessful } = rs.data.header;
        if (!isSuccessful) {
          this.rootStore.apiHandleStore.handleApiFail(rs.data.header);
        } else {
          const data = rs.data.data || [];

          this.menuAssignedList = data.reduce((map: any[], record: any = {}) => {
            if (record.level < 3) {
              return map;
            }
            const { menuCode, menuType, progCode, progFileName, progName, progPath, languages, level, upperMenuCode, displayOrder } = record;
            map.push({
              id: uuid.v4(),
              menuCode,
              menuType,
              progCode,
              progFileName,
              progName,
              progPath: progPath ? `/${progPath}` : '',
              languages,
              level,
              upperMenuCode,
              displayOrder
            });
            return map;
          }, []);
          this.menuAssignedList = [...InitMenuData, ...this.menuAssignedList];
        }
      })
      .catch((e: any) => {        
        Toast.fail(`Assigned Menu fetching fail.${e.message}`, 1);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  @computed
  get menuTree(): MenuModel[] {
    const tree: MenuModel[] = [];
    // get parent menu
    this.menuAssignedList.map((x: any) => {
      if (x.level === 3) {
        const menu = new MenuModel(x);
        if (this._menuToHide.includes(menu.id)) {
          menu.isShow = false;
        }
        tree.push(menu);
      }
      return 0;
    });
    // get sub menu
    tree.map(t => {
      if (t.id === 'dashboard' || t.id === 'user_info') {
        return 0;
      }
      const subMenu = this.menuAssignedList.filter((x: any) => x.level === 4 && x.upperMenuCode === t.menuCode);
      if (subMenu.length > 0) {
        t.children = subMenu.map((x: any) => new MenuModel(x));
      }
      return 0;
    });
    return tree;
  }

}
