import BaseRepository from 'shared/base/repositories/BaseRepository';
import ApiModel from 'shared/models/api/ApiModel';
import ApiHelper from 'shared/utils/ApiHelper';
import AppConst from 'shared/utils/AppConst';

const API_BASE = AppConst.API.SYS_API;
class ApiService extends BaseRepository<ApiModel> {
  constructor() {
    super(AppConst.API.SYS_API);
  }

  find = (params: any) => {
    return params && Object.keys(params).length > 0 ? ApiHelper.get(API_BASE, params) : ApiHelper.get(API_BASE);
  };
}

export default new ApiService();
