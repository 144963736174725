import ApiHelper from 'shared/utils/ApiHelper';
import AppConst from 'shared/utils/AppConst';

class HistoryService {
  /**
   * Fecth Login history
   */
  findLoginHistories = (searchModel: any) => {
    return ApiHelper.get(AppConst.API.SYS_HISTORY_LOGIN, searchModel);
  };

  exportLoginHistories = (searchModel: any) => {
    return ApiHelper.get(`${AppConst.API.SYS_HISTORY_LOGIN}/export`, searchModel);
  };

  /**
   * Fecth File Download history
   */
  findFileDownloadHistories = (searchModel: any) => {
    return ApiHelper.get(AppConst.API.SYS_HISTORY_FILE_DOWNLOAD, searchModel);
  };

  exportFileDownloadHistories = (searchModel: any) => {    
    return ApiHelper.get(`${AppConst.API.SYS_HISTORY_FILE_DOWNLOAD}/export`, searchModel);
  };

  /**
   * Fecth Menu Access history
   */
  findMenuAccessHistories = (searchModel: any) => {
    return ApiHelper.get(AppConst.API.SYS_HISTORY_MENU_ACCESS, searchModel);
  };

  exportMenuAccessHistories = (searchModel: any) => {    
    return ApiHelper.get(`${AppConst.API.SYS_HISTORY_MENU_ACCESS}/export`, searchModel);
  };

  trackMenuAccess = (params: any) => {
    return ApiHelper.post(`${AppConst.API.SYS_HISTORY_MENU_ACCESS}/create `, params);
  };

  /**
   * Fecth Personal Info Access history
   */
  findPersonalInfoAccessHistories = (searchModel: any) => {
    return ApiHelper.get(AppConst.API.SYS_HISTORY_PERSONALINFO_ACCESS, searchModel);
  };

  exportPersonalInfoAccessHistories = (searchModel: any) => {    
    return ApiHelper.get(`${AppConst.API.SYS_HISTORY_PERSONALINFO_ACCESS}/export`, searchModel);
  };

  /**
   * Fecth Report File history
   */
  findReportFileHistories = (searchModel: any) => {
    return ApiHelper.get(AppConst.API.SYS_HISTORY_REPORT_FILE, searchModel);
  };

  exportReportFileHistories = (searchModel: any) => {    
    return ApiHelper.get(`${AppConst.API.SYS_HISTORY_REPORT_FILE}/export`, searchModel);
  };

  /**
   * Report Print History
   */
  findReportPrintHistories = (searchModel: any) => {
    return ApiHelper.get(AppConst.API.SYS_HISTORY_REPORT_PRINT, searchModel);
  };

  exportReportPrintHistories = (searchModel: any) => {    
    return ApiHelper.get(`${AppConst.API.SYS_HISTORY_REPORT_PRINT}/export`, searchModel);
  };

  /**
   * System Usage History
   */
  findSystemUsageHistories = (searchModel: any) => {
    return ApiHelper.get(AppConst.API.SYS_HISTORY_SYSTEM_USAGE, searchModel);
  };

  exportSystemUsageHistories = (searchModel: any) => {    
    return ApiHelper.get(`${AppConst.API.SYS_HISTORY_SYSTEM_USAGE}/export`, searchModel);
  };


  /**
   * Menu Usage Status
   */
  findMenuUsageStatusHistories = (searchModel: any) => {
    return ApiHelper.get(AppConst.API.SYS_HISTORY_MENU_USAGE, searchModel);
  };

  exportMenuUsageStatusHistories = (searchModel: any) => {    
    return ApiHelper.get(`${AppConst.API.SYS_HISTORY_MENU_USAGE}/export`, searchModel);
  };

}

export default new HistoryService();
