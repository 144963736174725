import AppConst from 'shared/utils/AppConst';

const InitMenuData = [
  {
    id: 'dashboard',
    level: 3,
    languages: [
      {
        language: AppConst.LANG_EN.toUpperCase(),
        // menuName: 'Dashboard'
        menuName: 'Notice'
      },
      {
        language: AppConst.LANG_KO.toUpperCase(),
        // menuName: '대시보드'
        menuName: 'Notice'
      }
    ],
    progPath: '/m/dashboard'
  },
  {
    id: 'user_info',
    level: 3,
    languages: [
      {
        language: AppConst.LANG_EN.toUpperCase(),
        menuName: 'User Information'
      },
      {
        language: AppConst.LANG_KO.toUpperCase(),
        menuName: '사용자 정보'
      }
    ],
    progPath: '/m/user-info'
  },
  {
    id: 'overtimereg_detail',
    level: 4,
    languages: [
      {
        language: AppConst.LANG_EN.toUpperCase(),
        menuName: 'Overtime Reg.'
      },
      {
        language: AppConst.LANG_KO.toUpperCase(),
        menuName: '연장근로등록'
      }
    ],
    progPath: '/m/timesheet/overtime/overtimereg'
  },
  {
    id: 'overtimeapp_detail',
    level: 4,
    languages: [
      {
        language: AppConst.LANG_EN.toUpperCase(),
        menuName: 'Overtime Approval'
      },
      {
        language: AppConst.LANG_KO.toUpperCase(),
        menuName: '연장근로승인'
      }
    ],
    progPath: '/m/timesheet/overtime/overtimeapp/detail'
  },
  {
    id: 'utilResultreg_detail',
    level: 4,
    languages: [
      {
        language: AppConst.LANG_EN.toUpperCase(),
        menuName: 'UtilResult Reg.'
      },
      {
        language: AppConst.LANG_KO.toUpperCase(),
        menuName: '가동실적등록'
      }
    ],
    progPath: '/m/timesheet/utilresult/utilresultreg'
  },
  {
    id: 'utilResultapp_detail',
    level: 4,
    languages: [
      {
        language: AppConst.LANG_EN.toUpperCase(),
        menuName: 'UtilResult Approval'
      },
      {
        language: AppConst.LANG_KO.toUpperCase(),
        menuName: '가동실적승인'
      }
    ],
    progPath: '/m/timesheet/utilresult/utilresultapp/detail'
  }
];
export default InitMenuData;
