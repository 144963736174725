import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import CalendarMobileHeaderContainer from './CalendarMobileHeaderContainer';
import CalendarMobileBodyContainer from './CalendarMobileBodyContainer';
import OvertimeCalendarMobileStore from '../stores/OvertimeCalendarMobileStore';

interface Props {
  overtimeCalendarMobileStore?: OvertimeCalendarMobileStore;
}

const Wrapper = styled.div`
  color: #000000;
  text-align: left;
`;

@inject('overtimeCalendarMobileStore')
@observer
export default class CalendarMobileWrapperContainer extends Component<Props> {
  originbodyScrollY = document.getElementsByTagName('body')[0].style.overflowY;

  state = {
    headerVisible: true,
    waypointDate: '00',
    waypointWeek: '1'
  };

  componentDidMount(): any {
    if (this.props.overtimeCalendarMobileStore!.selectedWaypoint === '0') {
      this.props.overtimeCalendarMobileStore!.setSelectedWaypoint(moment().format('MMDD'));
    }

    if (this.props.overtimeCalendarMobileStore!.nowWorkSysYearMonth === '0') {
      this.props.overtimeCalendarMobileStore!.getNowWorkSysWeekNumber().then(() => {
        setTimeout(() => {
          this.horizontalHandleClick(this.props.overtimeCalendarMobileStore!.selectedWaypoint);
        }, 1000);
      });
    } else {
      this.props.overtimeCalendarMobileStore!.getMonthOverTime(this.props.overtimeCalendarMobileStore!.nowWorkSysYearMonth).then(() => {
        setTimeout(() => {
          this.horizontalHandleClick(this.props.overtimeCalendarMobileStore!.selectedWaypoint);
        }, 1000);
      });
    }
  }

  handleWaypointEnter = (a: any, weekNumber: any) => {
    this.setState({ waypointDate: a, waypointWeek: weekNumber });
    this.props.overtimeCalendarMobileStore!.setSelectedWaypoint(a);

    if (a === null) a = '01';
    const id = `h${a.toString()}`;
    if (document.getElementById(id) !== null) document.getElementById(id)!.scrollIntoView();
  };

  horizontalHandleClick = (a: any) => {
    this.setState({ waypointDate: a });
    this.props.overtimeCalendarMobileStore!.setSelectedWaypoint(a);

    if (a === null) a = '01';
    const id = `v${a.toString()}`;
    if (document.getElementById(id) !== null) document.getElementById(id)!.scrollIntoView();
  };

  onChangeHeaderVisible = (visible: boolean) => {
    this.setState({
      headerVisible: visible
    });
  };

  render() {
    const { overTimeMonthList } = this.props.overtimeCalendarMobileStore!;
    return (
      <>
        <Wrapper>
          <CalendarMobileHeaderContainer
            overTimeMonthList={overTimeMonthList}
            waypointDate={this.state.waypointDate}
            horizontalHandleClick={this.horizontalHandleClick}
            headerVisible={this.state.headerVisible}
          />
          <div>
            <CalendarMobileBodyContainer
              {...this.props}
              waypointEnter={this.handleWaypointEnter}
              overTimeMonthList={overTimeMonthList}
              onChangeHeaderVisible={this.onChangeHeaderVisible}
              waypointWeek={this.state.waypointWeek}
              headerVisible={this.state.headerVisible}
            />
          </div>
        </Wrapper>
      </>
    );
  }
}
