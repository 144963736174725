import React from 'react';

import sectionIcon from 'images/logo_small.png';

type IconMetanetProps = {
  style?: any;
};
const IconMetanet = ({ style }: IconMetanetProps) => {
  return (
    <b
      style={{
        background: '50% 50% / contain no-repeat ',
        backgroundImage: `url(${sectionIcon})`,
        width: 15,
        height: 15,
        display: 'inline-block',
        marginRight: 8,
        verticalAlign: 'middle',
        ...style
      }}
    />
  );
};

export default IconMetanet;
