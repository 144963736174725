import { Card, WhiteSpace, Flex, Badge } from 'antd-mobile';
import React from 'react';
import '../corporation.less';
import { Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import StorageHelper from '../../../shared/utils/StorageHelper';
import { AuthStore } from '../../login/stores/AuthStore';
import { CorporationStore } from '../stores/CorporationStore';
import { MobileHeaderSidebarStore } from 'pages-mobile/header-sidebar/stores/MobileHeaderSidebarStore';

export interface IProps {
  corporationStore?: CorporationStore;
  authStore?: AuthStore;
  mobileHeaderSidebarStore?: MobileHeaderSidebarStore;
}

const CorporationCard = (props: any) => {
  return (
    <>
      <WhiteSpace size="lg" />
      <Card style={{ width: '80%', textAlign: 'center' }} onClick={() => props.onCardSelect(props.corpCode)}>
        <Card.Body>
          <div style={{ fontSize: '16px' }}>{props.title}</div>
          <WhiteSpace size="lg" />
          <span className="corporation-detail">승인대기중</span>
          <Badge
            text={props.value}
            style={{
              marginLeft: '-10px',
              marginTop: '-2px',
              backgroundColor: props.value === '0' ? '#888888' : '#108fe9'
            }}
          />
        </Card.Body>
      </Card>
    </>
  );
};

@inject('corporationStore', 'authStore', 'mobileHeaderSidebarStore')
@observer
class CorporationViewMobile extends React.Component<IProps, any> {
  state = {
    isRedirect: false
  };

  onCardSelect = (corpCode: string) => {
    this.props.authStore!.setSelectedCorp(corpCode).then(() => {
      if (StorageHelper.getItem(StorageHelper.KEY_CURR_CORP_CODE)) {
        this.setState({
          isRedirect: true
        });
      }
    });
  };

  render() {
    const corpList = toJS(this.props.corporationStore!.corpList) || [];
    if (!corpList.length) {
      return <Redirect to="/" />;
    }

    if (this.state.isRedirect) {
      return <Redirect to={'/m/dashboard'} />;
    }

    return (
      <Flex justify="center" direction="column" className="corporation-container">
        <h2>법인을 선택해주세요</h2>
        {corpList.map((corpItem: any, index: number) => {
          const noWaitApproval = corpItem ? corpItem.totalApprCount : 0;          
          return <CorporationCard key={index} title={corpItem.corpName} value={noWaitApproval > 0 ? noWaitApproval : '0'} corpCode={corpItem.corpCode} onCardSelect={this.onCardSelect}/>;
        })}
      </Flex>
    );
  }
}

export default CorporationViewMobile;
