/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { observable, action, toJS, computed } from 'mobx';
import { message } from 'antd';
import { RootStore } from 'shared/stores/RootStore';
import WorkTypeListModel from '../static/WorkTypeListModel';
import WorkTypeChangeModel from '../static/WorkTypeChangeModel';

import WorkhourManagementListService from '../services/WorkhourManagementListService';

export default class WorkhourManagementListStore {
  // Drawer 영역
  rootStore: RootStore;
  // Drawer 영역

  @observable
  inProgress: boolean;

  @observable
  gridData: [];

  @observable
  drawerRecord: WorkTypeListModel;

  @observable
  visible: boolean;

  @observable
  searchDeptCode: string | undefined;

  @observable
  searchDeptName: string | undefined;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.gridData = [];
    this.visible = false;
    this.searchDeptCode = '';
  }

  @action
  setVisible(visible: boolean): void {
    this.visible = visible;
  }

  @action
  setDrawerRecord(record: WorkTypeListModel): void {
    this.drawerRecord = record;
  }

  @action
  deleteDepartment(): void {
    this.searchDeptCode = '';
    this.searchDeptName = '';
  }

  // Drawer 영역
  @computed
  get selectedDepartment() {
    this.searchDeptCode = this.rootStore.departmentStore.selectedDept.deptCode;
    this.searchDeptName = this.rootStore.departmentStore.selectedDept.deptName;
    return this.rootStore.departmentStore.selectedDept;
  }

  async fetchDepartmentForSearchPanel() {
    if (toJS(this.rootStore.departmentStore.departmentList.length) === 0) {
      await this.rootStore.departmentStore.searchDepartments({ departmentCode: '', departmentName: '', useYn: 'Y'});
    }
  }

  @action
  reset() {
    this.rootStore.departmentStore.departmentList = [];
    this.gridData = [];
  }
  // Drawer 영역

  // eslint-disable-next-line @typescript-eslint/require-await
  @action
  async getGridData(empNm: string, deptCode: any): Promise<void> {
    this.inProgress = true;
    await WorkhourManagementListService.getWorkTypeList(empNm, deptCode)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];

        // reduce No. column + data type
        data.map((v: any, i: any) => {
          v.no = i + 1;
          return null;
        });

        this.gridData = data;
        this.visible = false;
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  updateWorkTypeChange(workTypeList: WorkTypeListModel): Promise<void> {
    // 연장근로 등록
    this.inProgress = true;
    const workTypeChange: WorkTypeChangeModel = {
      rowStatus: workTypeList.rowStatus,
      corpCode: workTypeList.corpCode,
      empNo: workTypeList.empNo,
      startYearMonth: workTypeList.startYearMonth,
      workType: workTypeList.workType,
      nextWorkType: workTypeList.nextWorkType,
      endYearMonth: workTypeList.endYearMonth
    };

    return WorkhourManagementListService.updateWorkTypeChange(workTypeChange)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        this.getGridData('', '');
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
        message.error('저장 중 문제가 발생하였습니다.');
      });
  }
}
