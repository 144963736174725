import ApiHelper from 'shared/utils/ApiHelper';
import AppConst from 'shared/utils/AppConst';
import MenuModel from 'shared/models/menu/MenuModel';
import BaseRepository from 'shared/base/repositories/BaseRepository';

const API_MENU = AppConst.API.SYS_MENU;

class MenuService extends BaseRepository<MenuModel> {
  constructor() {
    super(API_MENU);
  }

  getFolder = (menuId: string) => {
    return ApiHelper.get(AppConst.API.SYS_MENU_FOLDER, { menuId });
  };
}

export default new MenuService();
