class MenuModel {
  id: string;
  menuName: any;
  menuCode?: string;
  progPath: string;
  children?: MenuModel[];
  isShow?: boolean; // whether show menu or not

  constructor(obj: any) {
    this.id = obj.id;
    this.menuName = obj.languages;
    this.menuCode = obj.menuCode;
    this.progPath = obj.progPath;
    this.isShow = true;
  }
}

export default MenuModel;