import { ApiStore } from 'pages/api-management/stores/ApiStore';
import { CommonCodeStore } from 'pages/code-management/stores/CommonCodeStore';
import { RepresentativeCodeStore } from 'pages/code-management/stores/RepresentativeCodeStore';
import { CorporationStore } from 'pages/corporations/store/CorporationStore';
import { DepartmentStore } from 'pages/department-management/stores/DepartmentStore';
import { DepartmentDrawerStore } from 'pages/department-management/stores/DepartmentDrawerStore';
import { EmployeeDrawerStore } from 'pages/department-management/stores/EmployeeDrawerStore';
import DepartmentHistoryStore from 'pages/department-management/stores/DepartmentHistoryStore';
import { EmployeeStore } from 'pages/employee-management/stores/EmployeeStore';
import { MenuModalStore } from 'pages/menu-management/stores/MenuModalStore';
import { MenuStore } from 'pages/menu-management/stores/MenuStore';
import { ProgramModalStore } from 'pages/menu-management/stores/ProgramModalStore';
import { ProgramStore } from 'pages/program-management/stores/ProgramStore';
import { AuthorityStore } from 'pages/role-authority-management/stores/AuthorityStore';
import { MenuAssignmentStore } from 'pages/role-management/stores/MenuAssignmentStore';
import { RoleStore } from 'pages/role-management/stores/RoleStore';
import { UserInfoStore } from 'pages/user-information/store/UserInfoStore';
import { UserStore } from 'pages/user-management/stores/UserStore';
import { AuthStore } from 'shared/stores/AuthenticationStore';
import CalendarListStore from '../../pages/calendar/stores/CalendarListStore';
import HolidayListStore from '../../pages/calendar/stores/HolidayListStore';
import { CommonStore } from '../../pages/common/stores/CommonStore';
import CorporationConfigStore from '../../pages/corporation-config/stores/CorporationConfigStore';
import MasterImportStore from '../../pages/corporation-config/stores/MasterImportStore';
import RawDataDownStore from '../../pages/corporation-config/stores/RawDataDownStore';
import { HistoryLoginStore } from '../../pages/logs-login/stores/HistoryLoginStore';
import { HistoryFileDownloadStore } from '../../pages/logs-file-download/stores/HistoryFileDownloadStore';
import { HistoryMenuAccessStore } from '../../pages/logs-menu-access/stores/HistoryMenuAccessStore';
import { HistorySystemUsageStore } from '../../pages/logs-system-usage/stores/HistorySystemUsageStore';
import { HistoryMenuUsageStatusStore } from '../../pages/logs-menu-usage-status/stores/HistoryMenuUsageStatusStore';
import HrAssignReqStore from '../../pages/hrAssignRequest/stores/HrAssignReqStore';
import HrAssignManageStore from '../../pages/human-resource-assign-management/stores/HrAssignManageStore';
import HrAssignViewStore from '../../pages/human-resource-assign-view/stores/HrAssignViewStore';
import OvertimeMonthApprListStore from '../../pages/overtime/stores/OvertimeMonthApprListStore';
import OvertimeMonthRegStore from '../../pages/overtime/stores/OvertimeMonthRegStore';
import OvertimeMonthStateStore from '../../pages/overtime/stores/OvertimeMonthStateStore';
import PmGroupStore from '../../pages/project-management/stores/PmGroupStore';
import ProjectStore from '../../pages/project-management/stores/ProjectStore';
import UtilizationResultAppStore from '../../pages/utilization-result/stores/UtilizationResultAppStore';
import UtilizationResultClosingStore from '../../pages/utilization-result/stores/UtilizationResultClosingStore';
import UtilizationResultRegStore from '../../pages/utilization-result/stores/UtilizationResultRegStore';
import UtilizationStore from '../../pages/utilization/stores/UtilizationStore';
import WorkhourManagementListStore from '../../pages/workhour/stores/WorkhourManagementListStore';
import ReducWorkManagementStore from '../../pages/reducwork/stores/ReducWorkManagementStore';
import ProjectResourceStore from '../../pages/project-resource/stores/ProjectResourceStore';
import MailSendLogStore from '../../pages/corporation-config/stores/MailSendLogStore';
import { ApiHandleStore } from './ApiHandleStore';
import { MenuAssignImplementStore } from './MenuAssignImplementStore';
import { RouteStore } from './RouteStore';
import { SystemLanguageStore } from './SystemLanguageStore';
import { UIStore } from './UIStore';
import { CorpStore } from 'pages/corp-management/stores/CorpStore';
import { MenuHelpStore } from 'pages/menu-help/stores/MenuHelpStore';

export class RootStore {
  userStore: UserStore;
  roleStore: RoleStore;
  representativeCodeStore: RepresentativeCodeStore;
  menuStore: MenuStore;
  commonCodeStore: CommonCodeStore;
  employeeStore: EmployeeStore;
  departmentStore: DepartmentStore;
  departmentDrawerStore: DepartmentDrawerStore;
  employeeDrawerStore: EmployeeDrawerStore;
  departmentHistoryStore: DepartmentHistoryStore;
  authStore: AuthStore;
  uiStore: UIStore;
  apiHandleStore: ApiHandleStore;
  corporationStore: CorporationStore;
  authorityStore: AuthorityStore;
  programStore: ProgramStore;
  programModalStore: ProgramModalStore;
  menuModalStore: MenuModalStore;
  menuAssignImplementStore: MenuAssignImplementStore;
  userInfoStore: UserInfoStore;
  routeStore: RouteStore;
  historyLoginStore: HistoryLoginStore;
  historyFileDownloadStore: HistoryFileDownloadStore;
  historyMenuAccessStore: HistoryMenuAccessStore;
  historySystemUsageStore: HistorySystemUsageStore;
  menuHelpStore: MenuHelpStore;
  historyMenuUsageStatusStore: HistoryMenuUsageStatusStore;

  commonStore: CommonStore;
  utilizationResultAppStore: UtilizationResultAppStore;
  utilizationResultRegStore: UtilizationResultRegStore;
  utilizationResultClosingStore: UtilizationResultClosingStore;
  projectStore: ProjectStore;
  pmGroupStore: PmGroupStore;
  overtimeMonthRegStore: OvertimeMonthRegStore;
  overtimeMonthApprListStore: OvertimeMonthApprListStore;
  overtimeMonthStateStore: OvertimeMonthStateStore;
  workhourManagementListStore: WorkhourManagementListStore;
  reducWorkManagementStore: ReducWorkManagementStore;
  calendarListStore: CalendarListStore;
  holidayListStore: HolidayListStore;
  corporationConfigStore: CorporationConfigStore;
  masterImportStore: MasterImportStore;
  rawDataDownStore: RawDataDownStore;
  mailSendLogStore: MailSendLogStore;
  projectResourceStore: ProjectResourceStore;

  hrAssignManageStore: HrAssignManageStore;
  hrAssignReqStore: HrAssignReqStore;
  hrAssignViewStore: HrAssignViewStore;
  menuAssignmentStore: MenuAssignmentStore;
  utilizationStore: UtilizationStore;
  systemLanguageStore: SystemLanguageStore;
  apiStore: ApiStore;
  corpStore: CorpStore;

  constructor() {
    this.userStore = new UserStore(this);
    this.roleStore = new RoleStore(this);
    this.representativeCodeStore = new RepresentativeCodeStore(this);
    this.menuStore = new MenuStore(this);
    this.commonCodeStore = new CommonCodeStore(this);
    this.employeeStore = new EmployeeStore(this);
    this.departmentStore = new DepartmentStore(this);
    this.departmentDrawerStore = new DepartmentDrawerStore(this);
    this.employeeDrawerStore = new EmployeeDrawerStore(this);
    this.departmentHistoryStore = new DepartmentHistoryStore(this);
    this.authStore = new AuthStore(this);
    this.uiStore = new UIStore(this);
    this.userInfoStore = new UserInfoStore(this);
    this.routeStore = new RouteStore(this);
    this.historyLoginStore = new HistoryLoginStore(this);
    this.historyFileDownloadStore = new HistoryFileDownloadStore(this);
    this.historyMenuAccessStore = new HistoryMenuAccessStore(this);
    this.menuHelpStore = new MenuHelpStore(this);
    this.historySystemUsageStore = new HistorySystemUsageStore(this);
    this.historyMenuUsageStatusStore = new HistoryMenuUsageStatusStore(this);

    this.commonStore = new CommonStore(this);
    this.utilizationResultAppStore = new UtilizationResultAppStore(this);
    this.utilizationResultRegStore = new UtilizationResultRegStore(this);
    this.utilizationResultClosingStore = new UtilizationResultClosingStore(this);
    this.projectStore = new ProjectStore(this);
    this.pmGroupStore = new PmGroupStore(this);
    this.overtimeMonthRegStore = new OvertimeMonthRegStore(this);
    this.overtimeMonthApprListStore = new OvertimeMonthApprListStore(this);
    this.overtimeMonthStateStore = new OvertimeMonthStateStore(this);
    this.workhourManagementListStore = new WorkhourManagementListStore(this);
    this.reducWorkManagementStore = new ReducWorkManagementStore(this);
    this.calendarListStore = new CalendarListStore(this);
    this.holidayListStore = new HolidayListStore(this);
    this.corporationConfigStore = new CorporationConfigStore(this);
    this.masterImportStore = new MasterImportStore(this);
    this.rawDataDownStore = new RawDataDownStore(this);
    this.mailSendLogStore = new MailSendLogStore(this);

    this.apiHandleStore = new ApiHandleStore(this);
    this.corporationStore = new CorporationStore(this);
    this.hrAssignReqStore = new HrAssignReqStore(this);
    this.authorityStore = new AuthorityStore(this);
    this.hrAssignManageStore = new HrAssignManageStore(this);
    this.hrAssignViewStore = new HrAssignViewStore(this);
    this.utilizationStore = new UtilizationStore(this);
    this.programStore = new ProgramStore(this);
    this.programModalStore = new ProgramModalStore(this);
    this.menuModalStore = new MenuModalStore(this);
    this.menuAssignmentStore = new MenuAssignmentStore(this);
    this.menuAssignImplementStore = new MenuAssignImplementStore(this);
    this.systemLanguageStore = new SystemLanguageStore(this);
    this.apiStore = new ApiStore(this);
    this.corpStore = new CorpStore(this);
    this.projectResourceStore = new ProjectResourceStore(this);
  }
}
