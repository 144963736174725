import { observable, action, computed, toJS } from 'mobx';
import ProjectModel from 'shared/models/project/ProjectModel';
import { RootStore } from 'shared/stores/RootStore';
import ProjectServices from '../services/CommonService';

export class CommonStore {
  @observable
  projects: ProjectModel[];

  @observable
  selectedProject: ProjectModel;

  @observable
  inProgress: boolean;

  @observable
  inProgressCurrentEmp: boolean;

  @observable
  visibleDrawer: boolean;

  @observable
  returnPjName: string;

  @observable
  returnPjCode: string;

  @observable
  searchDeptCode: string | undefined;

  @observable
  searchDeptName: string | undefined;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.projects = [];
    this.visibleDrawer = false;
    this.returnPjName = '';
    this.returnPjCode = '';
    this.rootStore = rootStore;
    this.inProgressCurrentEmp = false;
  }

  /**
   * Common Project List Search Service
   */

  @action
  getProjects(dept: string, pjCode: string, pmName: string, pjCreateType: string, searchPattern: string, mainSalesDept: string) {
    const reqObj = {
      pjSearch: pjCode,
      mainPerformDept: dept,
      pmEmpNm: pmName,
      pjCreateType,
      searchPattern,
      mainSalesDept,
      pjStatus: ''
    };

    const arrayRoles: ProjectModel[] = [];
    const { getCommonCodeName } = this.rootStore.systemLanguageStore;
    this.inProgress = true;

    if (searchPattern === 'all') {
      return ProjectServices.getProjects(reqObj)
        .then(response => {
          this.rootStore.apiHandleStore.handleApiFail(response.data.header);
          const data = response.data.data || [];
          data.map((item: any, i: number) => {
            const role = new ProjectModel({
              no: i + 1,
              corpCode: item.corpCode,
              pjCode: item.pjCode,
              mainSalesDept: item.mainSalesDept,
              mainPerformDept: item.mainPerformDept,
              mainSalesDeptNm: item.mainSalesDeptNm,
              mainPerformDeptNm: item.mainPerformDeptNm,
              pjName: item.pjName,
              pmEmpNo: item.pmEmpNo,
              pmEmpNm: item.pmEmpNm,
              pjStartDate: item.pjStartDate,
              pjEndDate: item.pjEndDate,
              pjStatus: item.pjStatus,
              pjStatusNm: getCommonCodeName('0012', item.pjStatus),
              pjType: item.pjType,
              pjTypeNm: getCommonCodeName('0013', item.pjType),
              rowStatus: 'UPDATE'
            });
            arrayRoles.push(role);
          });

          this.projects = arrayRoles;
          this.inProgress = false;
        })
        .catch(error => {
          this.rootStore.apiHandleStore.handleApiError(error);
          this.inProgress = false;
        });
    } if (searchPattern === 'alling') {
      reqObj.pjStatus = 'I';
      return ProjectServices.getProjects(reqObj)
        .then(response => {
          this.rootStore.apiHandleStore.handleApiFail(response.data.header);
          const data = response.data.data || [];
          data.map((item: any, i: number) => {
            const role = new ProjectModel({
              no: i + 1,
              corpCode: item.corpCode,
              pjCode: item.pjCode,
              mainSalesDept: item.mainSalesDept,
              mainPerformDept: item.mainPerformDept,
              mainSalesDeptNm: item.mainSalesDeptNm,
              mainPerformDeptNm: item.mainPerformDeptNm,
              pjName: item.pjName,
              pmEmpNo: item.pmEmpNo,
              pmEmpNm: item.pmEmpNm,
              pjStartDate: item.pjStartDate,
              pjEndDate: item.pjEndDate,
              pjStatus: item.pjStatus,
              pjStatusNm: getCommonCodeName('0012', item.pjStatus),
              pjType: item.pjType,
              pjTypeNm: getCommonCodeName('0013', item.pjType),
              rowStatus: 'UPDATE'
            });
            arrayRoles.push(role);
          });

          this.projects = arrayRoles;
          this.inProgress = false;
        })
        .catch(error => {
          this.rootStore.apiHandleStore.handleApiError(error);
          this.inProgress = false;
        });
    } if (searchPattern === 'pmg') {
      return ProjectServices.getPmGroupProjects(reqObj)
        .then(response => {
          this.rootStore.apiHandleStore.handleApiFail(response.data.header);
          const data = response.data.data || [];
          data.map((item: any, i: number) => {
            const role = new ProjectModel({
              no: i + 1,
              corpCode: item.corpCode,
              pjCode: item.pjCode,
              mainSalesDept: item.mainSalesDept,
              mainPerformDept: item.mainPerformDept,
              mainSalesDeptNm: item.mainSalesDeptNm,
              mainPerformDeptNm: item.mainPerformDeptNm,
              pjName: item.pjName,
              pmEmpNo: item.pmEmpNo,
              pmEmpNm: item.pmEmpNm,
              pjStartDate: item.pjStartDate,
              pjEndDate: item.pjEndDate,
              pjStatus: item.pjStatus,
              pjStatusNm: getCommonCodeName('0012', item.pjStatus),
              pjType: item.pjType,
              pjTypeNm: getCommonCodeName('0013', item.pjType),
              rowStatus: 'UPDATE'
            });
            arrayRoles.push(role);
          });

          this.projects = arrayRoles;
          this.inProgress = false;
        })
        .catch(error => {
          this.rootStore.apiHandleStore.handleApiError(error);
          this.inProgress = false;
        });
    }
  }

  @action
  setReturnPjValue(record: ProjectModel) {
    this.selectedProject = record;
    this.returnPjCode = record.pjCode;
    this.returnPjName = record.pjName;
    return this.selectedProject;
  }

  @action
  setVisibleDrawer(visible: boolean) {
    this.visibleDrawer = visible;
    this.projects = [];
  }

  // Department Drawer 영역
  @computed
  get selectedDepartment() {
    this.searchDeptCode = this.rootStore.departmentStore.selectedDept.deptCode;
    this.searchDeptName = this.rootStore.departmentStore.selectedDept.deptName;
    return this.rootStore.departmentStore.selectedDept;
  }

  async fetchDepartmentForSearchPanel() {
    if (toJS(this.rootStore.departmentStore.departmentList.length) === 0) {
      await this.rootStore.departmentStore.searchDepartments({ departmentCode: '', departmentName: '', useYn: 'Y'});
    }
  }

  @action
  reset() {
    this.rootStore.departmentStore.departmentList = [];
  }
}

export default CommonStore;
