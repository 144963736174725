import { Button, Form, Icon, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import ValidatorHelper from 'shared/utils/ValidatorHelper';
import { AuthStore } from '../../../shared/stores/AuthenticationStore';
import '../static/style.less';

interface IProps {
  authStore?: AuthStore;
}

export interface FormProps extends FormComponentProps, RouteComponentProps<{}> {}

const KEY_NEW_PASSWORD = 'newPassword';
const KEY_CONFIRMED_PASSWORD = 'confirmedPassword';

@inject('authStore')
@observer
class PasswordResetFormContainer extends React.Component<IProps & FormProps, any> {
  state = {
    isMoveBackToLogin: false,
    confirmDirty: false
  };

  handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { newPassword } = values;
        const paramArray = window.location.search.replace('?', '').split('&');
        if (paramArray && paramArray.length > 1) {
          const userName = paramArray[0].split('=')[1];
          const token = paramArray[1].split('=')[1];

          this.props.authStore!.resetPassword(userName, token, newPassword).then(() => {
            if (this.props.authStore!.isPasswordResetDone) {
              this.setState({
                isMoveBackToLogin: true
              });
            }
          });
        }
      }
    });
  };


  /**
   * Validate New Password and validate to Confirmed Password
   */
  validateNewPassword = (rule: any, value: any, callback: any) => {
    const { form } = this.props;
    const isValidPassword = ValidatorHelper.isValidPassword(value);
    if (value && this.state.confirmDirty) {
      form.validateFields([KEY_CONFIRMED_PASSWORD], { force: true });
    }
    if (!isValidPassword) return false;
    callback();
  };

  compareToNewPassword = (rule: any, value: any, callback: any) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue(KEY_NEW_PASSWORD)) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  handleConfirmBlur = (e: any) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  render() {
    const {
      form: { getFieldDecorator }
    } = this.props;

    const { isMoveBackToLogin } = this.state;

    if (isMoveBackToLogin) {
      return <Redirect to="/" />;
    }

    return (
      <div>
        <h1 style={{ color: '#fff', textAlign: 'center', fontSize: 40, paddingBottom: 20 }}>
          <Icon type="clock-circle" style={{ fontSize: 50 }} />
          <span style={{ paddingLeft: 30 }}>TimeSheet</span>
        </h1>

        <Form style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '2em', width: 360 }} onSubmit={this.handleSubmit}>
          <h1 style={{ color: '#fff', textAlign: 'center', fontSize: 20 }}>비밀번호 재설정</h1>

          <Form.Item label="새 비밀번호" className="password-reset-form-label">
            {getFieldDecorator(KEY_NEW_PASSWORD, {
              initialValue: '',
              rules: [
                {
                  validator: this.validateNewPassword,
                  whitespace: true,
                  required: true,
                  message: '비밀번호는 보안을 위해 영문, 숫자, 특수 문자를 최소 1개 이상 포함하고, 8자 이상으로 구성되어야 합니다.'
                }
              ]
            })(<Input type="password" allowClear prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="8자리 ~ 20자리 이내로 입력해주세요" />)}
          </Form.Item>
          <Form.Item label="새 비밀번호 확인" className="password-reset-form-label">
            {getFieldDecorator(KEY_CONFIRMED_PASSWORD, {
              initialValue: '',
              rules: [
                {
                  validator: this.compareToNewPassword,
                  whitespace: true,
                  required: true,
                  message: '비밀번호가 일치하지 않습니다. 다시 시도하십시오.'                  
                }
              ]
            })(<Input type="password" allowClear prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="8자리 ~ 20자리 이내로 입력해주세요" onBlur={this.handleConfirmBlur} />)}
          </Form.Item>

          <div style={{ paddingTop: 14 }}>
            <Button block type="primary" htmlType="submit">
              저장
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

const WrappedPasswordResetFormContainer = Form.create({ name: 'normal_login' })(PasswordResetFormContainer);
export default WrappedPasswordResetFormContainer;
