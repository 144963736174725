import { Icon } from 'antd';
import { Button, Flex, InputItem, List, WhiteSpace } from 'antd-mobile';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Redirect } from 'react-router-dom';
import ValidatorHelper from 'shared/utils/ValidatorHelper';
import '../password-reset-mobile.less';

const rcForm = require('rc-form');

@inject('authStore')
@observer
class PasswordResetMobileView extends React.Component<any, any> {
  state = {
    isMoveBackToLogin: false
  };

  onSubmit = () => {
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const { newPassword } = values;
        const paramArray = window.location.search.replace('?', '').split('&');
        if (paramArray && paramArray.length > 1) {
          const userName = paramArray[0].split('=')[1];
          const token = paramArray[1].split('=')[1];
          
          this.props.authStore!.resetPassword(userName, token, newPassword).then(() => {
            if (this.props.authStore!.isPasswordResetDone) {
              this.setState({
                isMoveBackToLogin: true
              });
            }
          });
        }
      }
    });
  };


  isValidConfirmedPassword = (value: string) => {
    const { form } = this.props;
    const isValid = ValidatorHelper.isValidPassword(value);
    const isMatchNewPassword = value === form.getFieldValue('newPassword');
    return isValid && isMatchNewPassword;
  };

  render() {
    let errors;
    const { getFieldProps, getFieldError } = this.props.form;

    const { isMoveBackToLogin } = this.state;

    if (isMoveBackToLogin) {
      return <Redirect to="/" />;
    }

    // eslint-disable-next-line no-return-assign
    return (
      <div style={{ height: '100%', background: '#fff' }}>
        <Flex justify="center" direction="column" className="login-m-container">
          <h1>비밀번호 재설정</h1>
          <WhiteSpace size="lg" />

          <List style={{ padding: '0 40px' }}>
            <InputItem
              {...getFieldProps('newPassword', {
                rules: [{ 
                  required: true, 
                  validator: (rule: any, value: string) => ValidatorHelper.isValidPassword(value),
                  message: '비밀번호는 소문자, 최소 하나의 특수문자, 최소 하나의 숫자로 구성되어야 합니다.' }]
              })}
              type="password"
              placeholder="새 비밀번호"
              className={getFieldError('newPassword') ? 'err-input' : ''}
            >
              <Icon type="key" />
            </InputItem>
            {(errors = getFieldError('newPassword')) ? <span className="err-text">{errors.join(',')}</span> : null}
            <WhiteSpace size="md" />

            <InputItem
              {...getFieldProps('confirmedPassword', {
                rules: [{ 
                  required: true,
                  validator: (rule: any, value: string) => this.isValidConfirmedPassword(value), 
                  message: '비밀번호가 일치하지 않습니다. 다시 시도하십시오.' }]
              })}
              type="password"
              placeholder="새 비밀번호 확인"
              className={getFieldError('confirmedPassword') ? 'err-input' : ''}
            >
              <Icon type="key" />
            </InputItem>
            {(errors = getFieldError('confirmedPassword')) ? <span className="err-text">{errors.join(',')}</span> : null}
            <WhiteSpace size="md" />

            <WhiteSpace />
            <WhiteSpace />
            <Button type="primary" onClick={this.onSubmit}>
            저장
            </Button>
          </List>

          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />

          <img width="30%" alt="example" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSokS4ZFbokxZP0_teVuPZfxRvCFvL8BkLEuy2ggaQluDgUO5WEug" />
        </Flex>
      </div>
    );
  }
}
const WrappedLoginMobileForm = rcForm.createForm()(PasswordResetMobileView);
export default WrappedLoginMobileForm;
