import { IntlProvider } from 'react-intl';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { ConfigProvider } from 'antd';
import { UIStore } from './shared/stores/UIStore';
import LanguageHelper from './shared/utils/LanguageHelper';

interface IMultiLanguageProviderProps {
  children?: any;
  uiStore?: UIStore;
}

@inject('uiStore')
@observer
class MultiLanguageProvider extends React.Component<IMultiLanguageProviderProps> {
  render() {
    const languagesData = LanguageHelper.getLanguageResourceData();

    const { currLanguage } = this.props.uiStore!;
    const localeText = currLanguage.locale === 'en' ? 'en' : 'ko';
    const localeData = currLanguage.locale === 'en' ? languagesData.en : languagesData.ko;
    return (
      <IntlProvider locale={localeText} messages={localeData}>
        <ConfigProvider locale={currLanguage}>{this.props.children}</ConfigProvider>
      </IntlProvider>
    );
  }
}

export default MultiLanguageProvider;
