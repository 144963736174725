import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { Form, Row, Col, Button, Input, Divider, DatePicker } from 'antd';
import AppConst from 'shared/utils/AppConst';
import useStores from 'shared/hooks/useStores';
import SelectCommonCode from 'shared/containers/SelectCommonCode';
import ReducWorkModel from 'pages/reducwork/static/ReducWorkModel';
import CommonSelectEmployeeDrawer from 'pages/common/CommonSelectEmployeeDrawer';
import { formEmployee } from 'pages/reducwork/static/formFields';
import EmployeeModel from 'shared/models/employee/EmployeeModel';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

const buttonStyle = {
  marginRight: '4px'
};

const ReducWorkManagementSearchContainer = observer(
  (): JSX.Element => {
    // state hooks
    const [empNm, setEmpNm] = useState<string>('');
    const [empNo, setEmpNo] = useState<string>('');
    const [fromDt, setFromDt] = useState<string>(moment().format('YYYYMMDD'));
    const [toDt, setToDt] = useState<string>(
      moment()
        .add(7, 'day')
        .format('YYYYMMDD')
    );
    const [type, setType] = useState<string>('');
    const [openEmpDrawer, setOpenEmpDrawer] = useState<boolean>(false);
    // mobx inject hook
    const { reducWorkManagementStore, employeeStore } = useStores();
    // 다국어 hook
    const { formatMessage } = useIntl();

    const onSearch = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
      e.preventDefault();
      reducWorkManagementStore.getWorkTimeListData(empNo, empNm, type, fromDt, toDt);
    };

    const onClearInput = (): void => {
      setEmpNm('');
      setEmpNo('');
    };

    const onChangeTypeSelect = (v: string): void => {
      setType(v);
    };

    const onChangeRangePicker = (range: any[]): void => {
      if (range.length > 0) {
        setFromDt(range[0].format('YYYYMMDD'));
        setToDt(range[1].format('YYYYMMDD'));
      } else {
        setFromDt('');
        setToDt('');
      }
    };

    const handleOpenDrawer = (): void => {
      reducWorkManagementStore.setVisibleType('create');
      reducWorkManagementStore.setSelectedRecord(new ReducWorkModel({}));
      reducWorkManagementStore.setVisibleDrawer(true);
    };

    // Employee Drawer 영역
    const handleShowEmpDrawer = (): void => {
      setOpenEmpDrawer(true);
    };

    const handleCloseEmpDrawer = (): void => {
      setOpenEmpDrawer(false);
    };

    const handleSearchConcurrentEmployee = (values: any): void => {
      employeeStore.findConcurrentEmployeeByConditions(values, AppConst.API.SYS_EMP);
    };

    const handleSetFieldValue = (): void => {
      const { selectedConcurrentEmployee } = employeeStore;

      setEmpNo(selectedConcurrentEmployee.empNo);
      setEmpNm(selectedConcurrentEmployee.empName);
    };

    const handleSubmitConcurrentEmployee = (values: any): void => {
      employeeStore.setSelectedConcurrentEmployee(values as EmployeeModel);
      handleSetFieldValue();
      handleCloseEmpDrawer();
    };

    return (
      <>
        <CommonSelectEmployeeDrawer
          formFields={formEmployee}
          visibleCondition={openEmpDrawer}
          onSubmit={handleSubmitConcurrentEmployee}
          onSearch={handleSearchConcurrentEmployee}
          onCancel={handleCloseEmpDrawer}
        />
        <Form>
          <Row gutter={24} type="flex" align="middle" justify="start">
            <Col>
              <Form.Item label={formatMessage({ id: 'workhour.time.management.label.emp.name' })} style={{ marginBottom: 0 }} />
            </Col>
            <Col>
              <Input allowClear style={{ width: '100%' }} placeholder="Employee Name" value={empNo} onChange={onClearInput} onClick={handleShowEmpDrawer} />
            </Col>
            <Col>
              <Form.Item label={formatMessage({ id: 'workhour.time.management.label.emp.no' })} style={{ marginBottom: 0 }} />
            </Col>
            <Col>
              <Input allowClear style={{ width: '100%' }} placeholder="Employee No" value={empNm} onChange={onClearInput} onClick={handleShowEmpDrawer} />
            </Col>
          </Row>
          <Row gutter={24} type="flex" align="middle" justify="start">
            <Col>{formatMessage({ id: 'workhour.time.management.label.work.time.reduce' })}</Col>
            <Col>
              <Form.Item label={formatMessage({ id: 'workhour.time.management.label.period' })} style={{ marginBottom: 0 }} />
            </Col>
            <Col>
              <RangePicker defaultValue={[moment(), moment().add(7, 'day')]} format={dateFormat} onChange={onChangeRangePicker} />
            </Col>
            <Col>
              <Form.Item label={formatMessage({ id: 'workhour.time.management.label.type' })} style={{ marginBottom: 0 }} />
            </Col>
            <Col span={4}>
              <SelectCommonCode
                representCode="0020"
                defaultValue=""
                emptyOptionMessageKey="workhour.time.management.select.empty.all"
                onChangeCommonCode={(values: any) => {
                  onChangeTypeSelect(values);
                }}
              />
            </Col>
          </Row>
          <Row gutter={24} type="flex" align="middle" justify="start">
            <Col style={{ marginLeft: 'auto' }}>
              <Button icon="plus" type="primary" style={buttonStyle} onClick={handleOpenDrawer}>
                {formatMessage({ id: 'workhour.time.management.drawer.modal.title.create' })}
              </Button>
              <Button icon="search" type="primary" onClick={onSearch}>
                {formatMessage({ id: 'button.title.search' })}
              </Button>
            </Col>
          </Row>
          <Divider />
        </Form>
      </>
    );
  }
);

export default ReducWorkManagementSearchContainer;
