import React from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { Table } from 'antd';
import AppConst from 'shared/utils/AppConst';
import useStores from 'shared/hooks/useStores';
import WrappedFormDrawer from 'pages/reducwork/containers/DrawerReducWorkManagementFormContainer';

const COL_WIDTH_MIN = '5%';

const ReducWorkManagementTableContainer = observer(() => {
  // mobx inject hook
  const { reducWorkManagementStore } = useStores();
  // 다국어 hook
  const { formatMessage } = useIntl();

  const onRow = (record: any): Record<string, any> => {
    return {
      onClick: (): void => {
        if (record.no) {
          reducWorkManagementStore.setVisibleType('update');
          reducWorkManagementStore.setSelectedRecord(record);
          reducWorkManagementStore.setVisibleDrawer(true);
        }
      }
    };
  };

  const myDataSource = reducWorkManagementStore.reducWorkModel;
  const isPagingAllowed = !!(myDataSource && myDataSource.length > AppConst.TABLE_PAGE_SIZE);
  const paginationConfig = isPagingAllowed ? { pageSize: AppConst.TABLE_PAGE_SIZE, showSizeChanger: true, showQuickJumper: true } : false;

  return (
    <>
      <Table dataSource={myDataSource} pagination={paginationConfig} size="middle" rowKey="no" onRow={onRow} bordered>
        <Table.Column className="tableCellAlignCenter" key="no" dataIndex="no" title="No." width={COL_WIDTH_MIN} />
        <Table.Column className="tableCellAlignCenter" key="empNo" dataIndex="empNo" title={formatMessage({ id: 'workhour.time.management.label.emp.no' })} />
        <Table.Column className="tableCellAlignCenter" key="empNm" dataIndex="empNm" title={formatMessage({ id: 'workhour.time.management.label.emp.name' })} />
        <Table.ColumnGroup title={formatMessage({ id: 'workhour.time.management.label.work.time.reduce' })}>
          <Table.Column className="tableCellAlignCenter" key="reducWorkTypeNm" dataIndex="reducWorkTypeNm" title={formatMessage({ id: 'workhour.time.management.label.type' })} />
          <Table.Column
            className="tableCellAlignCenter"
            key="reducWorkTermFrom"
            dataIndex="reducWorkTermFrom"
            title={formatMessage({ id: 'workhour.time.management.label.start.date' })}
            render={(text: any, record: any) => moment(record.reducWorkTermFrom, 'YYYYMMDD').format('YYYY/MM/DD')}
          />
          <Table.Column
            className="tableCellAlignCenter"
            key="reducWorkTermTo"
            dataIndex="reducWorkTermTo"
            title={formatMessage({ id: 'workhour.time.management.label.end.date' })}
            render={(text: any, record: any) => moment(record.reducWorkTermTo, 'YYYYMMDD').format('YYYY/MM/DD')}
          />
          <Table.Column className="tableCellAlignCenter" key="reducWorkHours" dataIndex="reducWorkHours" title={formatMessage({ id: 'workhour.time.management.label.time' })} />
          <Table.Column
            className="tableCellAlignCenter"
            key="parentReducWorkName"
            dataIndex="parentReducWorkName"
            title={formatMessage({ id: 'workhour.time.management.label.target.name' })}
          />
        </Table.ColumnGroup>
      </Table>
      <WrappedFormDrawer />
    </>
  );
});

export default ReducWorkManagementTableContainer;
