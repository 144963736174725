// Code splitting

import Loadable from 'react-loadable';
import React from 'react';
import { isBrowser } from 'react-device-detect';

const DashboardDesktop = Loadable({
  loader: () => import('./views/DashboardView'),
  loading() {
    return <div>Loading...</div>;
  }
});

const DashboardMobile = Loadable({
  loader: () => import('./views/DashboardMobileView'),
  loading() {
    return <div>Loading...</div>;
  }
});

const DashboardView = () => (isBrowser ? <DashboardDesktop /> : <DashboardMobile />);

export default DashboardView;
