import { observable, action, computed, toJS } from 'mobx';
import * as React from 'react';
import moment from 'moment';
import UtilizationService from '../services/UtilizationService';
import { RootStore } from 'shared/stores/RootStore';
import ItemModel from '../static/ItemModel';
import GroupModel from '../static/GroupModel';
import TreeGroupModel from '../static/TreeGroupModel';
import ProjectModel from 'shared/models/project/ProjectModel';
import { DepartmentModel } from 'shared/models/department/DepartmentModel';

export class UtilizationStore {
  @observable
  inProgress: boolean;

  @observable
  selectedYearMonth: string;

  @observable
  itemModel: ItemModel[];

  @observable
  groupModel: GroupModel[];

  @observable
  teamItemModel: ItemModel[];

  @observable
  afterFilterItemModel: ItemModel[];

  @observable
  teamGroupModel: GroupModel[];

  @observable
  afterFilterModel: GroupModel[];

  @observable
  afterPgFilterModel: GroupModel[];

  @observable
  pgItemModel: ItemModel[];

  @observable
  pgGroupModel: GroupModel[];

  @observable
  treeGroupModel: GroupModel[];

  @observable
  memberCheckedVal: object;

  @observable
  teamCheckedVal: object;

  @observable
  monthlyInjctRate: number;

  @observable
  totInjctDay: number;

  @observable
  getEmpName: string;

  @observable
  getPositName: string;

  @observable
  project: ProjectModel;

  rootStore: RootStore;

  leaveColor: string;

  overTimeColor: string;

  resultColor: string;

  planColor: string;

  openGroups: {};

  showParentYn: boolean;

  treeCheckModel: TreeGroupModel[];

  @observable
  glbDeptCode: string | undefined;

  @observable
  glbDeptName: string | undefined;

  @observable
  searchDeptCode: string | undefined;

  @observable
  searchDeptName: string | undefined;

  @observable
  descPjCode: string;

  @observable
  descPjName: string;

  @observable
  descPmName: string;

  @observable
  descPeriod: string;

  @observable
  descPjType: string;

  @observable
  descPjStatus: string;

  @observable
  descPjCreateType: string;

  @observable
  treeOpenYn: boolean;

  @observable
  drawerPjInfoVisible: boolean;

  @observable
  resourceGridData: [];

  constructor(rootStore: RootStore) {
    this.selectedYearMonth = moment().format('YYYY-MM');
    this.memberCheckedVal = ['L', 'O', 'R', 'P'];
    this.teamCheckedVal = ['L', 'O', 'R', 'P'];

    // TimeLine Color
    // this.leaveColor = '#6699FF';
    // this.overTimeColor = '#FF6666';
    // this.resultColor = '#FF33FF';
    // this.planColor = '#6666FF';

    this.leaveColor = '#00BFFF';
    this.overTimeColor = '#FF6666';
    this.resultColor = '#FF82FF';
    this.planColor = '#FF967E';

    this.openGroups = {};
    this.showParentYn = false;
    this.rootStore = rootStore;
    this.treeOpenYn = false;
    this.drawerPjInfoVisible = false;

    this.resourceGridData = [];
  }

  @action
  setTreeOpenYn(flag: boolean): any {
    this.treeOpenYn = flag;
  }

  @action
  setSelectedYearMonth(yearMonth: string): any {
    this.selectedYearMonth = yearMonth;
  }

  @action
  setMemberCheckedVal(checkedValues: object): any {
    this.memberCheckedVal = checkedValues;
  }

  @action
  setTeamCheckedVal(checkedValues: object): any {
    this.teamCheckedVal = checkedValues;
  }

  /**
   * 개인 Utilization
   */

  @action
  getMemberLeaveOverTimeResultPlan(yearMonth: string): any {
    this.inProgress = true;
    return UtilizationService.getMemberLeaveOverTimeResultPlan(yearMonth)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);

        const data = response.data.data || [];

        console.log(data);

        this.itemModel = [];

        this.groupModel = this.createMemberGroupModel();
        this.itemModel = this.createItemModel(data);
        this.monthlyInjctRate = data.monthlyRate.toFixed(2);
        this.getEmpName = data.empName;
        this.getPositName = data.positName;

        this.inProgress = false;
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  createMemberGroupModel(): GroupModel[] {
    const arrayRoles: GroupModel[] = [];
    this.inProgress = true;

    const role1 = new GroupModel({
      id: 1,
      title: '근태'
    });

    const role2 = new GroupModel({
      id: 2,
      title: '근무관리'
    });

    const role3 = new GroupModel({
      id: 3,
      title: '가동실적'
    });

    const role4 = new GroupModel({
      id: 4,
      title: '가동계획'
    });

    arrayRoles.push(role1);
    arrayRoles.push(role2);
    arrayRoles.push(role3);
    arrayRoles.push(role4);

    this.inProgress = false;
    return arrayRoles;
  }

  createItemModel(data: any): ItemModel[] {
    const arrayRoles: ItemModel[] = [];
    const { getCommonCodeName } = this.rootStore.systemLanguageStore;
    this.inProgress = true;

    let leaveYn = false;
    let overTimeYn = false;
    let resultYn = false;
    let planYn = false;

    if (this.memberCheckedVal instanceof Array) {
      this.memberCheckedVal.map(val => {
        if (val === 'L') leaveYn = true;
        if (val === 'O') overTimeYn = true;
        if (val === 'R') resultYn = true;
        if (val === 'P') planYn = true;
      });
    }

    let cnt = 1;

    // 근태
    if (leaveYn) {
      data.leaveList.map((item: any) => {
        let leaveTitleTxt = getCommonCodeName('0007', item.leaveType);

        let bgColor = this.leaveColor;

        const role1 = new ItemModel({
          id: cnt,
          group: 1,
          title: leaveTitleTxt,
          start_time: moment(item.leaveStartDay, 'YYYYMMDD').format('x'),
          end_time: moment(item.leaveEndDay, 'YYYYMMDD')
            .add(1, 'day')
            .format('x'),
          bgColor: bgColor
        });
        cnt += 1;
        arrayRoles.push(role1);
      });
    }

    // 근무관리
    if (overTimeYn) {
      data.overTimeList.map((item: any) => {
        let overTimeTitleTxt = getCommonCodeName('0006', item.overTimeType);

        let bgColor = this.overTimeColor;

        const role2 = new ItemModel({
          id: cnt,
          group: 2,
          title: `${overTimeTitleTxt}(${item.overtimeWorkHours})`,
          start_time: moment(item.overTimeYmd, 'YYYYMMDD').format('x'),
          end_time: moment(item.overTimeYmd, 'YYYYMMDD')
            .add(1, 'day')
            .format('x'),
          bgColor: bgColor
        });
        cnt += 1;
        arrayRoles.push(role2);
      });
    }

    // 가동실적
    if (resultYn) {
      data.resultList.map((item: any) => {
        // let resultTitleTxt = getCommonCodeName('0002', item.apprSts);
        let resultTitleTxt = '';

        let bgColor = this.resultColor;

        const role3 = new ItemModel({
          id: cnt,
          group: 3,
          title: `${item.pjName}(${item.injctRate}%)`,
          start_time: moment(item.injctStartDay, 'YYYYMMDD').format('x'),
          end_time: moment(item.injctEndDay, 'YYYYMMDD')
            .add(1, 'day')
            .format('x'),
          bgColor: bgColor,
          data: item.pjCode
        });
        cnt += 1;
        arrayRoles.push(role3);
      });
    }

    // 가동계획
    if (planYn) {
      data.planList.map((item: any) => {
        let planTitleTxt = getCommonCodeName('0002', item.apprSts);

        let bgColor = this.planColor;

        const role4 = new ItemModel({
          id: cnt,
          group: 4,
          title: `[${planTitleTxt}]${item.pjName}(${item.injctRate}%)`,
          start_time: moment(item.injctStartDay, 'YYYYMMDD').format('x'),
          end_time: moment(item.injctEndDay, 'YYYYMMDD')
            .add(1, 'day')
            .format('x'),
          bgColor: bgColor,
          data: item.pjCode
        });
        cnt += 1;
        arrayRoles.push(role4);
      });
    }

    return arrayRoles;
  }

  /**
   * Team Utilization (부서)
   */

  @action
  getTeamLeaveOverTimeResultPlan(deptCode: string | undefined, yearMonth: string): any {
    this.inProgress = true;

    return UtilizationService.getTeamLeaveOverTimeResultPlan(deptCode, yearMonth)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);

        const data = response.data.data || [];

        this.teamGroupModel = [];
        // this.afterFilterModel = [];
        this.teamItemModel = [];

        this.teamGroupModel = this.createTeamGroupModel(data);
        this.afterFilterModel = this.createTeamGroupModel(data);

        this.filterTeamGroupModel(0); // Tree Group Filter

        this.teamItemModel = this.createTeamItemModel(data);
        // this.afterFilterItemModel   = this.createCheckedFilterItemModel(teamItemModel);
        this.monthlyInjctRate = data.monthlyRate.toFixed(2);

        this.openGroups = {};
        this.inProgress = false;
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  onToggleTeamEvent(id: number): void {
    this.filterTeamGroupModel(id);
  }

  createTeamGroupModel(data: any): GroupModel[] {
    const arrayRoles: GroupModel[] = [];
    let openYn = false;

    data.empList.map((item: any) => {
      let empNo = item.empNo.replace(/[^0-9]/g, '');

      const role = new GroupModel({
        id: Number(empNo),
        title: React.createElement(
          'div',
          {
            style: { cursor: 'pointer' },
            onClick: () => {
              this.onToggleTeamEvent(Number(item.empNo));
            }
          },
          `[+] ${item.empName}(${item.positName})`
        ),
        parent: null,
        root: true,
        titleName: `${item.empName}(${item.positName})`,
        openYn: false,
        stackItems: false
      });

      const role1 = new GroupModel({
        id: Number(String(empNo) + '1'),
        // title   : '근태',
        title: React.createElement('div', { style: { paddingLeft: 20, hidden: true } }, '근태'),
        parent: Number(empNo),
        root: false,
        titleName: `${item.empName}(${item.positName})`,
        openYn: false
      });

      const role2 = new GroupModel({
        id: Number(String(empNo) + '2'),
        // title   : '근무관리',
        title: React.createElement('div', { style: { paddingLeft: 20 } }, '근무관리'),
        parent: Number(empNo),
        root: false,
        titleName: `${item.empName}(${item.positName})`,
        openYn: false
      });

      const role3 = new GroupModel({
        id: Number(String(empNo) + '3'),
        // title   : '가동실적',
        title: React.createElement('div', { style: { paddingLeft: 20 } }, '가동실적'),
        parent: Number(empNo),
        root: false,
        titleName: `${item.empName}(${item.positName})`,
        openYn: false
      });

      const role4 = new GroupModel({
        id: Number(String(empNo) + '4'),
        // title   : '가동계획',
        title: React.createElement('div', { style: { paddingLeft: 20 } }, '가동계획'),
        parent: Number(empNo),
        root: false,
        titleName: `${item.empName}(${item.positName})`,
        openYn: false
      });

      arrayRoles.push(role);
      arrayRoles.push(role1);
      arrayRoles.push(role2);
      arrayRoles.push(role3);
      arrayRoles.push(role4);
    });

    this.inProgress = false;
    return arrayRoles;
  }

  // 전체 하위 Tree Open (Open:true/Close:false)
  filterTeamGroupAllControl(flagYn: boolean): void {
    const arrayRoles: GroupModel[] = [];

    this.teamGroupModel.map((item: any) => {
      const titleObjOpen = React.createElement(
        'div',
        {
          style: { cursor: 'pointer' },
          onClick: () => {
            this.onToggleTeamEvent(Number(item.id));
          }
        },
        `[-] ${item.titleName}`
      );

      const titleObjClose = React.createElement(
        'div',
        {
          style: { cursor: 'pointer' },
          onClick: () => {
            this.onToggleTeamEvent(Number(item.id));
          }
        },
        `[+] ${item.titleName}`
      );

      if (item.root) {
        if (flagYn) {
          const roleRoot = new GroupModel({
            id: item.id,
            title: titleObjOpen,
            parent: item.parent,
            root: item.root,
            titleName: item.titleName,
            openYn: flagYn
          });

          arrayRoles.push(roleRoot);
        } else {
          const roleRoot = new GroupModel({
            id: item.id,
            title: titleObjClose,
            parent: item.parent,
            root: item.root,
            titleName: item.titleName,
            openYn: flagYn
          });

          arrayRoles.push(roleRoot);
        }
      } else {
        const roleSub = new GroupModel({
          id: item.id,
          title: item.title,
          parent: item.parent,
          root: item.root,
          titleName: item.titleName,
          openYn: flagYn
        });

        arrayRoles.push(roleSub);
      }
    });

    this.teamGroupModel = arrayRoles;
    this.filterTeamGroupModel(0);
  }

  // Tree 그룹 펼침/닫침 Event
  filterTeamGroupModel(checkedId: number): void {
    this.inProgress = true;

    const arrayRoles: GroupModel[] = [];

    this.teamGroupModel.map((item: any) => {
      if (item.root) {
        const titleObjOpen = React.createElement(
          'div',
          {
            style: { cursor: 'pointer' },
            onClick: () => {
              this.onToggleTeamEvent(Number(item.id));
            }
          },
          `[-] ${item.titleName}`
        );

        const titleObjClose = React.createElement(
          'div',
          {
            style: { cursor: 'pointer' },
            onClick: () => {
              this.onToggleTeamEvent(Number(item.id));
            }
          },
          `[+] ${item.titleName}`
        );

        if (item.id === checkedId) {
          // 하위 Tree Close
          if (item.openYn) {
            const role = new GroupModel({
              id: item.id,
              title: titleObjClose,
              parent: item.parent,
              root: item.root,
              titleName: item.titleName,
              openYn: !item.openYn
            });
            arrayRoles.push(role);
            // 하위 Tree Open
          } else {
            const role = new GroupModel({
              id: item.id,
              title: titleObjOpen,
              parent: item.parent,
              root: item.root,
              titleName: item.titleName,
              openYn: !item.openYn
            });
            arrayRoles.push(role);
          }
        } else {
          const role = new GroupModel({
            id: item.id,
            title: item.title,
            parent: item.parent,
            root: item.root,
            titleName: item.titleName,
            openYn: item.openYn
          });
          arrayRoles.push(role);
        }

        // Parent 상태 Update
      } else if (item.parent === checkedId) {
        const role = new GroupModel({
          id: item.id,
          title: item.title,
          parent: item.parent,
          root: item.root,
          titleName: item.titleName,
          openYn: !item.openYn
        });
        arrayRoles.push(role);
      } else {
        const role = new GroupModel({
          id: item.id,
          title: item.title,
          parent: item.parent,
          root: item.root,
          titleName: item.titleName,
          openYn: item.openYn
        });
        arrayRoles.push(role);
      }
    });

    this.inProgress = false;
    this.teamGroupModel = arrayRoles;

    const finalArrayRoles: GroupModel[] = [];

    // 상위에서 변경한 openYn 값으로 Group 재구성
    this.teamGroupModel.map((item: any) => {
      // Root 는 항상 Show
      if (item.root) {
        const role = new GroupModel({
          id: item.id,
          title: item.title,
          parent: item.parent,
          root: item.root,
          titleName: item.titleName,
          openYn: !item.openYn
        });
        finalArrayRoles.push(role);
        // Parent 는 OpenYn 으로 판별
      } else if (!item.root) {
        if (item.openYn) {
          const role = new GroupModel({
            id: item.id,
            title: item.title,
            parent: item.parent,
            root: item.root,
            titleName: item.titleName,
            openYn: !item.openYn
          });
          finalArrayRoles.push(role);
        }
      }
    });

    this.afterFilterModel = finalArrayRoles;
  }

  // Show Yn 을 위한 Update
  updateTeamGroupModel(id: number): void {
    const arrayRoles: GroupModel[] = [];

    this.teamGroupModel.map((item: any) => {
      if (item.parent === id) {
        const role = new GroupModel({
          id: item.id,
          title: item.title,
          parent: item.parent,
          root: item.root,
          titleName: item.titleName,
          openYn: !item.openYn
        });
        arrayRoles.push(role);
      } else {
        const role = new GroupModel({
          id: item.id,
          title: item.title,
          parent: item.parent,
          root: item.root,
          titleName: item.titleName,
          openYn: item.openYn
        });
        arrayRoles.push(role);
      }
    });

    this.teamGroupModel = arrayRoles;
  }

  createTeamItemModel(data: any): ItemModel[] {
    this.inProgress = true;
    const arrayRoles: ItemModel[] = [];
    const { getCommonCodeName } = this.rootStore.systemLanguageStore;

    let leaveYn = false;
    let overTimeYn = false;
    let resultYn = false;
    let planYn = false;

    if (this.teamCheckedVal instanceof Array) {
      this.teamCheckedVal.map(val => {
        if (val === 'L') leaveYn = true;
        if (val === 'O') overTimeYn = true;
        if (val === 'R') resultYn = true;
        if (val === 'P') planYn = true;
      });
    }

    let cnt = 1;

    data.empList.map((emp: any) => {
      // 가동계획
      if (planYn) {
        data.planList.map((plan: any) => {
          if (Number(emp.empNo) === Number(plan.empNo)) {
            let planTitleTxt = getCommonCodeName('0002', plan.apprSts);
            let bgColor = this.planColor;

            const role = new ItemModel({
              id: cnt,
              group: Number(String(emp.empNo) + '4'),
              title: `[${planTitleTxt}]${plan.pjName}(${plan.injctRate}%)`,
              start_time: moment(plan.injctStartDay, 'YYYYMMDD').format('x'),
              end_time: moment(plan.injctEndDay, 'YYYYMMDD')
                .add(1, 'day')
                .format('x'),
              bgColor: bgColor,
              data: plan.pjCode,
              titDesc: 'plan'
            });
            const sumRole = new ItemModel({
              id: cnt += 1,
              group: Number(emp.empNo),
              title: `[${planTitleTxt}]${plan.pjName}(${plan.injctRate}%)`,
              start_time: moment(plan.injctStartDay, 'YYYYMMDD').toDate(),
              end_time: moment(plan.injctEndDay, 'YYYYMMDD')
                .add(1, 'day')
                .toDate(),
              // .format('x'),
              bgColor: bgColor,
              data: plan.pjCode
            });

            cnt += 1;
            arrayRoles.push(sumRole);
            arrayRoles.push(role);
          }
        });
      } // 가동계획 End

      // 가동실적
      if (resultYn) {
        data.resultList.map((result: any) => {
          if (Number(emp.empNo) === Number(result.empNo)) {
            // let resultTitleTxt = getCommonCodeName('0002', result.apprSts);
            let resultTitleTxt = '';

            let bgColor = this.resultColor;

            const role = new ItemModel({
              id: cnt,
              group: Number(String(emp.empNo) + '3'),
              title: `${result.pjName}(${result.injctRate}%)`,
              start_time: moment(result.injctStartDay, 'YYYYMMDD').format('x'),
              end_time: moment(result.injctEndDay, 'YYYYMMDD')
                .add(1, 'day')
                .format('x'),
              bgColor: bgColor,
              data: result.pjCode,
              titDesc: 'result'
            });
            const sumRole = new ItemModel({
              id: cnt += 1,
              group: Number(emp.empNo),
              title: `${result.pjName}(${result.injctRate}%)`,
              start_time: moment(result.injctStartDay, 'YYYYMMDD').toDate(),
              end_time: moment(result.injctEndDay, 'YYYYMMDD')
                .add(1, 'day')
                .toDate(),
              // .format('x'),
              bgColor: bgColor,
              data: result.pjCode
            });

            cnt += 1;
            arrayRoles.push(sumRole);
            arrayRoles.push(role);
          }
        });
      } // 가동실적 End

      // 근무관리
      if (overTimeYn) {
        data.overTimeList.map((over: any) => {
          if (Number(emp.empNo) === Number(over.empNo)) {
            let overTimeTitleTxt = getCommonCodeName('0006', over.overTimeType);

            let bgColor = this.overTimeColor;

            const role = new ItemModel({
              id: cnt,
              group: Number(String(emp.empNo) + '2'),
              title: `${overTimeTitleTxt}(${over.overtimeWorkHours})`,
              start_time: moment(over.overTimeYmd, 'YYYYMMDD').format('x'),
              end_time: moment(over.overTimeYmd, 'YYYYMMDD')
                .add(1, 'day')
                .format('x'),
              bgColor: bgColor,
              titDesc: 'overtime'
            });
            const sumRole = new ItemModel({
              id: cnt += 1,
              group: Number(emp.empNo),
              title: `${overTimeTitleTxt}(${over.overtimeWorkHours})`,
              start_time: moment(over.overTimeYmd, 'YYYYMMDD').toDate(),
              end_time: moment(over.overTimeYmd, 'YYYYMMDD')
                .add(1, 'day')
                .toDate(),
              // .format('x'),
              bgColor: bgColor
            });

            cnt += 1;
            arrayRoles.push(sumRole);
            arrayRoles.push(role);
          }
        });
      }

      // 근태
      if (leaveYn) {
        data.leaveList.map((leave: any) => {
          if (Number(emp.empNo) === Number(leave.empNo)) {
            let leaveTitleTxt = getCommonCodeName('0007', leave.leaveType);

            let bgColor = this.leaveColor;

            const role = new ItemModel({
              id: cnt,
              group: Number(String(emp.empNo) + '1'),
              title: leaveTitleTxt,
              start_time: moment(leave.leaveStartDay, 'YYYYMMDD').format('x'),
              end_time: moment(leave.leaveEndDay, 'YYYYMMDD')
                .add(1, 'day')
                .format('x'),
              bgColor: bgColor,
              titDesc: 'leave'
            });

            const sumRole = new ItemModel({
              id: cnt += 1,
              group: Number(emp.empNo),
              title: leaveTitleTxt,
              start_time: moment(leave.leaveStartDay, 'YYYYMMDD').toDate(),
              end_time: moment(leave.leaveEndDay, 'YYYYMMDD')
                .add(1, 'day')
                .toDate(),
              // .format('x'),
              bgColor: bgColor
            });

            cnt += 1;
            arrayRoles.push(sumRole);
            arrayRoles.push(role);
          }
        });
      } // 근태 End
    });

    this.inProgress = false;
    return arrayRoles;
  }

  @action
  setDeptInfo(obj: DepartmentModel): void {
    this.glbDeptCode = obj.deptCode;
    this.glbDeptName = obj.deptName;
  }

  @action
  setGlbDeptCode(code: string): void {
    this.glbDeptCode = code;
  }

  @action
  setGlbDeptName(name: string): void {
    this.glbDeptName = name;
  }

  //Drawer 영역
  @computed
  get selectedDepartment() {
    this.searchDeptCode = this.rootStore.departmentStore.selectedDept.deptCode;
    this.searchDeptName = this.rootStore.departmentStore.selectedDept.deptName;
    return this.rootStore.departmentStore.selectedDept;
  }

  async fetchDepartmentForSearchPanel() {
    if (toJS(this.rootStore.departmentStore.departmentList.length) === 0) {
      await this.rootStore.departmentStore.searchDepartments({});
    }
  }

  @action
  reset() {
    this.rootStore.departmentStore.departmentList = [];
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * PM Group Utilization
   */

  @action
  getPmGroupLeaveOverTimeResultPlan(pjCode: string, yearMonth: string): any {
    this.inProgress = true;
    return UtilizationService.getPmGroupLeaveOverTimeResultPlan(pjCode, yearMonth)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);

        const data = response.data.data || [];

        this.pgGroupModel = [];
        // this.afterPgFilterModel = [];
        this.pgItemModel = [];

        this.pgGroupModel = this.createPgGroupModel(data);
        this.afterPgFilterModel = this.createPgGroupModel(data);

        this.filterPgGroupModel(0);

        this.pgItemModel = this.createPgGroupItemModel(data);
        this.totInjctDay = data.totInjctDay.toFixed(0);

        this.inProgress = false;
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  getRecentlyProjectInfo() {
    this.inProgress = true;
    return UtilizationService.getRecentlyProjectInfo()
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.inProgress = false;
        return data;
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  onTogglePgEvent(id: number): void {
    this.filterPgGroupModel(id);
  }

  createPgGroupModel(data: any): GroupModel[] {
    const arrayRoles: GroupModel[] = [];
    let openYn = false;

    data.empList.map((item: any) => {
      let empNo = item.empNo.replace(/[^0-9]/g, '');
      const role = new GroupModel({
        id: Number(empNo),
        // title   : item.empName + item.positName,
        title: React.createElement(
          'div',
          {
            style: { cursor: 'pointer' },
            onClick: () => {
              this.onTogglePgEvent(Number(empNo));
            }
          },
          `[+] ${item.empName}(${item.positName})`
        ),
        parent: null,
        root: true,
        titleName: `${item.empName}(${item.positName})`,
        openYn: false,
        stackItems: false
      });

      const role1 = new GroupModel({
        id: Number(String(empNo) + '1'),
        // title   : '근태',
        title: React.createElement('div', { style: { paddingLeft: 20, hidden: true } }, '근태'),
        parent: Number(empNo),
        root: false,
        titleName: `${item.empName}(${item.positName})`,
        openYn: false
      });

      const role2 = new GroupModel({
        id: Number(String(empNo) + '2'),
        // title   : '근무관리',
        title: React.createElement('div', { style: { paddingLeft: 20 } }, '근무관리'),
        parent: Number(empNo),
        root: false,
        titleName: `${item.empName}(${item.positName})`,
        openYn: false
      });

      const role3 = new GroupModel({
        id: Number(String(empNo) + '3'),
        // title   : '가동실적',
        title: React.createElement('div', { style: { paddingLeft: 20 } }, '가동실적'),
        parent: Number(empNo),
        root: false,
        titleName: `${item.empName}(${item.positName})`,
        openYn: false
      });

      const role4 = new GroupModel({
        id: Number(String(empNo) + '4'),
        // title   : '가동계획',
        title: React.createElement('div', { style: { paddingLeft: 20 } }, '가동계획'),
        parent: Number(empNo),
        root: false,
        titleName: `${item.empName}(${item.positName})`,
        openYn: false
      });

      arrayRoles.push(role);
      arrayRoles.push(role1);
      arrayRoles.push(role2);
      arrayRoles.push(role3);
      arrayRoles.push(role4);
    });

    this.inProgress = false;
    return arrayRoles;
  }

  // 전체 하위 Tree Open (Open:true/Close:false)
  filterPgGroupAllControl(flagYn: boolean): void {
    const arrayRoles: GroupModel[] = [];

    this.pgGroupModel.map((item: any) => {
      const titleObjOpen = React.createElement(
        'div',
        {
          style: { cursor: 'pointer' },
          onClick: () => {
            this.onTogglePgEvent(Number(item.id));
          }
        },
        `[-] ${item.titleName}`
      );

      const titleObjClose = React.createElement(
        'div',
        {
          style: { cursor: 'pointer' },
          onClick: () => {
            this.onTogglePgEvent(Number(item.id));
          }
        },
        `[+] ${item.titleName}`
      );

      if (item.root) {
        if (flagYn) {
          const roleRoot = new GroupModel({
            id: item.id,
            title: titleObjOpen,
            parent: item.parent,
            root: item.root,
            titleName: item.titleName,
            openYn: flagYn
          });

          arrayRoles.push(roleRoot);
        } else {
          const roleRoot = new GroupModel({
            id: item.id,
            title: titleObjClose,
            parent: item.parent,
            root: item.root,
            titleName: item.titleName,
            openYn: flagYn
          });

          arrayRoles.push(roleRoot);
        }
      } else {
        const roleSub = new GroupModel({
          id: item.id,
          title: item.title,
          parent: item.parent,
          root: item.root,
          titleName: item.titleName,
          openYn: flagYn
        });

        arrayRoles.push(roleSub);
      }
    });

    this.pgGroupModel = arrayRoles;

    this.filterPgGroupModel(0);
  }

  // Tree 그룹 펼침/닫침 Event
  filterPgGroupModel(checkedId: number): void {
    this.inProgress = true;

    const arrayRoles: GroupModel[] = [];

    this.pgGroupModel.map((item: any) => {
      if (item.root) {
        const titleObjOpen = React.createElement(
          'div',
          {
            style: { cursor: 'pointer' },
            onClick: () => {
              this.onTogglePgEvent(Number(item.id));
            }
          },
          `[-] ${item.titleName}`
        );

        const titleObjClose = React.createElement(
          'div',
          {
            style: { cursor: 'pointer' },
            onClick: () => {
              this.onTogglePgEvent(Number(item.id));
            }
          },
          `[+] ${item.titleName}`
        );

        if (item.id === checkedId) {
          // 하위 Tree Close
          if (item.openYn) {
            const role = new GroupModel({
              id: item.id,
              title: titleObjClose,
              parent: item.parent,
              root: item.root,
              titleName: item.titleName,
              openYn: !item.openYn
            });
            arrayRoles.push(role);
            // 하위 Tree Open
          } else {
            const role = new GroupModel({
              id: item.id,
              title: titleObjOpen,
              parent: item.parent,
              root: item.root,
              titleName: item.titleName,
              openYn: !item.openYn
            });
            arrayRoles.push(role);
          }
        } else {
          const role = new GroupModel({
            id: item.id,
            title: item.title,
            parent: item.parent,
            root: item.root,
            titleName: item.titleName,
            openYn: item.openYn
          });
          arrayRoles.push(role);
        }

        // Parent 상태 Update
      } else if (item.parent === checkedId) {
        const role = new GroupModel({
          id: item.id,
          title: item.title,
          parent: item.parent,
          root: item.root,
          titleName: item.titleName,
          openYn: !item.openYn
        });
        arrayRoles.push(role);
      } else {
        const role = new GroupModel({
          id: item.id,
          title: item.title,
          parent: item.parent,
          root: item.root,
          titleName: item.titleName,
          openYn: item.openYn
        });
        arrayRoles.push(role);
      }
    });

    this.inProgress = false;
    this.pgGroupModel = arrayRoles;

    const finalArrayRoles: GroupModel[] = [];

    // 상위에서 변경한 openYn 값으로 Group 재구성
    this.pgGroupModel.map((item: any) => {
      // Root 는 항상 Show
      if (item.root) {
        const role = new GroupModel({
          id: item.id,
          title: item.title,
          parent: item.parent,
          root: item.root,
          titleName: item.titleName,
          openYn: !item.openYn
        });
        finalArrayRoles.push(role);
        // Parent 는 OpenYn 으로 판별
      } else if (!item.root) {
        if (item.openYn) {
          const role = new GroupModel({
            id: item.id,
            title: item.title,
            parent: item.parent,
            root: item.root,
            titleName: item.titleName,
            openYn: !item.openYn
          });
          finalArrayRoles.push(role);
        }
      }
    });

    this.afterPgFilterModel = finalArrayRoles;
  }

  createPgGroupItemModel(data: any): ItemModel[] {
    this.inProgress = true;
    const arrayRoles: ItemModel[] = [];
    const { getCommonCodeName } = this.rootStore.systemLanguageStore;

    let leaveYn = false;
    let overTimeYn = false;
    let resultYn = false;
    let planYn = false;

    if (this.teamCheckedVal instanceof Array) {
      this.teamCheckedVal.map(val => {
        if (val === 'L') leaveYn = true;
        if (val === 'O') overTimeYn = true;
        if (val === 'R') resultYn = true;
        if (val === 'P') planYn = true;
      });
    }

    let cnt = 1;

    data.empList.map((emp: any) => {
      // 가동계획
      if (planYn) {
        data.planList.map((plan: any) => {
          if (Number(emp.empNo) === Number(plan.empNo)) {
            let planTitleTxt = getCommonCodeName('0002', plan.apprSts);
            let bgColor = this.planColor;

            const role = new ItemModel({
              id: cnt,
              group: Number(String(emp.empNo) + '4'),
              title: `[${planTitleTxt}]${plan.pjName}(${plan.injctRate}%)`,
              start_time: moment(plan.injctStartDay, 'YYYYMMDD').format('x'),
              end_time: moment(plan.injctEndDay, 'YYYYMMDD')
                .add(1, 'day')
                .format('x'),
              bgColor: bgColor,
              data: plan.pjCode,
              titDesc: 'plan'
            });
            const sumRole = new ItemModel({
              id: cnt += 1,
              group: Number(emp.empNo),
              title: `[${planTitleTxt}]${plan.pjName}(${plan.injctRate}%)`,
              start_time: moment(plan.injctStartDay, 'YYYYMMDD').toDate(),
              end_time: moment(plan.injctEndDay, 'YYYYMMDD')
                .add(1, 'day')
                .toDate(),
              // .format('x'),
              bgColor: bgColor,
              data: plan.pjCode
            });

            cnt += 1;
            arrayRoles.push(sumRole);
            arrayRoles.push(role);
          }
        });
      } // 가동계획 End

      // 가동실적
      if (resultYn) {
        data.resultList.map((result: any) => {
          if (Number(emp.empNo) === Number(result.empNo)) {
            // let resultTitleTxt = getCommonCodeName('0002', result.apprSts);

            let bgColor = this.resultColor;

            const role = new ItemModel({
              id: cnt,
              group: Number(String(emp.empNo) + '3'),
              title: `${result.pjName}(${result.injctRate}%)`,
              start_time: moment(result.injctStartDay, 'YYYYMMDD').format('x'),
              end_time: moment(result.injctEndDay, 'YYYYMMDD')
                .add(1, 'day')
                .format('x'),
              bgColor: bgColor,
              data: result.pjCode,
              titDesc: 'result'
            });
            const sumRole = new ItemModel({
              id: cnt += 1,
              group: Number(emp.empNo),
              title: `${result.pjName}(${result.injctRate}%)`,
              start_time: moment(result.injctStartDay, 'YYYYMMDD').toDate(),
              end_time: moment(result.injctEndDay, 'YYYYMMDD')
                .add(1, 'day')
                .toDate(),
              // .format('x'),
              data: result.pjCode,
              bgColor: bgColor
            });

            cnt += 1;
            arrayRoles.push(sumRole);
            arrayRoles.push(role);
          }
        });
      } // 가동실적 End

      // 근무관리
      if (overTimeYn) {
        data.overTimeList.map((over: any) => {
          if (Number(emp.empNo) === Number(over.empNo)) {
            let overTimeTitleTxt = getCommonCodeName('0006', over.overTimeType);

            let bgColor = this.overTimeColor;

            const role = new ItemModel({
              id: cnt,
              group: Number(String(emp.empNo) + '2'),
              title: `${overTimeTitleTxt}(${over.overtimeWorkHours})`,
              start_time: moment(over.overTimeYmd, 'YYYYMMDD').format('x'),
              end_time: moment(over.overTimeYmd, 'YYYYMMDD')
                .add(1, 'day')
                .format('x'),
              bgColor: bgColor,
              titDesc: 'overtime'
            });
            const sumRole = new ItemModel({
              id: cnt += 1,
              group: Number(emp.empNo),
              title: `${overTimeTitleTxt}(${over.overtimeWorkHours})`,
              start_time: moment(over.overTimeYmd, 'YYYYMMDD').toDate(),
              end_time: moment(over.overTimeYmd, 'YYYYMMDD')
                .add(1, 'day')
                .toDate(),
              // .format('x'),
              bgColor: bgColor
            });

            cnt += 1;
            arrayRoles.push(sumRole);
            arrayRoles.push(role);
          }
        });
      }

      // 근태
      if (leaveYn) {
        data.leaveList.map((leave: any) => {
          if (Number(emp.empNo) === Number(leave.empNo)) {
            let leaveTitleTxt = getCommonCodeName('0007', leave.leaveType);

            let bgColor = this.leaveColor;

            const role = new ItemModel({
              id: cnt,
              group: Number(String(emp.empNo) + '1'),
              title: leaveTitleTxt,
              start_time: moment(leave.leaveStartDay, 'YYYYMMDD').format('x'),
              end_time: moment(leave.leaveEndDay, 'YYYYMMDD')
                .add(1, 'day')
                .format('x'),
              bgColor: bgColor,
              titDesc: 'leave'
            });

            const sumRole = new ItemModel({
              id: cnt += 1,
              group: Number(emp.empNo),
              title: leaveTitleTxt,
              start_time: moment(leave.leaveStartDay, 'YYYYMMDD').toDate(),
              end_time: moment(leave.leaveEndDay, 'YYYYMMDD')
                .add(1, 'day')
                .toDate(),
              // .format('x'),
              bgColor: bgColor
            });

            cnt += 1;
            arrayRoles.push(sumRole);
            arrayRoles.push(role);
          }
        });
      } // 근태 End
    });

    this.inProgress = false;
    return arrayRoles;
  }

  createCheckedFilterItemModel(itemModel: ItemModel): ItemModel {
    let leaveYn = false;
    let overTimeYn = false;
    let resultYn = false;
    let planYn = false;

    if (this.teamCheckedVal instanceof Array) {
      this.teamCheckedVal.map(val => {
        if (val === 'L') leaveYn = true;
        if (val === 'O') overTimeYn = true;
        if (val === 'R') resultYn = true;
        if (val === 'P') planYn = true;
      });
    }

    // itemModel.map((item: any) =>{
    //     if(item.parent === id){
    //         const role = new GroupModel({
    //             id          : item.id,
    //             title       : item.title,
    //             parent      : item.parent,
    //             root        : item.root,
    //             titleName   : item.titleName,
    //             openYn      : !item.openYn,
    //         });
    //         arrayRoles.push(role);
    //     }
    // });

    return itemModel;
  }

  /**********************
   * Project Info Drawer
   **********************/

  @action
  setDrawerPjInfoVisible(visible: boolean) {
    this.drawerPjInfoVisible = visible;
  }

  @action
  getProjectDetailInfo(pjCode: any): any {
    const { getCommonCodeName } = this.rootStore.systemLanguageStore;
    this.inProgress = true;
    return UtilizationService.getProjectDetailInfo(pjCode)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);

        const data = response.data.data || [];
        const info = response.data.data.projectInfo || [];

        this.descPjName = info.pjName;
        this.descPjCode = info.pjCode;
        this.descPmName = `${info.pmEmpNm}(${info.positName})`;
        this.descPeriod = `${moment(info.pjStartDate, 'YYYYMMDD').format('YYYY-MM-DD')} ~ ${moment(info.pjEndDate, 'YYYYMMDD').format('YYYY-MM-DD')}`;
        this.descPjType = getCommonCodeName('0013', info.pjType);
        this.descPjStatus = getCommonCodeName('0012', info.pjStatus);
        this.descPjCreateType = getCommonCodeName('0024', info.pjCreateType);

        data.empList.map((v: any, i: any) => {
          v.no = i + 1;
        });

        this.resourceGridData = data.empList;

        this.inProgress = false;
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }
}

export default UtilizationStore;
