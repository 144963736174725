// to fix ie-11
import 'react-app-polyfill/ie11';
import 'core-js';

import React from 'react';
import ReactDOM from 'react-dom';
import { isBrowser } from 'react-device-detect';
import App from 'shared/layouts/App';
import MApp from 'pages-mobile/layouts/MobileApp';
import { BrowserRouter } from 'react-router-dom';

const {baseURI} = document;
 // console.log("referrer url : " + baseURI);
 // console.log("referrer url : " + document);
if(baseURI === 'http://test.metanet.co.kr:3000/'){
    window.location.replace('https://fa-eozb-saasfaprod1.fa.ocs.oraclecloud.com');
}if(baseURI === 'https://careers.metanet.co.kr/'){
    window.location.replace('https://fa-eozb-saasfaprod1.fa.ocs.oraclecloud.com/hcmUI/CandidateExperience/ko/sites/CX_5001');
}else if(baseURI === 'https://careers.metanet.co.kr/'){
    window.location.replace('https://fa-eozb-saasfaprod1.fa.ocs.oraclecloud.com/hcmUI/CandidateExperience/ko/sites/CX_5001');    
}else if(baseURI === 'https://hcm.metanet.co.kr/'){
    window.location.replace('https://fa-eozb-saasfaprod1.fa.ocs.oraclecloud.com/');
}else if(baseURI === 'http://hcm.metanet.co.kr/'){
    window.location.replace('https://fa-eozb-saasfaprod1.fa.ocs.oraclecloud.com/');
}else if(baseURI === 'https://erp.metanet.co.kr/'){
    window.location.replace('https://fa-eozb-saasfaprod1.fa.ocs.oraclecloud.com/');
}else if(baseURI === 'http://erp.metanet.co.kr/'){
    window.location.replace('https://fa-eozb-saasfaprod1.fa.ocs.oraclecloud.com/');
}else if(document.referrer === 'http://careers.metanetglobal.com/'){
    window.location.replace('https://fa-eozb-saasfaprod1.fa.ocs.oraclecloud.com/hcmUI/CandidateExperience/ko/sites/CX_8001');       
}else if(baseURI === 'http://careers.metanetglobal.com/'){
    window.location.replace('https://fa-eozb-saasfaprod1.fa.ocs.oraclecloud.com/hcmUI/CandidateExperience/ko/sites/CX_8001');    

// test url
}else if(baseURI === 'https://careers-test.metanet.co.kr/'){
    window.location.replace('https://fa-eozb-saasfaprod1.fa.ocs.oraclecloud.com/hcmUI/CandidateExperience/ko/sites/CX_5001');
}else if(baseURI === 'https://careers-test.metanet.co.kr/'){
    window.location.replace('https://fa-eozb-saasfaprod1.fa.ocs.oraclecloud.com/hcmUI/CandidateExperience/ko/sites/CX_5001');    
}else if(baseURI === 'https://hcm-test.metanet.co.kr/'){
    window.location.replace('https://fa-eozb-saasfaprod1.fa.ocs.oraclecloud.com/');
}else if(baseURI === 'http://hcm-test.metanet.co.kr/'){
    window.location.replace('https://fa-eozb-saasfaprod1.fa.ocs.oraclecloud.com/');
}else if(baseURI === 'https://erp-test.metanet.co.kr/'){
    window.location.replace('https://fa-eozb-saasfaprod1.fa.ocs.oraclecloud.com/');
}else if(baseURI === 'http://erp-test.metanet.co.kr/'){
    window.location.replace('https://fa-eozb-saasfaprod1.fa.ocs.oraclecloud.com/');
}else if(document.referrer === 'https://careers-test.metanetglobal.com/'){
    window.location.replace('https://fa-eozb-saasfaprod1.fa.ocs.oraclecloud.com/hcmUI/CandidateExperience/ko/sites/CX_8001');    
}else if(baseURI === 'http://careers-test.metanetglobal.com/'){
    window.location.replace('https://fa-eozb-saasfaprod1.fa.ocs.oraclecloud.com/hcmUI/CandidateExperience/ko/sites/CX_8001');    
}

else{
    const rootElement = document.getElementById('root');
    ReactDOM.render(isBrowser ? <BrowserRouter><App /></BrowserRouter> : <MApp />, rootElement);
  }