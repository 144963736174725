import { Toast } from 'antd-mobile';
import koKR from 'antd/es/locale/ko_KR';
import { createBrowserHistory } from 'history';
import _ from 'lodash';
import { action, observable } from 'mobx';
import { MobileRootStore } from 'shared/mobile-stores/RootStore';
import AuthAPIModel from 'shared/models/auth/AuthAPIModel';
import AppHelper from 'shared/utils/AppHelper';
import LanguageHelper from 'shared/utils/LanguageHelper';
import StorageHelper from 'shared/utils/StorageHelper';
import AuthenticationService from '../services/AuthenticationService';

export class AuthStore {
  @observable
  authModel: AuthAPIModel;

  @observable
  isPasswordResetEmailSent: boolean;

  @observable
  isPasswordResetDone: boolean;

  rootStore: MobileRootStore;
  @observable
  inProgress = false;

  constructor(rootStore: MobileRootStore) {
    this.rootStore = rootStore;
  }

  @action
  logout() {
    this.inProgress = true;
    return AuthenticationService.logout()
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        StorageHelper.resetAll();
        this.authModel = new AuthAPIModel({
          userName: '',
          accessToken: '',
          corpList: [],
          loginWithDefaultPassword: true
        });

        // hard reload
        this.moveToPage('/');
      })
      .catch((e: any) => {
        this.rootStore.apiHandleStore.handleApiError(e);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  @action
  login(userName: string, password: string) {
    const updatedUserName = userName ? userName.trim() : '';
    const trimPassword = password ? password.trim() : '';
    this.inProgress = true;
    return AuthenticationService.login(updatedUserName, trimPassword)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);

        const { data } = response.data;
        if (data) {
          const { loginWithDefaultPassword, userId, accessToken, corpList } = data;
          AuthenticationService.storeToken(userId, userName, accessToken);
          this.rootStore.corporationStore.corpList = data.corpList;

          this.authModel = new AuthAPIModel({
            userId,
            userName,
            accessToken,
            corpList,
            loginWithDefaultPassword
          });

          if (loginWithDefaultPassword) {
            // This is first time to login then move to Change Password page to change default password
            this.moveToPage('/change/password');
          } else if (!(data.corpList.length > 1)) {
            // check if it's NOT concurent user => set token
            StorageHelper.setItem(StorageHelper.KEY_CURR_CORP_CODE, data.corpList[0].corpCode);
            this.runWhenLoginSuccess();
          }
        }
      })
      .catch((e: any) => {
        this.authModel = new AuthAPIModel({
          userName,
          accessToken: '',
          corpList: [],
          loginWithDefaultPassword: true
        });
        AppHelper.notify('error', LanguageHelper.getMessage('login.message.login.fail'), e.message);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  @action
  moveToLoginPage() {
    this.rootStore.uiStore.setCurrentLanguage(koKR);
    StorageHelper.resetAll();
    this.authModel = new AuthAPIModel({
      userName: '',
      accessToken: '',
      corpList: [],
      loginWithDefaultPassword: true
    });
    // hard refresh whole page (send full HTTP request)
    this.moveToPage('/');
  }

  // eslint-disable-next-line class-methods-use-this
  get isLogged(): boolean {
    const hasStorageCorp = !!StorageHelper.getItem(StorageHelper.KEY_CURR_CORP_CODE);
    const hasToken = !!StorageHelper.getItem(StorageHelper.KEY_ACCESS_TOKEN);
    return hasStorageCorp && hasToken;
  }

  // eslint-disable-next-line class-methods-use-this
  get selectedCorporation(): any {
    return StorageHelper.getItem(StorageHelper.KEY_CURR_CORP_CODE);
  }

  setSelectedCorp(corpCode: string, isSwitchCorp?: boolean) {
    this.inProgress = true;
    return AuthenticationService.fetchAccessTokenBySelectedCorp(corpCode, isSwitchCorp)
      .then((response: any) => {
        const { isSuccessful } = response.data.header;
        if (!isSuccessful) {
          this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        } else {
          StorageHelper.setItem(StorageHelper.KEY_CURR_CORP_CODE, corpCode);
          const jwtToken = response.data.data.accessToken;
          AuthenticationService.storeSecondToken(jwtToken);
          this.rootStore.mobileHeaderSidebarStore.getMenuAssigned();
          this.runWhenLoginSuccess();
        }
      })
      .catch((e: any) => {
        console.error(`Second Token fetching fail: ${JSON.stringify(e)}`);
        AppHelper.notify('error', 'Second Token fetching fail', e.message);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  @action
  findPassword = (userName: string, email: string) => {
    this.isPasswordResetEmailSent = false;
    this.inProgress = true;
    return AuthenticationService.findPassword(_.trim(userName), _.trim(email))
      .then((response: any) => {
        const { isSuccessful } = response.data.header;
        if (!isSuccessful) {
          this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        } else {
          this.isPasswordResetEmailSent = true;
        }
        this.inProgress = false;
      })
      .catch((e: any) => {
        AppHelper.notify('error', 'Password Finder fail', e.message);
        this.isPasswordResetEmailSent = false;
      })
      .finally(() => {
        this.inProgress = false;
      });
  };

  @action
  resetPassword = (userName: string, token: string, newPassword: string) => {
    this.isPasswordResetDone = false;
    this.inProgress = true;
    return AuthenticationService.resetPassword(_.trim(userName), token, newPassword)
      .then((response: any) => {
        const { isSuccessful } = response.data.header;
        if (!isSuccessful) {
          this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        } else {
          Toast.success('비밀번호 재설정이 완료되었습니다.', 1);
          this.isPasswordResetDone = true;
        }
        this.inProgress = false;
      })
      .catch((e: any) => {
        Toast.fail(e.message, 1);
        this.isPasswordResetDone = false;
      })
      .finally(() => {
        this.inProgress = false;
      });
  };

  @action
  changePassword = (userId: string, oldPassword: string, newPassword: string) => {
    return AuthenticationService.changePassword(userId, oldPassword, newPassword)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const { isSuccessful } = response.data.header;
        if (isSuccessful) {
          Toast.success('비밀번호 변경이 완료되었습니다.', 1);
          this.rootStore.authStore!.moveToLoginPage();
        }
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  };

  moveToPage = (url: string) => {
    const localHistory = createBrowserHistory({
      forceRefresh: true
    });
    localHistory.push(url);
  };

  private runWhenLoginSuccess = () => {
    this.rootStore.systemLanguageStore.fetchLanguage();
  };
}
