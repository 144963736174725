import { observable, action } from 'mobx';
import moment from 'moment';
import CalendarModel from '../static/CalendarModel';
import UtilizationResultModel from '../static/UtilizationResultModel';
import UtilizationResultCalendarMobileService from '../services/UtilizationResultCalendarMobileService';
import { MobileRootStore } from 'shared/mobile-stores/RootStore';

interface CloseStsModel {
  corpCode: string | null;
  yearMonth: string | null;
  closeSts: string | null;
}

export default class UtilizationResultCalendarMobileStore {
  @observable
  inProgress: boolean;

  @observable
  selectedWaypoint = '0';

  @observable
  nowYear = '0';

  @observable
  nowMonth = '0';

  @observable
  preYearMonth = '0';

  @observable
  nowYearMonth = '0';

  @observable
  nextYearMonth = '0';

  @observable
  monthCalendarList: CalendarModel[];

  @observable
  monthUtilizationResultList: UtilizationResultModel[];

  @observable
  closingWeekly = 'N';

  @observable
  closingSts: CloseStsModel;

  rootStore: MobileRootStore;

  constructor(rootStore: MobileRootStore) {
    this.inProgress = false;
    this.rootStore = rootStore;
    this.monthCalendarList = [];
    this.monthUtilizationResultList = [];
  }

  @action
  getCalendarModel(date: string): any {
    this.inProgress = true;
    return UtilizationResultCalendarMobileService.getCalendarModel(date)
      .then(response => {
        const data = response.data.data || [];

        this.inProgress = false;
      })
      .catch((error: any) => {
        console.error(`Errors: ${error}`);
        this.inProgress = false;
      });
  }

  @action
  getNowYearMonth(): any {
    // 현재년월 가져오기
    this.inProgress = true;

    this.nowYear = moment()
      .format('YYYY')
      .toString();

    this.nowYearMonth = moment()
      .format('YYYYMM')
      .toString();
    this.preYearMonth = moment()
      .add(-1, 'months')
      .format('YYYYMM')
      .toString();
    this.nextYearMonth = moment()
      .add(1, 'months')
      .format('YYYYMM')
      .toString();

    return this.getMonthCalendarList(
      moment()
        .format('YYYYMM')
        .toString()
    );
  }

  @action
  getMonthCalendarList(yearMonth: string): any {
    // monthly 달력 가져오기
    this.inProgress = true;

    this.nowYearMonth = yearMonth;
    this.preYearMonth = moment(yearMonth, 'YYYYMM')
      .add(-1, 'months')
      .format('YYYYMM')
      .toString();
    this.nextYearMonth = moment(yearMonth, 'YYYYMM')
      .add(1, 'months')
      .format('YYYYMM')
      .toString();

    return UtilizationResultCalendarMobileService.getMonthCalendarList(yearMonth)
      .then(response => {
        const data = response.data.data || [];
        this.monthCalendarList = data;

        const toDate = data.reduce((previous: any, current: any) => {
          return previous.yyyymmdd > current.yyyymmdd ? previous : current;
        });

        const fromDate = data.reduce((previous: any, current: any) => {
          return previous.yyyymmdd > current.yyyymmdd ? current : previous;
        });
        this.getMonthUtilizationResultList(fromDate.yyyymmdd, toDate.yyyymmdd);

        this.inProgress = false;
      })
      .catch((error: any) => {
        console.error(`Errors: ${error}`);
        this.inProgress = false;
      });
  }

  @action
  getMonthUtilizationResultList(fromDate: string, toDate: string): any {
    // 가동실적 가져오기
    this.inProgress = true;

    return UtilizationResultCalendarMobileService.getUtilizationResultList(fromDate, toDate)
      .then(response => {
        const data = response.data.data || [];
        this.monthUtilizationResultList = data;
        this.inProgress = false;
      })
      .catch((error: any) => {
        console.error(`Errors: ${error}`);
        this.inProgress = false;
      });
  }

  @action
  addUtilizationResult(mh: any): any {
    // 가동실적 추가하기
    this.inProgress = true;
    const mhItem = {
      rowStatus: 'create',
      corpCode: '',
      empNo: 'me',
      seqNo: 0,
      apprSts: 'I',
      injctYmd: mh.injctYmd.toString(),
      pjCode: mh.pjCode.toString(),
      injctRate: Number(mh.injctRate) / 100,
      injctDay: Number(mh.injctRate) / 100,
      injctTime: (Number(mh.injctRate) / 100) * 8,
      actvType: mh.actvType.toString(),
      empName: null,
      deptName: null,
      pjName: mh.pjName.toString(),
      deptCode: ''
    };
    return UtilizationResultCalendarMobileService.createUtilizationResult([mhItem])
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        this.getMonthCalendarList(this.nowYearMonth);
        this.inProgress = false;
      })
      .catch((error: any) => {
        console.error(`Errors: ${error}`);
        this.inProgress = false;
      });
  }

  @action
  modifyUtilizationResult(mh: UtilizationResultModel): any {
    // 가동실적 수정하기
    this.inProgress = true;

    return UtilizationResultCalendarMobileService.updateUtilizationResult([mh])
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        this.getMonthCalendarList(this.nowYearMonth);
        this.inProgress = false;
      })
      .catch((error: any) => {
        console.error(`Errors: ${error}`);
        this.inProgress = false;
      });
  }

  @action
  removeUtilizationResult(mh: UtilizationResultModel): any {
    // 가동실적 제거하기
    this.inProgress = true;

    return UtilizationResultCalendarMobileService.deleteUtilizationResult([mh])
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        this.getMonthCalendarList(this.nowYearMonth);
        this.inProgress = false;
      })
      .catch((error: any) => {
        console.error(`Errors: ${error}`);
        this.inProgress = false;
      });
  }

  @action
  saveDailyUtilizationResult(yyyymmdd: string): any {
    // 일자 별 가동실적 저장
    this.inProgress = true;
  }

  @action
  getCorpConf(): any {
    // 법인 별 설정정보 가져오기 - 주마감 check
    this.inProgress = true;
    return UtilizationResultCalendarMobileService.getCorpConf()
      .then(response => {
        const data = response.data.data || [];

        this.closingWeekly = data.closingWeekly;

        this.inProgress = false;
      })
      .catch((error: any) => {
        console.error(`Errors: ${error}`);
        this.inProgress = false;
      });
  }

  @action
  getClosingSts(injctYmd: string): any {
    return UtilizationResultCalendarMobileService.getCloseSts(
      moment(injctYmd, 'YYYYMMDD')
        .format('YYYYMM')
        .toString()
    )
      .then(response => {
        const data = response.data.data || [];
        this.closingSts = data;
        return data;
      })
      .catch((error: any) => {
        console.error(`Errors: ${error}`);
      });
  }

  @action
  modifyUtilizationItem(mh: UtilizationResultModel, newMh: UtilizationResultModel): any {
    this.inProgress = true;
    mh = newMh;
    this.inProgress = false;
  }

  @action
  async regUtilizationResultInjctYmdValidation(mh: any) {
    this.inProgress = true;
    // 저장 전 최종 validation
    let validateSuccess = true;
    let message: string[] = [];

    await UtilizationResultCalendarMobileService.getCalendarModel(mh.injctYmd).then((response: any) => {
      const data = response.data.data || [];
      if (data.holidayYn === 'Y') {
        // 휴무일에는 가동실적을 입력할 수 없습니다.
        validateSuccess = false;
        message.push('휴무일에는 가동실적을 입력할 수 없습니다.');
      }
    });

    await UtilizationResultCalendarMobileService.getUtilizationResultList(mh.injctYmd, mh.injctYmd)
      .then(response => {
        const data = response.data.data || [];

        if (data.length > 0) {
          let mhSum = 0;
          data.forEach((item: any) => {
            if (item.actvType === mh.actvType && item.pjCode === mh.pjCode) {
              // 동일한 활동구분, 프로젝트로 입력된 가동실적이 존재합니다.
              validateSuccess = false;
              message.push('동일한 활동구분, 프로젝트로 입력된 가동실적이 존재합니다.');
            }
            mhSum += Number(item.injctRate) * 100;
          });
          if (Number(mhSum) + Number(mh.injctRate) > 100) {
            // 가동율이 100을 초과합니다.
            validateSuccess = false;
            message.push('가동율이 100을 초과합니다.');
          }
        }
      })
      .catch((error: any) => {
        console.error(`Errors: ${error}`);
        this.inProgress = false;
      });

    await UtilizationResultCalendarMobileService.getCloseSts(mh.injctYmd)
      .then(response => {
        const data = response.data.data || [];
        if (data.closeSts === 'C' || data.closeSts === 'T') {
          // 마감되었습니다. // 월마감
          validateSuccess = false;
          message.push('마감되었습니다.');
        }
      })
      .catch((error: any) => {
        console.error(`Errors: ${error}`);
      });

    if (validateSuccess) {
      await this.addUtilizationResult(mh);
      this.inProgress = false;
      return message;
    } else {
      this.inProgress = false;
      return message;
    }
  }

  @action
  setSelectedWaypoint(waypoint: string) {
    this.selectedWaypoint = waypoint;
  }
}
