import { observable } from 'mobx';
import CorporationAPIModel from 'shared/models/corporation/CorporationAPIModel';
import { RootStore } from 'shared/stores/RootStore';

export class CorporationStore {
  rootStore: RootStore;
  @observable
  private _corpList: CorporationAPIModel[] = [];

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  get corpList(): any[] {
    return this._corpList;
  }

  set corpList(arr: any[]) {
    this._corpList = arr;
  }
}
