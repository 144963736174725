import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import OvertimeAppMobileStore from '../stores/OvertimeAppMobileStore';
import OverTimeSumModel from '../static/OverTimeSumModel';
import { Button, Modal } from 'antd-mobile';
import MobileLanguageHelper from 'shared/utils/MobileLanguageHelper';

const { alert } = Modal;

interface Props {
  overtimeAppMobileStore?: OvertimeAppMobileStore;
  data: OverTimeSumModel;
  forwardDetailView: () => void;
}

const CardWrapper = styled.div`
  /* background-color: #f3f3f4; */
  background-color: #ffffff;
  border-bottom: 1px solid #e3e3e3;
  ::after {
    content: '';
    display: table;
    clear: both;
  }
  padding: 10px;
  text-align: center;
  margin-bottom: 5px;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 14px;
  margin-bottom: 5px;
`;

const CardHeaderDeptName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 2em;
  height: 2em;
  width: 100px;
`;

const CardHeaderEmpName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 2em;
  height: 2em;
  width: 80px;
`;

const CardBody = styled.div`
  display: flex;
  justify-content: space-around;
`;

const CardLeftBody = styled.div`
  background-color: #f6f6f6;
  padding: 10px;
  border-radius: 10px;
`;

const CardRightBody = styled.div`
  background-color: #f6f6f6;
  padding: 10px 20px;
  border-radius: 10px;
`;

const CardBodyTitle = styled.div`
  text-align: center;
  font-size: 12px;
  color: #747474;
  margin-bottom: 10px;
  border-bottom: 1px solid #bdbdbd;
`;

const CardBodyContents = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 12px;
`;

const RenterOverTimeHours = styled.div`
  font-size: 16px;
  font-weight: 700;
  background-color: #e5e5e5;
  padding: 5px;
  border-radius: 25px;
  height: 50px;
  width: 50px;
  display: inline-block;
  line-height: 37px;
`;

@inject('overtimeAppMobileStore')
@observer
class AppMobileCardComponent extends Component<Props> {
  onClickCard = (data: OverTimeSumModel): void => {
    this.props.overtimeAppMobileStore!.setRecordToDetail(data);
    this.props.overtimeAppMobileStore!.getWorkType(data.empNo, data.yyyy + data.mm);
    this.props.overtimeAppMobileStore!.getMonthOverTimeAppr(data.empNo, data.yyyy + data.mm, data.pjCode);

    if (data.corpCode !== null) localStorage.setItem('overtimeAppDetailcorpCode', data.corpCode);
    localStorage.setItem('overtimeAppDetailEmpNo', data.empNo);
    localStorage.setItem('overtimeAppDetailEmpNm', data.empNm);
    localStorage.setItem('overtimeAppDetailYyyy', data.yyyy);
    localStorage.setItem('overtimeAppDetailMm', data.mm);
    if (data.pjCode !== null) localStorage.setItem('overtimeAppDetailPjCode', data.pjCode);

    localStorage.setItem('overtimeAppDetailAllApprYn', data.allApprYn!);
    localStorage.setItem('overtimeAppDetailClosingYn', data.closingYn);
    localStorage.setItem('overtimeAppDetailCountsAppr', data.countsAppr);
    localStorage.setItem('overtimeAppDetailDeptNm', data.deptNm!);
    localStorage.setItem('overtimeAppDetailRowStatus', data.rowStatus!);
    localStorage.setItem('overtimeAppDetailAfterAverageOverTime', data.afterAverageOverTime.toString());
    localStorage.setItem('overtimeAppDetailBeforeAverageOverTime', data.beforeAverageOverTime.toString());
    localStorage.setItem('overtimeAppDetailNo', data.no);

    this.props.forwardDetailView();
  };

  handleAllAppr = (record: OverTimeSumModel, e: React.MouseEvent): void => {
    e.stopPropagation();
    alert('일괄승인', `${record.empNm}의 승인대기 ${record.countsAppr} 건을 승인하시겠습니까?`, [
      { text: MobileLanguageHelper.getMessage('overtime.button.title.cancel') },
      { text: MobileLanguageHelper.getMessage('overtime.button.title.approval'), onPress: () => this.props.overtimeAppMobileStore!.allApprListOverTime(record) }
    ]);
  };

  render() {
    const { data } = this.props;

    return (
      <CardWrapper onClick={(): void => this.onClickCard(data)}>
        <CardHeader>
          <CardHeaderDeptName>{data.deptNm}</CardHeaderDeptName>
          <CardHeaderEmpName>{data.empNo}</CardHeaderEmpName>
          <CardHeaderEmpName>{data.empNm}</CardHeaderEmpName>
          <div>
            {data.allApprYn === 'Y' ? (
              <Button type="primary" inline size="small" onClick={event => this.handleAllAppr(data, event)}>
                {MobileLanguageHelper.getMessage('overtime.button.title.apprall')}
              </Button>
            ) : (
              <Button type="primary" inline size="small" onClick={event => this.handleAllAppr(data, event)} disabled>
                {MobileLanguageHelper.getMessage('overtime.button.title.apprall')}
              </Button>
            )}
          </div>
        </CardHeader>
        <CardBody>
          <CardLeftBody>
            <CardBodyTitle>{MobileLanguageHelper.getMessage('overtime.card.title.apprwaiting')}</CardBodyTitle>
            <RenterOverTimeHours>{data.countsAppr}</RenterOverTimeHours>
          </CardLeftBody>
          <CardRightBody>
            <CardBodyTitle>{MobileLanguageHelper.getMessage('overtime.card.title.workinghoursavg')}</CardBodyTitle>
            <CardBodyContents>
              <div style={{ marginRight: '5px' }}>
                {MobileLanguageHelper.getMessage('overtime.card.title.beforeappr')} <RenterOverTimeHours>{data.beforeAverageOverTime}</RenterOverTimeHours>
              </div>
              <div>
                {MobileLanguageHelper.getMessage('overtime.card.title.afterappr')} <RenterOverTimeHours>{data.afterAverageOverTime}</RenterOverTimeHours>
              </div>
            </CardBodyContents>
          </CardRightBody>
        </CardBody>
      </CardWrapper>
    );
  }
}

export default AppMobileCardComponent;
