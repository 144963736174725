class DecodedTokenModel {
  roles: string[];
  exp: number;
  iat: number;
  userName: string;

  getRoles() {
    return this.roles;
  }

  setRoles(roles: string[]) {
    this.roles = roles;
  }
}

export default DecodedTokenModel;
