import React from 'react';
import { Form, Input, Button, Row, Col, Select, DatePicker } from 'antd';
import { observer, inject } from 'mobx-react';
import { FormComponentProps } from 'antd/lib/form/Form';
import { FormattedMessage } from 'react-intl';
import CommonProjectTableDrawerContainer from './CommonProjectTableDrawerContainer';
import commonStore from '../stores/CommonStore';
import SelectCommonCode from 'shared/containers/SelectCommonCode';
import CommonDepartmentDrawer from 'pages/common/CommonDepartmentDrawer';

interface Props {
  commonStore?: commonStore;
  buttonText?: any;
  form?: any;
  onClose?: any;
  searchPattern?: string;
}
@inject('commonStore')
@observer
class CommonProjectDrawerFormComponent extends React.Component<Props & FormComponentProps, any> {
  mainPerformDept: string;
  mainPerformDeptNm: string;
  mainSalesDept: string;
  mainSalesDeptNm: string;
  pjCode: string;
  pmName: string;
  pjCreateType: string;
  searchPattern: string;

  constructor(props: any) {
    super(props);
    this.mainPerformDept = '';
    this.mainPerformDeptNm = '';
    this.mainSalesDept = '';
    this.mainSalesDeptNm = '';
    this.pjCode = '';
    this.pmName = '';
    this.pjCreateType = '4C';
    this.searchPattern = this.props.searchPattern!;
  }

  state = {
    visible: false,
    popVisible: false,
    gbType: '',
    openDepartmentDrawer: false,
    performDeptInfo: {
      deptCode: '',
      deptName: ''
    },
    salesDeptInfo: {
      deptCode: '',
      deptName: ''
    }
  };

  onSearch = (e: any) => {
    e.preventDefault();
    this.props.commonStore!.getProjects(this.mainPerformDept, this.pjCode, this.pmName, this.pjCreateType, this.searchPattern, this.mainSalesDept);
  };

  onChangeDeptDrawerInput = (e: any) => {
    this.mainPerformDept = e.target.value;
  };

  onChangePjCodeDrawerInput = (e: any) => {
    this.pjCode = e.target.value;
  };

  onChangePmNameDrawerInput = (e: any) => {
    this.pmName = e.target.value;
  };

  onChangeTypeSelect = (v: any): void => {
    this.pjCreateType = v;
  };

  // Department Drawer 영역
  openSelectDepartment = async (type: string) => {
    await this.props.commonStore!.fetchDepartmentForSearchPanel();
    this.setState({
      gbType: type,
      openDepartmentDrawer: true
    });
  };

  closeSelectDepartment = () => {
    this.setState({
      openDepartmentDrawer: false
    });
  };

  handleSelectDepartmentInDrawer = () => {
    const deptInfo = this.getDeptData();

    if (this.state.gbType === 'P') {
      this.setState({
        openDepartmentDrawer: false,
        performDeptInfo: deptInfo
      });

      this.mainPerformDept = deptInfo.deptCode;
      this.mainPerformDeptNm = deptInfo.deptName;
    } else if (this.state.gbType === 'S') {
      this.setState({
        openDepartmentDrawer: false,
        salesDeptInfo: deptInfo
      });

      this.mainSalesDept = deptInfo.deptCode;
      this.mainSalesDeptNm = deptInfo.deptName;
    }

    this.setState({
      gbType: ''
    });
  };

  handleInitPerformDeptValue = (e: any) => {
    this.mainPerformDept = '';
    this.mainPerformDeptNm = '';

    this.setState({
      performDeptInfo: {
        deptCode: '',
        deptName: ''
      }
    });
  };

  handleInitSalesDeptValue = (e: any) => {
    this.mainSalesDept = '';
    this.mainSalesDeptNm = '';

    this.setState({
      salesDeptInfo: {
        deptCode: '',
        deptName: ''
      }
    });
  };

  getDeptData = (): { deptCode: string; deptName: string } => {
    const { deptCode, deptName } = this.props.commonStore!.selectedDepartment;
    return { deptCode: deptCode || '', deptName: deptName || '' };
  };

  render() {
    const propsCommonDepartmentDrawer = {
      onCloseDrawer: this.closeSelectDepartment,
      onSaveDrawer: this.handleSelectDepartmentInDrawer
    };

    return (
      <div>
        <CommonDepartmentDrawer {...propsCommonDepartmentDrawer} visibleCondition={this.state.openDepartmentDrawer} />
        <Form className="employee-form" layout="vertical">
          <Row gutter={24} type="flex" align="middle" justify="start">
            <Col>
              <Form.Item label={<FormattedMessage id="label.drawer.mainPerformDept" />}>
                <Input
                  onClick={() => {
                    this.openSelectDepartment('P');
                  }}
                  value={this.state.performDeptInfo.deptName}
                  onChange={this.handleInitPerformDeptValue}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label={<FormattedMessage id="label.drawer.mainSalesDept" />}>
                <Input
                  onClick={() => {
                    this.openSelectDepartment('S');
                  }}
                  value={this.state.salesDeptInfo.deptName}
                  onChange={this.handleInitSalesDeptValue}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} type="flex" align="middle" justify="start">
            <Col>
              <Form.Item label={<FormattedMessage id="label.common.project.code.name" />}>
                <Input allowClear style={{ width: '100%' }} onChange={this.onChangePjCodeDrawerInput} onPressEnter={this.onSearch} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label={<FormattedMessage id="label.common.pm.name" />}>
                <Input allowClear style={{ width: '100%' }} onChange={this.onChangePmNameDrawerInput} onPressEnter={this.onSearch} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} type="flex" align="middle" justify="start">
            <Col span={8}>
              <Form.Item label={<FormattedMessage id="table.drawer.col.pj.status" />}>
                <SelectCommonCode
                  representCode={'0024'}
                  defaultValue={'4C'}
                  emptyOptionMessageKey="workhour.time.management.select.empty.all"
                  onChangeCommonCode={(values: any) => {
                    this.onChangeTypeSelect(values);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <CommonProjectTableDrawerContainer onSearch={this.onSearch} />
          </Row>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button icon="check" onClick={this.props.onClose}>
              <span>
                <FormattedMessage id="button.drawer.button.confirm" />
              </span>
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

const WrappedFormDrawer = Form.create<Props & FormComponentProps>()(CommonProjectDrawerFormComponent);
export default WrappedFormDrawer;
