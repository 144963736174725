import { action, observable, toJS } from 'mobx';
import UsersServices from 'pages-mobile/user-info/services/UserService';
import EmployeeModel from 'shared/models/employee/EmployeeModel';
import EmployeeCountModel from 'shared/models/user/EmployeeCountModel';
import { MobileRootStore } from 'shared/mobile-stores/RootStore';

export class UserInfoStore {
  rootStore: MobileRootStore;

  @observable
  userInfoModel: EmployeeCountModel;

  @observable
  inProgress = false;

  constructor(rootStore: any) {
    this.rootStore = rootStore;
    this.userInfoModel = new EmployeeCountModel({
      userName: '',
      userId: '',
      empList: Array<EmployeeModel>()
    });
  }

  @action
  findUserInfo(userId: string) {
    this.inProgress = true;
    this.userInfoModel = new EmployeeCountModel({
      userName: '',
      userId: '',
      empList: Array<EmployeeModel>()
    });
    return UsersServices.findEmployees(userId)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const { data } = response.data;
        if(data) {
          const { userName } = data;
          const empList = data.empList || [];
          const validEmpList = empList.filter((empData: EmployeeModel) => {
            return empData.officeCode !== '2'; // For resigned Employees
          });

          this.userInfoModel = new EmployeeCountModel({
            userName: userName || '',
            userId: userId || '',
            empList: validEmpList
          });
        }
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }
}
