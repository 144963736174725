import React from 'react';
import { Layout, Menu, Icon } from 'antd';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ClickParam } from 'antd/lib/menu';

const { SubMenu } = Menu;
const { Sider } = Layout;

interface IProps {
  match?: any;
}
class SystemCommonSider extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
    const { selectedKeys, openKeys } = this.getActiveMenu();
    this.state = {
      selectedKeys,
      openKeys
    };
  }

  state = {
    selectedKeys: [''],
    openKeys: ['']
  };

  handleClick = (param: ClickParam) => {
    this.setState({
      selectedKeys: [param.key]
    });
  };

  getActiveMenu = () => {
    const { path } = this.props.match;

    try {
      const arrPath: string[] = path.split('/');
      const selectedKeys = [arrPath.pop() || ''];
      const openKeys = [arrPath.pop() || ''];

      return {
        selectedKeys,
        openKeys
      };
    } catch {
      return {
        selectedKeys: ['project'],
        openKeys: ['timesheet']
      };
    }
  };

  render() {
    const { selectedKeys, openKeys } = this.state;
    return (
      <Sider width={200} style={{ overflowY: 'auto' }}>
        <div className="hrm-sidebar-menu-wrapper">
          <Menu theme="dark" mode="inline" selectedKeys={selectedKeys} defaultOpenKeys={openKeys} onClick={this.handleClick}>
            <SubMenu
              key="project"
              title={
                <span>
                  <Icon type="folder" />
                  <FormattedMessage id="hrm.component.project" />
                </span>
              }
              // onTitleClick={this.onSelectSubMenu}
            >
              <Menu.Item key="projectmngt">
                <Link to="/timesheet/project/project/projectmngt">
                  <span>
                    <FormattedMessage id="hrm.component.projectmngt" />
                  </span>
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="utilplan"
              title={
                <span>
                  <Icon type="folder" />
                  <FormattedMessage id="hrm.component.utilplan" />
                </span>
              }
              // onTitleClick={this.onSelectSubMenu}
            >
              <Menu.Item key="hrassignrequest">
                <Link to="/timesheet/util/utilplan/hrassignrequest">
                  <span>
                    <FormattedMessage id="hrm.component.hrassignrequest" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="hrassignmngt">
                <Link to="/timesheet/util/utilplan/hrassignmngt">
                  <span>
                    <FormattedMessage id="hrm.component.hrassignmngt" />
                  </span>
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="utilresult"
              title={
                <span>
                  <Icon type="folder" />

                  <FormattedMessage id="hrm.component.utilresult" />
                </span>
              }
              // onTitleClick={this.onSelectSubMenu}
            >
              <Menu.Item key="utilresultreg">
                <Link to="/timesheet/util/utilresult/utilresultreg">
                  <span>
                    <FormattedMessage id="hrm.component.utilresultreg" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="utilresultapp">
                <Link to="/timesheet/util/utilresult/utilresultapp">
                  <span>
                    <FormattedMessage id="hrm.component.utilresultapp" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="utilresultclosing">
                <Link to="/timesheet/util/utilresult/utilresultclosing">
                  <span>
                    <FormattedMessage id="hrm.component.utilresultclosing" />
                  </span>
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="util"
              title={
                <span>
                  <Icon type="folder" />
                  <FormattedMessage id="hrm.component.util" />
                </span>
              }
            >
              <Menu.Item key="member">
                <Link to="/timesheet/util/util/member">
                  <span>
                    <FormattedMessage id="hrm.component.member" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="team">
                <Link to="/timesheet/util/util/team">
                  <span>
                    <FormattedMessage id="hrm.component.team" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="project">
                <Link to="/timesheet/util/util/project">
                  <span>
                    <FormattedMessage id="hrm.component.project" />
                  </span>
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="overtime"
              title={
                <span>
                  <Icon type="folder" />
                  <FormattedMessage id="hrm.component.overtime" />
                </span>
              }
            >
              <Menu.Item key="overtimereg">
                <Link to="/timesheet/overtime/overtime/overtimereg">
                  <span>
                    <FormattedMessage id="hrm.component.overtimereg" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="overtimeapp">
                <Link to="/timesheet/overtime/overtime/overtimeapp">
                  <span>
                    <FormattedMessage id="hrm.component.overtimeapp" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="worktypemngt">
                <Link to="/timesheet/overtime/overtime/worktypemngt">
                  <span>
                    <FormattedMessage id="hrm.component.worktypemngt" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="reducWorkManagementView">
                <Link to="/timesheet/overtime/overtime/reducworkmngt">
                  <span>
                    <FormattedMessage id="hrm.component.workhour.time.management" />
                  </span>
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="corpconfig"
              title={
                <span>
                  <Icon type="folder" />
                  <FormattedMessage id="hrm.component.corpconfig" />
                </span>
              }
            >
              <Menu.Item key="item-corpconfig">
                <Link to="/timesheet/configmngt/corpconfig/corpconfig">
                  <span>
                    <FormattedMessage id="hrm.component.corpconfig" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="calendarmngt">
                <Link to="/timesheet/configmngt/corpconfig/calendarmngt">
                  <span>
                    <FormattedMessage id="hrm.component.calendarmngt" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="holidaymngt">
                <Link to="/timesheet/configmngt/corpconfig/holidaymngt">
                  <span>
                    <FormattedMessage id="hrm.component.holidaymngt" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="legacy">
                <Link to="/timesheet/configmngt/corpconfig/legacy">
                  <span>
                    <FormattedMessage id="hrm.component.legacy" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="datadown">
                <Link to="/timesheet/configmngt/corpconfig/datadown">
                  <span>
                    <FormattedMessage id="hrm.component.datadown" />
                  </span>
                </Link>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </div>
      </Sider>
    );
  }
}

export default SystemCommonSider;
