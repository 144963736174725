import { Icon } from 'antd';
import { Button, Flex, InputItem, List, Result, WhiteSpace } from 'antd-mobile';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Redirect } from 'react-router-dom';
import '../password-finder-mobile.less';

const rcForm = require('rc-form');

@inject('authStore', 'corporationStore')
@observer
class PasswordFinderMobileView extends React.Component<any, any> {
  state = {
    isMoveBackToLogin: false,
    isPasswordResetEmailSent: false
  };

  getPasswordResetEmailSentForm = () => {
    const myImg = (src: string) => <img src={src} className="spe am-icon am-icon-md" alt="" />;
    return (
      <div className="password-reset-email-sent-form">
        <Result          
          img={myImg('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCLZX-HFOziw3PmyhuNdxpQpafEkLpygGi6IZyj6zAGEOnuAfU&s')}
          title="비밀번호 재설정 이메일 전송 완료"
          message="입력하신 주소로 이메일이 전송되었습니다. 이메일에 포함된 지침에 따라 비밀번호를 재설정하십시오."
        />
      </div>
    );
  };

  onSubmit = () => {
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const { userName, email } = values;
        this.props.authStore!.findPassword(userName, email).then(() => {
          if (this.props.authStore!.isPasswordResetEmailSent) {
            this.setState({
              isMoveBackToLogin: false,
              isPasswordResetEmailSent: true
            });
          }
        });
      }
    });
  };


  onMoveBackToLogin = () => {
    this.props.history.push('/m/');
  };

  getPasswordFinderForm = () => {
    let errors;
    const { getFieldProps, getFieldError } = this.props.form;

    // eslint-disable-next-line no-return-assign
    return (
      <div style={{ height: '100%', background: '#fff' }}>
        <Flex justify="center" direction="column" className="login-m-container">
          <h1>비밀번호 찾기</h1>
          <WhiteSpace size="lg" />

          <List style={{ padding: '0 40px' }}>
            <InputItem
              {...getFieldProps('userName', {
                rules: [{ required: true, message: 'Username 은(는) 필수 항목입니다' }]
              })}
              placeholder="로그인ID"
              className={getFieldError('userName') ? 'err-input' : ''}
            >
              <Icon type="user" />
            </InputItem>
            {(errors = getFieldError('userName')) ? <span className="err-text">{errors.join(',')}</span> : null}
            <WhiteSpace size="md" />

            <InputItem
              {...getFieldProps('email', {
                rules: [{ required: true, message: '형식에 맞는 이메일 주소를 입력해주세요' }]
              })}
              type="email"
              placeholder="내용을 입력해주세요"
              className={getFieldError('email') ? 'err-input' : ''}
            >
              <Icon type="mail" />
            </InputItem>
            {(errors = getFieldError('email')) ? <span className="err-text">{errors.join(',')}</span> : null}
            <WhiteSpace size="md" />

            <Button type="primary" onClick={this.onSubmit}>인증번호 발송</Button>
            <WhiteSpace />
            <Button icon="left" onClick={this.onMoveBackToLogin}>뒤로 가기</Button>
          </List>

          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />

          <img width="30%" alt="example" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSokS4ZFbokxZP0_teVuPZfxRvCFvL8BkLEuy2ggaQluDgUO5WEug" />
        </Flex>
      </div>
    );
  };

  render() {
    const { isMoveBackToLogin, isPasswordResetEmailSent } = this.state;
    if (isMoveBackToLogin) {
      return <Redirect to="/m" />;
    }

    // return <div style={{ height: '100%', background: '#fff' }}>{isPasswordResetEmailSent ? this.getPasswordResetEmailSentForm() : this.getPasswordFinderForm()}</div>;
    return <div className="password-finder-form">{isPasswordResetEmailSent ? this.getPasswordResetEmailSentForm() : this.getPasswordFinderForm()}</div>;
  }
}
const WrappedPasswordFinderMobileForm = rcForm.createForm()(PasswordFinderMobileView);
export default WrappedPasswordFinderMobileForm;
