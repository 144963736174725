import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import OvertimeMonthApprListStore from '../stores/OvertimeMonthApprListStore';
import { FormattedMessage } from 'react-intl';

interface Props {
  overtimeMonthApprListStore: OvertimeMonthApprListStore;
}

const RenderText = styled.div`
  height: 50px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  padding: 5px;
  background-color: #ffffff;
  margin-bottom: 20px;
  width: 1024px;
  min-width: 1150px;
  max-width: 1200px;
  margin-left: 20px;
  margin-right: 20px;
`;

const RenderTextSide = styled.div`
  height: 50px;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  padding: 5px;
  background-color: #ffffff;
  margin-bottom: 20px;
  width: 210px;
  min-width: 210px;
  /* min-width: 150px; */
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

@inject('overtimeMonthApprListStore')
@observer
export default class OverTimeContentsHeaderInfoApprDetailContainer extends Component<Props> {
  openCalendar = (): void => {};

  render(): JSX.Element {
    return (
      <>
        <Wrapper>
          <RenderTextSide>
            <span>
              <div>
                <FormattedMessage id="overtime.table.column.label.avg.over.time.before.approve" />
              </div>
              <div>
                <span
                  style={{
                    fontSize: '25px'
                  }}
                >
                  {this.props.overtimeMonthApprListStore.overTimeMonthApprSum}시간
                </span>
              </div>
            </span>
          </RenderTextSide>
          {/* <RenderTextOption>
            <div> &nbsp;</div>
            <div><Icon type="right"/></div>
          </RenderTextOption> */}
          <RenderText>
            <span>
              <FormattedMessage id="overtime.table.column.label.target.name" /> :{' '}
            </span>
            &nbsp;
            <span
              style={{
                fontSize: '25px'
              }}
            >
              {this.props.overtimeMonthApprListStore.detailEmpNm}
            </span>{' '}
            &nbsp;&nbsp;&nbsp;
            <span>
              <FormattedMessage id="overtime.table.column.label.work.type" /> :{' '}
            </span>
            &nbsp;
            <span
              style={{
                fontSize: '25px'
              }}
            >
              {this.props.overtimeMonthApprListStore.workTypeName}
            </span>{' '}
            &nbsp;&nbsp;&nbsp;
          </RenderText>
          {/* <RenderTextOption>
            <div> &nbsp;</div>
            <div><Icon type="right"/></div>
          </RenderTextOption> */}
          <RenderTextSide>
            <span>
              <div>
                <FormattedMessage id="overtime.table.column.label.avg.over.time.after.approve" />
              </div>
              <div>
                <span
                  style={{
                    fontSize: '25px'
                  }}
                >
                  {this.props.overtimeMonthApprListStore.overTimeMonthAllSum}
                  <FormattedMessage id="overtime.table.column.label.time" />
                </span>
              </div>
            </span>
          </RenderTextSide>
        </Wrapper>
      </>
    );
  }
}
