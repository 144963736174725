import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import OvertimeMonthStateStore from '../stores/OvertimeMonthStateStore';
import OverTimeMonthModel from '../static/OverTimeMonthModel';
import OverTimeWeeklySumModel from '../static/OverTimeWeeklySumModel';
import ProjectModel from 'shared/models/project/ProjectModel';
import OverTimeCellStateDetailComponent from './OverTimeCellStateDetailComponent';
import OverTimeSumCellStateDetailComponent from './OverTimeSumCellStateDetailComponent';

interface Props {
  overtime?: OverTimeMonthModel[];
  overTimeMonthList?: OverTimeMonthModel[];
  overtimeMonth?: OverTimeMonthModel[];
  monthlyTotalHour?: number;
  overtimeMonthStateStore?: OvertimeMonthStateStore;
}

const CalendarRow = styled.div`
  display: table-row;
`;

@inject('overtimeMonthStateStore')
@observer
export default class OverTimeRowStateDetailComponent extends Component<Props> {
  renderCell = (overtimeMonth: OverTimeMonthModel[], overTimeMonthList: OverTimeMonthModel[]): JSX.Element[] => {
    if (overtimeMonth.length <= 7) {
      const weekNumbers = ['SAT', 'SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI'];
      const cellList: JSX.Element[] = [];
      const calList: OverTimeMonthModel[] | undefined = overtimeMonth;
      let weekly = '1';

      // sum list를 구하기 위한 변수
      let overCorpCode = '';
      let overEmpNo = '';
      const overWeekly: string = calList[0].workSysMonthWeekly;
      let overTimeWeekAllOtSum = 0;
      let overTimeWeekApprOtSum = 0;
      let overTimeWeekAllAbSum = 0;
      let overTimeWeekApprAbSum = 0;
      let overTimeWeekAllSum = 0;
      let overTimeWeekApprSum = 0;

      // 승인 전후 시간 계산
      weekNumbers.forEach(function weekNumbersF(item, index, array) {
        const calItem = overtimeMonth.filter(calitem => calitem.weekEngName === item);

        if (calItem.length > 0) {
          overCorpCode = calItem[0].corpCode;
          overEmpNo = calItem[0].empNo;
          if (calItem[0].apprSts === 'S' && calItem[0].reducYn === null && calItem[0].leaveYn == null) {
            if (calItem[0].overTimeType === 'OT' && calItem[0].reducYn === null && calItem[0].leaveYn == null) {
              overTimeWeekApprOtSum += calItem[0].overtimeWorkHours;
              overTimeWeekApprSum += calItem[0].overtimeWorkHours;
            } else if (calItem[0].overTimeType === 'AB' && calItem[0].reducYn === null && calItem[0].leaveYn == null) {
              overTimeWeekApprAbSum -= calItem[0].overtimeWorkHours;
              overTimeWeekApprSum -= calItem[0].overtimeWorkHours;
            }
          }

          if ((calItem[0].apprSts === 'A' || calItem[0].apprSts === 'S') && calItem[0].reducYn == null && calItem[0].leaveYn == null) {
            if (calItem[0].overTimeType === 'OT' && calItem[0].reducYn == null && calItem[0].leaveYn == null) {
              overTimeWeekAllOtSum += calItem[0].overtimeWorkHours;
              overTimeWeekAllSum += calItem[0].overtimeWorkHours;
            } else if (calItem[0].overTimeType === 'AB' && calItem[0].reducYn == null && calItem[0].leaveYn == null) {
              overTimeWeekAllAbSum -= calItem[0].overtimeWorkHours;
              overTimeWeekAllSum -= calItem[0].overtimeWorkHours;
            }
          }
        }
      });

      const sumItem: OverTimeWeeklySumModel = {
        rowStatus: '',
        corpCode: overCorpCode,
        empNo: overEmpNo,
        overTimeWeekAllOtSum,
        overTimeWeekApprOtSum,
        overTimeWeekAllAbSum,
        overTimeWeekApprAbSum,
        overTimeWeekAllSum,
        overTimeWeekApprSum,
        workSysMonthWeekly: overWeekly
      };

      if (sumItem !== undefined) {
        this.props.overtimeMonthStateStore!.getMonthlyTotalOverTimeSum(Number(this.props.overtimeMonthStateStore!.overTimeMaxWeekly), Number(overWeekly), overTimeWeekAllSum);
        let workType = 'asisOT';
        cellList.push(<OverTimeSumCellStateDetailComponent dateSum={sumItem} workType={workType} />);
        workType = 'asisAB';
        cellList.push(<OverTimeSumCellStateDetailComponent dateSum={sumItem} workType={workType} />);
        workType = 'asisSUM';
        cellList.push(<OverTimeSumCellStateDetailComponent dateSum={sumItem} workType={workType} />);
        workType = 'null';
        cellList.push(<OverTimeSumCellStateDetailComponent dateSum={sumItem} workType={workType} />);
      }

      // 주차헤더 표시
      if (calList.length > 0) {
        weekly = overtimeMonth[0].workSysMonthWeekly;

        const headerItemList: OverTimeMonthModel[] = [];
        const headerItem: OverTimeMonthModel = {
          rowStatus: '',
          corpCode: '',
          empNo: '',
          yyyymmdd: '',
          weekEngName: '',
          weekKorName: '',
          holidayYn: '',
          workSysMonthWeekly: weekly,
          workSysYearMonth: '',
          overTimeYmd: '',
          overTimeType: 'H',
          pjCode: '',
          pjName: '',
          overtimeWorkHours: 0,
          apprSts: '',
          apprStsName: '',
          leaveYn: '',
          reducYn: '',
          inputYn: '',
          closing: '',
          pjInfo: new ProjectModel(ProjectModel)
        };
        headerItemList.push(headerItem);

        cellList.push(<OverTimeCellStateDetailComponent date={headerItemList[0]} overTimeMonthList={overTimeMonthList} />);
      }

      // 달력 생성
      weekNumbers.forEach(function weekNumbersF(item, index, array) {
        const calItem = overtimeMonth.filter(calitem => calitem.weekEngName === item);
        if (calItem.length > 0) cellList.push(<OverTimeCellStateDetailComponent date={calItem[0]} overTimeMonthList={overTimeMonthList} />);
        else cellList.push(<OverTimeCellStateDetailComponent date={null} />);
      });

      if (sumItem !== undefined) {
        this.props.overtimeMonthStateStore!.getMonthlyTotalOverTimeSum(Number(this.props.overtimeMonthStateStore!.overTimeMaxWeekly), Number(overWeekly), overTimeWeekAllSum);
        let workType = 'null';
        cellList.push(<OverTimeSumCellStateDetailComponent dateSum={sumItem} workType={workType} />);
        workType = 'tobeOT';
        cellList.push(<OverTimeSumCellStateDetailComponent dateSum={sumItem} workType={workType} />);
        workType = 'tobeAB';
        cellList.push(<OverTimeSumCellStateDetailComponent dateSum={sumItem} workType={workType} />);
        workType = 'tobeSUM';
        cellList.push(<OverTimeSumCellStateDetailComponent dateSum={sumItem} workType={workType} />);
      }

      return cellList;
    }
    const cellList: JSX.Element[] = [];

    return cellList;
  };

  render(): any {
    const overtime = this.props.overtime!;
    const overTimeMonthList = this.props.overTimeMonthList!;

    return (
      <>
        <CalendarRow>{this.renderCell(overtime, overTimeMonthList)}</CalendarRow>
      </>
    );
  }
}
