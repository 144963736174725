import { observable, action } from 'mobx';
import ReducWorkModel from '../static/ReducWorkModel';
import { DepartmentModel } from 'shared/models/department/DepartmentModel';
import ReducWorkManagementService from '../services/ReducWorkManagementService';
import { RootStore } from 'shared/stores/RootStore';

export default class ReducWorkManagementStore {
  @observable
  inProgress: boolean;

  @observable
  reducWorkModel: ReducWorkModel[];

  @observable
  glbDeptCode: string | undefined;

  @observable
  glbDeptName: string | undefined;

  @observable
  selectedRecord: ReducWorkModel;

  @observable
  visibleDrawer: boolean;

  @observable
  visibleType: string;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = this.rootStore;
    this.glbDeptCode = '';
    this.glbDeptName = '';
    this.visibleDrawer = false;
    this.visibleType = '';
    this.rootStore = rootStore;
  }

  @action
  getWorkTimeListData(empNo: string, empNm: string, type: string, fromDt: string, toDt: string): any {
    const arrayRolse: ReducWorkModel[] = [];
    const { getCommonCodeName } = this.rootStore.systemLanguageStore;
    this.inProgress = true;
    return ReducWorkManagementService.getWorkTimeList(empNo, empNm, type, fromDt, toDt)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        data.map((item: any, i: any) => {
          const role = new ReducWorkModel({
            no: i + 1,
            seq: item.seq,
            corpCode: item.corpCode,
            empNo: item.empNo,
            empNm: item.empNm,
            reducWorkType: item.reducWorkType,
            reducWorkTypeNm: getCommonCodeName('0020', item.reducWorkType),
            reducWorkTermFrom: item.reducWorkTermFrom,
            reducWorkTermTo: item.reducWorkTermTo,
            reducWorkTerm: `${item.reducWorkTermFrom}~${item.reducWorkTermTo}`,
            reducWorkHours: item.reducWorkHours,
            parentReducWorkName: item.parentReducWorkName,
            parentReducWorkBirthday: item.parentReducWorkBirthday
          });
          arrayRolse.push(role);
        });

        this.reducWorkModel = arrayRolse;
        this.inProgress = false;
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  createWorkTimeData(reducWorkModel: ReducWorkModel) {
    this.inProgress = true;
    return ReducWorkManagementService.createWorkTimeData(reducWorkModel)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  @action
  updateWorkTimeData(reducWorkModel: ReducWorkModel) {
    this.inProgress = true;
    return ReducWorkManagementService.updateWorkTimeData(reducWorkModel)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  @action
  deleteWorkTimeData(empNo: string, seq: number) {
    this.inProgress = true;

    return ReducWorkManagementService.deleteWorkTimeData(empNo, seq)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  @action
  setSelectedRecord(record: ReducWorkModel) {
    this.selectedRecord = record;
  }

  @action
  setVisibleDrawer(visible: boolean): void {
    this.visibleDrawer = visible;
  }

  @action
  setVisibleType(type: string): void {
    this.visibleType = type;
  }

  @action
  setGlbDeptInfo(obj: DepartmentModel): void {
    this.glbDeptCode = obj.deptCode;
    this.glbDeptName = obj.deptName;
  }
}
