/* eslint-disable @typescript-eslint/require-await */
import { observable, action } from 'mobx';

import { message } from 'antd';
import HolidayListService from '../services/HolidayListService';
import HolidayListModel from '../static/HolidayListModel';
import { RootStore } from 'shared/stores/RootStore';

export default class HolidayListStore {
  @observable
  inProgress: boolean;

  @observable
  gridData: [];

  @observable
  yyyyList: [];

  @observable
  visible: boolean;

  @observable
  holidayName: string;

  @observable
  yyyymmdd: string;

  @observable
  yyyy: string;

  @observable
  rowStatus: string;

  // @observable
  // drawerRecord: WorkTypeListModel;

  // @observable
  // visible: boolean;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.gridData = [];
    this.yyyyList = [];
    this.visible = false;
    this.holidayName = '';
    this.yyyymmdd = '';
    this.yyyy = '';
    this.rootStore = rootStore;
  }

  @action
  setVisible(visible: boolean): void {
    this.visible = visible;
  }

  @action
  setHolidayName(holidayName: string): void {
    this.holidayName = holidayName;
  }

  @action
  setYyyymmdd(yyyymmdd: string): void {
    this.yyyymmdd = yyyymmdd;
  }

  @action
  setYyyy(yyyy: string): void {
    this.yyyy = yyyy;
  }

  @action
  setRowStatus(rowStatus: string): void {
    this.rowStatus = rowStatus;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  @action
  async getYyyyList() {
    this.inProgress = true;

    HolidayListService.getYyyyList()
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.yyyyList = data;
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  @action
  async getGridData(yyyy: string) {
    this.inProgress = true;

    HolidayListService.getHolidayList(yyyy)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];

        // reduce No. column + data type
        data.map((v: any, i: any) => {
          v.no = i + 1;
          return null;
        });

        this.gridData = data;
        // this.visible = false;
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  createHoliday(holiday: any): any {
    // 연장근로 등록
    this.inProgress = true;

    const holidaySave: HolidayListModel = {
      rowStatus: '',
      corpCode: '',
      yyyymmdd: holiday.yyyymmdd.toString(),
      yyyy: holiday.yyyy.toString(),
      mm: holiday.mm.toString(),
      dd: holiday.dd.toString(),
      holidayName: holiday.holidayName.toString()
    };

    return HolidayListService.createHoliday(holidaySave)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        // const data = response.data.data || [];
        this.getGridData(this.yyyy);
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
        message.error('저장 중 문제가 발생하였습니다.');
      });
  }

  @action
  updateHoliday(holiday: any): any {
    // 연장근로 등록
    this.inProgress = true;

    const holidaySave: HolidayListModel = {
      rowStatus: '',
      corpCode: '',
      yyyymmdd: holiday.yyyymmdd.toString(),
      yyyy: holiday.yyyy.toString(),
      mm: holiday.mm.toString(),
      dd: holiday.dd.toString(),
      holidayName: holiday.holidayName.toString()
    };

    return HolidayListService.updateHoliday(holidaySave)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        // const data = response.data.data || [];
        this.getGridData(this.yyyy);
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
        message.error('저장 중 문제가 발생하였습니다.');
      });
  }

  @action
  deleteHoliday(yyyymmdd: string): any {
    // 연장근로 등록
    this.inProgress = true;

    return HolidayListService.deleteHoliday(yyyymmdd)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        // const data = response.data.data || [];
        this.getGridData(this.yyyy);
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
        message.error('저장 중 문제가 발생하였습니다.');
      });
  }
}
