import ApiHelper from 'shared/utils/ApiHelper';
import HrAssignReqModel from '../static/HrAssignReqModel';

const API_BASE = '/timesheet';

class HrAssignReqService {
  // Select Projects
  getUtilPlans(pjSearch: String, pjType: String) {
    const api = API_BASE + '/projects/utilization-plan' + '?pjSearch=' + pjSearch + '&pjStatus=' + pjType;
    return ApiHelper.get(api);
  }

  // Select Project Group By Member List
  getUtilPlanMemeberList(pjCode: String, corpCode: String) {
    const api = API_BASE + '/corps/' + corpCode + '/projects/' + pjCode + '/utilization-plans/member';
    return ApiHelper.get(api);
  }
  // Select Project Memeber
  getUtilPlanPersonalSchedule(empNo: String, fromDate: String, toDate: String) {
    const api = API_BASE + '/emps/' + empNo + '/utilization-plans/personal' + '?from=' + fromDate + '&to=' + toDate;
    return ApiHelper.get(api);
  }

  // 개인별 프로젝트별 상세 내역 조회
  getUtilPlanDetailPersonalSchedule(empNo: String, pjCode: String, fromDate: String, toDate: String) {
    const api = API_BASE + '/emps/' + empNo + '/projects/' + pjCode + '/utilization-plans' + '?from=' + fromDate + '&to=' + toDate;
    return ApiHelper.get(api);
  }

  // Select Project Member Schedule
  getUtilPlanMemberSchedule(pjCode: String, corpCode: String) {
    const api = API_BASE + '/corps/' + corpCode + '/projects/' + pjCode + '/utilization-plans';
    return ApiHelper.get(api);
  }

  // 월별, 개인별 프로젝트 스케쥴 조회
  getUtilPlanProjectSchedule(empNo: String, yearMonth: String) {
    const api = API_BASE + '/emps/' + empNo + '/utilization-plans' + yearMonth;
    return ApiHelper.get(api);
  }

  //개인별 프로젝트 스케쥴 상세 조회
  getUtilPlanProjectScheduleByEmpNo(empNo: String, from: String, to: String, pjCode: String) {
    const api = `${API_BASE}/emps/${empNo}/utilization-plans?from=${from}&to=${to}&pjCode=${pjCode}`;
    return ApiHelper.get(api);
  }

  // 직원별 기간내 투입 가능 비율 조회
  getUtilPlanPossibleInjctRateByPeriod(empNo: String, empNm: String, fromDt: String, toDt: String, deptCode: String) {
    const api = `${API_BASE}/utilization-plans/injctRate/${fromDt}/${toDt}?empNo=${empNo}&empName=${empNm}&deptCode=${deptCode}`;
    return ApiHelper.get(api);
  }

  // MH 등록
  createMemberUtilPlan(model: HrAssignReqModel) {
    const api = API_BASE + '/utilization-plans/auto';
    return ApiHelper.post(api, model);
  }

  // MH 등록 (List)
  createMembersUtilPlans(pjCode: string, model: HrAssignReqModel[]) {
    const api = `${API_BASE}/projects/${pjCode}/utilization-plans/`;
    return ApiHelper.post(api, model);
  }

  // TimeLine Resize Period 변경
  createUpdateUtilPlansPeriod(empNo: string, pjCode: string, model: HrAssignReqModel) {
    const api = `${API_BASE}/emps/${empNo}/projects/${pjCode}/utilization-plans`;
    return ApiHelper.post(api, model);
  }

  // 승인요청/ 배정요청
  updateApproveStauts(model: HrAssignReqModel) {
    const api = API_BASE + '/projects/' + model.pjCode + '/utilization-plans/';
    return ApiHelper.put(api, model);
  }

  getMonthOverTime(workSysYearMonth: string) {
    const api = API_BASE + '/emps/' + 'me' + '/calendar/' + workSysYearMonth + '/overtimes/monthly-list';
    return ApiHelper.get(api);
  }

  getWeekNumber(date: string) {
    const api = API_BASE + '/calendars/' + date;
    return ApiHelper.get(api);
  }

  removeUtilPlanMember(empNo: String, pjCode: String) {
    const api = `${API_BASE}/emps/${empNo}/projects/${pjCode}/utilization-plans`;
    return ApiHelper.delete(api, []);
  }

  // 선택 된 인력 제외 (List) (반려/작성) 삭제
  removeMembersUtilPlans(pjCode: String, record: HrAssignReqModel[]) {
    const api = `${API_BASE}/projects/${pjCode}/utilization-plans/remove`;
    return ApiHelper.delete(api, record);
  }
  // 프로젝트, 개인, 기간 별 내용 삭제
  removeUtilPlanMemberScheduleByPeriod(empNo: string, pjCode: string, fromDate: string, toDate: string) {
    const api = API_BASE + '/emps/' + empNo + '/projects/' + pjCode + '/utilization-plans/' + fromDate + '/' + toDate;
    return ApiHelper.delete(api, []);
  }
  // 개인, 일정 별 내용 수정
  updateUtilPlanContentByEmpNoAndPeriod(model: HrAssignReqModel) {
    let empNo = model.empNo;
    const api = API_BASE + '/emps/' + empNo + '/utilization-plans/';
    return ApiHelper.put(api, model);
  }

  // 개인별 프로젝트별 상세 내역 조회 (Grid)
  getUtilPlanMemberGrid(pjCode: String, deptCode: String, year: string) {
    const api = `${API_BASE}/utilization-plans/member?year=${year}&deptCode=${deptCode}&pjCode=${pjCode}`;
    return ApiHelper.get(api);
  }

  // 년도별 Calenday data 조회
  getUtilPlanCalendarData(empNo: String, pjCode: String, year: String) {
    const api = `${API_BASE}/utilization-plans/calendar?empNo=${empNo}&pjCode=${pjCode}&year=${year}`;
    return ApiHelper.get(api);
  }

  // 조건별 내용 삭제
  removeUtilPlanListByCriteria(model: HrAssignReqModel) {
    const api = `${API_BASE}/utilization-plans/`;
    return ApiHelper.delete(api, model);
  }
}
export default new HrAssignReqService();
