import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import { Waypoint } from 'react-waypoint';
import { Element } from 'react-scroll';
import { SwipeAction, Modal, Toast } from 'antd-mobile';
import { Icon } from 'antd';
import OverTimeMonthModel from '../static/OverTimeMonthModel';
import OvertimeCalendarMobileStore from '../stores/OvertimeCalendarMobileStore';
import moment from 'moment';
import MobileLanguageHelper from 'shared/utils/MobileLanguageHelper';
import StorageHelper from 'shared/utils/StorageHelper';
import AppConst from 'shared/utils/AppConst';

interface Props {
  overtimeCalendarMobileStore?: OvertimeCalendarMobileStore;
  overtimeItem: OverTimeMonthModel;
  waypointEnter?: (a: any, b: any) => void;
  onChangeHeaderVisible: (a: boolean) => void;
  history?: any;
}

const { alert } = Modal;

const CardWrapper = styled.div`
  background-color: #f3f3f4;
  border-bottom: 1px solid #e3e3e3;
  ::after {
    content: '';
    display: table;
    clear: both;
  }
  display: flex;
  justify-content: flex-start;
`;

const LeftDiv = styled.div`
  /* float: left; */
  width: 80px;
  padding: 10px 20px;
  text-align: right;
`;

const Date = styled.div`
  width: 30px;
  display: inline-block;
`;

const DateRender = styled.div<{ weekend?: any }>`
  color: ${props => (props.weekend === 'Y' ? '#e50505' : '#000000')};
  font-size: 20px;
`;

const YearMonthRender = styled.div`
  font-size: 12px;
  white-space: nowrap;
`;

const RightDiv = styled.div`
  /* float: left; */
  padding: 10px;
  flex: 1;
`;

const WeekName = styled.div`
  font-size: 12px;
`;

const OvertimeCard = styled.div<{ bgColor?: string }>`
  color: #a7a8ac;
  /* margin-bottom: 5px; */
  padding: 10px;
  height: 84px;
  background-color: ${props => props.bgColor};
  position: relative;
`;

const OvertimeCardAppr = styled.div<{ sts?: string | null; type?: string }>`
  color: ${props => (props.sts === 'S' ? '#ffffff' : props.type === 'OT' ? '#7b7cb4' : '#77a16f')};
  /* margin-bottom: 5px; */
  padding: 10px;
  height: 84px;
  /* background-color: #ffffff; */
  background-color: ${props => (props.sts === 'S' ? (props.type === 'OT' ? '#7b7cb4' : '#77a16f') : '#ffffff')};
  position: relative;
`;

const OvertimeCardReg = styled.div<{ sts?: string | null; type?: string }>`
  color: ${props => (props.sts === '1' ? '#8C8C8C' : props.sts === 'R' ? '#ffffff' : '#8C8C8C')};
  /* margin-bottom: 5px; */
  padding: 10px;
  height: 84px;
  /* background-color: #ffffff; */
  background-color: ${props => (props.sts === '1' ? '#ffffff' : props.sts === 'R' ? '#bcbdc1' : '#ffffff')};
  position: relative;
`;

const OvertimeCardHeader = styled.div`
  font-size: 14px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
`;

const PjCodeRender = styled.div`
  font-size: 14px;
  margin-top: 4px;
`;

const PjNameEllipsis = styled.div`
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 1.4em;
  height: 1.4em;
  width: 70%;
`;

const OvertimeEmptyCard = styled.div`
  border: 1px dashed #d5d5d5;
  text-align: center;
  color: #bdbdbd;
  font-size: 12px;
  height: 50px;
  line-height: 50px;
`;

const OvertimeEmptyCardForReg = styled.div`
  border: 1px dashed #d5d5d5;
  text-align: center;
  color: #8b8b8b;
  font-size: 12px;
  height: 50px;
  line-height: 50px;
`;

const ApprStsRender = styled.div<{ sts?: string | null }>`
  font-size: 12px;
  position: absolute;
  right: 20px;
  bottom: 10px;
  /* width: 70px; */
  text-align: right;
`;

const RenderHours = styled.div<{ type?: string; sts?: string | null }>`
  position: absolute;
  right: 20px;
  top: 5px;
  font-weight: 700;
  font-size: 34px;
  color: ${props => (props.sts === 'S' ? '#ffffff' : props.sts === 'R' ? '#ffffff' : props.type === 'OT' ? '#7a7bb3' : props.type === 'AB' ? '#77a16f' : '#a7a8ac')};
`;

const RenderType = styled.div<{ type?: string | null }>`
  color: ${props => (props.type === 'OT' ? '#7a7bb3' : props.type === 'AB' ? '#77a16f' : '#ffffff')};
`;

const InfoArrow = styled.div<{ visible?: boolean }>`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 8px;
  font-weight: 700;
  font-size: 14px;
  color: rgb(0, 0, 0, 0.5);
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

@inject('overtimeCalendarMobileStore')
@observer
export default class CalendarMobileVerticalCardComponent extends Component<Props> {
  state = {
    infoArrowVisible: false,
    modal: false,
    modalContents: ''
  };

  onModalOpen = (response: any) => {
    this.setState({
      modal: true,
      modalContents: response
    });
  };

  onModalClose = () => {
    this.setState({
      modal: false
    });
  };

  removeOvertime = (overtimeItem: OverTimeMonthModel) => {
    this.props.overtimeCalendarMobileStore!.deleteOverTime(overtimeItem.empNo, overtimeItem.overTimeYmd, overtimeItem.overTimeType).then(() => {
      Toast.success(MobileLanguageHelper.getMessage('overtime.toast.message.delete'), 2);
    });
  };

  apprOvertime = (overtimeItem: OverTimeMonthModel) => {
    // this.props
    //   .overtimeCalendarMobileStore!.updateOverTime(overtimeItem)
    //   .then((response: any) => {
    this.props
      .overtimeCalendarMobileStore!.apprOvertimeValidation(overtimeItem)
      .then((response: any) => {
        this.setState({ validationMessage: response });
        // Toast.fail(response, 2);
        if (response.length < 1) {
          Toast.success(MobileLanguageHelper.getMessage('overtime.toast.message.approvalreq'), 2);
        } else {
          this.onModalOpen(response);
        }
      })
      .catch((error: any) => {
        console.error(`Errors: ${error}`);
      });
    // })
    // .catch((error: any) => {
    //   console.error(`Errors: ${error}`);
    // });
  };

  modifyOvertime = (overtimeItem: OverTimeMonthModel) => {
    this.props.overtimeCalendarMobileStore!.setSelectedOvertimeItem(overtimeItem);
    this.props.overtimeCalendarMobileStore!.setSelectedWaypoint(moment(overtimeItem.overTimeYmd, 'YYYYMMDD').format('MMDD'));
    this.props.history.push('/m/timesheet/overtime/overtimereg');
  };

  onClickEmptyCard = (overtimeItem: OverTimeMonthModel) => {
    this.props.overtimeCalendarMobileStore!.setSelectedOvertimeItem(overtimeItem);
    this.props.overtimeCalendarMobileStore!.setSelectedWaypoint(moment(overtimeItem.overTimeYmd, 'YYYYMMDD').format('MMDD'));
    this.props.history.push('/m/timesheet/overtime/overtimereg');
  };

  onClickRegCard = () => {
    this.setState({
      infoArrowVisible: true
    });

    setTimeout(() => {
      this.setState({
        infoArrowVisible: false
      });
    }, 1000);
  };

  calendarCreateInput = (overtimeItem: OverTimeMonthModel): JSX.Element => {
    return (
      <SwipeAction
        style={{ backgroundColor: 'gray', marginBottom: '5px' }}
        autoClose
        left={[
          {
            text: MobileLanguageHelper.getMessage('overtime.button.title.delete'),
            onPress: () =>
              alert(MobileLanguageHelper.getMessage('overtime.button.title.delete'), MobileLanguageHelper.getMessage('overtime.confirm.message.delete'), [
                { text: MobileLanguageHelper.getMessage('overtime.button.title.cancel') },
                { text: MobileLanguageHelper.getMessage('overtime.button.title.delete'), onPress: () => this.removeOvertime(overtimeItem) }
              ]),
            style: { backgroundColor: '#F4333C', color: 'white', width: '70px' }
          }
        ]}
        right={[
          {
            text: MobileLanguageHelper.getMessage('overtime.button.title.approvalreq'),
            onPress: () =>
              alert(MobileLanguageHelper.getMessage('overtime.button.title.approvalreq'), MobileLanguageHelper.getMessage('overtime.confirm.message.approvalreq'), [
                { text: MobileLanguageHelper.getMessage('overtime.button.title.cancel') },
                { text: MobileLanguageHelper.getMessage('overtime.button.title.approvalreq'), onPress: () => this.apprOvertime(overtimeItem) }
              ]),
            style: { backgroundColor: '#108ee9', color: 'white', width: '70px' }
          },
          {
            text: MobileLanguageHelper.getMessage('overtime.button.title.modify'),
            onPress: () => this.modifyOvertime(overtimeItem),
            style: { backgroundColor: '#ffffff', color: '#108ee9', width: '70px', border: '1px solid #108ee9' }
          }
        ]}
      >
        <OvertimeCardReg sts={overtimeItem.apprSts} type={overtimeItem.overTimeType} onClick={this.onClickRegCard}>
          <OvertimeCardHeader>
            <div>
              {overtimeItem.overTimeType === 'OT'
                ? MobileLanguageHelper.getMessage('overtime.button.title.overtime')
                : overtimeItem.overTimeType === 'AB'
                ? MobileLanguageHelper.getMessage('overtime.button.title.alterbreak')
                : ''}
            </div>
          </OvertimeCardHeader>
          <PjCodeRender>{overtimeItem.pjCode}</PjCodeRender>
          <PjNameEllipsis>{overtimeItem.pjName}</PjNameEllipsis>

          <RenderHours sts={overtimeItem.apprSts}>{overtimeItem.overtimeWorkHours}</RenderHours>
          <ApprStsRender sts={overtimeItem.apprSts}>
            {overtimeItem.apprSts === '1'
              ? MobileLanguageHelper.getMessage('overtime.card.status.writing')
              : overtimeItem.apprSts === 'R'
              ? MobileLanguageHelper.getMessage('overtime.card.status.reject')
              : overtimeItem.apprSts === 'A'
              ? MobileLanguageHelper.getMessage('overtime.card.status.apprreq')
              : overtimeItem.apprSts === 'S'
              ? MobileLanguageHelper.getMessage('overtime.card.status.apprcomp')
              : ''}
          </ApprStsRender>
          <InfoArrow visible={this.state.infoArrowVisible}>
            <span style={{ color: 'rgb(0,0,255,0.5)' }}>
              <Icon type="double-left" />
              <Icon type="double-left" />
              <Icon type="double-left" />
            </span>
            {'  '}
            swipe{'  '}
            <span style={{ color: 'rgb(255,0,0,0.5)' }}>
              <Icon type="double-right" />
              <Icon type="double-right" />
              <Icon type="double-right" />
            </span>
          </InfoArrow>
        </OvertimeCardReg>
      </SwipeAction>
    );
  };

  calendarCreateClosing = (overtimeItem: OverTimeMonthModel): JSX.Element => {
    return (
      <OvertimeCard bgColor="#f8f8f8">
        <OvertimeCardHeader>
          <RenderType type={overtimeItem.overTimeType}>
            {overtimeItem.overTimeType === 'OT'
              ? MobileLanguageHelper.getMessage('overtime.button.title.overtime')
              : overtimeItem.overTimeType === 'AB'
              ? MobileLanguageHelper.getMessage('overtime.button.title.alterbreak')
              : ''}
          </RenderType>
        </OvertimeCardHeader>
        <PjCodeRender>{overtimeItem.pjCode}</PjCodeRender>
        <PjNameEllipsis>{overtimeItem.pjName}</PjNameEllipsis>

        <RenderHours>{overtimeItem.overtimeWorkHours}</RenderHours>
        <ApprStsRender sts={overtimeItem.apprSts}>
          {overtimeItem.apprSts === '1'
            ? MobileLanguageHelper.getMessage('overtime.card.status.writing.closed')
            : overtimeItem.apprSts === 'R'
            ? MobileLanguageHelper.getMessage('overtime.card.status.reject.closed')
            : overtimeItem.apprSts === 'A'
            ? MobileLanguageHelper.getMessage('overtime.card.status.apprreq.closed')
            : overtimeItem.apprSts === 'S'
            ? MobileLanguageHelper.getMessage('overtime.card.status.apprcomp.closed')
            : ''}
        </ApprStsRender>
      </OvertimeCard>
    );
  };

  calendarCreateStop = (overtimeItem: OverTimeMonthModel): JSX.Element => {
    return (
      <OvertimeCardAppr sts={overtimeItem.apprSts} type={overtimeItem.overTimeType}>
        <OvertimeCardHeader>
          <div>
            {overtimeItem.overTimeType === 'OT'
              ? MobileLanguageHelper.getMessage('overtime.button.title.overtime')
              : overtimeItem.overTimeType === 'AB'
              ? MobileLanguageHelper.getMessage('overtime.button.title.alterbreak')
              : ''}
          </div>
        </OvertimeCardHeader>
        <PjCodeRender>{overtimeItem.pjCode}</PjCodeRender>
        <PjNameEllipsis>{overtimeItem.pjName}</PjNameEllipsis>

        <RenderHours type={overtimeItem.overTimeType} sts={overtimeItem.apprSts}>
          {overtimeItem.overtimeWorkHours}
        </RenderHours>
        <ApprStsRender sts={overtimeItem.apprSts}>
          {overtimeItem.apprSts === 'A'
            ? MobileLanguageHelper.getMessage('overtime.card.status.apprreq')
            : overtimeItem.apprSts === 'S'
            ? MobileLanguageHelper.getMessage('overtime.card.status.apprcomp')
            : ''}
        </ApprStsRender>
      </OvertimeCardAppr>
    );
  };

  renderUtilizationResult = (overtimeItem: OverTimeMonthModel): JSX.Element => {
    if (
      this.props.overtimeCalendarMobileStore!.workType === 'C' ||
      this.props.overtimeCalendarMobileStore!.workType === 'D' ||
      this.props.overtimeCalendarMobileStore!.workType === 'X'
    ) {
      return <div />;
    }
    if (overtimeItem.reducYn !== null) {
      return (
        <>
          <OvertimeEmptyCard>{MobileLanguageHelper.getMessage('overtime.card.title.shortwork')}</OvertimeEmptyCard>
        </>
      );
    }
    if (overtimeItem.leaveYn !== null) {
      return (
        <>
          <OvertimeEmptyCard>{MobileLanguageHelper.getMessage('overtime.card.title.leave')}</OvertimeEmptyCard>
        </>
      );
    }
    if ((overtimeItem.overTimeType === null || overtimeItem.overTimeType === '') && (overtimeItem.closing === 'Y' || overtimeItem.inputYn === 'N')) {
      return (
        <>
          <OvertimeEmptyCard>{MobileLanguageHelper.getMessage('overtime.card.title.closed')}</OvertimeEmptyCard>
        </>
      );
    }
    if ((overtimeItem.overTimeType === null || overtimeItem.overTimeType === '') && overtimeItem.closing === 'N' && overtimeItem.inputYn === 'Y') {
      return (
        <>
          <OvertimeEmptyCardForReg onClick={() => this.onClickEmptyCard(overtimeItem)}>{MobileLanguageHelper.getMessage('overtime.card.title.clickhere')}</OvertimeEmptyCardForReg>
        </>
      );
    }
    if ((overtimeItem.apprSts === '1' || overtimeItem.apprSts === 'R') && overtimeItem.closing === 'N' && overtimeItem.inputYn === 'Y') {
      return this.calendarCreateInput(overtimeItem);
    }

    if (overtimeItem.closing === 'Y' || overtimeItem.inputYn === 'N') {
      return this.calendarCreateClosing(overtimeItem);
    } else {
      return this.calendarCreateStop(overtimeItem);
    }
  };

  render() {
    const { overtimeItem, waypointEnter, onChangeHeaderVisible } = this.props;
    const currLanguage = StorageHelper.getItem(StorageHelper.KEY_CURR_LANGUAGE) === AppConst.LANG_ENUS ? AppConst.LANG_EN.toUpperCase() : AppConst.LANG_KO.toUpperCase();
    return (
      <>
        <Element name={`v${moment(overtimeItem.yyyymmdd, 'YYYYMMDD').format('MMDD')}`} className="element">
          {/* <Element name={overtimeItem.yyyymmdd} className="element"> */}
          <Waypoint
            onEnter={({ previousPosition, currentPosition, event }) => {
              waypointEnter!(moment(overtimeItem.yyyymmdd, 'YYYYMMDD').format('MMDD'), overtimeItem.workSysMonthWeekly);
              let visible = true;
              if (previousPosition === 'above') visible = true;
              if (previousPosition === 'below') visible = false;

              onChangeHeaderVisible(visible);
            }}
            topOffset="200px"
            bottomOffset="80%"
          >
            <CardWrapper id={'v' + moment(overtimeItem.yyyymmdd, 'YYYYMMDD').format('MMDD')}>
              <LeftDiv>
                <Date>
                  <YearMonthRender>
                    {moment(overtimeItem.yyyymmdd, 'YYYYMMDD').format('DD') === '01' ? moment(overtimeItem.yyyymmdd, 'YYYYMMDD').format('YYYY-MM') : ''}
                  </YearMonthRender>
                  <DateRender weekend={overtimeItem.holidayYn}>{moment(overtimeItem.yyyymmdd, 'YYYYMMDD').format('DD')}</DateRender>
                </Date>
                <WeekName>{currLanguage === AppConst.LANG_EN.toUpperCase() ? overtimeItem.weekEngName : overtimeItem.weekKorName}</WeekName>
              </LeftDiv>
              <RightDiv>{this.renderUtilizationResult(overtimeItem)}</RightDiv>
            </CardWrapper>
          </Waypoint>
        </Element>
        <Modal
          visible={this.state.modal}
          transparent
          maskClosable={false}
          onClose={() => this.onModalClose()}
          // title="Title"
          footer={[{ text: '확인', onPress: () => this.onModalClose() }]}
          // wrapProps={{ onTouchStart: this.onWrapTouchStart }}
        >
          <div>{this.state.modalContents}</div>
        </Modal>
      </>
    );
  }
}
