import { observable, action, computed, toJS } from 'mobx';
import ProjectModel from 'shared/models/project/ProjectModel';
import ProjectServices from '../services/ProjectService';
import { RootStore } from 'shared/stores/RootStore';
import moment from 'moment';

export class ProjectStore {
  @observable
  projects: ProjectModel[];

  @observable
  selectedProject: ProjectModel;

  @observable
  inProgress: boolean;

  @observable
  visibleDrawer: boolean;

  @observable
  openDrawerType: string;

  @observable
  titleTextId: string;

  @observable
  searchDeptCode: string | undefined;

  @observable
  searchDeptName: string | undefined;

  @observable
  createPjCode: string;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.projects = [];
    this.visibleDrawer = false;
    this.openDrawerType = 'UPDATE';
    this.titleTextId = '';
    this.rootStore = rootStore;
    this.createPjCode = '';
  }

  @action
  getProjects(dept: string, pjCode: string, pmName: string, pjCreateType: string, mainSalesDept: string): any {
    const reqObj = {
      pjSearch: pjCode,
      mainPerformDept: dept,
      pmEmpNm: pmName,
      pjCreateType: pjCreateType,
      mainSalesDept: mainSalesDept
    };

    const arrayRoles: ProjectModel[] = [];
    const { getCommonCodeName } = this.rootStore.systemLanguageStore;
    this.inProgress = true;
    return ProjectServices.getProjects(reqObj)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];

        data.map((item: any, i: number) => {
          const role = new ProjectModel({
            no: i + 1,
            corpCode: item.corpCode,
            pjCode: item.pjCode,
            mainSalesDept: item.mainSalesDept,
            mainPerformDept: item.mainPerformDept,
            mainSalesDeptNm: item.mainSalesDeptNm,
            mainPerformDeptNm: item.mainPerformDeptNm,
            pjName: item.pjName,
            pmEmpNo: item.pmEmpNo,
            pmEmpNm: item.pmEmpNm,
            pjStartDate: item.pjStartDate,
            pjEndDate: item.pjEndDate,
            pjStatus: item.pjStatus,
            pjStatusNm: getCommonCodeName('0012', item.pjStatus),
            pjType: item.pjType,
            pjTypeNm: getCommonCodeName('0013', item.pjType),
            pjCreateType: item.pjCreateType,
            pjCreateTypeNm: getCommonCodeName('0024', item.pjCreateType),
            rowStatus: 'UPDATE'
          });
          arrayRoles.push(role);
        });

        this.projects = arrayRoles;
        this.inProgress = false;
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }
  @action
  modifyProject(arr: ProjectModel[]) {
    this.inProgress = true;

    return ProjectServices.updateProject(arr)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        this.getProjects('', '', '', '', '');
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  @action
  createProject(arr: ProjectModel[]): Promise<void> {
    this.inProgress = true;
    return ProjectServices.createProject(arr)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        this.getProjects('', '', '', '', '');
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  @action
  setSelectedProject(pjItem: ProjectModel): void {
    this.selectedProject = pjItem;
  }

  @action
  setVisibleDrawer(visible: boolean): void {
    this.visibleDrawer = visible;
  }

  @action
  setOpenDrawerType(type: string): void {
    this.openDrawerType = type;
  }

  @action
  initSelectedProject(): void {
    const initModel = new ProjectModel({
      pjStartDate: moment().format('YYYYMMDD'),
      pjEndDate: moment().format('YYYYMMDD')
    });
    this.selectedProject = initModel;
  }

  @action
  setProjectDrawerTitle(type: string): void {
    if (type === 'CREATE') {
      this.titleTextId = 'label.drawer.project.create';
    } else {
      this.titleTextId = 'label.drawer.project.modify';
    }
  }

  /**
   * Department Drawer 영역
   */

  @computed
  get selectedDepartment() {
    this.searchDeptCode = this.rootStore.departmentStore.selectedDept.deptCode;
    this.searchDeptName = this.rootStore.departmentStore.selectedDept.deptName;
    return this.rootStore.departmentStore.selectedDept;
  }

  async fetchDepartmentForSearchPanel() {
    if (toJS(this.rootStore.departmentStore.departmentList.length) === 0) {
      await this.rootStore.departmentStore.searchDepartments({});
    }
  }

  @action
  reset() {
    this.rootStore.departmentStore.departmentList = [];
  }

  // 프로젝트 코드 채번
  @action
  getNewPjCode(pjCreateType: string, pjType: string) {
    this.inProgress = true;
    return ProjectServices.createProjectCode(pjCreateType, pjType).then(response => {
      this.rootStore.apiHandleStore.handleApiFail(response.data.header);
      const data = response.data.data || [];

      this.createPjCode = data.createPjCode;
      this.inProgress = false;
    });
  }
}

export default ProjectStore;
