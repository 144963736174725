import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import OverTimeWeeklySumModel from '../static/OverTimeWeeklySumModel';
import OvertimeMonthApprListStore from '../stores/OvertimeMonthApprListStore';

interface Props {
  overtimeMonthApprListStore?: OvertimeMonthApprListStore;
  dateSum?: OverTimeWeeklySumModel | null;
  workType: string | null;
}

const CalendarAsisSumCell = styled.div<{ displayRate?: number }>`
  border-bottom: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  height: 100px;
  padding: 2px 2px 0 2px;
  display: table-cell;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  vertical-align: middle;
  text-align: center;
`;

const sumCellBorderChooser = (displayRate: number | null): string => {
  if (displayRate === null) {
    return '1px solid rgb(216,218,217)';
  }
  if (displayRate < 100) {
    return '1px solid rgb(189,189,217)';
  }
  return '1px solid rgb(185,208,180)';
};

const sumCellBgColorChooser = (displayRate: number | null): string => {
  if (displayRate === null) {
    return 'rgb(216,218,217)';
  }
  if (displayRate < 100) {
    return 'rgb(189,189,217)';
  }
  return 'rgb(185,208,180)';
};

const sumCellColorChooser = (displayRate: number | null): string => {
  if (displayRate === null) {
    return 'black';
  }
  if (displayRate < 100) {
    return 'black';
  }
  return 'black';
};

const CalendarTobeSumCell = styled.div<{ displayRate?: number }>`
  /*border: 1px solid #dddddd; */
  border: ${(props: any): any => sumCellBorderChooser(props.displayRate)};
  border-left: 3px solid white;
  border-bottom: 1px solid #dddddd;
  background-color: ${(props: any): any => sumCellBgColorChooser(props.displayRate)};
  min-width: 50px;
  height: 80px;
  padding: 0;
  display: table-cell;
  font-size: 20px;
  font-weight: 700;
  vertical-align: middle;
  text-align: center;
  color: ${(props: any): any => sumCellColorChooser(props.displayRate)};
`;

@inject('overtimeMonthApprListStore')
@observer
export default class OverTimeSumCellApprDetailComponent extends Component<Props> {
  state = {
    // visible: false,
    // overTimeType: 'OT',
    // timeMin: 0,
    // timeMax: 8,
    // defaultTime: 0,
    // overtimeWorkHours: 0,
    // apptSts: '',
    // pjCode: '',
    // holidayYn: '',
    // rowStatus: '',
    // otDisabled : false,
    // abDisabled : false
  };

  // sum Cell 생성
  calendarAsisSumCreate = (overtimeSum: OverTimeWeeklySumModel, displayRate: any): JSX.Element => {
    return (
      <CalendarAsisSumCell displayRate={displayRate}>
        {overtimeSum.overTimeWeekApprSum}
        {/* ({overtimeSum.overTimeWeekAllSum}) */}
      </CalendarAsisSumCell>
    );
  };

  // sum Cell 생성
  calendarAsisOtCreate = (overtimeSum: OverTimeWeeklySumModel, displayRate: any): JSX.Element => {
    return (
      <CalendarAsisSumCell displayRate={displayRate}>
        {overtimeSum.overTimeWeekApprOtSum}
        {/* ({overtimeSum.overTimeWeekAllOtSum}) */}
      </CalendarAsisSumCell>
    );
  };

  // sum Cell 생성
  calendarAsisAbCreate = (overtimeSum: OverTimeWeeklySumModel, displayRate: any): JSX.Element => {
    return (
      <CalendarAsisSumCell displayRate={displayRate}>
        {overtimeSum.overTimeWeekApprAbSum}
        {/* ({overtimeSum.overTimeWeekAllAbSum}) */}
      </CalendarAsisSumCell>
    );
  };

  // sum Cell 생성
  calendarTobeSumCreate = (overtimeSum: OverTimeWeeklySumModel, displayRate: any): JSX.Element => {
    return (
      <CalendarTobeSumCell displayRate={displayRate}>
        {/* {overtimeSum.overTimeWeekApprSum} */}
        {overtimeSum.overTimeWeekAllSum}
      </CalendarTobeSumCell>
    );
  };

  // sum Cell 생성
  calendarTobeOtCreate = (overtimeSum: OverTimeWeeklySumModel, displayRate: any): JSX.Element => {
    return (
      <CalendarTobeSumCell displayRate={displayRate}>
        {/* {overtimeSum.overTimeWeekApprOtSum} */}
        {overtimeSum.overTimeWeekAllOtSum}
      </CalendarTobeSumCell>
    );
  };

  // sum Cell 생성
  calendarTobeAbCreate = (overtimeSum: OverTimeWeeklySumModel, displayRate: any): JSX.Element => {
    return (
      <CalendarTobeSumCell displayRate={displayRate}>
        {/* {overtimeSum.overTimeWeekApprAbSum} */}
        {overtimeSum.overTimeWeekAllAbSum}
      </CalendarTobeSumCell>
    );
  };

  // sum Cell 생성
  calendarTobenullCreate = (): JSX.Element => {
    return <div style={{ width: '20px' }} />;
  };

  renderCell = (overtimeSum: OverTimeWeeklySumModel, workType: string | null): any => {
    if (workType === 'asisOT') {
      return this.calendarAsisOtCreate(overtimeSum, 0);
    }
    if (workType === 'asisAB') {
      return this.calendarAsisAbCreate(overtimeSum, 100);
    }
    if (workType === 'asisSUM') {
      return this.calendarAsisSumCreate(overtimeSum, null);
    }
    if (workType === 'tobeOT') {
      return this.calendarTobeOtCreate(overtimeSum, 0);
    }
    if (workType === 'tobeAB') {
      return this.calendarTobeAbCreate(overtimeSum, 100);
    }
    if (workType === 'tobeSUM') {
      return this.calendarTobeSumCreate(overtimeSum, null);
    }
    return this.calendarTobenullCreate();
  };

  render(): JSX.Element {
    const dateSum = this.props.dateSum!;
    const { workType } = this.props;

    return <>{this.renderCell(dateSum, workType)}</>;
  }
}
