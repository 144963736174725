import { action } from 'mobx';
import AppHelper from '../utils/AppHelper';
import LanguageHelper from '../utils/LanguageHelper';
import { MobileRootStore } from './RootStore';

export class ApiHandleStore {
  rootStore: MobileRootStore;

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  @action
  handleApiFail(responseHeader: any) {
    if (responseHeader && !responseHeader.isSuccessful) {
      const httpCode = Number(responseHeader.messageInfo.code.split('.')[2]);
      const content = responseHeader.messageInfo.contents;
      const { title } = responseHeader.messageInfo;
      if (httpCode === 401 || httpCode === 409) {
        AppHelper.notify('error', title, content, () => this.rootStore.authStore.moveToLoginPage());
      } else if (httpCode === 403) {
        AppHelper.notify('error', title, content);
      } else {
        AppHelper.notify('error', title, content);
      }
    }
  }

  @action
  handleAllApiResult(responses: any[]) {
    responses.forEach(response => {
      this.handleApiFail(response.data.header);
    });
    let isAllApiSuccessful = true;
    for (let index = 0; index < responses.length; index++) {
      const { isSuccessful } = responses[index].data.header;
      if (!isSuccessful) {
        isAllApiSuccessful = false;
        break;
      }
    }

    if (isAllApiSuccessful) {
      AppHelper.showMessage('success', LanguageHelper.getMessage('message.submit.done'));
    }
  }

  @action
  handleApiError(error: any) {
    let msg = error.message;
    if (msg === 'Network Error') {
      msg = LanguageHelper.getMessage('message.network.error');
    }
    AppHelper.notify('error', msg, msg);
  }
}
