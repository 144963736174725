import { Button, Form, Icon, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import IconMetanet from 'components/IconMetanet';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { CorporationStore } from 'pages/corporations/store/CorporationStore';
import React from 'react';
import { Link } from 'react-router-dom';
import { AuthStore } from 'shared/stores/AuthenticationStore';

export interface ILoginProps extends FormComponentProps {
  authStore?: AuthStore;
  corporationStore?: CorporationStore;
}

@inject('authStore', 'corporationStore')
@observer
class LoginFormContainer extends React.Component<ILoginProps, any> {
  /**
   * Handle Login action
   */
  handleLoginSubmit = (e: any) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.authStore!.login(values.username, values.password);
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator }
    } = this.props;

    return (
      <div>
        <h1 style={{ color: '#FFF', textAlign: 'center', fontSize: 40, marginBottom: 12 }}>
          <Icon type="clock-circle" style={{ fontSize: 36 }} />
          <span style={{ paddingLeft: 24 }}>Timesheet</span>
        </h1>
        <Form onSubmit={this.handleLoginSubmit} style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '2em' }}>
          <Form.Item>
            {getFieldDecorator('username', {
              rules: [{ required: true, message: '로그인ID를 입력해주세요.' }]
            })(<Input size="large" prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="로그인ID" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: '비밀번호를 입력해주세요.' }]
            })(<Input size="large" prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="비밀번호" />)}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" size="large" className="login-form-button">
              로그인
            </Button>
            <Link to="/passwordfinder" style={{ color: '#FFF', float: 'left' }}>
              비밀번호 찾기
            </Link>
          </Form.Item>
          <div style={{ color: '#FFF', textAlign: 'center', fontSize: 20, padding: '.5em 0 1em', opacity: 0.3 }}>
            <IconMetanet
              style={{
                width: 24,
                height: 24
              }}
            />
            <b>Metanet</b>
          </div>
        </Form>
      </div>
    );
  }
}

const WrappedLoginFormContainer = Form.create({ name: 'normal_login' })(LoginFormContainer);
export default WrappedLoginFormContainer;
