import { message, Modal } from 'antd';
import * as FileSaver from 'file-saver';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import _ from 'lodash';
import DecodedTokenModel from '../models/auth/DecodedTokenModel';
import AppConst from './AppConst';

class AppHelper {
  /**
   * decode the access_token to user info
   * @param accessToken
   * @return {DecodedTokenModel} model: contains user info
   */
  decodeToken = (accessToken: string) => {
    const decodedResult = jwtDecode(accessToken);
    const parsedJson = JSON.parse(JSON.stringify(decodedResult));
    const model = new DecodedTokenModel();
    if (parsedJson) {
      model.setRoles(parsedJson.auth);
    }
    return model;
  };

  /**
   * Get current date with format yyyyMMdd
   * @param: bridgeChar : character between unit such as '', '/', '-'
   * @return date string value such as '2019-06-23'
   */
  getCurrDateInYyyyMmDd = (bridgeChar: string) => {
    const d = new Date();
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join(bridgeChar);
  };

  /**
   * Display a modal to display message based on modal type
   */
  notify = (modalType: string, title: string, content: string, onOkFunc?: any) => {
    const notifyType = modalType ? modalType.toLowerCase() : '';
    switch (notifyType) {
      case 'info':
        Modal.info({ title, content, zIndex: 2000 });
        break;
      case 'success':
        Modal.success({ title, content, zIndex: 2000 });
        break;
      case 'error':
        Modal.error({ title, content, onOk: onOkFunc, zIndex: 2000 });
        break;
      case 'warning':
        Modal.warning({ title, content, zIndex: 2000 });
        break;
      default:
        break;
    }
  };

  /**
   * Download Excel file from given url
   */
  downloadExcel = (url: string) => {
    FileSaver.saveAs(url, '');
  };

  // sort Desc
  getStringSortResult = (a: string, b: string, isAsc?: boolean) => {
    if (a === null || a === undefined) a = '';
    if (b === null || b === undefined) b = '';
    if (isAsc) {
      return a !== undefined && b !== undefined && a !== null && b !== null && b.toLowerCase() > a.toLowerCase() ? -1 : 1;
    }
    return a !== undefined && b !== undefined && a !== null && b !== null && a.toLowerCase() > b.toLowerCase() ? -1 : 1;
  };

  getToolButtonStyle = () => {
    return {
      marginBottom: 16,
      marginRight: 4
    };
  };

  getColumnFilters = (list: string[]) => {
    const distinct = (value: any, index: number, self: any) => {
      if (value === undefined || value === null) return false;
      return self.indexOf(value) === index;
    };
    const distinctList = list.filter(distinct);
    const filters: any[] = [];
    distinctList.map((element: string) => {
      if (element === '') {
        filters.push({
          text: '',
          value: ' '
        });
      } else {
        filters.push({
          text: element,
          value: element
        });
      }
      return filters;
    });
    return filters;
  };

  parseYNToBoolean = (value: string) => {
    const parseValue = value === 'Y';
    return parseValue;
  };

  parseYNToString = (value: boolean) => {
    const parseValue = value ? 'Y' : 'N';
    return parseValue;
  };

  /**
   * Parse timestamp from DB to Date on UI.
   * @param timestamp: Timestamp fetched from server
   */
  parseTimestampToDate = (timestamp: number, format: string) => {
    const TIMEZONEOFFSET_KOREAN = 9;
    const TIMEZONEOFFSET_DIFF = TIMEZONEOFFSET_KOREAN - this.getCurrentTimeZoneValue();
    const actuallTimestamp = timestamp + TIMEZONEOFFSET_DIFF * 60 * 60 * 1000;
    return moment.unix(actuallTimestamp / 1000).format(format);
  };

  /**
   * Get current timezone value of Client.
   * Ref: http://jsfiddle.net/agzknz9L
   */
  getCurrentTimeZoneValue = () => {
    const offset = new Date().getTimezoneOffset();
    const o = Math.abs(offset);
    const diff = offset < 0 ? 1 : -1;
    return Math.floor(o / 60) * diff;
  };

  /**
   * Get current date with format DD/MM/YYYY
   */
  getCurrentDate = () => {
    const date = new Date().toJSON().slice(0, 10);
    const nDate = `${date.slice(8, 10)}/${date.slice(5, 7)}/${date.slice(0, 4)}`;
    return nDate;
  };

  /**
   * Validate password with rules:
   *  +  Password must be lowercase
   *  +  contains at least 1 special character
   *  +  contains at least 1 number
   *  +  Password must has min = 8 characters and max = 20 characters
   * @param password
   */
  isValidPassword = (password: string) => {
    if (password === undefined || (!password && password.length === 0)) return false; // Empty password is not validated here.
    const lowerCharacterRule = new RegExp(/^(?=.*?[a-z])/); // Password must be lowercase
    const numberRule = new RegExp(/(?=.*?[0-9])/); // contains at least 1 number
    const specialCharacterRule = new RegExp(/(?=.*?[^\w\s])/); // contains at least 1 special character
    const minMaxLengthRule = new RegExp(/.{8,20}$/); // min = 8 characters and max = 20 characters
    const isHasUpperCase = password.toLowerCase() !== password; // check password contain UpperCase letter
    const regex = new RegExp(lowerCharacterRule.source + numberRule.source + specialCharacterRule.source + minMaxLengthRule.source);
    return regex.test(password) && !isHasUpperCase;
  };

  isValidPhoneNumber = (phone: string) => {
    if (!phone && phone.length === 0) return true;
    const numberRule = new RegExp(/^\d+$/); // Only allow number
    const regex = new RegExp(numberRule.source);
    const isValidLength = !!(phone && phone.length <= AppConst.VALIDATOR.maxLength.phoneNumber);
    return regex.test(phone) && isValidLength;
  };

  scrollToBottom = (value: number) => {
    // scroll table antd when add data
    setTimeout(() => {
      const objDiv = document.getElementsByClassName('ant-table-body');
      if (objDiv && objDiv[value] !== null && objDiv[value] !== undefined) {
        objDiv[value].scrollTop = objDiv[value].scrollHeight;
      }
    }, 100);
  };

  showMessage = (messageType: string, content: string) => {
    const type = messageType ? messageType.toLowerCase() : '';
    switch (type) {
      case 'info':
        message.info(content);
        break;
      case 'success':
        message.success(content);
        break;
      case 'error':
        message.error(content);
        break;
      case 'warning':
        message.warning(content);
        break;
      default:
        break;
    }
  };

  /**
   * Convert a number to string. Example: 123456 => '123,456'
   * @param value
   */
  parseToCommaStyleString = (value: number) => {
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  getBreadcrumbsByUrl = (fakeParam?: any): any[] => {
    return [];
  };

  /**
   * Return today in Timestamp format such as 253402243780000
   * Today must has format YYYY-MM-DD
   */
  getTodayInTimestampFormat = () => {
    const currDate = this.getCurrDateInYyyyMmDd('-');
    return new Date(currDate).getTime();
  };

  /**
   * Check 2 string equal ignore upper/lower case
   */
  isEqualsIgnoreCase = (string1: string, string2: string) => {
    if (_.isUndefined(string1) || _.isUndefined(string2)) return false;
    return string1.toLowerCase() === string2.toLowerCase();
  };

  /**
   * Get row total of table (not include CREATING items)
   */
  getRowTotal = (tableDatasource: any) => {
    let total = 0;
    if (tableDatasource && tableDatasource.length > 0) {
      total = tableDatasource.filter((item: any) => item.changeStatus !== AppConst.ActionType.CREATE).length;
    }
    return total;
  };

  /**
   * Parse Date to timestamp
   */
  parseDateToTimestamp = (date: string) => {
    const convertToDate = date ? moment(date).format(AppConst.DEFAULT_DATE_FORMAT) : '';
    const timestamp = date ? moment(convertToDate, AppConst.DEFAULT_DATE_FORMAT).unix() * 1000 : 0;
    return timestamp;
  };

  /**
   * Parse Start Date to timestamp.
   * @param dateValue: date value which has Date format is AppConst.DEFAULT_DATE_FORMAT and time is 00:00:00. Ex: dateValue = Sun Jun 23 2019 00:00:00 GMT+0700
   */
  parseStartDateToTimestamp = (dateValue: string) => {
    return dateValue === '' ? 0 : this.parseDateToTimestamp(dateValue);
  };

  /**
   * Parse End Date to timestamp
   * @param dateValue: date value which has Date format is AppConst.DEFAULT_DATE_FORMAT and time is 23:59:59. Ex: dateValue = Sun Jun 23 2019 00:00:00 GMT+0700
   */
  parseEndDateToTimestamp = (dateValue: string) => {
    return _.isUndefined(dateValue) || dateValue === '' ? 0 : this.parseDateToTimestamp(dateValue) + AppConst.DEFAULT_END_TIME;
  };

  /**
   * Parse Date to timestamp to match Korean date in Databasewith Start date format: YYYY-MM-DD 00:00:00 and End Date format: YYYY-MM-DD 23:59:59
   * @param; dateValue : date string such as 'Sun Jun 23 2019 00:00:00 GMT+0700'
   * @param: dateType : is one of 2 values of AppConst.DATE_TYPE
   */
  parseDateToKoreanTimestamp = (dateValue: string, dateType: number) => {
    const TIMEZONEOFFSET_KOREAN = 9;
    const TIMEZONEOFFSET_DIFF = TIMEZONEOFFSET_KOREAN - this.getCurrentTimeZoneValue();
    let timestamp = -1;
    if (dateType === AppConst.DATE_TYPE.END_DATE) {
      timestamp = this.parseEndDateToTimestamp(dateValue);
    } else if (dateType === AppConst.DATE_TYPE.START_DATE) {
      timestamp = this.parseStartDateToTimestamp(dateValue);
    }
    const actuallTimestamp = timestamp - TIMEZONEOFFSET_DIFF * 60 * 60 * 1000;
    return actuallTimestamp;
  };

  /**
   * Check if End Date is greater than/equal Start Date
   * @params: startDateMoment: Moment object , endDateMoment: Moment object
   * @return true if  End Date is greater than/equal Start Date. Otherwise, return false.
   */
  isEndDateGreaterOrEqualStartDate = (startDateMoment: any, endDateMoment: any) => {
    return endDateMoment.startOf('day').isSameOrAfter(startDateMoment.startOf('day'));
  };

  /**
   * Trim String items data in a certain object
   */
  trimStringData = (obj: any) => {
    if (_.isNull(obj) || _.isUndefined(obj)) return;
    Object.keys(obj).map((key, i) => {
      if (obj[key] && typeof obj[key] === 'string') obj[key] = obj[key].trim();
      return obj;
    });
  };

  /**
   * Check if End Date is greater than Start Date
   * @params: startDateMoment: Moment object , endDateMoment: Moment object
   * @return true if  End Date is greater than/equal Start Date. Otherwise, return false.
   */

  isEndDateGreaterThanStartDate = (startDateMoment: any, endDateMoment: any) => {
    if (_.isNull(endDateMoment) || _.isNull(startDateMoment)) return false;
    return endDateMoment.startOf('day').isAfter(startDateMoment.startOf('day'));
  };

  /** check whitespace in string */
  checkStringHasWhiteSpace = (value: string) => {
    return /\s/g.test(value);
  };

  /** Return string of Employee info with format: EMP_ID (EMP_NAME) */
  getEmployeeInfo = (empId: string, empName: string) => {
    let info = empId;
    if (!_.isNull(empName)) {
      info += ` (${  empName  })`;
    }
    return info;
  };

  /**
   * Get InitialValue of DatePicker Antd component in <Form.Item> component
   * @return timestamp value
   */
  getDatePickerInitialValue = (timestamp: number) => {
    const dateValue = timestamp || moment().unix() * 1000;
    const defaultDatePicker = moment(this.parseTimestampToDate(dateValue, AppConst.DEFAULT_DATE_FORMAT), AppConst.DEFAULT_DATE_FORMAT);
    return defaultDatePicker;
  };

  /**
   * Get InitialValue of DatePicker Antd component in <Form.Item> component
   * @return timestamp value
   */
  getEndDateDatePickerInitialValue = (timestamp: number) => {
    const dateValue = timestamp || moment().unix() * 1000;
    return dateValue;
  };

  /**
   * Remove all of space of a field at beginning, middle or ending
   */
  removeSpacesOfString = (text: string) => {
    return text ? text.replace(/\s/g, "") : text;
  };

  /**
   * Get startDate by Today in Timestamp format such as 253402243780000 and match with Korean timezone
   * @return timestamp : is similar with Korean DB time
   */
  getStartDateByTodayInKoreanTimestamp = () => {
    const currTimezone = this.getCurrentTimeZoneValue();
    const currDate = this.getCurrDateInYyyyMmDd('-');
    const TIMEZONEOFFSET_KOREAN = 9;
    let actuallTimestamp = 0;
    let yourTimeStamp = new Date(`${currDate  } 00:00:00`).getTime();
    if(_.isUndefined(yourTimeStamp) || _.isNaN(yourTimeStamp)) {
      yourTimeStamp = new Date(currDate).getTime();
      actuallTimestamp = yourTimeStamp - TIMEZONEOFFSET_KOREAN * 60 * 60 * 1000;
      return actuallTimestamp;
    }
    const TIMEZONEOFFSET_DIFF = TIMEZONEOFFSET_KOREAN - currTimezone;
    actuallTimestamp = yourTimeStamp - TIMEZONEOFFSET_DIFF * 60 * 60 * 1000;
    return actuallTimestamp;
  };

  /**
   * Get Unix Timestamp (today)
   */
  getTodayInUnixTimestamp = () => {
    return moment().unix() * 1000;
  };
}

export default new AppHelper();
