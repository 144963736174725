/* eslint-disable @typescript-eslint/require-await */
import { observable, action } from 'mobx';

import CalendarListService from '../services/CalendarListService';
import CalendarListModel from '../static/CalendarListModel';
import { RootStore } from 'shared/stores/RootStore';

export default class CalendarListStore {
  @observable
  inProgress: boolean;

  @observable
  gridData: [];

  @observable
  yyyyList: [];

  @observable
  CalendarListModel: CalendarListModel;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.gridData = [];
    this.yyyyList = [];
    this.rootStore = rootStore;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  @action
  async getYyyyList() {
    this.inProgress = true;

    CalendarListService.getYyyyList()
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.yyyyList = data;
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  async getCalenderCreate(): Promise<void> {
    this.inProgress = true;

    CalendarListService.getCalenderCreate()
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  @action
  async getGridData(yyyy: string) {
    this.inProgress = true;

    CalendarListService.getWorkTypeList(yyyy)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];

        // reduce No. column + data type
        data.map((v: any, i: any) => {
          v.no = i + 1;
          return null;
        });

        this.gridData = data;
        // this.visible = false;
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }
}
