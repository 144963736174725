import ApiHelper from 'shared/utils/ApiHelper';
import { AxiosResponse } from 'axios';

const API_BASE = '/timesheet';

class ProjectResourceService {
  getProjectResources(pjCode: string, empNo: string, planYn: string, resultYn: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/project-resource?pjCode=${pjCode}&empNo=${empNo}&planYn=${planYn}&resultYn=${resultYn}`;
    return ApiHelper.get(api);
  }
}

export default new ProjectResourceService();
