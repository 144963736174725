import { observable, action } from 'mobx';
import moment from 'moment';
import EmployeeModel from 'shared/models/employee/EmployeeModel';
import UtilizationResultModel from '../static/UtilizationResultModel';
import CloseStsModel from '../static/CloseStsModel';
import MonthlyClosingModel from '../static/MonthlyClosingModel';
import UtilizationResultClosingService from '../services/UtilizationResultClosingService';
import { RootStore } from 'shared/stores/RootStore';

interface CloseStsWrapModel {
  yearMonth: string;
  closeSts: CloseStsModel | null;
}

export default class UtilizationResultClosingStore {
  @observable
  inProgress: boolean;

  @observable
  nowYear = '0';

  @observable
  preYear = '0';

  @observable
  nextYear = '0';

  @observable
  closeStsList: CloseStsWrapModel[];

  @observable
  selectedCloseSts: CloseStsWrapModel;

  @observable
  stepsCurrent = 0;

  @observable
  closePjList: UtilizationResultModel[];

  @observable
  empPriceList: EmployeeModel[];

  @observable
  retiredEmpList: UtilizationResultModel[];

  @observable
  overMdList: UtilizationResultModel[];

  @observable
  overMdDrawerVisible = false;

  @observable
  overMdDrawerDataList: UtilizationResultModel[];

  @observable
  aggregateDataList: MonthlyClosingModel[];

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.closeStsList = [];
    this.closePjList = [];
    this.empPriceList = [];
    this.retiredEmpList = [];
    this.overMdList = [];
    this.aggregateDataList = [];
    this.overMdDrawerDataList = [];
    this.rootStore = rootStore;
  }

  @action
  getCloseSts(year: string): any {
    this.inProgress = true;

    this.closeStsList = [];
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

    months.forEach(i => {
      const newMonth: CloseStsWrapModel = {
        yearMonth: year + i,
        closeSts: null
      };
      this.closeStsList.push(newMonth);
    });
    // for (let i of months) {
    //   const newMonth: CloseStsWrapModel = {
    //     yearMonth: year + i,
    //     closeSts: null
    //   };
    //   this.closeStsList.push(newMonth);
    // }

    this.nowYear = moment(year, 'YYYY')
      .format('YYYY')
      .toString();
    this.preYear = moment(year, 'YYYY')
      .add(-1, 'years')
      .format('YYYY')
      .toString();
    this.nextYear = moment(year, 'YYYY')
      .add(1, 'years')
      .format('YYYY')
      .toString();

    UtilizationResultClosingService.getClosingSts(year)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];

        this.closeStsList.forEach(stsItem => {
          data.forEach((dataItem: any) => {
            if (stsItem.yearMonth === dataItem.yearMonth) {
              stsItem.closeSts = dataItem;
            }
          });
        });
        // for (let stsItem of this.closeStsList) {
        //   for (let dataItem of data) {
        //     if (stsItem.yearMonth === dataItem.yearMonth) {
        //       stsItem.closeSts = dataItem;
        //     }
        //   }
        // }

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  onCardClick(closeSts: CloseStsWrapModel): any {
    this.selectedCloseSts = closeSts;

    if (closeSts.closeSts!.closeSts === '01') this.stepsCurrent = 0;
    else if (closeSts.closeSts!.closeSts === '02') this.stepsCurrent = 1;
    else if (closeSts.closeSts!.closeSts === '03') this.stepsCurrent = 2;
    else if (closeSts.closeSts!.closeSts === '04') this.stepsCurrent = 3;
    else if (closeSts.closeSts!.closeSts === 'C') this.stepsCurrent = 4;
    else if (closeSts.closeSts!.closeSts === 'T') this.stepsCurrent = 4;
  }

  @action
  createCloseSts(closeSts: CloseStsWrapModel): any {
    this.inProgress = true;
    const newCloseSts: CloseStsModel = {
      rowStatus: null,
      closeSts: '01',
      yearMonth: closeSts.yearMonth,
      corpCode: ''
    };

    UtilizationResultClosingService.createClosingSts(newCloseSts)
      .then(response => {
        // const data = response.data.data || [];
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);

        this.getCloseSts(this.nowYear);

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  nextStep(): any {
    this.inProgress = true;

    const closeSts: CloseStsModel = {
      rowStatus: null,
      closeSts: this.selectedCloseSts.closeSts!.closeSts,
      corpCode: this.selectedCloseSts.closeSts!.corpCode,
      yearMonth: this.selectedCloseSts.closeSts!.yearMonth
    };

    if (this.selectedCloseSts.closeSts!.closeSts === '01') {
      closeSts.closeSts = '02';
    } else if (this.selectedCloseSts.closeSts!.closeSts === '02') {
      closeSts.closeSts = '03';
    } else if (this.selectedCloseSts.closeSts!.closeSts === '03') {
      closeSts.closeSts = '04';
    } else if (this.selectedCloseSts.closeSts!.closeSts === '04') {
      closeSts.closeSts = 'C';
    }

    UtilizationResultClosingService.updateCloseSts(closeSts)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        // const data = response.data.data || [];

        if (this.selectedCloseSts.closeSts!.closeSts === '01') {
          this.selectedCloseSts.closeSts!.closeSts = '02';
          this.stepsCurrent = 1;
        } else if (this.selectedCloseSts.closeSts!.closeSts === '02') {
          this.selectedCloseSts.closeSts!.closeSts = '03';
          this.stepsCurrent = 2;
        } else if (this.selectedCloseSts.closeSts!.closeSts === '03') {
          this.selectedCloseSts.closeSts!.closeSts = '04';
          this.stepsCurrent = 3;
        } else if (this.selectedCloseSts.closeSts!.closeSts === '04') {
          this.selectedCloseSts.closeSts!.closeSts = 'C';
          this.stepsCurrent = 4;
        }

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });

    this.inProgress = false;
  }

  @action
  stepsChange(current: number): any {
    this.inProgress = true;

    const closeSts: CloseStsModel = {
      rowStatus: null,
      closeSts: this.selectedCloseSts.closeSts!.closeSts,
      corpCode: this.selectedCloseSts.closeSts!.corpCode,
      yearMonth: this.selectedCloseSts.closeSts!.yearMonth
    };

    if (current === 0) {
      closeSts.closeSts = '01';
    } else if (current === 1) {
      closeSts.closeSts = '02';
    } else if (current === 2) {
      closeSts.closeSts = '03';
    } else if (current === 3) {
      closeSts.closeSts = '04';
    }

    UtilizationResultClosingService.updateCloseSts(closeSts)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        // const data = response.data.data || [];

        if (current === 0) {
          this.selectedCloseSts.closeSts!.closeSts = '01';
        } else if (current === 1) {
          this.selectedCloseSts.closeSts!.closeSts = '02';
        } else if (current === 2) {
          this.selectedCloseSts.closeSts!.closeSts = '03';
        } else if (current === 3) {
          this.selectedCloseSts.closeSts!.closeSts = '04';
        }
        this.stepsCurrent = current;

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });

    this.inProgress = false;
  }

  // 01
  @action
  getClosePjList(): any {
    this.inProgress = true;
    UtilizationResultClosingService.getClosePjList(this.selectedCloseSts.yearMonth)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];

        this.closePjList = data;

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  removeClosePjList(): any {
    this.inProgress = true;
    UtilizationResultClosingService.removeClosePjList(this.selectedCloseSts.yearMonth)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        // const data = response.data.data || [];

        this.getClosePjList();

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  // 02
  @action
  getEmpPriceList(): any {
    this.inProgress = true;
    UtilizationResultClosingService.getEmpPriceList(this.selectedCloseSts.yearMonth)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];

        this.empPriceList = data;

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  // 03
  @action
  getRetiredEmpList(): any {
    this.inProgress = true;
    UtilizationResultClosingService.getRetiredEmpList(this.selectedCloseSts.yearMonth)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];

        this.retiredEmpList = data;

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  removeRetiredEmpList(): any {
    this.inProgress = true;
    UtilizationResultClosingService.removeRetiredEmpList(this.selectedCloseSts.yearMonth)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        // const data = response.data.data || [];

        this.getRetiredEmpList();

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  // 04
  @action
  getOverMdList(): any {
    this.inProgress = true;
    UtilizationResultClosingService.getOverMdList(this.selectedCloseSts.yearMonth)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];

        this.overMdList = data;

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  // @action
  // removeOverMdList() {
  //   this.inProgress = true;
  //   UtilizationResultClosingService.removeOverMdList(this.selectedCloseSts.yearMonth)
  //     .then(response => {
  //       const data = response.data.data || [];

  //       this.getOverMdList();

  //       this.inProgress = false;
  //     })
  //     .catch((error: any) => {
  //       console.error(`Errors: ${error}`);
  //       this.inProgress = false;
  //     });
  // }

  @action
  changeOverMdDrawerVisible(visible: boolean): any {
    this.overMdDrawerVisible = visible;
  }

  @action
  getOverMdDrawerDataList(record: UtilizationResultModel): any {
    this.inProgress = true;
    UtilizationResultClosingService.getOverMdDrawerDataList(record.empNo, record.injctYmd)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];

        this.overMdDrawerDataList = data;

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  removeOverMd(record: UtilizationResultModel): any {
    this.inProgress = true;

    UtilizationResultClosingService.removeOverMd([record])
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        // const data = response.data.data || [];

        this.getOverMdDrawerDataList(record);
        this.getOverMdList();

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  // C
  @action
  createAggregateData(): any {
    this.inProgress = true;
    UtilizationResultClosingService.createAggregateData(this.selectedCloseSts.yearMonth)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);

        // const data = response.data.data || [];

        this.nextStep();
        this.getAggregateData();

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  getAggregateData(): any {
    this.inProgress = true;
    UtilizationResultClosingService.getAggregateData(this.selectedCloseSts.yearMonth)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];

        this.aggregateDataList = data;

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  reCreateAggregateData(): any {
    this.inProgress = true;

    UtilizationResultClosingService.removeAggregateData(this.selectedCloseSts.yearMonth)
      .then(removeResponse => {
        this.rootStore.apiHandleStore.handleApiFail(removeResponse.data.header);
        // const data = response.data.data || [];

        UtilizationResultClosingService.createAggregateData(this.selectedCloseSts.yearMonth)
          .then(createResponse => {
            this.rootStore.apiHandleStore.handleApiFail(createResponse.data.header);
            // const data = response.data.data || [];

            this.getAggregateData();

            this.inProgress = false;
          })
          .catch((error: any) => {
            this.rootStore.apiHandleStore.handleApiError(error);
            this.inProgress = false;
          });
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }
}
