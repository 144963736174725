// TODO: USE IMPORT LAZY INSTEAD OF REQUIRE/IMPORT LIKE THIS
// CONVEnTION : prog_file_name should be a 'folder' instead of 'componentView'
// ex: program-management : correct
// program-management/views/View => in correct
import EN_API from 'pages/api-management/i18n/i18n_en.json';
import KR_API from 'pages/api-management/i18n/i18n_kr.json';
import EN_CODE from 'pages/code-management/i18n/i18n_en.json';
import KR_CODE from 'pages/code-management/i18n/i18n_kr.json';
import EN_COMMON_COMPONENT from 'pages/common/i18n/i18n_en.json';
import KR_COMMON_COMPONENT from 'pages/common/i18n/i18n_kr.json';
import EN_CORPORATIONS from 'pages/corporations/i18n/i18n_en.json';
import KR_CORPORATIONS from 'pages/corporations/i18n/i18n_kr.json';
import EN_DASHBOARD from 'pages/dashboard/i18n/i18n_en.json';
import KR_DASHBOARD from 'pages/dashboard/i18n/i18n_kr.json';
import EN_DEPARTMENT_MANAGEMENT from 'pages/department-management/i18n/i18n_en.json';
import KR_DEPARTMENT_MANAGEMENT from 'pages/department-management/i18n/i18n_kr.json';
import EN_EMPLOYEE_MANAGEMENT from 'pages/employee-management/i18n/i18n_en.json';
import KR_EMPLOYEE_MANAGEMENT from 'pages/employee-management/i18n/i18n_kr.json';
import EN_ERROR_PAGES from 'pages/errors/i18n/i18n_en.json';
import KR_ERROR_PAGES from 'pages/errors/i18n/i18n_kr.json';
import EN_HRASSIGN_REQUEST from 'pages/hrAssignRequest/i18n/i18n_en.json';
import KR_HRASSIGN_REQUEST from 'pages/hrAssignRequest/i18n/i18n_kr.json';
import EN_HRASSIGN_MANAGE from 'pages/human-resource-assign-management/i18n/i18n_en.json';
import KR_HRASSIGN_MANAGE from 'pages/human-resource-assign-management/i18n/i18n_kr.json';
import EN_HRASSIGN_VIEW from 'pages/human-resource-assign-view/i18n/i18n_en.json';
import KR_HRASSIGN_VIEW from 'pages/human-resource-assign-view/i18n/i18n_kr.json';
import EN_LOGIN from 'pages/login/i18n/i18n_en.json';
import KR_LOGIN from 'pages/login/i18n/i18n_kr.json';
import EN_MENU_MANAGEMENT from 'pages/menu-management/i18n/i18n_en.json';
import KR_MENU_MANAGEMENT from 'pages/menu-management/i18n/i18n_kr.json';
import EN_PASSWORD_FINDER from 'pages/password-finder/i18n/i18n_en.json';
import KR_PASSWORD_FINDER from 'pages/password-finder/i18n/i18n_kr.json';
import EN_PROGRAM_MANAGEMENT from 'pages/program-management/i18n/i18n_en.json';
import KR_PROGRAM_MANAGEMENT from 'pages/program-management/i18n/i18n_kr.json';
import EN_PROJECT from 'pages/project-management/i18n/i18n_en.json';
import KR_PROJECT from 'pages/project-management/i18n/i18n_kr.json';
import EN_PROJECT_RESOURCE from 'pages/project-resource/i18n/i18n_en.json';
import KR_PROJECT_RESOURCE from 'pages/project-resource/i18n/i18n_kr.json';
import EN_REDUCWORK from 'pages/reducwork/i18n/i18n_en.json';
import KR_REDUCWORK from 'pages/reducwork/i18n/i18n_kr.json';
import EN_ROLE_AUTHORITY from 'pages/role-authority-management/i18n/i18n_en.json';
import KR_ROLE_AUTHORITY from 'pages/role-authority-management/i18n/i18n_kr.json';
import EN_ROLE_MANAGEMENT from 'pages/role-management/i18n/i18n_en.json';
import KR_ROLE_MANAGEMENT from 'pages/role-management/i18n/i18n_kr.json';
import EN_USER_INFO from 'pages/user-information/i18n/i18n_en.json';
import KR_USER_INFO from 'pages/user-information/i18n/i18n_kr.json';
import EN_USERS from 'pages/user-management/i18n/i18n_en.json';
import KR_USERS from 'pages/user-management/i18n/i18n_kr.json';
import EN_UTILIZATION_RESULT from 'pages/utilization-result/i18n/i18n_en.json';
import KR_UTILIZATION_RESULT from 'pages/utilization-result/i18n/i18n_kr.json';
import EN_UTILIZATION from 'pages/utilization/i18n/i18n_en.json';
import KR_UTILIZATION from 'pages/utilization/i18n/i18n_kr.json';
import EN_WORKHOUR from 'pages/workhour/i18n/i18n_en.json';
import KR_WORKHOUR from 'pages/workhour/i18n/i18n_kr.json';
import EN_OVERTIME from 'pages/overtime/i18n/i18n_en.json';
import KR_OVERTIME from 'pages/overtime/i18n/i18n_kr.json';
import EN_CALENDAR from 'pages/calendar/i18n/i18n_en.json';
import KR_CALENDAR from 'pages/calendar/i18n/i18n_kr.json';
import EN_CORPCONFIG from 'pages/corporation-config/i18n/i18n_en.json';
import KR_CORPCONFIG from 'pages/corporation-config/i18n/i18n_kr.json';
import StorageHelper from 'shared/utils/StorageHelper';
import COMMON_EN_JSON from '../../i18n/hrm_en.json';
import COMMON_KR_JSON from '../../i18n/hrm_kr.json';
import AppConst from './AppConst';
import EN_CORP from 'pages/corp-management/i18n/i18n_en.json';
import KR_CORP from 'pages/corp-management/i18n/i18n_kr.json';
import EN_HISTORY_LOGIN from 'pages/logs-login/i18n/i18n_en.json';
import KR_HISTORY_LOGIN from 'pages/logs-login/i18n/i18n_kr.json';
import EN_HISTORY_FILE_DOWNLOAD from 'pages/logs-file-download/i18n/i18n_en.json';
import KR_HISTORY_FILE_DOWNLOAD from 'pages/logs-file-download/i18n/i18n_kr.json';
import EN_HISTORY_MENU_ACCESS from 'pages/logs-menu-access/i18n/i18n_en.json';
import KR_HISTORY_MENU_ACCESS from 'pages/logs-menu-access/i18n/i18n_kr.json';
import EN_MENU_HELP from 'pages/menu-help/i18n/i18n_en.json';
import KR_MENU_HELP from 'pages/menu-help/i18n/i18n_kr.json';
import EN_HISTORY_SYS_USAGE from 'pages/logs-system-usage/i18n/i18n_en.json';
import KR_HISTORY_SYS_USAGE from 'pages/logs-system-usage/i18n/i18n_kr.json';
import EN_HISTORY_MENU_USAGE from 'pages/logs-menu-usage-status/i18n/i18n_en.json';
import KR_HISTORY_MENU_USAGE from 'pages/logs-menu-usage-status/i18n/i18n_kr.json';

class LanguageHelper {
  private static instance: LanguageHelper;
  private langData: any;

  private constructor() {
    this.langData = this.getLanguageResourceData();
  }

  getLanguageResourceData = () => {
    // Merge data together
    const finalEN = {
      ...COMMON_EN_JSON,
      ...EN_USERS,
      ...EN_CODE,
      ...EN_CORPORATIONS,
      ...EN_DASHBOARD,
      ...EN_DEPARTMENT_MANAGEMENT,
      ...EN_EMPLOYEE_MANAGEMENT,
      ...EN_LOGIN,
      ...EN_PASSWORD_FINDER,
      ...EN_ROLE_MANAGEMENT,
      ...EN_PROJECT,
      ...EN_PROJECT_RESOURCE,
      ...EN_COMMON_COMPONENT,
      ...EN_HRASSIGN_REQUEST,
      ...EN_HRASSIGN_MANAGE,
      ...EN_HRASSIGN_VIEW,
      ...EN_ROLE_AUTHORITY,
      ...EN_ERROR_PAGES,
      ...EN_UTILIZATION_RESULT,
      ...EN_MENU_MANAGEMENT,
      ...EN_ERROR_PAGES,
      ...EN_UTILIZATION,
      ...EN_WORKHOUR,
      ...EN_OVERTIME,
      ...EN_CALENDAR,
      ...EN_CORPCONFIG,
      ...EN_PROGRAM_MANAGEMENT,
      ...EN_USER_INFO,
      ...EN_ERROR_PAGES,
      ...EN_REDUCWORK,
      ...EN_API,
      ...EN_CORP,
      ...EN_HISTORY_LOGIN,
      ...EN_HISTORY_FILE_DOWNLOAD,
      ...EN_HISTORY_MENU_ACCESS,
      ...EN_MENU_HELP,
      ...EN_HISTORY_SYS_USAGE,
      ...EN_HISTORY_MENU_USAGE
    };
    const finalKR = {
      ...COMMON_KR_JSON,
      ...KR_USERS,
      ...KR_CODE,
      ...KR_CORPORATIONS,
      ...KR_DASHBOARD,
      ...KR_DEPARTMENT_MANAGEMENT,
      ...KR_EMPLOYEE_MANAGEMENT,
      ...KR_LOGIN,
      ...KR_PASSWORD_FINDER,
      ...KR_ROLE_MANAGEMENT,
      ...KR_PROJECT,
      ...KR_PROJECT_RESOURCE,
      ...KR_COMMON_COMPONENT,
      ...KR_HRASSIGN_REQUEST,
      ...KR_HRASSIGN_MANAGE,
      ...KR_HRASSIGN_VIEW,
      ...KR_ROLE_AUTHORITY,
      ...KR_ERROR_PAGES,
      ...KR_UTILIZATION_RESULT,
      ...KR_MENU_MANAGEMENT,
      ...KR_ERROR_PAGES,
      ...KR_UTILIZATION,
      ...KR_WORKHOUR,
      ...KR_OVERTIME,
      ...KR_CORPCONFIG,
      ...KR_CALENDAR,
      ...KR_PROGRAM_MANAGEMENT,
      ...KR_USER_INFO,
      ...KR_ERROR_PAGES,
      ...KR_REDUCWORK,
      ...KR_API,
      ...KR_CORP,
      ...KR_HISTORY_LOGIN,
      ...KR_HISTORY_FILE_DOWNLOAD,
      ...KR_HISTORY_MENU_ACCESS,
      ...KR_MENU_HELP,
      ...KR_HISTORY_SYS_USAGE,
      ...KR_HISTORY_MENU_USAGE
    };

    const finalLanguagesData = {
      en: finalEN,
      ko: finalKR
    };
    return finalLanguagesData;
  };

  static getInstance(): LanguageHelper {
    if (!LanguageHelper.instance) {
      LanguageHelper.instance = new LanguageHelper();
    }
    return LanguageHelper.instance;
  }

  /**
   * Get message value from language json files
   * @param langKey
   */
  getMessage = (langKey: string) => {
    const currLang = StorageHelper.getItem(StorageHelper.KEY_CURR_LANGUAGE) === AppConst.LANG_KOKR ? 'ko' : 'en';
    return LanguageHelper.getInstance().langData[currLang][langKey];
  };

  /**
   * Get message value from language json files
   * @param langKey
   */
  getMessageWithLanguage = (langKey: string, language: string) => {
    return LanguageHelper.getInstance().langData[language][langKey];
  };
}

export default LanguageHelper.getInstance();
