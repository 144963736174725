import DashboardMobile from 'pages-mobile/dashboard';
import CorporationViewMobile from 'pages-mobile/corporations';
import LoginView from 'pages-mobile/login';
import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import UserInfoMobile from 'pages-mobile/user-info';
import MobileHeaderSidebar from 'pages-mobile/header-sidebar';
import PasswordFinderMobileView from 'pages-mobile/password-finder';
import PasswordResetMobileView from 'pages-mobile/password-reset';
import PasswordChangeMobileView from 'pages-mobile/password-change';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import NotFoundPage from './MobileNotFoundPage';
import MobileMultiLanguageProvider from '../MobileMultiLanguageProvider';
import { bucketRegisteredComponentWithPath } from './MobileRoutesAuthorization';
import OvertimeAppDetailMobileView from '../pages-mobile/overtime/views/OvertimeAppDetailMobileView';
import OvertimeRegMobileView from '../pages-mobile/overtime/views/OvertimeRegMobileView';
import UtilizationResultRegMobileView from '../pages-mobile/utilization-result/views/UtilizationResultRegMobileView';
import UtilizationResultAppDetailMobileView from '../pages-mobile/utilization-result/views/UtilizationResultAppDetailMobileView';

// const NotFoundPage = () => {
//   return <div>404 not found</div>;
// };

@inject('authStore', 'mobileHeaderSidebarStore')
@observer
class MTNRouter extends React.Component<any> {
  componentDidMount(): void {
    this.props.mobileHeaderSidebarStore.getMenuAssigned();

    this.props.authStore!.runWhenLoginSuccess();
  }

  render() {
    const addHeaderSidebar = (Component: any) => {
      return (props: any) => (
        <MobileMultiLanguageProvider>
          <MobileHeaderSidebar history={props.history}>
            <Component {...props} />
          </MobileHeaderSidebar>
        </MobileMultiLanguageProvider>
      );
    };

    // logged mean user already finished logged in progress including select coporattion
    const isUserLogged = this.props.authStore!.isLogged;

    const listRouteFromService = toJS(this.props.mobileHeaderSidebarStore!.menuAssignedList);
    const isAuthorizationStoreProgressing = this.props.mobileHeaderSidebarStore!.inProgress;
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/m" />} />
          <Route exact path="/m" component={LoginView} />
          <Route path="/m/corporations" component={CorporationViewMobile} />
          <Route path="/m/passwordfinder" component={PasswordFinderMobileView} />
          <Route path="/reset/password" component={PasswordResetMobileView} />
          <Route exact path="/change/password" component={PasswordChangeMobileView} />

          {!isUserLogged && <Redirect to="/" />}
          <Route path="/m/dashboard" component={addHeaderSidebar(DashboardMobile)} />
          <Route path="/m/user-info" component={addHeaderSidebar(UserInfoMobile)} />

          <Route path="/m/timesheet/overtime/overtimereg" component={addHeaderSidebar(OvertimeRegMobileView)} />
          <Route path="/m/timesheet/overtime/overtimeapp/detail" component={addHeaderSidebar(OvertimeAppDetailMobileView)} />
          <Route path="/m/timesheet/utilresult/utilresultreg" component={addHeaderSidebar(UtilizationResultRegMobileView)} />
          <Route path="/m/timesheet/utilresult/utilresultapp/detail" component={addHeaderSidebar(UtilizationResultAppDetailMobileView)} />

          {listRouteFromService
            .filter((x: any) => x.progPath)
            .map((v: any) => {
              const { progFileName: pathFromService, progPath: pathRoute } = v;
              const Component = bucketRegisteredComponentWithPath.get(pathFromService);
              if (Component) {
                return <Route exact path={pathRoute} render={addHeaderSidebar(Component)} key={Math.random()} />;
              }
              return <Route exact path={pathRoute} component={NotFoundPage} key={Math.random()} />;
            })}

          {!isAuthorizationStoreProgressing && <Route component={NotFoundPage} />}
        </Switch>
      </BrowserRouter>
    );
  }
}
export default MTNRouter;
