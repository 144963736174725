import ApiHelper from 'shared/utils/ApiHelper';
import CommonCodeModel from 'shared/models/code/CommonCodeModel';
import AppConst from 'shared/utils/AppConst';

const API_BASE_COMMONCODE = AppConst.API.SYS_COMMONCODE;

class CommonCodeManagementService {
  // eslint-disable-next-line class-methods-use-this
  getAllCommonCodes(representCode: string) {
    const api = API_BASE_COMMONCODE;
    const params = {
      ...(representCode && {
        representCode
      })
    };
    return ApiHelper.get(api, { ...params });
  }

  // eslint-disable-next-line class-methods-use-this
  exportExcelFile(representCode: string) {
    const api = `${API_BASE_COMMONCODE}/export?representCode=${representCode}`;
    return ApiHelper.get(api);
  }

  insertUserCommon = (arr: CommonCodeModel[]) => {
    const api = API_BASE_COMMONCODE;
    arr.map((item: any) => {
      delete item.changeStatus;
      delete item.id;
    });
    return ApiHelper.post(api, arr);
  };

  updateUserCommon = (arr: CommonCodeModel[]) => {
    const api = API_BASE_COMMONCODE;
    arr.map((item: any) => {
      delete item.changeStatus;
      delete item.id;
    });
    return ApiHelper.put(api, arr);
  };

  // eslint-disable-next-line class-methods-use-this
  deleteCommonCodeItems(arr: any[]) {
    const api = API_BASE_COMMONCODE;
    return ApiHelper.delete(api, arr);
  }

  // eslint-disable-next-line class-methods-use-this
  findAllCommonCode(representCode: string) {
    const api = `${API_BASE_COMMONCODE}?representCode=${representCode}`;
    return ApiHelper.get(api);
  }
}

export default new CommonCodeManagementService();
