import { IntlProvider } from 'react-intl';
import { inject, observer } from 'mobx-react';
import React from 'react';
import enUS from 'antd-mobile/lib/locale-provider/en_US';
import { LocaleProvider } from 'antd-mobile';
import { UIStore } from './shared/mobile-stores/UIStore';
import MobileLanguageHelper from './shared/utils/MobileLanguageHelper';


interface IMultiLanguageProviderProps {
  children?: any;
  uiStore?: UIStore;
}

@inject('uiStore')
@observer
class MobileMultiLanguageProvider extends React.Component<IMultiLanguageProviderProps> {
  render() {
    const languagesData = MobileLanguageHelper.getLanguageResourceData();

    const { currLanguage } = this.props.uiStore!;
    const localeText = currLanguage.locale === 'en' ? 'en' : 'ko';
    const localeData = currLanguage.locale === 'en' ? languagesData.en : languagesData.ko;
    return (
      <IntlProvider locale={localeText} messages={localeData}>
        {/* ant mobile only support english for now */}
        <LocaleProvider locale={enUS}>{this.props.children}</LocaleProvider>
      </IntlProvider>
    );
  }
}

export default MobileMultiLanguageProvider;
