/* eslint-disable class-methods-use-this */
import ApiHelper from 'shared/utils/ApiHelper';
import { AxiosResponse } from 'axios';
import CorporationConfigModel from '../static/CorporationConfigModel';

const API_BASE = '/timesheet';

class CorporationConfigService {
  getCorpConf(): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/corp-confs`;
    return ApiHelper.get(api);
  }

  updateCorpConf(CorporationConfig: CorporationConfigModel): any {
    const api = `${API_BASE}/corp-confs`;
    return ApiHelper.put(api, CorporationConfig);
  }
}

export default new CorporationConfigService();
