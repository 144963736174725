import { Icon } from 'antd';
import { Button, Flex, InputItem, List, WhiteSpace } from 'antd-mobile';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Redirect } from 'react-router-dom';
import StorageHelper from 'shared/utils/StorageHelper';
import '../login-mobile.less';
import { toJS } from 'mobx';
import AppConst from 'shared/utils/AppConst';

const rcForm = require('rc-form');

@inject('authStore', 'corporationStore', 'mobileHeaderSidebarStore')
@observer
class LoginViewMobile extends React.Component<any, any> {
  onLogin = () => {
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        this.props.authStore!.login(values.username, values.password).then(() => {
          let redirectTo = '/m/dashboard';
          const fechedCorpList = toJS(this.props.corporationStore!.corpList) || [];
          if (!this.props.authStore!.selectedCorporation && fechedCorpList.length) {
            StorageHelper.setItem(StorageHelper.KEY_CURR_LANGUAGE, AppConst.LANG_KOKR);
            redirectTo = '/m/corporations';
          }
          if (redirectTo === '/m/dashboard') {
            this.props.mobileHeaderSidebarStore.getMenuAssigned();
          }
          this.props.history.push(redirectTo);
        });
      }
    });
  };

  moveToPasswordFinder = () => {
    this.props.history.push('/m/passwordfinder');
  };

  render() {
    // check if user already login
    const { authModel } = this.props.authStore!;
    const isLoggedIn = StorageHelper.getItem(StorageHelper.KEY_ACCESS_TOKEN) !== null;
    // if (isLoggedIn) {
    //   // eslint-disable-next-line react/jsx-curly-brace-presence
    //   return <Redirect to={'/m/dashboard'} />;
    // }
    // if (authModel && authModel.accessToken !== '') {
    //   // eslint-disable-next-line react/jsx-curly-brace-presence
    //   return <Redirect to={'/m/dashboard'} />;
    // }
    let errors;
    const { getFieldProps, getFieldError } = this.props.form;
    return (
      <div style={{ height: '100%', background: '#fff' }}>
        <Flex justify="center" direction="column" className="login-m-container">
          <Icon type="clock-circle" style={{ fontSize: '36px' }} />

          <h1>TimeSheet</h1>

          <WhiteSpace size="lg" />

          <List style={{ padding: '0 40px' }}>
            <InputItem
              {...getFieldProps('username', {
                rules: [{ required: true, message: '사용자명을 입력해주세요.' }]
              })}
              placeholder="로그인ID"
              className={getFieldError('username') ? 'err-input' : ''}
            >
              <Icon type="user" />
            </InputItem>
            {(errors = getFieldError('username')) ? <span className="err-text">{errors.join(',')}</span> : null}
            <WhiteSpace size="md" />

            <InputItem
              {...getFieldProps('password', {
                rules: [{ required: true, message: '비밀번호를 입력해주세요.' }]
              })}
              type="password"
              placeholder="비밀번호"
              className={getFieldError('password') ? 'err-input' : ''}
            >
              <Icon type="key" />
            </InputItem>
            {(errors = getFieldError('password')) ? <span className="err-text">{errors.join(',')}</span> : null}
            <WhiteSpace size="md" />

            <WhiteSpace />
            <WhiteSpace />
            <Button type="primary" onClick={this.onLogin}>
              로그인
            </Button>
            <WhiteSpace />
            <Button onClick={this.moveToPasswordFinder}>비밀번호 찾기</Button>
          </List>

          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />

          <img width="30%" alt="example" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSokS4ZFbokxZP0_teVuPZfxRvCFvL8BkLEuy2ggaQluDgUO5WEug" />
        </Flex>
      </div>
    );
  }
}
const WrappedLoginMobileForm = rcForm.createForm()(LoginViewMobile);
export default WrappedLoginMobileForm;
