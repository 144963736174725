class AppConst {
  public static readonly APP_NAME = 'Timesheet';

  public static readonly HEADER_MENU_DASHBOARD = 'menu-dashboard';
  public static readonly HEADER_MENU_TIMESHEET = 'menu-timesheet';
  public static readonly HEADER_MENU_SYSTEM = 'menu-system';
  public static readonly HEADER_MENU_LOGOUT = 'menu-logout';
  public static readonly HEADER_MENUITEM_SYSTEM_COMMON = 'menuitem-system-common';
  public static readonly HEADER_MENUITEM_SYSTEM_MANAGERMENT = 'menuitem-system-management';
  public static readonly HEADER_MENU_LOGGED_USER = 'menu-logged-user';

  public static readonly MAX_ROW_WITHOUT_PAGING = 1000;
  public static readonly TABLE_PAGE_SIZE = 10;
  public static readonly LOG_TABLE_PAGE_SIZE = 100;
  public static readonly LOG_DEFAULT_OFFSET = 0;
  public static readonly DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
  public static readonly DEFAULT_UNIX_END_DATE = 253402268399000; // 9999-12-31 23:59:59 // Korean DB timestamp
  public static readonly DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
  public static readonly DEFAULT_END_TIME = 86399000; // 23:59:59
  public static readonly BRIDGE_DATE_CHAR = '-';

  public static readonly USE_NO = 'N';
  public static readonly USE_YES = 'Y';
  public static readonly YN_NO = 'N';
  public static readonly YN_YES = 'Y';
  public static readonly SELECT_ALL = 'All';
  public static readonly SELECT_NONE = 'None';
  public static readonly NOT_FOUND = -1;
  public static readonly IN_USE = '1';
  public static readonly NOT_IN_USE = '2';
  public static readonly ActionType = {
    CREATE: 'CREATE',
    READ: 'READ',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE'
  };

  public static readonly VALIDATOR = {
    maxLength: {
      userName: 50,
      phoneNumber: 20,
      password: 20
    }
  };

  public static readonly API_URL = process.env.REACT_APP_API_URL;
  public static readonly API_TIMESHEET_URL = process.env.REACT_APP_API_TIMESHEET;
  public static readonly LANG_EN = 'en';
  public static readonly LANG_ENUS = 'en-US';
  public static readonly LANG_KR = 'kr';
  public static readonly LANG_KO = 'ko';
  public static readonly LANG_KOKR = 'ko-KR';

  public static readonly API = {
    LOGIN: '/system/authenticate/login',
    SSO: '/system/authenticate/sso',
    LOGOUT: '/system/authenticate/logout',
    ENTERCORP: '/system/authenticate/enterCorp',
    SWITCH_CORP: '/system/authenticate/switchCorp',
    SYS_AUTHEN_FIND_PASSWORD: '/system/authenticate/find/password',
    SYS_AUTHEN_RESET_PASSWORD: '/system/authenticate/reset/password',
    SYS_USER: '/system/user',
    SYS_DEPT: '/system/dept',
    SYS_DEPT_HISTORY: '/system/dept/history',
    SYS_DEPT_ROOT: '/system/dept/root',
    SYS_DEPT_EXPORT: '/system/dept/export',
    SYS_EMP: '/system/emp',
    SYS_CONCURRENT_EMP_SEARCH: '/system/emp/corp',
    SYS_HELP: '/system/help',
    SYS_MENU: '/system/menu',
    SYS_MENU_FOLDER: '/system/menu/folder',
    SYS_PROGRAM: '/system/prog',
    SYS_PROGRAM_API: '/system/prog/api',
    SYS_API: '/system/api',
    SYS_LANGUAGE: '/system/language',
    SYS_REPRESENTCODE: '/system/representCode',
    SYS_COMMONCODE: '/system/commonCode',
    SYS_ROLE: '/system/role',
    SYS_ROLE_MENU: '/system/role/menu',
    SYS_AUTHORITY: '/system/authority',
    SYS_AUTHORITY_EMP: '/system/authority/emp',
    SYS_AUTHORITY_ROLE: '/system/authority/role',
    SYS_AUTHORITY_EXPORT: '/system/authority/export',
    SYS_CORP_CODE: '/system/corp/codes',
    SYS_MENU_ASSIGNED: '/system/menu/assigned',
    SYS_MENU_ASSIGNED_MOBILE: '/system/menu/assigned/mobile',
    SYS_CORP_SEARCH: '/system/corp/history',
    SYS_CORP_MANAGEMENT: '/system/corp',
    SYS_HISTORY_LOGIN: '/system/loginHist',
    SYS_HISTORY_FILE_DOWNLOAD: '/system/fileDownloadHist',
    SYS_HISTORY_MENU_ACCESS: '/system/menuAccessHist',
    SYS_HISTORY_PERSONALINFO_ACCESS: '/system/personalInfoAccessHist',
    SYS_HISTORY_REPORT_FILE: '/system/reportFileHist',
    SYS_HISTORY_REPORT_PRINT: '/system/reportFileHist',
    SYS_HISTORY_SYSTEM_USAGE: '/system/systemUsageHist',
    SYS_HISTORY_MENU_USAGE: '/system/menuUsageStatus'
  };

  public static readonly PERMISSION = {
    READ: 'VP',
    PRINT: 'PP',
    EDIT: 'EP',
    ADD: 'CP',
    FULL: 'AP'
  };

  static layoutConfig = {
    // to store html ID of web element
    // is aim to generate style by JS later
    mainLayoutContentId: 'mainLayoutContent',
    mainLayoutContentPadding: 24,
    tableBodyClassName: 'ant-table-body'
  };

  static menyType = {
    FOLDER: 'MF',
    PROGRAM: 'MP'
  };

  public static readonly RCode = {
    CORP_CODE: '0003',
    EMP_TYPE_CODE: '0004',
    WORK_SYS_CODE: '0005',
    OFFICE_CODE: '0009',
    WORK_GROUP_CODE: '0010',
    JOB_CODE: '0011',
    POSIT_CODE: '0015',
    IDENTITY_TYPE: '0028',
    USER_TYPE: '0017',
    ORIGIN_MEMBER_YN: '0018',
    VIRTUAL_DEPT_YN: '0019',
    REDUC_WORK_TYPE: '0020',
    LARGE_CLASS: '0021',
    MEDIUM_CLASS: '0022',
    STATUS_TYPE: '0023',
    PROG_TYPE: '0025',
    MENU_TYPE: '0026',
    AUTH_TYPE: '0027',
    TIMEZONE_CODE: '0028'
  };

  public static readonly DATE_TYPE = {
    START_DATE: 0,
    END_DATE: 1
  };
}

export default AppConst;
