import { Badge, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { AuthStore } from 'shared/stores/AuthenticationStore';
import React from 'react';
import { Redirect } from 'react-router-dom';
import StorageHelper from 'shared/utils/StorageHelper';
import { CorporationStore } from '../store/CorporationStore';

export interface Props {
  corpName: string;
  noWaitApproval: number;
  corpCode: string;
  corporationStore?: CorporationStore;
  authStore?: AuthStore;
}

export interface IState {
  isRedirect: boolean;
}

@inject('corporationStore', 'authStore')
@observer
class CorporationCard extends React.Component<Props, IState> {
  state = {
    isRedirect: false
  };

  onClickCard(cardProp: any) {
    this.props.authStore!.setSelectedCorp(cardProp.corpCode).then(() => {
      if (StorageHelper.getItem(StorageHelper.KEY_CURR_CORP_CODE)) {
        this.setState({
          isRedirect: true
        });
      }
    });
  }

  render() {
    const noApproval = this.props && this.props.noWaitApproval ? this.props.noWaitApproval : 0;
    const badgeStyle = noApproval === 0 ? { backgroundColor: '#888', marginLeft: 3 } : { backgroundColor: '#108ee9', marginLeft: 3 };

    if (this.state.isRedirect) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <div onClick={() => this.onClickCard(this.props)}>
        <Card style={{ padding: 1, margin: 10, minHeight: 160, textAlign: 'center', background: '#FFF'}} hoverable bordered={false}>
          <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>{this.props.corpName}</h3>
          <div>
            승인대기중
            <Badge count={noApproval} showZero style={badgeStyle} />
          </div>
        </Card>
      </div>
    );
  }
}

export default CorporationCard;
