class StorageHelper {
  KEY_ACCESS_TOKEN = 'hrm_jwtToken';
  KEY_USER_NAME = 'hrm_username';
  KEY_USER_ID = 'hrm_userid';
  KEY_CURR_CORP_CODE = 'hrm_curr_corp_code';
  KEY_CURR_LANGUAGE = 'hrm_curr_language';
  KEY_LATEST_MENU_CODE_TRACKED = 'hrm_latest_menu_code_tracked';

  resetAll() {
    this.removeItem(this.KEY_ACCESS_TOKEN);
    this.removeItem(this.KEY_USER_NAME);
    this.removeItem(this.KEY_USER_ID);
    this.removeItem(this.KEY_CURR_CORP_CODE);
    this.removeItem(this.KEY_CURR_LANGUAGE);
    this.removeItem(this.KEY_LATEST_MENU_CODE_TRACKED);
  }

  removeItem = (key: string) => {
    sessionStorage.removeItem(key);
  };

  getItem = (key: string) => {
    return sessionStorage.getItem(key) || null;
  };

  setItem = (key: string, value: string) => {
    sessionStorage.setItem(key, value);
  };
}

export default new StorageHelper();
