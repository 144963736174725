import ApiHelper from 'shared/utils/ApiHelper';
import { AxiosResponse } from 'axios';
// import MailSendLogModel from '../static/MailSendLogModel';

const API_BASE = '/timesheet';

class MailSendLogService {
  // eslint-disable-next-line class-methods-use-this
  getMailSendLog(empNo: string, empNm: string, mailSendYn: string, fromDt: string, toDt: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/mail-send-logs`;
    const trimedEmpNo = empNo ? empNo.trim() : '';
    const trimedEmpNm = empNm ? empNm.trim() : '';
    const trimedMailSendYn = mailSendYn ? mailSendYn.trim() : '';
    const trimedFromDt = fromDt ? fromDt.trim() : '';
    const trimedToDt = toDt ? toDt.trim() : '';
    const params = {
      empNo: trimedEmpNo,
      empNm: trimedEmpNm,
      mailSendYn: trimedMailSendYn,
      fromDt: trimedFromDt,
      toDt: trimedToDt
    };
    return ApiHelper.get(api, params);
  }

  // eslint-disable-next-line class-methods-use-this
  getMailReSend(seq: number): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/mail-send-logs/seq/${seq}`;
    return ApiHelper.get(api);
  }
}

export default new MailSendLogService();
