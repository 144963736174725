import { message } from 'antd';
import _ from 'lodash';
import { action, observable, toJS } from 'mobx';
import CorporationAPIModel from 'shared/models/corporation/CorporationAPIModel';
import EmployeeModel from 'shared/models/employee/EmployeeModel';
import { RootStore } from 'shared/stores/RootStore';
import AppConst from 'shared/utils/AppConst';
import AppHelper from 'shared/utils/AppHelper';
import LanguageHelper from 'shared/utils/LanguageHelper';
import EmployeeManagementService from '../services/EmployeeManagementService';

const getStatus = (objOriginal: any, newObj: any, changeStatusFieldName: string): string => {
    // changeStatusFieldName: some model use changeStatus, some model use status => need a string
    const objOrginalShallow = { ...objOriginal };
    objOrginalShallow[changeStatusFieldName] = undefined;
    const objNewShallow = { ...newObj };
    objNewShallow[changeStatusFieldName] = undefined;
  
    if (!objOriginal) {
      return 'CREATE';
    }
    if (_.isEqual(objOrginalShallow, objNewShallow)) {
      return '';
    }
    return 'UPDATE';
  };

export type TSearchParams = {
  empNo: string;
  empName: string;
  deptCode: string;
  originMemberYn: string;
  deptName: string;
  officeCode: string;
};

export class EmployeeStore {
  @observable
  selectedItem: EmployeeModel;

  @observable
  selectedConcurrentEmployee: any;

  @observable
  concurrentEmployees: EmployeeModel[];

  @observable
  employees: EmployeeModel[];

  @observable
  employeesToUpsert: EmployeeModel[];

  @observable
  employeesToRemove: any[];

  // MobX prefer using ID more than pass a whole object, tick before submot after submit remove item
  @observable
  inProgress: boolean;

  @observable
  inProgressCurrentEmp: boolean;

  @observable
  checkOriginEmpoyee: boolean;

  @observable
  searchParams?: TSearchParams;

  @observable
  corporations: CorporationAPIModel[] = [];

  @observable
  employeeDetail: EmployeeModel;

  originalEmployee: EmployeeModel[];

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.employeesToUpsert = [];
    this.employeesToRemove = [];
    this.employees = [];
    this.originalEmployee = [];
    this.concurrentEmployees = [];
    this.checkOriginEmpoyee = false;
    this.rootStore = rootStore;
    this.inProgressCurrentEmp = false;
  }

  @action
  getAllCorporation = () => {
    this.inProgress = true;
    return EmployeeManagementService.getAllData(AppConst.API.SYS_CORP_CODE)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.corporations = data;
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.inProgress = false;
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  };

  @action
  setEmployeeToRemove(arr: any[]) {
    this.employeesToRemove = arr;
  }

  @action
  deleteEmployee() {
    this.inProgress = true;

    return EmployeeManagementService.deleteEmployees(this.employeesToRemove)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        this.employeesToRemove = [];
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  /**
   * Submit  data in case of UPDATE / INSERT
   */
  @action
  submitEmployeeData = () => {
    const insertEmp: any[] = [];
    const updateEmp: any[] = [];
    let deleteEmp: any[] = [];
    if (this.employeesToUpsert && this.employeesToUpsert.length > 0) {
      this.inProgress = true;
      const arrayToChange: any[] = [];
      this.employeesToUpsert.map(item => {
        arrayToChange.push({
          changeStatus: item.changeStatus,
          cellPhoneNo: item.cellPhoneNo ? item.cellPhoneNo : '',
          deptCode: this.trimData(item.deptCode),
          deptHeadEmpName: this.trimData(item.deptHeadEmpName),
          deptHeadEmpNo: this.trimData(item.deptHeadEmpNo),
          deptName: this.trimData(item.deptName),
          divCode: this.trimData(item.divCode),
          divName: this.trimData(item.divName),
          email: this.trimData(item.email),
          empName: this.trimData(item.empName),
          empNo: item.empNo,
          empTypeCode: this.trimData(item.empTypeCode),
          empTypeName: this.trimData(item.empTypeName),
          entryDate: Number(item.entryDate),
          groupEntryDate: Number(item.groupEntryDate),
          resignDate: item.resignDate,
          jobCode: this.trimData(item.jobCode),
          jobName: this.trimData(item.jobName),
          nextLevelHeadEmpName: this.trimData(item.nextLevelHeadEmpName),
          nextLevelHeadEmpNo: this.trimData(item.nextLevelHeadEmpNo),
          officeCode: this.trimData(item.officeCode),
          officeName: this.trimData(item.officeName),
          originMemberYn: item.originMemberYn != null ? item.originMemberYn : '',
          phoneNo: item.phoneNo ? item.phoneNo : '',
          positCode: this.trimData(item.positCode),
          positName: this.trimData(item.positName),
          remark: this.trimData(item.remark),
          rowStatus: item.rowStatus,
          sectHeadEmpName: this.trimData(item.sectHeadEmpName),
          sectHeadEmpNo: this.trimData(item.sectHeadEmpNo),
          userId: this.trimData(item.userId),
          workGroupCode: this.trimData(item.workGroupCode),
          workGroupName: this.trimData(item.workGroupName),
          workSysCode: this.trimData(item.workSysCode),
          workSysName: this.trimData(item.workSysName)
        });

        return arrayToChange;
      });
      arrayToChange.map(obj => {
        if (obj.changeStatus === 'CREATE') {
          insertEmp.push(obj);
        } else if (obj.changeStatus === 'UPDATE') {
          updateEmp.push(obj);
        }
        return arrayToChange;
      });
    }
    if (this.employeesToRemove && this.employeesToRemove.length > 0) {
      this.inProgress = true;

      const canRemoveEmployees = [] as string[];

      this.employeesToRemove.map((item: any) => {
        return canRemoveEmployees.push(item.empNo);
      });
      if (this.checkOriginEmpoyee) {
        this.inProgress = false;
      } else {
        deleteEmp = canRemoveEmployees;
      }
    }

    const promises: any[] = [];
    if (insertEmp.length) {
      promises.push(EmployeeManagementService.insertEmployees(insertEmp));
    }
    if (updateEmp.length) {
      promises.push(EmployeeManagementService.updateEmployees(updateEmp));
    }
    if (deleteEmp.length) {
      promises.push(EmployeeManagementService.deleteEmployees(deleteEmp));
    }
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    Promise.all(promises)
      .then(responses => {
        // if responses have 1 success and 1 false ...... to do how ?????
        that.employeesToRemove = [];
        that.employeesToUpsert = [];
        responses.map(response => {
          this.rootStore.apiHandleStore.handleApiFail(response.data.header);
          const { isSuccessful } = response.data.header;
          if (isSuccessful) {
            message.info(LanguageHelper.getMessage('message.submit.done'));
          }
          return response;
        });
        if (this.searchParams) {
          that.findEmployeeByConditions(this.searchParams);
        }
      })
      .catch((error: any) => {
        console.error(error);
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });

    if (this.employeesToUpsert && !(this.employeesToUpsert.length > 0) && this.employeesToRemove && !(this.employeesToRemove.length > 0)) {
      message.info(LanguageHelper.getMessage('message.nothing.submit'));
    }
    return 0;
  };

  @action
  findEmployeeByConditions(searchParams: TSearchParams) {
    const { empNo, empName, deptCode, deptName, originMemberYn, officeCode } = searchParams;
    let isOriginMemberYn = originMemberYn || '';
    if (originMemberYn === 'All') {
      isOriginMemberYn = '';
    }

    this.inProgress = true;
    this.employeesToUpsert = [];
    return EmployeeManagementService.findAllEmployee(empNo, empName, deptCode, isOriginMemberYn, deptName, officeCode)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.employees = [];
        this.searchParams = { empNo, empName, deptCode, originMemberYn, deptName, officeCode };
        const dataEmp = data.map((item: any) => {
          return new EmployeeModel({
            id: item.id,
            changeStatus: item.changeStatus,
            cellPhoneNo: item.cellPhoneNo,
            corpCode: item.corpCode,
            corpName: item.corpName,
            deptCode: item.deptCode,
            deptHeadEmpName: item.deptHeadEmpName,
            deptHeadEmpNo: item.deptHeadEmpNo,
            deptName: item.deptName,
            divCode: item.divCode,
            divName: item.divName,
            email: item.email,
            empName: item.empName,
            empNo: item.empNo,
            empTypeCode: item.empTypeCode,
            empTypeName: item.empTypeName,
            entryDate: item.entryDate,
            groupEntryDate: item.groupEntryDate,
            jobCode: item.jobCode,
            jobName: item.jobName,
            nextLevelHeadEmpName: item.nextLevelHeadEmpName,
            nextLevelHeadEmpNo: item.nextLevelHeadEmpNo,
            officeCode: item.officeCode,
            officeName: item.officeName,
            originMemberYn: item.originMemberYn,
            phoneNo: item.phoneNo,
            positCode: item.positCode,
            positName: item.positName,
            remark: item.remark,
            rowStatus: item.rowStatus,
            sectHeadEmpName: item.sectHeadEmpName,
            sectHeadEmpNo: item.sectHeadEmpNo,
            userId: item.userId,
            workGroupCode: item.workGroupCode,
            workGroupName: item.workGroupName,
            workSysCode: item.workSysCode,
            workSysName: item.workSysName,
            regEmpNo: item.regEmpNo,
            regEmpName: item.regEmpName,
            updatedEmpNo: item.updatedEmpNo,
            updatedEmpName: item.updatedEmpName,
            regDate: item.regDate,
            updatedDate: item.updatedDate,
            resignDate: item.resignDate
          });
        });
        this.originalEmployee = dataEmp;
        this.employees = dataEmp;
        this.inProgress = false;
      })
      .catch((e: any) => {
        this.rootStore.apiHandleStore.handleApiError(e);
        this.inProgress = false;
      });
  }

  @action
  findConcurrentEmployeeByConditions(searchParams: any, urlApi: string) {
    this.inProgressCurrentEmp = true;
    return EmployeeManagementService.findConcurrentEmployee(searchParams, urlApi)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.concurrentEmployees = [];

        const dataEmpCon = data.map((item: any) => {
          return new EmployeeModel({
            id: item.id,
            changeStatus: item.changeStatus,
            cellPhoneNo: item.cellPhoneNo,
            corpCode: item.corpCode,
            corpName: item.corpName,
            deptCode: item.deptCode,
            deptHeadEmpName: item.deptHeadEmpName,
            deptHeadEmpNo: item.deptHeadEmpNo,
            deptName: item.deptName,
            divCode: item.divCode,
            divName: item.divName,
            email: item.email,
            empName: item.empName,
            empNo: item.empNo,
            empTypeCode: item.empTypeCode,
            empTypeName: item.empTypeName,
            entryDate: item.entryDate,
            groupEntryDate: item.groupEntryDate,
            jobCode: item.jobCode,
            jobName: item.jobName,
            nextLevelHeadEmpName: item.nextLevelHeadEmpName,
            nextLevelHeadEmpNo: item.nextLevelHeadEmpNo,
            officeCode: item.officeCode,
            officeName: item.officeName,
            originMemberYn: item.originMemberYn,
            phoneNo: item.phoneNo,
            positCode: item.positCode,
            positName: item.positName,
            remark: item.remark,
            rowStatus: item.rowStatus,
            sectHeadEmpName: item.sectHeadEmpName,
            sectHeadEmpNo: item.sectHeadEmpNo,
            userId: item.userId,
            workGroupCode: item.workGroupCode,
            workGroupName: item.workGroupName,
            workSysCode: item.workSysCode,
            workSysName: item.workSysName,
            regEmpNo: item.regEmpNo,
            regEmpName: item.regEmpName,
            updatedEmpNo: item.updatedEmpNo,
            updatedEmpName: item.updatedEmpName,
            regDate: item.regDate,
            updatedDate: item.updatedDate,
            resignDate: item.resignDate
          });
        });
        this.concurrentEmployees = dataEmpCon;
        this.inProgressCurrentEmp = false;
      })
      .catch((error: any) => {
        this.inProgressCurrentEmp = false;
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  }

  @action
  exportExcelFile() {
    return EmployeeManagementService.exportExcelFile(this.searchParams)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const url = response.data.data.url || '';
        if (url && url !== '') {
          AppHelper.downloadExcel(url);
        }
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  }

  @action
  updateEmployeesToUpsert(id: any, newValues: any) {
    const index = this.employeesToUpsert.findIndex(item => item.id === id);
    const updateIndex = this.employees.findIndex(item => item.id === id);

    if (index > -1) {
      this.employeesToUpsert[index] = {
        ...this.employeesToUpsert[index],
        ...newValues
      };
    } else if (updateIndex > -1) {
      this.employeesToUpsert.push({
        ...this.employees[updateIndex],
        ...newValues
      });
    }
  }

  @action
  setSelectedItem(empItem: any) {
    this.selectedItem = empItem;
  }

  @action
  setSelectedConcurrentEmployee(empItem: any) {
    this.selectedConcurrentEmployee = empItem;
  }

  @action
  removeBlankRow() {
    this.employees.splice(-1, 1);
  }

  trimData = (data: any) => {
    if (data && typeof data === 'string') {
      return data.trim();
    }
    return '';
  };

  @action
  getEmployeeDetail = (employeeNo: string) => {
    this.inProgress = true;
    return EmployeeManagementService.getEmployeeDetail(employeeNo)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        this.inProgress = false;
        const { isSuccessful } = response.data.header;
        if (isSuccessful) {
          const { data } = response.data;
          this.employeeDetail = data && data.length > 0 ? data[0] : null;
        }
      })
      .catch((error: any) => {
        this.inProgress = false;
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  };

  @action
  unDeleteItems(itemIds: string[]) {
    // case when user uncheck a confirmed delete item
    // => should revert to previous state (READ/UPDATE...)
    itemIds.map(id => {
      const idxItemRemoveInDelete =  this.employeesToRemove.findIndex(obj => obj.id === id);
      this.employeesToRemove.splice(idxItemRemoveInDelete,1);
      const idxItemToUndelete = this.employees.findIndex(obj => obj.id === id);
      if (idxItemToUndelete > -1) {
        const changeStatus = getStatus(toJS(this.originalEmployee.find(obj => obj.id === id)), toJS(this.employees[idxItemToUndelete]), 'changeStatus');
        this.employees[idxItemToUndelete] = new EmployeeModel(
          {
            ...toJS(this.employees[idxItemToUndelete]),
            changeStatus
          },
          id
        );
      }
      return this.employees[idxItemToUndelete];
    });
  }

}
