import ApiHelper from 'shared/utils/ApiHelper';
import { AxiosResponse } from 'axios';

import UtilizationResultModel from '../static/UtilizationResultModel';

const API_BASE = '/timesheet';

class UtilizationResultCalendarMobileService {
  getUtilizationResultList(fromDate: string, toDate: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/emps/me/utilization-results?from=${fromDate}&to=${toDate}`;
    return ApiHelper.get(api);
  }

  getMonthCalendarList(yearMonth: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/calendars/param?yearMonth=${yearMonth}`;
    return ApiHelper.get(api);
  }

  createUtilizationResult(mh: UtilizationResultModel[]): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/utilization-results`;
    return ApiHelper.post(api, mh);
  }

  updateUtilizationResult(mh: UtilizationResultModel[]): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/utilization-results`;
    return ApiHelper.put(api, mh);
  }

  deleteUtilizationResult(mh: UtilizationResultModel[]): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/utilization-results`;
    return ApiHelper.delete(api, mh);
  }

  getCorpConf(): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/corp-confs`;
    return ApiHelper.get(api);
  }

  getCloseSts(yearMonth: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/closests/${yearMonth}`;
    return ApiHelper.get(api);
  }

  getCalendarModel(date: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/calendars/${date}`;
    return ApiHelper.get(api);
  }
}

export default new UtilizationResultCalendarMobileService();
