import React from 'react';
import { Form, Col, Input, Button, Row } from 'antd';
import { observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import commonStore from '../stores/CommonStore';
import CommonProjectDrawerComponent from './CommonProjectDrawerComponent';
import CommonProjectDrawerFormComponent from './CommonProjectDrawerFormComponent';
import ProjectModel from 'shared/models/project/ProjectModel';

interface Props {
  commonStore?: commonStore;
  labelYn?: boolean;
  codeYn?: boolean;
  nameYn?: boolean;
  searchYn?: boolean;
  searchPattern?: string;
  pjStatus?: string;
}

@inject('commonStore')
@observer
class CommonProjectSearchComponent extends React.Component<Props> {
  labelYn: boolean;
  codeYn: boolean;
  nameYn: boolean;
  searchYn: boolean;
  searchPattern: string;
  pjStatus: string;

  constructor(props: any) {
    super(props);
    this.labelYn = true;
    this.codeYn = true;
    this.nameYn = true;
    this.searchYn = true;
    this.searchPattern = this.props.searchPattern! ? this.props.searchPattern! : 'all';
    this.pjStatus = this.props.pjStatus! ? this.props.pjStatus! : '';
  }

  setVisibleDrawer = (visible: boolean) => {
    this.props.commonStore!.setVisibleDrawer(visible);
  };

  showDrawer = () => {
    this.props.commonStore!.setVisibleDrawer(true);
  };

  onChange = (e: any) => {
    e.preventDefault();
    this.handleInitProjectValue();
  };

  handleInitProjectValue = () => {
    const initModel = new ProjectModel({
      pjCode: '',
      pjName: ''
    });
    this.props.commonStore!.setReturnPjValue(initModel);
  };

  render() {
    const { returnPjCode } = this.props.commonStore!;
    const { returnPjName } = this.props.commonStore!;

    const Label = () => {
      this.labelYn = this.props.labelYn ? this.props.labelYn : false;
      if (this.labelYn) {
        return <Form.Item label={<FormattedMessage id="label.titie.project" />} style={{ marginBottom: 0 }} />;
      }
      return <></>;
    };
    const InputCode = () => {
      this.codeYn = this.props.codeYn ? this.props.codeYn : false;
      if (this.codeYn) {
        return (
          <Input
            // disabled
            placeholder="Project Code"
            value={returnPjCode}
            onClick={this.showDrawer}
            onChange={this.onChange}
            allowClear
          />
        );
      }
      return <></>;
    };

    const InputName = () => {
      this.nameYn = this.props.nameYn ? this.props.nameYn : false;
      if (this.nameYn) {
        return <Input placeholder="Project Name" value={returnPjName} onClick={this.showDrawer} onChange={this.onChange} allowClear />;
      }
      return <></>;
    };

    const SearchButton = () => {
      this.searchYn = this.props.searchYn ? this.props.searchYn : false;
      if (this.searchYn) {
        return (
          <Col>
            <Button shape="circle" icon="search" onClick={this.showDrawer} style={{ display: 'inline-block', marginRight: 12 }} />
          </Col>
        );
      }
      return <></>;
    };

    return (
      <div>
        <div style={{ display: 'flex' }}>
          <Label />
          <InputCode />
          <InputName />
          <SearchButton />
        </div>

        <CommonProjectDrawerComponent
          widthDrawer={600}
          titleDrawer=""
          {...this.props}
          visibleProps={this.props.commonStore!.visibleDrawer}
          setVisibleDrawer={this.setVisibleDrawer}
          searchPattern={this.searchPattern}
        >
          <CommonProjectDrawerFormComponent searchPattern={this.searchPattern} />
        </CommonProjectDrawerComponent>
        {/* </IntlProvider> */}
      </div>
    );
  }
}

export default CommonProjectSearchComponent;
