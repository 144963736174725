import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import CommonProjectMobileStore from '../../common/stores/CommonProjectMobileStore';
import OvertimeAppMobileStore from '../stores/OvertimeAppMobileStore';
import AppMobileSearchContainer from '../containers/AppMobileSearchContainer';
import AppMobileBodyContainer from '../containers/AppMobileBodyContainer';
import ProjectModel from 'shared/models/project/ProjectModel';
import { Empty } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface Props extends RouteComponentProps {
  overtimeAppMobileStore?: OvertimeAppMobileStore;
  commonProjectMobileStore?: CommonProjectMobileStore;
  history: any;
}

const Wrapper = styled.div`
  padding-top: 5px;
  color: #000000;
  text-align: left;
`;

@inject('commonProjectMobileStore')
@inject('overtimeAppMobileStore')
@observer
class AppMobileWrapperContainer extends Component<Props> {
  componentDidMount() {
    if (this.props.overtimeAppMobileStore!.nowWorkSysYearMonth === '0') {
      this.props.overtimeAppMobileStore!.getNowWorkSysWeekNumber();
    }

    const projectCode = new ProjectModel({
      no: 0,
      corpCode: '',
      pjCode: '',
      mainSalesDept: '',
      mainPerformDept: '',
      pjName: '',
      pmEmpNo: '',
      pmEmpNm: '',
      pjStartDate: '',
      pjEndDate: '',
      pjStatus: '',
      pjType: '',
      rowStatus: ''
    });

    this.props.commonProjectMobileStore!.setReturnPjValue(projectCode);

    localStorage.removeItem('overtimeAppDetailCorpCode');
    localStorage.removeItem('overtimeAppDetailEmpNo');
    localStorage.removeItem('overtimeAppDetailEmpNm');
    localStorage.removeItem('overtimeAppDetailYyyy');
    localStorage.removeItem('overtimeAppDetailMm');
    localStorage.removeItem('overtimeAppDetailPjCode');
    localStorage.removeItem('overtimeAppDetailAllApprYn');
    localStorage.removeItem('overtimeAppDetailClosingYn');
    localStorage.removeItem('overtimeAppDetailCountsAppr');
    localStorage.removeItem('overtimeAppDetailDeptNm');
    localStorage.removeItem('overtimeAppDetailRowStatus');
    localStorage.removeItem('overtimeAppDetailAfterAverageOverTime');
    localStorage.removeItem('overtimeAppDetailBeforeAverageOverTime');
    localStorage.removeItem('overtimeAppDetailNo');
  }

  forwardDetailView = () => {
    this.props.history.push('/m/timesheet/overtime/overtimeapp/detail');
  };

  render() {
    return (
      <>
        <Wrapper>
          <AppMobileSearchContainer />
          {this.props.overtimeAppMobileStore!.gridData.length > 0 ? (
            <>
              <AppMobileBodyContainer forwardDetailView={this.forwardDetailView} />
            </>
          ) : (
            <div style={{ paddingTop: '50px' }}>
              <Empty />
            </div>
          )}
        </Wrapper>
      </>
    );
  }
}

export default withRouter(AppMobileWrapperContainer);
