/* eslint-disable prefer-destructuring */
import { observable, action, toJS, computed } from 'mobx';
import moment from 'moment';
import { RootStore } from 'shared/stores/RootStore';
import OverTimeSumModel from '../static/OverTimeSumModel';
import OverTimeMonthModel from '../static/OverTimeMonthModel';

import OvertimeMonthStateService from '../services/OvertimeMonthStateService';

export default class OvertimeMonthStateStore {
  @observable
  inProgress: boolean;

  @observable
  searchDeptCode: string | undefined;

  @observable
  searchDeptName: string | undefined;

  @observable
  seletedPjCode: string;

  @observable
  selectYyyymm: string;

  @observable
  selectEmpNm: string;

  @observable
  detailCorpCode: string;

  @observable
  detailMm: string;

  @observable
  detailYyyy: string;

  @observable
  detailEmpNo: string;

  @observable
  detailEmpNm: string;

  @observable
  detailPjCode: string;

  @observable
  workTypeName = '';

  @observable
  workType = '';

  @observable
  gridData: OverTimeSumModel[];

  @observable
  detailOverTimeSum: OverTimeSumModel = {
    rowStatus: '',
    no: '',
    corpCode: '',
    yyyy: '',
    mm: '',
    empNo: '',
    empNm: '',
    deptNm: '',
    pjCode: '',
    beforeAverageOverTime: 0,
    afterAverageOverTime: 0,
    closingYn: '',
    countsAppr: '',
    allApprYn: ''
  };

  @observable
  overTimeMonthList: OverTimeMonthModel[];

  @observable
  overTimeMonthApprSum = 0;

  @observable
  overTimeMonthAllSum = 0;

  @observable
  nowWorkSysYearMonth: any = '0';

  @observable
  monthlyClosing = '';

  @observable
  overTimeMaxWeekly = '0';

  @observable
  overtimeSumList = [0, 0, 0, 0, 0, 0];

  @observable
  monthlyTotalHour = 0;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.gridData = [];
    this.seletedPjCode = '';
    this.selectYyyymm = '';
    this.selectEmpNm = '';
    this.detailCorpCode = '';
    this.detailYyyy = '';
    this.detailMm = '';
    this.detailEmpNo = '';
    this.detailEmpNm = '';
    this.detailPjCode = '';
    this.overTimeMonthList = [];
    this.overTimeMonthAllSum = 0;
    this.overTimeMonthApprSum = 0;
    this.monthlyClosing = '';
    this.overTimeMaxWeekly = '0';
    this.overtimeSumList = [0, 0, 0, 0, 0, 0];
    this.monthlyTotalHour = 0;
    this.detailOverTimeSum.allApprYn = 'N';
    this.rootStore = rootStore;
    this.searchDeptCode = '';
    this.searchDeptName = '';
  }

  @action
  setNowWorkSysYearMonth(value: string | null) {
    this.nowWorkSysYearMonth = value;
  }

  @action
  setSelectYyyymm(selectYyyymm: string): any {
    this.selectYyyymm = selectYyyymm;
  }

  @action
  setSelectEmpNm(selectEmpNm: string): any {
    this.selectEmpNm = selectEmpNm;
  }

  @action
  setRecordToDetail(record: OverTimeSumModel): any {
    this.detailCorpCode = record.corpCode;
    this.detailMm = record.mm;
    this.detailYyyy = record.yyyy;
    this.detailEmpNo = record.empNo;
    this.detailEmpNm = record.empNm;
    this.detailPjCode = record.pjCode;
    this.detailOverTimeSum = record;
  }

  @action
  getNowWorkSysWeekNumber(): Promise<void> {
    // 현재주차 가져오기
    this.inProgress = true;
    return OvertimeMonthStateService.getWeekNumber(moment().format('YYYYMMDD'))
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.nowWorkSysYearMonth = data.workSysYearMonth;
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  @action
  getGridData(deptCode: string | undefined, toworkSysYearMonthMonth: string, empName: any): void {
    this.inProgress = true;

    if (deptCode === '' || deptCode === null || deptCode === undefined) {
      deptCode = 'all';
    }
    if (empName === null) {
      empName = '';
    }

    OvertimeMonthStateService.getMonthOverTimeList(deptCode, toworkSysYearMonthMonth, empName)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];

        // reduce No. column + data type
        data.map((v: any, i: any) => {
          v.no = i + 1;
          return null;
        });
        this.gridData = data;

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  getMonthOverTimeAppr(empNo: string, workSysYearMonth: string, pjCode: string): Promise<void> {
    // monthly 달력 가져오기
    this.inProgress = true;

    if (pjCode === null) {
      pjCode = 'all';
    }

    this.nowWorkSysYearMonth = workSysYearMonth;
    this.overTimeMonthApprSum = 0;
    this.overTimeMonthAllSum = 0;

    return OvertimeMonthStateService.getMonthOverTimeAppr(empNo, workSysYearMonth, pjCode)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.overTimeMonthList = data;

        if (data[0].closing === 'Y') {
          this.monthlyClosing = this.rootStore.uiStore.currLanguage.locale === 'ko' ? '(마감)' : '(Close)';
        } else if (data[0].closing === 'N') {
          this.monthlyClosing = '';
        } else if (data[0].closing === 'X') {
          this.monthlyClosing = '';
        }

        let calItem = this.overTimeMonthList.filter(calitem => calitem.weekEngName === 'SAT' && calitem.workSysMonthWeekly === '5');
        if (calItem.length > 0) {
          this.overTimeMaxWeekly = calItem[0].workSysMonthWeekly;
        } else {
          calItem = this.overTimeMonthList.filter(calitem => calitem.weekEngName === 'SAT' && calitem.workSysMonthWeekly === '4');
          this.overTimeMaxWeekly = calItem[0].workSysMonthWeekly;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const Item of this.overTimeMonthList) {
          if (Item.apprSts === 'S' && Item.reducYn === null && Item.leaveYn === null) {
            if (Item.overTimeType === 'OT' && Item.reducYn === null && Item.leaveYn === null) {
              this.overTimeMonthApprSum += Item.overtimeWorkHours;
            } else if (Item.overTimeType === 'AB' && Item.reducYn === null && Item.leaveYn === null) {
              this.overTimeMonthApprSum -= Item.overtimeWorkHours;
            }
          }

          // && Item.closing !=='NP' 조건 추가 시 내 프로젝트 시간만 계산
          if ((Item.apprSts === 'A' || Item.apprSts === 'S') && Item.reducYn === null && Item.leaveYn === null) {
            if (Item.overTimeType === 'OT' && Item.reducYn === null && Item.leaveYn === null) {
              this.overTimeMonthAllSum += Item.overtimeWorkHours;
            } else if (Item.overTimeType === 'AB' && Item.reducYn === null && Item.leaveYn === null) {
              this.overTimeMonthAllSum -= Item.overtimeWorkHours;
            }
          }
        }

        this.overTimeMonthAllSum = 40 + this.overTimeMonthAllSum / Number(this.overTimeMaxWeekly);
        this.overTimeMonthApprSum = 40 + this.overTimeMonthApprSum / Number(this.overTimeMaxWeekly);

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  getWorkType(empNo: string, nowWorkSysYearMonth: string): Promise<void> {
    return OvertimeMonthStateService.getWorkType(empNo, nowWorkSysYearMonth)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];

        if (data.length > 0) {
          this.workTypeName = data[0];
          this.workType = data[1];
        }
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  getMonthlyTotalOverTimeSum(maxWeekly: number, weelyNumber: number, sumHour: number): void {
    this.overtimeSumList[weelyNumber] = sumHour;
    let overtimeSum = 0;
    const lastWeekNumber = maxWeekly;

    this.overtimeSumList.forEach(function overtimeSumListF(item: any) {
      overtimeSum += item;
    });

    const overtimeAvr = overtimeSum / lastWeekNumber;
    const monthlyTotalHour = 40 + overtimeAvr;
    this.monthlyTotalHour = monthlyTotalHour;
  }

  @action
  deleteDepartment(): void {
    this.searchDeptCode = '';
    this.searchDeptName = '';
  }

  // Drawer 영역
  @computed
  get selectedDepartment() {
    this.searchDeptCode = this.rootStore.departmentStore.selectedDept.deptCode;
    this.searchDeptName = this.rootStore.departmentStore.selectedDept.deptName;
    return this.rootStore.departmentStore.selectedDept;
  }

  async fetchDepartmentForSearchPanel() {
    if (toJS(this.rootStore.departmentStore.departmentList.length) === 0) {
      await this.rootStore.departmentStore.searchDepartments({ departmentCode: '', departmentName: '', useYn: 'Y'});
    }
  }

  @action
  reset() {
    this.rootStore.departmentStore.departmentList = [];
    this.gridData = [];
  }
  // Drawer 영역
}
