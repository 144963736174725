import { action, observable } from 'mobx';
import ILogTableStore from 'shared/containers/MobXLogTable/ITableStore';
import HistoryMenuAccessModel from 'shared/models/history/HistoryMenuAccessModel';
import HistoryMenuAccessSearchModel from 'shared/models/history/HistoryMenuAccessSearchModel';
import { RootStore } from 'shared/stores/RootStore';
import HistoryService from 'shared/services/HistoryService';
import uuid from 'uuid';
import AppConst from 'shared/utils/AppConst';

export class HistoryMenuAccessStore implements ILogTableStore<HistoryMenuAccessModel> {
  @observable
  inProgress: boolean;

  rootStore: RootStore;
  @observable
  searchModel: HistoryMenuAccessSearchModel;

  @observable
  dataSource: HistoryMenuAccessModel[];

  /** Actual number of records in DB */
  @observable
  totalCount: number;

  constructor(rootStore: any) {
    this.rootStore = rootStore;
    this.resetStore();
  }

  @action
  export = async () => {
    try {
      const params = this.parseSearchModelToApiParams();
      const response = await HistoryService.exportMenuAccessHistories(params);
      this.rootStore.apiHandleStore.handleApiExport(response);
    } catch (error) {
      this.rootStore.apiHandleStore.handleApiError(error);
    }
  };

  @action
  find = async () => {
    try {
      this.inProgress = true;
      this.dataSource = Array<HistoryMenuAccessModel>();
      const histories = Array<HistoryMenuAccessModel>();
      const params = this.parseSearchModelToApiParams();
      const response = await HistoryService.findMenuAccessHistories(params);
      const { isSuccessful } = response.data.header;
      if (!isSuccessful) {
        this.inProgress = false;
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        return;
      }

      const { empty, totalCount, results } = response.data.data;
      const data = results || [];
      const length = data ? data.length : 0;
      for (let index = 0; index < length; index++) {
        const element = data[index];
        element.no = index + 1;
        element.id = uuid.v4();
        element.changeStatus = AppConst.ActionType.READ;
        histories.push(element);
      }
      this.totalCount = totalCount || 0;
      this.dataSource = histories;
      this.inProgress = false;
    } catch (error) {
      this.inProgress = false;
      this.rootStore.apiHandleStore.handleApiError(error);
    }
  };

  @action
  setDataSource(dataSource: HistoryMenuAccessModel[]) {
    this.dataSource = dataSource;
  }

  getDataSource() {
    return this.dataSource;
  }

  /**
   * @Override
   */
  resetSearchModel() {
    this.searchModel = {
      userName: '',
      empNo: '',
      offset: AppConst.LOG_DEFAULT_OFFSET,
      limit: AppConst.LOG_TABLE_PAGE_SIZE,
      menuName: '',
      usedDateFrom: 0,
      usedDateTo: 0
    };
  }

  parseSearchModelToApiParams = () => {
    const { userName, empNo, offset, menuName, usedDateFrom, usedDateTo } = this.searchModel;
    return {
      userName,
      empNo,
      usedDateFrom: this.trimDate(usedDateFrom),
      usedDateTo: this.trimDate(usedDateTo),
      menuName,
      offset,
      limit: AppConst.LOG_TABLE_PAGE_SIZE
    };    
  };

  trimDate = (dateValue: number) => {
    return dateValue > 0 ? dateValue : '';
  };

  /**
   * @Override
   */
  @action
  resetStore = () => {
    this.totalCount = 0;
    this.setDataSource(Array<HistoryMenuAccessModel>());
    this.resetSearchModel();
  };

  @action
  trackMenuAccess = async (menuCode: string) => {
    try {
      const params = {
        menuCode
      };
      const response = await HistoryService.trackMenuAccess(params);
      const { isSuccessful } = response.data.header;
      if (!isSuccessful) {
        this.inProgress = false;
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        return;
      }
    } catch (error) {
      this.rootStore.apiHandleStore.handleApiError(error);
    }
  };
}
