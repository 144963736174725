import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import { Button, Modal } from 'antd-mobile';
import { Icon } from 'antd';
import moment from 'moment';
import OvertimeAppMobileStore from '../stores/OvertimeAppMobileStore';
import OverTimeMonthModel from '../static/OverTimeMonthModel';
import MobileLanguageHelper from 'shared/utils/MobileLanguageHelper';

const { alert } = Modal;

interface Props {
  overtimeAppMobileStore?: OvertimeAppMobileStore;
  headerVisible: boolean;
  monthlyVisible: boolean;
  onChangeMonthlyVisible: (a: boolean) => void;
  onClickGoToList: () => void;
}

const HeaderWrapper = styled.div`
  height: 40px;
  /* margin-top: 45px; */
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: #ffffff;
`;

const HeaderVisibleWrapper = styled.div<{ visible: boolean }>`
  display: ${props => (props.visible ? 'display' : 'none')};
  background-color: #ffffff;
`;

const SimpleHeaderWrapper = styled.div`
  text-align: right;
  padding: 5px 10px 0 0;
`;

const HeaderRow = styled.div`
  display: flex;
  /* justify-content: flex-start; */
  font-size: 14px;
  background-color: #ffffff;
  align-items: top;
`;

const HeaderColLabel = styled.div`
  padding: 5px;
  width: 100px;
  font-weight: 700;
`;

const PjNameEllipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 1.4em;
  height: 1.5em;
`;

const HeaderCol = styled.div`
  padding: 5px 0;
`;

const HeaderSummaryWrapper = styled.div`
  background-color: #ffffff;
  padding: 10px 0;
  /* margin-top: 5px; */
`;

const HeaderSummaryTitle = styled.div`
  text-align: center;
  font-weight: 700;
  margin-bottom: 5px;
`;

const HeaderSummaryContentsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

const HeaderSummaryContents = styled.div``;

const RenderHours = styled.span`
  font-size: 16px;
  font-weight: 700;
`;

const MonthlyCalendarWrapper = styled.div`
  padding: 10px 15px;
  background-color: #ffffff;
  text-align: center;
  font-size: 12px;
`;

const MonthlyCalendarHeader = styled.div`
  font-weight: 600;
  border-top: 1px solid #818181;
  border-bottom: 1px solid #818181;
  display: flex;
  justify-content: space-between;
`;

const MonthlyCalendarWeekName = styled.div`
  height: 20px;
  width: 20px;
`;

const MonthlyClaendarBody = styled.div`
  border-bottom: 1px solid #818181;
`;

const MonthlyCalendarRow = styled.div`
  font-weight: 600;
  display: flex;
  justify-content: space-between;
`;

const MonthlyCalendarCell = styled.div`
  height: 40px;
`;

const MonthlyCalendarRenderDate = styled.div<{ sts: string | null }>`
  /* border: ${props => (props.sts === 'A' ? '1px solid #0000ff' : '0px')}; */
  background-color: ${props => (props.sts === 'A' ? '#FAF4C0' : '#ffffff')};
  border-radius: 9px;
  width: 20px;
  height: 20px;
`;

const MonthlyCalendarRenderOvertimeHours = styled.div<{ type: string }>`
  color: ${props => (props.type === 'AB' ? '#77a16f' : '#7b7cb4')};
  font-weight: 500;
  width: 20px;
  height: 20px;
`;

@inject('overtimeAppMobileStore')
@observer
class AppDetailMobileHeaderContainer extends Component<Props> {
  handleAllAppr = (): void => {
    this.props.overtimeAppMobileStore!.allApprMonthOverTime(this.props.overtimeAppMobileStore!.detailOverTimeSum);
  };

  monthlySummayWrapper = () => {
    this.props.onChangeMonthlyVisible(!this.props.monthlyVisible);
  };

  renderMonthlyCalendar = (overTimeMonthList: OverTimeMonthModel[]): JSX.Element[] => {
    let components: JSX.Element[] = [];
    let weekNumber = '0';
    let maxWeekNumber = '0';

    overTimeMonthList.forEach(item => {
      maxWeekNumber = item.workSysMonthWeekly;
    });

    for (let i = 0; i < Number(maxWeekNumber); i++) {
      const thisWeek = overTimeMonthList.filter(item => Number(item.workSysMonthWeekly) === i + 1);
      components.push(
        <MonthlyCalendarRow>
          {thisWeek.map(item => {
            return (
              <MonthlyCalendarCell key={'calendarcell' + item.yyyymmdd}>
                <MonthlyCalendarRenderDate sts={item.apprSts}>{moment(item.yyyymmdd, 'YYYYMMDD').format('DD')}</MonthlyCalendarRenderDate>
                <MonthlyCalendarRenderOvertimeHours type={item.overTimeType}>
                  {item.apprSts === 'A' || item.apprSts === 'S' ? (item.overTimeType === 'AB' ? `-${item.overtimeWorkHours}` : item.overtimeWorkHours) : ''}
                </MonthlyCalendarRenderOvertimeHours>
              </MonthlyCalendarCell>
            );
          })}
        </MonthlyCalendarRow>
      );
    }
    return components;
  };

  render() {
    const { overTimeMonthList } = this.props.overtimeAppMobileStore!;
    return (
      <>
        <HeaderWrapper>
          <HeaderRow>
            <Button type="ghost" size="small" style={{ marginTop: '10px', marginRight: '10px' }} onClick={() => this.props.onClickGoToList()}>
              {MobileLanguageHelper.getMessage('overtime.button.title.tolist')}
            </Button>
            {this.props.overtimeAppMobileStore!.detailOverTimeSum.allApprYn === 'Y' ? (
              <Button
                type="primary"
                inline
                size="small"
                style={{ marginTop: '10px' }}
                onClick={() =>
                  alert(MobileLanguageHelper.getMessage('overtime.button.title.apprall'), MobileLanguageHelper.getMessage('overtime.confirm.message.approval'), [
                    { text: MobileLanguageHelper.getMessage('overtime.button.title.cancel') },
                    { text: MobileLanguageHelper.getMessage('overtime.button.title.approval'), onPress: () => this.handleAllAppr() }
                  ])
                }
              >
                {this.props.overtimeAppMobileStore!.detailPjCode === null || this.props.overtimeAppMobileStore!.detailPjCode === 'null'
                  ? MobileLanguageHelper.getMessage('overtime.button.title.apprall')
                  : `${this.props.overtimeAppMobileStore!.detailPjCode} ${MobileLanguageHelper.getMessage('overtime.button.title.apprall')}`}
              </Button>
            ) : (
              <Button type="primary" inline size="small" style={{ marginTop: '10px' }} disabled>
                {this.props.overtimeAppMobileStore!.detailPjCode === null || this.props.overtimeAppMobileStore!.detailPjCode === 'null'
                  ? MobileLanguageHelper.getMessage('overtime.button.title.apprall')
                  : `${this.props.overtimeAppMobileStore!.detailPjCode} ${MobileLanguageHelper.getMessage('overtime.button.title.apprall')}`}
              </Button>
            )}

            <HeaderVisibleWrapper visible={!this.props.headerVisible} style={{ marginLeft: 'auto' }}>
              <SimpleHeaderWrapper>
                {/* <div style={{ marginRight: '10px' }}>
                  {this.props.overtimeAppMobileStore!.detailYyyy}년 {this.props.overtimeAppMobileStore!.detailMm}월
                </div> */}
                <div>
                  {this.props.overtimeAppMobileStore!.detailYyyy}년 {this.props.overtimeAppMobileStore!.detailMm}월<br />
                  {this.props.overtimeAppMobileStore!.detailEmpNm}
                </div>
              </SimpleHeaderWrapper>
            </HeaderVisibleWrapper>
          </HeaderRow>
          <HeaderVisibleWrapper visible={this.props.headerVisible}>
            <HeaderRow>
              <HeaderColLabel>년월</HeaderColLabel>
              <HeaderCol>
                {this.props.overtimeAppMobileStore!.detailYyyy}년 {this.props.overtimeAppMobileStore!.detailMm}월
              </HeaderCol>
            </HeaderRow>
            <HeaderRow>
              <HeaderColLabel>대상자</HeaderColLabel>
              <HeaderCol>
                <PjNameEllipsis>{this.props.overtimeAppMobileStore!.detailEmpNm}</PjNameEllipsis>
              </HeaderCol>
            </HeaderRow>
            <HeaderRow>
              <HeaderColLabel>근로제</HeaderColLabel>
              <HeaderCol>
                <PjNameEllipsis>{this.props.overtimeAppMobileStore!.workTypeName}</PjNameEllipsis>
              </HeaderCol>
            </HeaderRow>
          </HeaderVisibleWrapper>

          <HeaderSummaryWrapper onClick={() => this.monthlySummayWrapper()}>
            <HeaderSummaryTitle>{MobileLanguageHelper.getMessage('overtime.card.title.workinghoursavg')}</HeaderSummaryTitle>
            <HeaderSummaryContentsWrapper>
              <HeaderSummaryContents>
                {MobileLanguageHelper.getMessage('overtime.card.title.beforeappr')} <RenderHours>{this.props.overtimeAppMobileStore!.overTimeMonthApprSum}</RenderHours>
                {MobileLanguageHelper.getMessage('overtime.header.title.hours')}
              </HeaderSummaryContents>
              <HeaderSummaryContents>
                {MobileLanguageHelper.getMessage('overtime.card.title.afterappr')} <RenderHours>{this.props.overtimeAppMobileStore!.overTimeMonthAllSum}</RenderHours>
                {MobileLanguageHelper.getMessage('overtime.header.title.hours')}
              </HeaderSummaryContents>
            </HeaderSummaryContentsWrapper>
            {!this.props.monthlyVisible ? (
              <div style={{ textAlign: 'center', color: '#b7b7b7', fontSize: '12px' }}>
                <Icon type="down-circle" /> 달력 펼치기
              </div>
            ) : (
              <div style={{ textAlign: 'center', color: '#b7b7b7', fontSize: '12px' }}>
                <Icon type="up-circle" /> 달력 접기
              </div>
            )}
          </HeaderSummaryWrapper>

          {this.props.monthlyVisible ? (
            <MonthlyCalendarWrapper>
              <MonthlyCalendarHeader>
                <MonthlyCalendarWeekName style={{ color: '#ff0000' }}>토</MonthlyCalendarWeekName>
                <MonthlyCalendarWeekName style={{ color: '#ff0000' }}>일</MonthlyCalendarWeekName>
                <MonthlyCalendarWeekName>월</MonthlyCalendarWeekName>
                <MonthlyCalendarWeekName>화</MonthlyCalendarWeekName>
                <MonthlyCalendarWeekName>수</MonthlyCalendarWeekName>
                <MonthlyCalendarWeekName>목</MonthlyCalendarWeekName>
                <MonthlyCalendarWeekName>금</MonthlyCalendarWeekName>
              </MonthlyCalendarHeader>
              <MonthlyClaendarBody>{this.renderMonthlyCalendar(overTimeMonthList)}</MonthlyClaendarBody>
            </MonthlyCalendarWrapper>
          ) : (
            <div />
          )}
        </HeaderWrapper>
      </>
    );
  }
}

export default AppDetailMobileHeaderContainer;
