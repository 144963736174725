import { observable, computed } from 'mobx';
import koKR from 'antd/es/locale/ko_KR';
import enUS from 'antd/es/locale/en_US';
import StorageHelper from 'shared/utils/StorageHelper';
import AppConst from 'shared/utils/AppConst';
import { MobileRootStore } from './RootStore';

export class UIStore {
  @observable
  currLanguage: any;

  rootStore: MobileRootStore;

  constructor(rootStore: MobileRootStore) {
    // Default language for ConfigProvider only
    this.currLanguage = StorageHelper.getItem(StorageHelper.KEY_CURR_LANGUAGE) === AppConst.LANG_ENUS ? enUS : koKR;
    this.rootStore = rootStore;
  }

  setCurrentLanguage(value: any) {
    this.currLanguage = value;
  }

  @computed get pendingLoading(): number {
    let countLoading = 0;
    if (this.rootStore.authStore.inProgress) countLoading += 1;
    if (this.rootStore.userInfoStore.inProgress) countLoading += 1;
    if (this.rootStore.mobileHeaderSidebarStore.inProgress) countLoading += 1;
    if (this.rootStore.utilizationResultCalendarMobileStore.inProgress) countLoading += 1;
    if (this.rootStore.utilizationResultAppMobileStore.inProgress) countLoading += 1;
    if (this.rootStore.commonProjectMobileStore.inProgress) countLoading += 1;
    if (this.rootStore.overtimeCalendarMobileStore.inProgress) countLoading += 1;
    if (this.rootStore.overtimeAppMobileStore.inProgress) countLoading += 1;

    return countLoading;
  }
}
