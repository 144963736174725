import ApiHelper from 'shared/utils/ApiHelper';
import AppConst from 'shared/utils/AppConst';
import BaseRepository from 'shared/base/repositories/BaseRepository';
import ProgramModel from 'shared/models/program/ProgramModel';

class ProgramService extends BaseRepository<ProgramModel> {
  constructor() {
    super(AppConst.API.SYS_PROGRAM);
  }

  findByConditions = (largeClass: string, mediumClass: string, progCode: string, progName: string, progFileName: string) => {
    const params = {
      progCode,
      progName,
      progFileName,
      mediumClass,
      largeClass
    };
    return ApiHelper.get(AppConst.API.SYS_PROGRAM, params);
  };

  getApiAssignment = (progCode: string) => {
    const params = {
      progCode
    };
    return ApiHelper.get(AppConst.API.SYS_PROGRAM_API, params);
  };
}

export default new ProgramService();
