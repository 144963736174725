/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { observable, action } from 'mobx';
import { message } from 'antd';
import moment from 'moment';
import { RootStore } from 'shared/stores/RootStore';
import { async } from 'q';
import CalendarModel from '../static/CalendarModel';
import OverTimeMonthModel from '../static/OverTimeMonthModel';
import OverTimeWeeklySumModel from '../static/OverTimeWeeklySumModel';
import OverTimeModel from '../static/OverTimeModel';
import OvertimeMonthRegService from '../services/OvertimeMonthRegService';

export default class OvertimeMonthRegStore {
  rootStore: RootStore;

  @observable
  inProgress: boolean;

  @observable
  preWeekNumber = '0';

  @observable
  nowWeekNumber = '0';

  @observable
  nextWeekNumber = '0';

  @observable
  empNo = 'me';

  @observable
  monthlyTotalHour = 0;

  @observable
  monthlyClosing = '';

  @observable
  overtimeSumList = [0, 0, 0, 0, 0, 0];

  @observable
  overTimeMaxWeekly = '0';

  @observable
  workTypeName = '';

  @observable
  workType = '';

  @observable
  preWorkSysYearMonth = '0';

  @observable
  nowWorkSysYearMonth = '0';

  @observable
  nowWorkSysYearMonthKr: string;

  @observable
  nowWorkSysYearMonthEn: string;

  @observable
  nextWorkSysYearMonth = '0';

  @observable
  monthCalendarList: CalendarModel[];

  @observable
  overTimeMonthList: OverTimeMonthModel[];

  @observable
  overTimeWeeklySumList: OverTimeWeeklySumModel[];

  @observable
  overTimeModel: OverTimeModel;

  @observable
  popOverVisible: boolean;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.monthCalendarList = [];
    this.overTimeMonthList = [];
    this.overTimeWeeklySumList = [];
    this.empNo = 'me';
    this.monthlyTotalHour = 0;
    this.monthlyClosing = '';
    this.workTypeName = '';
    this.workType = 'X';
    this.overTimeMaxWeekly = '0';
    this.overtimeSumList = [0, 0, 0, 0, 0, 0];
    this.popOverVisible = true;
  }

  @action
  setPopOverVisible(visible: boolean): any {
    this.popOverVisible = visible;
  }

  @action
  async getNowWorkSysWeekNumber(): Promise<void> {
    // 현재주차 가져오기
    this.inProgress = true;
    try {
      const response = await OvertimeMonthRegService.getWeekNumber(moment().format('YYYYMMDD'));
      this.rootStore.apiHandleStore.handleApiFail(response.data.header);
      const data = response.data.data || [];
      this.nowWeekNumber = data.yearWeekly;
      this.preWeekNumber = (Number(data.yearWeekly) - 1).toString();
      this.nextWeekNumber = (Number(data.yearWeekly) + 1).toString();
      this.nowWorkSysYearMonth = data.workSysYearMonth;
      this.setNowWorkSysYearMonthText();
      this.preWorkSysYearMonth = (Number(data.workSysYearMonth) - 1).toString();
      this.nextWorkSysYearMonth = (Number(data.workSysYearMonth) + 1).toString();
      this.getMonthOverTime(data.workSysYearMonth);
      this.getWorkType(this.empNo, data.workSysYearMonth);
      this.inProgress = false;
    } catch (error) {
      this.rootStore.apiHandleStore.handleApiError(error);
      this.inProgress = false;
    }
  }

  // 한글 영문 변환용 Text 셋팅
  setNowWorkSysYearMonthText() {
    this.nowWorkSysYearMonthEn = moment(this.nowWorkSysYearMonth, 'YYYYMM').format('MMMM');
    this.nowWorkSysYearMonthKr = `${moment(this.nowWorkSysYearMonth, 'YYYYMM').format('MM')}월`;
  }

  @action
  async getMonthOverTime(workSysYearMonth: string): Promise<void> {
    // monthly 달력 가져오기
    this.inProgress = true;

    this.nowWorkSysYearMonth = workSysYearMonth;
    this.preWorkSysYearMonth = moment(workSysYearMonth, 'YYYYMM')
      .add(-1, 'months')
      .format('YYYYMM')
      .toString();
    this.nextWorkSysYearMonth = moment(workSysYearMonth, 'YYYYMM')
      .add(1, 'months')
      .format('YYYYMM')
      .toString();

    this.setNowWorkSysYearMonthText();

    try {
      const response = await OvertimeMonthRegService.getMonthOverTime(workSysYearMonth);
      this.rootStore.apiHandleStore.handleApiFail(response.data.header);
      const data = response.data.data || [];
      this.overTimeMonthList = data;

      let count = 0;

      // reduce No. column + data type
      data.map((v: any, i: any) => {
        if (v.closing === 'N') {
          count += 1;
        }
        return null;
      });

      if (count > 0) {
        this.monthlyClosing = '';
      } else {
        this.monthlyClosing = '';
      }

      // if (data[0].closing === 'Y') {
      //   this.monthlyClosing = '(마감)';
      // } else if (data[0].closing === 'N') {
      //   this.monthlyClosing = '';
      // } else if (data[0].closing === 'X') {
      //   this.monthlyClosing = '';
      // }
      let calItem = this.overTimeMonthList.filter(calitem => calitem.weekEngName === 'SAT' && calitem.workSysMonthWeekly === '5');
      if (calItem.length > 0) {
        this.overTimeMaxWeekly = calItem[0].workSysMonthWeekly;
      } else {
        calItem = this.overTimeMonthList.filter(calitem => calitem.weekEngName === 'SAT' && calitem.workSysMonthWeekly === '4');
        this.overTimeMaxWeekly = calItem[0].workSysMonthWeekly;
      }

      this.getWorkType(calItem[0].empNo, workSysYearMonth);
      this.setPopOverVisible(true);
      this.inProgress = false;
    } catch (error) {
      this.rootStore.apiHandleStore.handleApiError(error);
      this.inProgress = false;
    }
  }

  @action
  async getWorkType(empNo: string, nowWorkSysYearMonth: string): Promise<void> {
    try {
      const response = await OvertimeMonthRegService.getWorkType(empNo, nowWorkSysYearMonth);
      this.rootStore.apiHandleStore.handleApiFail(response.data.header);
      const data = response.data.data || [];
      if (data.length > 0) {
        this.workTypeName = data[0];
        this.workType = data[1];
      }
    } catch (error) {
      this.rootStore.apiHandleStore.handleApiError(error);
      this.inProgress = false;
    }
  }

  @action
  getOverTimeCheck(overTimeType: any, overtimeWorkHours: any, overTimeYmd: any) {
    const overTimeCheck: OverTimeMonthModel[] | undefined = this.overTimeMonthList;
    const weekNumbers = ['SAT', 'SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI'];
    const workSysMonthWeekly = ['1', '2', '3', '4', '5'];
    const { workType } = this;
    let weeklyCheck: any | undefined = null;
    let MonthSum = 0;
    let checkString: string | null = null;

    workSysMonthWeekly.forEach(function MonthWeekly(weeklyItem) {
      let overTimeWeekAllSum = 0;
      weekNumbers.forEach(function weekNumber(weekEngitem) {
        const calItem = overTimeCheck.filter(calitem => calitem.weekEngName === weekEngitem && calitem.workSysMonthWeekly === weeklyItem);

        if (calItem.length > 0) {
          weeklyCheck = weeklyItem;

          if (calItem[0].overTimeYmd === overTimeYmd) {
            if (overTimeType === 'OT') {
              overTimeWeekAllSum += overtimeWorkHours;
            } else if (calItem[0].overTimeType === 'AB') {
              overTimeWeekAllSum -= overtimeWorkHours;
            }
          } else if ((calItem[0].apprSts === 'A' || calItem[0].apprSts === 'S') && calItem[0].reducYn == null) {
            if (calItem[0].overTimeType === 'OT') {
              overTimeWeekAllSum += calItem[0].overtimeWorkHours;
            } else if (calItem[0].overTimeType === 'AB') {
              overTimeWeekAllSum -= calItem[0].overtimeWorkHours;
            }
          }
        }
      });
      if (overTimeWeekAllSum > 24 && workType === 'B') {
        checkString = 'weekOver';
      }

      MonthSum += overTimeWeekAllSum;
      console.log(overTimeWeekAllSum);
    });

    MonthSum /= Number(weeklyCheck);
    console.log(`MonthSum : ${MonthSum}`);
    console.log(`weeklyCheck : ${weeklyCheck}`);

    if (MonthSum > 12) {
      return 'monthOver';
    }
    if (checkString === 'weekOver') {
      return 'weekOver';
    }
    return 'OK';
  }

  @action
  getOverTimeMonthListMod(overTimeType: any, overtimeWorkHours: any, overTimeYmd: any, pjCode: any) {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of this.overTimeMonthList) {
      if (item.overTimeYmd === overTimeYmd) {
        item.overTimeType = overTimeType;
        item.overtimeWorkHours = overtimeWorkHours;
        item.apprSts = 'A';
        item.pjCode = pjCode;
      }
    }
  }

  @action
  getMonthlyTotalOverTimeSum(maxWeekly: number, weelyNumber: number, sumHour: number) {
    this.overtimeSumList[weelyNumber] = sumHour;
    let overtimeSum = 0;
    const lastWeekNumber = maxWeekly;

    this.overtimeSumList.forEach(function overtimeSumListF(item) {
      overtimeSum += item;
    });

    const overtimeAvr = overtimeSum / lastWeekNumber;
    const monthlyTotalHour = 40 + overtimeAvr;
    this.monthlyTotalHour = monthlyTotalHour;
  }

  @action
  async createOverTime(overtime: OverTimeMonthModel) {
    // 연장근로 등록
    this.inProgress = true;
    const overtimeItem: OverTimeModel = {
      rowStatus: overtime.rowStatus,
      corpCode: overtime.corpCode,
      empNo: overtime.empNo,
      overTimeYmd: overtime.overTimeYmd,
      overTimeType: overtime.overTimeType,
      pjCode: overtime.pjCode,
      overtimeWorkHours: overtime.overtimeWorkHours,
      apprSts: overtime.apprSts
    };

    try {
      const response = await OvertimeMonthRegService.createOverTime(overtimeItem);
      this.rootStore.apiHandleStore.handleApiFail(response.data.header);
      this.inProgress = false;
    } catch (error) {
      this.rootStore.apiHandleStore.handleApiError(error);
      this.inProgress = false;
      message.error('저장 중 문제가 발생하였습니다.');
    }
  }

  @action
  async updateOverTime(overtime: OverTimeMonthModel) {
    // 연장근로 등록
    this.inProgress = true;
    const overtimeItem = {
      rowStatus: overtime.rowStatus,
      corpCode: overtime.corpCode,
      empNo: overtime.empNo,
      overTimeYmd: overtime.overTimeYmd,
      overTimeType: overtime.overTimeType,
      pjCode: overtime.pjCode,
      overtimeWorkHours: overtime.overtimeWorkHours,
      apprSts: overtime.apprSts
    };

    try {
      const response = await OvertimeMonthRegService.updateOverTime(overtimeItem);
      this.rootStore.apiHandleStore.handleApiFail(response.data.header);
      this.inProgress = false;
    } catch (error) {
      this.rootStore.apiHandleStore.handleApiError(error);
      this.inProgress = false;
      message.error('저장 중 문제가 발생하였습니다.');
    }
  }

  @action
  async deleteOverTime(empNo: string, overTimeYmd: string, overTimeType: string) {
    // 연장근로 등록
    this.inProgress = true;

    try {
      const response = await OvertimeMonthRegService.deleteOverTime(empNo, overTimeYmd, overTimeType);
      this.rootStore.apiHandleStore.handleApiFail(response.data.header);
      this.inProgress = false;
    } catch (error) {
      this.rootStore.apiHandleStore.handleApiError(error);
      this.inProgress = false;
      message.error('저장 중 문제가 발생하였습니다.');
    }
  }

  @action
  getMeWeeklyOverTimeSum(overtimeMonthList: OverTimeMonthModel[]) {
    const weekNumbers = ['SAT', 'SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI'];
    const workSysMonthWeekly = ['1', '2', '3', '4', '5'];
    const sumList: OverTimeWeeklySumModel[] | undefined = [];
    // this.monthlyTotalHour = 40;

    workSysMonthWeekly.forEach(function MonthWeekly(weeklyItem) {
      let overCorpCode = '';
      let overEmpNo = '';
      let overWeekly = '';
      let overTimeWeekAllOtSum = 0;
      let overTimeWeekApprOtSum = 0;
      let overTimeWeekAllAbSum = 0;
      let overTimeWeekApprAbSum = 0;
      let overTimeWeekAllSum = 0;
      let overTimeWeekApprSum = 0;
      weekNumbers.forEach(function weekNumber(weekEngitem) {
        const calItem = overtimeMonthList.filter(calitem => calitem.weekEngName === weekEngitem && calitem.workSysMonthWeekly === weeklyItem);

        if (calItem.length > 0) {
          overCorpCode = calItem[0].corpCode;
          overEmpNo = calItem[0].empNo;
          overWeekly = weeklyItem;
          if (calItem[0].apprSts === 'S' && calItem[0].reducYn == null) {
            if (calItem[0].overTimeType === 'OT' && calItem[0].reducYn == null) {
              overTimeWeekApprOtSum += calItem[0].overtimeWorkHours;
              overTimeWeekApprSum += calItem[0].overtimeWorkHours;
            } else if (calItem[0].overTimeType === 'AB' && calItem[0].reducYn == null) {
              overTimeWeekApprAbSum -= calItem[0].overtimeWorkHours;
              overTimeWeekApprSum -= calItem[0].overtimeWorkHours;
            }
          }

          if ((calItem[0].apprSts === 'A' || calItem[0].apprSts === 'S') && calItem[0].reducYn == null) {
            if (calItem[0].overTimeType === 'OT' && calItem[0].reducYn == null) {
              overTimeWeekAllOtSum += calItem[0].overtimeWorkHours;
              overTimeWeekAllSum += calItem[0].overtimeWorkHours;
            } else if (calItem[0].overTimeType === 'AB' && calItem[0].reducYn == null) {
              overTimeWeekAllAbSum -= calItem[0].overtimeWorkHours;
              overTimeWeekAllSum -= calItem[0].overtimeWorkHours;
            }
          }
        }
      });

      const sumItem: OverTimeWeeklySumModel = {
        rowStatus: '',
        corpCode: overCorpCode,
        empNo: overEmpNo,
        overTimeWeekAllOtSum,
        overTimeWeekApprOtSum,
        overTimeWeekAllAbSum,
        overTimeWeekApprAbSum,
        overTimeWeekAllSum,
        overTimeWeekApprSum,
        workSysMonthWeekly: overWeekly
      };
      sumList.push(sumItem);
    });
    this.overTimeWeeklySumList = sumList;
    // this.getMonthlyTotalOverTimeSum(sumList);
  }

  @action
  getOverTimeMonthListLatestPj() {
    // eslint-disable-next-line no-restricted-syntax
    const calItem = this.overTimeMonthList.filter(calitem => calitem.pjCode != null);
    const items = calItem.sort((a: any, b: any) => b.yyyymmdd - a.yyyymmdd);
    if (items.length > 0) {
      this.rootStore.commonStore.returnPjCode = items[0].pjCode;
      this.rootStore.commonStore.returnPjName = items[0].pjName;
    }
  }
}
