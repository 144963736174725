import { observable, action, computed, toJS } from 'mobx';
import AppHelper from 'shared/utils/AppHelper';
import AppConst from 'shared/utils/AppConst';
import uuid from 'uuid';
import { DepartmentModel } from 'shared/models/department/DepartmentModel';
import _ from 'lodash';
import { RootStore } from 'shared/stores/RootStore';
import TreeNode from 'shared/components/TreeList/TreeNode';
import DepartmentService from '../services/DepartmentService';
import { DepartmentType } from '../static/constant';

export interface ISearchParameter {
  upperDeptCode: string;
  upperDeptType: string;
}

const ROOT_NODE_DEPTCODE = '';
export class DepartmentDrawerStore {
  @observable
  departmentList: DepartmentModel[] = [];

  @observable
  selectedDept: DepartmentModel;

  @observable
  expandedKeys: string[];

  @observable
  expandedDeptCode: string[];

  @observable
  inProgress = false;

  // to log  search parameter => can re-use latter
  @observable
  searchParameter: ISearchParameter;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action
  async searchDepartments(searchParams: ISearchParameter) {
    this.inProgress = true;

    try {
      const apiResult: any = await DepartmentService.searchUpperDepartments(searchParams);
      this.rootStore.apiHandleStore.handleApiFail(apiResult.data.header);

      const arrDepartment = apiResult.data.data.map((value: any) => {
        value.upperDeptCode = value.upperDeptCode ? value.upperDeptCode : ROOT_NODE_DEPTCODE;
        value.deptType = this.getDeptType(value);
        return new DepartmentModel(value);
      });

      this.departmentList = arrDepartment;
      this.searchParameter = searchParams;
    } catch (error) {
      this.rootStore.apiHandleStore.handleApiError(error);
    }
    this.inProgress = false;
  }

  getDeptType = (dept: DepartmentModel) => {
    if (dept.upperDeptCode === ROOT_NODE_DEPTCODE) return DepartmentType.Root;
    if (dept.deptCode === dept.sectCode) return DepartmentType.Sector;
    if (dept.deptCode === dept.divCode) return DepartmentType.Division;
    if (dept.deptCode !== dept.sectCode && dept.deptCode !== dept.divCode) return DepartmentType.Department;
  };

  @computed
  get treeData(): TreeNode[] {
    const { selectedDept } = this.rootStore.departmentStore;

    // Disable selcted a Sector or Root
    const isDisabled = (dept: DepartmentModel) => {
      if (selectedDept.deptType === DepartmentType.Department) {
        return dept.deptType === DepartmentType.Root || dept.deptType === DepartmentType.Sector;
      }
      if (selectedDept.deptType === DepartmentType.Division) {
        return dept.deptType === DepartmentType.Root;
      }
      return false;
    };

    const getNodeText = (deptName: string, deptCode: string) => {
      if (!deptCode) return deptName;
      return deptName ? `${deptName}-${deptCode}` : deptCode;
    };

    return this.departmentList.map(
      x =>
        new TreeNode({
          id: x.id,
          parentCode: x.upperDeptCode,
          valueCode: x.deptCode,
          nodeText: getNodeText(x.deptName, x.deptCode),
          disabled: isDisabled(x)
        })
    );
  }

  @computed
  get treeDepartment(): any[] {
    /**
     * Ref: http://jsfiddle.net/tx3uwhke/
     * @param data
     * @param parent
     */
    function buildTree(data: any, parent?: any): any[] {
      const result: any[] = [];
      parent = typeof parent !== 'undefined' ? parent : { valueCode: ROOT_NODE_DEPTCODE };
      const children = _.filter(data, function(value: TreeNode) {
        return value.parentCode === parent.valueCode;
      });
      if (!_.isEmpty(children)) {
        _.each(children, function(child) {
          if (child != null) {
            result.push(child);
            const ownChildren = buildTree(data, child);
            if (!_.isEmpty(ownChildren)) {
              child.isParent = true;
              child.children = ownChildren;
            } else {
              child.isParent = false;
            }
          }
        });
      }

      return result;
    }

    const tree = buildTree(this.treeData);

    return tree;
  }

  @action
  setSelectedDepartment(deptId: string | number) {
    this.selectedDept = this.departmentList.find(x => x.id === deptId)!;
  }

  @action
  setExpandedKeys(expandedKeys: string[]) {
    this.expandedKeys = expandedKeys;
    this.expandedDeptCode = this.departmentList.filter(x => expandedKeys.includes(x.id)).map(y => y.deptCode);
  }

  @action
  getPreviousKey() {
    if (this.expandedDeptCode) {
      const expandedKeys = this.departmentList.filter(x => this.expandedDeptCode.includes(x.deptCode)).map(y => y.id);
      return expandedKeys;
    }
    return [];
  }
}
