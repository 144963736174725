import React, { Component } from 'react';
import styled from 'styled-components';
import { Button, message, Tooltip } from 'antd';
import { observer, inject } from 'mobx-react';
import OverTimeMonthModel from '../static/OverTimeMonthModel';
import OvertimeMonthApprListStore from '../stores/OvertimeMonthApprListStore';
import commonStore from '../../common/stores/CommonStore';
import { UIStore } from 'shared/stores/UIStore';
import { FormattedMessage } from 'react-intl';

interface Props {
  overtimeMonthApprListStore?: OvertimeMonthApprListStore;
  commonStore?: commonStore;
  overTimeMonthList?: OverTimeMonthModel[];
  date?: OverTimeMonthModel | null;
  uiStore?: UIStore;
}

const overTimeRegOtColor = 'rgb(123,124,180)';
const overTimeRegAbColor = 'rgb(119,161,111)';
const overTimeRegInputNColor = 'rgb(241,243,242)';
const overTimeRegGrayColor = 'rgb(189,190,194)';
const overTimeRegWhiteColor = 'white';

const CalendarCellBg = (overtime: any): any => {
  if (overtime.inputYn === 'N') {
    return overTimeRegInputNColor;
  }
  if (overtime.apprSts === 'A') {
    return overTimeRegWhiteColor;
  }
  if (overtime.apprSts === 'S' && overtime.overTimeType === 'OT') {
    return overTimeRegOtColor;
  }
  if (overtime.apprSts === 'S' && overtime.overTimeType === 'AB') {
    return overTimeRegAbColor;
  }
  if (overtime.apprSts === 'R') {
    return overTimeRegGrayColor;
  }
  return overTimeRegWhiteColor;
};

const CalendarCellColor = (overtime: any): any => {
  if (overtime.leaveYn != null) {
    return 'black';
  }
  if (overtime.reducYn != null) {
    return 'black';
  }
  if (overtime.apprSts === undefined) {
    return overTimeRegGrayColor;
  }
  if (overtime.apprSts === 'A' && overtime.overTimeType === 'OT') {
    return overTimeRegOtColor;
  }
  if (overtime.apprSts === 'A' && overtime.overTimeType === 'AB') {
    return overTimeRegAbColor;
  }
  if (overtime.apprSts === 'S' && overtime.overTimeType === 'OT') {
    return overTimeRegWhiteColor;
  }
  if (overtime.apprSts === 'S' && overtime.overTimeType === 'AB') {
    return overTimeRegWhiteColor;
  }
  if (overtime.apprSts === 'R') {
    return overTimeRegWhiteColor;
  }
  return overTimeRegGrayColor;
};

const CalendarCell = styled.div<{ overtime?: any }>`
  background-color: ${(props: any): any => CalendarCellBg(props.overtime)};
  border-bottom: 1px solid rgb(209, 211, 212);
  height: 125px;
  min-width: 150px;
  padding: 2px 2px 0 2px;
  display: table-cell;
  vertical-align: top;
  cursor: pointer;
  color: ${(props: any): any => CalendarCellColor(props.overtime)};
`;

const CalendarCellNoAppr = styled.div<{ overtime?: any }>`
  background-color: ${(props: any): any => CalendarCellBg(props.overtime)};
  border-bottom: 1px solid rgb(209, 211, 212);
  height: 100px;
  min-width: 150px;
  padding: 2px 2px 0 2px;
  display: table-cell;
  vertical-align: top;
  cursor: pointer;
  color: ${(props: any): any => CalendarCellColor(props.overtime)};
`;

const CalendarWeeklyCell = styled.div<{ displayRate?: number }>`
  border-bottom: 1px solid rgb(209, 211, 212);
  border-right: 1px solid rgb(209, 211, 212);
  height: 100px;
  padding: 2px 2px 0 2px;
  display: table-cell;
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
  vertical-align: middle;
  text-align: center;
`;

const CalendarEmptyCell = styled.div`
  border: 1px solid #dddddd;
  background-color: #f5f5f5;
  min-width: 150px;
  height: 80px;
  padding: 0;
  display: table-cell;
`;

const contentsBgColorChooser = (apprSts: any, overTimeType: any, reduc: any, leave: any): string => {
  if (apprSts === '1') {
    return '#ffffff';
  }
  if (apprSts === 'S' && overTimeType === 'OT') {
    return '#FF0000';
  }
  if (apprSts === 'S' && overTimeType === 'AB') {
    return '#0000FF';
  }
  if (apprSts === 'A' && overTimeType === 'OT') {
    return '#ffebee';
  }
  if (apprSts === 'A' && overTimeType === 'AB') {
    return '#e3f2fd';
  }
  return '#ffffff';
};

const contentsColorChooser = (apprSts: any, overTimeType: any, reduc: any, leave: any): string => {
  if (reduc !== null) {
    return '#000000';
  }
  if (leave !== null) {
    return '#000000';
  }
  if (apprSts === 'R') {
    return '#FF0000';
  }
  if (apprSts === 'S') {
    return 'aliceblue';
  }
  if (apprSts === null) {
    return '#ffffff';
  }
  return '#000000';
};

const CellContentsHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CellContentsDate = styled.div<{ holiday?: string }>`
  color: ${(props: any): any => contentsDateColorChooser(props.holiday)};
  padding: 2px 5px;
  font-size: 15px;
  font-weight: 700;
`;

const cellContentsApprColor = (overtime: any): any => {
  if (overtime.apprSts === 'S') {
    return overTimeRegWhiteColor;
  }
  if (overtime.apprSts === 'R') {
    return overTimeRegWhiteColor;
  }
  return 'black';
};

const CellContentsAppr = styled.div<{ overtime?: any }>`
  color: ${(props: any): any => cellContentsApprColor(props.overtime)};
  padding: 2px 5px;
  font-size: 11px;
  font-weight: 700;
`;

const CellContents = styled.div<{ apprSts?: any; overTimeType?: any; reduc?: any; leave?: any }>`
  background-color: ffffff00;
  min-height: 27px;
  padding: 2px 5px;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  border-radius: 12px;
  /* color: black; */
`;

const CellContentsInfo = styled.div<{ apprSts?: any; overTimeType?: any; reduc?: any; leave?: any }>`
  background-color: ffffff00;
  min-height: 27px;
  padding: 2px 2px 0px 5px;
  text-align: left;
  font-size: 13px;
  font-weight: 700;
  border-radius: 12px;
  /* color: black; */
`;

const CellContentsInfoType = styled.div<{ overtime?: any }>`
  /* color: ${(props: any): any => cellContentsTimeColor(props.overtime)}; */
  font-weight: 700;
`;

const cellContentsTimeColor = (overtime: any): any => {
  if (overtime.overTimeType === undefined) {
    return 'black';
  }
  if (overtime.inputYn === 'N') {
    return overTimeRegGrayColor;
  }
  if (overtime.apprSts === 'A' && overtime.overTimeType === 'OT') {
    return overTimeRegOtColor;
  }
  if (overtime.apprSts === 'A' && overtime.overTimeType === 'AB') {
    return overTimeRegAbColor;
  }
  if (overtime.apprSts === 'S') {
    return overTimeRegWhiteColor;
  }
  if (overtime.apprSts === 'R') {
    return overTimeRegWhiteColor;
  }
  return overTimeRegGrayColor;
};

const CellContentsTime = styled.div<{ overtime?: any }>`
  background-color: ffffff00;
  min-height: 27px;
  padding: 2px 2px 0px 5px;
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  border-radius: 12px;
  color: ${(props: any): any => cellContentsTimeColor(props.overtime)};
`;

const CellContentsBody = styled.div`
  display: flex;
  justify-content: space-between;
`;

const contentsDateColorChooser = (holiday: string): string => {
  if (holiday === 'N') {
    return 'black';
  }
  if (holiday === 'Y') {
    return 'red';
  }
  return overTimeRegWhiteColor;
};

// const CellContents = styled.div<{ apprSts?: any; overTimeType?: any; reduc?: any; leave?: any }>`
//   background-color: ${(props: any): any => contentsBgColorChooser(props.apprSts, props.overTimeType, props.reduc, props.leave)};
//   min-height: 27px;
//   padding: 2px 5px;
//   text-align: center;
//   font-size: 15px;
//   border-radius: 12px;
//   color: ${(props: any): any => contentsColorChooser(props.apprSts, props.overTimeType, props.reduc, props.leave)};
// `;

// const contentsDateColorChooser = (holiday: string): string => {
//   if (holiday === 'N') {
//     return 'black';
//   }
//   if (holiday === 'Y') {
//     return 'red';
//   }
//   return '#ffffff';
// };

// const CellContentsDate = styled.div<{ holiday?: string }>`
//   color: ${(props: any): any => contentsDateColorChooser(props.holiday)};
//   padding: 2px 5px;
//   font-size: 15px;
//   font-weight: 700;
// `;

@inject('uiStore')
@inject('overtimeMonthApprListStore')
@inject('commonStore')
@observer
export default class OverTimeCellApprDetailComponent extends Component<Props> {
  state = {
    weekoverNumber: false
  };

  key = 'updatable';

  messageContentWeekOver = (
    <div>
      <span>{this.state.weekoverNumber}주차의 근로가능시간이 초과되었습니다</span>
      <br />
      <span>근로시간을 조정하시거나 대체휴식을 부여하여 주십시오</span>
    </div>
  );

  messageContentMonthOver = (
    <div>
      <span>당월 근로가능시간이 초과되었습니다</span>
      <br />
      <span>근로시간을 조정하시거나 대체휴식을 부여하여 주십시오</span>
    </div>
  );

  // 연장근로 등록을 위해 입력값 관리(반려)
  rejectReqOverTime = (overtime: OverTimeMonthModel): void => {
    const { locale } = this.props.uiStore!.currLanguage;
    this.setState({
      weekoverNumber: overtime.workSysMonthWeekly
    });
    if (this.props.overtimeMonthApprListStore!.getOverTimeCheck(overtime.overTimeType, overtime.overtimeWorkHours, overtime.overTimeYmd) === 'weekOver') {
      // message.warning({ content: `${overtime.workSysMonthWeekly}주차의 근로가능시간이 초과되었습니다.`, duration: 5 });
      message.warning({
        content: this.messageContentWeekOver,
        key: this.key,
        duration: 5
      });
      return;
    }
    if (this.props.overtimeMonthApprListStore!.getOverTimeCheck(overtime.overTimeType, overtime.overtimeWorkHours, overtime.overTimeYmd) === 'monthOver') {
      //message.warning({ content: `${overtime.workSysMonthWeekly}당월 근로가능시간이 초과되었습니다.`, duration: 5 });
      message.warning({
        content: this.messageContentMonthOver,
        key: this.key,
        duration: 5
      });
      return;
    }

    overtime.apprSts = 'R';
    this.props.overtimeMonthApprListStore!.updateOverTime(overtime);
    if (locale === 'ko') {
      message.warning(`반려되었습니다.`);
    } else {
      message.warning(`반려되었습니다.`);
    }
  };
  // 연장근로 등록을 위해 입력값 관리(반려)

  // 연장근로 등록을 위해 입력값 관리(승인)
  apprReqOverTime = (overtime: OverTimeMonthModel): void => {
    const { locale } = this.props.uiStore!.currLanguage;
    this.setState({
      weekoverNumber: overtime.workSysMonthWeekly
    });
    if (this.props.overtimeMonthApprListStore!.getOverTimeCheck(overtime.overTimeType, overtime.overtimeWorkHours, overtime.overTimeYmd) === 'weekOver') {
      // message.warning({ content: `${overtime.workSysMonthWeekly}주차의 근로가능시간이 초과되었습니다.`, duration: 5 });
      message.warning({
        content: this.messageContentWeekOver,
        key: this.key,
        duration: 5
      });
      return;
    }
    if (this.props.overtimeMonthApprListStore!.getOverTimeCheck(overtime.overTimeType, overtime.overtimeWorkHours, overtime.overTimeYmd) === 'monthOver') {
      // message.warning({ content: `${overtime.workSysMonthWeekly}당월 근로가능시간이 초과되었습니다.`, duration: 5 });
      message.warning({
        content: this.messageContentMonthOver,
        key: this.key,
        duration: 5
      });
      return;
    }
    overtime.apprSts = 'S';
    this.props.overtimeMonthApprListStore!.updateOverTime(overtime);
    if (locale === 'ko') {
      message.warning(`승인되었습니다.`);
    } else {
      message.warning(`승인되었습니다.`);
    }
  };
  // 연장근로 등록을 위해 입력값 관리(승인)

  // 입력가능한 근로날짜 생성
  calendarCreateInput = (overtime: OverTimeMonthModel, displayRate: any, displayOvertime: string): JSX.Element => {
    const retrunCell = (
      <CalendarCell overtime={overtime}>
        <CellContentsHeader>
          <CellContentsDate holiday={overtime.holidayYn}>{overtime.yyyymmdd.substring(6, 8)}</CellContentsDate>
          <CellContentsAppr overtime={overtime}>{overtime.apprStsName}</CellContentsAppr>
        </CellContentsHeader>
        <CellContentsBody>
          <CellContentsInfo apprSts={overtime.apprSts} overTimeType={overtime.overTimeType} reduc={overtime.reducYn} leave={overtime.leaveYn}>
            <Tooltip title={this.toolTipTitle(overtime)}>
              <CellContentsInfoType overtime={overtime}>
                {overtime.overTimeType === 'OT' ? (
                  <FormattedMessage id="overtime.calendar.label.overtime.work" />
                ) : overtime.overTimeType === 'AB' ? (
                  <FormattedMessage id="overtime.calendar.label.alternate.break" />
                ) : (
                  <div></div>
                )}
              </CellContentsInfoType>
              {/* <br /> */}
              {overtime.pjName}
            </Tooltip>
          </CellContentsInfo>
          <CellContentsTime overtime={overtime}>{overtime.overtimeWorkHours}</CellContentsTime>
        </CellContentsBody>
        <div style={{ textAlign: 'center' }}>
          <Button size="small" type="primary" style={{ textAlign: 'center', marginRight: '5px' }} onClick={(): any => this.apprReqOverTime(overtime)}>
            <FormattedMessage id="overtime.calendar.label.approve" />
          </Button>
          <Button size="small" type="primary" style={{ textAlign: 'center' }} onClick={(): any => this.rejectReqOverTime(overtime)}>
            <FormattedMessage id="overtime.calendar.label.reject" />
          </Button>
        </div>

        {/* <CellContents apprSts={overtime.apprSts} overTimeType={overtime.overTimeType}>
          {displayOvertime}
          <br />
          {overtime.pjCode}
          <br />
          <Button size="small" type="primary" style={{ textAlign: 'center', marginRight: '5px' }} onClick={(): any => this.apprReqOverTime(overtime)}>
            승인
          </Button>
          <Button size="small" type="primary" style={{ textAlign: 'center' }} onClick={(): any => this.rejectReqOverTime(overtime)}>
            반려
          </Button>
        </CellContents> */}
      </CalendarCell>
    );
    return retrunCell;
  };

  // 입력불가능한 근로날짜 생성
  calendarCreateStop = (overtime: OverTimeMonthModel, displayRate: any, displayOvertime: string): JSX.Element => {
    if (overtime.apprSts === '1') {
      const retrunCell = (
        <CalendarCellNoAppr overtime={overtime}>
          <CellContentsHeader>
            <CellContentsDate holiday={overtime.holidayYn}>{overtime.yyyymmdd.substring(6, 8)}</CellContentsDate>
            <CellContentsAppr overtime={overtime} />
          </CellContentsHeader>

          <CellContentsBody>
            <CellContentsInfo apprSts={overtime.apprSts} overTimeType={overtime.overTimeType} reduc={overtime.reducYn} leave={overtime.leaveYn}>
              <CellContentsInfoType overtime={overtime} />
            </CellContentsInfo>
            <CellContentsTime overtime={overtime} />
          </CellContentsBody>
        </CalendarCellNoAppr>
      );

      return retrunCell;
    }    
    const retrunCell = (
      <CalendarCellNoAppr overtime={overtime}>
        <CellContentsHeader>
          <CellContentsDate holiday={overtime.holidayYn}>{overtime.yyyymmdd.substring(6, 8)}</CellContentsDate>
          <CellContentsAppr overtime={overtime}>{overtime.apprStsName}</CellContentsAppr>
        </CellContentsHeader>
        <CellContentsBody>
          <CellContentsInfo apprSts={overtime.apprSts} overTimeType={overtime.overTimeType} reduc={overtime.reducYn} leave={overtime.leaveYn}>
            <Tooltip title={this.toolTipTitle(overtime)}>
              <CellContentsInfoType overtime={overtime}>
                {overtime.overTimeType === 'OT' ? (
                  <FormattedMessage id="overtime.calendar.label.overtime.work" />
                ) : overtime.overTimeType === 'AB' ? (
                  <FormattedMessage id="overtime.calendar.label.alternate.break" />
                ) : (
                  <div></div>
                )}
              </CellContentsInfoType>
              {/* <br /> */}
              {overtime.pjName}
            </Tooltip>
          </CellContentsInfo>
          <CellContentsTime overtime={overtime}>{overtime.overtimeWorkHours}</CellContentsTime>
        </CellContentsBody>
      </CalendarCellNoAppr>
    );
    return retrunCell;
  };

  toolTipTitle = (overtime: OverTimeMonthModel): JSX.Element => {
    if (overtime.pjInfo != null) {
      const retrunCell = (
        <div>
          <span>PM : {overtime.pjInfo.pmEmpNm} </span>
          <br />
          <span>PJ : {overtime.pjInfo.pjName} </span>
        </div>
      );
      return retrunCell;
    }

    return <></>;
  };

  calendarEtc = (overtime: OverTimeMonthModel, displayRate: any, displayOvertime: string): JSX.Element => {
    let workhour: any;
    if (overtime.overtimeWorkHours === null || overtime.overtimeWorkHours === 0) {
      workhour = '';
    } else {
      workhour = overtime.overtimeWorkHours;
    }
    const retrunCell = (
      <CalendarCell overtime={overtime}>
        <CellContentsHeader>
          <CellContentsDate holiday={overtime.holidayYn}>{overtime.yyyymmdd.substring(6, 8)}</CellContentsDate>
        </CellContentsHeader>
        <CellContentsBody>
          <CellContentsInfo apprSts={overtime.apprSts} overTimeType={overtime.overTimeType} reduc={overtime.reducYn} leave={overtime.leaveYn}>
            <CellContentsInfoType overtime={overtime}>
              {overtime.reducYn != null ? (
                <FormattedMessage id="overtime.calendar.label.short.work" />
              ) : overtime.leaveYn != null ? (
                <FormattedMessage id="overtime.calendar.label.vacation" />
              ) : (
                <div></div>
              )}
            </CellContentsInfoType>
          </CellContentsInfo>
        </CellContentsBody>
      </CalendarCell>
    );
    return retrunCell;
  };

  renderCell = (overtime: OverTimeMonthModel): JSX.Element => {
    if (overtime == null) {
      return <CalendarEmptyCell />;
    }

    let displayOvertime: string;
    if (overtime.overTimeType === 'H') {
      return (
        <CalendarWeeklyCell>
          {overtime.workSysMonthWeekly}
          {<FormattedMessage id="overtime.calendar.label.week" />}
        </CalendarWeeklyCell>
      );
    }
    if (overtime.reducYn !== null) {
      displayOvertime = `단축근무`;
      // displayOvertime = `${<FormattedMessage id="overtime.calendar.label.short.work" />}`;
      return this.calendarEtc(overtime, null, displayOvertime);
    }
    if (overtime.leaveYn !== null) {
      displayOvertime = `휴가`;
      // displayOvertime = `${<FormattedMessage id="overtime.calendar.label.short.vacation" />}`;
      return this.calendarEtc(overtime, null, displayOvertime);
    }
    if (overtime.apprSts === null || overtime.apprSts === '1') {
      displayOvertime = '';

      return this.calendarCreateStop(overtime, null, displayOvertime);
    }
    if (overtime.overTimeType === 'OT') {
      displayOvertime = `연장근로 ${overtime.overtimeWorkHours} 시간`;
      // displayOvertime = `${<FormattedMessage id="overtime.calendar.label.overtime.work" />}`;
      if (overtime.apprSts === 'A') {
        return this.calendarCreateInput(overtime, 0, displayOvertime);
      }
      return this.calendarCreateStop(overtime, 0, displayOvertime);
    }
    if (overtime.overTimeType === 'AB') {
      displayOvertime = `대체휴식 ${overtime.overtimeWorkHours} 시간`;
      // displayOvertime = `${<FormattedMessage id="overtime.calendar.label.alternate.break" />}`;
      if (overtime.apprSts === 'A') {
        return this.calendarCreateInput(overtime, 100, displayOvertime);
      }
      return this.calendarCreateStop(overtime, 100, displayOvertime);
    }

    displayOvertime = '';
    return this.calendarCreateStop(overtime, null, displayOvertime);
  };

  render(): JSX.Element {
    const date = this.props.date!;
    // const overTimeMonthList = this.props.overTimeMonthList!;

    return <>{this.renderCell(date)}</>;
  }
}
