import { Button, Form, Input, Row, Select, Spin, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import tableResultColumns from './tableResultColumns';

const { Option } = Select;

interface Props {
  form: any;
  formFields: any;
  formItemLayout?: any;
  listItems: any;
  onSearch?: any;
  onSubmit?: any;
  onCancel?: any;
  spinning?: boolean;
}
const defaultProps: Props = {
  formItemLayout: {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  },
  form: undefined,
  formFields: undefined,
  listItems: undefined
};
class SelectEmployee extends React.Component<Props & FormComponentProps> {
  dataSource: any[] = [];
  columns = tableResultColumns;

  static defaultProps = defaultProps;
  state = {
    rowIdSelected: '',
    selectedConcurrentEmployee: {}
  };

  renderTypeComponent = (item: any) => {
    switch (item.type) {
      case 'input':
        return <Input allowClear />;
      case 'select':
        return (
          <Select getPopupContainer={trigger => trigger.parentNode as HTMLElement} labelInValue allowClear={item.allowClear}>
            {item.options &&
              item.options.map((option: any) => {
                return (
                  <Option value={option.value} key={item.key}>
                    {option.text}
                  </Option>
                );
              })}
          </Select>
        );
      default:
        return <Input />;
    }
  };

  parseValueEmployee = (values: any) => {
    Object.keys(values).forEach(key => {
      if (values[key] && typeof values[key] === 'object') {
        values[`${key}Code`] = values[key].key;
        values[`${key}Name`] = values[key].label;
        delete values[key];
      }
    });
    return values;
  };

  handleSubmit = () => {
    this.props.onSubmit(this.state.selectedConcurrentEmployee);
    this.props.form.resetFields();
    this.setState({
      selectedConcurrentEmployee: {}
    });
  };

  handleSearch = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const newValues = this.parseValueEmployee(values);
        if (newValues) {
          Object.keys(newValues).forEach((key: any) => {
            if (!newValues[key]) {
              delete newValues[key];
            } else {
              newValues[key] = newValues[key].trim();
            }
          });
        }
        const searchParams = newValues || '';
        this.props.onSearch(searchParams);
      }
    });
  };

  handleCancel = () => {
    this.props.onCancel();
    this.props.form.resetFields();
  };

  getSortedEmployees = (data: any) => {
    data.sort((a: any, b: any) => {
      const { key: aId } = a;
      const { key: bId } = b;

      if (bId && aId) {
        return Number(bId - aId);
      }
      if (bId === null) {
        // Fix for Firefox
        return 1;
      }
      return -1;
    });
    return data;
  };

  reduceDataSource(): void {
    const { listItems } = this.props;
    this.dataSource = [];

    if (listItems) {
      listItems.map((emp: any, i: any) => {
        this.dataSource.push({ key: i + 1, ...emp });
        return this.dataSource;
      });
    }

    this.dataSource.map((v, i) => {
      v.no = i + 1;
      return this.dataSource;
    });

    this.dataSource = this.getSortedEmployees(this.dataSource);
  }

  render() {
    this.reduceDataSource();

    const { getFieldDecorator } = this.props.form;
    const { formFields, formItemLayout, spinning } = this.props;

    const onRow = (record: any) => {
      return {
        onClick: () => {
          this.setState({ rowIdSelected: record.id });
          this.setState({ selectedConcurrentEmployee: record });
        },

        onDoubleClick: () => {
          this.setState({ rowIdSelected: record.id });
          this.setState({ selectedConcurrentEmployee: record });
          this.handleSubmit();
        }
      };
    };

    const setRowClassName = (record: any) => {
      const selectedRecord = this.state.rowIdSelected || '';
      return record.id === selectedRecord ? 'selectedRow' : '';
    };

    const scroll = this.dataSource.length > 0 ? { x: 500, y: 'calc(100vh - 400px)' } : {};

    return (
      <div>
        <Form className="dynamic-form" layout="vertical">
          {formFields.map((item: any) => {
            return (
              <Form.Item key={item.key} label={item.label} {...formItemLayout}>
                {getFieldDecorator(item.key, {
                  rules: item.rules
                })(this.renderTypeComponent(item))}
              </Form.Item>
            );
          })}
          <Row gutter={16}>
            <Button type="primary" className="button-search-concurrent" onClick={this.handleSearch}>
              <FormattedMessage id="button.title.search" />
            </Button>
          </Row>

          <Spin spinning={spinning}>
            <Table
              columns={this.columns}
              rowClassName={(record: any) => setRowClassName(record)}
              dataSource={this.dataSource}
              pagination={false}
              scroll={scroll}
              onRow={onRow}
              className="select-concurrent-employee-table"
            />
          </Spin>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button onClick={this.handleCancel} style={{ marginRight: 8 }}>
              <FormattedMessage id="button.title.cancel" />
            </Button>
            <Button type="primary" onClick={this.handleSubmit}>
              <FormattedMessage id="button.title.drawer.confirm" />
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

const WrappedSelectEmployee = Form.create<Props & FormComponentProps>()(SelectEmployee);

export default WrappedSelectEmployee;
