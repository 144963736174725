import { action } from 'mobx';
import { RootStore } from './RootStore';
import { History, createBrowserHistory } from 'history';

export class RouteStore {
  rootStore: RootStore;
  private _firstAccessLocationPathname: string;

  storeHistory: History;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  get firstAccessLocationPathname() {
    return this._firstAccessLocationPathname;
  }

  @action
  setFirstAccessLocationPathname(firstAccessLocationPathname: string) {
    this._firstAccessLocationPathname = firstAccessLocationPathname;
  }

  // eslint-disable-next-line class-methods-use-this
  hardRefreshToPath(path: string) {
    // hard refresh whole page (send full HTTP request)
    const localHistory = createBrowserHistory({
      forceRefresh: true
    });
    localHistory.push(path);
  }
}
