import { observable, action } from 'mobx';
import { MobileRootStore } from 'shared/mobile-stores/RootStore';
import moment from 'moment';
import OverTimeSumModel from '../static/OverTimeSumModel';
import OverTimeMonthModel from '../static/OverTimeMonthModel';
import OvertimeAppMobileService from '../services/OvertimeAppMobileService';
import OverTimeModel from '../static/OverTimeModel';

export default class OvertimeMonthApprListStore {
  @observable
  inProgress: boolean;

  @observable
  seletedPjCode: string;

  @observable
  selectYyyymm: string;

  @observable
  selectEmpNm: string;

  @observable
  detailCorpCode: string | null;

  @observable
  detailMm: string;

  @observable
  detailYyyy: string;

  @observable
  detailEmpNo: string;

  @observable
  detailEmpNm: string;

  @observable
  detailPjCode: string | null;

  @observable
  workType = '';

  @observable
  workTypeName = '';

  @observable
  gridData: OverTimeSumModel[];

  @observable
  detailOverTimeSum: OverTimeSumModel = {
    rowStatus: '',
    no: '',
    corpCode: '',
    yyyy: '',
    mm: '',
    empNo: '',
    empNm: '',
    deptNm: '',
    pjCode: '',
    beforeAverageOverTime: 0,
    afterAverageOverTime: 0,
    closingYn: '',
    countsAppr: '',
    allApprYn: ''
  };

  @observable
  overTimeMonthList: OverTimeMonthModel[];

  @observable
  overTimeMonthApprSum = 0;

  @observable
  overTimeMonthAllSum = 0;

  @observable
  nowWorkSysYearMonth = '0';

  @observable
  monthlyClosing = '';

  @observable
  overTimeMaxWeekly = '0';

  @observable
  overtimeSumList = [0, 0, 0, 0, 0, 0];

  @observable
  monthlyTotalHour = 0;

  rootStore: MobileRootStore;

  constructor(rootStore: MobileRootStore) {
    this.inProgress = false;
    this.rootStore = rootStore;
    this.gridData = [];
    this.seletedPjCode = '';
    this.selectYyyymm = '';
    this.selectEmpNm = '';
    this.detailCorpCode = '';
    this.detailYyyy = '';
    this.detailMm = '';
    this.detailEmpNo = '';
    this.detailEmpNm = '';
    this.detailPjCode = '';
    this.workTypeName = '';
    this.workType = 'X';
    this.overTimeMonthList = [];
    this.overTimeMonthAllSum = 0;
    this.overTimeMonthApprSum = 0;
    this.monthlyClosing = '';
    this.overTimeMaxWeekly = '0';
    this.overtimeSumList = [0, 0, 0, 0, 0, 0];
    this.monthlyTotalHour = 0;
    this.detailOverTimeSum.allApprYn = 'N';
  }

  @action
  setSeletedPjCode(seletedPjCode: string): any {
    this.seletedPjCode = seletedPjCode;
  }

  @action
  setSelectYyyymm(selectYyyymm: string): any {
    this.selectYyyymm = selectYyyymm;
  }

  @action
  setSelectEmpNm(selectEmpNm: string): any {
    this.selectEmpNm = selectEmpNm;
  }

  @action
  setRecordToDetail(record: OverTimeSumModel): any {
    if (record.pjCode === 'null') record.pjCode = null;
    if (record.corpCode === 'null') record.corpCode = null;

    this.detailOverTimeSum = record;

    this.detailCorpCode = record.corpCode;
    this.detailMm = record.mm;
    this.detailYyyy = record.yyyy;
    this.detailEmpNo = record.empNo;
    this.detailEmpNm = record.empNm;
    this.detailPjCode = record.pjCode;
  }

  @action
  async getNowWorkSysWeekNumber(): Promise<void> {
    // 현재주차 가져오기
    this.inProgress = true;

    return OvertimeAppMobileService.getWeekNumber(moment().format('YYYYMMDD'))
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.nowWorkSysYearMonth = data.workSysYearMonth;

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiFail(error);
        this.inProgress = false;
      });
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  @action
  getGridData(pjCode: string, toworkSysYearMonthMonth: string): void {
    this.inProgress = true;

    if (pjCode === '' || pjCode === undefined) {
      pjCode = 'all';
    }

    OvertimeAppMobileService.getMonthOverTimeList(pjCode, toworkSysYearMonthMonth)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];

        // reduce No. column + data type
        data.map((v: any, i: any) => {
          v.no = i + 1;
          return null;
        });

        this.gridData = data;

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiFail(error);
        this.inProgress = false;
      });
  }

  @action
  getMonthOverTimeAppr(empNo: string, workSysYearMonth: string, pjCode: string | null): Promise<void> {
    // monthly 달력 가져오기
    this.inProgress = true;

    if (pjCode === null || pjCode === 'null') {
      pjCode = 'all';
    }

    this.nowWorkSysYearMonth = workSysYearMonth;
    this.overTimeMonthApprSum = 0;
    this.overTimeMonthAllSum = 0;

    return OvertimeAppMobileService.getMonthOverTimeAppr(empNo, workSysYearMonth, pjCode)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.overTimeMonthList = data;

        if (data[0].closing === 'Y') {
          this.monthlyClosing = '(마감)';
        } else if (data[0].closing === 'N') {
          this.monthlyClosing = '';
        } else if (data[0].closing === 'X') {
          this.monthlyClosing = '';
        }

        let calItem = this.overTimeMonthList.filter(calitem => calitem.weekEngName === 'SAT' && calitem.workSysMonthWeekly === '5');
        if (calItem.length > 0) {
          this.overTimeMaxWeekly = calItem[0].workSysMonthWeekly;
        } else {
          calItem = this.overTimeMonthList.filter(calitem => calitem.weekEngName === 'SAT' && calitem.workSysMonthWeekly === '4');
          this.overTimeMaxWeekly = calItem[0].workSysMonthWeekly;
        }

        this.overTimeMonthApprSum = 0;
        this.overTimeMonthAllSum = 0;

        // eslint-disable-next-line no-restricted-syntax
        for (const Item of this.overTimeMonthList) {
          if (Item.apprSts === 'S' && Item.reducYn === null && Item.leaveYn === null) {
            if (Item.overTimeType === 'OT') {
              this.overTimeMonthApprSum += Item.overtimeWorkHours;
            } else if (Item.overTimeType === 'AB') {
              this.overTimeMonthApprSum -= Item.overtimeWorkHours;
            }
          }

          // && Item.closing !=='NP' 조건 추가 시 내 프로젝트 시간만 계산
          if ((Item.apprSts === 'A' || Item.apprSts === 'S') && Item.reducYn === null && Item.leaveYn === null) {
            if (Item.overTimeType === 'OT') {
              this.overTimeMonthAllSum += Item.overtimeWorkHours;
            } else if (Item.overTimeType === 'AB') {
              this.overTimeMonthAllSum -= Item.overtimeWorkHours;
            }
          }
        }

        this.overTimeMonthAllSum = 40 + this.overTimeMonthAllSum / Number(this.overTimeMaxWeekly);
        this.overTimeMonthApprSum = 40 + this.overTimeMonthApprSum / Number(this.overTimeMaxWeekly);

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiFail(error);
        this.inProgress = false;
      });
  }

  @action
  async allApprListOverTime(overtimeSum: OverTimeSumModel): Promise<void> {
    // 연장근로 등록
    this.inProgress = true;

    OvertimeAppMobileService.allApprOverTime(overtimeSum)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.getGridData(this.seletedPjCode, this.selectYyyymm);
        // this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiFail(error);
        this.inProgress = false;
      });
  }

  @action
  async allApprMonthOverTime(overtimeSum: OverTimeSumModel): Promise<void> {
    // 연장근로 일괄승인
    this.inProgress = true;

    return OvertimeAppMobileService.allApprOverTime(overtimeSum)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.detailOverTimeSum.allApprYn = 'N';
        this.getGridData(this.seletedPjCode, this.selectYyyymm);
        this.getMonthOverTimeAppr(this.detailEmpNo, this.selectYyyymm, this.detailPjCode);
        // this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiFail(error);
        this.inProgress = false;
      });
  }

  @action
  getWorkType(empNo: string, nowWorkSysYearMonth: string): Promise<void> {
    return OvertimeAppMobileService.getWorkType(empNo, nowWorkSysYearMonth)
      .then(response => {
        const data = response.data.data || [];
        if (data.length > 0) {
          this.workTypeName = data[0];
          this.workType = data[1];
        }
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiFail(error);
        this.inProgress = false;
      });
  }

  @action
  async updateOverTime(overtime: OverTimeMonthModel): Promise<void> {
    // 연장근로 등록
    this.inProgress = true;
    const overtimeItem: OverTimeModel = {
      rowStatus: overtime.rowStatus,
      corpCode: overtime.corpCode,
      empNo: overtime.empNo,
      overTimeYmd: overtime.overTimeYmd,
      overTimeType: overtime.overTimeType,
      pjCode: overtime.pjCode,
      overtimeWorkHours: overtime.overtimeWorkHours,
      apprSts: overtime.apprSts
    };

    return OvertimeAppMobileService.updateOverTime(overtimeItem)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.getMonthOverTimeAppr(this.detailEmpNo, this.selectYyyymm, this.detailPjCode);
        // this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiFail(error);
        this.inProgress = false;
      });
  }

  @action
  getOverTimeCheck(overTimeType: any, overtimeWorkHours: any, overTimeYmd: any): any {
    const overTimeCheck: OverTimeMonthModel[] | undefined = this.overTimeMonthList;
    const weekNumbers = ['SAT', 'SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI'];
    const workSysMonthWeekly = ['1', '2', '3', '4', '5'];
    let weeklyCheck: any | undefined = null;
    let MonthSum = 0;
    let checkString = '';
    const { workType } = this;

    workSysMonthWeekly.forEach(function workSysMonthWeeklyF(weeklyItem) {
      let overTimeWeekAllSum = 0;
      weekNumbers.forEach(function weekNumbersF(weekEngitem) {
        const calItem = overTimeCheck.filter(calitem => calitem.weekEngName === weekEngitem && calitem.workSysMonthWeekly === weeklyItem);

        if (calItem.length > 0) {
          weeklyCheck = weeklyItem;

          if (calItem[0].overTimeYmd === overTimeYmd && calItem[0].reducYn === null && calItem[0].leaveYn === null) {
            if (overTimeType === 'OT') {
              overTimeWeekAllSum += overtimeWorkHours;
            } else if (calItem[0].overTimeType === 'AB') {
              overTimeWeekAllSum -= overtimeWorkHours;
            }
          } else if (calItem[0].apprSts === 'A' || (calItem[0].apprSts === 'S' && calItem[0].reducYn === null && calItem[0].leaveYn === null)) {
            if (calItem[0].overTimeType === 'OT') {
              overTimeWeekAllSum += calItem[0].overtimeWorkHours;
            } else if (calItem[0].overTimeType === 'AB') {
              overTimeWeekAllSum -= calItem[0].overtimeWorkHours;
            }
          }
        }
      });

      if (overTimeWeekAllSum > 24 && workType === 'B') {
        checkString = 'weekOver';
      }

      MonthSum += overTimeWeekAllSum;
    });

    MonthSum /= Number(weeklyCheck);

    if (MonthSum > 12) {
      return 'monthOver';
    }
    if (checkString === 'weekOver') {
      return 'weekOver';
    }
    return 'OK';
  }

  @action
  getMonthlyTotalOverTimeSum(maxWeekly: number, weelyNumber: number, sumHour: number): void {
    this.overtimeSumList[weelyNumber] = sumHour;
    let overtimeSum = 0;
    const lastWeekNumber = maxWeekly;

    this.overtimeSumList.forEach(function overtimeSumListF(item: any) {
      overtimeSum += item;
    });

    const overtimeAvr = overtimeSum / lastWeekNumber;
    const monthlyTotalHour = 40 + overtimeAvr;
    this.monthlyTotalHour = monthlyTotalHour;
  }

  @action
  setDetailDataForRefresh(
    corpCode: any,
    empNo: any,
    empNm: any,
    yyyy: any,
    mm: any,
    pjCode: any,
    allApprYn: any,
    closingYn: any,
    countsAppr: any,
    deptNm: any,
    rowStatus: any,
    afterAverageOverTime: any,
    beforeAberageOverTime: any,
    no: any
  ) {
    this.overTimeMonthApprSum = 0;
    this.overTimeMonthAllSum = 0;

    const data = {
      rowStatus,
      no,
      corpCode,
      yyyy,
      mm,
      empNo,
      empNm,
      deptNm,
      pjCode,
      beforeAverageOverTime: Number(beforeAberageOverTime),
      afterAverageOverTime: Number(afterAverageOverTime),
      closingYn,
      countsAppr,
      allApprYn
    };

    this.setRecordToDetail(data);
    this.getWorkType(empNo, yyyy + mm);
    this.getMonthOverTimeAppr(empNo, yyyy + mm, pjCode);
  }
}
