import { Card, Empty, Tree } from 'antd';
import Search from 'antd/lib/input/Search';
import { inject, observer } from 'mobx-react';
import { DepartmentStore } from 'pages/department-management/stores/DepartmentStore';
import { getParentKey } from 'pages/department-management/utils';
import React from 'react';
import AppConst from 'shared/utils/AppConst';
import _ from 'lodash';

const { TreeNode } = Tree;

interface IProps {
  departmentStore?: DepartmentStore;
  afterSelect?: any;
  topContainer?: any;
  height?: string; // Example: 100px , calc(100vh - 380px)
}

@inject('departmentStore')
@observer
export default class ListDepartmentTree extends React.Component<IProps, {}> {
  state = {
    autoExpandParent: true,
    checkedKeys: [],
    selectedKeys: [],
    searchValue: '',
    expandedKeys: [],
    allParentKeys: [], // save all parent keys to expand entire tree
    prevDeptList: [] // save previous department list to detect when user "Search" for new value
  };

  /**
   * handle when user use Search for new value
   * if new value coming, expand entire tree
   * @param props props
   * @param state state
   */
  static getDerivedStateFromProps(props: IProps, state: any) {
    // get all parent key from treeDepartment
    const getAllParentKey = (treeNode: any, rsArr: any) => {
      if (treeNode && treeNode.children && treeNode.children.length) {
        getAllParentKey(treeNode.children[0], rsArr);
      }
      if (treeNode && treeNode.isParent) {
        rsArr.push(treeNode.id);
      }
    };
    // if current department lists not equal previous department list
    if (
      !_.isEqual(
        state.prevDeptList,
        props.departmentStore!.departmentList.map((x: any) => x.id)
      )
    ) {
      const expandedAllKeys: any = [];
      getAllParentKey(props.departmentStore!.treeDepartment[0], expandedAllKeys);
      return {
        prevDeptList: props.departmentStore!.departmentList.map((x: any) => x.id), // update previous department
        // expandedKeys: expandedAllKeys, // expand entire tree
        allParentKeys: expandedAllKeys // save all parent key
      };
    }
    return null;
  }

  collapseTree = () => {
    this.setState({
      expandedKeys: []
    });
  };

  expandTree = () => {
    this.setState((prevState: any) => ({
      expandedKeys: prevState.allParentKeys
    }));
  };

  onChange = (e: any) => {
    const { value } = e.target;
    const { treeDepartment, departmentList } = this.props.departmentStore!;

    const expandedKeys = departmentList
      .map(item => {
        const title = `${item.deptName} - ${item.deptCode}`;
        if (title.indexOf(value) > AppConst.NOT_FOUND) {
          const prKey = getParentKey(item.deptCode, treeDepartment);
          return prKey ? departmentList.find(x => x.deptCode === prKey)!.id : prKey;
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);
    this.setState({
      expandedKeys,
      searchValue: value,
      autoExpandParent: true
    });
  };

  onExpand = (expandedKeys: any[]) => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    this.setState({
      expandedKeys,
      autoExpandParent: false
    });
  };

  onCheck = (checkedKeys: any) => {
    this.setState({ checkedKeys });
  };

  onSelect = (selectedKeys: any[]) => {
    this.setState({ selectedKeys }, () => {
      if (this.props.afterSelect) this.props.afterSelect(selectedKeys[selectedKeys.length - 1]);
    });

    this.props.departmentStore!.selectedDept = this.props.departmentStore!.departmentList.find(x => x.id === selectedKeys[selectedKeys.length - 1])!;
  };

  renderTreeNodes = (data: any[], searchValue: string) =>
    data.map(item => {
      const title = item.nodeText;
      const index = title.indexOf(searchValue);
      const beforeStr = title.substr(0, index);
      const afterStr = title.substr(index + searchValue.length);
      const searchTitle =
        index > -1 ? (
          <span>
            {beforeStr}
            <span style={{ color: '#f50', fontWeight: 'bold' }}>{searchValue}</span>
            {afterStr}
          </span>
        ) : (
          <span>{title}</span>
        );
      const key = item.id;
      if (item.children) {
        return (
          <TreeNode title={searchTitle} key={key} dataRef={item}>
            {this.renderTreeNodes(item.children, searchValue)}
          </TreeNode>
        );
      }
      return <TreeNode key={key} title={searchTitle} {...item} />;
    });

  render() {
    const { searchValue, expandedKeys, autoExpandParent, checkedKeys, selectedKeys } = this.state;
    const { treeDepartment } = this.props.departmentStore!;
    const { height } = this.props;
    const treeHeight = height || 'calc(100vh - 252px)';

    const isEmptyTree = treeDepartment && treeDepartment.length <= 0;
    const renderTree = () => {
      return (
        <Tree
          checkable={false}
          onExpand={this.onExpand}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          onCheck={this.onCheck}
          checkedKeys={checkedKeys}
          onSelect={this.onSelect}
          selectedKeys={selectedKeys}
          style={{ height: treeHeight, overflow: 'auto' }}
        >
          {this.renderTreeNodes(treeDepartment, searchValue)}
        </Tree>
      );
    };
    return (
      <>
        {this.props.topContainer ? this.props.topContainer : null}

        <Search style={{ marginBottom: 8 }} onChange={this.onChange} />

        <Card size="small" bordered={false} bodyStyle={{ background: '#f5f5f5', borderRadius: 8 }}>
          {!isEmptyTree ? renderTree() : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </Card>
      </>
    );
  }
}
