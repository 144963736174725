import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import OverTimeRowApprDetailComponent from '../components/OverTimeRowApprDetailComponent';
import OverTimeMonthModel from '../static/OverTimeMonthModel';
import OvertimeMonthApprListStore from '../stores/OvertimeMonthApprListStore';
import { FormattedMessage } from 'react-intl';

interface Props {
  overtimeMonthApprListStore: OvertimeMonthApprListStore;
}

const CalendarRow = styled.div`
  display: table-row;
`;

const CalendarHeaderCell = styled.div`
  /* background-color: #f5f5f5; */
  border-top: 1px solid #dddddd;
  border-bottom: 3px solid #dddddd;
  width: 150px;
  min-width: 100px;
  height: 60px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  padding: 5px;
  display: table-cell;
  vertical-align: middle;
`;

const CalendarHeaderBeforeSumCell = styled.div`
  /* background-color: #f5f5f5; */
  border-top: 1px solid #dddddd;
  border-bottom: 3px solid #dddddd;
  border-right: 1px solid #dddddd;
  width: 70px;
  min-width: 70px;
  height: 60px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  padding: 5px;
  display: table-cell;
  vertical-align: middle;
`;

const HeaderSumCellBgColorChooser = (displayRate: any | null): string => {
  if (displayRate === null) {
    return 'rgba(132, 132, 132, 0.73)';
  }
  if (displayRate < 100) {
    return 'rgb(123,124,180)';
  }
  return 'rgb(119,161,111)';
};

const HeaderSumCellColorChooser = (displayRate: number | null): string => {
  if (displayRate === null) {
    return 'aliceblue';
  }
  if (displayRate < 100) {
    return 'aliceblue';
  }
  return 'aliceblue';
};

const CalendarHeaderAfterSumCell = styled.div<{ displayRate?: any }>`
  /* border: 1px solid #f5f5f5; */
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  border-left: 3px solid white;
  background-color: ${(props: any): any => HeaderSumCellBgColorChooser(props.displayRate)};
  width: 70px;
  min-width: 70px;
  height: 60px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  padding: 5px;
  display: table-cell;
  vertical-align: middle;
  /*color: aliceblue;*/
  color: ${(props: any): any => HeaderSumCellColorChooser(props.displayRate)};
`;

@inject('overtimeMonthApprListStore')
@observer
export default class OverTimeContentsBodyApprDetailContainer extends Component<Props> {
  renderCalendar = (overTimeMonthList: OverTimeMonthModel[]): JSX.Element[] => {
    // const changeViewType = this.props.changeViewType;
    const rowList: JSX.Element[] = [];
    let weekNum = '0';

    // let calendarList: CalendarModel[] = [];
    let overtimeItems: OverTimeMonthModel[] = [];

    overTimeMonthList.forEach(function overTimeMonthListF(item, index, array) {
      // weekNum과 근로주차가 다를경우
      if (weekNum !== item.workSysMonthWeekly) {
        weekNum = item.workSysMonthWeekly;

        // 주차 변경 시 rowComponent 일주일 연장근로정보 생성
        if (overtimeItems.length !== 0) {
          rowList.push(<OverTimeRowApprDetailComponent overtime={overtimeItems} overTimeMonthList={overTimeMonthList} />);
        }

        // overTimeItems 리스트 초기화
        overtimeItems = [];
      }
      // 연장근로 정보 push
      overtimeItems.push(item);
    });

    // 마지막 주차 rowComponent 일주일 연장근로정보 생성
    rowList.push(<OverTimeRowApprDetailComponent overtime={overtimeItems} overTimeMonthList={overTimeMonthList} />);
    return rowList;
  };

  renderHeaderCalendar = (): JSX.Element => {
    const displayOt = 0;
    const displayAb = 100;
    const displaySum = null;

    return (
      <CalendarRow>
        <CalendarHeaderBeforeSumCell>
          <FormattedMessage id="overtime.calendar.label.overtime" />
        </CalendarHeaderBeforeSumCell>
        <CalendarHeaderBeforeSumCell>
          <FormattedMessage id="overtime.calendar.label.alternate" />
        </CalendarHeaderBeforeSumCell>
        <CalendarHeaderBeforeSumCell>
          <FormattedMessage id="overtime.calendar.label.sum" />
        </CalendarHeaderBeforeSumCell>
        <div style={{ width: '20px' }} />
        <CalendarHeaderCell style={{ borderRight: '1px solid rgb(209, 211, 212)' }}>
          <FormattedMessage id="overtime.calendar.label.week" />
        </CalendarHeaderCell>
        <CalendarHeaderCell>
          <FormattedMessage id="overtime.calendar.label.saturday" />
        </CalendarHeaderCell>
        <CalendarHeaderCell>
          <FormattedMessage id="overtime.calendar.label.sunday" />
        </CalendarHeaderCell>
        <CalendarHeaderCell>
          <FormattedMessage id="overtime.calendar.label.monday" />
        </CalendarHeaderCell>
        <CalendarHeaderCell>
          <FormattedMessage id="overtime.calendar.label.tuesday" />
        </CalendarHeaderCell>
        <CalendarHeaderCell>
          <FormattedMessage id="overtime.calendar.label.wednesday" />
        </CalendarHeaderCell>
        <CalendarHeaderCell>
          <FormattedMessage id="overtime.calendar.label.thursday" />
        </CalendarHeaderCell>
        <CalendarHeaderCell>
          <FormattedMessage id="overtime.calendar.label.friday" />
        </CalendarHeaderCell>
        <div style={{ width: '20px' }} />
        <CalendarHeaderAfterSumCell displayRate={displayOt}>
          <FormattedMessage id="overtime.calendar.label.overtime" />
        </CalendarHeaderAfterSumCell>
        <CalendarHeaderAfterSumCell displayRate={displayAb}>
          <FormattedMessage id="overtime.calendar.label.alternate" />
        </CalendarHeaderAfterSumCell>
        <CalendarHeaderAfterSumCell displayRate={displaySum}>
          <FormattedMessage id="overtime.calendar.label.sum" />
        </CalendarHeaderAfterSumCell>
      </CalendarRow>
    );
  };

  openCalendar = (): void => {};

  preDate = (): void => {};

  nextDate = (): void => {};

  render(): JSX.Element {
    const { overTimeMonthList } = this.props.overtimeMonthApprListStore;
    // const overTimeSumList = this.props.overtimeMonthRegStore.overTimeWeeklySumList;
    // const monthlyTotalHour = this.props.overtimeMonthRegStore.monthlyTotalHour;
    return (
      <>
        {this.renderHeaderCalendar()}
        {this.props.overtimeMonthApprListStore.overTimeMonthList.length > 0 ? this.renderCalendar(overTimeMonthList) : ''}
      </>
    );
  }
}
