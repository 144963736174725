import React from 'react';
import { Link } from 'react-router-dom';
import { Empty } from 'antd';

function NotFoundPage() {
  return (
    <div style={{ textAlign: 'center', paddingTop: '30vh' }}>
      <Empty
        image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTQNz2EQUgO215lil-_AFBm4lNIgocb3M00EWfP-t7rpYgRtnu"
        imageStyle={{
          height: 200
        }}
        description={
          <span>
            <Link to="/m/dashboard">
              <span>홈 페이지로 이동하려면 여기를 클릭하십시오.</span>
            </Link>
          </span>
        }
      />
    </div>
  );
}

export default NotFoundPage;
