import BaseRepository from 'shared/base/repositories/BaseRepository';
import UserModel from 'shared/models/user/UserModel';
import ApiHelper from 'shared/utils/ApiHelper';
import AppConst from 'shared/utils/AppConst';

const API_BASE = AppConst.API.SYS_USER;
class UserService extends BaseRepository<UserModel> {
  constructor() {
    super(AppConst.API.SYS_USER);
  }

  fetchUserDetail = (userName: string) => {
    const api = `${API_BASE}/${userName}`;
    return ApiHelper.get(api);
  };

  findUsersByConditions = (userName: string, userId: string, sortCondition: string) => {
    const params = {
      userId,
      userName,
      sortBy: sortCondition
    };
    return ApiHelper.get(AppConst.API.SYS_USER, params);
  };

  exportExcelFile = (userName: string, userId: string, userType: string, sortCondition: string) => {
    const params = {
      userId,
      userName,
      sortBy: sortCondition
    };
    return ApiHelper.get(`${AppConst.API.SYS_USER}/export`, params);
  };

  findEmployees = (userId: string) => {
    const params = {
      userId
    };
    return ApiHelper.get(`${AppConst.API.SYS_USER}/empList`, params);
  };

  resetPassword = (arr: any) => {
    return ApiHelper.post(`${AppConst.API.SYS_USER}/find/password`, arr);
  };

  getEmployeeDetail = (empNo: string, corpCode: string) => {
    const params = {
      empNo, corpCode
    };    
    return ApiHelper.get(`${AppConst.API.SYS_USER}/empDetail`, params);
  };
}

export default new UserService();
