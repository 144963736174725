/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { observable, action } from 'mobx';
import { message } from 'antd';
import moment from 'moment';
import { MobileRootStore } from 'shared/mobile-stores/RootStore';
import CalendarModel from '../static/CalendarModel';
import OverTimeMonthModel from '../static/OverTimeMonthModel';
import OverTimeWeeklySumModel from '../static/OverTimeWeeklySumModel';
import OverTimeModel from '../static/OverTimeModel';
import OvertimeMonthRegService from '../services/OvertimeCalendarMobileService';

export default class OvertimeCalendarMobileStore {
  @observable
  inProgress: boolean;

  @observable
  selectedWaypoint = '0';

  @observable
  preWeekNumber = '0';

  @observable
  nowWeekNumber = '0';

  @observable
  nextWeekNumber = '0';

  @observable
  empNo = 'me';

  @observable
  monthlyTotalHour = 0;

  @observable
  monthlyClosing = '';

  @observable
  overtimeSumList = [0, 0, 0, 0, 0, 0];

  @observable
  overTimeMaxWeekly = '0';

  @observable
  workTypeName = '';

  @observable
  workType = '';

  @observable
  preWorkSysYearMonth = '0';

  @observable
  nowWorkSysYearMonth = '0';

  @observable
  nextWorkSysYearMonth = '0';

  @observable
  overTimeMonthList: OverTimeMonthModel[];

  @observable
  selectedOvertimeItem: OverTimeMonthModel;

  @observable
  overTimeWeeklySumList: OverTimeWeeklySumModel[];

  @observable
  overTimeModel: OverTimeModel;

  rootStore: MobileRootStore;

  constructor(rootStore: MobileRootStore) {
    this.inProgress = false;
    this.rootStore = rootStore;
    this.overTimeMonthList = [];
    this.overTimeWeeklySumList = [];
    this.empNo = 'me';
    this.monthlyTotalHour = 0;
    this.monthlyClosing = '';
    this.workTypeName = '';
    this.workType = 'X';
    this.overTimeMaxWeekly = '0';
    this.overtimeSumList = [0, 0, 0, 0, 0, 0];
  }

  @action
  async getNowWorkSysWeekNumber(): Promise<void> {
    // 현재주차 가져오기
    this.inProgress = true;

    return OvertimeMonthRegService.getWeekNumber(moment().format('YYYYMMDD'))
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.nowWeekNumber = data.yearWeekly;
        this.preWeekNumber = (Number(data.yearWeekly) - 1).toString();
        this.nextWeekNumber = (Number(data.yearWeekly) + 1).toString();
        this.nowWorkSysYearMonth = data.workSysYearMonth;
        this.preWorkSysYearMonth = (Number(data.workSysYearMonth) - 1).toString();
        this.nextWorkSysYearMonth = (Number(data.workSysYearMonth) + 1).toString();
        this.getMonthOverTime(data.workSysYearMonth);
        this.getWorkType(this.empNo, data.workSysYearMonth);

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiFail(error);
        this.inProgress = false;
      });
  }

  @action
  async getMonthOverTime(workSysYearMonth: string): Promise<void> {
    // monthly 달력 가져오기
    this.inProgress = true;

    this.nowWorkSysYearMonth = workSysYearMonth;
    this.preWorkSysYearMonth = moment(workSysYearMonth, 'YYYYMM')
      .add(-1, 'months')
      .format('YYYYMM')
      .toString();
    this.nextWorkSysYearMonth = moment(workSysYearMonth, 'YYYYMM')
      .add(1, 'months')
      .format('YYYYMM')
      .toString();

    OvertimeMonthRegService.getMonthOverTime(workSysYearMonth)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.overTimeMonthList = data;

        let calItem = this.overTimeMonthList.filter(calitem => calitem.weekEngName === 'SAT' && calitem.workSysMonthWeekly === '5');
        if (calItem.length > 0) {
          this.overTimeMaxWeekly = calItem[0].workSysMonthWeekly;
        } else {
          calItem = this.overTimeMonthList.filter(calitem => calitem.weekEngName === 'SAT' && calitem.workSysMonthWeekly === '4');
          this.overTimeMaxWeekly = calItem[0].workSysMonthWeekly;
        }

        this.getWorkType(calItem[0].empNo, workSysYearMonth);
        this.getMonthlyTotalOverTimeSum();

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiFail(error);
        this.inProgress = false;
      });
  }

  @action
  async getWorkType(empNo: string, nowWorkSysYearMonth: string): Promise<void> {
    OvertimeMonthRegService.getWorkType(empNo, nowWorkSysYearMonth)
      .then(response => {
        const data = response.data.data || [];
        if (data.length > 0) {
          this.workTypeName = data[0];
          this.workType = data[1];
        }
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiFail(error);
        this.inProgress = false;
      });
  }

  @action
  getOverTimeMonthListMod(overTimeType: any, overtimeWorkHours: any, overTimeYmd: any, pjCode: any) {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of this.overTimeMonthList) {
      if (item.overTimeYmd === overTimeYmd) {
        item.overTimeType = overTimeType;
        item.overtimeWorkHours = overtimeWorkHours;
        item.apprSts = 'A';
        item.pjCode = pjCode;
      }
    }
  }

  @action
  getMonthlyTotalOverTimeSum() {
    let maxWeekly = '1';
    let sumHour = 0;

    this.overTimeMonthList.forEach(item => {
      if (maxWeekly !== item.workSysMonthWeekly) {
        maxWeekly = item.workSysMonthWeekly;
      }
      if ((item.apprSts === 'A' || item.apprSts === 'S') && item.reducYn === null && item.leaveYn === null) {
        if (item.overTimeType === 'OT') sumHour += item.overtimeWorkHours;
        else if (item.overTimeType === 'AB') sumHour -= item.overtimeWorkHours;
      }
      maxWeekly = item.workSysMonthWeekly;
    });

    const overtimeAvr = sumHour / Number(maxWeekly);
    const monthlyTotalHour = 40 + overtimeAvr;
    this.monthlyTotalHour = monthlyTotalHour;
  }

  @action
  async createOverTime(overtime: OverTimeMonthModel) {
    // 연장근로 등록
    this.inProgress = true;
    const overtimeItem: OverTimeModel = {
      rowStatus: overtime.rowStatus,
      corpCode: overtime.corpCode,
      empNo: overtime.empNo,
      overTimeYmd: overtime.overTimeYmd,
      overTimeType: overtime.overTimeType,
      pjCode: overtime.pjCode,
      overtimeWorkHours: overtime.overtimeWorkHours,
      apprSts: overtime.apprSts
    };

    OvertimeMonthRegService.createOverTime(overtimeItem)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.getMonthOverTime(this.nowWorkSysYearMonth);
        // this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiFail(error);
        this.inProgress = false;
      });
  }

  @action
  async updateOverTime(overtime: OverTimeMonthModel) {
    // 연장근로 수정
    this.inProgress = true;
    const overtimeItem = {
      rowStatus: overtime.rowStatus,
      corpCode: overtime.corpCode,
      empNo: overtime.empNo,
      overTimeYmd: overtime.overTimeYmd,
      overTimeType: overtime.overTimeType,
      pjCode: overtime.pjCode,
      overtimeWorkHours: overtime.overtimeWorkHours,
      apprSts: overtime.apprSts
    };

    return OvertimeMonthRegService.updateOverTime(overtimeItem)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];

        this.getMonthOverTime(this.nowWorkSysYearMonth);
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiFail(error);
        this.inProgress = false;
      });
  }

  @action
  async deleteOverTime(empNo: string, overTimeYmd: string, overTimeType: string) {
    // 연장근로 삭제
    this.inProgress = true;

    return OvertimeMonthRegService.deleteOverTime(empNo, overTimeYmd, overTimeType)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.getMonthOverTime(this.nowWorkSysYearMonth);
        // this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiFail(error);
        this.inProgress = false;
      });
  }

  @action
  setSelectedOvertimeItem(overtimeItem: OverTimeMonthModel) {
    this.selectedOvertimeItem = overtimeItem;
  }

  @action
  async apprOvertimeValidation(overtimeItem: OverTimeMonthModel) {
    this.inProgress = true;
    // 승인요청 전 최종 validation
    let validateSuccess = true;
    const message: string[] = [];

    const overTimeCheck: OverTimeMonthModel[] | undefined = this.overTimeMonthList;

    // let weekSum = 0;
    let monthSum = 0;
    // let weekMax = '0';
    const workSysMonthWeekly = ['1', '2', '3', '4', '5'];
    const weekNumbers = ['SAT', 'SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI'];
    let checkString: string | null = null;
    let weeklyCheck: any | undefined = null;
    const { workType } = this;

    console.log(overtimeItem);
    console.log(workType);

    workSysMonthWeekly.forEach(function MonthWeekly(this: any, weeklyItem) {
      let overTimeWeekAllSum = 0;
      weekNumbers.forEach(function weekNumber(weekEngitem) {
        const calItem = overTimeCheck.filter(calitem => calitem.weekEngName === weekEngitem && calitem.workSysMonthWeekly === weeklyItem);
        if (calItem.length > 0) {
          weeklyCheck = weeklyItem;

          if (calItem[0].overTimeYmd === overtimeItem.overTimeYmd) {
            if (overtimeItem.overTimeType === 'OT') {
              overTimeWeekAllSum += Number(overtimeItem.overtimeWorkHours);
            } else if (calItem[0].overTimeType === 'AB') {
              overTimeWeekAllSum -= Number(overtimeItem.overtimeWorkHours);
            }
          } else if ((calItem[0].apprSts === 'A' || calItem[0].apprSts === 'S') && calItem[0].reducYn == null) {
            if (calItem[0].overTimeType === 'OT') {
              overTimeWeekAllSum += calItem[0].overtimeWorkHours;
            } else if (calItem[0].overTimeType === 'AB') {
              overTimeWeekAllSum -= calItem[0].overtimeWorkHours;
            }
          }
        }
      });
      if (overTimeWeekAllSum > 24 && workType === 'B') {
        overtimeItem.workSysMonthWeekly = weeklyCheck;
        checkString = 'weekOver';
      }

      monthSum += Number(overTimeWeekAllSum);
      console.log(overTimeWeekAllSum);
    });

    // this.overTimeMonthList.forEach(item => {
    //   if (item.overTimeYmd === overtimeItem.overTimeYmd) {
    //     if (overtimeItem.overTimeType === 'OT') {
    //       weekSum += Number(overtimeItem.overtimeWorkHours);
    //       monthSum += Number(overtimeItem.overtimeWorkHours);
    //     } else if (overtimeItem.overTimeType === 'AB') {
    //       weekSum -= Number(overtimeItem.overtimeWorkHours);
    //       monthSum -= Number(overtimeItem.overtimeWorkHours);
    //     }
    //   } else if (item.apprSts === 'A' || item.apprSts === 'S') {
    //     if (item.workSysMonthWeekly === overtimeItem.workSysMonthWeekly) {
    //       if (item.overTimeType === 'OT') {
    //         weekSum += item.overtimeWorkHours;
    //       } else if (item.overTimeType === 'AB') {
    //         weekSum -= item.overtimeWorkHours;
    //       }
    //     }
    //     if (item.overTimeType === 'OT') {
    //       monthSum += item.overtimeWorkHours;
    //     } else if (item.overTimeType === 'AB') {
    //       monthSum -= item.overtimeWorkHours;
    //     }
    //   }
    //   weekMax = item.workSysMonthWeekly;
    //   console.log(`MonthSum : ${monthSum}`);
    // });

    // console.log(`MonthSum : ${monthSum}`);
    // console.log(`weeklyCheck : ${weekMax}`);

    if (workType === 'A') {
      if (monthSum / Number(weeklyCheck) > 12) {
        validateSuccess = false;
        message.push('당월 근로가능시간이 초과되었습니다. 조직책임자와 상의하여 근로시간을 조정하거나 대체휴식을 계획하여 주십시오.');
      }
    } else {
      // if (this.workType === 'B')
      // if (weekSum > 24) {
      if (checkString === 'weekOver') {
        validateSuccess = false;
        message.push(`${overtimeItem.workSysMonthWeekly}주차 근로가능시간이 초과되었습니다. 조직책임자와 상의하여 근로시간을 조정하거나 대체휴식을 계획하여 주십시오.`);
      }
      if (monthSum / Number(weeklyCheck) > 12) {
        validateSuccess = false;
        message.push('당월 근로가능시간이 초과되었습니다. 조직책임자와 상의하여 근로시간을 조정하거나 대체휴식을 계획하여 주십시오.');
      }
    }

    if (validateSuccess) {
      overtimeItem.apprSts = 'A';
      await this.updateOverTime(overtimeItem);
      // this.inProgress = false;
      return message;
    }
    this.inProgress = false;
    return message;
  }

  @action
  getRecentlyProjectInfo(): any {
    return OvertimeMonthRegService.getRecentlyProjectInfo()
      .then(response => {
        const data = response.data.data || [];
        return data;
      })
      .catch(error => {});
  }

  @action
  setSelectedWaypoint(waypoint: string) {
    this.selectedWaypoint = waypoint;
  }
}
