import ApiHelper from 'shared/utils/ApiHelper';

const API_BASE = '/timesheet';

class CommonService {
  // Select Projects
  getProjects(reqObj:any) {
    const api = `${API_BASE}/projects`;
    return ApiHelper.get(api,reqObj);
  }

  getPmGroupProjects(reqObj:any) {
    const api = `${API_BASE}/projects/pmGroup`;     
    return ApiHelper.get(api, reqObj);
  }
}
export default new CommonService();
