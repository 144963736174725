class ProjectResourceModel {
  no: number;
  corpCode: string;
  empNo: string;
  empNm: string;
  pjCode: string;
  pjName: string;
  resultYn: string;
  planYn: string;
  regEmpNo: string;
  regEmpName: string;
  regDate: number;
  updatedEmpNo: string;
  updatedEmpName: string;
  updatedDate: number;

  constructor(obj: any) {
    this.no = obj.no;
    this.corpCode = obj.corpCode;
    this.empNo = obj.empNo;
    this.empNm = obj.empNm;
    this.pjCode = obj.pjCode;
    this.pjName = obj.pjName;
    this.resultYn = obj.resultYn;
    this.planYn = obj.planYn;
    this.regEmpNo = obj.regEmpNo;
    this.regEmpName = obj.regEmpName;
    this.regDate = obj.regDate;
    this.updatedEmpNo = obj.updatedEmpNo;
    this.updatedEmpName = obj.updatedEmpName;
    this.updatedDate = obj.updatedDate;
  }
}

export default ProjectResourceModel;
