import { observable, action } from 'mobx';
import ProjectResourceModel from '../static/ProjectResourceModel';
import ProjectResourceService from '../services/ProjectResourceService';
import { RootStore } from 'shared/stores/RootStore';
import AppHelper from 'shared/utils/AppHelper';
import AppConst from 'shared/utils/AppConst';

export default class ProjectResourceStore {
  @observable
  inProgress: boolean;

  @observable
  projectResourceModel: ProjectResourceModel[];

  @observable
  glbDeptCode: string | undefined;

  @observable
  glbDeptName: string | undefined;

  @observable
  selectedRecord: ProjectResourceModel;

  @observable
  visibleDrawer: boolean;

  @observable
  visibleType: string;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = this.rootStore;
    this.visibleDrawer = false;
    this.visibleType = '';
    this.rootStore = rootStore;
  }

  @action
  getProjectResources(pjCode: string, empNo: string, planYn: string, resultYn: string): any {
    const arrayRolse: ProjectResourceModel[] = [];

    this.inProgress = true;
    return ProjectResourceService.getProjectResources(pjCode, empNo, planYn, resultYn)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];

        const formatDate = AppConst.DEFAULT_DATE_TIME_FORMAT;

        // console.log(data);
        data.map((item: any, i: any) => {
          const role = new ProjectResourceModel({
            no: i + 1,
            corpCode: item.corpCode,
            empNo: item.empNo,
            empNm: item.empNm,
            pjCode: item.pjCode,
            pjName: item.pjName,
            resultYn: item.resultYn,
            planYn: item.planYn,
            regEmpNo: item.regEmpNo,
            regEmpName: item.regEmpName,
            regDate: item.regDate ? AppHelper.parseTimestampToDate(item.regDate, formatDate) : '',
            updatedEmpNo: item.updatedEmpNo,
            updatedEmpName: item.updatedEmpName,
            updatedDate: item.updatedDate ? AppHelper.parseTimestampToDate(item.updatedDate, formatDate) : ''
          });
          arrayRolse.push(role);
        });

        this.projectResourceModel = arrayRolse;
        this.inProgress = false;
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }
}
