import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import OvertimeAppMobileStore from '../stores/OvertimeAppMobileStore';
import AppDetailMobileHeaderContainer from '../containers/AppDetailMobileHeaderContainer';
import AppDetailMobileBodyContainer from '../containers/AppDetailMobileBodyContainer';

interface Props extends RouteComponentProps {
  overtimeAppMobileStore?: OvertimeAppMobileStore;
  history: any;
}

const Wrapper = styled.div`
  color: #000000;
  text-align: left;
  /* padding-top: 5px; */
`;

@inject('overtimeAppMobileStore')
@observer
class AppDetailMobileWrapperContainer extends Component<Props> {
  state = {
    headerVisible: true,
    monthlyVisible: false
  };

  componentDidMount() {
    if (this.props.overtimeAppMobileStore!.overTimeMonthList.length === 0) {
      if (localStorage.getItem('overtimeAppDetailEmpNo') !== null) {
        this.props.overtimeAppMobileStore!.setDetailDataForRefresh(
          localStorage.getItem('overtimeAppDetailCorpCode'),
          localStorage.getItem('overtimeAppDetailEmpNo'),
          localStorage.getItem('overtimeAppDetailEmpNm'),
          localStorage.getItem('overtimeAppDetailYyyy'),
          localStorage.getItem('overtimeAppDetailMm'),
          localStorage.getItem('overtimeAppDetailPjCode'),
          localStorage.getItem('overtimeAppDetailAllApprYn'),
          localStorage.getItem('overtimeAppDetailClosingYn'),
          localStorage.getItem('overtimeAppDetailCountsAppr'),
          localStorage.getItem('overtimeAppDetailDeptNm'),
          localStorage.getItem('overtimeAppDetailRowStatus'),
          localStorage.getItem('overtimeAppDetailAfterAverageOverTime'),
          localStorage.getItem('overtimeAppDetailBeforeAverageOverTime'),
          localStorage.getItem('overtimeAppDetailNo')
        );
        this.props.overtimeAppMobileStore!.setSelectYyyymm(localStorage.getItem('overtimeAppDetailYyyy')! + localStorage.getItem('overtimeAppDetailMm')!);
      } else {
        this.props.history.push('/m/timesheet/overtime/overtimeapp');
      }
    }
  }

  handleWaypointEnter = (a: any) => {
    this.setState({ waypointDate: a });

    if (a === null) a = '01';
    const id = `h${a.toString()}`;
    if (document.getElementById(id) !== null) document.getElementById(id)!.scrollIntoView();
  };

  horizontalHandleClick = (a: any) => {
    this.setState({ waypointDate: a });

    if (a === null) a = '01';
    const id = `v${a.toString()}`;
    if (document.getElementById(id) !== null) document.getElementById(id)!.scrollIntoView();
  };

  onChangeHeaderVisible = (visible: boolean) => {
    this.setState({
      headerVisible: visible
    });
  };

  onChangeMonthlyVisible = (visible: boolean) => {
    this.setState({
      monthlyVisible: visible
    });
  };

  onClickGoToList = () => {
    this.props.history.push('/m/timesheet/overtime/overtimeapp');
  };

  render() {
    return (
      <>
        <Wrapper>
          <AppDetailMobileHeaderContainer
            headerVisible={this.state.headerVisible}
            monthlyVisible={this.state.monthlyVisible}
            onChangeMonthlyVisible={this.onChangeMonthlyVisible}
            onClickGoToList={this.onClickGoToList}
          />
          <AppDetailMobileBodyContainer
            {...this.props}
            headerVisible={this.state.headerVisible}
            monthlyVisible={this.state.monthlyVisible}
            onChangeHeaderVisible={this.onChangeHeaderVisible}
          />
        </Wrapper>
      </>
    );
  }
}

export default withRouter(AppDetailMobileWrapperContainer);
