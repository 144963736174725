import React from 'react';
import { Flex } from 'antd-mobile';
import './dashboard.less';
import StorageHelper from 'shared/utils/StorageHelper';
import noticeImage from 'images/notice/Notice_mobile_20191203.jpg';

class DashboardMobile extends React.Component<any, any> {
  render() {
    const loggedUsername = StorageHelper.getItem(StorageHelper.KEY_USER_NAME);
    return (
      // <Flex justify="center" direction="column" style={{ height: '100%' }}>
      // </Flex>
      <div style={{ textAlign: 'center' }}>
        <img src="https://careers.metanet.co.kr/timesheet/Notice_mobile_20191203.jpg" style={{ width: '100%' }} />
      </div>
    );
  }
}

export default DashboardMobile;
