import { Layout } from 'antd';
import LoadingFullScreen from 'components/LoadingFullScreen';
import Sidebar from 'components/Sidebar';
import SidebarTimeSheet from 'components/Sidebar-timesheet';
import { inject } from 'mobx-react';
import React from 'react';
import FeedbackHandler from 'shared/layouts/FeedbackHandler';
import MTNHeader from 'shared/layouts/Header';
import { MenuAssignImplementStore, TMenuAssign } from 'shared/stores/MenuAssignImplementStore';
import { UIStore } from 'shared/stores/UIStore';
import AppConst from 'shared/utils/AppConst';
import MultiLanguageProvider from '../../MultiLanguageProvider';

interface IObserverLoadingProps {
  uiStore?: UIStore;
}
const ObserverLoading = inject(({ uiStore }: IObserverLoadingProps) => ({
  shouldShow: uiStore!.pendingLoading > 0
}))(LoadingFullScreen);

interface InjectedSidebarProps {
  menuAssignImplementStore?: MenuAssignImplementStore;
  uiStore?: UIStore;
  rootMenuCodeOfSidebar: string;
}

const InjectedSidebar = inject(({ menuAssignImplementStore, uiStore }: InjectedSidebarProps) => ({
  sideBarMenuList: menuAssignImplementStore!.listMenuSidebar,
  menuAssignImplementStore,
  currentLanguage: uiStore!.currLanguage.locale,
  getMenuNameByLanguage: (objMenu: TMenuAssign) => menuAssignImplementStore!.getMenuNameByLanguage(objMenu)
}))(Sidebar);

const withNoSidebar = (Component: any) => {
  return (props: any) => (
    <MultiLanguageProvider>
      <Layout style={{ minHeight: '100vh' }}>
        <MTNHeader {...props} />
        <Layout>
          <Layout.Content style={{ background: '#fff' }}>
            <div style={{ height: 'calc(100vh - 64px)', overflowY: 'auto', boxShadow: 'inset 0 0 5px #2f2f2f' }}>
              <div style={{ padding: 24 }}>
                <Component {...props} />
              </div>
            </div>
          </Layout.Content>
        </Layout>
      </Layout>
      <ObserverLoading />
      <FeedbackHandler {...props} />
    </MultiLanguageProvider>
  );
};

const withSidebar = (Component: any) => {
  return (props: any) => {
    return (
      // props: react router params
      <MultiLanguageProvider>
        <Layout style={{ height: '100vh', overflow: 'hidden' }} className="app-hrm">
          <MTNHeader {...props} />
          <Layout>
            <InjectedSidebar />
            <Layout>
              <Layout.Content style={{ background: '#fff' }}>
                <div id={AppConst.layoutConfig.mainLayoutContentId} style={{ height: 'calc(100vh - 64px)', overflowY: 'auto', boxShadow: 'inset 0 0 5px #2f2f2f' }}>
                  <div style={{ padding: AppConst.layoutConfig.mainLayoutContentPadding }}>
                    <Component {...props} />
                  </div>
                </div>
              </Layout.Content>
            </Layout>
          </Layout>
        </Layout>
        <ObserverLoading />
        <FeedbackHandler {...props} />
      </MultiLanguageProvider>
    );
  };
};

const withSidebarTimeSheet = (Component: any) => {
  return (props: any) => {
    return (
      // props: react router params
      <MultiLanguageProvider>
        <Layout style={{ height: '100vh', overflow: 'hidden' }}>
          <MTNHeader />
          <Layout>
            <SidebarTimeSheet {...props} />
            <Layout>
              <Layout.Content style={{ margin: '1px 1px', background: '#fff' }}>
                <div style={{ height: '100%', overflowY: 'auto', padding: 24 }}>
                  <Component {...props} />
                </div>
              </Layout.Content>
            </Layout>
          </Layout>
        </Layout>
        <ObserverLoading />
        <FeedbackHandler {...props} />
      </MultiLanguageProvider>
    );
  };
};

const blankLayout = (Component: any) => {
  return (props: any) => {
    return (
      <MultiLanguageProvider>
        <div>
          <Component {...props} />
        </div>
        <ObserverLoading />
      </MultiLanguageProvider>
    );
  };
};

class LayoutGenerator {
  static withSidebar = withSidebar;
  static withNoSidebar = withNoSidebar;
  static withSidebarTimeSheet = withSidebarTimeSheet;
  static blankLayout = blankLayout;
}
export default LayoutGenerator;
