import React, { Component } from 'react';
import OverTimeContentsHeaderMonthStateDetailContainer from './OverTimeContentsHeaderMonthStateDetailContainer';
import OverTimeContentsHeaderInfoStateDetailContainer from './OverTimeContentsHeaderInfoStateDetailContainer';
import OverTimeContentsBodyStateDetailContainer from './OverTimeContentsBodyStateDetailContainer';

interface Props {
  history?: any;
}

export default class OverTimeMonthWrapperStateDetailContainer extends Component<Props> {
  render(): JSX.Element {
    return (
      <>
        <OverTimeContentsHeaderMonthStateDetailContainer {...this.props} />
        <OverTimeContentsHeaderInfoStateDetailContainer />
        <OverTimeContentsBodyStateDetailContainer />
      </>
    );
  }
}
