/* eslint-disable class-methods-use-this */
import ApiHelper from 'shared/utils/ApiHelper';

import { AxiosResponse } from 'axios';
import WorkTypeChangeModel from '../static/WorkTypeChangeModel';

const API_BASE = '/timesheet';

class WorkhourManagementListService {
  getWorkTypeList(empNm: string, deptCode: any): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/work-type-changes/management`;
    const trimedEmpNm = empNm ? empNm.trim() : '';
    const trimedDeptCode = deptCode ? deptCode.trim() : '';
    const params = {
      empNm: trimedEmpNm,
      deptCode: trimedDeptCode
    };
    return ApiHelper.get(api, params);
  }

  updateWorkTypeChange(workTypeChange: WorkTypeChangeModel): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/work-type-changes`;
    return ApiHelper.put(api, workTypeChange);
  }
}

export default new WorkhourManagementListService();
