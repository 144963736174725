import { observable, action, autorun, runInAction, computed, toJS } from 'mobx';
import ProgramModalSearchModel from 'shared/models/program/ProgramModalSearchModel';
import _ from 'lodash';
import AppConst from 'shared/utils/AppConst';
import { RootStore } from 'shared/stores/RootStore';
import ProgramModel from 'shared/models/program/ProgramModel';
import ProgramService from 'pages/program-management/services/ProgramService';
import uuid from 'uuid';

export class ProgramModalStore {
  @observable
  searchModel: ProgramModalSearchModel;

  @observable
  dataSource: ProgramModel[];

  @observable
  selectedItem: ProgramModel;

  selectedRowKeys: string[];

  @observable
  inProgress = false;

  @observable
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    this.searchModel = {
      progName: '',
      progFileName: '',
      useYn: ''
    };
    this.dataSource = [];
  }

  @action
  find() {
    this.inProgress = true;
    return ProgramService.find(this.searchModel)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const { isSuccessful } = response.data.header;
        if (isSuccessful) {
          const { data } = response.data;
          this.dataSource = data.map((x: any, index: number) => {
            const element = {
              id: uuid.v4(),
              no: index + 1,
              ...x
            };
            return element;
          });
        }

        this.inProgress = false;
      })
      .catch(error => {
        this.inProgress = false;
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  }

  @action
  clearSearchPanel() {
    this.searchModel = {
      progName: '',
      progFileName: '',
      useYn: ''
    };
  }

  @action
  setSelectedItem(selectedItem: any) {
    this.selectedItem = selectedItem;
  }

  @action
  setDataSource(dataSource: ProgramModel[]) {
    this.dataSource = dataSource;
  }

  getDataSource() {
    return this.dataSource;
  }

  @action
  setSelectedRowKeys(selectedRowKeys: string[]) {
    this.selectedRowKeys = selectedRowKeys;
  }
}
