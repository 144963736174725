/* eslint-disable class-methods-use-this */
import ApiHelper from 'shared/utils/ApiHelper';
import { AxiosResponse } from 'axios';

const API_BASE = '/timesheet';

class OvertimeMonthStateService {
  getWeekNumber(date: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/calendars/${date}`;
    return ApiHelper.get(api);
  }

  getMonthOverTimeList(deptCode: string, workSysYearMonth: string, empName: any): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/deptCode/${deptCode}/calendar/${workSysYearMonth}/overtimes/monthly-sum`;
    const trimedEmpName = empName ? empName.trim() : '';
    const params = {
      empName: trimedEmpName
    };
    return ApiHelper.get(api, params);
  }

  getMonthOverTimeAppr(empNo: string, workSysYearMonth: string, pjCode: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/pjCode/${pjCode}/emps/${empNo}/calendar/${workSysYearMonth}/overtimes/monthly-list`;
    return ApiHelper.get(api);
  }

  getWorkType(empNo: string, date: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/emps/${empNo}/work-type-changes/${date}`;
    return ApiHelper.get(api);
  }
}

export default new OvertimeMonthStateService();
