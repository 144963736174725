import ApiHelper from 'shared/utils/ApiHelper';

const API_BASE = '/timesheet';

class UtilizationService {
  getMemberLeaveOverTimeResultPlan(yearMonth: string) {
    const api = `${API_BASE}/utilization-leave-and-overtime-and-plan-and-results/${yearMonth}`;
    return ApiHelper.get(api);
  }

  getTeamLeaveOverTimeResultPlan(deptCode: string | undefined, yearMonth: string) {
    const api = `${API_BASE}/dept/${deptCode}/utilization-leave-and-overtime-and-plan-and-results/${yearMonth}`;
    return ApiHelper.get(api);
  }

  getPmGroupLeaveOverTimeResultPlan(pjCode: String, yearMonth: string) {
    const api = `${API_BASE}/projects/${pjCode}/utilization-leave-and-overtime-and-plan-and-results/${yearMonth}`;
    return ApiHelper.get(api);
  }
  getRecentlyProjectInfo() {
    const api = `${API_BASE}/utilization-leave-and-overtime-and-plan-and-results/project`;
    return ApiHelper.get(api);
  }

  getProjectDetailInfo(pjCode: any) {
    const api = `${API_BASE}/projects/${pjCode}/utilization-leave-and-overtime-and-plan-and-results/info`;
    return ApiHelper.get(api);
  }
}

export default new UtilizationService();
