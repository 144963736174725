import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { UIStore } from '../../shared/mobile-stores/UIStore';
import { ActivityIndicator } from 'antd-mobile';
import MobileLanguageHelper from '../../shared/utils/MobileLanguageHelper';

interface IProps {
  uiStore?: UIStore
}

@inject('uiStore')
@observer
class MobileLoading extends Component<IProps, any> {
  render() {
    return (
      <ActivityIndicator
        toast
        text={`${MobileLanguageHelper.getMessage('common.text.loading')}...`}
        animating={this.props.uiStore!.pendingLoading > 0}
      />
    );
  }
}

export default MobileLoading;