import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import background from 'images/metanet-background.jpg';
import WrappedPasswordFinderFormContainer from '../containers/PasswordFinderFormContainer';

export interface IProps extends RouteComponentProps<{}> {}

export interface IState {
  showModal: boolean;
}

const PasswordFinderPage = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const WrapForm = styled.div`
  width: 350px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

class PasswordFinderView extends React.Component<IProps, IState> {
  render() {
    const backgroundStyle: any = {
      background: '50% 50% / cover no-repeat',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundImage: `url(${background})`
    };
    return (
      <PasswordFinderPage>
        <b style={{ ...backgroundStyle }} />
        <WrapForm>
          <WrappedPasswordFinderFormContainer />
        </WrapForm>
      </PasswordFinderPage>
    );
  }
}

export default PasswordFinderView;
