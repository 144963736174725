import { action } from 'mobx';
import HistoryService from 'shared/services/HistoryService';
import { MobileRootStore } from './RootStore';

export class HistoryMenuAccessStore {

  rootStore: MobileRootStore;

  constructor(rootStore: MobileRootStore) {
    this.rootStore = rootStore;
  }

  /**
   * Keep track accessed Menu for Menu Access History each time User select a certain Menu
   */
  @action
  trackMenuAccess = async (menuCode: string) => {
    try {
      const params = {
        menuCode
      };
      const response = await HistoryService.trackMenuAccess(params);
      const { isSuccessful } = response.data.header;
      if (!isSuccessful) {        
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        return;
      }
    } catch (error) {
      this.rootStore.apiHandleStore.handleApiError(error);
    }
  };
  
}
