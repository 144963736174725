/**
 * Project model for View
 */
class ProjectModel {
  no: number;
  corpCode: string;
  pjCode: string;
  pjName: string;
  pmEmpNo: string;
  pmEmpNm: string;
  pjStartDate: string;
  pjEndDate: string;
  pjStatus: string;
  pjStatusNm: string;
  mainSalesDept: string;
  mainPerformDept: string;
  mainSalesDeptNm: string;
  mainPerformDeptNm: string;
  pjType: string;
  pjTypeNm: string;
  pjCreateType: string;
  pjCreateTypeNm: string;
  rowStaus: string;
  [key: string]: any;

  constructor(obj: any) {
    this.no = obj.no;
    this.corpCode = obj.corpCode;
    this.pjCode = obj.pjCode;
    this.pjName = obj.pjName;
    this.pmEmpNo = obj.pmEmpNo;
    this.pmEmpNm = obj.pmEmpNm;
    this.pjStartDate = obj.pjStartDate;
    this.pjEndDate = obj.pjEndDate;
    this.pjStatus = obj.pjStatus;
    this.pjStatusNm = obj.pjStatusNm;
    this.mainSalesDept = obj.mainSalesDept;
    this.mainPerformDept = obj.mainPerformDept;
    this.mainSalesDeptNm = obj.mainSalesDeptNm;
    this.mainPerformDeptNm = obj.mainPerformDeptNm;
    this.pjType = obj.pjType;
    this.pjTypeNm = obj.pjTypeNm;
    this.pjCreateType = obj.pjCreateType;
    this.pjCreateTypeNm = obj.pjCreateTypeNm;
    this.rowStaus = obj.rowStaus;
  }
}
export default ProjectModel;
