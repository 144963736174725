class MenuSearchModel {
  menuName?: string;
  progName?: string;
  progFileName?: string;
  useYn?: string;
  [key: string]: any;

  constructor(obj: any | MenuSearchModel) {
    this.menuName = obj.menuName;
    this.progName = obj.progName;
    this.progFileName = obj.progFileName;
    this.useYn = obj.useYn;
  }
}
export default MenuSearchModel;
