import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Empty } from 'antd';
import { FormattedMessage } from 'react-intl';

class NotFoundPage extends React.Component {
  render() {
    return (
      <div style={{ textAlign: 'center', paddingTop: '30vh' }}>
        <Empty
          image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTQNz2EQUgO215lil-_AFBm4lNIgocb3M00EWfP-t7rpYgRtnu"
          imageStyle={{
            height: 200
          }}
          description={
            <span>
              <Link to="/">
                <span>
                  <FormattedMessage id="message.click.to.move.home.page" />
                </span>
              </Link>
            </span>
          }
        />
      </div>
    );
  }
}

export default NotFoundPage;
