import _ from 'lodash';
import AppConst from 'shared/utils/AppConst';

class ValidatorHelper {
  isValidTextLength = (value: string, minLength: number, maxLength: number) => {
    return !_.isNull(value) && _.trim(value).length >= minLength && _.trim(value).length <= maxLength;
  };

  /**
   * Validate if a string contain one kind of special character such as '/' (slash), '.', etc only.
   */
  isContainSpecialCharacterOnly = (value: string, specialCharacter: string) => {
    const specialChars = '<>@!#$%^&*()_+[]{}?:;|\'"\\,./~`-=';
    let isContainCharacterOnly = true;
    if (!_.isNull(value)) {
      for (let index = 0; index < specialChars.length; index++) {
        if (specialChars[index] !== specialCharacter && value.indexOf(specialChars[index]) > -1) {
          isContainCharacterOnly = false;
          break;
        }
      }
    }
    return isContainCharacterOnly;
  };

  /**
   * Validate a string which contain at least one space between words
   */
  isTextContainMidleSpace = (value: string) => {
    return !_.isNull(value) && _.isString(value) && !_.isEmpty(value) && value.split(' ').length > 1;
  };

  /**
   * Validate password with rules:
   *  +  Password has lowercase and/or uppercase
   *  +  contains at least 1 special character
   *  +  contains at least 1 number
   *  +  Password must has min = 8 characters and max = 20 characters
   * @param password
   */
  isValidPassword = (password: string) => {
    if (password === undefined || (!password && password.length === 0)) return false; // Empty password is not validated here.
    const lowerCharacterRule = new RegExp(/^(?=.*?[a-z])/); // Password must be lowercase
    const numberRule = new RegExp(/(?=.*?[0-9])/); // contains at least 1 number
    const specialCharacterRule = new RegExp(/(?=.*?[^\w\s])/); // contains at least 1 special character
    const minMaxLengthRule = new RegExp(/.{8,20}$/); // min = 8 characters and max = 20 characters
    const regex = new RegExp(lowerCharacterRule.source + numberRule.source + specialCharacterRule.source + minMaxLengthRule.source);
    return regex.test(password);
  };

  isValidPhoneNumber = (phone: string) => {
    if (!phone && phone.length === 0) return true;
    const numberRule = new RegExp(/^\d+$/); // Only allow number
    const regex = new RegExp(numberRule.source);
    const isValidLength = !!(phone && phone.length <= AppConst.VALIDATOR.maxLength.phoneNumber);
    return regex.test(phone) && isValidLength;
  };

  /* Valid Input Number has maxlength
   */
  isValidInputNumber = (text: string, maxLength: number, isRequied: boolean) => {
    if (!isRequied && !text) return true;
    const numberRule = new RegExp(/^\d+$/); // Only allow number
    const regex = new RegExp(numberRule.source);
    const isValidLength = !!(text && text.length === maxLength);
    return regex.test(text) && isValidLength;
  };

  /**
   * Check if text contain at least 1 special characters
   * @param text: a string
   * @return: true if text contain at least 1 special characters. Otherwise return false
   */
  isTextHasSpecialCharacter = (text: string) => {
    // Regex for Valid Characters i.e. Alphabets, Numbers and Space.
    const regex = /^[A-Za-z0-9 ]+$/;
    const isValid = regex.test(text);
    const isTextHasSpecialCharacter = !isValid;
    return isTextHasSpecialCharacter;
  };

  /**
   * Validate if a string contain one of allowed special character such as '/' (slash), '.', etc only.
   */
  isAllowedSpecialCharacters = (value: string, allowedSpecialCharacters: string[]) => {
    if (_.isNull(allowedSpecialCharacters) || _.isEmpty(allowedSpecialCharacters) || _.isNull(value) || _.isEmpty(value)) return true;
    const specialChars = '<>@!#$%^&*()_+[]{}?:;|\'"\\,./~`-=';
    let isContainCharacterOnly = true;
    for (let index = 0; index < specialChars.length; index++) {
      const foundSpecCharacterIndex = value.indexOf(specialChars[index]);
      if (foundSpecCharacterIndex !== AppConst.NOT_FOUND) {
        for (let j = 0; j < allowedSpecialCharacters.length; j++) {
          if (!allowedSpecialCharacters.includes(value[foundSpecCharacterIndex])) {
            isContainCharacterOnly = false;
            break;
          }
        }
      }
    }
    return isContainCharacterOnly;
  };
}

export default new ValidatorHelper();
