import { observable, action } from 'mobx';
import ProjectModel from 'shared/models/project/ProjectModel';
import CommonProjectMobileService from '../services/CommonProjectMobileService';
import { MobileRootStore } from 'shared/mobile-stores/RootStore';

interface CloseStsModel {
  corpCode: string | null;
  yearMonth: string | null;
  closeSts: string | null;
}

export default class CommonProjectMobileStore {
  @observable
  inProgress: boolean;

  @observable
  projects: ProjectModel[];

  @observable
  selectedProject: ProjectModel;

  @observable
  returnPjName: string;

  @observable
  returnPjCode: string;

  @observable
  visible: boolean;

  rootStore: MobileRootStore;

  constructor(rootStore: MobileRootStore) {
    this.inProgress = false;
    this.rootStore = rootStore;
    this.projects = [];
    this.visible = false;
  }

  @action
  getProjects(pjCode: String, searchPattern: String, actvType: string | undefined) {
    const arrayProjects: ProjectModel[] = [];
    this.inProgress = true;

    console.log(actvType);

    if (searchPattern === 'all') {
      return CommonProjectMobileService.getProjects(pjCode, searchPattern)
        .then(response => {
          this.rootStore.apiHandleStore.handleApiFail(response.data.header);

          const data = response.data.data || [];
          let cnt = 1;
          data.map((item: any) => {
            const project = new ProjectModel({
              no: cnt,
              corpCode: item.corpCode,
              pjCode: item.pjCode,
              mainSalesDept: item.mainSalesDept,
              mainPerformDept: item.mainPerformDept,
              pjName: item.pjName,
              pmEmpNo: item.pmEmpNo,
              pmEmpNm: item.pmEmpNm,
              pjStartDate: item.pjStartDate,
              pjEndDate: item.pjEndDate,
              pjStatus: item.pjStatus,
              pjType: item.pjType,
              pjCreateType: item.pjCreateType,
              rowStatus: 'UPDATE'
            });
            cnt++;

            if (actvType) {
              if (actvType === 'M1') {
                if (item.pjCreateType === '1P') arrayProjects.push(project);
              } else if (actvType === 'M3') {
                if (item.pjCreateType === '4C') arrayProjects.push(project);
              } else {
                arrayProjects.push(project);
              }
            } else {
              arrayProjects.push(project);
            }
          });

          this.projects = arrayProjects;
          this.inProgress = false;
        })
        .catch(error => {
          this.rootStore.apiHandleStore.handleApiFail(error);
          this.inProgress = false;
        });
    } else if (searchPattern === 'pmg') {
      return CommonProjectMobileService.getPmGroupProjects(pjCode, searchPattern)
        .then(response => {
          this.rootStore.apiHandleStore.handleApiFail(response.data.header);

          const data = response.data.data || [];
          let cnt = 1;
          data.map((item: any) => {
            const project = new ProjectModel({
              no: cnt,
              corpCode: item.corpCode,
              pjCode: item.pjCode,
              mainSalesDept: item.mainSalesDept,
              mainPerformDept: item.mainPerformDept,
              pjName: item.pjName,
              pmEmpNo: item.pmEmpNo,
              pmEmpNm: item.pmEmpNm,
              pjStartDate: item.pjStartDate,
              pjEndDate: item.pjEndDate,
              pjStatus: item.pjStatus,
              pjType: item.pjType,
              rowStatus: 'UPDATE'
            });
            cnt++;
            arrayProjects.push(project);
          });

          this.projects = arrayProjects;
          this.inProgress = false;
        })
        .catch(error => {
          this.rootStore.apiHandleStore.handleApiFail(error);
          this.inProgress = false;
        });
    }
  }

  @action
  setReturnPjValue(record: ProjectModel) {
    this.selectedProject = record;
    this.returnPjCode = record.pjCode;
    this.returnPjName = record.pjName;
  }

  @action
  setVisibleModal(visible: boolean) {
    this.visible = visible;
    this.projects = [];
  }
}
