import { observable, action, autorun, runInAction, computed, toJS } from 'mobx';
import EmployeeDrawerSearchModel from 'shared/models/department/EmployeeDrawerSearchModel';
import _ from 'lodash';
import AppConst from 'shared/utils/AppConst';
import { RootStore } from 'shared/stores/RootStore';
import EmployeeModel from 'shared/models/employee/EmployeeModel';
import EmployeeManagementService from 'pages/employee-management/services/EmployeeManagementService';
import uuid from 'uuid';

export class EmployeeDrawerStore {
  @observable
  searchModel: EmployeeDrawerSearchModel;

  @observable
  dataSource: EmployeeModel[];

  @observable
  selectedItem: EmployeeModel;

  selectedRowKeys: string[];

  @observable
  inProgress = false;

  @observable
  rootStore: RootStore;

  /** Current selected row key */
  @observable
  selectedRowKey: string;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    this.searchModel = {
      empName: '',
      empNo: ''
    };
    this.dataSource = [];
  }

  @action
  find() {
    this.inProgress = true;
    const { empNo, empName } = this.searchModel;
    return EmployeeManagementService.findAllEmployee(empNo, empName, '', '', '', '')
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const { isSuccessful } = response.data.header;
        if (isSuccessful) {
          const { data } = response.data;
          this.dataSource = data.map((x: any, index: number) => {
            const element = {
              id: uuid.v4(),
              no: index + 1,
              ...x
            };
            return element;
          });
        }

        this.inProgress = false;
      })
      .catch(error => {
        this.inProgress = false;
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  }

  @action
  clearSearchPanel() {
    this.searchModel = {
      empName: '',
      empNo: ''
    };
  }

  @action
  setSelectedItem(selectedItem: any) {
    this.selectedItem = selectedItem;
  }

  @action
  setDataSource(dataSource: EmployeeModel[]) {
    this.dataSource = dataSource;
  }

  getDataSource() {
    return this.dataSource;
  }

  @action
  setSelectedRowKeys(selectedRowKeys: string[]) {
    this.selectedRowKeys = selectedRowKeys;
  }

  @action
  setCurrentSelectedRow(selectedRowKey: string) {
    this.selectedRowKey = selectedRowKey;
  }
}
