import { observable, action } from 'mobx';
import PmGroupModel from 'shared/models/project/PmGroupModel';
import pmGroupService from '../services/PmGroupService';
import { RootStore } from 'shared/stores/RootStore';

export class PmGroupStore {
  @observable
  pmGroups: PmGroupModel[];

  @observable
  empList: PmGroupModel[];

  @observable
  inProgress: boolean;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.pmGroups = [];
    this.rootStore = rootStore;
  }

  // 사업관리 그룹 인원 조회
  @action
  getPmGroups(dept: string, pjCode: string, pmName: string): any {
    const arrayRoles: PmGroupModel[] = [];
    this.inProgress = true;
    return pmGroupService
      .getPmGroupByPjCode(pjCode)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];

        let cnt = 1;
        data.map((item: any) => {
          const role = new PmGroupModel({
            no: cnt,
            empNo: item.empNo,
            empName: item.empName,
            deptCode: item.deptCode,
            deptName: item.deptName,
            positName: item.positName,
            role: item.role
          });
          cnt += 1;
          arrayRoles.push(role);
        });

        this.pmGroups = arrayRoles;
        this.inProgress = false;
      })
      .catch(error => {
        this.inProgress = false;
      });
  }

  // 사업 그룹에 속해 있지 않은 직원 조회
  @action
  getEmpList(pjCode: string, empName: string, deptCode: string): any {
    const arrayRoles: PmGroupModel[] = [];
    this.inProgress = true;
    return pmGroupService
      .getEmpList(pjCode, empName, deptCode)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        let cnt = 1;
        data.map((item: any) => {
          const role = new PmGroupModel({
            no: cnt,
            empNo: item.empNo,
            empName: item.empName,
            deptName: item.deptName,
            deptCode: item.deptCode,
            positName: item.positName
          });
          cnt += 1;
          arrayRoles.push(role);
        });
        this.empList = arrayRoles;
        this.inProgress = false;
      })
      .catch(error => {
        console.error(`Exception when fetch project ${error}`);
        this.inProgress = false;
      });
  }

  @action
  initPmGroupModel(): void {
    this.pmGroups = [];
  }
  @action
  initEmpListModel(): void {
    this.empList = [];
  }

  @action
  deletePmGroup(pjCode: string, empNo: string) {
    this.inProgress = true;

    return pmGroupService
      .deletePmGroup(pjCode, empNo)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  // 사업그룹 리스트에서 제거
  @action
  filterUtilPlanMemberGroup(key: string): void {
    this.pmGroups = this.pmGroups.filter(item => item.empNo !== key);
  }

  // 직원 리스트에서 제거
  @action
  filterEmpMemberGroup(key: string): void {
    this.empList = this.empList.filter(item => item.empNo !== key);
  }

  @action
  createPmGroup(empNo: string, pjCode: string) {
    this.inProgress = true;

    const obj = new PmGroupModel({
      pjCode: pjCode,
      empNo: empNo
    });

    return pmGroupService
      .createPmGroup(obj)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  // 사업그룹 리스트에 추가
  @action
  addUtilPlanMemberGroup(item: PmGroupModel): void {
    let size = this.pmGroups.length;

    const obj = new PmGroupModel({
      no: size + 1,
      deptCode: item.deptCode,
      deptName: item.deptName,
      empNo: item.empNo,
      empName: item.empName,
      positName: item.positName
    });

    this.pmGroups.push(obj);
  }
}

export default PmGroupStore;
