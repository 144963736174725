import uuid from 'uuid';

class CommonCodeModel {
  id: string;
  representCode: string;
  representCodeName: string;
  commonCode: string;
  krCommonCodeName: string;
  krAbbreviationName: string;
  enCommonCodeName: string;
  enAbbreviationName: string;
  languageList: any[];
  displayOrder: number;
  useYn: string;
  remark: string;
  oldCode: string;
  regEmpNo: string;
  regEmpName: string;
  regDate: number;
  updatedEmpNo: string;
  updatedEmpName: string;
  updatedDate: number;
  changeStatus: string;

  constructor(obj: any, id?: string) {
    this.id = id || uuid.v4();
    this.representCode = obj.representCode;
    this.representCodeName = obj.representCodeName;
    this.commonCode = obj.commonCode;
    this.krCommonCodeName = obj.krCommonCodeName;
    this.krAbbreviationName = obj.krAbbreviationName;
    this.enCommonCodeName = obj.enCommonCodeName;
    this.enAbbreviationName = obj.enAbbreviationName;
    this.languageList = obj.languageList;
    this.displayOrder = obj.displayOrder;
    this.useYn = obj.useYn;
    this.remark = obj.remark;
    this.oldCode = obj.oldCode;
    this.regEmpNo = obj.regEmpNo;
    this.regEmpName = obj.regEmpName;
    this.regDate = obj.regDate;
    this.updatedEmpNo = obj.updatedEmpNo;
    this.updatedEmpName = obj.updatedEmpName;
    this.updatedDate = obj.updatedDate;
    this.changeStatus = obj.changeStatus;
  }
}

export default CommonCodeModel;
