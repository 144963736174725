import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import RegMobileWrapperContainer from '../containers/RegMobileWrapperContainer';

@inject('authStore')
@observer
export default class OvertimeRegMobileView extends Component<any, any> {
  render() {
    return <RegMobileWrapperContainer {...this.props} />;
  }
}
