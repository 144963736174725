class GroupModel {
  id: number;
  title: React.ReactNode;
  parent: number;
  root: boolean;
  titleName: string;
  openYn: boolean;

  constructor(obj: any) {
    this.id = obj.id;
    this.title = obj.title;
    this.parent = obj.parent;
    this.root = obj.root;
    this.titleName = obj.titleName;
    this.openYn = obj.openYn;
  }
}
export default GroupModel;
