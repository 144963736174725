import axios from 'axios';
import AppConst from './AppConst';
import StorageHelper from './StorageHelper';

const { API_URL } = AppConst;
// const API_URL = 'http://152.149.58.66';

class ApiHelper {
  getApiHeader = () => {
    return {
      headers: {
        Authorization: StorageHelper.getItem(StorageHelper.KEY_ACCESS_TOKEN),
        'Accept-Language': StorageHelper.getItem(StorageHelper.KEY_CURR_LANGUAGE),
        'X-MENU-CODE': StorageHelper.getItem(StorageHelper.KEY_LATEST_MENU_CODE_TRACKED)
      }
    };
  };

  getApiUrlBase = () => {
    return API_URL;
  };

  /**
   * Do GET request
   * @param {string} api such as /system/users
   */
  get(api: string, params?: object) {
    return axios.get(API_URL + api, { ...this.getApiHeader(), params });
  }

  /**
   * Do POST request
   * @param {string} api such as /auth/login
   * @param {JSON} data such as { username, password }
   */
  post(api: string, data: any) {
    return axios.post(API_URL + api, data, this.getApiHeader());
  }

  postwithoutToken = (api: string, data: any) => {
    const headers = {
      headers: {
        'Accept-Language': AppConst.LANG_KOKR
      }
    };
    return axios.post(API_URL + api, data, headers);
  };

  put(api: string, data: any) {
    return axios.put(API_URL + api, data, this.getApiHeader());
  }

  /**
   * Get current selected Corp Code of logged User
   * @returns {string} corpCode: corp code
   */
  getCurrCorpCode = () => {
    const corpCode = StorageHelper.getItem(StorageHelper.KEY_CURR_CORP_CODE);
    return corpCode ? `corpCode=${corpCode}` : '';
  };

  /**
   *
   * @param api
   * @param data : list of Json data
   */
  delete(api: string, data: any) {
    return axios.delete(API_URL + api, { data, headers: this.getApiHeader().headers });
  }
}

export default new ApiHelper();
