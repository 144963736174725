import { action, observable } from 'mobx';
import AssignmentTaskModel from 'shared/models/authority/AssignmentTaskModel';
import AuthorityModel from 'shared/models/authority/AuthorityModel';
import { RootStore } from 'shared/stores/RootStore';
import AppConst from 'shared/utils/AppConst';
import AppHelper from 'shared/utils/AppHelper';
import LanguageHelper from 'shared/utils/LanguageHelper';
import AuthorityService from '../services/AuthorityService';

interface IDeleteParam {
  empNo: string;
  roleCode: string;
  id?: string;
}

export interface ISearchParameter {
  roleCode: string;
  deptName?: string;
  empName?: string;
}

export class AuthorityStore {
  @observable
  authorities: AuthorityModel[];

  // Save list all Role Authority (create, update, delete)
  @observable
  assignmentTasks: AssignmentTaskModel[];

  // Save list Role Authority from API
  @observable
  assignmentTasksOrigin: AssignmentTaskModel[];

  // Save list assignment task from API
  @observable
  assignmentTasksToRemove: IDeleteParam[];

  // Save selected row assignment task to delete
  @observable
  selectedAuthority: AuthorityModel;

  @observable
  assignmentTasksToUpSert: AssignmentTaskModel[];

  @observable
  inProgress: boolean;

  @observable
  searchParamAuthority: any;

  @observable
  selectedAssignmentTask: any;

  @observable
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.authorities = [];
    this.assignmentTasks = [];
    this.assignmentTasksToRemove = [];
    this.searchParamAuthority = '';
    this.assignmentTasksToUpSert = [];
    this.rootStore = rootStore;
  }

  @action
  apllyResponseAuthority = (response: any) => {
    const data = response.data.data || [];
    const arrayAuthority: AuthorityModel[] = [];

    data.map((item: any) => {
      const role = new AuthorityModel();
      role.deptCode = item.deptCode;
      role.deptName = item.deptName;
      role.remark = item.remark;
      role.roleCode = item.roleCode;
      role.roleName = item.roleName;
      role.rowStatus = item.rowStatus;
      arrayAuthority.push(role);
      return arrayAuthority;
    });
    return arrayAuthority;
  };

  @action
  getAuthorityByCondition() {
    this.inProgress = true;
    let switchService;
    let userNameSearch: string;

    if (this.searchParamAuthority && Object.keys(this.searchParamAuthority).length) {
      userNameSearch = this.searchParamAuthority.empName && this.searchParamAuthority.empName;
      switchService = AuthorityService.getAuthorityByCondition(this.searchParamAuthority);
    } else {
      switchService = AuthorityService.getAuthorityByCondition({});
    }
    return switchService
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const arrayAuthority = this.apllyResponseAuthority(response);
        if (userNameSearch) {
          arrayAuthority.map((item: any) => {
            item.empName = userNameSearch;
            return arrayAuthority;
          });
        }
        this.authorities = arrayAuthority;
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  @action
  getAssignmentTaskBycondition(roleCode: string, deptName: string, empName: string) {
    const arrayAssignmentTask: AssignmentTaskModel[] = [];
    this.inProgress = true;
    return AuthorityService.getAssignmentTaskBycondition(roleCode, deptName, empName)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];

        data.map((item: any) => {
          const assignment = new AssignmentTaskModel();
          assignment.creator = item.creator;
          assignment.empName = item.empName;
          assignment.deptName = item.deptName;
          assignment.empNo = item.empNo;
          assignment.endDate = item.endDate;
          assignment.positName = item.positName;
          assignment.remark = item.remark;
          assignment.roleCode = item.roleCode;
          assignment.roleName = item.roleName;
          assignment.rowStatus = item.rowStatus;
          assignment.startDate = item.startDate;
          assignment.regEmpNo = item.regEmpNo;
          assignment.regDate = item.regDate;
          assignment.updatedEmpNo = item.updatedEmpNo;
          assignment.updatedDate = item.updatedDate;
          assignment.changeStatus = AppConst.ActionType.READ;
          assignment.regEmpName = item.regEmpName;
          assignment.updatedEmpName = item.updatedEmpName;
          arrayAssignmentTask.push(assignment);
          return arrayAssignmentTask;
        });

        this.assignmentTasks = arrayAssignmentTask;
        this.assignmentTasksOrigin = arrayAssignmentTask;
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  reloadAssignmentTask = () => {
    const { roleCode } = this.selectedAuthority;
    const { deptName, empName } = this.searchParamAuthority;
    this.getAssignmentTaskBycondition(roleCode, deptName, empName);
  };

  summaryParamsUpsert = (arr: any) => {
    arr.map((item: any) => {
      delete item.changeStatus;
      delete item.id;
      delete item.key;
      delete item.no;
      return arr;
    });
    return arr;
  };

  summaryParamsDelete = (arrayToDelete: any) => {
    const newArrayToDelete: any[] = [];
    arrayToDelete.length > 0 &&
      arrayToDelete.map((value: any) => {
        newArrayToDelete.push({
          empNo: value.empNo,
          roleCode: value.roleCode
        });
        return arrayToDelete;
      });
    return newArrayToDelete;
  };

  @action
  submitAssignmentTaskData() {
    this.inProgress = true;
    const self = this;

    const arrayToInsert = this.summaryParamsUpsert(
      this.assignmentTasks.filter(function filterItemToInsert(item) {
        return item.changeStatus === AppConst.ActionType.CREATE;
      })
    );
    const arrayToUpdate = this.summaryParamsUpsert(
      this.assignmentTasks.filter(function filterItemToUpdate(item) {
        return item.changeStatus === AppConst.ActionType.UPDATE;
      })
    );
    const arrayToDelete = this.summaryParamsDelete(
      this.assignmentTasks.filter(function filterItemToUpdate(item) {
        return item.changeStatus === AppConst.ActionType.DELETE;
      })
    );

    if (arrayToInsert.length === 0 && arrayToUpdate.length === 0 && arrayToDelete.length === 0) {
      this.inProgress = false;
      AppHelper.showMessage('warning', LanguageHelper.getMessage('message.nothing.submit'));
      return;
    }

    const promises: any[] = [];
    if (arrayToInsert.length > 0) {
      promises.push(AuthorityService.insertAssignmentTask(arrayToInsert));
    }
    if (arrayToUpdate.length > 0) {
      promises.push(AuthorityService.updateAssignmentTask(arrayToUpdate));
    }
    if (arrayToDelete.length > 0) {
      promises.push(AuthorityService.deleteAssignmentTask(arrayToDelete));
    }

    Promise.all(promises)
      .then(function(responses) {
        self.rootStore.apiHandleStore.handleAllApiResult(responses);
        self.reloadAssignmentTask();
        self.inProgress = false;
        self.assignmentTasksToUpSert = [];
        self.assignmentTasksToRemove = [];
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  @action
  updateAssignmentTaskToUpsert(id: any, newValues: any) {
    const updateIndex = this.assignmentTasks.findIndex(item => item.id === id);
    const oldObj: AssignmentTaskModel = this.assignmentTasks[updateIndex];
    if (oldObj) {
      let actionStatus = oldObj.changeStatus;
      if(oldObj.changeStatus === AppConst.ActionType.READ && newValues.changeStatus === AppConst.ActionType.UPDATE) {
        actionStatus = AppConst.ActionType.UPDATE;
      }

      this.assignmentTasks[updateIndex] = {
        ...this.assignmentTasks[updateIndex],
        ...newValues,
        changeStatus: actionStatus
      };
    }
  }

  validateSpaceText = (value: any) => {
    const trimValue = value.trim();
    return trimValue;
  };

  @action
  setSelectedAuthority(value: any) {
    this.selectedAuthority = value;
  }

  @action
  setAssignmentTasksToRemove(arr: IDeleteParam[]) {
    this.assignmentTasksToRemove = arr;
  }

  @action
  setSearchParamAuthority(arr: any) {
    this.searchParamAuthority = arr;
  }

  @action
  setSelectedAssignmentTask(item: any) {
    this.selectedAssignmentTask = item;
  }

  @action
  resetAssignmentTask = () => {
    this.assignmentTasksToRemove = [];
    this.assignmentTasksToUpSert = [];
    this.selectedAssignmentTask = null;
  };

  @action
  exportExcel() {
    const params: ISearchParameter = {
      roleCode: this.selectedAuthority.roleCode,
      deptName: this.searchParamAuthority.deptName,
      empName: this.searchParamAuthority.empName
    };

    return AuthorityService.exportExcelFile(params)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const url = response.data.data.url || '';
        if (url && url !== '') {
          AppHelper.downloadExcel(url);
        }
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  }
}
