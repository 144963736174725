import { computed } from 'mobx';

/**
 * Project model for View
 */
class HrAssignReqTimeLineGroupModel {
  id: number;
  title: string;

  constructor(obj: any) {
    this.id = obj.id;
    this.title = obj.title;
  }
}
export default HrAssignReqTimeLineGroupModel;
