import { Drawer, List, Menu, NavBar } from 'antd-mobile';
import { Icon } from 'antd';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { UIStore } from 'shared/mobile-stores/UIStore';
import StorageHelper from 'shared/utils/StorageHelper';
import MenuModel from 'shared/mobile-models/sidebar/SidebarModel';
import { AuthStore } from '../../login/stores/AuthStore';
import { MobileHeaderSidebarStore } from '../stores/MobileHeaderSidebarStore';

interface IProps {
  children?: any;
  history: any;
  authStore?: AuthStore;
  mobileHeaderSidebarStore?: MobileHeaderSidebarStore;
  uiStore?: UIStore;
}

@inject('authStore', 'mobileHeaderSidebarStore', 'uiStore')
@observer
class MobileHeaderSidebar extends React.Component<IProps, any> {
  state = {
    show: false
  };

  handleClick = (e: any) => {
    // e.preventDefault(); // Fix event propagation on Android
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      show: !this.state.show
    });
  };

  onMenuSelect = (menu: MenuModel) => {
    if (!menu.progPath) {
      if (!this.props.mobileHeaderSidebarStore!.openedMenu.includes(menu.id)) {
        this.props.mobileHeaderSidebarStore!.openedMenu.push(menu.id);
      } else {
        this.props.mobileHeaderSidebarStore!.openedMenu = this.props.mobileHeaderSidebarStore!.openedMenu.filter(x => x !== menu.id);
      }
    } else if (!menu.children && this.props.history.location.pathname !== menu.progPath) {
      this.props.mobileHeaderSidebarStore!.activeMenu = '';
      this.props.history.push(menu.progPath);
    }
  };

  onSubMenuSelect = (value: any, subMenu: MenuModel[]) => {
    this.setState({ show: false });
    [this.props.mobileHeaderSidebarStore!.activeMenu] = value; // set active menu = value [0]
    const subMenuSelected = subMenu.find(sm => sm.id === value[0]);
    if (subMenuSelected && this.props.history.location.pathname !== subMenuSelected.progPath) {
      // Update local storage
      // Purpose: API calling need 'menu_code' in header params
      StorageHelper.setItem(StorageHelper.KEY_LATEST_MENU_CODE_TRACKED, subMenuSelected.menuCode!);
      this.props.history.push(subMenuSelected.progPath);
      
      // 4. Track Menu Access History      
      this.props.mobileHeaderSidebarStore!.rootStore.historyMenuAccessStore.trackMenuAccess(subMenuSelected.menuCode!);
    }
  };

  getMenuName = (languageList: any[]): any => {
    if (!languageList) {
      return '';
    }
    const language = languageList.find(lang => lang.language === this.props.uiStore!.currLanguage.locale.toUpperCase());
    if (language) return language.menuName;
    return '';
  };

  redirectToUserPage = () => {
    this.props.mobileHeaderSidebarStore!.activeMenu = '';
    this.props.history.push('/m/user-info');
  };

  getMenuByRoute = (route: string) => {
    return this.props.mobileHeaderSidebarStore!.menuAssignedList.find((x: any) => x.progPath === route) || ({} as any);
  };

  render() {
    const { menuTree, openedMenu, activeMenu } = this.props.mobileHeaderSidebarStore!;
    let arrowIcon: any = 'empty';
    const sidebar = (
      <List>
        {menuTree.map(menu => {
          let subMenu: any;
          if (menu.children) {
            subMenu = menu.children.map(sub => ({ value: sub.id, label: this.getMenuName(sub.menuName) }));
            arrowIcon = openedMenu.includes(menu.id) ? 'down' : 'horizontal';
          }
          return menu.isShow ? (
            <React.Fragment key={menu.id}>
              <List.Item arrow={arrowIcon} multipleLine onClick={() => this.onMenuSelect(menu)}>
                {this.getMenuName(menu.menuName)}
              </List.Item>
              {subMenu && openedMenu.includes(menu.id) ? (
                <Menu className="mobile-sub-menu" data={subMenu} value={[activeMenu]} level={1} onChange={(subId: any) => this.onSubMenuSelect(subId, menu.children!)} />
              ) : null}
            </React.Fragment>
          ) : null;
        })}
      </List>
    );

    return (
      <div className={`mobile-cmp-container + ${this.state.show ? 'menu-active' : ''}`} style={{ height: '100%' }}>
        <NavBar
          mode="dark"
          icon={<Icon type="menu" />}
          onLeftClick={this.handleClick}
          rightContent={<Icon type="user" onClick={this.redirectToUserPage} />}
          className="top-nav-bar"
        >
          {this.getMenuName(this.getMenuByRoute(this.props.history.location.pathname).languages)}
        </NavBar>
        <Drawer
          className="my-drawer"
          style={{ minHeight: document.documentElement.clientHeight - 45 }}
          // enableDragHandle
          contentStyle={{ color: '#A6A6A6', textAlign: 'center', height: document.documentElement.clientHeight - 45 }}
          sidebar={sidebar}
          open={this.state.show}
          onOpenChange={this.handleClick}
        >
          {this.props.children}
        </Drawer>
      </div>
    );
  }
}

export default MobileHeaderSidebar;
