import ApiHelper from 'shared/utils/ApiHelper';
import AppConst from 'shared/utils/AppConst';

const API_BASE_EMPLOYEE = AppConst.API.SYS_EMP;

class EmployeeManagementService {
  getAllData = (api: any) => {
    return ApiHelper.get(api);
  };

  exportExcelFile = (params: any) => {
    const getOriginMemberYn = (value?: any) => {
      if (value === 'true') return true;
      if (value === 'false') return false;
      return undefined;
    };
    return ApiHelper.get(`${AppConst.API.SYS_EMP}/export`, {
      empNo: params.empNo || undefined,
      empName: params.empName || undefined,
      deptCode: params.deptCode || undefined,
      originMemberYn: getOriginMemberYn(params.originMemberYn),
      deptName: params.deptName || undefined
    });
  };

  deleteEmployees = (arr: string[]) => {
    const api = API_BASE_EMPLOYEE;
    return ApiHelper.delete(api, arr);
  };

  insertEmployees = (arr: any[]) => {
    const api = API_BASE_EMPLOYEE;
    arr.map((item: any) => {
      delete item.changeStatus;
      delete item.id;
    });
    return ApiHelper.post(api, arr);
  };

  updateEmployees = (arr: any[]) => {
    const api = API_BASE_EMPLOYEE;
    arr.map((item: any) => {
      delete item.changeStatus;
      delete item.id;
    });
    return ApiHelper.put(api, arr);
  };

  findAllEmployee = (empNo: string, empName: string, deptCode: string, originMemberYn: string, deptName: string, officeCode: string) => {
    const params = {
      empNo,
      empName,
      deptCode,
      originMemberYn,
      deptName,
      officeCode
    };
    return ApiHelper.get(AppConst.API.SYS_EMP, params);
  };

  findConcurrentEmployee = (searchParams: any, urlApi: string) => {
    return ApiHelper.get(urlApi, searchParams);
  };

  getEmployeeDetail = (empNo: string) => {
    const params = {
      empNo
    };
    return ApiHelper.get(AppConst.API.SYS_EMP, params);
  };
}

export default new EmployeeManagementService();
