import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import {Icon } from 'antd';
import { MsalProvider, useMsal, useAccount, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import background from 'images/metanet-background.jpg';
// eslint-disable-next-line import/extensions
import styled from 'styled-components';
// eslint-disable-next-line import/extensions
import { msalConfig, loginRequest} from "./authConfig";
// eslint-disable-next-line import/extensions
import { AuthStore } from '../../../shared/stores/AuthenticationStore';
// eslint-disable-next-line import/extensions

export interface IState {
  showModal: boolean;
}

export interface IProps extends RouteComponentProps<any> {
  authStore?: AuthStore;
}

const LoginPage = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
`;

const WrapLoginForm = styled.div`
  width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

@inject('authStore')
@observer
class LoginSsoView extends React.Component<IProps, IState> {
  qty = 0;
  account1: any;

  componentDidMount(): void {
    
    setTimeout(() => {

      
      const ousername: string = sessionStorage.getItem('ousername') ?? "x";
      const msHrmSso: string = sessionStorage.getItem('msHrmSso') ?? "x";
      sessionStorage.removeItem(msHrmSso);
      // eslint-disable-next-line no-unused-expressions
      this.props.authStore?.loginSso(ousername, msHrmSso);      
    }, 3000);
  }

  // sso = () => {        
  //   let sessionUserName: any;
  //   let sessionData: any;

  //   alert(sessionStorage.length);

  //   for (let i = 0; i < sessionStorage.length; i++){
  //     const key = sessionStorage.key(i) ?? "x";
  //     const ss = sessionStorage.getItem(key) ?? "x";
  //     if(ss.includes("login.windows.net")){
  //         sessionData = JSON.parse(ss);        
  //       if(sessionData.username !== undefined){
  //         sessionUserName = sessionData.username;
  //       }
  //       console.log(sessionUserName);
  //     }      
  //   }  
  //   return sessionUserName;
  // };
  
  // MainContent = () => {
    
  //   const { instance, accounts } = useMsal();
  //   const account = useAccount(accounts[0] || {});
  //   this.account1 = useAccount(accounts[0] || {});
    
  //   if(sessionStorage.getItem('msHrmSso') === null){
  //     instance.loginRedirect(loginRequest);
  //     sessionStorage.setItem('msHrmSso', '1st');
  //     const ousername: string = this.account1?.username ?? "x";
  //     sessionStorage.setItem('ousername', ousername);
  //     alert(ousername + " : 123123");
  //     this.account1 = useAccount(accounts[0] || {});
  //     sessionStorage.removeItem('hrm_jwtToken');
  //   }else if(sessionStorage.getItem('msHrmSso') === '1st'){
  //     setTimeout(() => {
  //       if(account != null){
  //         const ousername: string = account?.username ?? "x";
  //         sessionStorage.setItem('ousername', ousername);
  //         sessionStorage.setItem('msHrmSso', 'sso');
  //         alert(ousername + " : bbbbbbbbbbb");
  //       }else{
  //         this.sso();
  //       }
  //     }, 5000);      
  //     // if(account != null){
  //     //   const ousername: string = account?.username ?? "x";
  //     //   sessionStorage.setItem('ousername', ousername);
  //     //   sessionStorage.setItem('msHrmSso', 'sso');
  //     //   alert(ousername + " : bbbbbbbbbbb");
  //     // }else{
  //     //   this.sso();
  //     // }
  //   }else if(sessionStorage.getItem('msHrmSso') === 'sso'){
  //     if(account != null){
  //       setTimeout(() => {
  //         const ousername: string = this.account1?.username ?? "x";
  //         sessionStorage.setItem('ousername', ousername);
  //         sessionStorage.setItem('msHrmSso', 'sso');
  //       }, 5000);      
  //     }else {
  //       this.sso();
  //     }
  //   }  

  //   return (
  //       <>
  //       </>
  //   );
  // };

  MainContent1 = () => {
    const { instance } = useMsal();
    if(sessionStorage.getItem('msHrmSso') === null || sessionStorage.length < 2){
      instance.loginRedirect(loginRequest);
      sessionStorage.setItem('msHrmSso', '{"type" : "1st"}');
    }        
    return (
        <>
        </>
    );
  };

  MainContent2 = () => {
    
    let sessionUserName: any;
    let sessionData: any;

    for (let i = 0; i < sessionStorage.length; i++){
      const key = sessionStorage.key(i) ?? "x";
      const ss = sessionStorage.getItem(key) ?? "x";
      if(ss.includes("login.windows.net")){
          sessionData = JSON.parse(ss);        
        if(sessionData.username !== undefined){
          sessionUserName = sessionData.username;
        }
      }      
    }  
    sessionStorage.setItem('ousername', sessionUserName);
    sessionStorage.setItem('msHrmSso', 'sso');
    
    return (
        <>
        </>
    );
  };

  SignInSignOutButton = () => {
    
    return (
        <>          
        </>
    );
};

PageLayout = (props: any) => {
    return (
        <>
        {props.children}
        </>
    );
};
  

  render(): JSX.Element {
    const msalInstance = new PublicClientApplication(msalConfig);

    const backgroundStyle: any = {
      background: '50% 50% / cover no-repeat',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundImage: `url(${background})`
    };

    // AuthenticatedTemplate MS 인증시 활성화
    // UnauthenticatedTemplate MS 비 인증시 활성화

    return (
      <div>
      <LoginPage>
        <b style={{ ...backgroundStyle }} />
        <WrapLoginForm>
          <h1 style={{ color: '#FFF', textAlign: 'center', fontSize: 40, marginBottom: 12, position: 'relative' }}>
            <Icon type="clock-circle" style={{ fontSize: 36 }} /> 
            <span style={{ paddingLeft: 24 }}>Authentication in progress</span>
          </h1>
        </WrapLoginForm>          
      </LoginPage>
      <MsalProvider instance={msalInstance}>
        <this.PageLayout>
          <AuthenticatedTemplate> 
            <this.MainContent2 />  
          </AuthenticatedTemplate>  
          <UnauthenticatedTemplate>
            <this.MainContent1 />  
          </UnauthenticatedTemplate>
        </this.PageLayout>            
      </MsalProvider>      
      </div>
    );
  }

}

export default LoginSsoView;
