import uuid from 'uuid';

class AuthorityModel {
  id: string; // ID of FE side
  deptCode: string;
  deptName: string;
  remark: string;
  roleCode: string;
  roleName: string;
  rowStatus: string;
  [key: string]: any;

  constructor(obj?: any) {
    this.id = uuid.v4();
    if (!obj) return;
    this.deptCode = obj.deptCode;
    this.deptName = obj.deptName;
    this.remark = obj.remark;
    this.roleCode = obj.roleCode;
    this.roleName = obj.roleName;
    this.rowStatus = obj.rowStatus;
  }
}

export default AuthorityModel;
