import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import { Modal, SearchBar, List } from 'antd-mobile';
import ProjectModel from 'shared/models/project/ProjectModel';
import CommonProjectMobileStore from '../stores/CommonProjectMobileStore';
import { isIOS } from 'react-device-detect';

interface Props {
  commonProjectMobileStore?: CommonProjectMobileStore;
  searchPattern?: string;
  actvType?: string;
}

const heightChooser = (length: number | null): string => {
  // if (length === null) return '10px';

  if (length! > 0) return '250px';
  return '0';
};

const SearchResultList = styled.div<{ length?: number | null; isIOS: boolean }>`
  overflow-y: auto;
  height: ${(props: any): string => (props.isIOS ? '250px' : heightChooser(props.length))};
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ModalHeaderButton = styled.div`
  color: #108ee9;
  font-size: 17px;
`;

@inject('commonProjectMobileStore')
@observer
export default class CommonProjectMobileComponent extends Component<Props> {
  searchPattern: string;

  constructor(props: any) {
    super(props);
    this.searchPattern = this.props.searchPattern! ? this.props.searchPattern! : 'all';
  }

  searchProject = (value: string, actvType: string | undefined) => {
    this.props.commonProjectMobileStore!.getProjects(value, this.searchPattern, actvType);
  };

  selectProject = (project: ProjectModel) => {
    this.props.commonProjectMobileStore!.setReturnPjValue(project);
    this.onClose();
  };

  renderProjectList = (projects: ProjectModel[]) => {
    let projectList: JSX.Element[] = [];
    projects.map(item => {
      projectList.push(
        <List.Item onClick={() => this.selectProject(item)}>
          <span style={{ fontSize: '14px', color: '#8C8C8C' }}>({item.pjCode})</span>
          <br />
          {item.pjName}
        </List.Item>
      );
    });

    return projectList;
  };

  onClose = () => {
    this.props.commonProjectMobileStore!.setVisibleModal(false);
  };

  onReset = () => {
    const project = new ProjectModel({
      no: '',
      corpCode: '',
      pjCode: '',
      mainSalesDept: '',
      mainPerformDept: '',
      pjName: '',
      pmEmpNo: '',
      pmEmpNm: '',
      pjStartDate: '',
      pjEndDate: '',
      pjStatus: '',
      pjType: '',
      rowStatus: ''
    });
    this.props.commonProjectMobileStore!.setReturnPjValue(project);
    this.props.commonProjectMobileStore!.setVisibleModal(false);
  };

  render() {
    const { visible } = this.props.commonProjectMobileStore!;

    return (
      <>
        <Modal popup visible={visible} onClose={this.onClose} animationType="slide-up">
          <List
            renderHeader={() => (
              <ModalHeader>
                <ModalHeaderButton onClick={this.onClose}>Cancel</ModalHeaderButton>
                <ModalHeaderButton onClick={this.onReset}>Reset</ModalHeaderButton>
              </ModalHeader>
            )}
          >
            <SearchBar
              placeholder="프로젝트코드/명으로 검색"
              onSubmit={value => this.searchProject(value, this.props.actvType)}
              cancelText="취소"
              ref={ref => {
                if (ref !== null && ref !== undefined) ref.focus();
              }}
            />
            <SearchResultList length={this.props.commonProjectMobileStore!.projects.length} isIOS={isIOS}>
              <List>{this.renderProjectList(this.props.commonProjectMobileStore!.projects)}</List>
            </SearchResultList>
            {/* <List.Item>
              <Button onClick={this.onClose}>취소</Button>
              <WhiteSpace />
              <Button type="primary" onClick={() => onSubmit()}>
                선택
              </Button>
            </List.Item> */}
          </List>
        </Modal>
      </>
    );
  }
}
