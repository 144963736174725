import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import CalendarMobileWrapperContainer from '../containers/CalendarMobileWrapperContainer';

@inject('authStore')
@observer
export default class OvertimeCalendarMobileView extends Component<any, any> {
  render() {
    return <CalendarMobileWrapperContainer {...this.props} />;
  }
}
