import { observable, computed } from 'mobx';
import koKR from 'antd/es/locale/ko_KR';
import enUS from 'antd/es/locale/en_US';
import StorageHelper from 'shared/utils/StorageHelper';
import AppConst from 'shared/utils/AppConst';
import { RootStore } from './RootStore';

export class UIStore {
  @observable
  currLanguage: any = {};

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    // Default language for ConfigProvider only
    this.currLanguage = StorageHelper.getItem(StorageHelper.KEY_CURR_LANGUAGE) === AppConst.LANG_ENUS ? enUS : koKR;
    this.rootStore = rootStore;
  }

  setCurrentLanguage(value: any) {
    this.currLanguage = value;
    StorageHelper.setItem(StorageHelper.KEY_CURR_LANGUAGE, value.locale === AppConst.LANG_EN ? AppConst.LANG_ENUS : AppConst.LANG_KOKR);
  }

  @computed get pendingLoading(): number {
    let countLoading = 0;

    // VN side:
    if (this.rootStore.representativeCodeStore.inProgress) countLoading += 1;
    if (this.rootStore.commonCodeStore.inProgress) countLoading += 1;
    if (this.rootStore.departmentStore.inProgress) countLoading += 1;
    if (this.rootStore.departmentHistoryStore.inProgress) countLoading += 1;
    if (this.rootStore.employeeStore.inProgress) countLoading += 1;
    if (this.rootStore.roleStore.inProgress) countLoading += 1;
    if (this.rootStore.authorityStore.inProgress) countLoading += 1;
    if (this.rootStore.userStore.isDisplayLoading) countLoading += 1;
    if (this.rootStore.programModalStore.inProgress) countLoading += 1;
    if (this.rootStore.authStore.inProgress) countLoading += 1;
    if (this.rootStore.programStore.inProgress) countLoading += 1;
    if (this.rootStore.menuStore.inProgress) countLoading += 1;
    if (this.rootStore.menuAssignmentStore.inProgress) countLoading += 1;
    if (this.rootStore.apiStore.inProgress) countLoading += 1;
    if (this.rootStore.menuAssignImplementStore.inProgress) countLoading += 1;
    if (this.rootStore.historyLoginStore.inProgress) countLoading += 1;
    if (this.rootStore.historyFileDownloadStore.inProgress) countLoading += 1;
    if (this.rootStore.menuHelpStore.inProgress) countLoading += 1;
    if (this.rootStore.historyMenuAccessStore.inProgress) countLoading += 1;
    if (this.rootStore.historySystemUsageStore.inProgress) countLoading += 1;
    if (this.rootStore.historyMenuUsageStatusStore.inProgress) countLoading += 1;

    // KR side:
    if (this.rootStore.utilizationResultRegStore.inProgress) countLoading += 1;
    if (this.rootStore.utilizationResultAppStore.inProgress) countLoading += 1;
    if (this.rootStore.utilizationResultClosingStore.inProgress) countLoading += 1;
    if (this.rootStore.projectStore.inProgress) countLoading += 1;
    if (this.rootStore.pmGroupStore.inProgress) countLoading += 1;
    if (this.rootStore.overtimeMonthRegStore.inProgress) countLoading += 1;
    if (this.rootStore.overtimeMonthApprListStore.inProgress) countLoading += 1;
    if (this.rootStore.overtimeMonthStateStore.inProgress) countLoading += 1;
    if (this.rootStore.workhourManagementListStore.inProgress) countLoading += 1;
    if (this.rootStore.reducWorkManagementStore.inProgress) countLoading += 1;
    if (this.rootStore.calendarListStore.inProgress) countLoading += 1;
    if (this.rootStore.holidayListStore.inProgress) countLoading += 1;
    if (this.rootStore.corporationConfigStore.inProgress) countLoading += 1;
    if (this.rootStore.masterImportStore.inProgress) countLoading += 1;
    if (this.rootStore.rawDataDownStore.inProgress) countLoading += 1;
    if (this.rootStore.hrAssignManageStore.inProgress) countLoading += 1;
    if (this.rootStore.hrAssignReqStore.inProgress) countLoading += 1;
    if (this.rootStore.utilizationStore.inProgress) countLoading += 1;
    if (this.rootStore.projectResourceStore.inProgress) countLoading += 1;
    if (this.rootStore.mailSendLogStore.inProgress) countLoading += 1;
    if (this.rootStore.hrAssignViewStore.inProgress) countLoading += 1;

    return countLoading;
  }
}
