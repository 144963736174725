import { orderBy } from 'lodash';
import { action, computed, observable, toJS } from 'mobx';
import MenuAssignedService from 'shared/services/MenuAssignedService';
import AppHelper from 'shared/utils/AppHelper';
import StorageHelper from 'shared/utils/StorageHelper';
import { RootStore } from './RootStore';

export const MENU_CODE_MAPPING = {
  // 1st level
  SYSTEM: 'M0102000',
  TIMESHEET: 'M0101000'
};

enum Elang {
  En = 'en',
  Kr = 'kr'
}

type TMenuAssignLanguage = {
  language: Elang;
  menuName: string;
};

export type TMenuAssign = {
  menuCode: string;
  menuType: string;
  progCode: string;
  progFileName: string;
  progName: string;
  progPath: string;
  level: number;
  languages: TMenuAssignLanguage[];
  upperMenuCode: string;
  displayOrder: number;
  authType: string;
  progUseYn: string;
};

export interface IMenuHeader extends TMenuAssign {
  pathToRedirectTo: string;
}
export class MenuAssignImplementStore {
  // store to manage authorization features
  rootStore: RootStore;

  @observable
  inProgress: boolean;

  @observable
  menuAssignedList: TMenuAssign[] = [];

  @observable
  currentRootSidebarMenu?: TMenuAssign;

  @observable
  private _selectedProgramViewMenu?: TMenuAssign;

  set selectedProgramViewMenu(value: TMenuAssign | undefined) {
    this._selectedProgramViewMenu = value;

    // 1. automatiacaccly set Sidebar based on selected view
    this.currentRootSidebarMenu = this.getLevelTwoParentMenuOfMenu(value);

    if (value) {
      // 2. Update local storage
      // Purpose: API calling need 'menu_code' in header params
      StorageHelper.setItem(StorageHelper.KEY_LATEST_MENU_CODE_TRACKED, value.menuCode);

      // 3. change route ( no need hard refresh) based on observable value
      this.rootStore.routeStore.storeHistory.push(value.progPath);

      // 4. Track Menu Access History
      this.rootStore.historyMenuAccessStore!.trackMenuAccess(value.menuCode);
    }
  }

  get selectedProgramViewMenu() {
    return this._selectedProgramViewMenu;
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  getListMenuHeader(parentLevelOneMenu: TMenuAssign) {
    const getPathToRedirectTo = (parentCode: string): string => {
      // TODO: Use recrusive is better?
      const childLevelThree = this.menuAssignedList.filter(obj => obj.upperMenuCode === parentCode && obj.level === 3 && !!obj.menuCode);
      const childLevelThreeOrdered = orderBy(childLevelThree, 'displayOrder');

      const possibleList: TMenuAssign[] = [];
      childLevelThreeOrdered.map(levelThree => {
        const leafList = this.menuAssignedList.filter(obj => {
          return obj.level === 4 && obj.upperMenuCode === levelThree.menuCode;
        });
        possibleList.push(...orderBy(leafList, 'displayOrder'));
        return possibleList;
      });
      if (possibleList[0]) {
        return possibleList[0].progPath;
      }
      return '';
    };
    const listMenuHeader: IMenuHeader[] = [];

    this.menuAssignedList.map(v => {
      if (v.level === 2) {
        if (v.upperMenuCode === parentLevelOneMenu.menuCode) {
          listMenuHeader.push({
            ...v,
            pathToRedirectTo: getPathToRedirectTo(v.menuCode)
          });
        }
      }
      return listMenuHeader;
    });
    return listMenuHeader;
  }

  @computed
  get listMenuSidebar(): TMenuAssign[] {
    if (!this.currentRootSidebarMenu) return [];
    const parentMenuCodeLevelTwo: string[] = [this.currentRootSidebarMenu.menuCode];

    const parentMenuCodeLevelThree: string[] = this.menuAssignedList.reduce((map: any, record: TMenuAssign) => {
      if (parentMenuCodeLevelTwo.includes(record.upperMenuCode) && record.level === 3) {
        map.push(record.menuCode);
      }
      return map;
    }, []);

    const parentMenuCodeLevelFour: string[] = this.menuAssignedList.reduce((map: any, record: TMenuAssign) => {
      if (parentMenuCodeLevelThree.includes(record.upperMenuCode) && record.level === 4) {
        map.push(record.menuCode);
      }
      return map;
    }, []);

    // return full obj arr
    return (
      this.menuAssignedList.filter(obj => {
        const bucketMatchedMenuCodes = [...parentMenuCodeLevelFour, ...parentMenuCodeLevelThree];
        return bucketMatchedMenuCodes.includes(obj.menuCode);
      }) || []
    );
  }

  @computed
  get authorityButton() {
    if (!this._selectedProgramViewMenu) {
      return {
        disableAdd: true,
        disableDelete: true,
        disablePrint: true,
        disableSave: true,
        disableEdit: true,
        disableRead: false
      };
    }
    switch (this._selectedProgramViewMenu.authType) {
      case 'VP':
        return {
          disableAdd: true,
          disableDelete: true,
          disablePrint: true,
          disableSave: true,
          disableEdit: true,
          disableRead: false
        };
      case 'PP':
        return {
          disableAdd: true,
          disableDelete: true,
          disablePrint: false,
          disableSave: true,
          disableEdit: true,
          disableRead: false
        };
      case 'EP':
        return {
          disableAdd: true,
          disableDelete: true,
          disablePrint: false,
          disableSave: false,
          disableEdit: false,
          disableRead: false
        };
      case 'CP':
        return {
          disableAdd: false,
          disableDelete: true,
          disablePrint: false,
          disableSave: false,
          disableEdit: false,
          disableRead: false
        };
      // AP
      default:
        return {
          disableAdd: false,
          disableDelete: false,
          disablePrint: false,
          disableSave: false,
          disableEdit: false,
          disableRead: false
        };
    }
  }

  @action
  getMenuAssigned() {
    // should not call API when user not logged
    if (!this.rootStore.authStore.isLogged) {
      return true;
    }

    this.inProgress = true;
    return MenuAssignedService.getListAssignedMenu()
      .then((rs: any) => {
        const { isSuccessful } = rs.data.header;
        if (!isSuccessful) {
          this.rootStore.apiHandleStore.handleApiFail(rs.data.header);
        } else {
          const data = rs.data.data || [];

          this.menuAssignedList = data.reduce((map: any[], record: any = {}) => {
            const { menuCode, menuType, progCode, progFileName, progName, progPath, languages, level, upperMenuCode, displayOrder, authType, progUseYn } = record;
            map.push({
              menuCode,
              menuType,
              progCode,
              progFileName,
              progName,
              progPath: `/${progPath}`,
              languages,
              level,
              upperMenuCode,
              displayOrder,
              authType,
              progUseYn
            });
            return map;
          }, []);

          // set  sidebar item list when user access through specifc url
          const selectedMenuByPath: TMenuAssign | undefined = this.menuAssignedList.find(obj => obj.progPath === this.rootStore.routeStore.firstAccessLocationPathname);

          if (selectedMenuByPath) {
            this.selectedProgramViewMenu = selectedMenuByPath;
          }
        }
      })
      .catch((e: any) => {
        console.error(`Assigned Menu fetching fail: ${JSON.stringify(e)}`);
        AppHelper.notify('error', '', e.message);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  getMenuNameByLanguage = (v: TMenuAssign): string => {
    let menuNameByLanguage = '';

    const objLang = v.languages.find(lang => {
      const mappingEngLangCode = ['en'];
      const mappingKrLangCode = ['kr', 'ko'];

      let afterMapLangCode = '';
      const langLanguageLowerCase = lang.language ? lang.language.toLowerCase() : '';
      if (mappingKrLangCode.includes(langLanguageLowerCase)) {
        afterMapLangCode = 'ko';
      } else if (mappingEngLangCode.includes(langLanguageLowerCase)) {
        afterMapLangCode = 'en';
      }
      return afterMapLangCode === this.rootStore.uiStore.currLanguage.locale;
    });
    if (objLang) menuNameByLanguage = objLang.menuName;

    return menuNameByLanguage;
  };

  getLevelTwoParentMenuOfMenu = (menuNeedTocheck?: TMenuAssign) => {
    if (!menuNeedTocheck) return undefined;
    // get parent root level 2 menu such as System Common, System Mgmt...

    const menuToCheck = menuNeedTocheck;
    let levelTwoParent: TMenuAssign | undefined;
    const recur = (arr: TMenuAssign[], menu: TMenuAssign): TMenuAssign | undefined => {
      if (menu.level === 2) {
        return menu;
      }
      const tempParent = arr.find(obj => obj.menuCode === menu.upperMenuCode);
      if (tempParent) return recur(arr, tempParent);
      return undefined;
    };
    if (menuToCheck) {
      levelTwoParent = recur(toJS(this.menuAssignedList), menuToCheck);
    }

    return levelTwoParent;
  };
}
