import StorageHelper from 'shared/utils/StorageHelper';
import AppConst from './AppConst';

const EN_JSON = '/i18n/i18n_en.json';
const KR_JSON = '/i18n/i18n_kr.json';
const COMMON_EN_JSON = require('pages-mobile/i18n/hrm_en.json');
const COMMON_KR_JSON = require('pages-mobile/i18n/hrm_kr.json');
const USER_INFO_EN_JSON = require('pages-mobile/user-info' + EN_JSON);
const USER_INFO_KR_JSON = require('pages-mobile/user-info' + KR_JSON);
const OVERTIME_EN_JSON = require('pages-mobile/overtime' + EN_JSON);
const OVERTIME_KR_JSON = require('pages-mobile/overtime' + KR_JSON);

class MobileLanguageHelper {
  private static instance: MobileLanguageHelper;
  private langData: any;

  private constructor() {
    this.langData = this.getLanguageResourceData();
  }

  getLanguageResourceData = () => {
    // Merge data together
    const finalEN = {
      ...COMMON_EN_JSON,
      ...USER_INFO_EN_JSON,
      ...OVERTIME_EN_JSON
    };
    const finalKR = {
      ...COMMON_KR_JSON,
      ...USER_INFO_KR_JSON,
      ...OVERTIME_KR_JSON
    };

    const finalLanguagesData = {
      en: finalEN,
      ko: finalKR
    };
    return finalLanguagesData;
  };

  static getInstance(): MobileLanguageHelper {
    if (!MobileLanguageHelper.instance) {
      MobileLanguageHelper.instance = new MobileLanguageHelper();
    }
    return MobileLanguageHelper.instance;
  }

  /**
   * Get message value from language json files
   * @param langKey
   */
  getMessage = (langKey: string) => {
    const currLang = StorageHelper.getItem(StorageHelper.KEY_CURR_LANGUAGE) === AppConst.LANG_ENUS ? 'en' : 'ko';
    return MobileLanguageHelper.getInstance().langData[currLang][langKey];
  };
}

export default MobileLanguageHelper.getInstance();
