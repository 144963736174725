import { action, computed, observable, toJS } from 'mobx';
import MenuSearchModel from 'shared/models/menu/MenuSearchModel';
import MenuHelpModel, { ELangKey, TMenuHelpContent } from 'shared/models/menuHelp/MenuHelpModel';
import { RootStore } from 'shared/stores/RootStore';
import MenuHelpService from '../services/MenuHelpService';

export type SearchParams = {
  useYn: string;
};

const sleep = (m: any) => new Promise(r => setTimeout(r, m));

export class MenuHelpStore {
  rootStore: RootStore;

  private _originalMenuHelpBucket?: MenuHelpModel[];

  @observable
  menuHelpSelected?: MenuHelpModel; // for Help button only?

  @observable
  menuHelpBucket?: MenuHelpModel[];

  @observable
  selectedMenuHelpId?: string;

  @observable
  inProgress = false;

  @observable
  searchParams: SearchParams;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @computed
  get originalMenuHelpBucket() {
    return this._originalMenuHelpBucket;
  }

  @computed
  get selectedMenuHelp() {
    return this.menuHelpBucket && this.menuHelpBucket.find(obj => obj.id === this.selectedMenuHelpId);
  }

  getSelectedMenuHelpContent(contentLang: ELangKey): string {
    if (this.selectedMenuHelp && this.selectedMenuHelp.content) {
      const objContent = this.selectedMenuHelp.content.find(obj => obj.language === contentLang);
      if (objContent) {
        return objContent.contentText || '';
      }
    }
    return '';
  }

  @action
  setSelectedMenuHelpIdByMenuCode = (menuCode: string) => {
    if (this.menuHelpBucket) {
      const selectedMenuHelp = this.menuHelpBucket.find(obj => obj.menuCode === menuCode);
      if (selectedMenuHelp) this.selectedMenuHelpId = selectedMenuHelp.id;
    }
    return this.fetchMenuHelpDataByMenuCode(menuCode);
  };

  @action
  async getContentByMenuCode(menuCode: string) {
    this.inProgress = true;
    const prepareToFill = await MenuHelpService.getMenuHelpByMenuCode(menuCode);
    const rsData = prepareToFill.data;
    this.inProgress = false;
    if (!rsData.data) {
      this.menuHelpSelected = undefined;
      return;
    }

    const contentData: TMenuHelpContent[] = [];
    Object.keys(rsData.data).map(key => {
      contentData.push({
        language: key as ELangKey,
        contentText: rsData.data[key],
        contentImages: []
      });

      return contentData;
    });

    const newObj = new MenuHelpModel({
      menuCode,
      content: contentData
    });
    this.menuHelpSelected = newObj; // rsData.data;
  }

  @action
  async fetchMenuHelpDataByMenuCode(menuCode: string) {
    // fill more data to menuHelpBucket (in previous fetch we just get menuCode list only)
    this.inProgress = true;
    const prepareToFill = await MenuHelpService.getMenuHelpByMenuCode(menuCode);
    const rsData = prepareToFill.data;
    this.inProgress = false;

    if (!rsData.data) return;

    const { data } = rsData;
    if (this.menuHelpBucket && this.menuHelpBucket.length && data) {
      const contentData: TMenuHelpContent[] = [];
      Object.keys(data).map(key => {
        contentData.push({
          language: key as ELangKey,
          contentText: data[key],
          contentImages: []
        });

        return contentData;
      });

      const idx = this.menuHelpBucket.findIndex(obj => obj.menuCode === menuCode);
      const newObj = new MenuHelpModel(
        {
          ...this.menuHelpBucket[idx],
          content: contentData
        },
        this.menuHelpBucket[idx].id
      );

      this.menuHelpBucket[idx] = newObj;

      // sync to orginal data
      if (this._originalMenuHelpBucket) {
        this._originalMenuHelpBucket[idx] = newObj;
      }
    }
  }

  @action
  submitHelpContent = async (lang: ELangKey, markdown: string) => {
    if (this.selectedMenuHelp) {
      this.inProgress = true;

      await MenuHelpService.submitHelpContent(this.selectedMenuHelp, lang, markdown);
      await this.fetchMenuHelpDataByMenuCode(this.selectedMenuHelp.menuCode);

      this.inProgress = false;
    }
  };

  @action
  async find() {
    this.rootStore.menuStore.searchModel = new MenuSearchModel({
      useYn: this.searchParams.useYn
    });
    await this.rootStore.menuStore.find();
    const { menuList } = this.rootStore.menuStore;
    const dataForMenuHelpBucket: MenuHelpModel[] = [];

    menuList.map(obj => {
      dataForMenuHelpBucket.push(
        new MenuHelpModel({
          menuCode: obj.menuCode
        })
      );
      return dataForMenuHelpBucket;
    });

    this.menuHelpBucket = dataForMenuHelpBucket;
    this._originalMenuHelpBucket = JSON.parse(JSON.stringify(dataForMenuHelpBucket));
  }

  @action
  changeImagesForSelectedMenuHelp = async (singleContentWithChangeImages: TMenuHelpContent) => {
    const obj = this.selectedMenuHelp;
    if (obj) {
      try {
        return MenuHelpService.uploadImages(obj.menuCode, [singleContentWithChangeImages]);
      } catch {
        console.log('');
      }
    }
  };
}
