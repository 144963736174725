/**
 * Project model for View
 */
class HrAssignReqModel {
  no: number;
  corpCode: string;
  corpCodeNm: string;
  pjCode: string;
  pjName: string;
  pmEmpNo: string;
  pmEmpNm: string;
  pjStartDate: string;
  pjEndDate: string;
  pjStatus: string;
  pjStatusNm: string;
  pjType: string;
  pjTypeNm: string;
  injctMonth: string;
  injctDay: string;
  injctYmd: string;
  injctTime: string;
  deptCode: string;
  deptName: string;
  totInjctRate: number;
  positName: string;
  jobName: string;
  empName: string;
  empNo: string;
  injctStartDay: string;
  injctEndDay: string;
  actvType: string;
  injctRate: number;
  apprSts: string;
  insertRate: number;
  bfStartDate: string;
  bfEndDate: string;
  afStartDate: string;
  afEndDate: string;

  constructor(obj: any) {
    this.no = obj.no;
    this.corpCode = obj.corpCode;
    this.corpCodeNm = obj.corpCodeNm;
    this.pjCode = obj.pjCode;
    this.pjName = obj.pjName;
    this.pmEmpNo = obj.pmEmpNo;
    this.pmEmpNm = obj.pmEmpNm;
    this.pjStartDate = obj.pjStartDate;
    this.pjEndDate = obj.pjEndDate;
    this.pjStatus = obj.pjStatus;
    this.pjStatusNm = obj.pjStatusNm;
    this.pjType = obj.pjType;
    this.pjTypeNm = obj.pjTypeNm;
    this.injctMonth = obj.injctMonth;
    this.injctDay = obj.injctDay;
    this.injctYmd = obj.injctYmd;
    this.injctTime = obj.injctTime;
    this.deptCode = obj.deptCode;
    this.deptName = obj.deptName;
    this.totInjctRate = obj.totInjctRate;
    this.positName = obj.positName;
    this.jobName = obj.jobName;
    this.empName = obj.empName;
    this.empNo = obj.empNo;
    this.injctStartDay = obj.injctStartDay;
    this.injctEndDay = obj.injctEndDay;
    this.actvType = obj.actvType;
    this.injctRate = obj.injctRate;
    this.apprSts = obj.apprSts;
    this.insertRate = obj.insertRate;
    this.bfStartDate = obj.bfStartDate;
    this.bfEndDate = obj.bfEndDate;
    this.afStartDate = obj.afStartDate;
    this.afEndDate = obj.afEndDate;
  }
}
export default HrAssignReqModel;
