import React from 'react';
import { Breadcrumb } from 'antd';
import { inject } from 'mobx-react';
import { MenuAssignImplementStore, TMenuAssign } from 'shared/stores/MenuAssignImplementStore';
import { toJS } from 'mobx';
import HelpButton from 'components/buttons/HelpButton';

interface IHRMBreadcrumb {
  urlPath?: any;
}
const HRMBreadcrumb = (props: IHRMBreadcrumb) => {
  // ATTENTION: props: IHRMBreadcrumb => SHOULD NOT REMOVE UNTIL WE remove urlPath from other pages
  return <InjectedBreadcrumbItems />;
};

const BreadcrumbItems = ({ getItems, getMenuNameByLangugage }: any) => {
  const breadcrumbItems = getItems() || [];
  let lastMenu = '';
  return (
    <div style={{ position: 'relative'}}>
      <Breadcrumb style={{ marginBottom: '1em' }}>
        {breadcrumbItems.map((v: TMenuAssign) => {
          lastMenu = getMenuNameByLangugage(v);
          return <Breadcrumb.Item key={v.menuCode}>{getMenuNameByLangugage(v)}</Breadcrumb.Item>;
        })}
      </Breadcrumb>
      <div style={{ position: 'absolute', right: '0px', top: 0}}>
        <HelpButton key={lastMenu} title={lastMenu} />
      </div>
    </div>
  );
};

interface IInjectedBreadcrumbItems {
  menuAssignImplementStore?: MenuAssignImplementStore;
}

const InjectedBreadcrumbItems = inject(({ menuAssignImplementStore }: IInjectedBreadcrumbItems) => ({
  getMenuNameByLangugage: menuAssignImplementStore!.getMenuNameByLanguage,
  getItems: () => {
    // generate matched menu to display breadcrumb
    const arrBreadcrumbs: TMenuAssign[] = [];
    const arrAllMenu = toJS(menuAssignImplementStore!.menuAssignedList);

    // recuisive find upper parent menu
    const recur = (menu: TMenuAssign): TMenuAssign | undefined => {
      const tempParent = arrAllMenu.find(obj => obj.menuCode === menu.upperMenuCode);
      if (tempParent && tempParent.level !== 0) {
        arrBreadcrumbs.push(tempParent);
        recur(tempParent);
      }
      return undefined;
    };
    const currentMenu = menuAssignImplementStore!.selectedProgramViewMenu;
    if (currentMenu) {
      arrBreadcrumbs.push(currentMenu);
      recur(toJS(currentMenu));
    }

    return [...arrBreadcrumbs].reverse();
  }
}))(BreadcrumbItems);

export default HRMBreadcrumb;
