import { observable, action } from 'mobx';
import { message } from 'antd';
import moment from 'moment';
import CalendarModel from '../static/CalendarModel';
import UtilizationResultModel from '../static/UtilizationResultModel';
import UtilizationResultRegService from '../services/UtilizationResultRegService';
import { RootStore } from 'shared/stores/RootStore';

interface CloseStsModel {
  corpCode: string | null;
  yearMonth: string | null;
  closeSts: string | null;
}

export default class UtilizationResultRegStore {
  @observable
  inProgress: boolean;

  @observable
  nowYear = '0';

  @observable
  preWeekNumber = '0';

  @observable
  nowWeekNumber = '0';

  @observable
  nextWeekNumber = '0';

  @observable
  nowMonth = '0';

  @observable
  weekFromDate = '0';

  @observable
  weekToDate = '0';

  @observable
  weekCalendarList: CalendarModel[];

  @observable
  weekUtilizationResultList: UtilizationResultModel[];

  @observable
  preYearMonth = '0';

  @observable
  nowYearMonth = '0';

  @observable
  nextYearMonth = '0';

  @observable
  monthCalendarList: CalendarModel[];

  @observable
  monthUtilizationResultList: UtilizationResultModel[];

  @observable
  closingWeekly = 'N';

  @observable
  closingSts: CloseStsModel;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.weekCalendarList = [];
    this.monthCalendarList = [];
    this.weekUtilizationResultList = [];
    this.monthUtilizationResultList = [];
    this.rootStore = rootStore;
  }

  @action
  getCalendarModel(date: string): void {
    UtilizationResultRegService.getCalendarModel(date)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];

        this.getWeekCalendarList(data);
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  getNowWeekNumber(): any {
    // 현재주차 가져오기
    this.inProgress = true;

    this.nowYear = moment()
      .format('YYYY')
      .toString();

    return UtilizationResultRegService.getWeekNumber(
      moment()
        .format('YYYYMMDD')
        .toString()
    )
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];

        this.nowWeekNumber = data.yearWeekly;

        this.getWeekCalendarList(data);
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  getNowYearMonth(): any {
    // 현재년월 가져오기
    this.inProgress = true;

    this.nowYear = moment()
      .format('YYYY')
      .toString();

    this.nowYearMonth = moment()
      .format('YYYYMM')
      .toString();
    this.preYearMonth = moment()
      .add(-1, 'months')
      .format('YYYYMM')
      .toString();
    this.nextYearMonth = moment()
      .add(1, 'months')
      .format('YYYYMM')
      .toString();

    return this.getMonthCalendarList(
      moment()
        .format('YYYYMM')
        .toString()
    );
  }

  @action
  getWeekCalendarListArrow(date: string, g: string): any {
    this.inProgress = true;

    if (g === 'pre') {
      UtilizationResultRegService.getWeekNumber(
        moment(date, 'YYYYMMDD')
          .add(-1, 'days')
          .format('YYYYMMDD')
          .toString()
      )
        .then(response => {
          this.rootStore.apiHandleStore.handleApiFail(response.data.header);
          const data = response.data.data || [];
          this.getWeekCalendarList(data);
          this.inProgress = false;
        })
        .catch((error: any) => {
          this.rootStore.apiHandleStore.handleApiError(error);
          this.inProgress = false;
        });
    } else if (g === 'next') {
      UtilizationResultRegService.getWeekNumber(
        moment(date, 'YYYYMMDD')
          .add(1, 'days')
          .format('YYYYMMDD')
          .toString()
      )
        .then(response => {
          this.rootStore.apiHandleStore.handleApiFail(response.data.header);
          const data = response.data.data || [];
          this.getWeekCalendarList(data);
          this.inProgress = false;
        })
        .catch((error: any) => {
          this.rootStore.apiHandleStore.handleApiError(error);
          this.inProgress = false;
        });
    }
  }

  @action
  getWeekCalendarList(date: CalendarModel): any {
    // weekly 달력 가져오기
    this.inProgress = true;

    return UtilizationResultRegService.getWeekCalendarList(date.yyyy, date.yearWeekly)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.weekCalendarList = data;

        const toDate = data.reduce((previous: any, current: any) => {
          return previous.yyyymmdd > current.yyyymmdd ? previous : current;
        });
        this.weekToDate = moment(toDate.yyyymmdd, 'YYYYMMDD').format('YYYY-MM-DD');

        const fromDate = data.reduce((previous: any, current: any) => {
          return previous.yyyymmdd > current.yyyymmdd ? current : previous;
        });
        this.weekFromDate = moment(fromDate.yyyymmdd, 'YYYYMMDD').format('YYYY-MM-DD');

        this.getWeekUtilizationResultList(fromDate.yyyymmdd, toDate.yyyymmdd);

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  getWeekUtilizationResultList(fromDate: string, toDate: string): any {
    // weekly 가동실적 가져오기
    this.inProgress = true;

    return UtilizationResultRegService.getUtilizationResultList(fromDate, toDate)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.weekUtilizationResultList = data;
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  getMonthCalendarList(yearMonth: string): any {
    // monthly 달력 가져오기
    this.inProgress = true;

    this.nowYearMonth = yearMonth;
    this.preYearMonth = moment(yearMonth, 'YYYYMM')
      .add(-1, 'months')
      .format('YYYYMM')
      .toString();
    this.nextYearMonth = moment(yearMonth, 'YYYYMM')
      .add(1, 'months')
      .format('YYYYMM')
      .toString();

    return UtilizationResultRegService.getMonthCalendarList(yearMonth)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.monthCalendarList = data;

        const toDate = data.reduce((previous: any, current: any) => {
          return previous.yyyymmdd > current.yyyymmdd ? previous : current;
        });

        const fromDate = data.reduce((previous: any, current: any) => {
          return previous.yyyymmdd > current.yyyymmdd ? current : previous;
        });
        this.getMonthUtilizationResultList(fromDate.yyyymmdd, toDate.yyyymmdd);

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  getMonthUtilizationResultList(fromDate: string, toDate: string): any {
    // weekly 가동실적 가져오기
    this.inProgress = true;

    return UtilizationResultRegService.getUtilizationResultList(fromDate, toDate)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.monthUtilizationResultList = data;
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  addUtilizationResult(mh: any): void {
    // 가동실적 추가하기
    this.inProgress = true;
    const mhItem = {
      rowStatus: 'create',
      corpCode: '',
      empNo: 'me',
      seqNo: 0,
      apprSts: 'A',
      injctYmd: mh.injctYmd.toString(),
      pjCode: mh.pjCode.toString(),
      injctRate: Number(mh.injctRate) / 100,
      injctDay: Number(mh.injctRate) / 100,
      injctTime: (Number(mh.injctRate) / 100) * 8,
      actvType: mh.actvType.toString(),
      empName: null,
      deptName: null,
      pjName: mh.pjName.toString(),
      deptCode: ''
    };

    this.weekUtilizationResultList.push(mhItem);
    this.inProgress = false;
  }

  @action
  removeUtilizationResult(mh: UtilizationResultModel): any {
    // 가동실적 제거하기
    this.inProgress = true;

    const idx = this.weekUtilizationResultList.findIndex(item => {
      return item.rowStatus === 'create' && item.injctYmd === mh.injctYmd && item.pjCode === mh.pjCode && item.actvType === mh.actvType && item.injctRate === mh.injctRate;
    });
    if (idx > -1) this.weekUtilizationResultList.splice(idx, 1);

    this.inProgress = false;
  }

  @action
  saveDailyUtilizationResult(yyyymmdd: string): any {
    // 일자 별 가동실적 저장
    this.inProgress = true;

    const todayUtilizationResultList = this.weekUtilizationResultList.filter(item => item.injctYmd === yyyymmdd);
    const createUtilizationResultList = todayUtilizationResultList.filter(item => item.rowStatus === 'create');
    const updateUtilizationResultList = todayUtilizationResultList.filter(item => item.rowStatus === 'update');
    const deleteUtilizationResultList = todayUtilizationResultList.filter(item => item.rowStatus === 'delete');

    UtilizationResultRegService.saveUtilizationResultBulk(todayUtilizationResultList)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];

        UtilizationResultRegService.getUtilizationResultList(yyyymmdd, yyyymmdd).then(response => {
          this.rootStore.apiHandleStore.handleApiFail(response.data.header);
          const data = response.data.data || [];

          this.weekUtilizationResultList = this.weekUtilizationResultList.filter(item => item.injctYmd !== yyyymmdd);
          this.weekUtilizationResultList = this.weekUtilizationResultList.concat(data);

          this.inProgress = false;
        });
        message.success('저장되었습니다.');
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });

    // // 하나의 transaction으로 묶기
    // UtilizationResultRegService.deleteUtilizationResult(deleteUtilizationResultList)
    //   .then(deleteResponse => {
    //     this.rootStore.apiHandleStore.handleApiFail(deleteResponse.data.header);
    //     const deleteResult = deleteResponse.data.data;
    //     if (deleteResult)
    //       UtilizationResultRegService.updateUtilizationResult(updateUtilizationResultList)
    //         .then(updateResponse => {
    //           this.rootStore.apiHandleStore.handleApiFail(updateResponse.data.header);
    //           const updateResult = updateResponse.data.data;
    //           if (updateResult)
    //             UtilizationResultRegService.createUtilizationResult(createUtilizationResultList)
    //               .then(createResponse => {
    //                 this.rootStore.apiHandleStore.handleApiFail(createResponse.data.header);
    //                 // const data = response.data.data || [];

    //                 UtilizationResultRegService.getUtilizationResultList(yyyymmdd, yyyymmdd).then(response => {
    //                   this.rootStore.apiHandleStore.handleApiFail(response.data.header);
    //                   const data = response.data.data || [];

    //                   this.weekUtilizationResultList = this.weekUtilizationResultList.filter(item => item.injctYmd !== yyyymmdd);
    //                   this.weekUtilizationResultList = this.weekUtilizationResultList.concat(data);

    //                   this.inProgress = false;
    //                 });
    //                 message.success('저장되었습니다.');
    //               })
    //               .catch((error: any) => {
    //                 this.rootStore.apiHandleStore.handleApiError(error);
    //                 this.inProgress = false;
    //                 message.error('저장 중 문제가 발생하였습니다.');
    //               });
    //         })
    //         .catch((error: any) => {
    //           this.rootStore.apiHandleStore.handleApiError(error);
    //           this.inProgress = false;
    //           message.error('저장 중 문제가 발생하였습니다.');
    //         });
    //   })
    //   .catch((error: any) => {
    //     this.rootStore.apiHandleStore.handleApiError(error);
    //     this.inProgress = false;
    //     message.error('저장 중 문제가 발생하였습니다.');
    //   });
  }

  @action
  getCorpConf(): any {
    // 법인 별 설정정보 가져오기 - 주마감 check
    this.inProgress = true;
    return UtilizationResultRegService.getCorpConf()
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];

        this.closingWeekly = data.closingWeekly;

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  getClosingSts(injctYmd: string): any {
    return UtilizationResultRegService.getCloseSts(
      moment(injctYmd, 'YYYYMMDD')
        .format('YYYYMM')
        .toString()
    )
      .then(response => {
        // this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.closingSts = data;
        return data;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  }

  @action
  modifyUtilizationItem(mh: UtilizationResultModel, newMh: UtilizationResultModel): any {
    this.inProgress = true;
    mh = newMh;
    this.inProgress = false;
  }
}
