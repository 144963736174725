import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import HRMBreadcrumb from 'components/HRMBreadcrumb';
import ProjectResourceSearchContainer from 'pages/project-resource/containers/ProjectResourceSearchContainer';
import ProjectResourceTableContainer from 'pages/project-resource/containers/ProjectResourceTableContainer';

const ReducWorkManagementView = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <>
      <HRMBreadcrumb urlPath={match.path} />
      <ProjectResourceSearchContainer />
      <ProjectResourceTableContainer />
    </>
  );
};

export default ReducWorkManagementView;
