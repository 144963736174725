import AppConst from 'shared/utils/AppConst';
import uuid from 'uuid';

class AssignmentTask {
  id: string; // ID of FE side
  creator: string;
  empName: string;
  deptName: string;
  empNo: string;
  endDate: number;
  positName: string;
  remark: string;
  roleCode: string;
  roleName: string;
  rowStatus: string;
  startDate: number;
  regEmpNo: string;
  regEmpName: string;
  regDate: number;
  updatedEmpNo: string;
  updatedEmpName: string;
  updatedDate: number;
  changeStatus: string;
  [key: string]: any;

  constructor(obj?: any) {
    this.id = uuid.v4();
    if (!obj) return;
    this.creator = obj.creator;
    this.empName = obj.empName;
    this.deptName = obj.deptName;
    this.empNo = obj.empNo;
    this.endDate = AppConst.DEFAULT_UNIX_END_DATE;
    this.positName = obj.positName;
    this.remark = obj.remark;
    this.roleCode = obj.roleCode;
    this.roleName = obj.roleName;
    this.rowStatus = obj.rowStatus;
    this.startDate = obj.startDate;
    this.regEmpNo = obj.regEmpNo;
    this.regEmpName = obj.regEmpName;
    this.regDate = obj.regDate;
    this.updatedEmpNo = obj.updatedEmpNo;
    this.updatedEmpName = obj.updatedEmpName;
    this.updatedDate = obj.updatedDate;
    this.changeStatus = obj.changeStatus;
  }
}

export default AssignmentTask;
