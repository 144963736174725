import CorporationAPIModel from '../corporation/CorporationAPIModel';

class AuthAPIModel {
  userName: string;
  accessToken: string;
  userId?: string;
  corpList: CorporationAPIModel[];
  loginWithDefaultPassword: boolean;

  constructor(obj?: AuthAPIModel) {
    if (obj) {
      this.userName = obj.userName;
      this.accessToken = obj.accessToken;
      this.corpList = obj.corpList;
      this.userId = obj.userId;
      this.loginWithDefaultPassword = obj.loginWithDefaultPassword;
    }
  }
}

export default AuthAPIModel;
