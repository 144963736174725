class HrAssignViewModel {
  no: number;
  corpCode: string;
  pjCode: string;
  pjName: string;
  injctYmd: string;
  deptCode: string;
  totInjctRate: number;
  positName: string;
  jobName: string;
  empName: string;
  empNo: string;
  injctStartDay: string;
  injctEndDay: string;
  actvType: string;
  injctRate: number;
  apprSts: string;
  bfApprSts: string;

  constructor(obj: any) {
    this.no = obj.no;
    this.corpCode = obj.corpCode;
    this.pjCode = obj.pjCode;
    this.pjName = obj.pjName;
    this.injctYmd = obj.injctYmd;
    this.deptCode = obj.deptCode;
    this.totInjctRate = obj.totInjctRate;
    this.positName = obj.positName;
    this.jobName = obj.jobName;
    this.empName = obj.empName;
    this.empNo = obj.empNo;
    this.injctStartDay = obj.injctStartDay;
    this.injctEndDay = obj.injctEndDay;
    this.actvType = obj.actvType;
    this.injctRate = obj.injctRate;
    this.apprSts = obj.apprSts;
    this.bfApprSts = obj.bfApprSts;
  }
}
export default HrAssignViewModel;
