import { Drawer } from 'antd';
import IconMetanet from 'components/IconMetanet';
import { inject, observer } from 'mobx-react';
import { EmployeeStore } from 'pages/employee-management/stores/EmployeeStore';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import SelectEmployee from 'shared/components/SelectEmployee';

export enum EOfficeCodeOptions {
  All = '',
  Incumbent = 'INCUMBENT'
}
interface IProps {
  onSubmit: Function;
  onSearch: Function;
  onCancel: Function;
  visibleCondition: boolean;
  employeeStore?: EmployeeStore;
  formFields?: any[];
  formItemLayout?: any;
  disableCorpList?: boolean;
  officeCodeOptions?: EOfficeCodeOptions;
}

@inject('employeeStore')
@observer
class CommonSelectEmployeeDrawer extends Component<IProps> {
  componentDidMount() {
    if (!this.props.disableCorpList) this.props.employeeStore!.getAllCorporation();
  }

  handleSearch = (values: any) => {
    let params = values;
    // because bad typesafe (any type) => should check again
    if (typeof values === 'object') {
      params = {
        ...values,
        officeCode: this.props.officeCodeOptions ? this.props.officeCodeOptions : EOfficeCodeOptions.All
      };
    }
    this.props.onSearch(params);
  };

  handleSubmit = (values: any) => {
    this.props.onSubmit(values);
    this.handleCancel();
  };

  handleCancel = () => {
    this.props.employeeStore!.concurrentEmployees = [];
    this.props.onCancel();
  };

  render() {
    const { formFields } = this.props;

    if (formFields) {
      const corporationList: any[] = this.props.employeeStore!.corporations.map(corporation => {
        return { value: corporation.corpCode, text: corporation.corpName };
      });
      formFields.map(v => {
        if (v.key === 'corp') {
          v.options = corporationList;
        }
        return formFields;
      });
    }

    const { inProgressCurrentEmp, concurrentEmployees } = this.props.employeeStore!;
    return (
      <Drawer
        title={
          <>
            <IconMetanet />
            <FormattedMessage id="employees.title.drawer.select.employee" />
          </>
        }
        width={500}
        visible={this.props.visibleCondition}
        maskClosable={false}
        onClose={this.handleCancel}
      >
        <SelectEmployee
          formFields={formFields}
          spinning={inProgressCurrentEmp}
          listItems={concurrentEmployees}
          onSearch={this.handleSearch}
          onCancel={this.handleCancel}
          onSubmit={this.handleSubmit}
        />
      </Drawer>
    );
  }
}

export default CommonSelectEmployeeDrawer;
