import ApiHelper from 'shared/utils/ApiHelper';
import RoleModel from 'shared/models/role/RoleModel';
import AppConst from 'shared/utils/AppConst';
import { UseYnConfig } from '../../department-management/config/configUseYn';

class RoleService {
  getAllRoles = () => {
    return ApiHelper.get(AppConst.API.SYS_ROLE);
  };

  getRoles = (roleName: string, deptCode: string, useYn: string) => {
    const trimedRoleName = roleName ? roleName.trim() : undefined;
    const trimedDeptCode = deptCode ? deptCode.trim() : undefined;
    const params = {
      roleName: trimedRoleName,
      deptCode: trimedDeptCode,
      useYn: UseYnConfig.mappingApi(useYn)
    };
    return ApiHelper.get(AppConst.API.SYS_ROLE, params);
  };

  getRoleMenu = (roleCode: string) => {
    return ApiHelper.get(AppConst.API.SYS_ROLE_MENU, { roleCode });
  };

  createRoles = (arr: RoleModel[]) => {
    const api = AppConst.API.SYS_ROLE;
    const newArr: any[] = [];
    arr.map(obj => {
      const { name, remark, deptCode, deptName, useYn }: RoleModel = obj;
      newArr.push({
        roleName: name,
        remark,
        deptCode,
        deptName,
        useYn: UseYnConfig.mappingApi(useYn)
      });
    });
    return ApiHelper.post(api, newArr);
  };

  updateRoles = (arr: RoleModel[], reassignedMenu = false) => {
    // reassignedMenu: update menu assignment
    const api = AppConst.API.SYS_ROLE;
    const newArr: any[] = [];
    arr.map(obj => {
      const { name, remark, roleId, deptCode, deptName, roleMenuList, useYn }: RoleModel = obj;
      newArr.push({
        roleName: name,
        remark,
        roleCode: roleId,
        deptCode,
        deptName,
        reassignedMenu,
        roleMenuList,
        useYn: UseYnConfig.mappingApi(useYn)
      });
    });

    return ApiHelper.put(api, newArr);
  };

  exportExcelFile = (seacrhParams: any) => {
    const trimedRoleName = seacrhParams.roleName ? seacrhParams.roleName.trim() : '';
    const useYn = seacrhParams.useYn === 'ALL' ? '' : seacrhParams.useYn;
    const trimDeptCode = seacrhParams.deptCode ? seacrhParams.deptCode.trim() : '';
    const params = {
      roleName: trimedRoleName,
      useYn,
      deptCode: trimDeptCode
    };
    return ApiHelper.get(`${AppConst.API.SYS_ROLE}/export`, params);
  };

  deleteRoles = (arr: RoleModel[]) => {
    const data: any[] = [];
    arr.map(obj => {
      const { name: roleName, remark, roleId: roleCode, deptCode, deptName } = obj;
      const roleAPI = { roleName, remark, roleCode, deptCode, deptName };
      data.push(roleAPI);
    });

    return ApiHelper.delete(AppConst.API.SYS_ROLE, data);
  };
}

export default new RoleService();
