import { observable, action } from 'mobx';

import MasterImportService from '../services/MasterImportService';
import { RootStore } from 'shared/stores/RootStore';

export default class MasterImportStore {
  @observable
  inProgress: boolean;

  @observable
  timeout: number;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.timeout = 0;
    this.rootStore = rootStore;
  }

  @action
  masterAllImpot(): void {
    this.inProgress = true;

    setTimeout(() => {
      this.timeout = 30;
    }, 3000);
    setTimeout(() => {
      this.timeout = 60;
    }, 6000);

    setTimeout(() => {
      this.timeout = 90;
    }, 9000);

    setTimeout(() => {
      this.timeout = 100;
    }, 12000);

    this.inProgress = false;
    setTimeout(() => {
      this.timeout = 0;
    }, 15000);

    this.inProgress = false;
  }

  @action
  async unitPriceImpot(): Promise<void> {
    this.inProgress = true;

    await MasterImportService.unitPriceImpot()
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  async empImpot(): Promise<void> {
    this.inProgress = true;

    await MasterImportService.empImpot()
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  async projectImpot(): Promise<void> {
    this.inProgress = true;

    await MasterImportService.projectImpot()
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  async deptImpot(): Promise<void> {
    this.inProgress = true;

    await MasterImportService.deptImpot()
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  @action
  async interfaceImpot(jobId: any): Promise<void> {
    this.inProgress = true;

    await MasterImportService.interfaceImpot(jobId)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        this.inProgress = false;
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }
}
