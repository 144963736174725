import ApiHelper from 'shared/utils/ApiHelper';
import { AxiosResponse } from 'axios';

const API_BASE = '/timesheet';

class CommonProjectMobileService {
  getProjects(pjCode: String, searchPattern: String) {
    const api = API_BASE + '/projects' + '?pjSearch=' + pjCode + '&searchPattern=' + searchPattern + '&pjStatus=' + 'I';
    return ApiHelper.get(api);
  }

  getPmGroupProjects(pjCode: String, searchPattern: String) {
    const api = API_BASE + '/projects/pmGroup' + '?pjSearch=' + pjCode + '&searchPattern=' + searchPattern;
    return ApiHelper.get(api);
  }
}

export default new CommonProjectMobileService();
