import { observable, action, computed } from 'mobx';
import { message } from 'antd';
import moment from 'moment';
import CalendarModel from '../static/CalendarModel';
import ProjectModel from 'shared/models/project/ProjectModel';
import UtilizationPlanAndResultModel from '../static/UtilizationPlanAndResultModel';
import UtilizationResultAppMobileService from '../services/UtilizationResultAppMobileService';
import { MobileRootStore } from 'shared/mobile-stores/RootStore';

interface GridDataModel {
  injctYm: string;
  planMm: string;
  resultMm: string;
  gap: any | null;
  pjCode: string;
}

interface MonthDrawerDataModel {
  yyyymmdd: string;
  empNo: string;
  empName: string | null;
  deptName: string | null;
  planSum: string;
  resultSum: string;
  gap: string;
}

interface CloseStsModel {
  corpCode: string | null;
  yearMonth: string | null;
  closeSts: string | null;
}

export default class UtilizationResultAppMobileStore {
  @observable
  inProgress: boolean;

  @observable
  nowYearMonth: string;

  @observable
  seletedPjCode: string;

  @observable
  seletedProject: ProjectModel;

  @observable
  monthCalendarList: CalendarModel[];

  @observable
  monthDataList: UtilizationPlanAndResultModel[];

  @observable
  selectedCellData: UtilizationPlanAndResultModel;

  @observable
  monthDrawerDataList: MonthDrawerDataModel[];

  @observable
  closingWeekly = 'N';

  @observable
  closingSts: CloseStsModel;

  rootStore: MobileRootStore;

  constructor(rootStore: MobileRootStore) {
    this.inProgress = false;
    this.rootStore = rootStore;
    this.monthCalendarList = [];
    this.monthDataList = [];
    this.monthDrawerDataList = [];
  }

  @action
  async getMonthData(pjCode: string, selectedMonth: string): Promise<any> {
    this.inProgress = true;

    this.nowYearMonth = selectedMonth;

    await UtilizationResultAppMobileService.getMonthCalendarList(selectedMonth)
      .then(response => {
        const data = response.data.data || [];
        this.monthCalendarList = data;
      })
      .catch((error: any) => {
        console.error(`Errors: ${error}`);
        this.inProgress = false;
      });

    const fromDate = moment(selectedMonth, 'YYYYMM').format('YYYYMMDD');
    const toDate = moment(selectedMonth, 'YYYYMM')
      .endOf('month')
      .format('YYYYMMDD');

    await UtilizationResultAppMobileService.getUtilizationPlanAndResultList(pjCode, fromDate, toDate)
      .then(response => {
        const data = response.data.data || [];

        this.monthDataList = data;
        if (this.selectedCellData) this.getMonthDrawerData();

        this.inProgress = false;
      })
      .catch((error: any) => {
        this.inProgress = false;
      });
  }

  @action
  rejectUtilizationResult(data: MonthDrawerDataModel): any {
    this.inProgress = true;

    return UtilizationResultAppMobileService.rejectUtilizationResult(this.seletedPjCode, data.empNo, data.yyyymmdd)
      .then(() => {
        // const data = response.data.data || [];
        // this.getMonthDrawerData();
        this.getMonthData(this.seletedPjCode, this.nowYearMonth);

        this.inProgress = false;
      })
      .catch((error: any) => {
        console.error(`Errors: ${error}`);
        message.error('저장 중 문제가 발생하였습니다.');
        this.inProgress = false;
      });
  }

  @computed
  get monthPlanSum(): any {
    let planSum = 0;
    const workingDays = this.monthCalendarList.filter(item => item.holidayYn === 'N');

    if (this.monthDataList.length > 0) {
      this.monthDataList.forEach(monthDataItem => {
        monthDataItem.planList.forEach(planItem => {
          planSum += planItem.injctRate;
        });
      });
    }
    return (planSum / workingDays.length).toFixed(2);
  }

  @computed
  get monthResultSum(): any {
    let resultSum = 0;
    const workingDays = this.monthCalendarList.filter(item => item.holidayYn === 'N');

    if (this.monthDataList.length > 0) {
      this.monthDataList.forEach(monthDataItem => {
        monthDataItem.resultList.forEach(resultItem => {
          resultSum += resultItem.injctRate;
        });
      });
    }

    return (resultSum / workingDays.length).toFixed(2);
  }

  @action
  getMonthDrawerData(selectedCellData: UtilizationPlanAndResultModel = this.selectedCellData): any {
    this.selectedCellData = selectedCellData;
    this.inProgress = true;
    this.monthDrawerDataList = [];
    const cellList = this.monthDataList.filter(item => item.calendar.yyyymmdd === selectedCellData.calendar.yyyymmdd)[0];

    // const cellList = this.selectedCellDataList[0];

    let empNoList: string[] = [];
    const monthDrawerDataList: MonthDrawerDataModel[] = [];
    let empName: string | null = '';
    let deptName: string | null = '';

    cellList.planList.forEach(item => empNoList.push(item.empNo));
    cellList.resultList.forEach(item => empNoList.push(item.empNo));
    empNoList = Array.from(new Set(empNoList));

    // for (let item of empNoList) {
    empNoList.forEach(item => {
      let planSum = 0;
      let resultSum = 0;

      cellList.planList.forEach(planItem => {
        if (planItem.empNo === item) {
          planSum += planItem.injctRate;
          empName = planItem.empName;
          deptName = planItem.deptName;
        }
      });
      cellList.resultList.forEach(resultItem => {
        if (resultItem.empNo === item) {
          resultSum += resultItem.injctRate;
          empName = resultItem.empName;
          deptName = resultItem.deptName;
        }
      });

      const resultData = {
        yyyymmdd: cellList.calendar.yyyymmdd,
        empNo: item,
        empName,
        deptName,
        planSum: String(planSum * 100),
        resultSum: String(resultSum * 100),
        gap: String(resultSum * 100 - planSum * 100)
      };

      monthDrawerDataList.push(resultData);
    });

    this.monthDrawerDataList = monthDrawerDataList;
    this.inProgress = false;
  }

  @action
  setSelectedPjCode(project: ProjectModel): any {
    this.seletedPjCode = project.pjCode;
    this.seletedProject = project;
  }

  @action
  getCorpConf(): any {
    // 법인 별 설정정보 가져오기 - 주마감 check
    this.inProgress = true;
    return UtilizationResultAppMobileService.getCorpConf()
      .then(response => {
        const data = response.data.data || [];

        this.closingWeekly = data.closingWeekly;

        this.inProgress = false;
      })
      .catch((error: any) => {
        console.error(`Errors: ${error}`);
        this.inProgress = false;
      });
  }

  @action
  getClosingSts(yyyymmdd: string): any {
    return UtilizationResultAppMobileService.getCloseSts(
      moment(yyyymmdd, 'YYYYMMDD')
        .format('YYYYMM')
        .toString()
    )
      .then(response => {
        const data = response.data.data || [];
        this.closingSts = data;
        return data;
      })
      .catch((error: any) => {
        console.error(`Errors: ${error}`);
      });
  }
}
