/* eslint-disable class-methods-use-this */
import ApiHelper from 'shared/utils/ApiHelper';
import { AxiosResponse } from 'axios';

const API_BASE = '/timesheet';

class RawDataDownService {
  workHourImpot(): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/unit-price-grade-import`;
    return ApiHelper.get(api);
  }

  exportWorkHourExcelFile = (deptCode: string, from: string, to: string, empName: string): any => {
    const api = `${`${API_BASE}/overtime/export`}`;
    const trimedDeptCode = deptCode ? deptCode.trim() : '';
    const trimedFrom = from ? from.trim() : '';
    const trimedTo = to ? to.trim() : '';
    const trimedEmpName = empName ? empName.trim() : '';
    const params = {
      deptCode: trimedDeptCode,
      from: trimedFrom,
      to: trimedTo,
      empName: trimedEmpName
    };
    return ApiHelper.get(api, params);
  };

  exportUtilPlanExcelFile = (deptCode: string, from: string, to: string, empName: string): any => {
    const api = `${`${API_BASE}/utilization-plan/export`}`;
    const trimedDeptCode = deptCode ? deptCode.trim() : '';
    const trimedFrom = from ? from.trim() : '';
    const trimedTo = to ? to.trim() : '';
    const trimedEmpName = empName ? empName.trim() : '';
    const params = {
      deptCode: trimedDeptCode,
      from: trimedFrom,
      to: trimedTo,
      empName: trimedEmpName
    };
    return ApiHelper.get(api, params);
  };

  exportUtilResultExcelFile = (deptCode: string, from: string, to: string, empName: string): any => {
    const api = `${`${API_BASE}/utilization-result/export`}`;
    const trimedDeptCode = deptCode ? deptCode.trim() : '';
    const trimedFrom = from ? from.trim() : '';
    const trimedTo = to ? to.trim() : '';
    const trimedEmpName = empName ? empName.trim() : '';
    const params = {
      deptCode: trimedDeptCode,
      from: trimedFrom,
      to: trimedTo,
      empName: trimedEmpName
    };
    return ApiHelper.get(api, params);
  };
}

export default new RawDataDownService();
