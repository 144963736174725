import { action, observable, toJS } from 'mobx';
import ITableStore from 'shared/containers/MobXEditTable/ITableStore';
import ApiModel from 'shared/models/api/ApiModel';
import ApiSearchModel from 'shared/models/api/ApiSearchModel';
import { RootStore } from 'shared/stores/RootStore';
import AppConst from 'shared/utils/AppConst';
import AppHelper from 'shared/utils/AppHelper';
import LanguageHelper from 'shared/utils/LanguageHelper';
import uuid from 'uuid';
import ApiService from '../services/ApiService';

export class ApiStore implements ITableStore<ApiModel> {
  @observable
  dataSource: ApiModel[];

  @observable
  selectedItem?: ApiModel | undefined;

  @observable
  selectedRowKeys: string[];

  @observable
  inProgress: boolean;

  rootStore: RootStore;
  @observable
  searchModel: ApiSearchModel;

  @observable
  lastSearch: any;

  /** Current selected row key */
  @observable
  selectedRowKey: string;

  constructor(rootStore: any) {
    this.rootStore = rootStore;
    this.dataSource = [];
    this.searchModel = {
      apiName: '',
      apiPath: '',
      useYn: '',
      usePattern: ''
    };
    this.lastSearch = null;
  }

  submit = () => {
    this.inProgress = true;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const _insert = this.summaryParamsUpsert(this.dataSource.filter(x => x.changeStatus === AppConst.ActionType.CREATE));
    const _update = this.summaryParamsUpsert(this.dataSource.filter(x => x.changeStatus === AppConst.ActionType.UPDATE));
    const _delete = this.dataSource.filter(x => x.changeStatus === AppConst.ActionType.DELETE);

    if (_insert.length === 0 && _update.length === 0 && _delete.length === 0) {
      AppHelper.showMessage('warning', LanguageHelper.getMessage('message.nothing.submit'));
      this.inProgress = false;
      return;
    }
    const _deleteIds = _delete.map(x => {
      return x.apiAuthCode;
    });
    const promises: any[] = [];
    if (_insert.length > 0) {
      promises.push(ApiService.createArray(_insert));
    }
    if (_update.length > 0) {
      promises.push(ApiService.updateArray(_update));
    }
    if (_delete.length > 0) {
      promises.push(ApiService.deleteArray(_deleteIds));
    }

    Promise.all(promises)
      .then(function submitApi(responses: any[]) {
        self.rootStore.apiHandleStore.handleAllApiResult(responses);
        self.find();
      })
      .catch((error: any) => {
        console.error(error);
        this.inProgress = false;
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  };

  @action
  setDataSource = (dataSource: ApiModel[]) => {
    this.dataSource = dataSource;
  };

  @action
  setSelectedItem = (selectedItem: any) => {
    this.selectedItem = selectedItem;
  };

  @action
  setSelectedRowKeys(selectedRowKeys: string[]) {
    this.selectedRowKeys = selectedRowKeys;
  }

  @action
  exportExcelFile() {
    this.inProgress = true;
    return ApiService.export(this.lastSearch)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const url = response.data.data.url || '';
        if (url && url !== '') {
          AppHelper.downloadExcel(url);
        }
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  find = () => {
    this.inProgress = true;
    const newParamsSearch = this.handleTrimValue(toJS(this.searchModel));
    this.lastSearch = this.handleTrimValue(toJS(this.searchModel));
    const myApi = Array<ApiModel>();
    this.dataSource = Array<ApiModel>();

    this.setSelectedRowKeys([]);
    return ApiService.find(newParamsSearch)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        const length = data ? data.length : 0;
        for (let index = 0; index < length; index++) {
          const element = data[index];
          element.id = uuid.v4();
          element.changeStatus = AppConst.ActionType.READ;
          myApi.push(element);
        }

        const myApiSort = myApi.sort(function(a, b) {
          return AppHelper.getStringSortResult(a.apiAuthCode, b.apiAuthCode, true);
        });
        myApiSort.map((v, i) => {
          v.no = i + 1;
        });
        this.dataSource = myApiSort;
        this.inProgress = false;
      })
      .catch(error => {
        this.inProgress = false;
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  };

  handleTrimValue = (obj: any) => {
    Object.keys(obj).forEach((key: any) => {
      if (!obj[key]) {
        delete obj[key];
      } else {
        obj[key] = obj[key].trim();
      }
    });
    return obj;
  };

  summaryParamsUpsert = (arr: any) => {
    arr.map((item: any) => {
      delete item.changeStatus;
      delete item.id;
      delete item.key;
      delete item.no;
      delete item.updatedDate;
      delete item.rowStatus;
      delete item.updatedEmpNo;
      delete item.regDate;
      return arr;
    });
    return arr;
  };

  @action
  setCurrentSelectedRow(selectedRowKey: string) {
    this.selectedRowKey = selectedRowKey;
    const { dataSource } = this;
    dataSource.map((itemOrigin: any) => {
      if (itemOrigin.id === this.selectedRowKey) {
        itemOrigin.changeStatus = itemOrigin.changeStatus === 'DELETE' ? 'READ' : itemOrigin.changeStatus;
      }
    });
  }
}
