import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import OvertimeAppMobileStore from '../stores/OvertimeAppMobileStore';
import OverTimeSumModel from '../static/OverTimeSumModel';
import AppMobileCardComponent from '../components/AppMobileCardComponent';
import MobileLanguageHelper from 'shared/utils/MobileLanguageHelper';

interface Props {
  overtimeAppMobileStore?: OvertimeAppMobileStore;
  forwardDetailView: () => void;
}

const Message = styled.div`
  color: #ff0000;
  padding: 10px;
  font-size: 12px;
  font-weight: 700;
`;

@inject('overtimeAppMobileStore')
@observer
class AppMobileBodyContainer extends Component<Props> {
  renderContents = (gridData: OverTimeSumModel[]) => {
    const elementList: JSX.Element[] = [];
    gridData.forEach(item => {
      if (Number(item.countsAppr) > 0) {
        elementList.push(<AppMobileCardComponent forwardDetailView={this.props.forwardDetailView} data={item} />);
      }
    });
    return elementList;
  };

  render() {
    const { gridData } = this.props.overtimeAppMobileStore!;
    return (
      <>
        <Message>{MobileLanguageHelper.getMessage('overtime.header.title.havingdetails')}</Message>
        {this.renderContents(gridData)}
      </>
    );
  }
}

export default AppMobileBodyContainer;
