import React, { Component } from 'react';
import * as autoBind from 'auto-bind';
import AppConst from 'shared/utils/AppConst';
import HRMBreadcrumb from 'components/HRMBreadcrumb';
import OverTimeMonthWrapperStateDetailContainer from '../containers/OverTimeMonthWrapperStateDetailContainer';

interface Props {
  match?: any;
  history?: any;
}

export default class OvertimeMonthStateDetailView extends Component<Props> {
  representCode: string;
  representCodeName: string;
  commonCode: string;
  useYnText: string;

  constructor(props: any) {
    super(props);
    autoBind.react(this);

    this.representCode = '';
    this.representCodeName = '';
    this.commonCode = '';
    this.useYnText = AppConst.SELECT_ALL;
  }

  renderView = (): JSX.Element => {
    return <OverTimeMonthWrapperStateDetailContainer {...this.props} />;
  };

  render(): JSX.Element {
    const urlPath = this.props.match.path;

    return (
      <>
        <HRMBreadcrumb urlPath={urlPath} />
        {this.renderView()}
      </>
    );
  }
}
