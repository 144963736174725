import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import { isIOS } from 'react-device-detect';
import CalendarMobileVerticalCardComponent from './CalendarMobileVerticalCardComponent';
import OverTimeMonthModel from '../static/OverTimeMonthModel';
import MobileLanguageHelper from 'shared/utils/MobileLanguageHelper';

interface Props {
  overTimeMonthList: OverTimeMonthModel[];
  waypointEnter?: (a: any, b: any) => void;
  onChangeHeaderVisible: (a: boolean) => void;
  waypointWeek: any;
  headerVisible: boolean;
}

const RenderWrapper = styled.div`
  padding: 5px 5px 15px 5px;
  text-align: center;
  background-color: #ffffff;
`;

const SummaryHeader = styled.div`
  font-weight: 700;
`;

const SummaryWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
`;

const SummaryItem = styled.div<{ bgcolor: string; color: string }>`
  background-color: ${props => props.bgcolor};
  color: ${props => props.color};
  text-align: center;
  width: 30%;
  padding: 2px;
`;

const SummaryItemName = styled.div`
  font-size: 12px;
`;

const FixedSummary = styled.div<{ headerVisible: boolean; isIOS: boolean }>`
  position: fixed;
  width: 100%;
  top: ${props => (!props.isIOS ? (props.headerVisible ? '165px' : '135px') : props.headerVisible ? '105px' : '105px')};
  z-index: 900;
`;

@inject('overtimeCalendarMobileStore')
@observer
export default class CalendarMobileVerticalComponent extends Component<Props> {
  renderSummary = (weekNumber: string, overTimeMonthWeeklyList: OverTimeMonthModel[], waypointWeek: any, headerVisible: boolean): JSX.Element => {
    let otAllSum = 0;
    let otAppSum = 0;
    let abAllSum = 0;
    let abAppSum = 0;
    let allSum = 0;
    let appSum = 0;

    overTimeMonthWeeklyList.forEach(item => {
      if (item.apprSts === 'S' && item.overTimeType === 'OT' && item.reducYn === null && item.leaveYn === null) {
        otAppSum += item.overtimeWorkHours;
        appSum += item.overtimeWorkHours;
      } else if (item.apprSts === 'S' && item.overTimeType === 'AB' && item.reducYn === null && item.leaveYn === null) {
        abAppSum -= item.overtimeWorkHours;
        appSum -= item.overtimeWorkHours;
      }

      if ((item.apprSts === 'S' || item.apprSts === 'A') && item.overTimeType === 'OT' && item.reducYn === null && item.leaveYn === null) {
        otAllSum += item.overtimeWorkHours;
        allSum += item.overtimeWorkHours;
      } else if ((item.apprSts === 'S' || item.apprSts === 'A') && item.overTimeType === 'AB' && item.reducYn === null && item.leaveYn === null) {
        abAllSum -= item.overtimeWorkHours;
        allSum -= item.overtimeWorkHours;
      }
    });

    if (waypointWeek === weekNumber) {
      return (
        <>
          <FixedSummary headerVisible={headerVisible} isIOS={isIOS} key={'vf' + weekNumber}>
            <RenderWrapper key={'vs' + weekNumber}>
              <SummaryHeader>
                {weekNumber} {MobileLanguageHelper.getMessage('overtime.card.title.week')}
              </SummaryHeader>
              <SummaryWrapper>
                <SummaryItem bgcolor="#bdbdd9" color="#57585a">
                  <SummaryItemName>{MobileLanguageHelper.getMessage('overtime.card.title.overtime')}</SummaryItemName>
                  <div>
                    {otAppSum} ({otAllSum})
                  </div>
                </SummaryItem>
                <SummaryItem bgcolor="#b9d0b4" color="#57585a">
                  <SummaryItemName>{MobileLanguageHelper.getMessage('overtime.card.title.alterbreak')}</SummaryItemName>
                  <div>
                    {abAppSum} ({abAllSum})
                  </div>
                </SummaryItem>
                <SummaryItem bgcolor="#d8dad9" color="#57585a">
                  <SummaryItemName>{MobileLanguageHelper.getMessage('overtime.card.title.sum')}</SummaryItemName>
                  <div>
                    {appSum} ({allSum})
                  </div>
                </SummaryItem>
              </SummaryWrapper>
            </RenderWrapper>
          </FixedSummary>

          <RenderWrapper key={'v' + weekNumber}>
            <SummaryHeader>
              {weekNumber} {MobileLanguageHelper.getMessage('overtime.card.title.week')}
            </SummaryHeader>
            <SummaryWrapper>
              <SummaryItem bgcolor="#bdbdd9" color="#57585a">
                <SummaryItemName>{MobileLanguageHelper.getMessage('overtime.card.title.overtime')}</SummaryItemName>
                <div>
                  {otAppSum} ({otAllSum})
                </div>
              </SummaryItem>
              <SummaryItem bgcolor="#b9d0b4" color="#57585a">
                <SummaryItemName>{MobileLanguageHelper.getMessage('overtime.card.title.alterbreak')}</SummaryItemName>
                <div>
                  {abAppSum} ({abAllSum})
                </div>
              </SummaryItem>
              <SummaryItem bgcolor="#d8dad9" color="#57585a">
                <SummaryItemName>{MobileLanguageHelper.getMessage('overtime.card.title.sum')}</SummaryItemName>
                <div>
                  {appSum} ({allSum})
                </div>
              </SummaryItem>
            </SummaryWrapper>
          </RenderWrapper>
        </>
      );
    } else {
      return (
        <>
          <RenderWrapper key={'v' + weekNumber}>
            <SummaryHeader>
              {weekNumber} {MobileLanguageHelper.getMessage('overtime.card.title.week')}
            </SummaryHeader>
            <SummaryWrapper>
              <SummaryItem bgcolor="#bdbdd9" color="#57585a">
                <SummaryItemName>{MobileLanguageHelper.getMessage('overtime.card.title.overtime')}</SummaryItemName>
                <div>
                  {otAppSum} ({otAllSum})
                </div>
              </SummaryItem>
              <SummaryItem bgcolor="#b9d0b4" color="#57585a">
                <SummaryItemName>{MobileLanguageHelper.getMessage('overtime.card.title.alterbreak')}</SummaryItemName>
                <div>
                  {abAppSum} ({abAllSum})
                </div>
              </SummaryItem>
              <SummaryItem bgcolor="#d8dad9" color="#57585a">
                <SummaryItemName>{MobileLanguageHelper.getMessage('overtime.card.title.sum')}</SummaryItemName>
                <div>
                  {appSum} ({allSum})
                </div>
              </SummaryItem>
            </SummaryWrapper>
          </RenderWrapper>
        </>
      );
    }
  };

  renderDate = (
    overTimeMonthList: OverTimeMonthModel[],
    waypointEnter: (a: any, b: any) => void,
    onChangeHeaderVisible: (a: boolean) => void,
    waypointWeek: any,
    headerVisible: boolean
  ): JSX.Element[] => {
    const cardComponentList: JSX.Element[] = [];
    let weekNumber: string = '0';
    let count = 0;
    let overTimeMonthWeeklyList: OverTimeMonthModel[] = [];

    overTimeMonthList.forEach(overtimeItem => {
      const thisWeekList = overTimeMonthList.filter(item => item.workSysMonthWeekly === overtimeItem.workSysMonthWeekly);

      if (weekNumber !== overtimeItem.workSysMonthWeekly) {
        cardComponentList.push(this.renderSummary(overtimeItem.workSysMonthWeekly, thisWeekList, waypointWeek, headerVisible));
        weekNumber = overtimeItem.workSysMonthWeekly;
        overTimeMonthWeeklyList = [];
      }
      cardComponentList.push(
        <CalendarMobileVerticalCardComponent
          {...this.props}
          overtimeItem={overtimeItem}
          waypointEnter={waypointEnter}
          onChangeHeaderVisible={onChangeHeaderVisible}
          key={'v' + overtimeItem.yyyymmdd}
        />
      );
      overTimeMonthWeeklyList.push(overtimeItem);
      // count += 1;
      // if (count === overTimeMonthList.length) {
      //   cardComponentList.push(this.renderSummary(weekNumber, overTimeMonthWeeklyList));
      // }
    });
    return cardComponentList;
  };

  render() {
    const { overTimeMonthList, waypointEnter, onChangeHeaderVisible, waypointWeek, headerVisible } = this.props;
    return <>{this.renderDate(overTimeMonthList, waypointEnter!, onChangeHeaderVisible, waypointWeek, headerVisible)}</>;
  }
}
