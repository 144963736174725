/* eslint-disable class-methods-use-this */
import ApiHelper from 'shared/utils/ApiHelper';

import { AxiosResponse } from 'axios';
import HolidayListModel from '../static/HolidayListModel';

const API_BASE = '/timesheet';

class HolidayListService {
  getYyyyList() {
    const api = `${API_BASE}/calendars/yyyy`;
    return ApiHelper.get(api);
  }

  getHolidayList(yyyy: string) {
    const api = `${API_BASE}/holidays/param?yyyy=${yyyy}`;
    return ApiHelper.get(api);
  }

  createHoliday(HolidayList: HolidayListModel): any {
    const api = `${API_BASE}/holidays`;
    return ApiHelper.post(api, HolidayList);
  }

  updateHoliday(HolidayList: HolidayListModel): any {
    const api = `${API_BASE}/holidays`;
    return ApiHelper.put(api, HolidayList);
  }

  deleteHoliday(yyyymmdd: string): any {
    const api = `${API_BASE}/holidays/${yyyymmdd}`;
    return ApiHelper.delete(api, yyyymmdd);
  }
}

export default new HolidayListService();
