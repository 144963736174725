import { action, observable, toJS, computed } from 'mobx';
import ITableStore from 'shared/containers/MobXEditTable/ITableStore';
import CorpModel from 'shared/models/corp/CorpModel';
import { RootStore } from 'shared/stores/RootStore';
import AppConst from 'shared/utils/AppConst';
import AppHelper from 'shared/utils/AppHelper';
import LanguageHelper from 'shared/utils/LanguageHelper';
import uuid from 'uuid';
import CorpSearchModel from 'shared/models/corp/CorpSearchModel';
import CorpService from '../services/CorpService';
import _ from 'lodash';
export class CorpStore implements ITableStore<CorpModel> {
  @observable
  dataSource: CorpModel[];

  @observable
  selectedItem: CorpModel;

  @observable
  selectedRowKeys: string[];

  @observable
  inProgress: boolean;

  rootStore: RootStore;
  @observable
  searchModel: CorpSearchModel;

  @observable
  lastSearch: any;

  @observable
  private _originCorp: CorpModel[] = [];

  /** Current selected row key */
  @observable
  selectedRowKey: string;

  constructor(rootStore: any) {
    this.rootStore = rootStore;
    this.resetStore();
  }

  @computed
  get originalCorp() {
    return this._originCorp;
  }

  @action
  setDataSource = (dataSource: CorpModel[]) => {
    this.dataSource = dataSource;
  };

  @action
  setSelectedItem = (selectedItem: any) => {
    this.selectedItem = selectedItem;
  };

  getDataSource() {
    return this.dataSource;
  }

  @action
  exportExcelFile() {
    this.inProgress = true;
    return CorpService.export(this.lastSearch)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const url = response.data.data.url || '';
        if (url && url !== '') {
          AppHelper.downloadExcel(url);
        }
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  find = () => {
    this.inProgress = true;
    const newParamsSearch = this.handleTrimValue(toJS(this.searchModel));
    this.lastSearch = this.handleTrimValue(toJS(this.searchModel));
    const myCorp = Array<CorpModel>();
    this.dataSource = Array<CorpModel>();

    this.setSelectedRowKeys([]);
    return CorpService.find(newParamsSearch)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const data = response.data.data || [];
        const length = data ? data.length : 0;
        for (let index = 0; index < length; index++) {
          const element = data[index];
          element.id = uuid.v4();
          element.changeStatus = AppConst.ActionType.READ;
          myCorp.push(element);
        }
        const myCorpSort = _.orderBy(myCorp, ['corpCode', 'startDate'], ['asc', 'desc']);
        myCorpSort.map((v, i) => {
          v.no = i + 1;
        });
        this.dataSource = myCorpSort;
        this._originCorp = myCorpSort;

        this.inProgress = false;
      })
      .catch(error => {
        this.inProgress = false;
        this.rootStore.apiHandleStore.handleApiError(error);
      });
  };

  handleTrimValue = (obj: any) => {
    Object.keys(obj).forEach((key: any) => {
      if (!obj[key]) {
        delete obj[key];
      } else {
        obj[key] = obj[key].trim();
      }
    });
    return obj;
  };

  @action
  removeBlankRow() {
    this.dataSource.splice(-1, 1);
  }

  @action
  submit() {
    this.inProgress = true;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const _insert = this.dataSource.filter(x => x.changeStatus === AppConst.ActionType.CREATE);
    const _update = this.dataSource.filter(x => x.changeStatus === AppConst.ActionType.UPDATE);
    const _delete = this.dataSource.filter(x => x.changeStatus === AppConst.ActionType.DELETE);

    if (_insert.length === 0 && _update.length === 0 && _delete.length === 0) {
      AppHelper.showMessage('warning', LanguageHelper.getMessage('message.nothing.submit'));
      this.inProgress = false;
      return;
    }

    const _deleteIds = _delete.map(x => {
      return {
        corpCode: x.corpCode,
        endDate: x.endDate
      };
    });

    const promises: any[] = [];
    if (_insert.length > 0) {
      _insert.map(item => {
        delete item.id;
        delete item.changeStatus;
      });
      promises.push(CorpService.createCorp(_insert));
    }
    if (_update.length > 0) {
      _update.map(item => {
        delete item.id;
        delete item.changeStatus;
        delete item.endDate;
        delete item.regEmpNo;
        delete item.regDate;
        delete item.updatedEmpNo;
        delete item.updatedDate;
      });
      promises.push(CorpService.updateCorp(_update));
    }
    if (_delete.length > 0) {
      promises.push(CorpService.deleteCorp(_deleteIds));
    }

    Promise.all(promises)
      .then(function submitData(responses: any[]) {
        self.rootStore.apiHandleStore.handleAllApiResult(responses);
        self.find();
      })
      .catch((error: any) => {
        console.error(error);
        this.inProgress = false;
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  @action
  setSelectedRowKeys(selectedRowKeys: string[]) {
    const { dataSource } = this;

    if (selectedRowKeys && selectedRowKeys.length > 0) {
      dataSource.map((itemOrigin: any) => {
        selectedRowKeys.map((seletedId: any) => {
          if (itemOrigin.id !== seletedId) {
            // check uncheck latter
            if (itemOrigin.changeStatus === 'DELETE') {
              const itemUncheck = this._originCorp.find(obj => obj.id === itemOrigin.id);
              if (itemUncheck) {
                itemOrigin.endDate = itemUncheck.endDate;
                itemOrigin.changeStatus = 'READ';
              }
            }
          }
        });
      });
    } else {
      dataSource.map((itemOrigin: any) => {
        if (itemOrigin.changeStatus === 'DELETE') {
          const itemUncheck = this._originCorp.find(obj => obj.id === itemOrigin.id);
          if (itemUncheck) {
            itemOrigin.endDate = itemUncheck.endDate;
            itemOrigin.changeStatus = 'READ';
          }
        }
      });
    }
    this.selectedRowKeys = selectedRowKeys;
  }

  getSelectedRowKeys() {
    return this.selectedRowKeys;
  }

  @action
  resetStore = () => {
    this.dataSource = [];
    this.searchModel = {
      corpCode: '',
      corpName: '',
      useYn: ''
    };
    this.lastSearch = null;
  };

  @action
  setCurrentSelectedRow(selectedRowKey: string) {
    this.selectedRowKey = selectedRowKey;
  }
}
