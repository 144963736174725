import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import CalendarMobileVerticalComponent from '../components/CalendarMobileVerticalComponent';
import OverTimeMonthModel from '../static/OverTimeMonthModel';
import { isIOS } from 'react-device-detect';

interface Props {
  overTimeMonthList: OverTimeMonthModel[];
  waypointEnter?: (a: any, b: any) => void;
  onChangeHeaderVisible: (a: boolean) => void;
  waypointWeek: any;
  headerVisible: boolean;
}

const ContentWrapper = styled.div<{ isIOS: boolean }>`
  margin-top: ${props => (!props.isIOS ? '120px' : '60px')};
  /* margin-top: 120px; */
  padding-bottom: 100px;
  background-color: #f3f3f4;
`;

const WrapperVerticalCalendar = styled.div`
  /* padding: 20px; */
  background-color: #f3f3f4;
`;

@inject('overtimeCalendarMobileStore')
@observer
export default class CalendarMobileBodyContainer extends Component<Props> {
  render() {
    const { overTimeMonthList, waypointEnter, onChangeHeaderVisible, waypointWeek, headerVisible } = this.props;
    return (
      <ContentWrapper isIOS={isIOS}>
        <WrapperVerticalCalendar>
          <CalendarMobileVerticalComponent
            {...this.props}
            waypointEnter={waypointEnter}
            overTimeMonthList={overTimeMonthList}
            onChangeHeaderVisible={onChangeHeaderVisible}
            waypointWeek={waypointWeek}
            headerVisible={headerVisible}
          />
        </WrapperVerticalCalendar>
      </ContentWrapper>
    );
  }
}
