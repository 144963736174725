/* eslint-disable class-methods-use-this */
import ApiHelper from 'shared/utils/ApiHelper';
import { AxiosResponse } from 'axios';
import ReducWorkModel from '../static/ReducWorkModel';

const API_BASE = '/timesheet';

class ReducWorkManagementService {
  getWorkTimeList(empNo: string, empNm: string, type: string, fromDt: string, toDt: string): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/reduc-works`;
    const trimedEmpNo = empNo ? empNo.trim() : '';
    const trimedEmpNm = empNm ? empNm.trim() : '';
    const trimedType = type ? type.trim() : '';
    const trimedFromDt = fromDt ? fromDt.trim() : '';
    const trimedToDt = toDt ? toDt.trim() : '';
    const params = {
      empNo: trimedEmpNo,
      empNm: trimedEmpNm,
      type: trimedType,
      fromDt: trimedFromDt,
      toDt: trimedToDt
    };
    return ApiHelper.get(api, params);
  }

  createWorkTimeData(reducWorkModel: ReducWorkModel): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/reduc-works`;
    return ApiHelper.post(api, reducWorkModel);
  }

  updateWorkTimeData(reducWorkModel: ReducWorkModel): Promise<AxiosResponse<any>> {
    const api = `${API_BASE}/reduc-works`;
    return ApiHelper.put(api, reducWorkModel);
  }

  deleteWorkTimeData(empNo: string, seq: number) {
    const api = `${API_BASE}/emps/${empNo}/reduc-works/${seq}`;
    return ApiHelper.delete(api, []);
  }
}

export default new ReducWorkManagementService();
