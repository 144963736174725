import { Button, Card, Drawer } from 'antd';
import IconMetanet from 'components/IconMetanet';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import ListDepartmentTree from 'shared/containers/ListDepartmentTree';

interface IProps {
  onCloseDrawer: Function;
  onSaveDrawer: Function;
  visibleCondition: boolean;
}

@observer
class DrawerDepartment extends Component<IProps> {
  @observable
  selectedDepartment?: string;

  handleClose = () => {
    this.props.onCloseDrawer();
  };

  handleSave = () => {
    if(this.selectedDepartment !== undefined){
      this.props.onSaveDrawer(this.selectedDepartment);
    this.props.onCloseDrawer();
    }    
  };

  handleSelectDepartment = (departmentUUID: string) => {
    this.selectedDepartment = departmentUUID;
  };

  render() {
    return (
      <Drawer
        width={500}
        title={
          <>
            <IconMetanet /> <FormattedMessage id="title.drawer.department.assigment" />
          </>
        }
        placement="right"
        onClose={this.handleClose}
        visible={this.props.visibleCondition}
        maskClosable={false}
        zIndex={1070} // project drawer 보다 위에 올라오도록 수정
      >
        <ListDepartmentTree afterSelect={this.handleSelectDepartment} />
        <Card size="small" bordered={false} bodyStyle={{ background: '#f5f5f5', borderRadius: 8, textAlign: 'right', marginTop: 12 }}>
          <Button style={{ marginRight: '1em' }} onClick={this.handleClose}>
            <FormattedHTMLMessage id="button.title.cancel" />
          </Button>
          <Button type="primary" onClick={() => this.handleSave()}>
            <FormattedHTMLMessage id="button.title.select" />
          </Button>
        </Card>
      </Drawer>
    );
  }
}

export default DrawerDepartment;
