import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import { Icon, Calendar } from 'antd-mobile';
import CalendarMobileHorizontalComponents from '../components/CalendarMobileHorizontalComponent';
import OvertimeCalendarMobileStore from '../stores/OvertimeCalendarMobileStore';
import OverTimeMonthModel from '../static/OverTimeMonthModel';
import moment from 'moment';
import enUS from 'antd-mobile/lib/calendar/locale/en_US';
import { isIOS } from 'react-device-detect';
import MobileLanguageHelper from 'shared/utils/MobileLanguageHelper';

const now = new Date();

interface Props {
  overTimeMonthList: OverTimeMonthModel[];
  overtimeCalendarMobileStore?: OvertimeCalendarMobileStore;
  waypointDate: any;
  horizontalHandleClick?: (a: any) => void;
  headerVisible: boolean;
}

const HeaderWrapper = styled.div`
  height: 40px;
  /* margin-top: 45px; */
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 45px;
`;

const RenderMonth = styled.div`
  height: 30px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  padding: 5px;
  font-family: '맑은 고딕';
  background-color: #ffffff;

  display: flex;
  justify-content: space-between;
`;

const WrapperHorizontalCalendar = styled.div`
  padding-top: 20px;
  height: 60px;
  background-color: #ffffff;
  width: 100%;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const RenderInfo = styled.div<{ visible: boolean; isIOS: boolean }>`
  background-color: #ffffff;
  padding: ${props => (props.isIOS ? '10px 0 5px 0' : '0')};
  height: 30px;
  display: flex;
  justify-content: space-around;
  display: ${props => (props.isIOS ? 'display' : props.visible ? 'display' : 'none')};
  font-size: 12px;
  align-items: center;
`;

const MonthlyTotalHour = styled.span`
  font-weight: 700;
  font-size: 14px;
`;

@inject('overtimeCalendarMobileStore')
@observer
export default class CalendarMobileHeaderContainer extends Component<Props> {
  originbodyScrollY = document.getElementsByTagName('body')[0].style.overflowY;

  state = {
    selectedMonth: '',
    popOverVisible: false,
    calendarShow: false,
    config: {}
  };

  renderMonth = (yearMonth: string): string => {
    return moment(yearMonth, 'YYYYMM').format('YYYY-MM');
  };

  openCalendar = () => {
    document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
    this.setState({
      calendarShow: true
    });
  };

  arrowClick = (yearMonth: string): void => {
    this.props.overtimeCalendarMobileStore!.getMonthOverTime(yearMonth);
  };

  onConfirmCalendar = (startTime: any, endTime: any) => {
    document.getElementsByTagName('body')[0].style.overflowY = this.originbodyScrollY;
    this.setState({
      calendarShow: false,
      startTime,
      endTime
    });

    if (this.props.overtimeCalendarMobileStore!.overTimeMonthList.find(item => item.yyyymmdd === moment(startTime).format('YYYYMMDD')) !== undefined) {
      // if (moment(startTime).format('YYYYMM') === this.props.overtimeCalendarMobileStore!.nowWorkSysYearMonth) {
      this.props.horizontalHandleClick!(moment(startTime).format('MMDD'));
    } else {
      if (moment(startTime).format('YYYYMM') === this.props.overtimeCalendarMobileStore!.nowWorkSysYearMonth) {
        this.props.overtimeCalendarMobileStore!.getMonthOverTime(
          moment(startTime)
            .add(-1, 'month')
            .format('YYYYMM')
        );
      } else {
        this.props.overtimeCalendarMobileStore!.getMonthOverTime(moment(startTime).format('YYYYMM'));
      }
    }
  };

  onCancelCalendar = () => {
    document.getElementsByTagName('body')[0].style.overflowY = this.originbodyScrollY;
    this.setState({
      calendarShow: false,
      startTime: undefined,
      endTime: undefined
    });
  };

  render() {
    const { preWorkSysYearMonth, nowWorkSysYearMonth, nextWorkSysYearMonth } = this.props.overtimeCalendarMobileStore!;
    const { overTimeMonthList, waypointDate, horizontalHandleClick, headerVisible } = this.props;

    return (
      <>
        <HeaderWrapper>
          <RenderMonth>
            <Icon type="left" onClick={(): void => this.arrowClick(preWorkSysYearMonth)} style={{ marginTop: '5px' }} />
            &nbsp;
            <span onClick={() => this.openCalendar()}>{this.renderMonth(nowWorkSysYearMonth)}</span>&nbsp;
            <Icon type="right" onClick={(): void => this.arrowClick(nextWorkSysYearMonth)} style={{ marginTop: '5px' }} />
          </RenderMonth>
          {!isIOS ? (
            <WrapperHorizontalCalendar>
              <CalendarMobileHorizontalComponents overTimeMonthList={overTimeMonthList} waypointDate={waypointDate} horizontalHandleClick={horizontalHandleClick} />
            </WrapperHorizontalCalendar>
          ) : (
            <div />
          )}
          <RenderInfo visible={headerVisible} isIOS={isIOS}>
            <div>{this.props.overtimeCalendarMobileStore!.workTypeName}</div>
            <div>
              {MobileLanguageHelper.getMessage('overtime.header.title.monthlyavg')} <MonthlyTotalHour>{this.props.overtimeCalendarMobileStore!.monthlyTotalHour}</MonthlyTotalHour>{' '}
              {MobileLanguageHelper.getMessage('overtime.header.title.hours')}
            </div>
          </RenderInfo>
        </HeaderWrapper>
        <Calendar
          {...this.state.config}
          visible={this.state.calendarShow}
          onCancel={this.onCancelCalendar}
          onConfirm={this.onConfirmCalendar}
          // onSelectHasDisableDate={this.onSelectHasDisableDate}
          // getDateExtra={this.getDateExtra}
          defaultDate={now}
          type={'one'}
          locale={enUS}
          style={{ position: 'fixed', zIndex: 1050 }}
        />
      </>
    );
  }
}
