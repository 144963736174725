import uuid from 'uuid';

export type THelpImageItem = {
  data: string; // base64 data
  fileName: string;
};
export type TMenuHelpContent = {
  language: ELangKey;
  contentText?: string;
  contentImages?: THelpImageItem[];
};

export enum ELangKey {
  KO = 'KO',
  EN = 'EN'
}

class MenuHelpModel {
  id: string;
  menuCode: string;
  content?: TMenuHelpContent[];

  constructor(obj: any, id?: string) {
    this.id = id || uuid.v4();
    this.menuCode = obj.menuCode;
    this.content = obj.content;
  }
}
export default MenuHelpModel;
