import axios from 'axios';
import AppConst from 'shared/utils/AppConst';
import ApiHelper from '../../../shared/utils/ApiHelper';
import StorageHelper from '../../../shared/utils/StorageHelper';

class AuthenticationService {
  login = (userName: string, password: string): any => {
    const { API_URL } = AppConst;
    const headers = {
      headers: {
        'Accept-Language': AppConst.LANG_KOKR
      }
    };
    const params = {
      userName,
      password
    };
    return axios.post(API_URL + AppConst.API.LOGIN, params, headers);
  };

  sso = (userName: string, password: string): any => {
    const { API_URL } = AppConst;
    const headers = {
      headers: {
        'Accept-Language': AppConst.LANG_KOKR
      }
    };
    const params = {
      userName,
      password
    };
    return axios.post(API_URL + AppConst.API.SSO, params, headers);
  };

  fetchAccessTokenBySelectedCorp = (corpCode: string, isSwitchCorp?: boolean) => {
    return ApiHelper.post(!isSwitchCorp ? AppConst.API.ENTERCORP : AppConst.API.SWITCH_CORP, { corpCode });
  };

  storeToken = (userId: string, username: string, token: string) => {
    StorageHelper.setItem(StorageHelper.KEY_USER_ID, userId);
    StorageHelper.setItem(StorageHelper.KEY_USER_NAME, username);
    StorageHelper.setItem(StorageHelper.KEY_ACCESS_TOKEN, token);
  };

  storeSecondToken = (token: string) => {
    StorageHelper.setItem(StorageHelper.KEY_ACCESS_TOKEN, token);
  };

  logout = () => {
    return ApiHelper.get(AppConst.API.LOGOUT);
  };

  findPassword = (userName: string, email: string) => {
    return ApiHelper.postwithoutToken(AppConst.API.SYS_AUTHEN_FIND_PASSWORD, { userName, email });
  };

  resetPassword = (userName: string, token: string, newPassword: string) => {
    return ApiHelper.postwithoutToken(AppConst.API.SYS_AUTHEN_RESET_PASSWORD, { userName, password: newPassword, token });
  };

  changePassword = (userId: string, oldPassword: string, newPassword: string) => {
    const params = { userId, oldPassword, newPassword };
    return ApiHelper.post(`${AppConst.API.SYS_USER}/change/password`, params);
  };
}

export default new AuthenticationService();
