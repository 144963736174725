class ItemModel {
  id: number;
  group: number;
  title: React.ReactNode;
  start_time: any;
  end_time: any;
  rate: string;
  data: string | undefined;
  color: any;
  selectedBgColor: any;
  bgColor: any;
  titDesc: string;

  constructor(obj: any) {
    this.id = obj.id;
    this.group = obj.group;
    this.title = obj.title;
    this.start_time = obj.start_time;
    this.end_time = obj.end_time;
    this.data = obj.data;
    this.color = obj.color;
    this.selectedBgColor = obj.selectedBgColor;
    this.bgColor = obj.bgColor;
    this.titDesc = obj.titDesc;
  }
}
export default ItemModel;
