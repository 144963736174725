import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import OvertimeMonthApprListStore from '../stores/OvertimeMonthApprListStore';
import OverTimeContentsHeaderMonthApprDetailContainer from './OverTimeContentsHeaderMonthApprDetailContainer';
import OverTimeContentsHeaderInfoApprDetailContainer from './OverTimeContentsHeaderInfoApprDetailContainer';
import OverTimeContentsBodyApprDetailContainer from './OverTimeContentsBodyApprDetailContainer';
import OverTimeContentsBottomInfoApprDetailContainer from './OverTimeContentsBottomInfoApprDetailContainer';

interface Props {
  overtimeMonthApprListStore?: OvertimeMonthApprListStore;
  history?: any;
}

const ContentWrapRight = styled.div`
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: flex-end;
`;

@inject('overtimeMonthApprListStore')
@observer
export default class OverTimeMonthWrapperApprDetailContainer extends Component<Props> {
  render(): JSX.Element {
    return (
      <>
        <OverTimeContentsHeaderMonthApprDetailContainer {...this.props} overtimeMonthApprListStore={this.props.overtimeMonthApprListStore!} />
        <OverTimeContentsHeaderInfoApprDetailContainer overtimeMonthApprListStore={this.props.overtimeMonthApprListStore!} />
        <OverTimeContentsBodyApprDetailContainer overtimeMonthApprListStore={this.props.overtimeMonthApprListStore!} />
        {/* <br /> */}
        {/* <ContentWrapRight>
          <OverTimeContentsBottomInfoApprDetailContainer overtimeMonthApprListStore={this.props.overtimeMonthApprListStore!} />
        </ContentWrapRight> */}
      </>
    );
  }
}
