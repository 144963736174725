import React from 'react';
import { Table, Button } from 'antd';
import { observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import IconMetanet from 'components/IconMetanet';
import AppConst from 'shared/utils/AppConst';
import commonStore from '../stores/CommonStore';
import '../static/style.css';

export interface SearhcPops {
  onSearch: any;
  commonStore?: commonStore;
}

/**
 * PM GROUP Table
 */
@inject('commonStore')
@observer
class CommonProjectTableDrawerContainer extends React.Component<SearhcPops> {
  constructor(props: any) {
    super(props);
  }
  state = {
    rowNo: 0
  };

  handleSave = (row: any) => {};

  handleSearch = (e: any) => {
    this.props.onSearch(e);
  };

  setRowClassName = (record: any) => {
    return record.no === this.state.rowNo ? 'clickRowStyl' : '';
  };

  render() {
    // const { users, onSelectUser } = this.props;

    const COL_WIDTH_MIN = '5%';
    const COL_WIDTH_MEDIUM = '10%';
    const myColumns = [
      {
        title: <FormattedMessage id="table.drawer.col.no" />,
        dataIndex: 'no',
        key: 'no',
        width: COL_WIDTH_MIN,
        align: 'center'
      },
      // {
      //   title: <FormattedMessage id="table.drawer.col.project.code" />,
      //   dataIndex: 'pjCode',
      //   key: 'pjCode',
      //   width: COL_WIDTH_MEDIUM
      // },
      {
        title: <FormattedMessage id="table.drawer.col.project.name" />,
        dataIndex: 'pjName',
        key: 'pjName',
        width: COL_WIDTH_MEDIUM,
        align: 'center'
      },
      // {
      //   title: <FormattedMessage id="table.drawer.col.pm.empno" />,
      //   dataIndex: 'pmEmpNo',
      //   key: 'pmEmpNo',
      //   width: COL_WIDTH_MEDIUM
      // },
      {
        title: <FormattedMessage id="table.drawer.col.pm.name" />,
        dataIndex: 'pmEmpNm',
        key: 'pmEmpNm',
        width: COL_WIDTH_MEDIUM,
        align: 'center'
      },
      {
        title: <FormattedMessage id="table.col.mainSalesDept" />,
        dataIndex: 'mainSalesDeptNm',
        key: 'mainSalesDeptNm',
        width: COL_WIDTH_MEDIUM,
        editable: true
      },
      {
        title: <FormattedMessage id="table.col.mainPerformDept" />,
        dataIndex: 'mainPerformDeptNm',
        key: 'mainPerformDeptNm',
        width: COL_WIDTH_MEDIUM,
        editable: true
      },
      {
        title: <FormattedMessage id="table.drawer.col.pj.status" />,
        dataIndex: 'pjStatusNm',
        key: 'pjStatusNm',
        width: COL_WIDTH_MIN,
        align: 'center'
      },
      {
        title: <FormattedMessage id="table.drawer.col.pj.type" />,
        dataIndex: 'pjTypeNm',
        key: 'pjTypeNm',
        width: COL_WIDTH_MIN,
        align: 'center'
      }
    ];

    const columns = myColumns.map((col: any) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: any) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave
        })
      };
    });

    const divStyle = {
      marginBottom: 70,
      marginRight: 4
    };

    const onRow = (record: any, rowIndex: any) => {
      return {
        onClick: (event: any) => {
          this.setState({
            rowNo: record.no
          });
          this.props.commonStore!.setReturnPjValue(record);
        },

        onDoubleClick: (event: any) => {
          // this.rowIdSelected = record.id;
          if (record.no === '') return;
          this.props.commonStore!.setReturnPjValue(record);
          this.props.commonStore!.setVisibleDrawer(false);
        }
      };
    };
    const { projects } = this.props.commonStore!;
    const myDataSource = projects;
    const isPagingAllowed = !!(myDataSource && myDataSource.length > AppConst.MAX_ROW_WITHOUT_PAGING);
    const paginationConfig = isPagingAllowed ? { pageSize: AppConst.TABLE_PAGE_SIZE, showSizeChanger: true, showQuickJumper: true } : false;

    const buttonStyle = {
      marginBottom: 16,
      marginRight: 4
    };

    const scroll = myDataSource.length > 0 ? { x: 300, y: 'calc(100vh - 400px)' } : {};

    return (
      <div style={divStyle}>
        <div style={{ textAlign: 'right', overflow: 'hidden' }}>
          <h3 style={{ float: 'left', fontWeight: 'bold' }}>
            <IconMetanet />
            <FormattedMessage id="label.drawer.project.list" />
          </h3>
          <Button icon="search" type="primary" style={buttonStyle} onClick={this.handleSearch}>
            <span>
              <FormattedMessage id="button.drawer.button.search" />
            </span>
          </Button>
        </div>

        <Table
          bordered
          dataSource={myDataSource}
          columns={columns}
          size="middle"
          pagination={paginationConfig}
          scroll={scroll}
          className="hrmTableClass"
          // onChange    = {this.handleChange}
          onRow={onRow}
          rowClassName={this.setRowClassName}
        />
      </div>
    );
  }
}

export default CommonProjectTableDrawerContainer;
