import * as React from 'react';
import { observable, action, toJS, computed } from 'mobx';
import { RootStore } from 'shared/stores/RootStore';
import HrAssignManageModel from '../static/HrAssignManageModel';
import GroupModel from '../static/HrAssignManageTimeLineGroupModel';
import ItemModel from '../static/HrAssignManageTimeLineItemModel';
import HrAssignManageService from '../services/HrAssignManageService';
import { MenuAssignImplementStore, TMenuAssign } from 'shared/stores/MenuAssignImplementStore';
import moment from 'moment';

export class HrAssignManageStore {
  @observable
  utilPlans: HrAssignManageModel[];

  @observable
  detailSchedule: HrAssignManageModel[];

  @observable
  inProgress: boolean;

  @observable
  groupModel: GroupModel[];

  @observable
  itemModel: ItemModel[];

  @observable
  visibleDtlDrawer: boolean;

  @observable
  selectedYear: string;

  @observable
  searchDeptCode: string | undefined;

  @observable
  searchDeptName: string | undefined;

  @observable
  sharedDeptCode: string | undefined;

  @observable
  sharedDeptName: string | undefined;

  @observable
  sharedPjCode: string | undefined;

  @observable
  sharedPjName: string | undefined;

  @observable
  sharedYear: string | undefined;

  @observable
  gridData: [];

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.utilPlans = [];
    this.visibleDtlDrawer = false;
    this.rootStore = rootStore;
    this.selectedYear = moment().format('YYYY');
    this.gridData = [];
  }

  /////////////////////////////////////////////////////////////////////////
  /////////////////////// TimeLine Area Start /////////////////////////////
  /////////////////////////////////////////////////////////////////////////

  // 프로젝트 소속 인원 별 스케쥴 조회
  @action
  getUtilPlanMemberSchedule(pjCode: string, deptCode: string, year: string): any {
    this.inProgress = true;
    this.selectedYear = year;

    return HrAssignManageService.getUtilPlanMemberSchedule(pjCode, deptCode, year)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);

        const data = response.data.data || [];

        this.groupModel = this.createTimeLineGroupModel(data);
        this.itemModel = this.createTimeLineItemModel(data);
        this.inProgress = false;
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  createTimeLineGroupModel(data: any) {
    const arrayRoles: GroupModel[] = [];
    this.inProgress = true;

    let cnt = 1;
    let bfEmpNo = '';
    let bfCnt = 1;

    data.map((item: any) => {
      const titName = `${item.empName}(${item.positName}) `;

      if (cnt === 1) {
        const role = new GroupModel({
          id: item.empNo,
          title: titName,
          stackItems: true
        });

        cnt += 1;
        bfEmpNo = item.empNo;
        bfCnt += 1;
        arrayRoles.push(role);
      } else if (bfEmpNo != item.empNo) {
        const role = new GroupModel({
          id: item.empNo,
          title: titName,
          stackItems: true
        });

        bfEmpNo = item.empNo;
        bfCnt += 1;
        arrayRoles.push(role);
      }
    });

    // console.log(arrayRoles);
    this.inProgress = false;
    return arrayRoles;
  }

  createTimeLineItemModel(data: any) {
    const arrayRoles: ItemModel[] = [];
    // const { getCommonCodeName } = this.rootStore.systemLanguageStore;
    this.inProgress = true;

    let cnt = 1;
    data.map((item: any) => {
      let bgColor = '#9e9e9e'; // S: 승인완료

      if (item.apprSts === '1') bgColor = '#78d6c5'; // 1:작성중
      if (item.apprSts === 'A') bgColor = '#99ffff'; // A:승인요청
      if (item.apprSts === 'R') bgColor = '#f39c11'; // R:반려

      // let planTitleTxt = getCommonCodeName('0002', item.apprSts);

      const role = new ItemModel({
        id: cnt,
        group: item.empNo,
        title: `[${item.pjCode}]${item.pjName}(${item.injctRate}%)`,
        start_time: moment(item.injctStartDay, 'YYYYMMDD').format('x'),
        end_time: moment(item.injctEndDay, 'YYYYMMDD')
          .add(1, 'day')
          .format('x'),
        data: item.injctRate,
        pjCode: item.pjCode,
        pjName: item.pjName,
        bgColor,
        stackItems: true
      });
      cnt += 1;
      arrayRoles.push(role);
    });
    // console.log(arrayRoles);
    this.inProgress = false;
    return arrayRoles;
  }

  @action
  setVisibleDtlDrawer(visible: boolean): void {
    this.visibleDtlDrawer = visible;
  }

  // 상세 내용조회
  @action
  getMemberScheduleDtl(item: ItemModel) {
    this.inProgress = true;
    //  this.selectedScheduleId = item.id;

    const fromDt = moment.unix(item.start_time / 1000).format('YYYYMMDD');
    const toDt = moment.unix(item.end_time / 1000).format('YYYYMMDD');
    const empNo = String(item.group);
    const { pjCode } = item;

    this.getUtilPlanDetailPersonalSchedule(empNo, pjCode, fromDt, toDt);
    this.inProgress = false;
  }

  // 프로젝트별, 개인별, 개간별 스케쥴 조회
  @action
  getUtilPlanDetailPersonalSchedule(empNo: string, pjCode: string, fromDate: string, toDate: string) {
    const arrayRoles: HrAssignManageModel[] = [];
    this.inProgress = true;
    return HrAssignManageService.getUtilPlanDetailPersonalSchedule(empNo, pjCode, fromDate, toDate)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);

        const data = response.data.data || [];

        let cnt = 1;
        data.map((item: any) => {
          const role = new HrAssignManageModel({
            no: cnt,
            corpCode: item.corpCode,
            pjCode: item.pjCode,
            pjName: item.pjName,
            injctStartDay: item.injctStartDay,
            injctEndDay: item.injctEndDay,
            actvType: item.actvType,
            injctRate: item.injctRate,
            deptCode: item.deptCode,
            empName: item.empName,
            empNo: item.empNo,
            jobName: item.JobName,
            positName: item.positName,
            apprSts: item.apprSts
          });
          cnt += 1;
          arrayRoles.push(role);
        });
        this.detailSchedule = arrayRoles;
        this.inProgress = false;
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }

  // 개인, 프로젝트, 승인/반려 처리
  @action
  memberStatusRequest(stat: string) {
    this.inProgress = true;
    const model = new HrAssignManageModel({
      empNo: this.detailSchedule[0].empNo,
      pjCode: this.detailSchedule[0].pjCode,
      injctStartDay: this.detailSchedule[0].injctStartDay,
      injctEndDay: this.detailSchedule[0].injctEndDay,
      apprSts: stat
    });

    return HrAssignManageService.updateApproveStauts(model)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  @action
  memberProjectStatusRequest(stat: string) {
    this.inProgress = true;
    const model = new HrAssignManageModel({
      empNo: this.detailSchedule[0].empNo,
      pjCode: this.detailSchedule[0].pjCode,
      bfApprSts: 'A', // 승인요청건
      apprSts: stat
    });

    return HrAssignManageService.updateApproveStauts(model)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  // Drawer 영역
  @computed
  get selectedDepartment() {
    this.searchDeptCode = this.rootStore.departmentStore.selectedDept.deptCode;
    this.searchDeptName = this.rootStore.departmentStore.selectedDept.deptName;
    return this.rootStore.departmentStore.selectedDept;
  }

  async fetchDepartmentForSearchPanel() {
    if (toJS(this.rootStore.departmentStore.departmentList.length) === 0) {
      await this.rootStore.departmentStore.searchDepartments({});
    }
  }

  @action
  reset() {
    this.rootStore.departmentStore.departmentList = [];
  }

  /////////////////////////////////////////////////////////////////////////
  /////////////////////// Grid Area Start /////////////////////////////////
  /////////////////////////////////////////////////////////////////////////

  // 프로젝트 소속 인원 별 스케쥴 조회
  @action
  getUtilPlanMemberGrid(pjCode: string, deptCode: string, year: string): any {
    this.inProgress = true;
    this.selectedYear = year;
    const { getCommonCodeName } = this.rootStore.systemLanguageStore;
    return HrAssignManageService.getUtilPlanMemberGrid(pjCode, deptCode, year)
      .then(response => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);

        // console.log(this.rootStore.routeStore.firstAccessLocationPathname); // 전체 Menu 경로 가져오기

        const data = response.data.data || [];
        this.gridData = [];

        // reduce No. column + data type
        data.map((v: any, i: any) => {
          v.no = i + 1;
          v.formatInjctStartDay = moment(v.injctStartDay).format('YYYY-MM-DD');
          v.formatInjctEndDay = moment(v.injctEndDay).format('YYYY-MM-DD');
          v.injctRate = '-'; // 가동율
          v.period = '-'; // 프로젝트 기간
          v.pmEmpNm = '-'; // PM 명
          v.type = 'parents';

          let apprCnt = 0;

          v.children.map((s: any, i: any) => {
            let typeNm = getCommonCodeName('0002', s.apprSts);
            let fontColorNm = 'gray';
            s.formatInjctStartDay = moment(s.injctStartDay).format('YYYY-MM-DD');
            s.formatInjctEndDay = moment(s.injctEndDay).format('YYYY-MM-DD');
            s.injctRate = `${s.injctRate}%`;
            s.rowType = 'child';
            s.period = `${moment(s.pjStartDate).format('YYYY-MM-DD')}~${moment(s.pjEndDate).format('YYYY-MM-DD')}`;
            s.type = 'children';

            if (s.apprSts === 'S') {
              fontColorNm = 'green';
            } else if (s.apprSts === 'A') {
              fontColorNm = '#AC1F8D';
              apprCnt += 1;
            }

            s.status = React.createElement('div', { style: { color: fontColorNm, fontWeight: 'bold' } }, typeNm);
            // s.btnApply = React.createElement('button', { type: 'primary' }, '승인');
            // s.btnRejct = React.createElement('button', { type: 'primary' }, '반려');
          });

          v.apprCnt = apprCnt;
          v.status = `승인대기 (${apprCnt}) 건`;

          return null;
        });

        this.gridData = data;
        this.inProgress = false;
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
        this.inProgress = false;
      });
  }
  // 일괄 승인/반려
  @action
  approveOrRejectAllRequestByEmpNo(record: any, apprSts: string) {
    this.inProgress = true;
    const model = new HrAssignManageModel({
      empNo: record.empNo,
      bfApprSts: 'A', // 승인요청건
      apprSts: apprSts
    });

    return HrAssignManageService.updateApproveStauts(model)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
      })
      .catch(error => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }
}

export default HrAssignManageStore;
