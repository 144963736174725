import { observable } from 'mobx';
import CorporationAPIModel from 'shared/models/corporation/CorporationAPIModel';
import { MobileRootStore } from 'shared/mobile-stores/RootStore';

export class CorporationStore {
  rootStore: MobileRootStore;
  @observable
  corpList: CorporationAPIModel[] = [];

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }
}
