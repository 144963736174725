import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import AppMobileWrapperContainer from '../containers/AppMobileWrapperContainer';

@inject('authStore')
@observer
export default class OvertimeAppMobileView extends Component<any, any> {
  render() {
    return <AppMobileWrapperContainer {...this.props} />;
  }
}
