import uuid from 'uuid';

class EmployeeModel {
  cellPhoneNo: string;
  corpCode: string;
  corpName: string;
  deptCode: string;
  deptHeadEmpName: string;
  deptHeadEmpNo: string;
  deptName: string;
  divCode: string;
  divName: string;
  email: string;
  empName: string;
  empNo: string;
  empTypeCode: string;
  empTypeName: string;
  entryDate: number;
  groupEntryDate: number;
  resignDate: number;
  jobCode: string;
  jobName: string;
  nextLevelHeadEmpName: string;
  nextLevelHeadEmpNo: string;
  officeCode: string;
  officeName: string;
  originMemberYn: string;
  phoneNo: string;
  positCode: string;
  positName: string;
  remark: string;
  rowStatus: string;
  sectHeadEmpName: string;
  sectHeadEmpNo: string;
  userId: string;
  workGroupCode: string;
  workGroupName: string;
  workSysCode: string;
  workSysName: string;
  // ID of object in store, not ID of record from API.
  id: string;
  // Status of object while editing.
  // Should consider using this approach later:
  // https://github.com/mobxjs/mobx-utils#viewmodel
  changeStatus: string;
  regEmpNo: string;
  regEmpName: string;
  updatedEmpNo: string;
  updatedEmpName: string;
  regDate: number;
  updatedDate: number;

  [key: string]: any;

  constructor(obj: any, id?: string) {
    this.id = id || uuid.v4();
    this.changeStatus = obj.changeStatus;
    this.cellPhoneNo = obj.cellPhoneNo;
    this.corpCode = obj.corpCode;
    this.corpName = obj.corpName;
    this.deptCode = obj.deptCode;
    this.deptHeadEmpName = obj.deptHeadEmpName;
    this.deptHeadEmpNo = obj.deptHeadEmpNo;
    this.deptName = obj.deptName;
    this.divCode = obj.divCode;
    this.divName = obj.divName;
    this.email = obj.email;
    this.empName = obj.empName;
    this.empNo = obj.empNo;
    this.empTypeCode = obj.empTypeCode;
    this.empTypeName = obj.empTypeName;
    this.entryDate = obj.entryDate;
    this.groupEntryDate = obj.groupEntryDate;
    this.resignDate = obj.resignDate;
    this.jobCode = obj.jobCode;
    this.jobName = obj.jobName;
    this.nextLevelHeadEmpName = obj.nextLevelHeadEmpName;
    this.nextLevelHeadEmpNo = obj.nextLevelHeadEmpNo;
    this.officeCode = obj.officeCode;
    this.officeName = obj.officeName;
    this.originMemberYn = obj.originMemberYn;
    this.phoneNo = obj.phoneNo;
    this.positCode = obj.positCode;
    this.positName = obj.positName;
    this.remark = obj.remark;
    this.rowStatus = obj.rowStatus;
    this.sectHeadEmpName = obj.sectHeadEmpName;
    this.sectHeadEmpNo = obj.sectHeadEmpNo;
    this.userId = obj.userId;
    this.workGroupCode = obj.workGroupCode;
    this.workGroupName = obj.workGroupName;
    this.workSysCode = obj.workSysCode;
    this.workSysName = obj.workSysName;
    this.regEmpNo = obj.regEmpNo;
    this.regEmpName = obj.regEmpName;
    this.updatedEmpNo = obj.updatedEmpNo;
    this.updatedEmpName = obj.updatedEmpName;
    this.regDate = obj.regDate;
    this.updatedDate = obj.updatedDate;
  }
}

export default EmployeeModel;
