import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import UtilizationResultAppMobileStore from '../stores/UtilizationResultAppMobileStore';
import AppDetailMobileHeaderContainer from '../containers/AppDetailMobileHeaderContainer';
import AppDetailMobileBodyContainer from '../containers/AppDetailMobileBodyContainer';

interface Props extends RouteComponentProps {
  utilizationResultAppMobileStore?: UtilizationResultAppMobileStore;
  history: any;
}

const Wrapper = styled.div`
  color: #000000;
  text-align: left;
  /* padding-top: 5px; */
`;

@inject('utilizationResultAppMobileStore')
@observer
class AppDetailMobileWrapperContainer extends Component<Props> {
  componentDidMount() {
    if (this.props.utilizationResultAppMobileStore!.selectedCellData === undefined) {
      this.props.history.push('/m/timesheet/utilresult/utilresultapp');
    }
  }

  onClickGoToList = () => {
    this.props.history.push('/m/timesheet/utilresult/utilresultapp');
  };

  render() {
    return (
      <>
        <Wrapper>
          <AppDetailMobileHeaderContainer onClickGoToList={this.onClickGoToList} />
          <AppDetailMobileBodyContainer />
        </Wrapper>
      </>
    );
  }
}

export default withRouter(AppDetailMobileWrapperContainer);
