import _ from 'lodash';
import { action, computed, observable, toJS } from 'mobx';
import TreeNode from 'shared/components/TreeList/TreeNode';
import MenuModel from 'shared/models/menu/MenuModel';
import MenuSearchModel from 'shared/models/menu/MenuSearchModel';
import { RootStore } from 'shared/stores/RootStore';
import AppConst from 'shared/utils/AppConst';
import AppHelper from 'shared/utils/AppHelper';
import StorageHelper from 'shared/utils/StorageHelper';
import uuid from 'uuid';
import MenuService from '../services/MenuService';

export class MenuStore {
  @observable
  searchModel: MenuSearchModel;

  @observable
  selectedMenu?: MenuModel;

  @observable
  menuList: MenuModel[] = [];

  @observable
  expandedKeys: string[];

  @observable
  expandedMenuCode: string[];

  @observable
  inProgress = false;

  @observable
  rootStore: RootStore;

  @observable
  lastSearch: any;

  @observable
  currLanguage: any;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    this.searchModel = {
      menuName: '',
      progName: '',
      useYn: ''
    };

    this.lastSearch = null;
    this.currLanguage = StorageHelper.getItem(StorageHelper.KEY_CURR_LANGUAGE) === AppConst.LANG_ENUS ? AppConst.LANG_EN.toUpperCase() : AppConst.LANG_KO.toUpperCase();
  }

  @action
  async find() {
    this.inProgress = true;
    const getLanguage = (languageList: any[], currLang: string): any => {
      return languageList.find(value => value.language === currLang) || {};
    };
    this.lastSearch = { ...toJS(this.searchModel) };
    try {
      const apiResult: any = await MenuService.find(this.searchModel);
      this.rootStore.apiHandleStore.handleApiFail(apiResult.data.header);
      const { isSuccessful } = apiResult.data.header;
      if (isSuccessful) {
        this.menuList = toJS(
          apiResult.data.data.map((x: MenuModel) => {
            x.id = uuid.v4();
            x.menuName = getLanguage(x.languages, AppConst.LANG_KO.toUpperCase()).menuName;
            x.menuNameEnglish = getLanguage(x.languages, AppConst.LANG_EN.toUpperCase()).menuName;
            x.authType = '';
            return x;
          })
        );
        // Expand Previous keys
        this.expandedKeys = this.getPreviousKey();
      }
    } catch (error) {
      this.rootStore.apiHandleStore.handleApiError(error);
    }
    this.inProgress = false;
  }

  @action
  save() {
    const self = this;
    this.inProgress = true;

    const _insert = this.menuList.filter(x => x.changeStatus === 'CREATE');
    const _update = this.menuList.filter(x => x.changeStatus === 'UPDATE');
    const _delete = this.menuList.filter(x => x.changeStatus === 'DELETE');
    const _deleteIds = _delete.filter(x => x.menuCode !== undefined).map(y => y.menuCode);

    const _updateMenu = _update.map(x => {
      if (x.menuType === AppConst.menyType.FOLDER) x.progCode = '';
      return x;
    });
    const _insertMenu = _insert.map(x => {
      if (x.menuType === AppConst.menyType.FOLDER) x.progCode = '';
      return x;
    });

    const insertPromise = MenuService.createArray(_insertMenu);
    const updatePromise = MenuService.updateArray(_updateMenu);
    const deletePromise = MenuService.deleteArray(_deleteIds);

    const promises: Array<Promise<any>> = [];
    if (_insertMenu.length > 0) promises.push(insertPromise);
    if (_updateMenu.length > 0) promises.push(updatePromise);
    if (_deleteIds.length > 0) promises.push(deletePromise);

    Promise.all(promises)
      .then(function(responses: any) {
        self.rootStore.apiHandleStore.handleAllApiResult(responses);
        self.find();
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  @action
  setSelectedMenu(menuId: string | number) {
    this.selectedMenu = this.menuList.find(x => x.id === menuId)!;
  }

  setExpandedKeys(expandedKeys: string[]) {
    this.expandedKeys = expandedKeys;
    this.expandedMenuCode = this.menuList.filter(x => expandedKeys.includes(x.id)).map(y => y.menuCode);
  }

  @action
  getPreviousKey() {
    if (this.expandedMenuCode) {
      const expandedKeys = this.menuList.filter(x => this.expandedMenuCode.includes(x.menuCode)).map(y => y.id);
      return expandedKeys;
    }
    return [];
  }

  @action
  updateMenu(menuId: string, partial: object) {
    const selectedMenuIndex = this.menuList.findIndex(x => x.id === menuId)!;
    if (selectedMenuIndex !== -1) {
      const selectedMenu = this.menuList[selectedMenuIndex];
      const changedMenu = { ...selectedMenu, ...partial };
      this.selectedMenu = changedMenu;
      this.menuList[selectedMenuIndex] = changedMenu;
    }
  }

  @action
  createMenu(): MenuModel | undefined {
    if (this.selectedMenu!) {
      const x = this.selectedMenu!;
      const menuId = uuid.v4();
      const menuModel = new MenuModel({
        id: menuId,
        menuType: x.menuType,
        corpCode: x.corpCode,
        upperMenuName: x.menuName,
        upperMenuCode: x.menuCode,
        useYn: 'Y',
        mobileYn: 'Y',
        displayOrder: 1,
        languages: [{ language: AppConst.LANG_EN.toUpperCase(), menuName: 'New menu' }, { language: AppConst.LANG_KO.toUpperCase(), menuName: '한글메뉴' }],
        changeStatus: 'CREATE'
      });
      this.menuList.push(menuModel);
      this.setSelectedMenu(menuId);
      return menuModel;
    }
  }

  @computed
  get hasUpdateMenu() {
    const _insert = this.menuList.filter(x => x.changeStatus === 'CREATE');
    const _update = this.menuList.filter(x => x.changeStatus === 'UPDATE');
    const _delete = this.menuList.filter(x => x.changeStatus === 'DELETE');

    return _insert.length + _update.length + _delete.length > 0;
  }

  @computed
  get treeData(): TreeNode[] {
    const getLanguage = (languageList: any[], currLang: string): any => {
      return languageList.find(value => value.language === currLang) || {};
    };
    const currLang = this.currLanguage;
    const sorted = _.orderBy(this.menuList, 'displayOrder');
    const getNodeText = (menuName: string, menuCode: string) => {
      if (!menuCode) return menuName;
      return menuName ? `${menuName}-${menuCode}` : menuCode;
    };
    return sorted.map(
      x =>
        new TreeNode({
          id: x.id,
          parentCode: x.upperMenuCode ? x.corpCode + x.upperMenuCode : '',
          valueCode: x.corpCode + x.menuCode,
          nodeText: getNodeText(getLanguage(x.languages, currLang).menuName, x.menuCode), 
          changeStatus: x.changeStatus
        })
    );
  }

  @computed
  get treeMenu(): any[] {
    const tree = buildTree(this.treeData);

    /**
     * Ref: http://jsfiddle.net/tx3uwhke/
     * @param data
     * @param parent
     */
    function buildTree(data: any, parent?: any): any[] {
      const result: any[] = [];
      const DEPTCODE_ROOT = '';
      parent = typeof parent !== 'undefined' ? parent : { valueCode: DEPTCODE_ROOT };
      const children = _.filter(data, function(value: MenuModel) {
        return value.parentCode === parent.valueCode;
      });
      if (!_.isEmpty(children)) {
        _.each(children, function(child) {
          if (child != null) {
            result.push(child);
            const ownChildren = buildTree(data, child);
            if (!_.isEmpty(ownChildren)) {
              child.isParent = true;
              child.children = ownChildren;
            } else {
              child.isParent = false;
            }
          }
        });
      }
      return result;
    }
    return tree;
  }

  @action
  exportExcel = () => {
    this.inProgress = true;
    return MenuService.export(this.lastSearch)
      .then((response: any) => {
        this.rootStore.apiHandleStore.handleApiFail(response.data.header);
        const url = response.data.data.url || '';
        if (url && url !== '') {
          AppHelper.downloadExcel(url);
        }
      })
      .catch((error: any) => {
        this.rootStore.apiHandleStore.handleApiError(error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  };

  @action
  onDelete() {
    if (this.selectedMenu!) {
      this.updateMenu(this.selectedMenu!.id, {
        changeStatus: 'DELETE'
      });
    }
  }

  @action
  setCurrentLanguge = (lang: string) => {
    this.currLanguage = lang;
  };
}
