import React from 'react';
import { Drawer } from 'antd';
import { FormattedMessage } from 'react-intl';

interface Props {
  widthDrawer?: number;
  titleDrawer: string;
  submitForm?: any;
  children?: any;
  visibleProps?: boolean;
  setVisibleDrawer?: any;
  searchPattern?: string;
}

class CommonProjectDrawerComponent extends React.Component<Props, any> {
  constructor(props: any) {
    super(props);
  }

  onClose = () => {
    this.props.setVisibleDrawer(false);
  };

  render() {
    const { widthDrawer, children } = this.props;
    return (
      <>
        <Drawer
          title={<FormattedMessage id="label.drawer.project.search" />}
          placement="right"
          closable={true}
          onClose={this.onClose}
          visible={this.props.visibleProps}
          width={widthDrawer}
          zIndex={1050} // 근로관리 popOver(1030)문제로 인하여 1050 설정
          maskClosable={false}
        >
          {React.cloneElement(children, {
            onClose: this.onClose
          })}
        </Drawer>
      </>
    );
  }
}

export default CommonProjectDrawerComponent;
