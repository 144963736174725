class TreeNode {
  id: string;
  parentCode: string;
  valueCode: string;
  nodeText: string;
  changeStatus?: string;
  children?: any;
  disabled: boolean;

  constructor(obj: any) {
    this.id = obj.id;
    this.parentCode = obj.parentCode;
    this.valueCode = obj.valueCode;
    this.nodeText = obj.nodeText;
    this.children = obj.children;
    this.changeStatus = obj.changeStatus;
    this.disabled = obj.disabled;
  }
}

export default TreeNode;
