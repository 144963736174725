class ReducWorkModel {
  no: number;
  seq: number;
  corpCode: string;
  empNo: string;
  empNm: string;
  reducWorkType: string;
  reducWorkTypeNm: string;
  reducWorkTermFrom: string;
  reducWorkTermTo: string;
  reducWorkTerm: string;
  reducWorkHours: string;
  parentReducWorkName: string;
  parentReducWorkBirthday: string;

  constructor(obj: any) {
    this.no = obj.no;
    this.seq = obj.seq;
    this.corpCode = obj.corpCode;
    this.empNo = obj.empNo;
    this.empNm = obj.empNm;
    this.reducWorkType = obj.reducWorkType;
    this.reducWorkTypeNm = obj.reducWorkTypeNm;
    this.reducWorkTermFrom = obj.reducWorkTermFrom;
    this.reducWorkTermTo = obj.reducWorkTermTo;
    this.reducWorkTerm = obj.reducWorkTerm;
    this.reducWorkHours = obj.reducWorkHours;
    this.parentReducWorkName = obj.parentReducWorkName;
    this.parentReducWorkBirthday = obj.parentReducWorkBirthday;
  }
}

export default ReducWorkModel;
