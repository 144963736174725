import React from 'react';
import { FormattedMessage } from 'react-intl';

const formEmployee = [
  {
    label: <FormattedMessage id="employees.label.department.name" />,
    key: 'deptName',
    type: 'input'
  },
  {
    label: <FormattedMessage id="employees.label.name" />,
    key: 'empName',
    type: 'input'
  }
];

export { formEmployee };
