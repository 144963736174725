import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import OvertimeAppMobileStore from '../stores/OvertimeAppMobileStore';
import OverTimeMonthModel from '../static/OverTimeMonthModel';
import AppDetailMobileCardComponent from '../components/AppDetailMobileCardComponent';
import MobileLanguageHelper from 'shared/utils/MobileLanguageHelper';

interface Props {
  overtimeAppMobileStore?: OvertimeAppMobileStore;
  onChangeHeaderVisible: (a: boolean) => void;
  headerVisible: boolean;
  monthlyVisible: boolean;
}

const BodyWrapper = styled.div<{ monthlyVisible: boolean }>`
  padding-top: ${props => (props.monthlyVisible ? '440px' : '215px')};
  color: #000000;
  /* padding-top: 450px; */
`;

const RenderWrapper = styled.div`
  padding: 15px 5px;
  text-align: center;
  background-color: #ffffff;
`;

const SummaryHeader = styled.div`
  font-weight: 700;
`;

const SummaryWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
`;

const SummaryItem = styled.div<{ bgcolor: string; color: string }>`
  background-color: ${props => props.bgcolor};
  color: ${props => props.color};
  text-align: center;
  width: 30%;
  padding: 2px;
`;

const SummaryItemName = styled.div`
  font-size: 12px;
`;

@inject('overtimeAppMobileStore')
@observer
class AppDetailMobileBodyContainer extends Component<Props> {
  renderSummary = (weekNumber: string, overTimeMonthWeeklyList: OverTimeMonthModel[]): JSX.Element => {
    let otAllSum = 0;
    let otAppSum = 0;
    let abAllSum = 0;
    let abAppSum = 0;
    let allSum = 0;
    let appSum = 0;

    overTimeMonthWeeklyList.forEach(item => {
      if (item.apprSts === 'S' && item.overTimeType === 'OT' && item.reducYn === null && item.leaveYn === null) {
        otAppSum += item.overtimeWorkHours;
        appSum += item.overtimeWorkHours;
      } else if (item.apprSts === 'S' && item.overTimeType === 'AB' && item.reducYn === null && item.leaveYn === null) {
        abAppSum -= item.overtimeWorkHours;
        appSum -= item.overtimeWorkHours;
      }

      if ((item.apprSts === 'S' || item.apprSts === 'A') && item.overTimeType === 'OT' && item.reducYn === null && item.leaveYn === null) {
        otAllSum += item.overtimeWorkHours;
        allSum += item.overtimeWorkHours;
      } else if ((item.apprSts === 'S' || item.apprSts === 'A') && item.overTimeType === 'AB' && item.reducYn === null && item.leaveYn === null) {
        abAllSum -= item.overtimeWorkHours;
        allSum -= item.overtimeWorkHours;
      }
    });

    return (
      <>
        <RenderWrapper>
          <SummaryHeader>
            {weekNumber} {MobileLanguageHelper.getMessage('overtime.card.title.week')}
          </SummaryHeader>
          <SummaryWrapper>
            <SummaryItem bgcolor="#bdbdd9" color="#57585a">
              <SummaryItemName>{MobileLanguageHelper.getMessage('overtime.card.title.overtime')}</SummaryItemName>
              <div>
                {otAppSum} ({otAllSum})
              </div>
            </SummaryItem>
            <SummaryItem bgcolor="#b9d0b4" color="#57585a">
              <SummaryItemName>{MobileLanguageHelper.getMessage('overtime.card.title.alterbreak')}</SummaryItemName>
              <div>
                {abAppSum} ({abAllSum})
              </div>
            </SummaryItem>
            <SummaryItem bgcolor="#d8dad9" color="#57585a">
              <SummaryItemName>{MobileLanguageHelper.getMessage('overtime.card.title.sum')}</SummaryItemName>
              <div>
                {appSum} ({allSum})
              </div>
            </SummaryItem>
          </SummaryWrapper>
        </RenderWrapper>
      </>
    );
  };

  renderContents = (overTimeMonthList: OverTimeMonthModel[]): JSX.Element[] => {
    const cardComponentList: JSX.Element[] = [];
    let weekNumber: string = '0';
    let count = 0;
    let overTimeMonthWeeklyList: OverTimeMonthModel[] = [];
    let summaryCheck = false;

    overTimeMonthList.forEach(overtimeItem => {
      const thisWeekList = overTimeMonthList.filter(
        item =>
          item.workSysMonthWeekly === overtimeItem.workSysMonthWeekly && !(item.overTimeType === null || item.overTimeType === '') && (item.apprSts === 'A' || item.apprSts === 'S')
      );
      if (weekNumber !== overtimeItem.workSysMonthWeekly) {
        if (thisWeekList.length > 0) cardComponentList.push(this.renderSummary(overtimeItem.workSysMonthWeekly, thisWeekList));
        weekNumber = overtimeItem.workSysMonthWeekly;
        overTimeMonthWeeklyList = [];
        summaryCheck = false;
      }
      if (
        !(overtimeItem.overTimeType === null || overtimeItem.overTimeType === '') &&
        (overtimeItem.apprSts === 'A' || overtimeItem.apprSts === 'S') &&
        overtimeItem.reducYn === null &&
        overtimeItem.leaveYn === null
      ) {
        cardComponentList.push(<AppDetailMobileCardComponent {...this.props} overtimeItem={overtimeItem} onChangeHeaderVisible={this.props.onChangeHeaderVisible} />);
        summaryCheck = true;
      }
      overTimeMonthWeeklyList.push(overtimeItem);
      // count += 1;
      // if (count === overTimeMonthList.length) {
      //   if (summaryCheck) cardComponentList.push(this.renderSummary(weekNumber, overTimeMonthWeeklyList));
      // }
    });
    return cardComponentList;
  };

  render() {
    const { overTimeMonthList } = this.props.overtimeAppMobileStore!;

    return (
      <>
        <BodyWrapper monthlyVisible={this.props.monthlyVisible}>{this.renderContents(overTimeMonthList)}</BodyWrapper>
      </>
    );
  }
}

export default AppDetailMobileBodyContainer;
