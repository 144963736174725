import { Icon, Layout, Menu } from 'antd';
import { ClickParam } from 'antd/lib/menu';
import { toJS } from 'mobx';
import React from 'react';
import { MenuAssignImplementStore, TMenuAssign } from 'shared/stores/MenuAssignImplementStore';
import { orderBy } from 'lodash';

const { SubMenu } = Menu;
const { Sider } = Layout;

interface IProps {
  sideBarMenuList?: TMenuAssign[];
  currentLanguage?: string;
  getMenuNameByLanguage?: Function;
  menuAssignImplementStore?: MenuAssignImplementStore;
}

// dumb component, receiver observable values by props from parent
class Sidebar extends React.Component<IProps> {
  handleClickLeafMenuItem = (menu: TMenuAssign) => {
    this.props.menuAssignImplementStore!.selectedProgramViewMenu = menu;
  };

  renderLeafMenus = (parentMenuCode: string) => {
    const arr: TMenuAssign[] = orderBy(this.props.sideBarMenuList, 'displayOrder') || [];

    return arr.map(v => {
      if (v.upperMenuCode !== parentMenuCode) {
        return null;
      }
      return (
        <Menu.Item key={v.menuCode}>
          <div onClick={() => this.handleClickLeafMenuItem(v)} role="presentation">
            <span>{this.props.getMenuNameByLanguage!(v)}</span>
          </div>
        </Menu.Item>
      );
    });
  };

  render() {
    const { selectedProgramViewMenu } = this.props.menuAssignImplementStore!;
    const menuParentDefaultOpen = selectedProgramViewMenu ? selectedProgramViewMenu.upperMenuCode : '';
    const selectedMenu = selectedProgramViewMenu ? selectedProgramViewMenu.menuCode : '';
    const orderMenu = orderBy(this.props.sideBarMenuList, 'displayOrder');
    return (
      <Sider width={200}>
        <div className="hrm-sidebar-menu-wrapper">
          <Menu theme="dark" mode="inline" selectedKeys={[selectedMenu]} defaultOpenKeys={[menuParentDefaultOpen]}>
            {orderMenu.map(v => {
              const menuNameByLanguage = this.props.getMenuNameByLanguage!(v);
              if (v.level !== 3) return null;
              return (
                <SubMenu
                  key={v.menuCode}
                  title={
                    <span>
                      <Icon type="folder" />
                      {menuNameByLanguage}
                    </span>
                  }
                >
                  {this.renderLeafMenus(v.menuCode)}
                </SubMenu>
              );
            })}
          </Menu>
        </div>
      </Sider>
    );
  }
}

export default Sidebar;
